'use strict';

import angular from 'angular';

export default angular.module('widgets.testing.grids', [])
    .filter('slitReaction', [slitReaction])
    .directive('agBackGrid2x4', [agBackGrid2x4])
    .directive('agBackGrid2x5', [agBackGrid2x5])
    .directive('agBackGrid1x8', [agBackGrid1x8])
    .directive('agBackGrid1x10', [agBackGrid1x10])
    .directive('agLeftArmGrid1x8', [agLeftArmGrid1x8])
    .directive('agLeftArmGrid1x10', [agLeftArmGrid1x10])
    .directive('agLeftForearmGrid2x4', [agLeftForearmGrid2x4])
    .directive('agLeftForearmGrid2x5', [agLeftForearmGrid2x5])
    .directive('agRightArmGrid1x8', [agRightArmGrid1x8])
    .directive('agRightArmGrid1x10', [agRightArmGrid1x10])
    .directive('agRightArmTreatmentGrid', [agRightArmTreatmentGrid])
    .directive('agRightArmTreatmentGridVialtest', [agRightArmTreatmentGridVialtest])
    .directive('agRightForearmGrid2x4', [agRightForearmGrid2x4])
    .directive('agRightForearmGrid2x5', [agRightForearmGrid2x5])
    .directive('agColumnGrid1x8', [agColumnGrid1x8])
    .directive('agColumnGrid1x10', [agColumnGrid1x10])
    .directive('agColumnTreatmentGridScit', [agColumnTreatmentGridScit])
    .directive('agColumnTreatmentGridSlit', [agColumnTreatmentGridSlit])
    .directive('agColumnTreatmentGridVt', [agColumnTreatmentGridVt])
    .directive('agColumnTreatmentGridVialtest', [agColumnTreatmentGridVialtest])
    .name;

// Filter for displaying a SLIT reaction. Unset, 0 for No Reaction, or 1 for Reaction.
function slitReaction() {
    return function (size) {
        if (!angular.isNumber(size) || size < 0)
            return '';
        else if (size === 0)
            return 'No';
        else
            return 'Yes';
    };
}

function getGridLink(gridRanges, maxGridsPerSheet, hideGridNumbers, extraStyleClass) {
    return (scope, element, attrs) => {
        scope.activeGridIdx = 1;
        scope.hideActiveGridName = false;
        scope.gridNames = [];
        scope.gridRanges = gridRanges;
        scope.maxGridCount = maxGridsPerSheet;
        scope.restartPosPerGrid = false;
        scope.extraStyleClass = extraStyleClass;
        let itemsPerGrid = gridRanges.x.length * gridRanges.y.length;

        /*
         * Three variations of getPrickerPos below; set based on the selected grid arrangement.
         */

        /** Get display position number from 1-based coordinates in a grid when showing sequential arrangement */
        let getPrickerPosSeq = (y,x) => {
            return y + ((x-1) * scope.gridRanges.y.length);
        };

        /** Get display position number from 1-based coordinates in a grid when showing clockwise arrangement */
        let getPrickerPosClock = (y,x) => {
            return x === 1 && scope.gridRanges.x.length > 1 ? (scope.gridRanges.x.length * scope.gridRanges.y.length - y + 1) : y;
        };

        /** Get display position number from 1-based coordinates in a grid when showing counter-clockwise arrangement */
        let getPrickerPosCounterClock = (y,x) => {
            return x === 2 ? (2 * scope.gridRanges.y.length - y + 1) : y;
        };

        scope.getPrickerPos = getPrickerPosSeq;  // Default until set

        scope.getBoxNumber = (ix, y, x) => {
            if (!scope.gs.boxNumbers[ix]) {
                return null;
            }
            else if (scope.restartPosPerGrid) {
                return scope.getPrickerPos(y, x);
            }
            else {
                return scope.gs.boxNumbers[ix];
            }
        };

        /*
         * Get index of a position on board. Account for gaps caused by unused grids.
         * Two variations, depending on whether these are true grids vs columns.
         */
        let getGridOffset = (gridIdx) => {
            if (scope.gs.sptGroups) {
                const groupLetter = scope.gs.sptGroups.charCodeAt(gridIdx - 1);
                return (groupLetter - /*'A'*/65) * itemsPerGrid;
            }
            else {
                return (gridIdx - 1) * itemsPerGrid;
            }
        };

        if (scope.gridRanges.x.length === 2) {
            scope.getIx = (y, gridIdx, x) => {
                return getGridOffset(gridIdx) + scope.getPrickerPos(y,x);
            };
        }
        else if (scope.gridRanges.x.length === 1) {
            scope.getIx = (y, gridIdx, x) => {
                return getGridOffset(gridIdx) + y;
            };
        }
        else {
            console.error("Grid x range > 2 not supported");
        }

        /*
         * Build lookups for templates to use instead of calling scope.getIx() and scope.getPrickerPos().
         * While the functions work, calling functions in templates breaks Angular's change detection,
         * requiring the functions to be called on every $digest. Lookup tables don't suffer this problem.
         */
        let buildLookups = () => {
            scope.ixLookup = []; // Usage: ixLookup[gridIdx][y][x]
            for (let gridIdx = 1; gridIdx <= scope.gridNames.length; ++gridIdx) {
                scope.ixLookup[gridIdx] = [];
                for (let y = 1; y <= scope.gridRanges.y.length; ++y) {
                    scope.ixLookup[gridIdx][y] = [];
                    for (let x = 1; x <= scope.gridRanges.x.length; ++x) {
                        scope.ixLookup[gridIdx][y][x] = scope.getIx(y, gridIdx, x);
                    }
                }
            }

            scope.prickerPosLookup = []; // Usage: prickerPosLookup[y][x];
            for (let y = 1; y <= scope.gridRanges.y.length; ++y) {
                scope.prickerPosLookup[y] = [];
                for (let x = 1; x <= scope.gridRanges.x.length; ++x) {
                    scope.prickerPosLookup[y][x] = scope.getPrickerPos(y, x);
                }
            }
        };

        /*
         * Watch for values that may change from the parent component, which require recomputation.
         */
        scope.$watchGroup(['gs.measureConfig', 'gs.sptGroups', 'gs.substanceCount', 'gs.arrangement'], () => {
            if (!scope.gs.isOtolaryngic && scope.gs.sptGroups) {
                scope.gridNames = Array.from(scope.gs.sptGroups).map(s => 'Group ' + s);
                scope.restartPosPerGrid = true;
                scope.showGridNames = true;
                scope.hideActiveGridName = false;
            }
            else {
                scope.restartPosPerGrid = false;
                scope.showGridNames = !hideGridNumbers;
                scope.hideActiveGridName = true;
                const numGrids = Math.ceil(scope.gs.substanceCount / itemsPerGrid);
                scope.gridNames = [];
                for (let grid = 0; grid < numGrids; ++grid) {
                    const groupLetter = String.fromCharCode(/*'A'*/65 + grid);
                    if (scope.gs.sptGroups && !scope.gs.sptGroups.includes(groupLetter)) {
                        // SPT Step passes sptGroups to scope. Don't want names for groups that aren't supposed to be tested
                        // IDT Step doesn't pass sptGroups to scope
                        continue;
                    }

                    // Custom Grid names are passed in specficially during the IDT step at Oto practices
                    if (!scope.gs.customGridNames) {
                        let rangeStart = 1 + (grid * itemsPerGrid);
                        let rangeEnd = rangeStart + itemsPerGrid - 1;
                        scope.gridNames.push(rangeStart + ' - ' + rangeEnd);
                    }
                    else {
                        // If there's an unnamed grid, it's due to controls being adhoc'ed or shifted to the back
                        let gridName = scope.gs.customGridNames[grid] || "Controls";
                        scope.gridNames.push(gridName);
                    }
                }
            }

            scope.maxlength = 5;
            scope.gs.sheetCount = Math.ceil(scope.gridNames.length / maxGridsPerSheet);

            switch (scope.gs.arrangement) {
                case 'SEQ':
                    scope.getPrickerPos = getPrickerPosSeq;
                    break;
                case 'CW':
                    scope.getPrickerPos = getPrickerPosClock;
                    break;
                case 'CCW':
                    scope.getPrickerPos = getPrickerPosCounterClock;
                    break;
            }
            buildLookups();
        });

        scope.onFocus = (index) => {
            scope.gs.setFocus();
            scope.gs.focused[index] = true;

            if (scope.gs.sptGroups) {
                const groupLetter = String.fromCharCode(/*'A'*/65 + Math.floor((index-1) / itemsPerGrid));
                scope.activeGridIdx = 1 + scope.gs.sptGroups.indexOf(groupLetter);
            }
            else {
                scope.activeGridIdx = Math.ceil(index / itemsPerGrid);
            }
        };

        scope.onBlur = (index) => {
            scope.gs.focused[index] = false;
            scope.tabindex = undefined;
        };
        
        /* Validate user input on wheal / erythema / range measurements. Numbers can be input and only one non-leading dash is allowed. */
        scope.onKeyPress = ($event, index) => {
            let elements = document.getElementsByName('section' + index);
            let viewValue = elements && elements[0] ? elements[0].value : '';
            
            let isValid = ($event.charCode >= 48 && event.charCode <= 57)
                || (viewValue.length > 0 && event.charCode === 45 && (viewValue.match(/-/g) || []).length === 0);

            if (!isValid) {
                $event.preventDefault();
            }
        }


        // Sheets are only used by column view. Each sheet can manage 40 measurements.
        // Sheet management variables are defined in scope.gs in order to break out of this directive's isolated scope,
        // as they are also used in agMeasureValues().
        scope.$watch('gs.sheet', () => {
            scope.sheetStartGrid = scope.gs.sheet * maxGridsPerSheet;
            scope.sheetEndGrid = scope.sheetStartGrid + maxGridsPerSheet;
            scope.gs.lastIdxCurrentSheet = scope.getIx(gridRanges.y.length, scope.sheetEndGrid, gridRanges.x.length);
        });
        scope.gs.sheet = 0;
    };
}

function agBackGrid2x4() {
    return {
        template: require('./back-grid-2x4.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1,2], y: [1,2,3,4] }, 5)
    };
}

function agBackGrid2x5() {
    return {
        template: require('./back-grid-2x5.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1,2], y: [1,2,3,4,5] }, 4)
    };
}

function agBackGrid1x8() {
    return {
        template: require('./column-grid.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8] }, 5, true, 'back')
    };
}

function agBackGrid1x10() {
    return {
        template: require('./column-grid.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8,9,10] }, 4, true, 'back')
    };
}

function agLeftArmGrid1x8() {
    return {
        template: require('./left-arm-grid-1x8.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8] }, 5)
    };
}

function agLeftArmGrid1x10() {
    return {
        template: require('./left-arm-grid-1x10.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8,9,10] }, 4)
    };
}

function agLeftForearmGrid2x4() {
    return {
        template: require('./left-forearm-grid-2x4.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1,2], y: [1,2,3,4] }, 2)
    };
}

function agLeftForearmGrid2x5() {
    return {
        template: require('./left-forearm-grid-2x5.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1,2], y: [1,2,3,4,5] }, 2)
    };
}

function agRightArmGrid1x8() {
    return {
        template: require('./right-arm-grid-1x8.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8] }, 5)
    };
}

function agRightArmGrid1x10() {
    return {
        template: require('./right-arm-grid-1x10.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8,9,10] }, 4)
    };
}

function agRightArmTreatmentGrid() {
    return {
        template: require('./right-arm-grid-treatment.html'),
        scope: false
    };
}

function agRightArmTreatmentGridVialtest() {
    return {
        template: require('./right-arm-grid-treatment-vialtest.html'),
        scope: false
    };
}

function agRightForearmGrid2x4() {
    return {
        template: require('./right-forearm-grid-2x4.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1,2], y: [1,2,3,4] }, 5)
    };
}

function agRightForearmGrid2x5() {
    return {
        template: require('./right-forearm-grid-2x5.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1,2], y: [1,2,3,4,5] }, 4)
    };
}

function agColumnGrid1x8() {
    return {
        template: require('./column-grid.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8] }, 5, true, 'column')
    };
}

function agColumnGrid1x10() {
    return {
        template: require('./column-grid.html'),
        scope: {
            gs: '='
        },
        link: getGridLink({ x: [1], y: [1,2,3,4,5,6,7,8,9,10] }, 4, true, 'column')
    };
}

function agColumnTreatmentGridScit() {
    return {
        template: require('./column-grid-treatment-scit.html'),
        scope: false
    };
}

function agColumnTreatmentGridSlit() {
    return {
        template: require('./column-grid-treatment-slit.html'),
        scope: false
    };
}

function agColumnTreatmentGridVt() {
    return {
        template: require('./column-grid-treatment-vtmeasure.html'),
        scope: false
    };
}

function agColumnTreatmentGridVialtest() {
    return {
        template: require('./column-grid-treatment-vialtest.html'),
        scope: false
    };
}
