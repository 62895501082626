"use strict";

import BaseController from './../../../base.controller.js';

export default class ControllerImpl extends BaseController {

    CRITERIA_NEXT_CHAR_MAX_WAIT_MS = 750;

    static $inject = ["$scope", "$timeout", "$injector", "freshWidgetService"];
    constructor($scope, $timeout, $injector, freshWidgetService) {
        super($scope, $injector);

        $scope.searchResults = [];
        $scope.searchCriteria = "";
        $scope.isLoading = false;
        $scope.showSettings = this.$scope.user.roles.includes('DOCTOR');
        $scope.appointmentDetailsSelected = (patient) => this._appointmentDetailsSelected(patient);
        $scope.toPatientDetails = (patient) => this._toPatientDetails(patient);
        $scope.closeResults = () => { this._clearSearch(); };
        $scope.onAddPatient = () => this._addPatient();
        $scope.openLabelDetails = () => this._openLabelDetails();

        freshWidgetService.ensureReadiness(this.$scope.user.email);

        this._timeoutImpl = $timeout;
        this._injector = $injector;
        this._patientSvc =  $injector.get("patientService");
        this._uibModal = $injector.get('$uibModal');

        // Instance of a Promise of a list of Patients (search results)
        // We've declared this instance to give ourselves an opportunity to cancel it, so the user can update it.
        this._pendingSearchPromise = null;
    }

    /**
     * @override
     */
    destructor() {
        super.destructor();
        this.cancelPendingSearch();
    }

    onCriteriaChange(updatedCriteriaExpression) {
        this.searchCriteriaExpression = updatedCriteriaExpression;

        // Reset search results so the last search results don't appear under the current search
        this.$scope.searchResults = [];

        // Cancel pending search, if exists
        this.cancelPendingSearch();

        // Clear subscription to previous search, if exists
        this.unsubscribeAllSubscriptions();

        // Don't search for empty
        if (this.searchCriteriaExpression) {
            this.$scope.isLoading = true;

            this._pendingSearchPromise = this._timeoutImpl(this.CRITERIA_NEXT_CHAR_MAX_WAIT_MS);
            this._pendingSearchPromise.then(() => this.doSearch());
        } else {
            this.$scope.isLoading = false;
        }
    }

    cancelPendingSearch() {
        if (this._pendingSearchPromise) {
            this._timeoutImpl.cancel(this._pendingSearchPromise);
            this._pendingSearchPromise = null;
        }
    }

    doSearch() {

        this._pendingSearchPromise = null;
        let searchQuery = this.searchCriteriaExpression;

        let xhrDone = false;
        let socketDone = false;

        // Subscribe to changes to this search we're about to perform
        this.registerSubscription(this.notificationService.subscribeToPatientSearch(this.$scope.practice, searchQuery))
            .then(null, null, (result) => {
                if (searchQuery === this.searchCriteriaExpression) {
                    socketDone = true;
                    if (xhrDone) {
                        this.$scope.isLoading = false;
                    }

                    // Socket response contains updated patients, so if any patients exist in both the XHR response
                    // and the socket, we use the patient from the socket
                    let patients = result.body.list.slice(0, 10 - this.$scope.searchResults.length);
                    for (let patient of patients) {
                        let i = this.$scope.searchResults.findIndex(m => m.id === patient.id);
                        if (i >= 0) {
                            this.$scope.searchResults[i] = patient;
                        }
                        else {
                            this.$scope.searchResults.push(patient);
                        }
                    }
                }
            })
            .catch(() => {
                socketDone = true;
                if (xhrDone) {
                    this.$scope.isLoading = false;
                }
            });

        this._patientSvc.findPatientByAnything(this.$scope.practice, searchQuery)
            .then((theResults)=> {
                xhrDone = true;
                if (socketDone) {
                    this.$scope.isLoading = false;
                } else if (theResults.isCompleteResult) {
                    this.$scope.isLoading = false;
                    this.unsubscribeAllSubscriptions();
                }

                let patients = theResults.list.slice(0, 10 - this.$scope.searchResults.length);
                for(let patient of patients) {
                    this.$scope.searchResults.push(patient);
                }

                return this.$scope.searchResults;
            })
            .catch(() => {
                xhrDone = true;
                if (socketDone) {
                    this.$scope.isLoading = false;
                }
            });

        this.startAllSubscriptions();
    }

    _appointmentDetailsSelected(patient) {
        let _this = this;
        this._uibModal.open({
            windowClass: 'appointment',
            template: require('../../appointment-details/appointment-details.html'),
            css: require('../../appointment-details/appointment-details.scss'),
            controller: 'AppointmentDetailsController',
            size: 'md',
            resolve: {
                patient: function() {

                    // Note this won't actually return the updated patient, the server will update
                    // the patient in the background. We can get the updated patient by asking the
                    // server for it later when it's needed
                    _this._patientSvc.refresh(patient);
                    return patient;
                }
            }
        });

        this.$scope.searchCriteria = "";
    }

    _clearSearch() {
        this.unsubscribeAllSubscriptions();

        this.$scope.$apply(() => {
            this.$scope.isLoading = false;
            this.$scope.searchCriteria = "";
            this.$scope.searchResults = [];
        });
    }

    _toPatientDetails(/** qualifies as a {ReferenceDTO.<Patient>} */patient) {
        this.routeToPage(this.urlPaths.PATIENT_DETAILS, patient);
    }

    /**
     * Open the Add Patient dialog
     * @private
     */
    _addPatient() {
        this._uibModal.open({
            windowClass: 'patientEdit',
            template: require('../../../patient/patient-edit/patient-edit.html'),
            css: require('../../../patient/patient-edit/patient-edit.scss'),
            controller: 'PatientEditController',
            size: 'lg',
            resolve: {
                patient: () => undefined
            }
        });

        this.$scope.searchCriteria = "";
    }

    /**
     * Open the label details modal
     */
    _openLabelDetails() {
        this._uibModal.open({
            openedClass: 'modal-open print-modal-only',
            windowClass: 'labelDetailsModal',
            template: require('../../label-details/label-details.html'),
            css: require('../../label-details/label-details.scss'),
            controller: 'LabelDetailsController',
            size: 'lg'
        });
    }
}
