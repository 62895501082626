import React, { useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

const FramePortal = React.forwardRef((props, ref) => {
	const [mountNode, setMountNode] = useState(null)

	useLayoutEffect(() => {
		setMountNode(ref.current.contentDocument.body)
	}, [ref.current])
	
    return (
		<iframe ref={ref} style={props.style}>
			{ mountNode ? createPortal(props.children, mountNode) : null }
		</iframe>
    )
})

export default FramePortal
