"use strict";
import angular from 'angular';
import AddPrescriptionModal from './add-rx-modal';
import ViewVialHistoryModal from './view-vial-history';
import DelayedReactionModal from './delayed-reaction-modal';
import PatientNoteModal from './patient-note-modal';
import PatientAlertModal from './patient-alert-modal';
import PatientDetailsController from "./patient.details.controller";

export default angular
    .module('pages.patient.details', [
        AddPrescriptionModal,
        ViewVialHistoryModal,
        DelayedReactionModal,
        PatientNoteModal,
        PatientAlertModal
    ])
    .controller('PatientDetailsController', PatientDetailsController)
    .name;
