"use strict";

import angular from 'angular';
import controller from "./controller";

export default angular.module('pages.inventory.sections.sp.well.board.details', [])
    .controller('BoardDetailsController', controller)
    .directive('spWellBoardTable', spWellBoardTable)
    .name;

function spWellBoardTable(){
    return {
        template: require('./sp-well-board-table.html'),
        css: require('./sp-well-board-table.scss'),
        link: function(scope, elem, attr) {
            scope.printDate = new Date();
        }
    };
}
