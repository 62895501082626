'use strict';

// Framework
import angular from 'angular';
import ngRoute from 'angular-route';

// Support from higher echelons within the project
import agStandardPageLayout from "../common/standard-layout";

// Logical Data Model
import InventorySections from "./models/inventory-sections";
import UrlPaths from "../../models/url-paths";
// UI-Widgetry
import moduleWidgets from "./widgets";

// Locally defined Inventory page specific services
import moduleServices from "./services";

// Page UI-Constituents
import NavigationPanel from "./navigation-panel";
import InventoryBoardList from "./widgets/inv-board-list";
import InventoryBoardDetails from "./widgets/inv-board-details";

// The primary controller impl in this module.
import InventoryController from './controller.js';
import ConcentrateDetailsController from './sections/concentrates/view-concentrate/controller.js';
import BoardDetailsController from './sections/sp-wells/sp-well-board-details/controller.js';
import InventoryBoardDetailsController from "./widgets/inv-board-details/controller";

// Import sections
import Concentrates from './sections/concentrates';
import ConcentrateDetails from './sections/concentrates/view-concentrate';
import SpWells from "./sections/sp-wells";
import BoardDetails from './sections/sp-wells/sp-well-board-details';
import TestingBoards from "./sections/testing-boards";
import MixingBoards from "./sections/mixing-boards";
import PatientVials from "./sections/patient-vials";

export default angular.module('pages.inventory',
    [
        // framework : principle-startup
        ngRoute,
        // From up above
        agStandardPageLayout,
        // data-model
        InventorySections,
        UrlPaths,
        // dynamically support UI
        moduleWidgets,
        // local services
        moduleServices,
        InventoryBoardList,
        InventoryBoardDetails,
        // directed-UI-constituents
        NavigationPanel,
        Concentrates,
        ConcentrateDetails,
        SpWells,
        BoardDetails,
        TestingBoards,
        MixingBoards,
        PatientVials
    ]
)
    .config(routingConfig)
    .controller('InventoryController', InventoryController)
    .controller('ConcentrateDetailsController', ConcentrateDetailsController)
    .controller('BoardDetailsController', BoardDetailsController)
    .name;

function routingConfig($routeProvider, InventorySections, UrlPaths) {

    $routeProvider.when(UrlPaths.INVENTORY_PAGE, {
        template: require('./layout.html'),
        css: require('./sections/concentrates/styles.scss'),
        controller: 'InventoryController',
        resolve : {
            "activeSection" : ()=> null
        }
    })
    .when(UrlPaths.INVENTORY_CONCENTRATES, {
        template: require('./layout.html'),
        css: require('./sections/concentrates/styles.scss'),
        controller: 'InventoryController',
        resolve : {
            "activeSection" : function() { return InventorySections.CONCENTRATES; }
        }
    })
    .when(UrlPaths.INVENTORY_CONCENTRATE_DETAILS, {
        template: require('./sections/concentrates/view-concentrate/layout.html'),
        css: require('./sections/concentrates/view-concentrate/styles.scss'),
        controller: 'ConcentrateDetailsController',
    })
    .when(UrlPaths.INVENTORY_WELLS, {
        template: require('./layout.html'),
        css: require('./sections/sp-wells/sp-wells.scss'),
        controller: 'InventoryController',
        resolve : {
            "activeSection" : function() { return InventorySections.WELLS; }
        }
    })
    .when(UrlPaths.INVENTORY_WELL_DETAILS, {
        template: require('./sections/sp-wells/sp-well-board-details/layout.html'),
        css: require('./sections/sp-wells/sp-well-board-details/styles.scss'),
        controller: 'BoardDetailsController'
    })
    .when(UrlPaths.INVENTORY_TESTING, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: 'InventoryController',
        resolve : {
            "activeSection" : () => InventorySections.TESTING
        }
    })
    .when(UrlPaths.INVENTORY_TESTING_DETAILS, {
        template: require('./widgets/inv-board-details/layout.html'),
        css: require('./widgets/inv-board-details/styles.scss'),
        controller: 'InventoryBoardDetailsController',
        resolve : {
            "activeSection" : () => InventorySections.TESTING,
            "sectionUrlPath" : ()=> UrlPaths.INVENTORY_TESTING,
            "subjectTypeAdjective" : ()=> "Testing"
        }
    })
    .when(UrlPaths.INVENTORY_MIXING, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: 'InventoryController',
        resolve : {
            "activeSection" : () => InventorySections.MIXING
        }
    })
    .when(UrlPaths.INVENTORY_MIXING_DETAILS, {
        template: require('./widgets/inv-board-details/layout.html'),
        css: require('./widgets/inv-board-details/styles.scss'),
        controller: 'InventoryBoardDetailsController',
        resolve : {
            "activeSection" : () => InventorySections.MIXING,
            "sectionUrlPath" : ()=> UrlPaths.INVENTORY_MIXING,
            "subjectTypeAdjective" : ()=> "Mixing"
        }
    })
    .when(UrlPaths.INVENTORY_VIALS, {
        template: require('./layout.html'),
        css: require('./sections/patient-vials/styles.scss'),
        controller: 'InventoryController',
        resolve : {
            "activeSection" : function() { return InventorySections.VIALS; }
        }
    });


}
routingConfig.$inject = ['$routeProvider', 'InventorySections', 'UrlPaths'];
