'use strict';

import angular from "angular";
import BaseController from '../../../base.controller'

export default class BaseSectionController extends BaseController {

    /**
     * @param {Angular.$injector} $injector
     * A provider for ng injector service impl.
     * @param {Angular.rootScope.Scope} $scope
     * The scope instance associate with each instance of this class's compiled results
     * @param {DashboardSections} instanceSectionType
     * An opaque identifier for which species of subclass the instance will be.
     */
    constructor($injector, $scope, instanceSectionType) {
        super($scope, $injector);
        this.$scope.ownSectionType = instanceSectionType;
        this.$scope.DashboardSections = $injector.get("DashboardSections");
    }

};
