'use strict';

import BaseService from './base.service.js';

export default class TestReviewService extends BaseService {

    /**
     * Approve this test review (as a Doctor).
     *
     * @param testReview existing TestReview DTO
     * @param note text note to add to the approval.
     * @param prescribeTreatment TreatmentType (DTO/enum) to prescribe.
     * @returns Promise to the updated TestReview DTO
     */
    approve(testReview, note, prescribeTreatment) {
        return this.serverAPI.put(testReview._links.approve, { note: note, treatment: prescribeTreatment });
    }
    
    /**
     * Get a TestReview from an IDT only test.
     *
     * @param idtTest get test review for this IDT only test
     * @returns Promise to the TestReview DTO, or nothing if no such test review exists
     */
    getByTest(idtTest) {
        return this.serverAPI.get(idtTest._links.testReview, null, null, [404]);
    }

    /**
     * Create a new TestReview from an IDT only test.
     *
     * @param idtTest the IDT only test to create the review from
     * @returns Promise to the created TestReview DTO
     */
    create(idtTest) {
        return this.serverAPI.post(idtTest._links.createTestReview);
    }
}
