'use strict';

import TestingController from '../testing.controller'

export default class IntradermalDetailsController extends TestingController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.concentrateService = $injector.get('concentrateService');

        this.allergyTestLoaded()
            .then(() => this._loadPanel())
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    async reload() {
        super.validateStage(['IDT_DETAILS']);

        this.$scope.idtDosage = this.testConfig.idtDosage;
        this.$scope.text = "Review Intradermal Details";
        this.initFooterNote('Intradermal Details');

        // This maps the antigens to the panel position to get the substances that were selected
        let idtAntigens = [];
        for(let ps of this.$scope.panel.substances) {
            let isAntigen = ps.substance._dto.category._dto._isAntigen;
            let isPositiveControl = ps.substance._dto.category._dto._isPositiveControl;

            // Ignore items not on the panel
            if (ps.idtPos < 0 && !isPositiveControl) {
                continue;
            }

            let testResult = isAntigen ?
                this.$scope.allergyTest.antigenResults.find(m => m.substance.id === ps.substance.id) :
                this.$scope.allergyTest.controlResults.find(m => m.substance.id === ps.substance.id);

            if (testResult && testResult.idtDilution === 0) {
                let vm = { name: ps.substance._dto.name, idtPos: ps.idtPos === -1 ? -100 : ps.idtPos };
                vm.concentrate = (await this.concentrateService.getInServiceSubstanceAtOffice(this.$scope.office, ps.substance)).list[0];
                idtAntigens.push(vm);
            }
        }

        this.$scope.idtAntigens = idtAntigens.sort((a,b) => a.idtPos - b.idtPos);
        this.$scope.leadingAllergyTest = await this.allergyTestService.getLeadingAllergyTest(this.$scope.allergyTest)

        this.userService.populateUserAction(this.$scope.leadingAllergyTest.approvedBy);
        this.userService.populateUserAction(this.$scope.leadingAllergyTest.performedBy);

        this.$scope.$digest();
    }

    /**
     * @override
     */
    next() {
        this.disableNext = true;
        this.advance();
    };
}
