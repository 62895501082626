'use strict';

import BaseMixingController from '../../base-mixing.controller'
import {ClassicalDilution,ClassicalDilutions} from "../../../../models/classical-dilutions";

export default class MixingReviewOrderController extends BaseMixingController {
    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        this.treatmentVialService = $injector.get('treatmentVialService');

        $scope.text = "Review Completed Prescription";
        $scope.nextButtonLabel = "Done";

        this.prescriptionLoaded([this.TAB_REVIEW])
            .then(() => Promise.all([this._loadOffices(), this._mixComplete()]))
            // Reprocess Prescription after completing mix...
            .then(() => super._processRxPartial(this.pscript))
            .then(() => super._processLoadedPrescription(this.pscript, null))
            .then(() => this._subscribePrescriptionUpdates())
            .then(() => super.loadUserActionNames())
            .then(() => super.loadPreviousMixes())
            .then(() => this._populateDisplay())
            .catch(ex => console.error(ex));
    }

    /**
     * What to do when the Next button is click in the wizard.
     * @override
     * @return Promise to updated Prescription
     */
    async nextStep() {
        /* Mixing complete - client data no longer needed */
        this.pscript.clientData = {};
        return this.saveAndExit();
    }

    /**
      * Subscribe to prescription updates in the event the prescription mix
      * is approved while viewing the review page
      */
    async _subscribePrescriptionUpdates() {
        this.notificationService.init()
            .then(() => {
                super.registerSubscription(this.notificationService.subscribeToPrescriptionUpdates(this.$scope.practice))
                    .then(null, null, (notification) => {
                        let pscript = notification.body;
                        if (this.pscript.id === pscript.id) {
                            this.disableNext = true;
                            this.$scope.nextButtonLabel = 'Reloading...';
                            super._processRxPartial(pscript)
                                .then(() => super._processLoadedPrescription(pscript, null))
                                .then(() => this._populateDisplay())
                                .then(() => {
                                    this.$scope.nextButtonLabel = 'Done';
                                    this.$scope.$digest();
                                });
                        }
                    });

                super.startAllSubscriptions();
            });
    }

    /*
     * Retreive all offices for the current practice, as well as the offices associated
     * with the current prescription
     */
    _loadOffices() {
        return this.officeService.getInPractice(this.$scope.practice).then((officeDto) => {
            this.$scope.offices = officeDto.list;
        });
    }

    /**
     * All data has loaded, so populate $scope now.
     * @private
     */
    async _populateDisplay() {
        this.$scope.mix = this.pscript;
        //this.initFooterNote('Review Order');

        let sourceOffice = this.$scope.offices.find(m => m.id === this.pscript.sourceOffice.id);
        if (sourceOffice) {
            this.pscript.sourceOffice = sourceOffice;
        }

        let office = this.$scope.offices.find(m => m.id === this.pscript.office.id);
        if (office) {
            this.pscript.office = office;
        }

        /*
         * Add _rxVial property to each TreatmentVial pointing back to its PrescribedVial
         */
        this.pscript.treatmentVials.forEach(tv => tv._rxVial = this.pscript.vials.find(pv => pv.barcode === tv.barcode));

        /*
         * Sort treatmentVials first by classicalDilution, and then by name.
         */
        this.pscript.treatmentVials.sort((a,b) => {
            if (a._rxVial.classicalDilution && b._rxVial.classicalDilution && a._rxVial.classicalDilution !== b._rxVial.classicalDilution) {
                return ClassicalDilution[a._rxVial.classicalDilution].order - ClassicalDilution[b._rxVial.classicalDilution].order;
            }
            else if (a.name < b.name)
                return -1;
            else if (a.name > b.name)
                return +1;
            else
                return 0;
        });

        /*
         * Add values to the all TreatmentVials: _isEscalation,  _name,  _barcodes
         * For classical vials, adds: _colors,  _isSeriesFirst
         */
        const haveFirstOfSeries = new Set();
        for (let treatmentVial of this.pscript.treatmentVials) {
            let pscriptVial = treatmentVial._rxVial;
            treatmentVial._isEscalation = this.prescriptionService.isEscalationVial(pscriptVial);

            if (this.isClassical) {
                if (!haveFirstOfSeries.has(pscriptVial.baseName)) {
                    haveFirstOfSeries.add(pscriptVial.baseName);
                    treatmentVial._isSeriesFirst = true;
                    const seriesVials = this.pscript.vials.filter(pv => pv.baseName === pscriptVial.baseName);
                    treatmentVial._name = pscriptVial.baseName + ' - ' + pscriptVial.mixNum;
                    treatmentVial._barcodes = seriesVials.map(pv => pv.barcode).join(', ');
                    treatmentVial._useByDates = seriesVials.map(pv => {
                        let tv = this.pscript.treatmentVials.find(m => m.barcode === pv.barcode);
                        return tv ? this.$filter('date')(tv.useBy, 'MM/dd/yyyy') : 'Unknown';
                    }).join(', ');
                    treatmentVial._beyondUseDates = seriesVials.map(pv => {
                        let tv = this.pscript.treatmentVials.find(m => m.barcode === pv.barcode);
                        return tv ? this.$filter('date')(tv.beyondUse, 'MM/dd/yyyy') : 'Unknown';
                    }).join(', ');
                    treatmentVial._colors = seriesVials.map(pv => {
                        const color = pv.classicalDilution.toLowerCase();
                        return color.substr(0,1).toUpperCase() + color.substring(1);//title case
                    }).join(', ');
                }
            }
            else {
                treatmentVial._name = treatmentVial.name;
                treatmentVial._barcodes = treatmentVial.barcode;
                treatmentVial._useByDates = this.$filter('date')(treatmentVial.useBy, 'MM/dd/yyyy');
                treatmentVial._beyondUseDates = this.$filter('date')(treatmentVial.beyondUse, 'MM/dd/yyyy');
            }

            let antigens = '';
            for (let pscriptSubst of pscriptVial.substances) {
                let name = undefined;

                if (pscriptSubst._category && pscriptSubst._category._isAntigen) {
                    name = pscriptSubst._substance.name;
                }
                else if (pscriptSubst.substanceTreatmentVial) {
                    let sourceVial = await this.treatmentVialService.get(pscriptSubst.substanceTreatmentVial);
                    name = sourceVial.name;
                }
                else if (pscriptSubst.substanceVialId) {
                    let maintVial = super.getVialById(pscriptSubst.substanceVialId);
                    name = "Vial " + maintVial.name;
                }

                if (name) {
                    if (antigens.length > 0)
                        antigens += ', ';
                    antigens += name;
                }
            }

            treatmentVial._antigens = antigens;
        }

        this.disableNext = false;
    }
}
