'use strict';

import TestingController from '../testing.controller'
import PrintTestReportModalController from '../../../widgets/testing/print-test-report-modal/controller';

export default class TestResultsController extends TestingController {

    static $inject = ['$scope', '$injector', 'boardService', 'panelService', 'substanceService', 'substanceCategoryService'];
    constructor($scope, $injector, boardService, panelService, substanceService, substanceCategoryService) {

        super($scope, $injector);

        this.boardService = boardService;
        this.panelService = panelService;
        this.substanceService = substanceService;
        this.substanceCategoryService = substanceCategoryService;
        this.treatmentConfigService = $injector.get('treatmentConfigService');

        this.$scope.text = 'Allergy Testing Results';
        this.$scope.printButtonLabel = 'Print';
        this.$scope.reportButtonLabel = 'View Test Report';
        this.$scope.nextButonLabel = 'Check Out Patient';

        $scope.consentFormModal = () => this.consentFormModal();

        $scope.select = (value) => {
            if (!this.$scope.abortedMessage || value === 'NONE')
                this.$scope.treatment = value;
        };

        $scope.print = () => this._printModal();
        $scope.report = () => this._showReportModal();

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }


    reload() {
        super.validateStage(['REVIEW']);

        this.$scope.treatment = this.$scope.allergyTest.treatmentType;
        this.disableButtons = true;

        let histamineState = this.$scope.allergyTest.histamineState;
        if (this.$scope.allergyTest.antigenResults.length && (histamineState.startsWith('PASSED') || this.$scope.idtOnly)) {
            // Load details about the Panel used in this test
            if (this.isClassical) {
                this._loadPanel().then((panel) => {
                    this.panel = panel;
                    this.populateResults();
                });
            }
            else {
                this.boardService.getWithNoVials(this.$scope.allergyTest.sptBoard).then(board => {
                    return this.panelService.get(board.panel);
                }).then((panel) => {
                    this.panel = panel;
                    this.populateResults();
                });
            }
        }
        else {
            // No results - test aborted
            this.$scope.abortedMessage = this.$scope.allergyTest.performedBy.note;
            this.disableButtons = false;
        }

        this.initFooterNote('Testing Results');

        // Are SCIT and SLIT treatments available? Only give options for what exist.
        this.treatmentConfigService.getAtPractice(this.$scope.practice).then(treatmentConfigs => {
            for (let config of treatmentConfigs.list) {
                if (config.treatmentType === 'SCIT')
                    this.$scope.isScitAvailable = true;
                else if (config.treatmentType === 'SLIT')
                    this.$scope.isSlitAvailable = !this.isClassical;
            }
        });
    }

    /**
     * @override
     */
    nextDisabled() {
        return this.disableButtons || !this.$scope.treatment;
    }

    /**
     * @override
     */
    next() {
        let treatmentType = this.$scope.treatment;
        if (treatmentType !== "NONE" && !this.$scope.patient.consentsGiven.includes(treatmentType)){
            this.consentFormModal(treatmentType);
        } else {
            this.submitPage();
        }

    }

    submitPage() {
        this.disableButtons = true;
        return super.next();
    }

    // Populate the results for the directive to show
    populateResults() {
        this.catIdToIdx = {};
        this.$scope.results = [];
        for (let cat of this.panel._categories.values()) {
            if (cat._isAntigen) {
                this.catIdToIdx[cat.id] = this.$scope.results.length;
                this.$scope.results.push({categoryName: cat.name, allergens: []});
            }
        }

        for (let antigenResult of this.$scope.allergyTest.antigenResults) {
            // Patient allergic to this substance?
            if (antigenResult.endPoint > 0) {
                let panelSubst = this.panel.substances.find(pS => pS.substance.id === antigenResult.substance.id);
                let substance = panelSubst.substance._dto;

                this.$scope.results[this.catIdToIdx[substance.category.id]].allergens.push(substance.name);
            }
        }

        this.disableButtons = false;
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        let noteModified = this.updateFooterNote();

        if (noteModified || this.$scope.allergyTest.treatmentType !== this.$scope.treatment) {
            this.$scope.allergyTest.treatmentType = this.$scope.treatment;
            return super.update();
        }
        else {
            return Promise.resolve(this.$scope.allergyTest);
        }
    }

    /*
     * Display a modal for choosing a test report to print
     */
    _printModal() {
        this.$uibModal.open({
            windowClass: 'printTestReport',
            template: require('../../../widgets/testing/print-test-report-modal/layout.html'),
            css: require('../../../widgets/testing/print-test-report-modal/styles.scss'),
            controller: PrintTestReportModalController,
            resolve: {
                allergyTest: () => {
                    return this.$scope.allergyTest;
                }
            }
        }).result
            .then((reportUrl) => {
                if (reportUrl) {
                    this.update().then(() => {
                        var hiddenLink = document.createElement('a');
                        hiddenLink.href = reportUrl;
                        hiddenLink.target = '_blank';
                        hiddenLink.click();
                    });
                }
            });
    }

    /**
     * Display the test approval report in a modal.
     * @private
     */
    async _showReportModal() {
        // Save any changes first, as the modal loads from server.
        await this.update();
        this.$scope.viewingTestReport = true;

        // Open modal that is very much like test approval.
        this.$uibModal.open({
            size: 'full',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                allergyTestHref: () => this.$scope.allergyTest.href
            },
            template: `
                <div>
                    <ag-test-approval allergy-test-href="allergyTestHref" on-exit-callback="close(allergyTest)" 
                                      is-test-wizard="true" is-approval="false"></ag-test-approval>
                </div>`,
            controller: function($scope, $uibModalInstance, allergyTestHref) {
                $scope.allergyTestHref = allergyTestHref;
                $scope.close = (allergyTest) => $uibModalInstance.close(allergyTest);
            }
        }).result
            .then(allergyTest => {
                this.$scope.viewingTestReport = false;

                // If new value passed back, it's an updated AllergyTest
                if (allergyTest) {
                    this.$scope.allergyTest = allergyTest;
                    this.reload();
                }
            });
    }

    /**
     * Display a model for getting the consent form signed.
     */
    consentFormModal(treatmentType) {
        this.$uibModal.open({
            backdrop: 'static',
            keyboard: false,
            windowClass: 'patientConsent',
            scope: this.$scope, //passed current scope to the modal
            template: require('./patient-consent-modal.html'),
            css: require('./patient-consent-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.checkConsent = (consented) => $uibModalInstance.close(consented);
                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        }).result
            .then((consented) => {
                if (consented) {
                    this.$scope.patient.consentsGiven.push(treatmentType);
                    this.patientService.update(this.$scope.patient)
                        .then(patient => {
                            this.patient = this.$scope.patient = patient;
                            this.submitPage();
                        });
                }
                else {
                    this.submitPage();
                }
            });
    }
}
