"use strict";

import angular from 'angular';

/**
 * This module contains the general purpose board-list widget. It will provide parts needed for concrete implementations
 * in inventory sections.
 *
 * The working title is "BaseBoardList", the impls will all live within pages.inventory.sections likely incorporating the
 * name of the section into its name. For the prefix, I'll go with "inv-".
 */
export default angular.module('pages.inventory.widgets.inv-board-list', [])
    .directive('invBoardList', [invBoardList])
    .directive("invBoardTable", [invBoardTable])
    .name;

function invBoardList() {
    return {
        restrict: "E",

        scope : {

            /** @type{function({BoardViewModel})} */
            boardPickHandler : "&",

            /** @type{function()} */
            createBoardHandler : "&",

            /** @type{String} text applied (as TextContent, not HTML) as the UI page's title */
            uiTitle : "@",

            /**  The view-model's state. */
            rowCollection: "="
        },

        template: require('./inv-board-list.html'),

        css: require('./styles.scss'),

        link: function ($scope) {

            $scope.onUiAddBoard =()=> $scope.createBoardHandler();

            $scope.onRowClick =(row)=> $scope.boardPickHandler({board : row});
        }
    };
}

function invBoardTable() {
    return {
        restrict : "E",
        scope : {
            showFilter: "=",
            rowCollection: "=",
            rowPickHandler : "&",
            boardTitle : "@"
        },
        template : require("./inv-board-table.html"),
        link : function($scope, element, attrs) {

            $scope.onRowClick =(argRow)=> $scope.rowPickHandler({row : argRow });

            $scope.printDate = new Date();

        }
    };
}