'use strict';

import angular from 'angular';
import dilutionFilter from "../../../../common/filters/dilution";
import MixApprovalController from "./mix-approval.controller";

export default angular.module('pages.dashboard.sections.approvals.mix', [dilutionFilter])
    .controller('MixApprovalController', MixApprovalController)
    .directive('agMixApproval', agMixApproval)
    .directive('amxPatientInfo', [amxPatientInfo])
    .directive('amxVialTable', ['$timeout', amxVialTable])
    .name;

// Directive to wrap mix review / approval
function agMixApproval() {
    return {
        template: require('./mix-approve.html'),
        css: require('./styles.scss'),
        controller: 'MixApprovalController',
        scope: {
            mixHref: '=',
            isApproval: '<',
            /** @type{function()} called upon form submission. */
            onExitCallback: '&?',
        },
        link: function(scope, element, attrs) {
        }
    }
}

// Directive for the template that contains the patient info section of the approve mix page
function amxPatientInfo() {
    return {
        template: require('./widgets/amx-patient-info.html'),
        css: require('./widgets/amx-patient-info.scss')
    };
}

// Directive that contains the vial table(s).
function amxVialTable($timeout) {
    return {
        template: require('./widgets/amx-vial-table.html'),
        css: require('./widgets/amx-vial-table.scss'),
        scope: {
            treatmentType: '=',
            prescribedVials: '=',
            /** @type{boolean} Is this a classical process prescription? */
            isClassical: "=",
        },
        link: function (scope, element, attrs) {
        }
    };
}
