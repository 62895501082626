
import angular from 'angular';
import ngRoute from 'angular-route';

import TestIdtCheckListController from './idt-checklist.controller.js';

export default angular.module('pages.testing.idtCheckList', [ngRoute])
    .config(config)
    .controller('TestIdtCheckListController',TestIdtCheckListController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/idtCheckList', {
        template: require('./idt-checklist.html'),
        css: require('./idt-checklist.scss'),
        controller: 'TestIdtCheckListController'
    });
}

config.$inject = ['$routeProvider'];
