"use strict";

export default angular.module("models.treatmentVialReasonCode", [])
    .constant("TreatmentVialReasonCode", {
        PASS : "PASS",
        DILUTE : "DILUTE",
        PROCEED : "PROCEED"
    })
    .filter("treatmentVialReasonCodeFilter", treatmentVialReasonCodeFilter)
    .name;

function treatmentVialReasonCodeFilter() {

    return (type)=> {
        let displayName = type;

        switch (type) {
        case "PASS" :
            displayName = "Pass";
            break;
        case "DILUTE" :
            displayName = "Dilute";
            break;
        case "PROCEED" :
            displayName = "Proceed";
            break;
        default :
            break;
        };

        return displayName;
    };
}