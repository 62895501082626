"use strict";

import angular from 'angular';
import controller from "./controller";
import PhoneContext from "../../../../models/phone-context";
import Procedure from "../../../../models/procedure";

export default angular.module('pages.dashboard.sections.ordered-not-tested', [
        PhoneContext, Procedure
    ])
    .controller('OrderedNotTestedSectionController', controller)
    .directive('stOrderedNotTested', [stOrderedNotTested])
    .name;

function stOrderedNotTested(){
    return {
        scope:{
            rowCollection: '=',
            rowPickHandler : '&',
            checkInHandler : '&',
            cancelAppointmentHandler : '&',
            showFilter: "=",
            loading: "="
        },
        template: require('./widgets/st-ordered-not-tested-list.html'),
        css: require('./widgets/st-ordered-not-tested-list.scss'),
        link: function ($scope, element, attrs) {
            $scope.onRowClick = (row)=> $scope.rowPickHandler({argRow: row});
            $scope.onPatientCheckIn = (row) => $scope.checkInHandler({argRow: row});
            $scope.onCancelAppointment = (row) => $scope.cancelAppointmentHandler({argRow: row});
            $scope.printDate = new Date();
        }
    };
}
