'use strict';

import BaseService from './base.service.js';

export default class BoardService extends BaseService {

    /** Filter value to retrieve full history of all vials associated with the board */
    ALL_VIALS = "ALL_VIALS";
    /** Filter value to retrieve only the vials currently, or last, on the board */
    CURRENT_VIALS = "CURRENT_VIALS";
    /** Filter value to retrieve only data about the board itself, no vials */
    NO_VIALS = "NO_VIALS";

    static $inject = ['serverAPI', '$injector'];

    _boardCache = {}

    constructor(serverAPI, $injector) {
        super(serverAPI);
        this.$http = $injector.get('$http');
        this.substanceService = $injector.get('substanceService');
        this.panelService = $injector.get('panelService');
    }

    /**
     * Get a Board by its ReferenceDTO with only data about the board itself, no vials.
     *
     * @param ref ReferenceDTO to a DTO type
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     * @override
     */
    getWithNoVials(ref) {
        return this._getBoardResource(this._stripVersion(ref.href), {filter:this.NO_VIALS});
    }

    /**
     * Get a Board by its ReferenceDTO with data about the board itself, and all associated vials.
     *
     * @param ref ReferenceDTO to a DTO type
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     * @override
     */
    getWithAllVials(ref) {
        return this._getBoardResource(this._stripVersion(ref.href), {filter:this.ALL_VIALS});
    }

    /**
     * Get a Board by its ReferenceDTO with only the vials currently on the board.
     *
     * @param ref ReferenceDTO to a DTO type
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     * @override
     */
    getWithCurrentVials(ref) {
        return this._getBoardResource(this._stripVersion(ref.href), {filter:this.CURRENT_VIALS});
    }

    /**
     * Get a Board by its ReferenceDTO with only the vials currently on the board.
     *
     * @param ref ReferenceDTO to a DTO type
     * @param referencingDTO the DTO containing the reference (and possibly the embedded value)
     * @param uncached {boolean} if true, bypass the browser's cache.
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     * @override
     */
    getTraditionalTrayVials(prescription) {
        return this.serverAPI.get(prescription._links.traditionalTrayVials);
    }

    /**
     * Create a new Board at an Office.
     *
     * @param office to add the Board to
     * @param board new Board DTO
     * @param concentrates Array of ReferenceDTO<Concentrate> to use to populate the board.
     * @returns Promise to the created Board DTO, or a ServerError where first error string is a message, and rest are a list.
     */
    create(office, board, concentrates) {
        board.office = {id : office.id};
        const req = {
            board: board,
            concentrates: concentrates
        };
        return this.serverAPI.post(office._links.boards, {}, req, [400, 409]);
    }

    /**
     * Update a Board.
     *
     * @param dto modified DTO
     * @param filter (optional) Which vials to return. See member values on this class.
     *              This is an optimization to reduce the potentially large response size when full details aren't needed.
     * @return Promise that will resolve to an updated DTO, or reject with a ServerError.
     */
    update(dto, filter) {
        return this.serverAPI.put(dto.href, {filter:filter}, dto)
            .then(dto => this.transform(dto));
    }

    /**
     * Replace a TrayVial with a new one, filled from the current in-service Concentrate.
     *
     * @param board to operate on
     * @param trayVial the existing TrayVial to replace
     * @param newStatus new ServiceStatus for the old trayVial
     * @param sourceVialId entity ID of Concentrate or TrayVial to use as the source to create the new vial
     * @param filter (optional/default=ALL_VIALS) Which vials to return. See member values on this class.
     *   This is an optimization to reduce the potentially large response size when full details aren't needed.
     * @return updated Board,
     */
    replaceVial(board, trayVial, newStatus, sourceVialId, filter) {
        let params = {
            filter: filter,
            boardColumn: trayVial.boardColumn,
            dilution: trayVial.dilution,
            status: newStatus,
            sourceVialId: sourceVialId
        };

        return this.serverAPI.put(board._links.replaceVial, params)
            .then(dto => this.transform(dto));
    }

    /**
     * Get Boards at an Office
     *
     * @param office DTO
     * @param procedure (optional) Only Boards for this Procedure
     * @param status (optional) Only Boards with this ServiceStatus
     * @param filter (optional) Which vials to return. See member values on this class.
     *              This is an optimization to reduce the potentially large response size when full details aren't needed.
     * @return Promise to a Board.List
     */
    getAtOffice(office, procedure, status, filter) {
        return this._getBoardResource(office._links.boards, {procedure:procedure, status:status, filter:filter});
    }

    /**
     * Get Boards at a Practice (any Office).
     *
     * @param practice DTO
     * @param procedure (optional) Only Boards for this Procedure
     * @param status (optional) Only Boards with this ServiceStatus
     * @param filter (optional) Which vials to return. See member values on this class.
     *              This is an optimization to reduce the potentially large response size when full details aren't needed.
     * @return Promise to a Board.List
     */
    getAtPractice(practice, procedure, status, filter) {
        return this._getBoardResource(practice._links.boards, {procedure:procedure, status:status, filter:filter});
    }

    /**
     * Find the boards valid to be barcode scanned in the Testing or Mixing wizard.
     *
     * @param officeOrPractice {Office} or {Practice} the board must be at. (Yes, either context works!)
     * @param panel panel that the board must be part of
     * @param procedure procedure of the wizard (Procedure.TESTING or Procedure.TESTING)
     */
    getAvailableInPanel(officeOrPractice, panel, procedure) {
        return this.serverAPI.get(officeOrPractice._links.boards, {procedure:procedure, panel: panel.id, status:'IN_SERVICE', filter:'NO_VIALS'});
    }

    /**
     * @param {BoardDataModel} board
     * @param {Integer} column
     * @param {Integer} dilution
     * @returns {TrayVialDataModel} the newest TrayVial satisfying these criteria :
     *   the vial is assigned to *board* operand's column id'd by operand *column*
     *   the vial's chemical payload has been diluted to the level id'd by operand *dilution*
     */
    getCurrentVial(board, substance, dilution) {
        for (let aVial of board._currentVials)
          if (aVial.substance.id === substance.id && aVial.dilution === dilution)
              return aVial;

        return undefined;
    }

    /**
     * Check to see of the board has been used in an AllergyTest or Prescription.
     *
     * @param board a Board DTO
     * @returns {Promise} to boolean true if the board has been used, or false if not.
     */
    isUsed(board) {
        return this.serverAPI.get(board._links.isUsed);
    }

    /**
     * Find a tray vial with a given barcode at the practice
     *
     * @param barcode a barcode to search for a matching tray vial for
     * @returns {Promise} to a tray vial if one exists, 404 if not.
     */
    getTrayVialByBarcode(practice, barcode) {
        return this.serverAPI.get(practice._links.findTrayVialByBarcode, { barcode: barcode });
    }

    /**
     * Find a board with a given tray vial on it at the practice
     *
     * @param trayVial a TrayVial DTO that you need the board for
     * @returns {Promise} to a Board if one exists, 404 if not.
     */
    getByTrayVial(practice, trayVial) {
        return this.serverAPI.get(practice._links.findBoardByTrayVial, { trayVialId: trayVial.id });
    }

    getOutOfServiceSubstances(board) {
        let result = [];
        for (let trayVial of board._currentVials) {
            if (trayVial.status !== 'IN_SERVICE') {
                trayVial.substance._dto._trayVialStatus = trayVial.status;
                if (result.indexOf(trayVial.substance._dto) === -1) {
                    result.push(trayVial.substance._dto);
                }
            }
        }
        return result;
    }

    /**
     * Get list of distinct substance names that are not in service on the board
     */
    getOutOfServiceSubstanceNames(board) {
        let result = [];
        for(let trayVial of board._currentVials) {
            if (trayVial.status !== 'IN_SERVICE') {
                let substanceName = trayVial.substance._dto.name;
                if (result.indexOf(substanceName) === -1) {
                    result.push(substanceName);
                }
            }
        }
        return result;
    }

    /**
     * Transform a DTO from the server to inline embedded data in useful ways.
     *
     * Result is a Board DTO with:
     *
     * - A '_trayColumn' field added to each TrayVial indicating the vial's 0-based column on the tray.
     * - A '_trayRow' field added to each TrayVial indicating the vial's 0-based row on the tray. (It's just dilution - 1.)
     * - Each ReferenceDTO<Substance> field containing the referenced data in the member field called _dto
     * - A '_currentVials' array on the Board with a copy of each TrayVial where current == true
     *
     * @override
     * @param dto a loaded Panel
     * @return Promise to modified dto
     */
    transform(dto) {
        var deferred = this.serverAPI.$q.defer();

        // Confirm it's a Board DTO and has vials - could be a ServerError
        if (!dto.vials || !dto.vials.length) {
            deferred.resolve(dto);
        }
        else {
            let board = dto;
            let isTraditionalArrangement = board.arrangement === 'TRADITIONAL';
            let substancePromiseMap = new Map();

            let panelPromise = this.resolved();
            if (isTraditionalArrangement) {
                panelPromise = this.panelService.get(board.panel).then((panel) => board._panel = panel);
            }

            panelPromise.then(() => {
                board._currentVials = [];
                return Promise.all(
                    board.vials.map(vial => {
                        vial._trayColumn = vial.boardColumn % board.substancesPerTray;
                        vial._trayRow = vial.dilution - 1;

                        if (vial.current) {
                            board._currentVials.push(vial);
                        }

                        let substancePromise = substancePromiseMap.get(vial.substance.id);
                        if (!substancePromise) {
                            substancePromise = this.substanceService.get(vial.substance, board);
                            substancePromiseMap.set(vial.substance.id, substancePromise);
                        }

                        // Populate Substance DTO
                        return substancePromise.then(subst => {
                            vial.substance._dto = subst;
                            return subst; //resolved
                        });
                    })
                )
            })
            .then(() => deferred.resolve(board))
            .catch(obj => deferred.reject(obj));
        }

        return deferred.promise;
    }

    _getBoardResource(url, params) {
        let request = {
            method: 'GET',
            url: url,
            params: params,
            headers: {},
            acceptedStatusCodes: [304]
        }

        let cachedBoard = this._getFromCache(url, params);
        if (cachedBoard) {
            request.headers['If-None-Match'] = cachedBoard.eTag;
        }

        return this.$http(request)
            .then(response => {
                let body = response.data;
                let headers = response.headers();
                if (headers.etag) {
                    body.eTag = headers.etag;
                    this._addToCache(url, params, body);
                }

                return this.serverAPI.$q.resolve(this.transform(body));
            }).catch(rejection => {
                if (rejection.status === 304) {
                    cachedBoard = this._getFromCache(url, params, request.headers['If-None-Match']);
                    if (cachedBoard) {
                        return this.serverAPI.$q.resolve(cachedBoard);
                    }
                }

                return this.serverAPI.$q.reject(rejection);
            });
    }

    _addToCache(url, params, resource) {
        let fullUrl = this.serverAPI.buildUrl(url, params);
        this._boardCache[fullUrl] = resource;
    }

    _getFromCache(url, params, eTag) {
        let fullUrl = this.serverAPI.buildUrl(url, params);
        let cachedBoard = this._boardCache[fullUrl];
        if (cachedBoard) {
            if (!eTag) {
                return cachedBoard;
            }
            else if (eTag === cachedBoard.eTag) {
                return cachedBoard;
            }
        }

        return undefined;
    }
}
