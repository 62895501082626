'use strict';

/**
 * View Model a row in the Users table.
 */
export class UserRow {

    /**
     * {ReferenceDTO<User>}
     * Reference to the User. Used by controller to reload and modify user.
     * The User may change by other means, thus we must reload before any modification.
     */
    userRef;

    /** {string} User's family (last) name */
    familyName = '';

    /** {string} User's given (first) name */
    givenName = '';

    /** {string} The user's access roles */
    roles = '';

    /** {string} The user's email address */
    email = '';

    /** {UserAccess} The user's access level */
    access = '';

    /** {Date} if non-null, or when the user account will unlock. */
    lockedUntil = null;

    /** Reference back to the owning controller */
    _controller;

    /**
     * Is the account currently locked?
     * @returns {boolean}
     */
    isLocked() {
        return this.lockedUntil ? (this.lockedUntil.getTime() > Date.now()) : false;
    }

    /**
     * Disable this user.
     */
    disableUser() {
        this._controller.disableUser(this.userRef);
    }

    /**
     * Enable this user.
     */
    enableUser() {
        this._controller.enableUser(this.userRef);
    }

    /**
     * Send this user a password reset e-mail.
     */
    resetPassword() {
        this._controller.resetPassword(this.userRef);
    }

    constructor(controller, user) {
        this._controller = controller;
        this.reset(user);
    }

    /**
     * Reset the row content. Replacing the entire instance doesn't always update SmartTable,
     * but this does.
     * @param user
     */
    reset(user) {
        this.userRef = {id: user.id, href: user.href};
        this.familyName = user.person.familyName;
        this.givenName = user.person.givenName;
        this.roles = user.roles.sort().join('\n');
        this.email = user.email;
        this.access = user.access;
        this.lockedUntil = user.lockedUntil ? new Date(user.lockedUntil) : null;
    }
}


/**
 * View Model for the Users page.
 */
export class UsersTable {
    /** {Array<UserRow>} Rows for the Users table */
    rowCollection = [];

    /** {Array<UserRow>} Displayed (after filter/sort) Rows for the Users table */
    displayedCollection = [];

    constructor() {
    }
}
