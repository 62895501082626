"use strict";

import BaseController from "../../../base.controller";
import { ClassicalDilutions, toPascalCase } from '../../../../models/classical-dilutions';

import React from 'react'
import { submitPrintJob } from '../../../../react/print/Printer'
import { PRINTER_DYMO30336_PORTRAIT, SingleDilutionLabel } from "../../../../react/print/Dymo30336Portrait"
import { TrayLabel, LandscapeToPortrait } from "../../../../react/print/Dymo30336Landscape"

export default class CreateInventoryBoardModal extends BaseController {

    /**
     * @protected
     * @type {services.BoardService}
     *
     * Furnishes subject Board data models.
     */
    _boardService = undefined;

    /**
     * @protected
     * @type {Map.<Panel-model>}
     */
    _eligiblePanelMap = undefined;

    /**
     * @protected
     * @type {pages.inventory.eligible-status.service}
     *
     * Inventory package based service which abstracts the selection of ServiceStatus values appropriate
     * for board resources.
     */
    _eligibleStatusService = undefined;

    /**
     * @protected
     * @type {services.PanelService}
     *
     * Furnishes subject Board's subordinate Panel data models.
     */
    _panelService = undefined;

    /**
     * @private
     * @type {services.ConcentrateService}
     *
     * Source of Concentrate data.
     */
    _concentrateService = undefined;

    /**
     * @protected
     * @type {models.Procedure}
     *
     * A value living inside models.Procedure. Each use-case board-"flavor" ( SP Wells, Testing, Mixing ) has an
     * associated models.Procedure value. Instances of this impl will receive this value as an initialization parameter.
     * This value should used for the "procedure" fields of models during board-service POST and PUT mutations.
     */
    _procedureType = undefined;

    /**
     * @type {String}
     *
     * This URL-Path fragment will be used in the addresses leading the Details-UI for boards created by this impl.
     * We can't treat this as a constant because the appropriate value varies with the use-case (the type of Board this
     * instance is handling).
     */
    _detailsUrlPath = undefined;

    /**
     * @type {Array<Concentrate>}
     * @private
     *
     * Concentrates available for use.
     */
    _availableConcentrates = [];

    /**
     * @protected
     * @type {bootstrap.uiModal}
     *
     * Supports the modal popup behavior for the UI.
     */
    _$uibModalInstance = undefined;

    /**
     * Field in Panel.OrderedSubstance that defines the order for this type of board.
     */
    _procedureSubstanceField;

    static $inject =
    /*DI-list*/["$uibModalInstance","$scope","$filter","$injector","procedureType","detailsUrlPath","Procedure"];
    constructor( $uibModalInstance , $scope , $filter , $injector , procedureType , detailsUrlPath , Procedure) {
        super($scope, $injector);

        $scope.ServiceStatus = this.ServiceStatus = $injector.get("ServiceStatus");
        $scope.cancel = () => $uibModalInstance.dismiss();
        $scope.createBoard = () => this._createBoard();
        $scope.onBoardNameChange = () => this._onBoardNameChange();
        $scope.onPanelChange = (newPanel) => this._onPanelChange(newPanel);
        $scope.toBarcodeList = (concentrates) => this._toBarcodeList(concentrates);
        $scope.scanBarcode = () => this._scanBarcode();
        $scope.scanBarcodeKeydown = (event) => this._scanBarcodeKeydown(event);
        $scope.setArrangement = (arrangement) => this._setArrangement(arrangement);
        $scope.isValidArrangement = () => this._isValidArrangement();
        $scope.canSave = () => this._canSave();

        this._$uibModalInstance = $uibModalInstance;
        this._allergyTestConfigService = $injector.get('allergyTestConfigService');
        this._boardService = $injector.get('boardService');
        this._globalConfigService = $injector.get('globalConfigService');
        this._panelService = $injector.get('panelService');
        this._substanceService = $injector.get('substanceService');
        this._concentrateService = $injector.get('concentrateService');
        this._eligibleStatusService = $injector.get('eligibleStatusService');
        this._$q = $injector.get('$q');
        this._BoardArrangement = $injector.get('BoardArrangement');
        this._procedureType = procedureType;
        this._detailsUrlPath = detailsUrlPath;
        this._$filter = $filter;
        this._Procedure = Procedure;
        /** @type{String} UI-metadata; not part of DM */
        this.$scope.submissionFailureMessage = undefined;
        /** @type{Boolean} UI-metadata; not part of DM */
        this.$scope.isSubmissionFailure = false;
        /** @type{Array<{substanceId,substanceName,selected[]}>} */
        this.$scope.concentrates = [];
        /** @type{Boolean} All concentrate sources set */
        this.$scope.areConcentratesSelected = false;

        switch (this._procedureType) {
            case Procedure.SPT:
                $scope.boardType = 'SP Wells';
                this._procedureSubstanceField = 'sptPos';
                break;
            case Procedure.IDT:
                $scope.boardType = 'Testing';
                this._procedureSubstanceField = 'idtPos';
                break;
            case Procedure.MIXING:
                $scope.boardType = 'Mixing';
                this._procedureSubstanceField = 'mixPos';
                break;
            default:
                console.log('Unexpected procedureType: ' + this._procedureType);
                $scope.boardType = '';
                break;
        }

        this._initState();
    }

    _initState() {
        this.$scope.showArrangement = false;
        this.$scope.selectedArrangement = this._BoardArrangement.STANDARD;

        let hasClassicalConfig = false;
        let hasOtolaryngicConfig = false;
        this._allergyTestConfigService.haveClassicalTests(this.$scope.practice)
            .then((doesHaveClassicalConfig) => {
                hasClassicalConfig = doesHaveClassicalConfig;
                return this._allergyTestConfigService.haveOtolaryngicTests(this.$scope.practice);
            }).then((doesHaveOtolaryngicConfig) => {

                hasOtolaryngicConfig = doesHaveOtolaryngicConfig;

                if (hasClassicalConfig && hasOtolaryngicConfig && this._procedureType === this._Procedure.MIXING) {
                    this.$scope.showArrangement = true;
                }
                else if (hasOtolaryngicConfig) {
                    this.$scope.selectedArrangement = this._BoardArrangement.OTOLARYNGIC;
                }
                else if (hasClassicalConfig) {
                    this.$scope.selectedArrangement = this._BoardArrangement.TRADITIONAL;
                }
            });

        this._initServiceStatus();
        this._initAvailableConcentrates().then(() => {
            this._initPanelValues();
        });

        return this._globalConfigService.get().then((config) => {
            this._allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    _isRelevantPanel(panel) {
        return panel.active &&
            ((this._procedureType === this._Procedure.MIXING && panel.type === 'MIXING') ||
            (this._procedureType === this._Procedure.SPT && panel.type === 'TESTING') ||
            (this._procedureType === this._Procedure.IDT && panel.type === 'TESTING'));
    }

    _initPanelValues() {

        this._panelService.getActiveAtPractice(this.$scope.practice).then(panels => {

            this.$scope.eligiblePanels = [];
            this._eligiblePanelMap = new Map();

            for (let aPanel of panels.list) {
                if (this._isRelevantPanel(aPanel)) {
                    this.$scope.eligiblePanels.push(aPanel);
                    this._eligiblePanelMap.set(aPanel.id, aPanel);
                }
            }

            if (this.$scope.eligiblePanels.length === 1) {
                this.$scope.onPanelChange(this.$scope.eligiblePanels[0]);
            }

        });
    }

    _initAvailableConcentrates() {
        return this._concentrateService.getInServiceAtOffice(this.$scope.office).then(concentrates => {
            this._availableConcentrates = concentrates.list;
        });
    }

    _initServiceStatus() {
        this._eligibleStatusService.getEligibleStatusTypesForNewBoard().then(eligibleStatusValues => {
            this.$scope.eligibleStatusTypes = eligibleStatusValues;
        });
    }

    _prepBarcodeScan() {
        if (this.$scope.boardName && this.$scope.selectedPanel && this.$scope.selectedArrangement) {
            setTimeout(() => {
                $('#concentrateBarcode').focus();
            }, 10);
        }
    }

    _onBoardNameChange() {
        this._prepBarcodeScan();
    }

    async _onPanelChange(newPanel) {
        this.$scope.selectedPanel = newPanel;
        this.$scope.createBoardForm.boardPanel.$setViewValue(newPanel);

        // Set/reset selected concentrates
        this.$scope.concentrates = [];

        for (const orderedSubstance of newPanel.substances) {
            const pos = orderedSubstance[this._procedureSubstanceField];
            if (pos >= 0) {
                const substance = await this._substanceService.get(orderedSubstance.substance);

                // Check if only one concentrate of this substance is available. If so, it'll be auto selected.
                const concOfSubstance = this._availableConcentrates.filter(c => c.substance.id === substance.id);

                this.$scope.concentrates.push({
                    sortOrder: pos,
                    substanceId: substance.id,
                    substanceName: substance.name,
                    selected: (concOfSubstance.length === 1) ? [concOfSubstance[0]] : []
                });   
            }
        }

        // Sort in defined order
        this.$scope.concentrates.sort((a,b) => a.sortOrder - b.sortOrder);
        this.$scope.areConcentratesSelected = this.$scope.concentrates.every(entry => entry.selected.length === 1);
        this._prepBarcodeScan();

        this.$scope.$digest();
    }

    _setArrangement(arrangement) {
        this.$scope.selectedArrangement = arrangement;
        this._prepBarcodeScan();
    }

    _isValidArrangement() {
        return this._procedureType !== this._Procedure.MIXING
            || (this.$scope.selectedArrangement && this.$scope.selectedArrangement !== this._BoardArrangement.STANDARD);
    }

    _canSave() {
        let scope = this.$scope;
        return !(scope.createBoardForm.$error.required
            || scope.isSubmissionFailure
            || !scope.areConcentratesSelected
            || scope.beenClicked
            || !this._isValidArrangement());
    }

    _printTrayLabels(data) {
        const labels = []
        var $filter = this._$filter;

        for(let vial of data.vials) {
            vial._antigen = vial.boardColumn + 1;
            if (data.arrangement === 'TRADITIONAL') {
                let panelSubstance = this.$scope.selectedPanel.substances.find(m => m.substance.id === vial.substance.id);
                switch (data.procedure) {
                    case 'SPT':
                        vial._antigen = panelSubstance.sptPos + 1;
                        break;
                    case 'IDT':
                        vial._antigen = panelSubstance.idtPos + 1;
                        break;
                    case 'MIXING':
                        vial._antigen = panelSubstance.mixPos + 1;
                        break;
                }
            }
        }

        let vials = $filter('orderBy')(data.vials, ['trayNum','_antigen','dilution']);

        for (var i = 0; i < data.trayCount; i++) {
            labels.push(
                <LandscapeToPortrait key={`tray-${i}`}>
                    <TrayLabel
                        barcode={data.barcode}
                        description={data.name}
                    />
                </LandscapeToPortrait>
            )
        }

        for (var j = 0; j < vials.length; j++) {
            let dilution = vials[j].dilution;
            if (data.arrangement === 'TRADITIONAL' && ClassicalDilutions[vials[j].dilution]) {
                dilution = toPascalCase(ClassicalDilutions[vials[j].dilution].color);
            }
            labels.push(
                <SingleDilutionLabel
                    key={`vial-${j}`}
                    barcode={vials[j].barcode}
                    description={vials[j].substance._dto.name}
                    dilution={dilution}
                />
            )
        }

        submitPrintJob(PRINTER_DYMO30336_PORTRAIT, labels)
    }

    _createBoard() {
        /* Set beenClicked to true, so the save button can not be clicked again */
        this.$scope.beenClicked = true;

        const concentrates = this.$scope.concentrates.map(c => {
            return { id: c.selected[0].id };
        });

        this._boardService.create(this.$scope.office, this._vmToDm(), concentrates)
            .then(genesisResponse => {
                this._boardService.getWithCurrentVials(genesisResponse).then(printResponse =>{
                    this._printTrayLabels(printResponse)
                    this._$uibModalInstance.close(genesisResponse)
                    this.routeToPage(this._detailsUrlPath, /** BoardDTO */genesisResponse)
                });
            })
            .catch(genesisFailure => {
                this.$scope.isSubmissionFailure = true;
                this.$scope.submissionFailureMessage = genesisFailure;
            });
    }

    _vmToDm() {
        let
            theForm = this.$scope.createBoardForm,
            dm = {};

        dm.name = theForm.boardName.$modelValue; // From UI
        dm.status = this.ServiceStatus.IN_SERVICE; // By business logic rules
        dm.procedure = this._procedureType; // Is part of impl identity
        dm.panel = this._panelVmToDm(); // Punting to child-object impl
        dm.arrangement = this.$scope.selectedArrangement;

        return dm;
    }

    _panelVmToDm() {
        let
            theForm = this.$scope.createBoardForm,
            dm = theForm.boardPanel.$modelValue;

        return dm;
    }

    _scanBarcodeKeydown(event) {
        if (event.which === 13) {
            event.preventDefault();
            this._scanBarcode();
        }
    }

    _scanBarcode() {
        const barcodeInput = this.$scope.barcodeInput;
        this.$scope.barcodeError = null;
        console.log("Barcode input '" + barcodeInput + "'");
        if (!barcodeInput) {
            // No input
            return;
        }

        // Auto-fill all selections for dev & QA
        if (barcodeInput === '=' && this._allowAutoBarcode) {
            for (let entry of this.$scope.concentrates) {
                const c = this._availableConcentrates.find(c => c.substance.id === entry.substanceId);
                if (c)
                    entry.selected = [c];
            }
        }
        else if (barcodeInput.length !== 8) {
            this.$scope.barcodeError = "Invalid barcode: " + barcodeInput;
        }
        else {
            // Find concentrate match
            const conc = this._availableConcentrates.find(c => c.barcode === barcodeInput);
            if (conc) {
                const entry = this.$scope.concentrates.find(item => item.substanceId === conc.substance.id);
                if (entry) {
                    const exists = entry.selected.find(c => c.id === conc.id);
                    if (!exists) {
                        // Add to selection
                        entry.selected.push(conc);
                    }
                    else {
                        // May be a tie breaker - make this now be the only selection
                        entry.selected = [conc];
                    }
                }
                else {
                    this.$scope.barcodeError = "Substance not in panel.";
                }
            }
            else {
                this.$scope.barcodeError = "Concentrate " + barcodeInput + " not available.";
            }
        }

        this.$scope.barcodeInput = '';
        this.$scope.areConcentratesSelected = this.$scope.concentrates.every(entry => entry.selected.length === 1);
    }

    /**
     * Return a comma-separated list of barcode numbers
     * @param concentrates array of Concentrate objects
     */
    _toBarcodeList(concentrates) {
        return concentrates.map(c => c.barcode).toString();
    }
}
