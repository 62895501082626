"use strict";

import BaseController from "../../base.controller";
// Models
import {ClassicalDilution,ClassicalDilutions} from "../../../models/classical-dilutions";
import PrescriptionReason from "../../../models/prescriptionReason";

/**
 * Usage and Workflow/Lifecycle notes::
 * See inventory/sections/patient-vials/controller's use case for an example. Instances of this class presume the
 * presence of spawning uibModalInstance (a Bootstrap ui.modal). Instances offer 2 data-state altering actions. Once
 * the user opts for a Vial-Replacement or End-Service action and submits the form, the appropriate API requests are
 * made and the spawning modal controller will dismiss the containing panel. At this point, the modal's *close* API
 * is invoked, returning active governance of the UI session to the caller. It is customary for the parent UI
 * controller to refresh its contents at this time.
 */
export default class TreatmentVialDetailsController extends BaseController {

    /**
     * @param {Bootstrap.UI.modal} $uibModalInstance
     *  The Bootstrap ModalUiController instance which serves this instance
     * @param {Angular.Scope} $scope
     * @param {Angular.$injectorServiceProvider} $injector
     *
     * @param patient : structural description follows::
     *  {{
     *      person : {{
     *          givenName : {String},
     *          familyName : {String}
     *      }},
     *      dayOfBirth : {Date},
     *      chartNum : {String}
     *  }}
     *
     * @param {ReferenceDto.<Vial>} vial
     *  The structure may contain any other fields, and they matter not. So long as reference fields are present, the
     *  operand will work.
     */
    constructor($uibModalInstance, $scope, $injector, patient, vial) {
        super($scope, $injector);
        this._initInjections($injector);

        // UI View Model
        $scope.patient = patient; // part of the ViewModel
        $scope.action = null;

        $scope.onOfficeChange = (newOffice) => { this.$scope.vial.office = newOffice; };

        this.officeService.getInPractice(this.$scope.practice)
            .then(officeList => {
                this.$scope.officeChoices = officeList.list;
                return this._createVialVm(vial);
            })
            .then(vialVm => {
                $scope.vial = vialVm;
                $scope.eligibleReasons = this._initReasons(vialVm);

                if($scope.eligibleReasons && $scope.eligibleReasons.length === 1) {
                    $scope.selectedReplacementCase = $scope.eligibleReasons[0];
                }
            });

        $scope.canEndService = (vial) => {
            if (vial) {
                const acceptableStatuses = [
                    this.ServiceStatus.IN_SERVICE,
                    this.ServiceStatus.INVENTORY,
                    this.ServiceStatus.EXPIRED
                ];
                // If vial's current status is in the list of acceptable statuses, allow ending of service
                return acceptableStatuses.indexOf(vial.status) !== -1;
            }
            return false;
        };

        $scope.canDeplete = (vial) => {
            if (vial) {
                const acceptableStatuses = [
                    this.ServiceStatus.EXPIRED
                ]
                // If vial's current status is in the list of acceptable statuses, allow ending of service
                return acceptableStatuses.indexOf(vial.status) !== -1;
            }
            return false;
        }

        // UI-DOM Event Reactions
        $scope.close = () => { $scope.selectedReplacementCase = ''; $uibModalInstance.dismiss(); };

        $scope.onSubmit =()=> this._handleUiSubmmission($scope).then(()=> $uibModalInstance.close());
    }

    _initInjections($injector) {
        this.ServiceStatus = $injector.get("ServiceStatus");
        this.PrescriptionReason = $injector.get("PrescriptionReason");
        this.treatmentVialService = $injector.get("treatmentVialService");
        this.prescriptionService = $injector.get("prescriptionService");
        this.prescriptionReasonFilter = $injector.get('prescriptionReasonFilterFilter');
    }

    /** @return {Promise.<VialViewModel>}*/
    _createVialVm(subjectVial) {
        let treatmentVialDm; //temporary storage
        let vialModel = {};

        return this.treatmentVialService.getUncached(subjectVial)
            .then(dto => {
                treatmentVialDm = dto;
                const VERBATIM_FIELDS = ["_links", "barcode", "useBy", "beyondUse", "status", "id", "href", "name"];
                VERBATIM_FIELDS.forEach(k => { vialModel[k] = treatmentVialDm[k];});
                vialModel.vialType = subjectVial.vialType || subjectVial.treatmentType;
                vialModel.isReplaceable = subjectVial.isReplaceable;
                return this.officeService.get(treatmentVialDm.office);
            })
            .then(office => {
                vialModel.office = office;
                return this.prescriptionService.get(treatmentVialDm.prescription);
            })
            .then(pscript => {
                // Check with prescription to determine if this is a SLIT Escalation vial
                let prescribedVial = pscript.vials.find(v => v.barcode === treatmentVialDm.barcode);
                vialModel.isDilution = prescribedVial && prescribedVial.substances.find(ps => ps.substanceVialId);
                vialModel.classicalDilution = prescribedVial.classicalDilution;

                return vialModel;
            });
    };


    /** @returns {Array.<{value: PrescriptionReason, display: string}>} */
    _initReasons(vialVm) {
        if (!vialVm.isReplaceable) {
            return [];
        }

        switch (vialVm.status) {
            case this.ServiceStatus.END_SERVICE: {
                return [];
            }

            case this.ServiceStatus.RECALLED: {
                const value = this.PrescriptionReason.RECALLED;
                return [{ value: value, display: this.prescriptionReasonFilter(value) }];
            }

            case this.ServiceStatus.EXPIRED: {
                const value = this.PrescriptionReason.EXPIRED;
                return [{ value: value, display: this.prescriptionReasonFilter(value) }];

            }

            case this.ServiceStatus.INVENTORY:
            case this.ServiceStatus.IN_SERVICE: {
                let reasons = [];

                if (vialVm.classicalDilution) {
                    const dilution = ClassicalDilution[vialVm.classicalDilution];
                    let vialDesc = `${this._getDilutionTitlecase(dilution.color)} (1:${dilution.dilution.toLocaleString()}) Vial`;

                    reasons.push(
                        {
                            value: this.PrescriptionReason.BROKEN,
                            display: `Repeat ${vialDesc} - Broken`
                        },
                        {
                            value: this.PrescriptionReason.LOST,
                            display: `Repeat ${vialDesc} - Lost`
                        },
                        {
                            value: this.PrescriptionReason.REPEAT,
                            display: `Repeat ${vialDesc}`
                        }
                    );

                    if (vialVm.classicalDilution !== ClassicalDilution.SILVER.color) {
                        const diluteTo =  ClassicalDilutions[dilution.order + 1];
                        const diluteToDesc = `${this._getDilutionTitlecase(diluteTo.color)} (1:${diluteTo.dilution.toLocaleString()}) Vial`;

                        reasons.push(
                            {
                                value: this.PrescriptionReason.DILUTE_VIAL,
                                display: `Dilute ${vialDesc} to make ${diluteToDesc}`
                            }
                        );
                    }

                    if (vialVm.classicalDilution !== ClassicalDilution.RED.color) {
                        const diluteTo =  ClassicalDilutions[dilution.order - 1];
                        const diluteToDesc = this._getDilutionTitlecase(diluteTo.color)
                            + ' (1:' + diluteTo.dilution.toLocaleString() + ') Vial';

                        reasons.push(
                            {
                                value: this.PrescriptionReason.ADVANCE,
                                display: `Vial Complete - Advance to ${diluteToDesc}`
                            }
                        );
                    }
                }
                else {
                    reasons.push(
                        {
                            value: this.PrescriptionReason.BROKEN,
                            display: this.prescriptionReasonFilter(this.PrescriptionReason.BROKEN)
                        },
                        {
                            value: this.PrescriptionReason.LOST,
                            display: this.prescriptionReasonFilter(this.PrescriptionReason.LOST)
                        }
                    );

                    if (!vialVm.isDilution) {
                        reasons.push(
                            {
                                value: this.PrescriptionReason.REPEAT,
                                display: this.prescriptionReasonFilter(this.PrescriptionReason.REPEAT)
                            },
                            {
                                value: this.PrescriptionReason.DILUTE_NEW,
                                display: this.prescriptionReasonFilter(this.PrescriptionReason.DILUTE_NEW)
                            },
                            {
                                value: this.PrescriptionReason.DILUTE_VIAL,
                                display: this.prescriptionReasonFilter(this.PrescriptionReason.DILUTE_VIAL)
                            },
                            {
                                value: this.PrescriptionReason.ADVANCE,
                                display: this.prescriptionReasonFilter(this.PrescriptionReason.ADVANCE)
                            }
                        );
                    }
                }
                return reasons;
            }
            default:
                console.warn("eligibleReasons := f(status) status value not recognized or supported.");
        }
    }

    /** @return {Promise.<{Void|Prescription}>} */
    _handleUiSubmmission(scope) {
        switch (scope.action) {
            case 'replace': {
                return this.prescriptionService.createToReplaceVial(scope.vial, scope.selectedReplacementCase.value);
            }
            case 'end': {
                return this.prescriptionService.createToReplaceVial(scope.vial, this.PrescriptionReason.NOMORE, this.ServiceStatus.END_SERVICE);
            }
            case 'deplete': {
                return this.prescriptionService.createToReplaceVial(scope.vial, this.PrescriptionReason.NOMORE, this.ServiceStatus.DEPLETED)
            }
            case 'move': {
                let newOffice = scope.vial.office;
                return this.treatmentVialService.getUncached(scope.vial)
                    .then(treatVial => {
                        if (treatVial.office.id === newOffice.id) {
                            return treatVial; // no change
                        }
                        else {
                            treatVial.office = {id: newOffice.id };
                            return this.treatmentVialService.update(treatVial);
                        }
                    });
            }
            default: {
                console.error("Unexpected action: " + scope.action);
                return Promise.resolve();
            }
        }
    }

    _getDilutionTitlecase(classicalDilution) {
        return classicalDilution[0] + classicalDilution.substr(1).toLowerCase();
    }
}
