"use strict";

import angular from "angular";
export default angular.module('pages.dashboard.sections.appointments.visit-type-filter', [])
	.directive("agDbVisitTypeFilter", ['Station', initView ])
	.name;

function initView(Station) {
	return {
		restrict: "E",
		css: require("./styles.scss"),
		template: require("./layout.html"),
		scope: {
			/**
			 * This is the logical scalar value of the instance.
			 */
			visitTypeShowing : "="
		},
		link: (scope, element, attr) => {
			scope.Station = Station;
		}
	};
}