'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentVialTestController from './vial-test.controller.js';

export default angular.module('pages.treatment.vial.test', [ngRoute])
    .config(config)
    .controller('TreatmentVialTestController', TreatmentVialTestController)
    .directive('vialTestDirective', ["$window", vialTestDirective])
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_VIAL_TEST, {
        template: require('./vial-test.html'),
        css: require('./vial-test.scss'),
        controller: 'TreatmentVialTestController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];

function vialTestDirective($window) {
    return {
        restrict : "A",
        scope: false,
        link: function(scope, elem, attrs) {
            scope.enteringBarcode = false;

            scope.$watch('barCode', function(newvalue, oldvalue){
                if(newvalue != undefined) {
                   if(newvalue === '=' && scope.allowAutoBarcode)
                       scope.barCode = scope.vial.barcode;
                   if(scope.barCode.length == 8){
                       if(scope.barCode === scope.vial.barcode) {
                           scope.validBarcode = true;
                       } else if(scope.barCodesInTreatment.includes(scope.barCode)){
                           scope.barcodeMismatchModal();
                           scope.validBarcode = false;
                       } else {
                           scope.barcodeModal();
                           scope.validBarcode = false;
                       }
                   } else {
                       scope.validBarcode = false;
                   }
                }
            });
        }
    };
}
