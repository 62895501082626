'use strict';

import BaseSectionController from '../../widgets/section-content-panel/controller';
import EditConcentrateModalController from '../../widgets/edit-concentrate-modal/controller';
import ClearInventoryAlertController from '../../widgets/clear-inventory-alert-modal/controller';

import React from 'react'
import { submitPrintJob } from '../../../../react/print/Printer'
import { PRINTER_DYMO30336_PORTRAIT, ConcentrateLabel } from '../../../../react/print/Dymo30336Portrait'

export default class ConcentratesSectionController extends BaseSectionController {

    static $inject = ['$injector', '$scope', '$filter', 'InventorySections'];

    constructor($injector, $scope, $filter, UiSectionTypes) {
        super($injector, $scope, UiSectionTypes.CONCENTRATES);

        // Establish own resources
        this._allergyTestConfigService = $injector.get('allergyTestConfigService');
        this._concentrateService = $injector.get('concentrateService');
        this._substanceService = $injector.get('substanceService');
        this._notificationType = $injector.get('NotificationType');
        this._$filter = $filter;

        // Establish stateful reactions
        $scope.onRowSelection = (aConcentrate) => this._gotoDetails(aConcentrate);
        $scope.addConcentrateModal = (mode) => this._addConcentrateModal(mode);
        $scope.clearInventoryAlertModal = (row) => this._clearInventoryAlertModal(row);

        // Subscribe to inventory alerts
        this.inventoryAlertService.subscribe(this.$scope, alertSummary => {
            if (angular.isArray(this.$scope.rowCollection)) {
                for (let row of this.$scope.rowCollection) {
                    if (!row._alertDismissed) {
                        row.alert = alertSummary.icons.get(row.id);
                    }
                }
            }
        });

        // Get this party started
        this.reload();
    }

    /**
     * This is called when the user selects a different Office.
     * The current office is a fundamental input in determining which sets of records are applicable.
     * Hence, reload everything!
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this.reload();
    }

    /**
     *
     * @param concentrateObject
     *  serialized data model of the concentrate list entry whose row the user selected
     * @private
     */
    _gotoDetails(concentrateObject) {
        this.routeToPage(
            this.urlPaths.INVENTORY_CONCENTRATE_DETAILS,
            concentrateObject,
            this.routingService.createLocationParams('Concentrate List')
        );
    }


    /**
     * Subscribe to be notified about changes to this offices' concentrate list.
     * @private
     */
    _subscribeToConcentrateNotifications() {
        this.registerSubscription(this.notificationService.subscribeAllConcentrateUpdates(this.$scope.practice))
            .then(null, null, (notification) => this._onNotificationReceived(notification));
    }

    /**
     * Update a concentrate that has been changed, or add a concentrate to the list of concentrates if it doesn't exist.
     * @param notification - a notification about a concentrate.
     * @private
     */
    async _onNotificationReceived(notification) {
        if (notification.type !== this._notificationType.Concentrate) {
            return;
        }

        let concentrate = notification.body;
        let isAtThisOffice = (concentrate.office.id === this.$scope.office.id);

        for (let index = 0; index < this.$scope.rowCollection.length; ++index) {
            if (this.$scope.rowCollection[index].id === concentrate.id) {
                // Found the concentrate replace it.
                this.$scope.rowCollection.splice(index, 1);
                if (isAtThisOffice) {
                    this.$scope.rowCollection.push(await this._buildConcentrate(concentrate));
                }

                this.$scope.$digest();
                return;
            }
        }

        // Not found in list. If for this office though, add it.
        if (isAtThisOffice) {
            this.$scope.rowCollection.push(await this._buildConcentrate(concentrate));
            this.$scope.$digest();
        }
    }

    /**
     * Logical state bootstrap and reset routine.
     */
    async reload() {

        await this.notificationService.init();
        this.unsubscribeAllSubscriptions();
        this._subscribeToConcentrateNotifications();

        this.$scope.rowCollection = [];

        /**
         * Concentrates used at this office.
         * @type {Object}
         */
        this.officeConcentrates = {};

        /**
         * Map.<String, {{ SubstanceObject }} > where the map key values match substance-object "id" field values.
         */
        this.substanceMap = new Map();

        /**
         * An object, we expect to contain a list field where:
         * {{ "list" : Array.< {{ ConcentrateObject }} > }}
         * where ConcentrateObject has a HATEOAS reference to an associated Substance value.
         */
        this.officeConcentrates = await this._concentrateService.getAtOffice(this.$scope.office);

        for (let aConcentrate of this.officeConcentrates.list) { // Associate each concentrate w/a substance

            let aRowModel = await this._buildConcentrate(aConcentrate);
            this.$scope.rowCollection.push(aRowModel);
        }

        this.startAllSubscriptions();
        this.inventoryAlertService.sendTo(this.$scope);
        this.$scope.$digest();
    }

    /**
     * Transform a concentrate dto to a view concentrate.
     * @param aConcentrate - concentrate dto
     * @returns {{id: *, barcode: *, description: *, lot: (*|number), mnfg: *, expirationDate: *, inServiceDate: *, endServiceDate: *, status: *, href: *}}
     * @private
     */
    async _buildConcentrate(aConcentrate) {
        let aSubstance = await this._getSubstance(aConcentrate.substance);


        let aRowModel = {
            "id": aConcentrate.id,
            "barcode": aConcentrate.barcode,
            "description": aSubstance.name,
            "lot": aConcentrate.lot,
            "mnfg": aConcentrate.manufacturer,
            "expirationDate": aConcentrate.useBy,
            "inServiceDate": aConcentrate.startService,
            "endServiceDate": aConcentrate.endService,
            "status": aConcentrate.status,
            "forIdt": aConcentrate.forIdt,
            "href": aConcentrate.href,
            "alert": undefined
        };

        return aRowModel;
    }

    /**
     * Fetch the Substance DTO by it's reference. Results are cached.
     * @param substanceRef
     * @return Promise to a Substance DTO
     * @private
     */
    async _getSubstance(substanceRef) {
        let foundSubstance = this.substanceMap.get(substanceRef.id);

        if (!angular.isObject(foundSubstance)) {
            foundSubstance = await this._substanceService.get(substanceRef, this.officeConcentrates);
            this.substanceMap.set(substanceRef.id, foundSubstance);
        }

        return foundSubstance;
    }

    _addConcentrateModal(mode) {

        let modal = this.$uibModal.open({
            windowClass: 'addConcentrate',
            scope: this.$scope, //passed current scope to the modal
            template: require('../../widgets/edit-concentrate-modal/layout.html'),
            css: require('../../widgets/edit-concentrate-modal/styles.scss'),
            controller: EditConcentrateModalController,
            resolve: {
                "useCase": () => EditConcentrateModalController.UseCaseAdd,
                "concentrateHref": () => undefined
            }
        });

        modal.result.then(conc => {
            if (angular.isObject(conc))
                this._printConcentrate(conc);
        });
    }

    _clearInventoryAlertModal(row) {
        if (!row.alert) return;

        let confirmModal = this.$uibModal.open({
            windowClass: 'clearInventoryAlert',
            template: require('../../widgets/clear-inventory-alert-modal/layout.html'),
            css: require('../../widgets/clear-inventory-alert-modal/styles.scss'),
            controller: ClearInventoryAlertController,
            resolve: {
                'inventoryItem' : () => {
                    return { name: row.description, serviceStatus: row.status };
                }
            }
        });

        confirmModal.result.then(() =>  {
            this.inventoryAlertService.clearAlerts(this.$scope.practice, row.id)
                .then(() => {
                    row.alert = null;
                    row._alertDismissed = true;
                });
        });
    }

    async _printConcentrate(conc) {
        let substance = await this._getSubstance(conc.substance);
        const concentrate = {
            barcode: conc.barcode,
            description: substance.name,
            manufacturer: conc.manufacturer,
            lot: conc.lot,
            volume: conc.capacity + ' mL',
            formulation: this._$filter('agFormulation')(conc),
            ubd: conc.useBy,
            dilution: conc.dilution !== 0 ? this._$filter('dilution')(conc.dilution) + ' Dilution' : null,
        }
        submitPrintJob(PRINTER_DYMO30336_PORTRAIT, <ConcentrateLabel concentrate={concentrate} />)
    }
}
