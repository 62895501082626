"use strict";

import angular from 'angular';
import controller from "./controller";

export default angular.module('pages.inventory.sections.patient.vials', [])
    /**
     * This constant is the identity of a ModelStateChange; an event which indicates a data model change has occurred
     * and a UI refresh is in order.
     */
    .constant("ModelStateChange", "ModelStateChange")
    .controller('PatientVialsSectionController', controller)
    .directive('stPatientVialsTable', ['$filter', stPatientVialsTable])
    .directive("stReSearchListener", ["ModelStateChange", smartTableReSearchListener])
    .directive("pvPresentsNotes", ["$document", pvPresentsNotes])
    .name;

function stPatientVialsTable($filter) {
    return {
        template: require('./widgets/st-patient-vials-table.html'),
        css: require('./widgets/st-patient-vials-table.scss'),
        link: function ($scope) {
            $scope.printDate = new Date();

            /*
             * This method is called from angular smart table for each object in vm.vialsList.
             * Actual: The id of the object being checked against
             * Expect: The search value
             */
            $scope.useByDateSearch = (actual, expected) => {
                let id = actual;
                let result = false;

                if (expected === undefined || expected === null || expected === '') {
                    result = true;
                }
                else if ($scope.vm && $scope.vm.vialsList) {
                    let item = $scope.vm.vialsList.find(m => m.id === id) || {};
                    let displayedValue = item.isEscalation ? 'N/A' : $filter('date')(item.useByDate, 'MM/dd/yyyy');
                    result = displayedValue.indexOf(expected) > -1;
                }

                return result;
            };

            let dismissInitialRowDataWatch = $scope.$watchCollection(
                () => ($scope.vm ? $scope.vm.vialsList : null),
                (newVal,oldVal) => {
                    $scope.displayedCollection = [].concat(newVal);
                    dismissInitialRowDataWatch();
                });
        }
    };
}
/**
 * Use-Case: given an element supported by a SmartTableController, instances attributed with this directive will
 * automatically initiate a refresh on the SmartTable contents.
 *
 * Smart-Table instances are normally pretty good about auto-detecting and reacting to model-state changes; however,
 * changes wrought via vectors outside of UI-DOM or instantiation can sometimes get stuck. This is a light-weight
 * solution which supports highly-decoupled controller and VM states without much operational buy-in.
 *
 * @param ModelStateChange logical/operational event representation. It is context free, bears no data of own save for
 * the contextual notification its existance conveys, which is: something inside your data-model has been altered and
 * you've got to refresh your rendered view-state.
 *
 */
function smartTableReSearchListener(ModelStateChange) {
    return {
        restrict : "A",
        require : "stTable",
        link : ($scope, element, attributes, /** {SmartTableController} */stCnlr) => {
            $scope.$on(ModelStateChange, ()=> stCnlr.search(stCnlr.tableState()));
        }
    };
};

function pvPresentsNotes($document) {
    return {
        scope : {
            isOpen : "="
        },
        restrict : "A",
        link : (scope, element)=> {

            const NOTE_CONTAINER_ATTR_NAME = "pv-note-container";

            let stateChangeHandler = domEvent => {

                    let wasClickedInsideArea = angular.element(domEvent.target).inheritedData(NOTE_CONTAINER_ATTR_NAME);

                    if (!wasClickedInsideArea) {
                        scope.$apply( scope => { scope.isOpen = false;});
                    }
                },
                onOpen =()=> angular.element($document[0].body).bind('click', stateChangeHandler),
                onClose =()=> angular.element($document[0].body).unbind('click', stateChangeHandler);

            element.data(NOTE_CONTAINER_ATTR_NAME, true);
            scope.$watch( ()=>(scope.isOpen), isItOpenNow => (isItOpenNow ? onOpen() : onClose()));
        }
    };
}
