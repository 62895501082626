'use strict';

import TestingController from '../testing.controller'

export default class TestMeasureIntradermalTimerController extends TestingController {

    static $inject = ['$scope','$injector','patientService'];
    constructor($scope, $injector, patientService) {

        super($scope, $injector);

        this.allergyTestLoaded().then(() => this.reload());
    }

    reload() {
        super.validateStage(['IDT_ANTIGEN_TIMER']);

        this.$scope.expiredTimer = false;
        this.disableNext = false;
        this.$scope.text = 'IDT Antigen Test Timer';
        this.initFooterNote("Measure Intradermal");
    }

    /**
     * Save if something changed.
     * @override
     */
    update() {
        if (this.updateFooterNote())
            return super.update();
        else
            return Promise.resolve(this.$scope.allergyTest);
    }

    /**
     * @override
     */
    nextDisabled() {
        return this.disableNext || !this.$scope.expiredTimer;
    }
}
