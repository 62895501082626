"use strict";

import angular from 'angular';

/**
 * Taxonomy :
 *
 * Pages := have single-segment paths. They end in "Page".
 * Page-Children := UI-units within divisible Pages to which URL navigation is possible. Their names
 * will begin with a Page value's name. This implicitly lays out the hierarchy.
 *
 */
export default angular.module("models.url-paths", [])
    .constant("UrlPaths", {
        LOGIN_PAGE : "/login",

        DASHBOARD_PAGE : "/dashboard",
        DASHBOARD_APPOINTMENTS : "/dashboard/appointments",

        DASHBOARD_APPROVALS : "/dashboard/approvals",
        DASHBOARD_APPROVALS_TEST : "/dashboard/approvals/tests",
        DASHBOARD_APPROVALS_TEST_REVIEW : "/dashboard/approvals/tests/combined",
        DASHBOARD_APPROVALS_RX : "/dashboard/approvals/prescription",
        DASHBOARD_APPROVALS_TREATMENT : "/dashboard/approvals/treatment",
        DASHBOARD_APPROVALS_MIX : "/dashboard/approvals/mix",

        DASHBOARD_MIXING : "/dashboard/mixing",
        DASHBOARD_MIXING_INITIALIZE : "/dashboard/mixing/initialize",
        DASHBOARD_INTERNAL_MIXING_DETAILS : "/dashboard/mixing/details",
        DASHBOARD_EXTERNAL_MIXING_DETAILS : "/dashboard/external/mixing/details",
        DASHBOARD_MIXING_CHECKLIST : "/dashboard/mixing/checklist",
        DASHBOARD_MIXING_PREPARE : "/dashboard/mixing/prepare",
        DASHBOARD_MIXING_VIAL: "/dashboard/mixing/vial",
        DASHBOARD_MIXING_EXTERNAL_VIAL: "/dashboard/mixing/external-vial",
        DASHBOARD_MIXING_REVIEW : "/dashboard/mixing/review",
        DASHBOARD_NO_TREATMENT : "/dashboard/no-treatment",
        DASHBOARD_ORDERED_NOT_TESTED : "/dashboard/ordered-not-tested",

        DASHBOARD_TREATMENT : "/dashboard/treatment",
        DASHBOARD_TREATMENT_INITIALIZE : "/dashboard/treatment/initialize",
        DASHBOARD_TREATMENT_VIAL_SELECTION : "/dashboard/treatment/vial-selection",
        DASHBOARD_TREATMENT_QUESTIONNAIRE : "/dashboard/treatment/questionnaire",
        DASHBOARD_TREATMENT_PRE_VITALS : "/dashboard/treatment/pre-vitals",
        DASHBOARD_TREATMENT_CHECKLIST : "/dashboard/treatment/checklist",
        DASHBOARD_TREATMENT_VIAL_TEST : "/dashboard/treatment/vial-test",
        DASHBOARD_TREATMENT_IDT_TIMER : "/dashboard/treatment/idt-result/idt-timer",
        DASHBOARD_TREATMENT_IDT_RESULTS : "/dashboard/treatment/idt-result",
        DASHBOARD_TREATMENT_INJECTION : "/dashboard/treatment/injection",
        DASHBOARD_TREATMENT_REACTION_TIMER : "/dashboard/treatment/examination/reaction-timer",
        DASHBOARD_TREATMENT_EXAMINATION : "/dashboard/treatment/examination",

        DASHBOARD_BILLING : "/dashboard/billing",

        KIOSK_PAGE : "/kiosk",

        PATIENT_PAGE : "/patient",
        PATIENT_LIST :  "/patient/patient-list",
        PATIENT_DETAILS : "/patient/patient-details",

        INVENTORY_PAGE : "/inventory",
        INVENTORY_CONCENTRATES: "/inventory/concentrates",
        INVENTORY_CONCENTRATE_DETAILS: "/inventory/concentrate/details",
        INVENTORY_WELLS: "/inventory/sp-wells",
        INVENTORY_WELL_DETAILS: "/inventory/sp-well/details",
        INVENTORY_TESTING: "/inventory/testing-boards",
        INVENTORY_TESTING_DETAILS: "/inventory/testing-board/details",
        INVENTORY_MIXING: "/inventory/mixing-boards",
        INVENTORY_MIXING_DETAILS: "/inventory/mixing-board/details",
        INVENTORY_VIALS: "/inventory/patient-vials",

        REPORTS_PAGE : "/reports",
        REPORTS_TOTAL_TESTS : "/reports/total-tests",
        REPORTS_DAILY_VISIT : "/reports/daily-visit",
        REPORTS_PATIENT_TESTING_STATUS : "/reports/patient-testing-status",
        REPORTS_PATIENT_MIXING_STATUS : "/reports/patient-mixing-status",
        REPORTS_PATIENT_TREATMENT_STATUS : "/reports/patient-treatment-status",
        REPORTS_TREATMENT_COMPLIANCE : "/reports/treatment-compliance",
        REPORTS_CANCELLED : "/reports/cancelled",
        REPORTS_CONCENTRATE_EXPIRATION : "/reports/concentrate-expiration",
        REPORTS_APPROVED_AWAITING_TREATMENT: "/reports/approved-waiting-for-treatment",
        REPORTS_PATIENT_VIAL_EXPIRATION: "/reports/patient-vial-expiration"
    })
    .name;
