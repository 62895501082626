import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentVitalsController from './treatment-vitals.controller.js';

export default angular.module('pages.treatment.vitals', [ngRoute])
    .config(config)
    .controller('TreatmentVitalsController',TreatmentVitalsController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_PRE_VITALS, {
        template: require('./treatment-vitals.html'),
        controller: 'TreatmentVitalsController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
