'use strict';

import angular from 'angular';
import ngRoute from 'angular-route';

import PasswordRecoveryController from './controller.js';

export default angular.module('pages.password.recovery', [ngRoute])
    .config(config)
    .controller('PasswordRecoveryController', PasswordRecoveryController)
    .name;

function config($routeProvider) {
    $routeProvider.when('/password-reset', {
        template: require('./new-password.html'),
        css: require("./new-password.scss"),
        controller: 'PasswordRecoveryController'
    });
}

config.$inject = ['$routeProvider'];

