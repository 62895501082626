'use strict'

export default angular.module("models.notification.type", [])
    .constant("NotificationType", {
        Appointment: 'Appointment',
        AllergyTest: 'AllergyTest',
        Board: 'Board',
        Bill: 'Bill',
        Concentrate: 'Concentrate',
        InventoryAlert: 'InventoryAlert',
        Patient: 'Patient',
        PatientList: 'Patient',
        PatientVial: 'PatientVial',
        PatientAlert: 'PatientAlert',
        Prescription: 'Prescription',
        Treatment: 'Treatment',
        Unapproved: 'Unapproved',
        EntityLockEvent: 'EntityLockEvent'
    }).name;