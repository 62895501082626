"use strict";

import BaseController from "./../base.controller";

export default class ReportsController extends BaseController {

    static $inject = ['$scope', '$injector', 'UrlPaths'];

    constructor($scope, $injector, UrlPaths) {
        super($scope, $injector);
        this.routeToPage(UrlPaths.REPORTS_TOTAL_TESTS);
    }
}