'use strict';

/**
 * Obtains and stores GlobalLinks (as 'global').
 * Holds an $http that may be used, so that every service need not inject it.
 * Has a special version of get() that handles embedded objects.
 */
export default class ServerAPI {

    static $inject = ['$http', '$q', '$httpParamSerializer'];

    constructor($http, $q, $httpParamSerializer) {
        this.$http = $http;
        this.$q = $q;
        this.$httpParamSerializer = $httpParamSerializer;
        this.reset();
    }

    reset() {
        this.globalLinksPromise = this.get("/api/links");
        this.globalLinksPromise.then(global => {
            // Set millisecond offset to add to browser's clock to match server time.
            global.clockOffset = new Date(global.serverDateTime).getTime() - Date.now();
            console.log("Server version: " + global.version + ", time: " + global.serverDateTime + ", clockOffset: " + global.clockOffset);
            return global;
        });
    }

    /**
     * @returns {Promise} to GlobalLinks
     */
    getGlobalLinks() {
        return this.globalLinksPromise;
    }

    /**
     * HTTP GET an href.
     *
     * @param href URI to get from
     * @param params is a key/value map for the query string, and may be empty.
     * @param parentDTO is optional, but if given may contain _embedded data to avoid actually hitting the server to resolve this href.
     * @param acceptedStatusCodes is optional, but if given may contain an array of status error codes that will be treated as non-critical errors. Non-critical errors will be rejected without the Http ErrorInterceptor processing them.
     * @return a Promise that will return the requested DTO on success, or ServerError DTO failure.
     */
    get(href, params, parentDTO, acceptedStatusCodes) {
        var deferred = this.$q.defer();

        // First check if what we want is in cache
        if (parentDTO != undefined) {
            var o = parentDTO._embedded && parentDTO._embedded[href];
            if (o != undefined) {
                deferred.resolve(o);
                return deferred.promise;
            }
        }

        var request = {
            method: 'GET',
            url: href,
            params: params
        };

        this._sendRequestAndRespond(request, acceptedStatusCodes, deferred);
        return deferred.promise;
   }

    /**
     * HTTP PUT to an href.
     *
     * @param href URI to put to
     * @param params is a key/value map for the query string, and may be empty.
     * @param data is the DTO to PUT. May be undefined.
     * @param acceptedStatusCodes is optional, but if given may contain an array of status error codes that will be treated as non-critical errors. Non-critical errors will be rejected without the Http ErrorInterceptor processing them.
     * @return a Promise that will return the updated DTO on success, or ServerError DTO failure.
     */
    put(href, params, data, acceptedStatusCodes) {
        var deferred = this.$q.defer();

        var request = {
            method: 'PUT',
            url: href,
            params: params,
            data: this._cleanJsonStringify(data)
        };

        this._sendRequestAndRespond(request, acceptedStatusCodes, deferred);
        return deferred.promise;
    }

    /**
     * HTTP POST to an href for creation.
     *
     * @param href URI to post to
     * @param params key/value map for the query string, typically empty
     * @param data the DTO to create with.
     * @param acceptedStatusCodes is optional, but if given may contain an array of status error codes that will be treated as non-critical errors. Non-critical errors will be rejected without the Http ErrorInterceptor processing them.
     * @return a Promise that will return the created DTO on success, or ServerError DTO failure.
     */
    post(href, params, data, acceptedStatusCodes) {
        var deferred = this.$q.defer();

        var request = {
            method: 'POST',
            url: href,
            params: params,
            data: (data instanceof FormData) ? data : this._cleanJsonStringify(data)
        };

        // By default angular $httpProvider will add a header {'Content-Type' : 'application/json'} to any POST.
        // This is an incorrect content type for FormData, and could cause the request to be rejected by the server.
        // $http docs state to explicitly remove a header automatically added via $httpProvider we should set that header to undefined.
        // https://docs.angularjs.org/api/ng/service/$http
        if (data instanceof FormData) {
            request.headers = {
                'Content-Type': undefined
            };
        }

        this._sendRequestAndRespond(request, acceptedStatusCodes, deferred);

        return deferred.promise;
    }

    /**
     * HTTP DELETE to an href.
     *
     * @param href URI to object to delete
     * @param acceptedStatusCodes is optional, but if given may contain an array of status error codes that will be treated as non-critical errors. Non-critical errors will be rejected without the Http ErrorInterceptor processing them.
     * @return a Promise that will return the value 200 on success, or ServerError DTO upon failure.
     */
    delete(href, acceptedStatusCodes) {
        var deferred = this.$q.defer();

        var request = {
            method: 'DELETE',
            url: href
        };

        this._sendRequestAndRespond(request, acceptedStatusCodes, deferred);
        return deferred.promise;
    }

    /**
     * Helper to build a URL with parameters
     */
    buildUrl(baseUrl, params) {
        let serializedParams = this.$httpParamSerializer(params);

        let url = baseUrl;
        if (serializedParams.length > 0) {
            url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
        }

        return url;
    }

    /**
     * Send a request to the server.
     *
     * @param request HTTP request object.
     * @param acceptedStatusCodes is optional, but if given may contain an array of status error codes that will be treated as non-critical errors. Non-critical errors will be rejected without the Http ErrorInterceptor processing them.
     * @param deferred $q deferred object to put the result in.
     * @private
     */
    _sendRequestAndRespond(request, acceptedStatusCodes, deferred) {

        if (Array.isArray(acceptedStatusCodes)) {
            request.acceptedStatusCodes = acceptedStatusCodes;
        }

        this.$http(request)
            .then(response => {
                if (response.status === 200 || (response.status === 201 && request.method === 'POST')) {
                    // Success; return the DTO
                    deferred.resolve(response.data);
                }
                else {
                    // Failure. If result from the service is a ServerError, return it.
                    // Otherwise construct our own ServerError properties.
                    if (Number.isInteger(response.data.status))
                        deferred.reject(response.data);
                    else
                        deferred.reject({status: response.status, errors: []});
                }
            }, rejection => {
                if (rejection.data && Number.isInteger(rejection.data.status))
                    deferred.reject(rejection.data);
                else
                    deferred.reject({status: rejection.status, errors: []});
            });
    }

    /**
     * Helper function for turning a DTO into JSON for submission back to the server.
     * Fields that start with an underscore or $ are private and/or meta-data and are ignored by the server.
     * This function saves bandwidth/processing by leaving them out.
     *
     * @param dto Javascript object of a DTO
     * @return the DTO stringified into JSON, excluding any fields that begin with an underscore.
     * @private
     */
    _cleanJsonStringify(dto) {
        return JSON.stringify(dto, (key,value) => {

            // Check first character of the field name
            let c = key.charAt(0);
            if (c === '_' || c === '$') {
                // It's client data - don't include in JSON string
                return undefined;
            }
            else {
                return value;
            }
        });
    }
}
