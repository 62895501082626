'use strict';

import angular from 'angular';
import moment from 'moment';
import { ClassicalDilution, ClassicalDilutions } from "../models/classical-dilutions";

import React from 'react'
import { submitPrintJob } from '../react/print/Printer'
import { PRINTER_DYMO30336_PORTRAIT, PatientVialLabel } from '../react/print/Dymo30336Portrait'

export default class LabelPrinter {
    static $inject = ['$injector'];

    constructor($injector) {
        this.$filter = $injector.get('$filter');
        this.$q = $injector.get("$q");
        this.patientService = $injector.get('patientService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
    }

    printTreatmentVials(vials) {
        return this._preparePatientVialLabels(vials).then(labels => {
            submitPrintJob(PRINTER_DYMO30336_PORTRAIT, labels)
        })
    }

    printPrescribedVial(prescription, vial) {
        return this._createPrescribedVialVm(prescription, vial).then((vialVm) => {
            return this.printTreatmentVials([vialVm]);
        });
    }

    async printPrescribedVials(prescriptions) {
        if(!Array.isArray(prescriptions)) {
            prescriptions = [prescriptions];
        }

        //We want to reuse the label generation, so let's create some vial VMs based on the treatment config
        let vialVms = [];
        for(let prescription of prescriptions) {
            for(let j = 0; j < prescription.vials.length; j++) {
                let vial = prescription.vials[j];
                if(vial.containsAntigen) {
                    let vialVm = await this._createPrescribedVialVm(prescription, vial);
                    vialVms.push(vialVm);
                }
            }
        }

        return this.printTreatmentVials(vialVms);
    }

    async _createPrescribedVialVm(prescription, vial) {
        if (!prescription._treatmentConfig) {
            prescription._treatmentConfig = {};
        }

        let vialVm = {};
        vialVm._prescription = prescription;
        vialVm.patient = prescription.patient;

        /* Determine use by date and treatment config for traditional or otolaryngic */
        if (prescription.diluteTo) {
            let useBy = moment();
            let treatmentConfigs = prescription.treatmentConfigs.slice().sort((a,b) => b.dilution - a.dilution);
            for(let ptc of treatmentConfigs) {
                if (!prescription._treatmentConfig[ptc.treatmentConfig.id]) {
                    prescription._treatmentConfig[ptc.treatmentConfig.id] = await this.treatmentConfigService.get(ptc.treatmentConfig);
                }

                let treatmentConfig = prescription._treatmentConfig[ptc.treatmentConfig.id];
                useBy = useBy.add(treatmentConfig.vialExpireDays, 'days');
                if (ptc.dilution === ClassicalDilution[vial.color].order) {
                    vialVm._treatmentConfig = vialVm.treatmentConfig = treatmentConfig;
                    vialVm.useBy = useBy.format('YYYY-MM-DD');
                    break;
                }
            }
        }
        else {
            vialVm.treatmentConfig = prescription.treatmentConfigs[0].treatmentConfig;
            vialVm.useBy = prescription.vialsUseBy;
        }

        vialVm.barcode = vial.barcode;
        vialVm.name = vial.name;

        let today = moment();
        let oneYearFromToday = today.add(365, 'days');
        if (prescription.treatmentVials) {
            let treatmentVial = prescription.treatmentVials.find(tv => tv.barcode === vial.barcode);
            if (treatmentVial) {
                vialVm.beyondUse = treatmentVial.beyondUse;
                vialVm.useBy = treatmentVial.useBy;
            }
        }
        else if (prescription.clientData.beyondUseDate && prescription.clientData.beyondUseDate[vial.id]) {
            /* During mixing, the beyond use date is temporarily stored in clientData */
            vialVm.beyondUse = prescription.clientData.beyondUseDate[vial.id];

            /* Do not allow use by date to exceed beyond use date */
            if (moment(vialVm.beyondUse).isBefore(moment(vialVm.useBy))) {
                vialVm.useBy = vialVm.beyondUse;
            }
        }
        else {
            /* Do not allow use by date to exceed one year */
            if (oneYearFromToday.isBefore(moment(vialVm.useBy))) {
                vialVm.useBy = vialVm.beyondUse = oneYearFromToday.format('YYYY-MM-DD');
            }
            else {
                vialVm.beyondUse = vialVm.useBy;
            }
        }

        return vialVm;
    }

    async _preparePatientVialLabels(vials) {
        //To handle both a single vial and multiple vials, turn a single into an array for looping
        if(!Array.isArray(vials)) {
            vials = [vials];
        }

        let patients = {};
        let prescriptions = {};
        let treatmentConfigs = {};

        const labels = []

        for(let vialIdx = 0; vialIdx < vials.length; vialIdx++) {
            let vial = vials[vialIdx]

            if (!vial._patient) {
                let patient = patients[vial.patient.id] || await this.patientService.get(vial.patient);
                vial._patient = patients[patient.id] = patient;
            }

            if(!vial._prescription) {
                let prescription = prescriptions[vial.prescription.id] || await this.prescriptionService.get(vial.prescription);
                vial._prescription = prescriptions[prescription.id] = prescription;
            }

            if(!vial._treatmentConfig) {
                let treatmentConfig = treatmentConfigs[vial.treatmentConfig.id] || await this.treatmentConfigService.get(vial.treatmentConfig);
                vial._treatmentConfig = treatmentConfigs[treatmentConfig.id] = treatmentConfig;
            }

            let isScit = vial._prescription.treatmentType === 'SCIT';
            if(!isScit && vial._isEscalation === undefined) {
                let prescribedVial = vial._prescription.vials.find(m => m.barcode == vial.barcode);
                vial._isEscalation = await this.prescriptionService.isEscalationVial(prescribedVial);
            }

            let params = {
                isScit,
                barcode: vial.barcode,
                lastName: vial._patient.person.familyName,
                firstName: vial._patient.person.givenName,
                dob: this.$filter('date')(vial._patient.dayOfBirth, 'MM/dd/yyyy'),
                vialName: vial.name,
                volume: null,
                doses: null,
                // possible bug in the previous code??  is there ever an "isEscalation" ?
                ubd: vial.isEscalation ? null : this.$filter('date')(vial.useBy, 'MM/dd/yyyy'),
                bud: this.$filter('date')(vial.beyondUse, 'MM/dd/yyyy'),
                instructions: null,
                compounded: null,
            }

            // SCIT
            if(isScit) {
                params = {
                    ...params,
                    volume: vial._treatmentConfig.scit.fillVolume + "mL",
                    doses: vial._treatmentConfig.scitSchedule.length,
                    compounded: 'SC',
                }
            }
            // SLIT
            else {
                let slitConfig = vial._treatmentConfig.slit
                params = {
                    ...params,
                    volume: (vial._isEscalation ? slitConfig.fillVolumeEsc : slitConfig.fillVolumeMaint) + "mL",
                    instructions: vial._isEscalation ? slitConfig.instructionsEsc : slitConfig.instructionsMaint,
                    compounded: 'SL',
                }
            }

            labels.push(<PatientVialLabel key={vialIdx} vial={params}/>)
        }

        return labels
    }
}
