import React, { useRef, useState, useEffect, useImperativeHandle } from 'react'
import _ from 'lodash'
import createEmotion from '@emotion/css/create-instance'
import { CacheProvider } from '@emotion/react'
import FramePortal from '../FramePortal'
import { dymo30336LandscapeGlobalCss, dymo30336PortraitGlobalCss, DYMO30336_LENGTH_IN, DYMO30336_WIDTH_IN } from './Dymo30336'
import { PRINTER_DYMO30336_PORTRAIT } from './Dymo30336Portrait'
import { PRINTER_DYMO30336_LANDSCAPE } from './Dymo30336Landscape'

const Printer = React.forwardRef((props, printerRef) => {
	const frameRef = useRef(null)
	const emotionRef = useRef(null)
	const [hasEmotionCache, setHasEmotionCache] = useState(false)
	const [children, setChildren] = useState(null)
	const [showPrintDialog, setShowPrintDialog] = useState(false)

	useEffect(() => {
		console.debug(`[Printer] instantiated ${props.emotionKey}`)
		emotionRef.current = createEmotion({
			key: props.emotionKey,
			container: frameRef.current.contentDocument.body,
		})
		emotionRef.current.injectGlobal(props.globalCss)
		setHasEmotionCache(true)
	}, [])

	useImperativeHandle(printerRef, () => ({
		print: (children) => {
			setChildren(children)
			setShowPrintDialog(true)
		},
	}))

	useEffect(() => {
		if(showPrintDialog) {
			setShowPrintDialog(false)
			frameRef.current.contentWindow.focus()
			frameRef.current.contentWindow.print()
		}
	}, [showPrintDialog])

	const frameStyle = {
		...props.frameStyle,
		overflow: 'hidden',
	}
	
	return (
		<FramePortal ref={frameRef} style={frameStyle}>
			{hasEmotionCache ? (
				<CacheProvider value={emotionRef.current.cache}>
					{children}
				</CacheProvider>
			) : null}
		</FramePortal>
	)
})

const printerRegistryRef = React.createRef({})
const PrinterRegistry = React.forwardRef((props, registryRef) => {
	const dymo30336PortraitRef = useRef(null)
	const dymo30336LandscapeRef = useRef(null)

	useImperativeHandle(registryRef, () => ({
		submitPrintJob: (printerName, job) => {
			switch(printerName) {
				case PRINTER_DYMO30336_PORTRAIT:
					dymo30336PortraitRef.current.print(job)
					break
				case PRINTER_DYMO30336_LANDSCAPE:
					dymo30336LandscapeRef.current.print(job)
					break
				default:
					console.error('[PrinterRegistry] unknown printerName specified', printerName)
			}
		},
	}))

	return (
		<>
			<Printer
				id={'printer-' + PRINTER_DYMO30336_PORTRAIT}
				ref={dymo30336PortraitRef}
				emotionKey="dymoap"
				frameStyle={{
					// set to block to see debugging view on screen, otherwise none
					display: 'block',
					width: `${DYMO30336_WIDTH_IN}in`,
					height: `${DYMO30336_LENGTH_IN}in`,
				}}
				globalCss={dymo30336PortraitGlobalCss}
			/>
			<Printer
				id={'printer-' + PRINTER_DYMO30336_LANDSCAPE}
				ref={dymo30336LandscapeRef}
				emotionKey="dymoal"
				frameStyle={{
					// set to block to see debugging view on screen, otherwise none
					display: 'block',
					width: `${DYMO30336_LENGTH_IN}in`,
					height: `${DYMO30336_WIDTH_IN}in`,
				}}
				globalCss={dymo30336LandscapeGlobalCss}
			/>
		</>
	)
})
export const printerRegistryInstance = <PrinterRegistry ref={printerRegistryRef} />

export const submitPrintJob = (printerName, job) => {
	console.debug('[submitPrintJob]', printerName, job)
	if(_.isArray(job) && job.length < 1) {
		console.warn('[submitPrintJob] job is empty!')
		alert('Oops, nothing found to print...')
		return
	}
	printerRegistryRef.current.submitPrintJob(printerName, job)
}
