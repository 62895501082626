'use strict';

import BaseService from './base.service.js';

export default class ConcentrateService extends BaseService {

    /**
     * Get the URL to a concentrate given it's ID
     */
    getHrefForId(concentrateId) {
        return `/api/concentrates/${concentrateId}`
    }

    /**
     * Add a new concentrate
     *
     * @param {Office} office
     * The office to which this new Concentrate value will be applied
     * @param {Concentrate} concentrate
     * The logical value we're introducing
     *
     * @return Promise to new Concentrate DTO
     */
    create(office, concentrate) {
        return this.serverAPI.post(office._links.concentrates, {}, concentrate);
    }

    /**
     * Fetch all Concentrates at an Office.
     *
     * @param {Office} office
     * @return Promise to a Concentrate.List of matches.
     */
    getAtOffice(office) {
        return this.serverAPI.get(office._links.concentrates, {});
    }

    /**
     * Fetch all Concentrates at an Office that are IN_SERVICE.
     *
     * @param {Office} office
     * @return Promise to a Concentrate.List of matches.
     */
    getInServiceAtOffice(office) {
        return this.serverAPI.get(office._links.concentratesInService);
    }

    /**
     * Fetch all Concentrates at an Office that are IN_SERVICE and of a specific Substance
     *
     * @param {Office} office
     * @param {Substance} substance
     * @return Promise to a Concentrate.List of matches. Should be zero or one.
     */
    getInServiceSubstanceAtOffice(office, substance) {
        return this.serverAPI.get(office._links.concentratesInService, { substance: substance.id });
    }

    /**
     * Fetch all Concentrates at an Office that are IN_SERVICE and a control.
     * @param {Office} office
     * @return Promise to a Concentrate.List of matches. Should be zero +
     */
    getInServiceControlsAtOffice(office) {
        return this.serverAPI.get(office._links.concentratesInService, { controlsOnly: true });
    }

    /**
     * GET history of uses of this Concentrate.
     *
     * @param concentrate a Concentrate DTO
     * @return Promise to a ConcentrateUse.List sorted by useDate
     */
    getUses(concentrate) {
        return this.serverAPI.get(concentrate._links.uses, {});
    }

    /**
     * GET a Concentrate given it's barcode
     *
     * @param practice a Practice DTO
     * @param barcode a barcode for a concentrate
     * @return Promise to a ConcentrateUse.List sorted by useDate
     */
    getByBarcode(practice, barcode) {
        return this.serverAPI.get(practice._links.findConcentrateByBarcode, { barcode: barcode }, undefined, [404]);
    }
}
