"use strict";

import angular from "angular";
import BaseController from "../../../base.controller";

export default class EditInventoryBoardController extends BaseController {

    /*
     * Scope:
     * The view content portrays the subject Board value state. Hence, the ViewModel *data* derives from the DM.
     *
     * VM metadata:
     * UI values for updating the subject's ServiceStatus value.
     */

    /**
     * Dependency Injection
     *
     * $uibModalInstance
     *  The controller instance handling the DOM-UI popup-presentation.
     *  @see https://angular-ui.github.io/bootstrap/#/modal
     *  @see https://github.com/angular-ui/bootstrap/tree/master/src/modal
     *
     * $scope
     *  The Angular-Scope instance in which this instance will operate.
     *
     * $injector
     *  Angular-InjectorService
     *
     * subjectBoard {BoardDataModel}
     *  The data-model operand value we're serving in this instance.
     */
    static $inject =
               ["$uibModalInstance","$scope","$injector","subjectBoard"];
    constructor( $uibModalInstance , $scope , $injector , subjectBoard) {
        super($scope, $injector);
        this._initInjections($injector);

        $scope.isSaving = false;

        // UI-Behaviors
        $scope.onUiCancel = ()=> $uibModalInstance.dismiss();

        $scope.onUiSubmit = (shouldPrint)=> {
            $scope.isSaving = true;
            this._uiSave(shouldPrint).then(updatedDm =>
                $uibModalInstance.close(updatedDm));
        };

        $scope.onUiOfficeChange = (newOffice) => { this.$scope.office = newOffice; };

        // UI-State Init
        this._dataModel = subjectBoard;
        this._loadServiceStatusValues();
        this._loadAvailableOffices();
        this._renderVm();
    }

    /**
     * @param {AngularInjectorService} $injector
     * @protected
     */
    _initInjections($injector) {
        this._$q = $injector.get("$q");
        this._boardService = $injector.get("boardService");
        this._eligibleStatusService = $injector.get("eligibleStatusService");
        this._Procedure = $injector.get("Procedure");
    }

    /**
     * @protected
     *
     * @return {Promise.<{void}>}
     * We Promise to update the scope, defining *eligibleStatusTypes* as an Array of
     * ServiceStatus values.
     */
    _loadServiceStatusValues() {
        return this._eligibleStatusService.getEligibleStatusTypesForBoard(/** @type{BoardDataModel} */this._dataModel)
            .then( eligibleStatusTypes => (this.$scope.eligibleStatusTypes = eligibleStatusTypes));
    }

    /**
     * Initialize the list of available offices.
     * @returns {Promise} resolves when complete
     * @private
     */
    _loadAvailableOffices() {
        return this.officeService.getInPractice(this.$scope.practice)
            .then(officeList => this.$scope.officeChoices = officeList.list);
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                            Event Reactions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @param {Boolean} isPrinting
     *
     * @returns {Promise.<BoardDataModel>}
     */
    _uiSave(isPrinting) {

        let /** {BoardDataModel} */dm = this._dmFromVm();

        return this._boardService.update(dm)
            .then( refreshedDm => {
                if (isPrinting) {
                    // Call print function provided by parent in scope.
                    this.$scope.printEditedBoard(refreshedDm)
                    return refreshedDm;
                }
                else {
                    return refreshedDm;
                }
            });
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                     View Model creation/mapping
    // These routines produce expressions of the subject data-model in a form the amenable to the UI-View layout.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     * @param {BoardDataModel} subjDm
     */
    _renderVm() {
        this.$scope.vm = this._vmFromDm();
    }

    /**
     * @protected
     * @returns {BoardEditorViewModel}
     *
     * Generates an ViewModel fit to populate the Board Editing-UI needs.
     *
     * Why is this so Spartan? We're not showing what it is, or what is there. We're displaying what is required.
     */
    _vmFromDm() {
        let vm = {};
        vm.name = this._dataModel.name;
        vm.panelName = this._dataModel._panel.name;
        vm.status = this._dataModel.status;

        this.officeService.get(this._dataModel.office)
            .then(office => vm.office = office);

        switch (this._dataModel.procedure) {
            case this._Procedure.SPT:
                vm.boardType = 'SP Wells';
                break;
            case this._Procedure.IDT:
                vm.boardType = 'Testing';
                break;
            case this._Procedure.MIXING:
                vm.boardType = 'Mixing';
                break;
            default:
                console.log('Unexpected procedureType: ' + this._procedureType);
                vm.boardType = '';
                break;
        }

        return vm;
    }

    /**
     * @protected
     * @returns {BoardDataModel}
     *  A copy of the the Data-Model representation as required by the #_UiSave's resolution API with any applicable
     *  mutations occuring in the UI applied from the current VM state. Note, we generate a copy of the DM instance
     *  rather than overwrite it. Simply calling this._dataModel = this._dmFromVm() will refresh the instance DM state.
     */
    _dmFromVm() {
        let dm = angular.copy(this._dataModel);
        dm.name = this.$scope.vm.name;
        dm.status = this.$scope.vm.status;

        if (this.$scope.vm.office)
            dm.office = { id: this.$scope.vm.office.id };

        return dm;
    }
}
