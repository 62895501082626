'use strict';

import BaseService from './base.service.js';

export default class PanelService extends BaseService {
    static $inject = ['serverAPI', 'substanceService', 'substanceCategoryService'];

    constructor(serverAPI, substanceService, substanceCategoryService) {
        super(serverAPI);
        this.substanceService = substanceService;
        this.substanceCategoryService = substanceCategoryService;
    }

    /**
     * Get *active* Panels for a Practice
     *
     * @return Promise to a Panel.List (unsorted)
     */
    getActiveAtPractice(practice) {
        return this.serverAPI.get(practice._links.panels, { active: true});
    }

    /**
     * Create a new Panel in a Practice. (Super-admin only.)
     *
     * @param practice to add the Panel to
     * @param panel new Panel DTO
     * @returns Promise to the created Panel DTO
     */
    create(practice, panel) {
        return this.serverAPI.post(practice._links.panels, {}, panel);
    }

    /**
     * Transform a DTO from the server to inline embedded data in useful ways.
     *
     * Result is a Panel DTO with:
     *
     * - Each ReferenceDTO<Substance> field contains the referenced data in the member field called _dto
     * - Each ReferenceDTO<SubstanceCategory> field contains the referenced data in the member field called _dto
     * - a new Map called _substances for ID to DTO lookup,
     * - a new Map called _categories for ID to DTO lookup.
     *
     * @override
     * @param dto a loaded Panel
     * @return Promise to modified dto
     */
    transform(dto) {
        // Confirm it's a Panel DTO - could be a ServerError
        if (!dto.substances) {
            return super.resolved(dto);
        }
        else {
            let panel = dto;
            panel._substances = new Map();
            panel._categories = new Map();

            return this.serverAPI.$q.all(
                panel.substances.map(orderedSubstance => {

                    return this.substanceService.get(orderedSubstance.substance, panel)
                        .then(subst => {
                            orderedSubstance.substance._dto = subst;
                            panel._substances.set(subst.id, subst);

                            return this.substanceCategoryService.get(subst.category, panel).then(cat => {
                                panel._categories.set(cat.id, cat);
                                subst.category._dto = cat;
                            });
                        });
                    })
            )
            .then(() => panel);
        }
    }

    /**
     * Add a field called 'sptGroup' to the embedded Substance objects in the given panel.
     * sptGroup is the group name (letter) for the substance in this panel.
     * @param practice
     * @param panel
     */
    populateSptGroupNames(practice, panel) {
        const sptGroupSize = practice.config.sptSubstancesPerTray / practice.config.sptPrickersPerTray;

        panel.substances.forEach(orderedSubstance => {
            if (!orderedSubstance.sptGroup && orderedSubstance.sptPos > -1) {
                orderedSubstance.sptGroup = String.fromCharCode(65 + Math.floor(orderedSubstance.sptPos / sptGroupSize));
                orderedSubstance.substance._dto.sptGroup = orderedSubstance.sptGroup;
            }
        });
    }
}
