'use strict';

import angular from 'angular';
export default angular.module('pages.reports.models.ReportsSections', [])
    .constant('ReportsSections', {
        TOTAL_TESTS : 'TOTAL_TESTS',
        DAILY_VISIT : 'DAILY_VISIT',
        PATIENT_TESTING_STATUS : 'PATIENT_TESTING_STATUS',
        PATIENT_MIXING_STATUS : 'PATIENT_MIXING_STATUS',
        PATIENT_TREATMENT_STATUS : 'PATIENT_TREATMENT_STATUS',
        PATIENT_VIAL_EXPIRATION : 'PATIENT_VIAL_EXPIRATION',
        TREATMENT_COMPLIANCE : 'TREATMENT_COMPLIANCE',
        CANCELLED : 'CANCELLED',
        CONCENTRATE_EXPIRATION : 'CONCENTRATE_EXPIRATION',
        APPROVED_AWAITING_TREATMENT: 'APPROVED_AWAITING_TREATMENT',
    })
    .name;
