'use strict';

export default class WidgetsController {
    constructor($scope, $filter, $injector) {
        this.$scope = $scope;
        this.$uibModal = $injector.get('$uibModal');

        // Set addAppointmentDetails to scope
        this.$scope.addAppointmentDetails = () => {
            this.addAppointmentDetails();
        };

        // Set addAlertModal to scope
        this.$scope.addAlertModal = () => {
            this.addAlertModal();
        };

        /* BEGIN Doctor Dashboard Approval List code */

        // Scope data for stubbed out table structure on Doctor Dashboard homepage
        $scope.rowCollection = [
            {
                patientName: 'Michael Stewart',
                dateRequested: new Date('1962-05-21'),
                patientNumber: 102,
                approvalType: 'Testing (MQT)',
                provider: 'Dr. Smith',
                location: 'Allergy Busters',
                status: 'New'
                },
            {
                patientName: 'Bruce Myers',
                dateRequested: new Date('1964-12-21'),
                patientNumber: 111,
                approvalType: 'Treatment (SCIT)',
                provider: 'Dr. Jones',
                location: 'Allergy Busters',
                status: 'Viewed'
                },
            {
                patientName: 'Keith Miller',
                dateRequested: new Date('1972-07-11'),
                patientNumber: 1234,
                approvalType: 'Treatment (SCIT)',
                provider: 'Dr. Manhattan',
                location: 'Allergy Busters',
                status: 'New'
                }, {
                patientName: 'Michael Stewart',
                dateRequested: new Date('1962-05-21'),
                patientNumber: 102,
                approvalType: 'Testing (MQT)',
                provider: 'Dr. Jones',
                location: 'Allergy Busters',
                status: 'New'
                },
            {
                patientName: 'Bruce Myers',
                dateRequested: new Date('1964-12-21'),
                patientNumber: 111,
                approvalType: 'Mixing (SCIT)',
                provider: 'Dr. Smith',
                location: 'Allergy Busters',
                status: 'Viewed'
                },
            {
                patientName: 'Keith Miller',
                dateRequested: new Date('1972-07-11'),
                patientNumber: 1234,
                approvalType: 'Testing (MQT)',
                provider: 'Dr. Manhattan',
                location: 'Allergy Busters',
                status: 'Viewed'
                }, {
                patientName: 'Michael Stewart',
                dateRequested: new Date('1962-05-21'),
                patientNumber: 102,
                approvalType: 'Mixing (SCIT)',
                provider: 'Dr. Smith',
                location: 'Allergy Busters',
                status: 'New'
                },
            {
                patientName: 'Bruce Myers',
                dateRequested: new Date('1964-12-21'),
                patientNumber: 111,
                approvalType: 'Mixing (SCIT)',
                provider: 'Dr. Jones',
                location: 'Allergy Busters',
                status: 'Viewed'
                },
            {
                patientName: 'Malcolm Reynolds',
                dateRequested: new Date('1972-07-11'),
                patientNumber: 1234,
                approvalType: 'Mixing (SCIT)',
                provider: 'Dr. Manhattan',
                location: 'Allergy Busters',
                status: 'Resubmitted'
                }, {
                patientName: 'Michael Stewart',
                dateRequested: new Date('1962-05-21'),
                patientNumber: 102,
                approvalType: 'Mixing (SCIT)',
                provider: 'Dr. Jones',
                location: 'Allergy Busters',
                status: 'New'
                },
            {
                patientName: 'Bruce Myers',
                dateRequested: new Date('1964-12-21'),
                patientNumber: 111,
                approvalType: 'Mixing (SCIT)',
                provider: 'Dr. Smith',
                location: 'Allergy Busters',
                status: 'Viewed'
                },
            {
                patientName: 'Keith Miller',
                dateRequested: new Date('1972-07-10'),
                patientNumber: 1234,
                approvalType: 'Treatment (SCIT)',
                provider: 'Dr. Manhattan',
                location: 'Allergy Busters',
                status: 'Resubmitted'
                }
        ];

        // Make a copy of the scoped data for smart table display purposes.
        $scope.displayedCollection = [].concat($scope.rowCollection);

        // Set the roeSelectedObj to false for default
        $scope.rowSelectedObj = {
            isSet: false
        };

        /* END Doctor Dashboard Approval List code */

        /* BEGIN Prescription Approval page code */
        // Scope data for vial table on approve prescription page
        $scope.vialPerscriptionData = [
            {
                name: "Vial A",
                substances: [{
                        dilution: 2,
                        endPoint: 2,
                        name: 'Timothy Grass',
                        volume: 0.2
            },
                    {
                        dilution: 3,
                        endPoint: 3,
                        name: 'Live Oak',
                        volume: 0.2
            },
                    {
                        dilution: 1,
                        endPoint: 1,
                        name: 'Virgina Oak',
                        volume: 0.2
            },
                    {
                        dilution: 3,
                        endPoint: 3,
                        name: 'Red Ceader',
                        volume: 0.2
            },
                    {
                        dilution: null,
                        endPoint: null,
                        name: 'Glycerin',
                        volume: 1.0
            },
                    {
                        dilution: null,
                        endPoint: null,
                        name: 'PNS',
                        volume: 3.2
            }]
            }, {
                name: "Vial B",
                substances: [{
                        dilution: 2,
                        endPoint: 2,
                        name: 'Oadas Grass',
                        volume: 0.2
            },
                    {
                        dilution: 3,
                        endPoint: 3,
                        name: 'Liasdve Oak',
                        volume: 0.2
            },
                    {
                        dilution: 1,
                        endPoint: 1,
                        name: 'Virgadsina Oak',
                        volume: 0.2
            },
                    {
                        dilution: 3,
                        endPoint: 3,
                        name: 'Rasded Ceader',
                        volume: 0.2
            },
                    {
                        dilution: null,
                        endPoint: null,
                        name: 'Glycerin',
                        volume: 1.0
            },
                    {
                        dilution: null,
                        endPoint: null,
                        name: 'PNS',
                        volume: 3.2
            }]
            }];

        // Scope for test types
        $scope.data = {
            patient: {
                person: {
                    givenName: "Test",
                    middleName: "Testing",
                    familyName: "Tester"
                },
                chartNum: 1243546
            },
            testConfigs: [{
                name: "Standard",
                id: "UUID1"
            }, {
                name: "Pediatric",
                id: "UUID2"
            }],
            panels: [{
                name: "So. Cal. Panel",
                id: "UUID3"
            }, {
                name: "Farm Panel",
                id: "UUID4"
            }],
            providers: [{
                person: {
                    givenName: "John",
                    middleName: "W",
                    familyName: "Smith"
                },
                id: "IIID5"
            }, {
                person: {
                    givenName: "Oogy",
                    middleName: "",
                    familyName: "Boogy"

                },
                id: "IIID6"
            }],
            appointmentType: "TESTING",
            appointmentLength: 120
        };

        $scope.data.providerId = $scope.data.providers[0].person.id;


        $scope.antigens = [
            {
                name: "Timothy Grass",
            },
            {
                name: "Live Oak",
            },
            {
                name: "Virgna Oak",
            },
            {
                name: "Red Ceader",
            }
        ];

        $scope.vialData = [
            {
                name: "A",
                dosage: ".50 cc",
                reaction: "None",
                mixingId: "A184385",
                expiration: "12/31/2016",
                previousReactions: "None",
                popoverNotes: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean",
                pastTreatments: [
                    {
                        date: '12/02/2015',
                        dosage: '.50 cc',
                        reaction: 'None'
            },
                    {
                        date: '11/21/2015',
                        dosage: '.50 cc',
                        reaction: 'Dime'
            },
                    {
                        date: '11/14/2015',
                        dosage: '.50 cc',
                        reaction: '3mm'
            },
                    {
                        date: '11/07/2015',
                        dosage: '.50 cc',
                        reaction: 'None'
            }
                ]
            },
            {
                name: "B",
                dosage: ".50 cc",
                reaction: "None",
                mixingId: "A184334",
                expieration: "11/31/2016",
                previousReactions: "5mm Wheal Size",
                popoverNotes: "Here are some notes for this popover",
                pastTreatments: [
                    {
                        date: '11/02/2014',
                        dosage: '.75 cc',
                        reaction: 'None'
            },
                    {
                        date: '10/21/2014',
                        dosage: '.75 cc',
                        reaction: 'Dime'
            },
                    {
                        date: '10/14/2014',
                        dosage: '.75 cc',
                        reaction: '3mm'
            },
                    {
                        date: '10/07/2014',
                        dosage: '.75 cc',
                        reaction: 'None'
            }
                ]
            }
        ];

        // Function to add an anitgen row to a vial table for substance modification (Called by saving a edited row on the vial table)
        $scope.addAntigenRowDataToData = function (vial) {

            // Var that creates an object with the newly added antigen
            var antigen = {
                dilution: $scope.vialPerscriptionData[vial].substances.dilution,
                endPoint: $scope.vialPerscriptionData[vial].substances.endPoint,
                name: $scope.vialPerscriptionData[vial].substances.name,
                volume: $scope.vialPerscriptionData[vial].substances.volume
            };

            // Add new antigen to the front of the substances array
            $scope.vialPerscriptionData[vial].substances.unshift(antigen);

            // Clear the form
            $scope.vialPerscriptionData[vial].substances.dilution = "";
            $scope.vialPerscriptionData[vial].substances.endPoint = "";
            $scope.vialPerscriptionData[vial].substances.name = "";
            $scope.vialPerscriptionData[vial].substances.volume = "";

        };


        // Function to add a dilutant row to a vial table for substance modification (Called by saving a edited row on the vial table)
        $scope.addDilutantRowDataToData = function (vial) {

            // Var that creates an object with the newly added antigen
            var dilutant = {
                dilution: null,
                endPoint: null,
                name: $scope.vialPerscriptionData[vial].substances.name,
                volume: $scope.vialPerscriptionData[vial].substances.volume
            };

            // Add new dilutant to the back of the substances array
            $scope.vialPerscriptionData[vial].substances.push(dilutant);

            // Clear the form
            $scope.vialPerscriptionData[vial].substances.name = "";
            $scope.vialPerscriptionData[vial].substances.volume = "";

        };

        /* END Prescriprion Approval page code */

        console.log("WidgetsController constructor compeleted !");

    }

    addAlertModal() {
        let $this = this;
        this.$uibModal.open({
            windowClass: 'addAlertModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('../common/approvals/widgets/add-alert-modal.html'),
            css: require('../common/approvals/widgets/add-alert-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.close('cancel');

            }
        });
    }

}

WidgetsController.$inject = ['$scope', '$filter', '$injector', '$uibModal'];
