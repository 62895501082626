'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import moment from 'moment';

export default class PatientTestingStatusReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.PATIENT_TESTING_STATUS);
        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getPatientTestingStatus(this.reportCriteria);
    }

    async _renderReportData(reportData) {
        this.$scope.rowCollection = [];
        for(let row of reportData) {
            row.datePerformed = moment(row.datePerformed);
            row.datePerformedDisplay = row.datePerformed.format('MM/DD/YYYY');
            this.$scope.rowCollection.push(row);
        }
        this.$scope.$digest();
    }

    _getCsvMeta() {
        return [
            { name: 'Patient Name', value: 'patientName' },
            { name: 'Chart Number', value: 'chartNumber' },
            { name: 'Patient Status', value: 'patientStatus' },
            { name: 'Type', value: 'testType' },
            { name: 'Date Performed', value: 'datePerformedDisplay' },
            { name: 'Status', value: 'testStatus' },
            { name: 'Location', value: 'location' },
            { name: 'Ordering Provider', value: 'orderingProvider' },
            { name: 'Performed By', value: 'performedBy' },
            { name: 'Approved', value: 'approved' },
            { name: 'Billing Provider', value: 'billingProvider' },
            { name: 'Charges Incurred', value: 'chargesIncurred' }
        ];
    }
}
