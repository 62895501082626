'use strict';

import angular from 'angular';
import ngRoute from 'angular-route';

import UserSetupController from './controller.js';

export default angular.module('pages.account.setup', [ngRoute])
    .config(config)
    .controller('UserSetupController', UserSetupController)
    .name;

function config($routeProvider) {
    $routeProvider.when('/user-setup', {
        template: require('./user-setup.html'),
        css: require("./user-setup.scss"),
        controller: 'UserSetupController'
    });
}

config.$inject = ['$routeProvider'];

