"use strict";

import BaseSectionController from "../../widgets/section-content-panel/controller";
import DashboardSections from "../../models/dashboard-sections";
import PhoneContext from "../../../../models/phone-context";
import Procedure from  "../../../../models/procedure";
import {ChronoUnit, LocalDateTime, Period} from 'js-joda';

export default class OrderedNotTestedSectionController extends BaseSectionController {

    static $inject = ["$injector", "$scope", "DashboardSections", "PhoneContext", "Procedure"];
    static TIMEFRAME_THIS_MONTH = "THIS-MONTH";
    static TIMEFRAME_THIS_YEAR = "THIS-YEAR";

    /**
     * @param {Angular.$injector} $injector
     * @param {Angular.$rootScope.Scope} $scope
     * @param {Dashboard.UiSectionTypes} UiSectionTypes
     *  the Dashboard.UiSectionTypes enumeration definition (the whole enum, not just one of the member values)
     */
    constructor($injector, $scope, UiSectionTypes, PhoneContext, Procedure) {
        super($injector, $scope, UiSectionTypes.ORDERED_NOT_TESTED);

        // Establish own resources
        this.appointmentService = $injector.get("appointmentService");
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.patientService = $injector.get("patientService");
        this.PhoneContext = PhoneContext;
        this.Procedure = Procedure;
        this.allergyTestConfigService = $injector.get('allergyTestConfigService');

        // Establish stateful reactions
        $scope.TIMEFRAME_THIS_MONTH = OrderedNotTestedSectionController.TIMEFRAME_THIS_MONTH;
        $scope.TIMEFRAME_THIS_YEAR = OrderedNotTestedSectionController.TIMEFRAME_THIS_YEAR;
        $scope.$watch(() => $scope.dateRange, newDateRange => this._updateTimeFrame());
        $scope.onRowSelection = (argRow) => this._gotoPatientDetails(argRow);
        $scope.checkInPatient = (argRow) => this._checkInPatient(argRow);
        $scope.cancelAppointment = (argRow) => this._cancelAppointment(argRow);

        this.reload();
    }

    /**
     * Reaction behavior to the user actively changing the session's subject office location. The current office is
     * a fundamental input in determining which sets of records are applicable. Hence, reload everything!
     *
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this.reload();
    }


    // Public API

    /** Load data, or reload when something changes. */
    reload() {

        if (angular.isDefined(this.$scope.dateRange)) {
            // Current DateTime is the end. It's an ISO-8601 string that we can manipulate.
            let tEnd = this.chronologyMappingService.currentDateTime(this.$scope.office.timezone);
            let tStart = this._uiToVmTime(tEnd, this.$scope.dateRange);

            this._resetViewSmartTable();
            this._loadAppointmentNoShowPatients(tStart, tEnd);
        }

        //else ==> Incomplete initial param set, waiting for complete picture.

    };

    // Internal Impl

    /**
     * Actively encourages Angular's compiler to complete the view rendering process.
     *
     * @private
     */
    _forceScopeFruition() {
        this.$scope.$apply();
    }

    /**
     *
     * @param patientObject
     * @returns {string}
     *  the user-facing text formatted version of the logical telephone number stored in the param value.
     * @private
     */
    _getPhoneNumber(patientObject) {
        if (angular.isDefined(patientObject["phoneNumbers"])) {

            let phoneNumbers = patientObject.phoneNumbers;

            for (let aContextType in this.PhoneContext) {

                let aPhoneKey = aContextType.toLowerCase();
                if (angular.isDefined(phoneNumbers[aPhoneKey])) {
                    return phoneNumbers[aPhoneKey];
                }
            }
        }
        return null;
    }

    /**
     * An entry in the ViewModel's collection of patient-appointment joined models. The type annotation does not
     * describe the param's entirety, or species; instead, we only specify the fields required by THIS method.
     * @param vmRow - appointment view model containing _links object allowing for patient check in
     * @private
     */
    _gotoPatientDetails(vmRow) {
        let patientReference = { id : vmRow._patient.id, href : vmRow._patient.href };
        this.routeToPage(this.urlPaths.PATIENT_DETAILS, patientReference, this.routingService.createLocationParams('Ordered Not Tested'));
    }

    /**
     * Check in a patient and redirect the user to the dashboard
     * @param vmRow - appointment view model containing _links object allowing for patient check in
     * @private
     */
    async _checkInPatient(vmRow) {
        let canCheckIn = await this._canCheckIn(vmRow._appointment);
        if (!canCheckIn) {
            let modal = this.$uibModal.open({
                windowClass: 'appointment',
                template: require('../../../common/appointment-details/appointment-details.html'),
                css: require('../../../common/appointment-details/appointment-details.scss'),
                controller: 'AppointmentDetailsController',
                size: 'md',
                resolve: {
                    patient: () => {

                        // Note this won't actually return the updated patient, the server will update
                        // the patient in the background. We can get the updated patient by asking the
                        // server for it later when it's needed
                        this.patientService.refresh(vmRow._patient);
                        return vmRow._patient;
                    }
                }
            });

            modal.result.then((appt) => {
                this._createVm(appt, vmRow);
            });
        }
        else {
            this.patientService.checkinByAppointment(vmRow)
                .then(() => this.routeToPage(this.urlPaths.DASHBOARD_APPOINTMENTS));
        }
    }

    /* Return true if the appointment can be checked in immediately or false otherwise */
    async _canCheckIn(appt) {
        if (!appt.provider || !appt.procedure) {
            return false;
        }
        else if (!(appt.procedure === 'TREATMENT' || appt.procedure === 'IDT')) {
            if (!appt.allergyTestConfig) {
                return false;
            }
            else {
                let allergyTestConfig = await this.allergyTestConfigService.get(appt.allergyTestConfig);
                if (allergyTestConfig.spt && allergyTestConfig.spt.length && !appt.panel) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Cancel an appointment
     * @param vmRow - appointment view model containing _links object to cancel the appointment
     * @private
     */
    _cancelAppointment(vmRow) {
        this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('../../widgets/cancel-activity-modal.html'),
            css: require('../../widgets/cancel-activity-modal.scss'),
            controller: function ($uibModalInstance, $scope, appointment) {
                $scope.activityType = 'Appointment';
                $scope.appointment = appointment;

                $scope.yes = () => {
                    $uibModalInstance.close($scope.note);
                };
                $scope.no = () => $uibModalInstance.dismiss();
            },
            resolve: {
                appointment: () => vmRow
            }
        }).result.then((note) => {
            return this.appointmentService.cancel(vmRow, note);
        }).then(() => {
            let i = this.$scope.rowCollection.findIndex(m => m.id === vmRow.id);
            if (i >= 0) {
                this.$scope.rowCollection.splice(i, 1);
            }
        });
    }


    /**
     * Acquires all patients who had a test ordered, but never came in for an allergy test
     * @param {string} tStart ISO-8601 DateTime
     * @param {string} tEnd ISO-8601 DateTime
     *
     * @private
     */
    async _loadAppointmentNoShowPatients(tStart, tEnd) {
        this.$scope.isLoading = true;

        let noShowAppointments = await this.appointmentService.getFloatingAtOffice(
            this.$scope.office, tStart, tEnd, [this.Procedure.TESTING, this.Procedure.IDT]);

        this.$scope.rowCollection = [];

        for (let anAppt of noShowAppointments.list) {
            let vmAppointment = {};
            await this._createVm(anAppt, vmAppointment);
            this.$scope.rowCollection.push(vmAppointment);
        }

        this.$scope.isLoading = false;
        this._forceScopeFruition();
    }

    async _createVm(anAppt, vmAppointment) {
        let aPatient = await this.patientService.get(anAppt.patient /* , ?referencingObject? */);
        let aProvider = await this.userService.getUserName(anAppt.provider);

        vmAppointment.id = anAppt.id;
        vmAppointment._appointment = anAppt;
        vmAppointment.patientName = `${aPatient.person.givenName} ${aPatient.person.middleName} ${aPatient.person.familyName}`;
        vmAppointment.chartNumber = aPatient.chartNum;
        vmAppointment.phoneNumber = this._getPhoneNumber(aPatient);
        vmAppointment.orderedDate =
            this.chronologyMappingService.utcToTimezone(anAppt.modifiedDateTime, this.$scope.office.timezone);
        vmAppointment.provider = aProvider;
        vmAppointment._patient = aPatient;
        vmAppointment._links = anAppt._links;
    }

    /**
     * Common opening moves for impl which have a View packing a smart-table.
     *
     * @private
     */
    _resetViewSmartTable() {
        this.$scope.rowCollection = [];
        this.$scope.displayedCollection = [];
        this.$scope.rowSelectedObj = { isSet: false };
    }

    /**
     * @param {string} current ISO DateTime at the subject office.
     * @param {string} periodExpr
     *   Non-opaque, contextual identifier impl'd as a majik string. The value is assumed to either lexically match
     *   one of the class constants (TIMEFRAME_THIS_MONTH or TIMEFRAME_THIS_YEAR), failing that the string is
     *   processed as a JS-Joda.Period chronology serialized representation.
     *   The value is formatted to support Joda's API.
     *
     * @see https://js-joda.github.io/js-joda/esdoc/class/src/Period.js~Period.html
     *
     * @returns {string} ISO-8601 DateTime
     * @private
     */
    _uiToVmTime(currentDateTime, periodExpr) {

        let parsedPeriod, myTimeFrame;

        let now = LocalDateTime.parse(currentDateTime);

        if (periodExpr === OrderedNotTestedSectionController.TIMEFRAME_THIS_MONTH) {
            // Special Case :: limit appointments to those created the current Gregorian calendar month.
            // This is NOT necessarily equivalent to "Gimme the past 30 days!".
            myTimeFrame = now.withDayOfMonth(1);
        }
        else if (periodExpr === OrderedNotTestedSectionController.TIMEFRAME_THIS_YEAR) {
            // Special Case :: limit appointments to those created the current Gregorian calendar year.
            // This is NOT necessarily equivalent to "Gimme the past 365 days!".
            myTimeFrame = now.withDayOfYear(1);
        }
        else {
            // General Case :: arg expression assumed to be of the form "PnU",
            // where : 'P' is required by format (it means Period)
            //  n is an integer
            //  U is code, of value 'D','M' or 'Y' representing days, months, year respectively.
            // In this case, we let Joda's Chronologic-Arithmetic handle it...
            parsedPeriod = Period.parse(periodExpr);
            myTimeFrame = now.minusTemporalAmount(parsedPeriod);
        }

        return myTimeFrame.truncatedTo(ChronoUnit.DAYS).toString();
    }

    /**
     * Reaction behavior to the user actively changing the session's subject time frame of interest.
     * The service powering appointment data acquisition always wants finite a timeframe when accessing appointments,
     * which effectively makes the time period a fundamental input in determining which sets of records are applicable.
     * Hence, reload everything!
     *
     * @private
     */
    _updateTimeFrame() {
        this.reload();
    }
}
