'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentInjectionController from './treatment-injection.controller.js';

export default angular.module('pages.treatment.injection', [ngRoute])
    .config(config)
    .controller('TreatmentInjectionController', TreatmentInjectionController)
    .directive('treatmentDirective', ['$window', treatmentDirective])
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_INJECTION, {
        template: require('./injection.html'),
        css: require('./injection.scss'),
        controller: 'TreatmentInjectionController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];

function treatmentDirective($window) {
    return {
        restrict : "A",
        scope: false,
        link: function(scope, elem, attrs) {
            scope.enteringBarcode = false;
            scope.modalOpen = false;

            scope.$watch('barCode', function(newvalue, oldvalue) {
                if(newvalue != undefined) {
                   if(newvalue === '=' && scope.allowAutoBarcode)
                       scope.barCode = scope.vial.barcode;
                   if(scope.barCode.length == 8){
                       if(scope.barCode === scope.vial.barcode) {
                           scope.validBarcode = true;
                       } else if(scope.barCodesInTreatment.includes(scope.barCode)){
                           scope.barcodeMismatchModal();
                           scope.validBarcode = false;
                       } else {
                           scope.barcodeModal();
                           scope.validBarcode = false;
                       }
                   } else {
                       scope.validBarcode = false;
                   }
                }
            });
            
            
            scope.$watch('modalOpen', function(newvalue, oldvalue){
                if(newvalue === false) {
                    angular.element($window).on('keydown', function (e) {
                        if (document.getElementById('footer-note-text') !== null) {
                            // User is entering a note
                        }
                        else if (e.keyCode === 10 || e.keyCode === 13) {
                            scope.enteringBarcode = false;
                        }
                        else if (e.keyCode >= 48 && e.keyCode <= 57) {
                            if (!scope.enteringBarcode || document.activeElement !== document.getElementById("barcode")) {
                                scope.enteringBarcode = true;
                                document.getElementById('barcode').value = '';
                                document.getElementById('barcode').focus();
                            }
                        }
                    });
                } else {
                    angular.element($window).off('keydown');
                }
            });
            
            scope.$on("$destroy", function() {
                angular.element($window).off('keydown');
            });
        }
    };
}
