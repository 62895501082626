"use strict";

import BaseController from "../../../../pages/base.controller.js";

/**
 * Controller for the Shot History report popup.
 */
export default class DelayedReactionModalController extends BaseController {

    static $inject = ['$scope', '$injector', '$uibModalInstance', 'treatment'];

    constructor($scope, $injector, $uibModalInstance, treatment) {

        super($scope, $injector);

        this.$q = $injector.get('$q');
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.treatmentService = $injector.get('treatmentService');
        this.$uibModalInstance = $uibModalInstance;

        this.$scope.cancel = () => this._cancel();
        this.$scope.saveAndClose = () => this._saveAndClose();

        this._initialize(treatment);
    }

    _initialize(treatment) {
        this.treatment = treatment;
        let vm = { treatmentDate: this.treatment.performedBy.actionDateTime, injections: [] };

        for(let injection of treatment.injections.filter(i => i.injected)) {
            let vmInjection = {
                vialName: injection._vial.name,
                dose: injection.dosage + ' mL',
                location: injection.location,
                reactionWhealSize: injection.reactionWhealSize + ' mm'
            };

            vmInjection.delayedWhealSize = injection.delayedWhealSize === -1 ? null : injection.delayedWhealSize;
            vmInjection.note = injection.delayedReactionBy ? injection.delayedReactionBy.note : null;

            vm.injections.push(vmInjection);
        }

        this.$scope.vm = vm;
    }

    _cancel() {
        this.$uibModalInstance.dismiss();
    }

    _saveAndClose() {
        let hasChanges = false;

        for(let i = 0; i < this.$scope.vm.injections.length; i++) {
            let vmInjection = this.$scope.vm.injections[i];
            let injection = this.treatment.injections[i];

            let whealSize = vmInjection.delayedWhealSize ? vmInjection.delayedWhealSize : -1;
            if (whealSize !== injection.delayedWhealSize) {
                hasChanges = true;
                injection.delayedWhealSize = vmInjection.delayedWhealSize;
                injection.delayedReactionBy = { note: vmInjection.note };
            }
        }

        let updatePromise = this.$q.resolve();
        if (hasChanges) {
            updatePromise = this.treatmentService.update(this.treatment);
        }

        /* Add a timeout for any update delay */
        updatePromise
            .then(() => this.$timeout(() => {}, 10))
            .then(() => this.$uibModalInstance.close(hasChanges));
    }
}
