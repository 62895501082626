'use strict';

import './patient-info.scss';

import angular from 'angular';

export default angular.module('widgets.patient-info', [])
    .directive('agPatientInfo', ['$timeout', '$uibModal', 'routingService', 'UrlPaths', 'userService', agPatientInfo])
    .name;

function agPatientInfo($timeout, $uibModal, routingService, UrlPaths, userService) {
    return {
        template: require('./patient-info.html'),
        scope: true,
        link: function(scope, element, attrs) {
            let _goToPatientDetails = () => {
                return $timeout(() => {
                    routingService.goToPage(
                        UrlPaths.PATIENT_DETAILS,
                        scope.patient,
                        routingService.createLocationParams('Back to Session')
                    );
                });
            }

            userService.getGlobalLinks().then(globalLinks => {
                $scope.nonProductionType = globalLinks.environment == 'PRODUCTION' ? null : 'training/testing'
            });

            scope.goToPatientDetails = () => {
                if (scope.update) {
                    scope.update().then((result) => {
                        if (result) {
                            _goToPatientDetails()
                        }
                    });
                }
                else {
                    _goToPatientDetails();
                }
            }

            scope.openLabelDetails = () => {
                $uibModal.open({
                    openedClass: 'modal-open print-modal-only',
                    windowClass: 'labelDetailsModal',
                    template: require('../../pages/common/label-details/label-details.html'),
                    css: require('../../pages/common/label-details/label-details.scss'),
                    controller: 'LabelDetailsController',
                    size: 'lg',
                    resolve: {
                        inWizard: function () {
                            return true;
                        }
                    }
                });
            }
        }
    }
}
