"use strict";

import BaseBoardListController from "../../widgets/inv-board-list/controller";

export default class TestingBoardsController extends BaseBoardListController {

    static $inject =
    /*fmIndex*/["$injector","$scope","InventorySections","UrlPaths","Procedure"];
    constructor( $injector , $scope , InventorySections , UrlPaths , Procedure ) {
        super($injector, $scope, InventorySections.TESTING, UrlPaths.INVENTORY_TESTING_DETAILS, Procedure.IDT);
    }
}
