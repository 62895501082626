'use strict';

// Where to show an alert.
// Values refer to tabs in Inventory, including the Inventory tab itself.
export default angular.module("models.alert.icon.position", [])
    .constant("AlertIconPosition", {
        INVENTORY: 'INVENTORY',
        CONCENTRATE: 'CONCENTRATE',
        SPT_BOARD: 'SPT_BOARD',
        IDT_BOARD: 'IDT_BOARD',
        MIX_BOARD: 'MIX_BOARD',
        PATIENT_VIAL: 'PATIENT_VIAL'
    }).name;
