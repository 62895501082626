'use strict'

/**
 * Reason for a PrescribedVial
 */
export default angular.module("models.prescription.reason", [])
    .constant("PrescriptionReason", {
        NEW : "NEW",
        RECALLED : "RECALLED",
        EXPIRED : "EXPIRED",
        BROKEN : "BROKEN",
        LOST : "LOST",
        REPEAT : "REPEAT",
        DILUTE_NEW: "DILUTE_NEW",
        DILUTE_VIAL: "DILUTE_VIAL",
        ADVANCE: "ADVANCE",
        NOMORE: "NOMORE"
    })
    .filter('prescriptionReasonFilter', prescriptionReasonFilter)
    .name;

function prescriptionReasonFilter() {
    return (type) => {
        let displayName = type;

        switch (type) {
            case "NEW":
                displayName = "Starting Prescription";
                break;
            case "RECALLED":
                displayName = "Repeat Vial - Mfr. Recalled";
                break;
            case "EXPIRED":
                displayName = "Repeat Vial - Expired";
                break;
            case "BROKEN":
                displayName = "Repeat Vial - Broken";
                break;
            case "LOST":
                displayName = "Repeat Vial - Lost";
                break;
            case "REPEAT":
                displayName = "Repeat Vial";
                break;
            case "DILUTE_NEW":
                displayName = "Dilute using mixing board";
                break;
            case "DILUTE_VIAL":
                displayName = "Dilute using current vial";
                break;
            case "ADVANCE":
                displayName = "Vial Complete - Advance";
                break;
            case "NOMORE":
                displayName = "Treatment Complete";
                break;
            default:
                break;
        }

        return displayName;
    }
}
