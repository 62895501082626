'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import moment from 'moment';

export default class CancelledReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.CANCELLED);
        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getCancelled(this.reportCriteria);
    }

    async _renderReportData(reportData) {
        this.$scope.rowCollection = [];
        for(let row of reportData) {
            let datePerformed = moment(row.datePerformed);
            row.datePerformed = datePerformed;
            row.datePerformedDisplay = datePerformed.isValid() ? datePerformed.format('MM/DD/YYYY') : row.datePerformed;
            row.dateCancelled = moment(row.dateCancelled);
            row.dateCancelledDisplay = row.dateCancelled.format('MM/DD/YYYY');
            this.$scope.rowCollection.push(row);
        }
        this.$scope.$digest();
    }

    _getCsvMeta() {
        return [
            { name: 'Patient Name', value: 'patientName' },
            { name: 'Chart Number', value: 'chartNumber' },
            { name: 'Activity', value: 'activity' },
            { name: 'Date Performed', value: 'datePerformedDisplay' },
            { name: 'Date Cancelled', value: 'dateCancelledDisplay' },
            { name: 'Cancelled By', value: 'cancelledBy' },
            { name: 'Location', value: 'location' },
            { name: 'Ordering Provider', value: 'orderingProvider' },
            { name: 'Performed By', value: 'performedBy' },
            { name: 'Billing Provider', value: 'billingProvider' }
        ];
    }
}
