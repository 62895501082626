"use strict";

import BaseController from "../../../../pages/base.controller.js";

export default class PatientAlertController extends BaseController {

    static $inject = ['$scope', '$injector', '$uibModalInstance', 'patient', 'patientAlert'];

    constructor($scope, $injector, $uibModalInstance, patient, patientAlert) {
        super($scope, $injector);

        this.$scope = $scope;
        
        this.$uibModalInstance = $uibModalInstance;
        this.patient = patient;
        this.patientAlert = patientAlert;

        this.$q = $injector.get('$q');
        this.patientService = $injector.get('patientService');
        this.patientAlertService = $injector.get('patientAlertService');

        this._initialize();

        this.$scope.onDisplayLocationChanged = (displayLocation, display) => {
            let locInTypes = this.$scope.vm.types.indexOf(displayLocation);
            if (locInTypes != -1 && !display) {
                this.$scope.vm.types.splice(locInTypes, 1);
            }
            else if (locInTypes == -1 && display) {
                this.$scope.vm.types.push(displayLocation);
            }
        }

        this.$scope.disabled = () => {
            let vm = this.$scope.vm;
            
            if (!vm.title || vm.title.length > 50) {
                return true;
            }
            else if (!vm.message || vm.message.length > 8000) {
                return true;
            }
            else if (vm.isActive === "true" && !(vm.types.length > 0)) {
                return true;
            }

            return false;
        }

        this.$scope.cancel = () => $uibModalInstance.dismiss('cancel');
        this.$scope.continue = () => this._saveAndClose();
    }

    _initialize() {
        if (!this.patientAlert) {
            this.patientAlert = { patient: this.patient, active: true, types: [] };
        }

        let vm = {};

        // Set vm variables based on patientalert.types array
        vm.editing = !!this.patientAlert.id;
        vm.title = this.patientAlert.title;
        vm.message = this.patientAlert.message;
        vm.isActive = this.patientAlert.active ? "true" : "false";

        vm.types = this.patientAlert.types;
        vm.displayTest = this.patientAlert.types.indexOf("TESTING") != -1;
        vm.displayMix = this.patientAlert.types.indexOf("MIXING") != -1;
        vm.displayTreatment = this.patientAlert.types.indexOf("TREATMENT") != -1;
        vm.displayPatientDetails = this.patientAlert.types.indexOf("DETAILS") != -1;

        this.$scope.vm = vm;
    }

    _saveAndClose() {
        let hasChanges = this.$scope.vm.alert !== this.patientAlert.alert;

        let updatePromise = this.$q.resolve();
        
        this.patientAlert.title = this.$scope.vm.title;
        this.patientAlert.message = this.$scope.vm.message;
        this.patientAlert.active = this.$scope.vm.isActive;
        this.patientAlert.types = this.$scope.vm.types;

        if (this.patientAlert.id) {
            updatePromise = this.patientAlertService.update(this.patientAlert)
                .then(patientAlert => this.patientAlert = patientAlert);
        }
        else {
            updatePromise = this.patientAlertService.create(this.patient, this.patientAlert)
                .then(patientAlert => this.patientAlert = patientAlert);
        }

        updatePromise
            .then(() => this.$uibModalInstance.close(this.patientAlert));
    }
}