"use strict";

import BaseController from "../../../../pages/base.controller.js";

/**
 * Controller for the Patient Note modal.
 */
export default class PatientNoteModalController extends BaseController {

    static $inject = ['$scope', '$injector', '$uibModalInstance', 'patient', 'patientNote', 'isEditable'];

    constructor($scope, $injector, $uibModalInstance, patient, patientNote, isEditable) {

        super($scope, $injector);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.patientNoteService = $injector.get('patientNoteService');
        this.$uibModalInstance = $uibModalInstance;
        this.patient = patient;
        this.patientNote = patientNote;

        this.$scope.vm = { isEditable: isEditable };

        this.$scope.cancel = () => this._cancel();
        this.$scope.saveAndClose = () => this._saveAndClose();

        this._initialize();
    }

    _initialize() {
        if (!this.patientNote) {
            this.patientNote = { patient: this.patient, note: '' };
        }

        this.$scope.vm.note = this.patientNote.note;
    }

    _cancel() {
        this.$uibModalInstance.dismiss();
    }

    _saveAndClose() {
        let hasChanges = this.$scope.vm.note !== this.patientNote.note;

        let updatePromise = this.$q.resolve();
        if (hasChanges) {
            this.patientNote.note = this.$scope.vm.note;
            if (this.patientNote.id) {
                updatePromise = this.patientNoteService.update(this.patientNote)
                    .then(patientNote => this.patientNote = patientNote);
            }
            else {
                updatePromise = this.patientNoteService.create(this.patient, this.patientNote)
                    .then(patientNote => this.patientNote = patientNote);
            }
        }

        updatePromise
            .then(() => this.$uibModalInstance.close(this.patientNote));
    }
}
