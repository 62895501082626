'use strict';

import BaseService from './base.service.js';

export default class ExternalResourceService extends BaseService {

    /**
     * Get all ExternalResources for a given practice
     * @param practice the practice to return external resources for
     * @return Promise to ExternalResource.List (unordered)
     */
    getAtPractice(practice) {
        return this.serverAPI.get(practice._links.externalResources);
    }
}
