'use strict';

import angular from 'angular';
import ngRoute from 'angular-route';

import UserSettingsController from './user-settings.controller.js';

export default angular.module('pages.settings', [ngRoute])
    .config(config)
    .controller('UserSettingsController', UserSettingsController)
.directive('stUsersTable', [stUsersTable])
    .name;

function config($routeProvider) {
    $routeProvider.when('/settings', {
        template: require('./layout.html'),
        css: require("./styles.scss"),
        controller: 'UserSettingsController'
    });
}

config.$inject = ['$routeProvider'];

function stUsersTable(){
    return {
        scope : {
            showFilter: "=",
            users: '=',
        },
        template: require('./widgets/user-list.html'),
        css: require('./widgets/user-list.scss')
    };
}
