 'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import MixingPrepareVialsController from './prepare-vials.controller.js';


export default angular.module('pages.mixing.prepare.vials', [ngRoute])
    .config(config)
    .controller('MixingPrepareVialsController', MixingPrepareVialsController)
    .directive('agPrepMix', ['$timeout', '$parse', agPrepMix])
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_MIXING_PREPARE, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: 'MixingPrepareVialsController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];

function agPrepMix($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {

            // Function to change grid as user moves through allergens.    
            scope.changeGrid = function () {
                for(let i=1; i<=100; i++) {
                    let filter_string = "grid" + i;
                    $parse(filter_string).assign(scope);
                    scope[filter_string] = false;
                    if (scope.count <= scope.dataChunkSize * i) {
                        let grid = "#grid-" + i;
                        let mixingArea = $("#prepare-mixing-area")[0];
                        if (i > 1 && scope.grid !== grid && mixingArea.clientWidth !== mixingArea.scrollWidth) {
                            // Scroll right 75 pixels (if scrollbar exists)
                            mixingArea.scrollLeft += 75;
                        }

                        scope.grid = grid;
                        scope[filter_string] = true;
                        break;
                    }
                }
            };

            scope.setDilutionHeader = function (number) {
                for(let i=0; i<=30; i++) {
                    let filter_string = "dil" + i;
                    $parse(filter_string).assign(scope);
                    scope[filter_string] = false;
                    if(number === i) {
                        scope[filter_string] = true;
                    }
                }
            };

            scope.init = () => {
                $timeout(() => {
                    scope.changeGrid();
                    scope.setDilutionHeader(scope.number);                    
                }); //  a trick-- $timeout with 0 sec wait will add a new event to the browser event queue
            };
        }
    };
}