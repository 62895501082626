import React from 'react'
import styled from '@emotion/styled'
import { DYMO30336_WIDTH_IN, DYMO30336_LENGTH_IN } from './Dymo30336'
import LabelBarcode from './LabelBarcode'
import { Line } from './LabelFeatures'
import { PortraitSizedDiv } from './Dymo30336Portrait'

export const PRINTER_DYMO30336_LANDSCAPE = 'dymo30336-landscape'

const LandscapeLabel = styled.div({
	pageBreakAfter: 'always',
	width: `${DYMO30336_LENGTH_IN - .125}in`, /* - 1/16in print margin each side */
	maxWidth: `${DYMO30336_LENGTH_IN - .125}in`,
	height: `${DYMO30336_WIDTH_IN - .125}in`, /* - 1/16in print margin each side */
	maxHeight: `${DYMO30336_WIDTH_IN - .125}in`,
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-evenly',
	alignItems: 'center',
	gap: '.125in',
})

const landscapeLabelColumnBase = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	gap: '.5em',
}

const LandscapeLabelBarcodeColumn = styled.div({
	...landscapeLabelColumnBase,
	// prevent the barcode from being clipped
	flexShrink: 0,

})

const LandscapeLabelContentColumn = styled.div({
	...landscapeLabelColumnBase,
	// prevent the content from pushing over and clipping the barcode
	flexShrink: 1,
	minWidth: 0,
	overflow: 'hidden',
})

export const TrayLabel = React.memo(props => {
	const {
		barcode,
		description,
	} = props
	return (
		<LandscapeLabel>
			<LandscapeLabelBarcodeColumn>
				<LabelBarcode value={barcode} height={50} />
			</LandscapeLabelBarcodeColumn>
			<LandscapeLabelContentColumn>
				<Line bold>{description}</Line>
			</LandscapeLabelContentColumn>
		</LandscapeLabel>
	)
})

export const PatientIdCardLabel = React.memo(props => {
	const {
		barcode,
		practiceName,
		patientName,
		patientPhone,
	} = props
	return (
		<LandscapeLabel>
			<LandscapeLabelBarcodeColumn>
				<LabelBarcode value={barcode} format="EAN13" height={50} />
			</LandscapeLabelBarcodeColumn>
			<LandscapeLabelContentColumn style={{textAlign: 'left'}}>
				<Line bold>{practiceName}</Line>
				<div></div>
				<Line>Patient:</Line>
				<Line bold>{patientName}</Line>
				{patientPhone ? <Line bold>{patientPhone}</Line> : null}
			</LandscapeLabelContentColumn>
		</LandscapeLabel>
	)
})

// Wrap a LandscapeLabel in this to flip its orientation
// for inclusion in portrait print jobs
export const LandscapeToPortrait = styled(PortraitSizedDiv)({
    pageBreakAfter: 'always',
    overflow: 'hidden',
    '> *': {
        transformOrigin: 'top left',
        transform: 'rotate(90deg) translateY(-100%)',
		pageBreakAfter: 'auto !important',
    },
})
