"use strict";

import manufacturers from '../../../../models/manufacturers';

import BaseController from "../../../base.controller";

export default class EditConcentrateModalController extends BaseController {

    static UseCaseEdit = "EDIT";
    static UseCaseLimited = "LIMITED"; // only edit status and office
    static UseCaseAdd = "ADD";

    /**
     * @param {BootstrapNgUiModalInstance} $uibModalInstance
     *      reference to the current (UI-Modal) layout implementation containing this instance.
     *      Furnished implicitly by BootstrapNgUiModal during progenitor's open() call.
     * @param $scope
     *      Verify we're not recklessly leaning on any members of this param
     *      Furnished implicitly by BootstrapNgUiModal during progenitor's open() call.
     * @param $injector
     *      Allows easy injection of our assets
     *      Injected into BaseController super class instance
     * @param $filter
     *      Angular filterProvider service, needed to unleash the serviceStatusFilter logic
     * @param useCase
     *      Serialized representation of enumerated states this class supports. Valid values {"EDIT","ADD"}
     *      Furnished explicitly via BootstrapNgUiModal.open() param-object.resolve.userCase
     * @param {URL?} concentrateHref
     *      A HATEOUS friendly reference to the concentrate we'll be editing. This operand will be ignored for
     *      ADD instances, but drives the subject state access for EDIT instances.
     */
    constructor($uibModalInstance, $scope, $injector, $filter, useCase, concentrateHref) {

        super($scope, $injector);

        // Modal UI Event Behaviors
        $scope.cancel = () => $uibModalInstance.dismiss(null);
        $scope.save = () => {
            this.saveConcentrate().then(result => {
                $uibModalInstance.close(result);
            })
        };
        // General Purpose default onChange checking...

        $scope.onFieldChange = () => this.checkFormState();

        this._deregisterSubstanceWatch = $scope.$watch(
            "substance",
            function handleFooChange( newValue, oldValue ) {
                if(newValue !== undefined) {
                    $scope.onSubstanceChange(newValue);
                }
            }
        );

        $scope.onSubstanceChange = (newSubstance) => {
            if (newSubstance) {
                this.$scope.selectedSubstance = newSubstance;
                this._setFormulationUnits();
                this.checkForInventoryInUse();
                this.checkFormState();
            }
        };

        $scope.onStatusChange = (newStatus) => {
            if (this.$scope.selectedStatus === newStatus)
                return;

            this.$scope.selectedStatus = newStatus;
            this.$scope.isStatusChanged = true;

            if (angular.isDefined(this._dmStatus)) {
                this.$scope.isStatusChanged = (this._dmStatus !== newStatus);
            }

            this._loadStatusChoices();
            this.checkForInventoryInUse();
            this.checkFormState();
        };

        // Added a function to check the form state when date is changed, so that if it was blanked out, the save button would be disabled.
        $scope.onDateChange = () => {
            this.checkFormState();
        };

        $scope.onOfficeChange = (newOffice) => {
            this.$scope.selectedOffice = newOffice;
            this.checkFormState();
        };

        // Date Picker Options
        this.$scope.dp = {
            opened: false,
            format: "MM/dd/yyyy",
            minDate: new Date()
        };

        this.$scope.dateOptions = {
            startingDay: 1,
            showWeeks: false,
            minDate: new Date()
        };

        // End Date Picker Options
        $scope.modelOptions = {
            debounce: {
                default: 0,
                blur: 250
            },
            getterSetter: true,
            timezone: 'UTC'
        };

        $scope.ServiceStatus = $injector.get("ServiceStatus");

        this._useCase = useCase;
        this.$injector = $injector;
        this.$filter = $filter;
        this.allergyTestConfigService = $injector.get("allergyTestConfigService");
        this.concentrateService = $injector.get("concentrateService");
        this.substanceService = $injector.get("substanceService");
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.$uibModal = $injector.get("$uibModal");

        // Oddball state variables
        this.$scope.isSubstanceDuplicate = false; // Truth implies that another concentrate of the selected substance is in service
        this.$scope.isStatusChanged = false; // Truth implies VM.status != DM.status
        this.$scope.isLimited = false;
        this._dmStatus = undefined;// Used to calculate $scope.isStatusChanged

        this._initData(concentrateHref);
    }

    async _initData(concentrateHref) {
        this._initInServiceConcentratesSet();

        // Common case init logic :: crude impl
        await this.loadFormOptions();

        // State Building
        switch (this._useCase) {
            case EditConcentrateModalController.UseCaseLimited:
                this.$scope.isLimited = true;
                // Fall through...
            case EditConcentrateModalController.UseCaseEdit:
                this.$scope.mode = "Edit";
                this.$scope.isAdd = false;
                this.$scope.saveButtonLabel = "Save";
                await this.loadEditState(concentrateHref);
                break;
            case EditConcentrateModalController.UseCaseAdd:
                this.$scope.mode = "Add";
                this.$scope.isAdd = true;
                this.$scope.saveButtonLabel = "Save and Print Label";
                this.$scope.selectedOffice = this.$scope.office;
                break;
        }

        this.$scope.isEdit = !this.$scope.isAdd;

        // Run at load, to disable button for add.
        this.checkFormState();
        this.$scope.$digest();
    }

    /** Loads authorized values for the form fields into the scope. */
    async loadFormOptions() {
        await this._loadSubstancesChoices();

        this.$scope.manufacturers = manufacturers;
        this.$scope.volumes = [100, 50, 30, 10, 5];
        this.$scope.dilutions = Array.from(Array(this.$scope.practice.config.idtDilutionsCount + 1).keys());
        this.$scope.dilutionsItem = 0;

        this._setFormulationUnits();
        this._loadStatusChoices();

        this.$scope.officeChoices = (await this.officeService.getInPractice(this.$scope.practice)).list;
        this.$scope.haveClassicalTests = await this.allergyTestConfigService.haveClassicalTests(this.$scope.practice);

        // This is for the expirationDate, to force it to reject dates in the past
        this.$scope.earliestAllowedDate = new Date();
    }

    async loadEditState(subjectConcentrateHref) {

        let
            // The operand to the get call is wrapped to express it as part of a HATEOUS reference
            subjectConcentrate = await this.concentrateService.get({"href":subjectConcentrateHref});

        this._subjectConcentrate = subjectConcentrate;

        await this._initInventorySubstance(subjectConcentrate);
        this.$scope.barcode = subjectConcentrate.barcode;
        this.$scope.expDate = this.chronologyMappingService.isoDateToJsUTCDate(subjectConcentrate.useBy);
        this._initFormulations(subjectConcentrate);
        this.$scope.manufacturerItem = subjectConcentrate.manufacturer;
        this._initStatus(subjectConcentrate);
        this.$scope.volumesItem = subjectConcentrate.capacity;
        this.$scope.lot = subjectConcentrate.lot;
        this.$scope.forIdt = subjectConcentrate.forIdt;
        this.$scope.dilutionsItem = subjectConcentrate.dilution;
        this.$scope.selectedOffice = this.$scope.officeChoices.find(o => o.id === this._subjectConcentrate.office.id)
                                        || this.$scope.office;
    }

    _initFormulations(subjectConcentrate) {
        switch (subjectConcentrate.potencyUnit) {
            case 'W/V':
                this.$scope.potency = (1 / subjectConcentrate.potency);
                this.$scope.formulationUnit = 'W/V';
                break;

            case 'mL':
                this.$scope.potency = subjectConcentrate.potency * subjectConcentrate.capacity;
                this.$scope.formulationUnit = 'mL';
                break;

            default:
                this.$scope.potency = subjectConcentrate.potency;
                this.$scope.formulationUnit = subjectConcentrate.potencyUnit + '/mL';
                break;
        }
    }

    _getSerializedPotency() {
        switch (this.$scope.formulationUnit) {
            case 'W/V':
                return  1 / this.$scope.potency;

            case 'mL':
                return this.$scope.potency / this.$scope.volumesItem;

            default:
                return this.$scope.potency;
        }

    }

    _getSerializedPotencyUnit() {
        const unit = this.$scope.formulationUnit;
        if (unit.endsWith('/mL'))
            return unit.substr(0, unit.length-3);
        else
            return unit;
    }

    /**
     *
     * Side Effect: on fruition, $scope.selectedSubstance (the UI name for the currently selected substance from which
     * this concentrate is being derived. The "Item" suffix is naming convention used in the layout to designate
     * the current-selection of the parent choice list.
     *
     * @param subjectConcentrate
     */
    async _initInventorySubstance(subjectConcentrate) {
        let mySubstance = await this.substanceService.get(subjectConcentrate.substance);
        this.$scope.selectedSubstance = this._createSubstanceVm(mySubstance);
    }

    /**
     * Accepts a cannonical representation of a logical inventory-substance instance, and produces the structure
     * required by the UI widget which will handle and offer them as choices.
     *
     * @param {String} substanceId
     *  the ID by which an associated Panel (used by the current office) refers to the substance
     *
     * @param {Substance} substance
     *  object representation of the operand state
     *
     * @returns
     *  {{substanceId: {String}, name: *, href: *}}
     *  ViewModel representation of an inventory-substance.
     *
     * @private
     */
    _createSubstanceVm(/** {SubstanceModel} */substance) {
        return {
            'id' : substance.id,
            'name' : substance.name,
            'href' : substance.href,
            'isAntigen': substance._category._isAntigen,
            'isControl': substance._category._isControl,
            'isPositiveControl': substance._category._isPositiveControl
        };
    }

    /**
     * Side Effect: #$scope.statusTypes is reset such that:
     *  A. all member values are required and allowed by business rules governing Inventory Concentrates
     *  B. it excludes $scope.selectedStatus.value (failure to abide this means the Status list will contain the
     *  current value, allowing a useless no-op request. It's actually worse than useless as the validation logic
     *  doesn't anticipate said case, nor does it handle it gracefully).
     *
     * @private
     */
    _loadStatusChoices() {

        let authorizedStates = [];

        if (this._useCase == EditConcentrateModalController.UseCaseAdd) {
            authorizedStates = [
                this.$scope.ServiceStatus.IN_SERVICE,
                this.$scope.ServiceStatus.INVENTORY
            ];
        }
        else {// Status field can have all values (except discarded/retired) :: client impl has no retired value.
            authorizedStates = [
                this.$scope.ServiceStatus.IN_SERVICE,
                this.$scope.ServiceStatus.INVENTORY,
                this.$scope.ServiceStatus.DEPLETED,
                this.$scope.ServiceStatus.RECALLED,
                this.$scope.ServiceStatus.EXPIRED
            ];
        }

        this.$scope.statusTypes = [];

        for(let aStatus of authorizedStates) {

            if (angular.isDefined(this.$scope.selectedStatus) &&
                (aStatus === this.$scope.selectedStatus.value)) {
                continue;
            }

            this.$scope.statusTypes.push({
                "name" : this.$filter("serviceStatusFilter")(aStatus),
                "value" : aStatus
            });
        }

    }

    _initStatus(subjectConcentrate) {
        /* Dropdowns base the name shown in the button on the name property of an object.
           When a concentrate is loaded, the initial value of selectedStatus was a string.
           Made selectedStatus an empty object, then added in the name property so the currently
           selected status on Edit would load into the button and be shown. Also, added the
           filter to it so it looked 'pretty'. */

        this.$scope.selectedStatus = {};
        this.$scope.selectedStatus.name = this.$filter("serviceStatusFilter")(subjectConcentrate.status);
        this._dmStatus = this.$scope.selectedStatus.value = subjectConcentrate.status;
    }

    /**
     * Side Effect: on fruition, $scope.substances (choices for the substance used in this concentrate) is
     * initialized with a list of objects of the form:
     * {
     *      id : String, // Opaque identifier of the substance
     *      name : String // Prose appearing in the UI options; the human-readable name of the substance,
     *      href : URL/String // A HATEOUS endpoint address, needed for the return trip.
     * }
     *
     * The UI will depict these values in some sort of multiple choice format, the visuals matter not. It is
     * important we express the choices in a form amenable to the ag-drop-down-select impl. The widget demands that
     * instance's "choice-list"/choiceList be supplied with a list of objects containing a "name" field.
     */
    async _loadSubstancesChoices() {
        this.$scope.substances = [];

        let substanceList = await this.substanceService.getForPractice(this.$scope.practice);
        for (let substance of substanceList) {
            this.$scope.substances.push(this._createSubstanceVm(substance));
        }
    }

    _setFormulationUnits() {
        this.$scope.formulationUnits = [ 'AgE/mL', 'AU/mL', 'BAU/mL', 'mg/mL', 'µg/mL', 'PNU/mL', 'W/V' ];
        if (this.$scope.selectedSubstance && !this.$scope.selectedSubstance.isAntigen) {
            this.$scope.formulationUnits.unshift('mL');
        }
    }

    /**
     * Save the new or modified concentrate.
     *
     * @return Promise to the value to pass back to the caller of this modal.
     *      Either null to do nothing more, or a Concentrate DTO to print a label.
     */
    saveConcentrate() {
        switch (this._useCase) {
            case EditConcentrateModalController.UseCaseEdit:
            case EditConcentrateModalController.UseCaseLimited:
                let dm = this._getSerializedConcentrate();
                if (dm.status === this.$scope.ServiceStatus.RECALLED || dm.status === this.$scope.ServiceStatus.EXPIRED) {
                    return this.concentrateStatusModal(dm);
                }
                else {
                    return this.concentrateService.update(dm)
                        .then(conc => {
                            // Don't print
                            return null;
                        });
                }

            case EditConcentrateModalController.UseCaseAdd:
                return this.concentrateService.create(this.$scope.office, this._getSerializedConcentrate());
        }
    }

    /**
     * Confirm concentrate status selection.
     * If confirmed, saves the update to the server.
     *
     * @param concentrate {Concentrate} to submit if confirmed by user
     * @return {Promise} to null upon completion, regardless of answer.
     */
    concentrateStatusModal(concentrate) {
        let acknowledgeText, paragraphText, titleText;
        if (concentrate.status == this.$scope.ServiceStatus.RECALLED) {
            acknowledgeText = 'Mfr. Recalled';
            paragraphText = 'manufacturer recalled';
            titleText = 'Recall';
        }
        else if (concentrate.status == this.$scope.ServiceStatus.EXPIRED) {
            acknowledgeText = 'Expired';
            paragraphText = 'expired';
            titleText = 'Expire';
        }
        return this.$uibModal.open({
            windowClass: 'changeConcentrateStatus',
            scope: this.$scope, //passed current scope to the modal
            resolve: {
                concentrateDetails: {
                    description: this.$scope.selectedSubstance.name,
                    barcode: concentrate.barcode,
                    mfg: concentrate.manufacturer,
                    lot: concentrate.lot
                },
                statusDisplay: {
                    acknowledgeText: acknowledgeText,
                    paragraphText: paragraphText,
                    titleText: titleText,
                }
            },
            template: require('../concentrate-status-modal.html'),
            css: require('../concentrate-status-modal.scss'),
            controller: function ($uibModalInstance, $scope, concentrateDetails, statusDisplay) {

                $scope.concentrateDetails = concentrateDetails;
                $scope.statusDisplay = statusDisplay;
                $scope.cancel = () => $uibModalInstance.dismiss();
                $scope.acknowledgeChange = () => $uibModalInstance.close();
            }
        }).result
            .then(() => this.concentrateService.update(concentrate))
            .then(newConc => {
                let href = newConc._links.recallReport;
                if (href)
                    window.location.href = href;
                return null;
            })
            .catch(() => null);
    }

    /**
     * Assuming this instance's UI is populated, this method returns a serialized representation of the
     * Concentrate, fit for use by the concentrateService requests.
     *
     * @returns {ConcentrateDTO}
     * @private
     */
    _getSerializedConcentrate() {
        // Piecemeal tactics:
        //      Add Cases :: just serialize the VM's current state.
        //      Edit Cases :: resubmit the OG model with mutations applied from the VM state.

        let concentrate = this._subjectConcentrate;
        let limited = false;

        if (this._useCase === EditConcentrateModalController.UseCaseLimited) {
            limited = true;
        }
        else if (this._useCase === EditConcentrateModalController.UseCaseAdd) {
            concentrate = {};
        }

        if (!limited) {
            concentrate.substance = {
                id : this.$scope.selectedSubstance.id,
                href : this.$scope.selectedSubstance.href
            };
            concentrate.useBy = this.chronologyMappingService.jsUTCDateToISODate(this.$scope.expDate);
            concentrate.potency = this._getSerializedPotency();
            concentrate.potencyUnit = this._getSerializedPotencyUnit();
            concentrate.lot = this.$scope.lot;
            concentrate.manufacturer = this.$scope.manufacturerItem;
            concentrate.capacity = this.$scope.volumesItem;
            concentrate.dilution = this.$scope.dilutionsItem;
        }

        concentrate.forIdt = this.$scope.forIdt;
        concentrate.status = this.$scope.selectedStatus.value;

        if (concentrate.status === this.$scope.ServiceStatus.INVENTORY || angular.isUndefined(concentrate.office)) {
            concentrate.office = { id: this.$scope.selectedOffice.id };
        }

        return concentrate;
    }

    /**
     * @param {String} lotDesignation
     *  A string whose composition we have no means of validating. These are identifiers following nomenclature schema
     *  set forth by the substance manufacturer's administrations. We currently make no assumptions about
     *  the various conventions used. All we can do is verify the user did enter something into the UI field. Anything
     *  beyond that would have to be specific to substance manufacturer, and that's clearly beyond the scope.
     *
     * @returns {boolean} true when the operand is a non empty string, false otherwise
     *
     * @private
     */
    _isUiLotInvalid(lotDesignation) {
        // If operand is a string, and length > 0 it's good. Otherwise reject it.
        // But how is this sufficient? Because in template specified directives proactively catch obvious non-values
        // entries like all-space-strings, and
        return (angular.isString(lotDesignation)) ? (lotDesignation.length == 0) : true;
    }

    /**
     * Side Effect: alters scope.isFormLocked based on the state of the UI form. When ANY of the essential
     * fields are unassigned we lock the submission control. Otherwise, its open for use.
     */
    checkFormState() {

        this.$scope.isFormLocked =
            (angular.isUndefined(this.$scope.selectedSubstance)) ||
            (angular.isUndefined(this.$scope.dilutionsItem)) ||
            (angular.isUndefined(this.$scope.manufacturerItem)) ||
            (this._isUiLotInvalid(this.$scope.lot)) ||
            (angular.isUndefined(this.$scope.volumesItem)) ||
            (angular.isUndefined(this.$scope.formulationUnit)) ||
            (!this.$scope.potency || this.$scope.potency < 0) ||
            (angular.isUndefined(this.$scope.expDate)) ||
            (angular.isUndefined(this.$scope.selectedStatus));
    }


    /**
     * Side Effect: updates scope.isSubstanceDuplicate to conform the logical state of the DM
     */
    checkForInventoryInUse() {

        let isStatusInService = ((angular.isDefined(this.$scope.selectedStatus))
            && ( this.$scope.selectedStatus.value === this.$scope.ServiceStatus.IN_SERVICE));

        // This routine only applies when status := In Service
        if (!isStatusInService) return;

        // This routine only applies when inventory is selected
        if (!angular.isDefined(this.$scope.selectedSubstance)) return;

        // Please see #_initInServiceConcentratesMap() for origin story on #_inServiceConcentrateSubstances
        this.$scope.isSubstanceDuplicate = this._inServiceConcentrateSubstances.has(this.$scope.selectedSubstance.id);
    }

    /**
     * Establishes the state of #_inServiceConcentrateSubstances
     * Logically, that's the collection of ID's for all substances that are currently In-Service by any panel of the
     * the office. These ID's are housed in a Map affording other members quick access.
     *
     * @private
     */
    async _initInServiceConcentratesSet() {
        let activeConcentrates = await this.concentrateService.getInServiceAtOffice(this.$scope.office);

        this._inServiceConcentrateSubstances = new Set();
        for (let aConcentrate of activeConcentrates.list) {
            if (!this._inServiceConcentrateSubstances.has(aConcentrate.substance.id)) {
                this._inServiceConcentrateSubstances.add(aConcentrate.substance.id);
            }
        }

    }

    /**
     * @override
     */
    destructor() {
        super.destructor();
        this._deregisterSubstanceWatch();
    }

}
