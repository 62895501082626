"use strict";

import angular from "angular";
import controller from "./controller";
import pdDateField from "./widgets/pd-date-field";
import agDropDownSelect from "../../../../widgets/drop-down-select";

export default angular.module("pages.patient.details.add-rx-modal", [pdDateField, agDropDownSelect])
    .controller("AddPrescriptionModalController", controller)
    .name;
