import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentVialSelectionController from './treatment-vial-selection.controller.js';


export default angular.module('pages.treatment.vial-selection', [ngRoute])
    .config(config)
    .controller('TreatmentVialSelectionController',TreatmentVialSelectionController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_VIAL_SELECTION, {
        template: require('./treatment-vial-selection.html'),
        css: require('./treatment-vial-selection.scss'),
        controller: 'TreatmentVialSelectionController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
