"use strict";
// Module : navigation-panel
// Local refers to the parent module "Inventory", which is an application-client primary-UI-component-unit,
// colloquially known as a "page". The Inventory is a "page", but it is broken into about half a dozen divisions.
// I'm going with "section" for secondary content divisions.
//
// This module contains an Angular component (which is subset of directive) covering the Inventory own navigation
// UI.

import angular from "angular";

export default angular.module("pages.inventory.navigation-panel", [])
    .directive("agInvNavigation" , ["InventorySections", "UrlPaths", agInvNavigation])
    .name;

function agInvNavigation(InventorySections, UrlPaths) {
    return {
        scope : {
            /**
             * Directive EigenValue : this is the only legit full-fledged scope variable. The impl will actively
             * perform behaviors in reaction to changes in this datum.
             *
             * @type{DashboardSection}
             *
             * This instance field represents *which* section is currently active. This allows user input to drive
             * the state of the caller.
             */
            activeSection : "=",

            /**
             * @type{Array.<String>}
             *
             * It's a list strings, where each value represents a logical role value. A user may have multiple roles
             * attributed to their account. Content to which this impl leads is restricted / conditionally withheld
             * from the user when the user lacks the required role values.
             *
             * We've bound this field 1-way because within an authenticated user-session, this value will not change.
             * Hence, the lifespan of the represented data (and that span will outlive the client logged-in
             * user-session) we can operationally consider this a configuration value as opposed to an input param
             * like *activeSection*.
             */
            userRoles : "@",

            /** @type{string of AlertSeverity} Alert to show on Concentrate tab; undefined if none */
            concentrateSectionAlert : "=",
            /** @type{string of AlertSeverity} Alert to show on SP Wells tab; undefined if none */
            sptBoardSectionAlert : "=",
            /** @type{string of AlertSeverity} Alert to show on Testing Boards tab; undefined if none */
            idtBoardSectionAlert : "=",
            /** @type{string of AlertSeverity} Alert to show on Mixing Boards tab; undefined if none */
            mixBoardSectionAlert : "=",
            /** @type{string of AlertSeverity} Alert to show on Patient Vials tab; undefined if none */
            patientVialSectionAlert : "="
        },
        restrict : "E",
        css : require("./styles.scss"),
        template : require("./layout.html"),

        link (scope,element,attr) {
            scope.InventorySections = InventorySections;
            scope.UrlPaths = UrlPaths;
        }
    };
}



