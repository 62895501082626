"use strict";

import BaseController from "../../../base.controller";

export default class LocationManagementController extends BaseController {

    static $inject=["$injector", "$scope"];
    constructor($injector, $scope) {
        super($scope, $injector);
        this.$scope.currentLocationName = this.$scope.office.name;
        this.$scope.allOtherPracticeOffices = [];
        this._fetchAllOtherOfficesForPractice();
    }

    /**
     * Public API . In real languages, using a solid MVC paradigm, this method would satisfy a contract/interface
     * for participating View classes to use as a ViewDelegate.
     *
     * @param newOffice
     */
    onOfficeLocationChange(newOffice) {

        this.officeService.select(newOffice)
            .then(()=> {
                this.$scope.office = newOffice;
                this.$scope.currentLocationName = newOffice.name;

                this.sessionService.set('office', newOffice);

                this._fetchAllOtherOfficesForPractice();

                this.$scope.$root.$broadcast("OFFICE_CHANGED", {
                    subject: "Office",
                    action: "switch"
                });
            });
    }

    /**
     * @private
     *
     * No return value. This is an async procedure whose side-effects alter the scope. Upon fruition, this instance's
     * $scope.allPracticeLocations member will be assigned an Array of Office model objects operationally associated
     * with the Practice model living in the instance's $scope.practice, excluding the one in scope.office.
     */
    _fetchAllOtherOfficesForPractice() {

        this.officeService.getInPractice(this.$scope.practice)
            .then( officesInPractice => {

                let selectedOfficeId = this.$scope.office ? this.$scope.office.id : '';
                this.$scope.allOtherPracticeOffices =
                    officesInPractice.list.filter((anOffice) => anOffice.id !== selectedOfficeId);
            });
    }
}
