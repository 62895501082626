"use strict";

import angular from "angular";
import controller from "./controller";
import freshWidgetService from "./fresh-widget.service";

export default angular.module("widgets.freshdesk-support-ticket", [])
    .service("freshWidgetService" , freshWidgetService)
    .controller("controller", controller)
    .directive("spawnsFreshdeskSupportTicket", ["$window", spawnsFreshdeskSupportTicket])
    .name;

function spawnsFreshdeskSupportTicket($window) {
    // serialized name:: spawns-freshdesk-support-ticket
    return {
        scope: false,
        restrict : "A",
        controller : "controller",
        link : function (scope, element, attrs, controller) {
            angular.element(element).bind("click", ()=> controller.onUiOpenSupportModal());

            $window.FreshWidget.close =()=> controller.closePopup();
        }
    }
}

