import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentQuestionnaireController from './treatment-questionnaire.controller.js';


export default angular.module('pages.treatment.questionnaire', [ngRoute])
    .config(config)
    .controller('TreatmentQuestionnaireController',TreatmentQuestionnaireController)
    .directive('setReactionDropdown', setReactionDropdown)
    .directive('captureNote', captureNote)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_QUESTIONNAIRE, {
        template: require('./treatment-questionnaire.html'),
        css: require('./treatment-questionnaire.scss'),
        controller: 'TreatmentQuestionnaireController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];

function setReactionDropdown(){
    return {
        scope: false,
        link: function( scope, elem, attr) {
               
         scope.optionChanged = function(name, value){
                scope.selectedItem = name;
                scope.reaction.newWhealSize = value;
                
                scope.$parent.reactionsAnswered = true;
                
            };
            
            
        }
    };
}

function captureNote() {
    return {
        link: function(scope, elem, attr) {
        scope.captureNote = function() {
            scope.$parent.delayedReactionsNote = scope.delayedReactionsNote;
        };
        }
    };
}