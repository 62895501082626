"use strict";

/**
 * Little controller that handles the top-level navigation tabs.
 */
export default class StandardLayoutNavController {

    static $inject=["$scope", "$location", "inventoryAlertService", "sessionService", "AlertIconPosition", "UrlPaths"];
    constructor($scope, $location, inventoryAlertService, sessionService, AlertIconPosition, UrlPaths) {

        this.$scope = $scope;
        $scope.UrlPaths = UrlPaths;

        let path = $location.path();
        $scope.areWeInDashboard = path.startsWith(UrlPaths.DASHBOARD_PAGE) ;
        $scope.areWeInPatientlist = path.startsWith(UrlPaths.PATIENT_PAGE) ;
        $scope.areWeInInventory = path.startsWith(UrlPaths.INVENTORY_PAGE) ;
        $scope.areWeInReports = path.startsWith(UrlPaths.REPORTS_PAGE) ;

        this._inventoryAlertService = inventoryAlertService;

        // Subscribe to receive changes to InventoryAlerts.
        // Callback happens immediately to initialize, and then whenever the alerts content changes.
        this._inventoryAlertService.subscribe($scope, alertSummary => {
            this.$scope.inventoryTabAlert = alertSummary.icons.get(AlertIconPosition.INVENTORY);
        });
    }
}
