'use strict';

import TestingController from '../testing.controller'

export default class TestInitializeController extends TestingController {

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        this.allergyTestLoaded().then(allergyTest => {
            this.routing(allergyTest);
        });
    }
}
