'use strict';

import TestingController from '../testing.controller'

export default class TestIdtCheckListController extends TestingController {

    static $inject = ['$scope', '$injector', '$document', 'checklistService', 'boardService', 'panelService', 'Procedure'];
    constructor($scope, $injector, $document, checklistService, boardService, panelService, Procedure) {

        super($scope, $injector);

        this.panelService = panelService;
        this.boardService = boardService;
        this.checklistService = checklistService;
        this.globalConfigService = $injector.get('globalConfigService');
        this.$q = $injector.get('$q');
        this.Procedure = Procedure;
        this.$document = $document;
        this.disableNext = true;
        this.allowAutoBarcode = false;

        $scope.barCode = '';
        $scope.nextButonLabel = this.$scope.practice.config.minIdtAntigenReactionWait > 0 ? 'Start Intradermal Timer' : undefined;
        $scope.testingViews = [];

        $scope.setLocation = (location) => this._setLocation(location);
        $scope.onBarcodeChange = () => this._onBarcodeChange();
        $scope.barcodeClass = () => this._barcodeClass();

        this._loadGlobalConfig();

        this.allergyTestLoaded()
            .then(() => this._loadPanel())
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    reload() {
        super.validateStage(['IDT_CHECKLIST']);

        this.$scope.text = 'Complete Intradermal Test Checklist';
        this.$scope.location = this.$scope.allergyTest.idtLocation;
        this.$scope.requireBoardSelection = this.isOtolaryngic;
        this.initFooterNote("Intradermal Checklist");

        if (this.$scope.requireBoardSelection) {
            this._loadIdtBoard().then(() => {
                if (this.$scope.allergyTest.idtBoard) {
                    this.$scope.barCode = this.$scope.allergyTest.idtBoard.barcode;
                }
                if (this._hasIdtBoardWarning()) {
                    this.warningModal();
                }
            });   
        }

        Promise.all([
            this._manageValidBoards(),
            this._loadChecklist(),
            this._loadTestableSubstances()
        ]).then(() => {

            let testingViews = [{ key: 'COLUMN', value: 'Column'}];

            if (this._countAntigensToDisplay() <= 40) {
                testingViews.splice(0, 0, { key: 'BACK', value: 'Back'});
                testingViews.splice(0, 0, { key: 'ARM', value: 'Arm'});
            } else {
                this.$scope.location = 'COLUMN';
            }
            this.$scope.testingViews = testingViews;
            this.disableNext = false;
            this.$scope.$digest();
        });
    }

    _loadChecklist() {
        return this.checklistService.getForIDT(this.$scope.practice)
            .then(checklist => {
                this.$scope.itemLabels = checklist.items;
                this.$scope.itemChecked = [];
            });
    }


    /**
     * Save changes to server.
     * @override
     */
    update() {
        this.$scope.allergyTest.idtLocation = this.$scope.location;
        this.updateFooterNote();
        return super.update();
    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext)
            return true;

        if (this.$scope.requireBoardSelection && !this.$scope.validBarcode) {
            return true;
        }

        for (let idx = 0; idx < this.$scope.itemLabels.length; ++idx) {
            if (!this.$scope.itemChecked[idx])
                return true;
        }

        return false;
    }

    _setLocation(location) {
        this.$scope.location = location;
    }

    _onBarcodeChange() {
        this.$scope.validBarcode = undefined;
        this.$scope.allergyTest.idtBoard = undefined;
        this.$scope.boardError = undefined;

        if (this.$scope.barCode === '=' && this.allowAutoBarcode && this.validBoards.length) {
            this.$scope.barCode = this.validBoards[0].barcode;
        }

        if (this.$scope.barCode.length == 8) {
            let candidateFound = false;
            for (let candidateBoard of this.validBoards) {
                if (this.$scope.barCode !== candidateBoard.barcode) {
                    continue;
                }

                candidateFound = true;
                this.$scope.allergyTest.idtBoard = candidateBoard;
                this._loadIdtBoard();

                break;
            }

            if (!candidateFound) {
                this.$scope.validBarcode = false;
            }
        }
    }

    _barcodeClass() {
        // $scope.validBarcode is undefined while checking to see if a scanned barcode is valid, don't want input to flash red during that time - hence strict false check
        if (this.$scope.barCode && this.$scope.barCode.length && this.$scope.validBarcode === false) {
            return 'error';
        }
        else if (this.$scope.boardError) {
            return 'warning';
        }

        return '';
    }

    _hasIdtBoardWarning() {
        return this.$scope.allergyTest.warnings.IDT_BOARD && !this.$scope.allergyTest.warnings.IDT_BOARD.override;
    }

    _loadIdtBoard() {
        var deferred = this.$q.defer();
        if (this.$scope.allergyTest.idtBoard) {
            return this.boardService.getWithCurrentVials(this.$scope.allergyTest.idtBoard).then((board) => {
                this.$scope.allergyTest.idtBoard = board;
                this._loadUntestableSubstances();
                deferred.resolve();
            });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    }

    _loadUntestableSubstances() {
        // This logic is duplicated in the Allergy Test Service, be sure to change both
        let idtBoard = this.$scope.allergyTest.idtBoard;

        let invalidTestedAntigens = this.$scope.testableAntigens.filter(s => {
            // Antigen is testable if the scanned board has an In Service vial of the same substance and dilution
            let hasTestableVial = idtBoard.vials.some(v => v.status === "IN_SERVICE" && v.substance.id === s.substance.id && v.dilution === s.dilution);
            return !hasTestableVial;
        });

        // Tray vials are not created for controls, so checking for one is useless.
        // All controls will be in service here because the end-user had to scan the barcode to test it ad - hoc in the "Prepare Intradermal" step

        let untestableSubstances = invalidTestedAntigens.map(s => s.substance);

        this.$scope.validBarcode = true;
        if (untestableSubstances.length) {
            // If the number of untestable substances is the same as the number of tested substances
            if (untestableSubstances.length === this.$scope.testableAntigens.length) {
                this.$scope.boardError = 'None of the dilutions to be tested on this board are in service.';
                this.$scope.untestableSubstances = untestableSubstances;
                this.$scope.validBarcode = false;
            } else {
                this.$scope.boardError = 'One or more of the dilutions to be tested on this board are not in service.';
                this.$scope.untestableSubstances = untestableSubstances;
            }
        }
    }

    async _loadTestableSubstances() {
        this.disableNext = true;
        this.$scope.testableAntigens = [];
        this.$scope.testableControls = [];

        const practiceConfig = this.$scope.practice.config;

        const panel = await this._loadPanel();
        panel.substances.sort((a, b) => a.idtPos - b.idtPos);

        for (let panelSubst of panel.substances) {
            let substance = panelSubst.substance._dto;
            let category = substance.category._dto;

            if (panelSubst.idtPos >= 0) {
                let dilution = category._isAntigen ?
                    this.$scope.allergyTest.antigenResults.find(m => m.substance.id === substance.id).idtDilution :
                    this.$scope.allergyTest.controlResults.find(m => m.substance.id === substance.id).idtDilution;

                /** idtDilution == 100 means SPT test happened but thresholds dictate IDT not needed
                 *  idtDilution == -1 means Server has determined this antigen is not to be tested
                 * If the system calculates that an IDT test should be done for the substance,
                 * an updated value gets set */
                if (dilution >= 0 && dilution <= practiceConfig.idtDilutionsCount) {
                    let testableSubstance = {
                        substance: substance,
                        dilution: dilution
                    };

                    if (category._isControl) {
                        this.$scope.testableControls.push(testableSubstance);
                    }
                    else {
                        this.$scope.testableAntigens.push(testableSubstance);
                    }   
                }
            }
        }

        this.disableNext = false;
        this.$scope.$digest();
    }

    /**
     * How many IDT antigens will be displayed in the grids of the Measure Intradermal screen?
     * @private
     */
    _countAntigensToDisplay() {
        const gridSize = this.$scope.practice.config.sptSubstancesPerTray / this.$scope.practice.config.sptPrickersPerTray;

        let count;
        if (this.isOtolaryngic) {
            // In otolaryngic, we show grids for each SPT group that wasn't skipped, and controls in their own
            count = this.$scope.allergyTest.sptGroups.length * gridSize;
            count += this.$scope.allergyTest.controlResults
                .filter(result => result.idtDilution >= 0 && result.idtDilution < 100).length;
        }
        else {
            // In classical, we only show input boxes for the antigens & controls to actually test.
            count = this.$scope.allergyTest.antigenResults
                .filter(result => result.idtDilution >= 0 && result.idtDilution < 100)
                .length;
            count += this.$scope.allergyTest.controlResults
                .filter(result => result.idtDilution >= 0 && result.idtDilution < 100)
                .length;
        }

        // Complete grids are displayed, so we need to round up
        count = Math.ceil(count / gridSize) * gridSize;

        console.log("Next stage will show grids for " + count + " antigens");
        return count;
    }

    /**
     * Initialize this.validBoards and refresh it whenever boards at the office change.
     * @returns {Promise} that resolves when complete
     * @private
     */
    async _manageValidBoards() {
        if (!this.$scope.requireBoardSelection) {
            return;
        }

        // Init the valid boards now
        await this._refreshValidBoards();

        // When boards change at the practice, refresh this.validBoards
        await this.notificationService.init();
        super.registerSubscription(this.notificationService.subscribeAllBoardUpdatesAtPractice(this.$scope.practice))
            .then(null, null, (notification) => {
                if (notification.body.procedure === this.Procedure.IDT)
                    this._refreshValidBoards()
            });

        super.registerSubscription(this.notificationService.subscribeBoardCreation(this.$scope.practice))
            .then(null, null, (notification) => {
                if (notification.body.procedure === this.Procedure.IDT)
                    this._refreshValidBoards()
            });

        super.startAllSubscriptions();
    }

    /**
     * Load the current valid Testing Boards at an office. Only called from _manageValidBoards().
     * @returns {Promise} that resolves when complete
     * @private
     */
    _refreshValidBoards() {
        return this.boardService.getAvailableInPanel(this.$scope.practice, this.$scope.panel, this.Procedure.IDT)
            .then(boards => this.validBoards = boards.list);
    }

    warningModal() {
        let parent = this;
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('../widgets/board-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {
                $scope.warningText = "Some substances on the scanned board are not in service. You will not be able to record intradermal reactions for the following substances:";
                $scope.cancel = () => {
                    $uibModalInstance.dismiss();
                    $scope.disableNext = false;
                }

                $scope.continue = () => {
                    $uibModalInstance.close('success');
                    $scope.allergyTest.warnings.IDT_BOARD.override = { note: "Proceed with board containing expired antigens" };
                    parent.next();
                }
            }
        });
    }
}
