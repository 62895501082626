'use strict';

import angular from 'angular';
import $filter from 'angular';
import ServiceStatus from "../../models/serviceStatus";

export default angular.module('widgets.smart.table', [])
    .directive('stSelect', ['$timeout', stSelect])
    .directive('stSelectAll', [stSelectAll])
    .directive('stSelectDistinct', ['$parse', stSelectDistinct])
    .directive('stSelectOptions', ['$timeout', stSelectOptions])
    .directive('stDateSearch', ['$filter', stDateSearch])
    .directive('stCustomSearch', [stCustomSearch])
    .directive('stStatusFilterField', ["ServiceStatus", "$filter", "$parse", stStatusFilterField])
    .filter('customFilter', ['$filter', customFilter])
    .directive('angStyledDropdown', [angStyledDropdown])
    .directive('angStyledDropdownValues', ["$timeout", angStyledDropdownValues])
    .directive('angStyledDropdownObjects', ["$timeout", angStyledDropdownObjects])
    .name;

// Directive for Smart Table that allows the selection of rows, as well as checks to see if rows are selected or not to displa hidden buttons
function stSelect($timeout) {
    return {
        require: '^stTable',
        scope: {
            row: '=stSelect',
            rowSelectedObj: '='
        },
        link: function (scope, element, attr, ctrl) {


            element.bind('change', function () {
                scope.$apply(function () {
                    ctrl.select(scope.row, 'multiple');
                });
            });

            var count = 0;

            scope.$watch('row.isSelected', function (newValue, oldValue) {
                if (newValue === true) {
                    element.parent().addClass('st-selected');
                    scope.rowSelectedObj = true;
                } else {
                    element.parent().removeClass('st-selected');
                }

                $timeout(function () {
                    var checkboxes = document.querySelectorAll('input[id^="checkbox"]');
                    var empty = [].filter.call(checkboxes, function (el) {
                        return !el.checked;
                    });

                    if (checkboxes.length == empty.length) {
                        scope.rowSelectedObj = false;
                    }
                });


            });
        }
    };
}

// Directive for a checkbox that selects all checkboxes in the current smart table (filtered, if the smart table has been filtered)
function stSelectAll() {
    return {
        restrict: 'E',
        template: '<style-checkbox check-all-selected="isAllSelected" checkbox-id="checkAll"></style-checkbox>',
        require: '^stTable',
        scope: {
            /** Array of row elements */
            all: '=',
            /** Optional callback function with parameter 'row' that returns boolean.
                If result is false, the row is skipped during selected toggle. */
            condition: '&?',
            /** Scope variable indicating if the checkbox is checked */
            allSelected: '=?'
        },
        link: function (scope, element, attrs) {
            scope.$watch('isAllSelected', function () {
                scope.allSelected = scope.isAllSelected;

                if (scope.all) {
                    scope.all.forEach(function (val) {
                        // Skip if condition function given and not met
                        if (scope.condition && !scope.condition({row:val}))
                            return;
                        val.isSelected = scope.isAllSelected;
                    });
                }
            });
        }
    };
}

// Directive for smart table to pull various predicates and create a dropdown selector box based on those predicates.
function stSelectDistinct($parse) {
    return {
        restrict: 'E',
        require: '^stTable',
        scope: {
            collection: '=',
            predicate: '@',
            predicateExpression: '=',
            excludeFromAll: '@',
            allName: '@'
        },
        template: require('../../pages/new-dashboard/sections/approvals/approval-list/dropdown-select-distinct.html'),
        css: require('../../pages/new-dashboard/sections/approvals/approval-list/dropdown-select.scss'),
        link: function (scope, element, attr, table) {
            let allName = scope.allName !== undefined && scope.allName !== null ? scope.allName : 'All';

            var getPredicate = function () {
                var predicate = scope.predicate;
                if (!predicate && scope.predicateExpression) {
                    predicate = scope.predicateExpression;
                }
                return predicate;
            };

            // Set variable to make inital load run once, so that on subsequnet table changes (IE, checkboxes being checked) the table does not refresh all data.
            var dirty = false;

            scope.$watch('collection', function (newValue) {
                var predicate = getPredicate();

                if (newValue) {
                    var temp = [];
                    scope.distinctItems = [allName];

                    let isNumbersOnly = true;
                    angular.forEach(scope.collection, function (item) {
                        var value = item[predicate];

                        if (value && value.toString().trim().length > 0 && temp.indexOf(value) === -1) {
                            temp.push(value);
                            if (isNaN(parseInt(value))) {
                                isNumbersOnly = false;
                            }
                        }
                    });
                    temp.sort((a,b) => isNumbersOnly ? parseInt(a) - parseInt(b) : a.toString().localeCompare(b));

                    scope.distinctItems = scope.distinctItems.concat(temp);

                    // Set dropdown selectors to be allName (or whatever if first in the array) by default on first load, display the table via the optionChanged function, and then set dirty to true to prevent re-running on changes to the table (such as selecting a checkbox).
                    if (dirty === false) {
                        scope.selectedOption = scope.distinctItems[0];
                        scope.optionChanged(scope.selectedOption);
                        dirty = true;
                    }
                }
            }, true);

            scope.optionChanged = function (selectedOption) {
                var predicate = getPredicate();

                var filter_string = predicate;
                var filter = $parse(filter_string);
                filter.assign(scope.$parent, selectedOption);
                scope.selectedOption = selectedOption;
                var query = {};
                query.distinct = selectedOption;
                query.excludeFromAll = scope.excludeFromAll;

                if (query.distinct === allName) {
                    query.distinct = '';
                }

                table.search(query, predicate);
            };
        }
    };
}

// Directive for smart table to pull various predicates and filter using a specified list of options.
function stSelectOptions($timeout) {
    return {
        restrict: 'E',
        require: '^stTable',
        scope: {
            collection: '=',
            predicate: '@',
            options: '=',
            selectedOption: '=',
            selectedOptionChange: '='
        },
        template: require('../../pages/new-dashboard/sections/approvals/approval-list/dropdown-select-options.html'),
        css: require('../../pages/new-dashboard/sections/approvals/approval-list/dropdown-select.scss'),
        link: function (scope, element, attr, table) {
            scope.onOptionChange = (selectedOption) => {
                scope.selectedOption = selectedOption;

                $timeout(() => {
                    if (scope.selectedOptionChange) {
                        scope.selectedOptionChange();
                    }

                    var query = selectedOption.value;
                    table.search(query, scope.predicate);
                });
            };
        }
    };
}

function stDateSearch() {
    return {
        restrict: 'E',
        require: '^stTable',
        scope: {
            date: '=',
        },
        template: '<input id="{{predicateName}}" type="search" class="date-search" ng-model="date"/>',

        link: function (scope, element, attr, table) {

            var dateSearch = angular.element(document.getElementsByClassName("date-search"));
            scope.predicateName = attr.predicate;

                dateSearch.bind('keyup', function () {

                    var query = {};

                    if (scope.date) {
                        query.date = scope.date;
                    }
                    scope.$apply(function () {
                        table.search(query, scope.predicateName);
                    });

                });


        }
    };
}

function stCustomSearch() {
    return {
        restrict: 'E',
        require: '^stTable',
        scope: {
            search: '='
        },
        template: '<input type="search" class="search" ng-model="searchValue" ng-change="doSearch()" />',
        link: function (scope, element, attr, table) {
            scope.predicateName = attr.predicate;

            scope.doSearch = () => {
                if (scope.search) {
                    var query = {
                        fn: scope.search,
                        searchValue: scope.searchValue
                    };

                    table.search(query, scope.predicateName);
                }
            };
        }
    };
}

function angStyledDropdown($parse){
    return {
        template: require('./styled-dropdown.html'),
        scope:{
            object: '=',
            selectedItem: '=',
            value: '@',
            otherfunction: '&'
        },
        link: function( scope, elem, attr) {

            scope.optionChanged = function(value) {
                scope.selectedItem = value;
            };
        }
    };
}

function angStyledDropdownValues($timeout){
    return {
        template: require('./styled-dropdown-values.html'),
        scope:{
            /** {Array<Object> */
            object: '=',
            /** Variable containing what to display for the current selection. Modified upon user selection. */
            selectedItem: '=',
            /** Variable containing current selection value. Modified upon user selection. */
            selectedValue: '=',
            /** Field within object containing the value to use as the displayed name of an item */
            name: '@',
            /** Field within object containing the value to set selectedValue to upon being selected. */
            value: '@',
            /** Callback function to call after selectedItem is set with a new selection. */
            onChange: '&'
        },
        link: function(scope, elem, attr) {
            scope.optionChanged = function(name, value){
                scope.selectedItem = name;
                scope.selectedValue = value;
                if (scope.onChange) {
                    $timeout(() => scope.onChange());
                }
            };
        }
    };
}

function angStyledDropdownObjects($timeout){
    return {
        template: require('./styled-dropdown-objects.html'),
        scope:{
            /** {Array<Object> */
            options: '=',
            /** Current selected object item. Modified upon user selection. */
            selectedItem: '=',
            /** Field within object containing the value to use as the displayed name of an item */
            nameField: '@',
            /** Callback function to call after selectedItem is set with a new selection. */
            onChange: '&'
        },
        link: function( scope, elem, attr) {
            scope.optionChanged = function(item){
                scope.selectedItem = item;
                $timeout(() => scope.onChange());
            };
        }
    };
}

function customFilter($filter) {
    var filterFilter = $filter('filter');

    var standardComparator = function standardComparator(obj, text) {
        text = ('' + text).toLowerCase();
        return ('' + obj).toLowerCase().indexOf(text) > -1;
    };

    return function customFilter(array, expression) {
        function customComparator(actual, expected) {

            var isBeforeActivated = expected.before;
            var isAfterActivated = expected.after;
            var isLower = expected.lower;
            var isHigher = expected.higher;
            var higherLimit;
            var lowerLimit;
            var itemDate;
            var queryDate;

            if (angular.isObject(expected)) {

                //exact match
                if (expected.distinct) {
                    if (!actual || actual.toString().toLowerCase() !== expected.distinct.toString().toLowerCase()) {
                        return false;
                    }

                    return true;
                } else if (expected.excludeFromAll) {
                    if (!actual || actual.toLowerCase() === expected.excludeFromAll.toLowerCase()) {
                        return false;
                    }

                    return true;
                }

                //matchAny
                if (expected.matchAny) {
                    if (expected.matchAny.all) {
                        return true;
                    }

                    if (!actual) {
                        return false;
                    }

                    for (var i = 0; i < expected.matchAny.items.length; i++) {
                        if (actual.toLowerCase() === expected.matchAny.items[i].toLowerCase()) {
                            return true;
                        }
                    }

                    return false;
                }

                //date search
                if (expected.date) {
                    higherLimit = expected.date;
                    queryDate = $filter('date')(higherLimit, 'MM/dd/yyyy');
                    itemDate = $filter('date')(actual, 'MM/dd/yyyy');

                    try {
                        if (itemDate == queryDate) {
                            return true;
                        }
                        return false;
                    } catch (e) {
                        return false;
                    }

                }

                //date range
                if (expected.before || expected.after) {
                    try {
                        if (isBeforeActivated) {
                            higherLimit = expected.before;
                            itemDate = new Date(actual);
                            queryDate = new Date(higherLimit);

                            if (itemDate > queryDate) {
                                return false;
                            }
                        }

                        if (isAfterActivated) {
                            lowerLimit = expected.after;

                            itemDate = new Date(actual);
                            queryDate = new Date(lowerLimit);

                            if (itemDate < queryDate) {
                                return false;
                            }
                        }

                        return true;
                    } catch (e) {
                        return false;
                    }

                } else if (isLower || isHigher) {
                        //number range
                    if (isLower) {
                        higherLimit = expected.lower;

                        if (actual > higherLimit) {
                            return false;
                        }
                    }

                    if (isHigher) {
                        lowerLimit = expected.higher;
                        if (actual < lowerLimit) {
                            return false;
                        }
                    }

                    return true;
                }

                // Custom function
                if (expected.fn) {
                    return expected.fn(actual, expected.searchValue);
                }

                return true;

            }
            return standardComparator(actual, expected);
        }

        var output = filterFilter(array, expression, customComparator);

        return output;
    };
}

function stStatusFilterField(ServiceStatus, $filter, $parse) {
    /*
     * Attribute Params :: Operationally, these parameters act as though they were scope members, however they are not
     * part of the scope! I'm expressing these values in attributes instead of bound scope members to avoid scope
     * performance penalties.
     *
     * Transferred to the action-button::
     *  ng-model
     *  st-search
     *  action-button-id ==> applied as "id"
     *
     * Transferred to the current value text display ::
     *  ui-value-display-id ==> applied as "id"
     *
     */
    return {
        restrict : "E",
        require: ["^ngModel","^stTable"],
        template: require("./st-status-filter-field.html"),
        css: require('./st-status-filter-field.scss'),
        /**
         * @param $scope
         * @param element
         * @param attrs
         * @param {Array} requiredControllers
         *  This one is totally influenced by the *require* member. Per NG's DDO syntax, the Array membership
         *  will follow the sequence of the entries in the *require* list. Hence, we're pulling in an normal
         *  Angular NgModelController and a 3rdParty SmartTableController.
         */
        link: function ($scope, element, attrs, requiredControllers) {

            let
                /** Angular.NgModelController */
                ngModelController = requiredControllers[0],
                /** StTableController */
                smartTableController = requiredControllers[1],
                /** function(String) : String */
                dmToUiText =(s)=> $filter("serviceStatusFilter")(s),
                /** HtmlElement */
                valueDisplayElem = element[0].querySelector("BUTTON").querySelector(".current-value-display");

            ngModelController.$render =()=>
                angular.element(valueDisplayElem).html(dmToUiText(ngModelController.$modelValue));


            $scope.onStatusChange = (newVal) => {
                smartTableController.search({ distinct : newVal }, attrs.stSearch );
                ngModelController.$setViewValue(newVal);
                ngModelController.$render();
            };

            $scope.onClearFilter = () => {
                smartTableController.search({ distinct : "" }, attrs.stSearch );
                ngModelController.$setViewValue("All");
                ngModelController.$render();
            };

            let statusTypes = ServiceStatus; // A direct injection from inside this package registration.
            $scope.statusTypes = statusTypes;

            $scope.actionButtonId = attrs.actionButtonId;
            $scope.uiValueDisplayId = attrs.uiValueDisplayId;
            $scope.ngModel = attrs.ngModel;
            $scope.stSearch = attrs.stSearch;

            /* If the collection attribute is specified, we watch the collection and update the dropdown to only contain
             * values present in the collection */
            if (attrs.collection) {
                $scope.$watch(function() {
                    return $parse(attrs.collection)($scope);
                }, function (newValue) {
                    if (newValue) {
                        let temp = Object.assign({}, statusTypes);
                        let foundValues = {};

                        angular.forEach(newValue, function (item) {
                            let value = item[attrs.stSearch];
                            if (!statusTypes[value]) {
                                return;
                            }

                            foundValues[value] = true;
                        });

                        /* We intentionally make a copy the full serviceStatus object and delete values not found in the
                         * collection rather than make a new object and add values in the collection to it. This allows
                         * us to maintain the order of options as they are in the serviceStatus object. */
                        for(let key in temp) {
                            if (!foundValues[key]) {
                                delete temp[key];
                            }
                        }

                        $scope.statusTypes = temp;
                    }
                }, true);
            }

            // :::::::: ::::: ::: :: : : Bootstrap this instance : : :: ::: ::::: ::::::::

            if (angular.isDefined(attrs.initialValue)) {
                $scope.ngModel = dmToUiText(attrs.initialValue);
                $scope.onStatusChange(attrs.initialValue);
            }
        }
    };
}
