'use strict';

import BaseService from './base.service.js';

/**
 * Service for managing InsurancePolicies.
 */
export default class InsurancePolicyService extends BaseService {

    /**
     * GET all policies for a patient.
     *
     * @param patient to get policies for
     * @return Promise to an InsurancePolicy.List DTO in priority order
     */
    getForPatient(patient) {
        return this.serverAPI.get(patient._links.insurancePolicies);
    }

    /**
     * Create an InsurancePolicy.
     *
     * @param patient Patient to add InsurancePolicy to
     * @param policy {InsurancePolicy} content for new InsurancePolicy
     * @return Promise to created InsurancePolicy DTO
     */
    createForPatient(patient, policy) {
        return this.serverAPI.post(patient._links.insurancePolicies, {}, policy);
    }

    /**
     * Set the medicare flag on a list of InsurancePolicies.
     *
     * @param insurancePolicies InsurancePolicy.List DTO (result of getForPatient())
     * @param isMedicare {boolean} new flag value
     * @return Promise to updated InsurancePolicy.List DTO
     */
    async setMedicareFlag(insurancePolicies, isMedicare) {
        for (let policyIdx = 0; policyIdx < insurancePolicies.list.length; ++policyIdx) {
            let policy = insurancePolicies.list[policyIdx];

            if (policy.medicare != isMedicare) {
                policy.medicare = isMedicare;
                insurancePolicies.list[policyIdx] = await this.update(policy);
            }
        }

        return insurancePolicies;
    }

    /**
     * Check the InsurancePolicy.List for a Patient to see if any are Medicare.
     *
     * @param insurancePolicies InsurancePolicy.List DTO (result of getForPatient())
     * @return boolean - true if any policy is Medicare.
     */
    isMedicare(insurancePolicies) {
        for (let policy of insurancePolicies.list) {
            if (policy.medicare)
                return true;
        }

        return false;
    }
}
