'use strict';

import angular from 'angular';

export default angular.module('widgets.mixing', [])
    .directive('agMixingNavBar', ["$location", "$timeout", agMixingNavBar])
    .directive('agMixingFooter', ["$location", "$uibModal", agMixingFooter])
    .directive('mixingVialTable', mixingVialTable)
    .name;

function agMixingNavBar($location, $timeout) {
    return {
        windowClass: require('./mixing-navbar.scss'),
        template: require('./mixing-navbar.html'),
        scope: false,
        link: function (scope, element, attrs) {

            scope.getNumber = function (num) {
                return new Array(num);
            };

            var currentPath = $location.path();
            currentPath = currentPath.substr(currentPath.lastIndexOf('/') + 1);

            scope.$watchGroup(
                ["vialTabNames", "antigenCount", "isClassical", "isMixExternal"],
                function () {
                    if (!scope.vialTabNames)
                        return;

                    scope.showPrepareVialsTab = scope.antigenCount > 0 && !scope.isClassical;
                    // Rebuild tab list based on current values
                    scope.classNames = ['details'];
                    if (!scope.isMixExternal) {
                        scope.classNames.push('checklist');
                        if (scope.showPrepareVialsTab)
                            scope.classNames.push('prepare');

                        for (let i = 1; i <= scope.vialTabNames.length; i++)
                            scope.classNames.push('vial' + i);

                    }
                    else {
                        for (let i = 1; i <= scope.vialTabNames.length; i++) {
                            scope.classNames.push('external-vial' + i);
                        }
                    }
                    scope.classNames.push('review');

                    scope.cur = function (className) {
                        return currentPath === className;
                    };

                    scope.next = function (className) {
                        for (let i = 0; i < scope.classNames.length; i++) {
                            if (currentPath === scope.classNames[i] && className == scope.classNames[i + 1]) {
                                return true;
                            }
                        }
                        return false;
                    };

                    scope.prev = function (className) {
                        var i = 0;
                        for (i = 0; i <= scope.classNames.length; i++) {
                            if (currentPath === scope.classNames[i]) {
                                var j = 0;
                                for (j = 0; j <= scope.classNames.length; j++) {
                                    if (className == scope.classNames[j] && j < i) {
                                        return true;
                                    }
                                }
                                return false;
                            }
                        }
                        return false;
                    };
                }
            );

        }
    };
}

function agMixingFooter($location, $uibModal) {
    return {
        //restrict: 'E',
        windowClass: require('./mixing-footer.scss'),
        template: require('./mixing-footer.html'),
        scope: false,
        link: function (scope, element, attrs) {

            var url = $location.url();

            if (url.indexOf("review") > -1) {
                scope.reviewPage = true;
            }

            scope.transferRxModal = () => {
                $uibModal.open({
                    scope: scope, // Pass current scope to the modal
                    template: require('./transfer-prescription-modal.html'),
                    css: require('./transfer-prescription-modal.scss'),
                    controller: function ($uibModalInstance, $scope) {

                        $scope.cancel = () => $uibModalInstance.dismiss();
                        $scope.ok = () => $uibModalInstance.close();
                    }
                });
            };

            scope.exitWizard = () => {
                if (!scope.isReadyToMix) {
                    // If function to check for mix readiness isn't defined, then can't do
                    // a mix here. Do a simple exit.
                    scope.saveAndExit();
                }
                else {
                    $uibModal.open({
                        scope: scope,
                        size: 'md',
                        template: require('./end-mixing-wizard-modal.html'),
                        css: require('./end-mixing-wizard-modal.scss'),
                        controller: ($uibModalInstance, $scope) => {

                            $scope.isReadyToMix = scope.isReadyToMix();
                            $scope.isRefusal = false;

                            $scope.cancel = () => $uibModalInstance.dismiss();

                            $scope.continue = () => {
                                const doMix = $scope.endAction === 'mix';
                                if ($scope.isRefusal) {
                                    $uibModalInstance.dismiss();
                                }
                                else if (doMix && !$scope.isReadyToMix) {
                                    $scope.isRefusal = true;
                                }
                                else {
                                    scope.saveAndExit({ doMix: doMix });
                                    $uibModalInstance.close();
                                }
                            }
                        }
                    });
                }
            };
        }
    };
}

function mixingVialTable() {
    return {
        template: require('./mixing-vial-table.html'),
        css: require('./mixing-vial-table.scss'),
        link: function (scope, elem, attrs) {
            scope.editingData = {};
        }
    };
}