'use strict';

import BaseService from './base.service.js';

export default class VisitService extends BaseService {

    /**
     * Get visit history in an Office
     *
     * @param office DTO of Office to search
     * @param start only visits after or at this time
     * @param end only visits before or at this time
     * @return Promise to an Visit.List DTO sorted by checkinTime
     */
    getAtOffice(office, start, end) {
        return this.serverAPI.get(office._links.visits,{
            start: start,
            end: end
        });
    }

    /**
     * Get visit history for a Patient.
     *
     * @param patient DTO of Patient to search
     * @param start only visits after or at this time
     * @param end only visits before or at this time
     * @return Promise to an Visit.List DTO sorted by checkinTime
     */
    getForPatient(patient, start, end) {
        return this.serverAPI.get(patient._links.visits,{
            start: start,
            end: end
        });
    }

    /**
     * Check-out the patient from this Visit.<p/>
     * Only available if checkoutTime == null.
     *
     * @param visit DTO
     * @return Promise to a Visit DTO
     */
    checkout(visit) {
        return this.serverAPI.put(visit._links.checkout, {});
    }
}
