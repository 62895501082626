"use strict";

import angular from 'angular';
import BaseController from './../base.controller.js';

export default class DashboardController extends BaseController {

    static $inject = ['$scope', '$injector', 'activeSection'];

    /**
     * @param {Angular.Scope} $scope
     *   a normal Scope instance Angular controllers manage
     *
     * @param {injectorProvider} $injector
     *  my superclass needs this
     *
     * @param {DashboardSection} activeSetion
     *   a scalar DashboardSection value representing which section we wish to access
     */
    constructor($scope, $injector, activeSection) {
        super($scope, $injector);
        let DashboardSections = $injector.get('DashboardSections');

        // Initial model state establishment

        if (activeSection) {
            this.sessionService.set(this.sessionService.KEY_DASHBOARD_TAB, activeSection);
        } else {
            // Dashboard tab was clicked. Reselect the last used dashboard section or set to the default.
            activeSection = this.sessionService.get(this.sessionService.KEY_DASHBOARD_TAB);
            if (!activeSection) {
                activeSection = $scope.user.roles.includes('DOCTOR') ? DashboardSections.APPROVALS : DashboardSections.APPOINTMENTS;
            }
        }

        /* Send user back to kiosk page if they click back in the browser to reach the dashboard from the kiosk page */
        if (this.userService.isSelfServiceUser(this.$scope.user)) {
            this.routeToPage(this.urlPaths.KIOSK_PAGE);
        }

        this.$scope.activeSection = activeSection;
    };

}
