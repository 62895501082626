'use strict';

import angular from 'angular';
import moment from 'moment';
import BaseMixingController from '../../base-mixing.controller'
import { ClassicalDilution,ClassicalDilutions,toPascalCase } from "../../../../models/classical-dilutions";

export default class BaseMixingPrescriptionDetailsController extends BaseMixingController {

    static $inject = ['$scope', '$injector'];

    static GENERIC_REAPPROVAL_NOTE = 'Prescription has been updated and needs re-approval';

    constructor($scope, $injector) {
        super($scope, $injector);

        this._initInjections($injector);

        $scope.changeBoard = (board) => this.changeBoard(board);
        $scope.changeDiluent = (data, vialIdx) => this.changeDiluent(data, vialIdx);
        $scope.changeAntigen = (data) => this.changeAntigen(data);
        $scope.changeClassicalDilutions = () => this.changeClassicalDilutions();
        $scope.showTraditionalColor = (prescribedSubstance) => this.showTraditionalColor(prescribedSubstance);

        $scope.rxChangeRequest = false;
        $scope.nextButtonLabel = "Begin Mixing";
        $scope.text = "Review Prescription Details and Apply Labels to Vials";

        this.disableNext = true;
        this.concentrates = null;
        this.boardCache = {};
        this.changes = { NEW_BOARD: null, BOARD_ERROR: null, NEW_TRADITIONAL_DILUTION: null, DILUENT_CHANGE: null, ANTIGEN_CHANGE: null };

        let concentratePromise = this.$q.resolve();
        this.prescriptionLoaded([this.TAB_DETAILS], true)
            .then(() => this._loadBoardAndTreatmentConfig())
            .then(() => {
                concentratePromise = this._checkConcentrates();
                return super.loadUserActionNames();
            })
            .then(() => super.loadPreviousMixes())
            .then(() => this._populateSubstanceNames())
            .then(() => {
                this.$scope.maximumDilution = this.$scope.practice.config.mixDilutionsCount;
                return this._loadAvailableDiluents();
            })
            .then(() => {
                console.log("Prescription Details loaded");
                return this._populateDisplay(concentratePromise);
            })
            .then(() => this.$timeout())
            .catch(ex => console.error(ex));
    }

    _initInjections($injector) {
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$uibModal = $injector.get('$uibModal');
        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.concentrateService = $injector.get('concentrateService');
        this.substanceService = $injector.get('substanceService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.BoardArrangement = $injector.get('BoardArrangement');
        this.Procedure = $injector.get('Procedure');
        this.ServiceStatus = $injector.get('ServiceStatus');
    }

    /**
     * Populate this.treatmentConfig with the TreatmentConfig used by the current prescription.
     *
     * @returns {Promise}
     * @private
     */
    _loadBoardAndTreatmentConfig() {
        let q = [];

        let allBoardsPromise = this.boardService.getAtOffice(this.$scope.office, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS).then((boards) => {
            this.$scope.allBoards = boards.list;
            this.$scope.isTraditionalArrangement = boards.list.some(board => board.arrangement === this.BoardArrangement.TRADITIONAL);

            this.isDilutedVialRx = this.pscript.vials.filter(v => v.containsAntigen).every(v => v.reason === 'DILUTE_VIAL');
            if (this.pscript.mixingBoard && !this.isDilutedVialRx) {

                let selectedMixingBoard = boards.list.find(board => board.id === this.pscript.mixingBoard.id);
                let selectedMixingBoardPromise = this.$q.resolve(selectedMixingBoard);
                if (!selectedMixingBoard) {
                    /* If the board belongs to another office or is not in service, we need to retrieve it here */
                    selectedMixingBoardPromise = this.boardService.get(this.pscript.mixingBoard).then(board => {
                        this.$scope.allBoards.push(board);
                        return board;
                    });
                }

                return selectedMixingBoardPromise.then(selectedMixingBoard => {
                    this.initialMixingBoard = this.$scope.selectedMixingBoard = selectedMixingBoard;
                    return this._checkBoardValidity(this.initialMixingBoard);
                });
            }
        });
        q.push(allBoardsPromise);

        let treatmentConfigPromise = this._loadTreatmentConfigs().then((treatmentConfigMap) => {
            this.treatmentConfig = treatmentConfigMap.get(this.pscript.treatmentConfigs[0].treatmentConfig.id);
            if (this.pscript.treatmentConfigs.length == 1) {
                this.$scope.dosingSchedule = this.treatmentConfig.name;
            }
            else {
                this.$scope.dosingSchedules = [];
                for (let configEntry of this.pscript.treatmentConfigs) {
                    let treatmentConfig = treatmentConfigMap.get(configEntry.treatmentConfig.id);
                    this.$scope.dosingSchedules.push({
                        color: toPascalCase(ClassicalDilutions[configEntry.dilution].color),
                        name: treatmentConfig.name
                    });
                }
            }

        });
        q.push(treatmentConfigPromise);

        return this.$q.all(q);
    }

    // Load each of the treatmentConfigs on the RX only once
    async _loadTreatmentConfigs() {
        let treatmentConfigMap = new Map();
        for (let configEntry of this.pscript.treatmentConfigs) {
            let treatmentConfig = treatmentConfigMap.get(configEntry.id);
            if (!treatmentConfig) {
                treatmentConfig = await this.treatmentConfigService.get(configEntry.treatmentConfig);
                treatmentConfigMap.set(treatmentConfig.id, treatmentConfig);
            }
        }
        return treatmentConfigMap;
    }

    _checkBoardValidity(mixingBoard) {
        let boardPromise = null;
        if (this.boardCache[mixingBoard.id]) {
            boardPromise = this.$q.resolve(this.boardCache[mixingBoard.id]);
        }
        else {
            boardPromise = this.boardService.getWithCurrentVials(mixingBoard);
        }

        return boardPromise.then(board => {
            this.mixingBoard = board;
            this.boardCache[board.id] = board;

            let invalidSubstances = this.boardService.getOutOfServiceSubstanceNames(board);
            if (invalidSubstances.length > 0) {
                this.$scope.boardError = `One or more substances on the board ${board.name} are not in service`;
                this._prescriptionNeedsReApproval('BOARD_ERROR', this.$scope.boardError, true);
            }
        });
    }

    async _checkConcentrates() {
        this.concentrates = (await this.concentrateService.getInServiceAtOffice(this.$scope.office)).list;

        if (this.isClassical) {
            return this._checkTraditionalConcentrates();
        }
        else {
            return Promise.resolve();
        }
    }

    /* Special method. A tray vial concentrate might be valid but the concentrate is depleted in which case it would not have been provided in the return value from the call
    * to concentrateService.getInServiceAtOffice */
    async _getConcentrate(concentrateRef) {
        let concentrate = this.concentrates.find(conc => conc.id === concentrateRef.id);
        if (!concentrate) {
            concentrate = await this.concentrateService.get(concentrateRef);
        }
        return concentrate;
    }

    /**
     * For Traditional only.
     * Update prescribed substances with warning message if their expected concentrate or tray vial is not in service.
     *
     * @returns {Promise}
     * @private
     */
    async _checkTraditionalConcentrates() {
        let checkTrayVials = this.$scope.isTraditionalArrangement && this.mixingBoard && this.pscript.diluteTo !== ClassicalDilutions[0].color;
        let mixableVials = this.pscript.vials.filter(m => m.containsAntigen && (this.$scope.isTraditionalArrangement || m.classicalDilution === ClassicalDilutions[0].color));
        let relevantTrayVials = checkTrayVials ? this.mixingBoard.vials.filter(m => m.dilution === ClassicalDilution[this.pscript.diluteTo].order) : [];

        for(let vial of mixableVials) {
            for(let ps of vial.substances) {
                if (!ps._category._isAntigen) {
                    continue;
                }

                if (checkTrayVials) {
                    let trayVial = relevantTrayVials.find(m => m.substance.id === ps.substance.id);
                    if (!trayVial) {
                        this._setInventoryError(ps);
                    }

                    let concentrate = await this._getConcentrate(trayVial.concentrate);
                    if (!(concentrate && trayVial.status === this.ServiceStatus.IN_SERVICE && concentrate.potency === ps.concPotency && concentrate.potencyUnit === ps.potencyUnit)) {
                        this._setInventoryError(ps);
                    }
                }
                else {
                    let concentrate = this.concentrates.find(m => m.substance.id === ps.substance.id && m.potency === ps.concPotency && m.potencyUnit === ps.potencyUnit);
                    if (!concentrate) {
                        this._setInventoryError(ps);
                    }
                }
            }
        }
    }

    _setInventoryError(prescribedSubstance, message) {
        prescribedSubstance.changeRequest = message || 'This substance is not available for this formulation in inventory';
        prescribedSubstance._error = prescribedSubstance.changeRequest;
        this._prescriptionNeedsReApproval('BOARD_ERROR', 'One or more substances is not available for their desired formulation in inventory', true);
    }

    nextDisabled() {
        return super.nextDisabled();
    }

    _prescriptionNeedsReApproval(reason, note, isMandatory) {
        this.changes[reason] = note || BaseMixingPrescriptionDetailsController.GENERIC_REAPPROVAL_NOTE;
        this.$scope.nextButtonLabel = "Submit for Approval";
        this.$scope.rxChangeRequest = true;
        this.$scope.rxChangeRequestMandatory = this.$scope.rxChangeRequestMandatory || isMandatory;
    }

    updateUiDetails(nextButtonLabel, instructions) {
        this.initialNextButtonLabel = nextButtonLabel;
        this.$scope.text = instructions;
        this._reevaluateChanges();
    }

    async _sendChangeRequest() {
        // Change notes that aren't falsey
        let changeNotes = Object.values(this.changes).filter(function (change) { return change; });

        let allChanges = new Set([BaseMixingPrescriptionDetailsController.GENERIC_REAPPROVAL_NOTE, ...changeNotes]);
        let changeRequestNote = Array.from(allChanges).join('. ');

        // Update server and send back for approval
        this.updateFooterNote();
        await this.prescriptionService.updateAndRequestChangeApproval(this.pscript, changeRequestNote);
        // Exit
        this.$scope.$apply(() => this.exitToDashboard());
    }

    async _loadAvailableDiluents() {
        this.$scope.availableDiluents = new Map();

        for (let iVial = 0; iVial < this.pscript.vials.length; iVial++) {
            this.$scope.availableDiluents.set(iVial, []); // initializing Map with empty arrays
        }

        // Any negative control defined by the system may be used as a diluent.
        let negControls = await this.substanceService.getByType(this.$scope.practice, this.substanceService.NEGATIVE_CONTROL);

        for (let aSubstance of negControls) {
            aSubstance._isControl = true;

            for (let iVial = 0; iVial < this.pscript.vials.length; iVial++) {
                if (false == this._isSubstanceInVial(aSubstance, iVial)) {
                    let availableToVialArray = this.$scope.availableDiluents.get(iVial);
                    if (!this._isDTOInArray(availableToVialArray, aSubstance)) {
                        aSubstance._category = await this.substanceCategoryService.get(aSubstance.category, negControls.list);
                        availableToVialArray.push(aSubstance);
                    }
                }
            }
        }
    }

    /**
     * Check for the presence of a DTO in an array using identity comparison.
     * @param array
     * @param dto
     * @returns {boolean} true if found, false if not.
     * @private
     */
    _isDTOInArray(array, dto) {
        for (let element of array)
            if (element.id === dto.id)
                return true;
        return false;
    }

    /**
     *
     * @param argPanelSubstance
     * The substance whose presence or absence in the current subject prescription we want to ascertain.
     * Note this species of the operand 'substance' object is not of the same species as the values
     * through which we'll be iterating; the arg substance comes from the PanelService, whereas the
     * substance representations attached the RX.vials.substances come the SubstanceService. Origin
     * stories aside, the types structurally differ in the PanelSubstance objects "id" field is a direct
     * child, whereas SubstanceService substances keep their "id" inside their "substance" children.
     *
     * @param vialIndex
     * @returns {boolean}
     * True : when the RX vial #[vialIndex] contains the operand substance
     * False : if the vial is totally devoid of the chemical agent
     *
     * @private
     */
    _isSubstanceInVial(argPanelSubstance, vialIndex) {
        let theVial = this.pscript.vials[vialIndex];

        for (let aSubstance of theVial.substances) {
            if (aSubstance.substance.id === argPanelSubstance.id)
                return true;
        }
        return false;
    }

    _earliestDate(a,b) {
        if (a.isBefore(b)) {
            return a;
        }

        return b;
    }


    /* Evaluate the use by date of the concentrates which will be used according to the mixing board which will be used. If any expire before the prescription
     * vialsUseBy date, present a modal notifying the user */
    async _evaluateBeyondUse() {

        let substancesExpiringEarly = [];
        let substancePromises = [];
        let vialsUseBy = moment(this.pscript.vialsUseBy);
        this.pscript.clientData.beyondUseDate = this.pscript.clientData.beyondUseDate || {};
        for(let vial of this.pscript.vials) {
            if (!vial.containsAntigen) {
                continue;
            }

            let bud = moment().add(365, 'days');
            for(let ps of vial.substances) {
                if (ps.substanceTreatmentVial) {
                    let treatmentVial = await this.treatmentVialService.get(ps.substanceTreatmentVial);
                    let treatmentVialBeyondUse = moment(treatmentVial.beyondUse);
                    if (treatmentVialBeyondUse.isBefore(vialsUseBy)) {
                        substancesExpiringEarly.push(treatmentVial.name);
                    }
                    bud = this._earliestDate(bud, treatmentVialBeyondUse);
                }
                else if (!ps._category._isAntigen || ps.dilution === 0) {
                    continue;
                }
                else {
                    let trayVial = this.mixingBoard
                        ? this.mixingBoard.vials.find(vial => vial.dilution === ps.dilution && vial.substance.id === ps.substance.id)
                        : null;

                    if (!trayVial) {
                        continue;
                    }

                    let concentrate = await this._getConcentrate(trayVial.concentrate);
                    let concentrateUseBy = moment(concentrate.useBy);
                    if (concentrateUseBy.isBefore(this.pscript.vialsUseBy)) {
                        let substancePromise = this.substanceService.get(concentrate.substance).then((substance) => {
                            concentrate._substance = substance;
                            substancesExpiringEarly.push(concentrate._substance.name);
                        });
                        substancePromises.push(substancePromise);
                    }

                    bud = this._earliestDate(bud, concentrateUseBy);
                }
            }
            vial._bud = bud.format('YYYY-MM-DD');

            /* Store beyond use dates here until they can be established and generated for actual treatment vials */
            this.pscript.clientData.beyondUseDate[vial.id] = vial._bud;
        }

        await Promise.all(substancePromises);
        if (substancesExpiringEarly.length === 0) {
            return;
        }

        let parent = this;
        let modal = this.$uibModal.open({
            windowClass: 'earlyExpirationModal',
            scope: this.$scope,
            template: require('./widgets/early-expiration-modal.html'),
            css: require('./widgets/early-expiration-modal.scss'),
            backdrop: 'static',
            controller: function ($uibModalInstance, $scope) {

                let substanceList = substancesExpiringEarly.join(', ');
                let mixingBoard = parent.initialMixingBoard;

                $scope.vials = parent.pscript.vials;
                $scope.hasMixingBoard = !!mixingBoard;

                if (mixingBoard) {
                    $scope.expirationWarningMessage = `Substance(s) ${substanceList} on board ${mixingBoard.name} will expire before the vial you are mixing would expire.`;
                }
                else {
                    $scope.expirationWarningMessage = `Substance(s) ${substanceList} will expire before the vial you are mixing would expire.`;
                }

                $scope.selectChoice = (choice) => {
                    $scope.choice = choice;
                }

                $scope.continue = () => {
                    if ($scope.choice === 'QUIT') {
                        parent.exitToDashboard();
                    }
                    else if ($scope.choice !== 'CHANGE_BOARD' && parent.initialMixingBoard) {
                        /* Reset selected board if not changing the board */
                        parent.changeBoard(parent.initialMixingBoard);
                    }

                    $uibModalInstance.close();
                };
            }
        });

        await modal.result;
    }


    /**
     * Set $scope for the view
     * @private
     */
    async _populateDisplay(concentratePromise) {
        this.$scope.mix = this.pscript;
        this.initFooterNote('Details');

        for(let otherAction of this.pscript.otherActions) {
            otherAction._displayNote = otherAction.note;
            if (otherAction.type === 'APPROVE' && !otherAction.note) {
                otherAction._noteType = 'Approval';
                otherAction._displayNote = 'Prescription was approved.';
            }
        }

        // Set vial size
        for(let vial of this.pscript.vials) {
            vial._isDilutedVial = vial.substances.some(s => s.substanceVialId || s.substanceTreatmentVial);

            if (this.pscript.treatmentType === 'SCIT') {
                vial.vialSize = this.treatmentConfig.scit.vialSize;
            }
            else if (this.pscript.treatmentType === 'SLIT') {
                vial.vialSize = this.prescriptionService.isEscalationVial(vial) ? this.treatmentConfig.slit.vialSizeEsc : this.treatmentConfig.slit.vialSizeMaint;
            }
        }

        await concentratePromise;
        await this._evaluateBeyondUse();

        this.disableNext = false;
        this.disableExit = false;
    }

    /**
     * Set _name on each PrescribedSubstance.
     * @returns {Promise.<void>} that resolves upon completion.
     * @private
     */
    async _populateSubstanceNames() {
        for (let prescribedVial of this.pscript.vials) {
            for (let prescribedSubstance of prescribedVial.substances) {
                if (prescribedSubstance.substanceVialId) {
                    prescribedSubstance._name = 'Vial ' + super.getVialById(prescribedSubstance.substanceVialId).name;
                }
                else if (prescribedSubstance.substanceTreatmentVial) {
                    let sourceVial = await this.treatmentVialService.get(prescribedSubstance.substanceTreatmentVial);
                    prescribedSubstance._name = sourceVial.name;
                }
                else {
                    prescribedSubstance._name = prescribedSubstance._substance.name;
                }
            }
        }
    }

    /**
     * Show the Prescription Labels dialog
     * @param becauseExpired {boolean} true if must print because the old labels expired. false for first time.
     * @private
     */
    _printLabelsDialog(becauseExpired) {
        if (becauseExpired) {
            console.log("Show dialog that the old labels have expired, so have to print new one.");
            return this._printLabelsModal('expiredPrescription');
        }
        else {
            console.log("Show dialog that labels haven't been printed yet. Must do so.");
            return this._printLabelsModal();
        }
    }

    _reevaluateChanges() {
        let hasChanges = false;
        for(let key in this.changes) {
            if (this.changes[key]) {
                hasChanges = true;
            }
        }

        if (!hasChanges) {
            this.$scope.nextButtonLabel = this.initialNextButtonLabel;
            this.$scope.rxChangeRequest = false;
        }
    }

    changeBoard(board) {
        this.$scope.selectedMixingBoard = board;

        if (!this.initialMixingBoard || board.id !== this.initialMixingBoard.id) {
            let message = 'Requesting to change mixing board to ' + board.name;
            this._prescriptionNeedsReApproval('NEW_BOARD', message, false);
        }
        else {
            this.changes.NEW_BOARD = null;
            this._reevaluateChanges();
        }
    }

    changeDiluent(data, vialIdx) {
        if (this.isClassical) {
            this.changeClassicalSubstance(data, 'Preservative');
            return;
        }

        // Otolaryngic
        let parent = this;

        this.$uibModal.open({
            windowClass: 'changeDiluent',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/change-diluent-modal.html'),
            css: require('./widgets/change-diluent-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.data = data;
                $scope.noDiluent = 'There are no additional diluents available for selection.';
                let diluents = [];
                $scope.availableDiluents.get(vialIdx).forEach(substance => diluents.push(substance));
                $scope.diluents = diluents;
                $scope.diluentChangeItem = $scope.data._substance.name;

                $scope.changeDiluent = function (diluent) {
                    $scope.diluentChangeItem = diluent.name;
                    $scope.changeDiluentSubstance = diluent;
                };


                $scope.AddDiluent = function (diluent) {
                    $scope.diluentAddItem = diluent.name;
                    let prescribedSubstance = {
                        "name": diluent.name,
                        'dilution': 0,
                        'dosage': document.getElementById('addDiluentDosage').value,
                        'substance': {
                            'id': diluent.id
                        },
                        "_substance": diluent,
                        "_category": diluent._category,
                        "antigenChanged": true
                    };
                    $scope.addNewDiluent = prescribedSubstance;
                };

                $scope.changeDosage = function (dosage) {
                    dosage = document.getElementById('addDiluentDosage');
                    $scope.selecteddosage = dosage.value;
                    $scope.volumeNeeded = false;
                };

                $scope.save = () => {
                    if ($scope.formdata.changeDiluentCheck) {
                        data.substance.id = $scope.changeDiluentSubstance.id;
                        data.substance.href = $scope.changeDiluentSubstance.href;
                        data._substance = $scope.changeDiluentSubstance;
                        $scope.data.antigenChanged = true;
                        if (document.getElementById('changeOther').value !== "") {
                            $scope.data.changeRequest = document.getElementById('changeOther').value;
                        } else {
                            $scope.data.changeRequest = "Diluent Unavailable";
                        }
                    }

                    if ($scope.formdata.addDiluentCheck) {
                        if ($scope.selecteddosage === "" || $scope.selecteddosage === undefined) {
                            $scope.volumeNeeded = true;
                            return false;
                        }
                        if (document.getElementById('changeOther').value !== "") {
                            $scope.addNewDiluent.changeRequest = document.getElementById('changeOther').value;
                        } else {
                            $scope.addNewDiluent.changeRequest = "Diluent Unavailable";
                        }
                        $scope.addNewDiluent.dosage = $scope.selecteddosage;
                        $scope.$parent.mix.vials[vialIdx].substances.push($scope.addNewDiluent);
                    }

                    parent._prescriptionNeedsReApproval('DILUENT_CHANGE', null, false);
                    $uibModalInstance.close();
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

            }
        });
    }

    changeAntigen(data) {
        if (this.isClassical) {
            this.changeClassicalSubstance(data, 'Antigen');
            return;
        }

        // Otolaryngic
        let parent = this;
        this.$uibModal.open({
            windowClass: 'changeAntigen',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/change-antigen-modal.html'),
            css: require('./widgets/change-antigen-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.data = data;
                $scope.selectedDilution = $scope.data.dilution;

                $scope.$watch('selectedDilution', () => {
                    $scope.diluentSelected = true;
                    $scope.changeDetected = true;
                });

                $scope.changeDosage = function (dosage) {
                    dosage = document.getElementById('changeDosage');
                    $scope.selecteddosage = dosage.value;
                };
                $scope.save = () => {
                    if (document.getElementById('changeOther').value !== "") {
                        $scope.data.changeRequest = document.getElementById('changeOther').value;
                    } else {
                        $scope.data.changeRequest = "Antigen Unavailable";
                    }
                    $scope.data.dosage = $scope.selecteddosage || $scope.data.dosage;
                    $scope.data.dilution = $scope.selectedDilution;
                    $scope.data.antigenChanged = true;
                    parent._prescriptionNeedsReApproval('ANTIGEN_CHANGE', null, false);
                    $uibModalInstance.close();
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

            }
        });
    }

    changeClassicalSubstance(data, substanceType) {
        let parent = this;
        this.$uibModal.open({
            windowClass: 'changeClassicalSubstance',
            scope: this.$scope,
            template: require('./widgets/change-classical-substance-modal.html'),
            css: require('./widgets/change-classical-substance-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.reasons = [
                    'Not enough ' + substanceType + ' volume to mix',
                    substanceType + ' unavailable',
                    'Other'
                ];
                $scope.otherNote = '';
                $scope.substanceType = substanceType;
                $scope.selection = null;

                $scope.select = (reason) => {
                    $scope.selection = reason;
                };
                $scope.save = () => {
                    data.changeRequest = ($scope.selection === 'Other') ? $scope.otherNote : $scope.selection;
                    data.antigenChanged = true;

                    parent._prescriptionNeedsReApproval('SUBSTANCE_CHANGE', null, false);
                    $uibModalInstance.close();
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

            }
        });
    }

    changeClassicalDilutions() {
        const parent = this;
        const originalCount = this.pscript.diluteTo
            ? ClassicalDilution[this.pscript.diluteTo].order + 1
            : this.pscript.treatmentConfigs.length;

        this.$uibModal.open({
            windowClass: 'changeClassicalDilutions',
            scope: this.$scope,
            template: require('./widgets/change-classical-dilutions-modal.html'),
            css: require('./widgets/change-classical-dilutions-modal.scss'),
            resolve: {
                isRxTraditionalArrangement: () => this.$scope.isTraditionalArrangement
            },
            controller: ['$uibModalInstance', '$scope', 'isRxTraditionalArrangement', function ($uibModalInstance, $scope, isRxTraditionalArrangement) {
                $scope.originalCount = originalCount;
                $scope.dilutionCount = originalCount;
                $scope.ClassicalDilutions = ClassicalDilutions;
                $scope.changeNote = '';
                $scope.selections = [ true, true, true, true, true ];
                $scope.isRxTraditionalArrangement = isRxTraditionalArrangement;

                $scope.toggle = (order) => {
                    setTimeout(() => {
                        const isSet = $scope.selections[order];
                        $scope.dilutionCount = isSet ? order + 1 : order;
                        for (let i = 0; i < ClassicalDilutions.length; ++i) {
                            $scope.selections[i] = isRxTraditionalArrangement ? i === order : (i < $scope.dilutionCount);
                        }

                        $scope.$digest();
                    });
                };
                $scope.toggle($scope.dilutionCount-1);

                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.save = () => {
                    parent.pscript.diluteTo = ClassicalDilutions[$scope.dilutionCount-1].color;
                    if (isRxTraditionalArrangement) {
                        parent.pscript.treatmentConfigs[0].dilution = ClassicalDilutions[$scope.dilutionCount-1].order;
                    }

                    if ($scope.dilutionCount > originalCount) {
                        let changeDescription = isRxTraditionalArrangement ? 'change dilution' : 'increase the number of dilutions';
                        parent.$scope.classicalDilutionsChanged = true;
                        parent._prescriptionNeedsReApproval(
                            'NEW_TRADITIONAL_DILUTION',
                            'Requesting approval to ' + changeDescription + ' to ' + ClassicalDilutions[$scope.dilutionCount-1].color.toLowerCase() + '.\n' + $scope.changeNote,
                            false);
                    }
                    else if ($scope.dilutionCount < originalCount) {
                        if (!isRxTraditionalArrangement) {
                            parent.pscript.treatmentConfigs.length = $scope.dilutionCount;
                        }

                        let changeDescription = isRxTraditionalArrangement ? 'change dilution' : 'decrease the number of dilutions';
                        parent.$scope.classicalDilutionsChanged = true;
                        parent._prescriptionNeedsReApproval(
                            'NEW_TRADITIONAL_DILUTION',
                            'Requesting approval to ' + changeDescription + ' to ' + ClassicalDilutions[$scope.dilutionCount-1].color.toLowerCase() + '.\n' + $scope.changeNote,
                            false);
                    }

                    $uibModalInstance.close();
                }
            }]
        });
    }

    _printLabelsModal(type) {
        let parent = this;
        return this.$uibModal.open({
            windowClass: 'printLabels',
            backdrop: 'static',
            keyboard: false,
            scope: this.$scope, //passed current scope to the modal
            template: require('../../widgets/print-labels-modal.html'),
            css: require('../../widgets/print-labels-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                if (type === "expiredPrescription") {
                    $scope.expiredPS = true;
                }

                $scope.save = () => {
                    parent._printLabels(false).then(() => {
                        $uibModalInstance.close();
                    });
                };

            }
        }).result;
    }

    showTraditionalColor(prescribedSubstance) {
        return (this.$scope.isTraditionalArrangement && !prescribedSubstance._substance._category._isControl)
            || (this.isClassical && (prescribedSubstance.substanceVialId || prescribedSubstance.substanceTreatmentVial));
    }
}
