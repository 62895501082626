'use strict';

// Framework
import angular from 'angular';
import ngRoute from 'angular-route';

// Support from higher echelons within the project
import agStandardPageLayout from "../common/standard-layout";

// Logical Data Model
import DashboardSections from "./models/dashboard-sections";
import UrlPaths from "../../models/url-paths";
// UI-Widgetry
import moduleWidgets from "./widgets";

// Page UI-Constituents
import NavigationPanel from "./navigation-panel";
import GlobalAlertsPanel from "./global-alerts-panel";

// Appointments-Section directed-UI-constituents
import agDbCurrentVisitsList from './sections/appointments/current-visits-list';
import agDbVisitTypeFilter from './sections/appointments/visit-type-filter';

// The primary controller impl in this module.
import DashboardController from './controller.js';

// Import sections
import Appointments from './sections/appointments';
import Approvals from "./sections/approvals";
import Mixing from "./sections/mixing";
import NoTreatment from "./sections/no-treatment";
import OrderedNotTested from "./sections/ordered-not-tested";
import Billing from "./sections/billing";

export default angular.module('pages.dashboard',
    [
        // framework : principle-startup
        ngRoute,
        // From up above
        agStandardPageLayout,
        // data-model
        DashboardSections,
        UrlPaths,
        // dynamically support UI
        moduleWidgets,
        // directed-UI-constituents
        NavigationPanel,
        GlobalAlertsPanel,
        agDbCurrentVisitsList,
        agDbVisitTypeFilter,
        Appointments,
        Approvals,
        Mixing,
        NoTreatment,
        OrderedNotTested,
        Billing
    ]
)
    .config(routingConfig)
    .controller('DashboardController', DashboardController)
    .name;

function routingConfig($routeProvider, DashboardSections, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_PAGE, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: 'DashboardController',
        resolve : {
            "activeSection" : function() { return null; }
        }
    })
    .when(UrlPaths.DASHBOARD_APPROVALS, {
        template: require('./layout.html'),
        css: require('./sections/approvals/styles.scss'),
        controller: 'DashboardController',
        resolve : {
            "activeSection" : function() { return DashboardSections.APPROVALS; }
        }
    })
    .when(UrlPaths.DASHBOARD_APPOINTMENTS, {
        template: require('./layout.html'),
        css: require('./sections/appointments/styles.scss'),
        controller: 'DashboardController',
        resolve : {
            "activeSection" : function() { return DashboardSections.APPOINTMENTS; }
        }
    })
    .when(UrlPaths.DASHBOARD_MIXING, {
        template: require('./layout.html'),
        css: require('./sections/mixing/styles.scss'),
        controller: 'DashboardController',
        resolve : {
            "activeSection" : function() { return DashboardSections.MIXING; }
        }
    })
    .when(UrlPaths.DASHBOARD_ORDERED_NOT_TESTED, {
        template: require('./layout.html'),
        css: require('./sections/ordered-not-tested/styles.scss'),
        controller: 'DashboardController',
        resolve : {
            "activeSection" : function() { return DashboardSections.ORDERED_NOT_TESTED; }
        }
    })
    .when(UrlPaths.DASHBOARD_NO_TREATMENT, {
        template: require('./layout.html'),
        css: require('./sections/no-treatment/styles.scss'),
        controller: 'DashboardController',
        resolve : {
            "activeSection" : function() { return DashboardSections.NO_TREATMENT; }
        }
    })
    .when(UrlPaths.DASHBOARD_BILLING, {
        template: require('./layout.html'),
        css: require('./sections/billing/styles.scss'),
        controller: 'DashboardController',
        resolve: {
            "activeSection" : function() { return DashboardSections.BILLING; }
        }
    })
    .when(UrlPaths.DASHBOARD_APPROVALS_TEST, {
        template: require('./sections/approvals/test-approval/test-details.html'),
        css: require('./styles.scss'),
        controller: 'ApprovalDetailsController'
    })
    .when(UrlPaths.DASHBOARD_APPROVALS_TEST_REVIEW, {
        template: require('./sections/approvals/test-review-approval/test-review-details.html'),
        css: require('./styles.scss'),
        controller: 'ApprovalDetailsController'
    })
    .when(UrlPaths.DASHBOARD_APPROVALS_RX, {
        template: require('./sections/approvals/prescription-approval/prescription-approval.html'),
        css: require('./styles.scss'),
        controller: 'ApprovalDetailsController'
    })
    .when(UrlPaths.DASHBOARD_APPROVALS_TREATMENT, {
        template: require('./sections/approvals/treatment-review/treatment-review.html'),
        css: require('./styles.scss'),
        controller: 'ApprovalDetailsController'
    })
    .when(UrlPaths.DASHBOARD_APPROVALS_MIX, {
        template: require('./sections/approvals/mix-approval/layout.html'),
        css: require('./styles.scss'),
        controller: 'ApprovalDetailsController'
    });
}
routingConfig.$inject = ['$routeProvider', 'DashboardSections', 'UrlPaths'];
