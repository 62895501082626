"use strict";

/**
 * An office procedure.
 */
export default angular.module("models.treatmentStage", [])
    .constant("TreatmentStage", {
        NEW : "NEW",
        VIAL_SELECTION: "VIAL_SELECTION",
        QUESTIONNAIRE : "QUESTIONNAIRE",
        PRE_VITALS : "PRE_VITALS",
        CHECKLIST : "CHECKLIST",
        VIAL_TEST : "VIAL_TEST",
        IDT_TIMER : "IDT_TIMER",
        IDT_RESULTS : "IDT_RESULTS",
        INJECTION : "INJECTION",
        REACTION_TIMER : "REACTION_TIMER",
        EXAMINATION : "EXAMINATION",
        REFILL : "REFILL",
        COMPLETE : "COMPLETE",
        ABORTED : "ABORTED"
    }).name;
