'use strict';

import moment from 'moment';

import BaseTreatmentController from '../base-treatment.controller'
import TreatmentVialService from "../../../services/treatment-vial.service";

export default class TreatmentVialSelectionController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.chronologyMappingService = $injector.get('chronologyMappingService');
        this.questionnaireService = $injector.get('questionnaireService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.$q = $injector.get('$q');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.TreatmentType = $injector.get('TreatmentType');
        this.isClassical = null;

        // Scope functions
        $scope.validateSelections = () => this._validateSelections();
        $scope.consultChoice = (injection, consultChoice, dosage) => this._consultChoice(injection, consultChoice, dosage);

        // Scope data
        $scope.text = "Select Vials for Treatment";

        this.treatmentLoaded()
            .then(() => {
                if (this.treatment.injections.length) {
                    return this.prescriptionService.get(this.treatment.injections[0]._vial.prescription);
                }

                return null;
            }).then(pscript => {
                this.isClassical = pscript && pscript.vials.some(v => v.classicalDilution);
            })
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TreatmentVialSelectionController constructor completed!");
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.VIAL_SELECTION]);
        this.disableNext = false;
        this.initFooterNote('Vial Selection');

        /* Get last visit to check for delayed reactions */
        this.treatmentService.getPatientHistory(this.$scope.patient, 1).then(treatmentHistory => {
            let lastTreatment = treatmentHistory.list[0];
            this.$scope.delayedReactionInjections = lastTreatment ? lastTreatment.injections.filter(i => i.injected && i.delayedWhealSize) : [];

            for(let injection of this.$scope.delayedReactionInjections) {
                this.treatmentVialService.get(injection.vial).then(treatmentVial => {
                    injection._vial = treatmentVial;
                });
            }
        });

        this.$scope.injections = this.treatment.injections;
        for (let inj of this.treatment.injections) {
            /* Set this flag to prevent selected expired vials from being displayed twice on UI */
            inj._isDefault = true;
            inj._originalPlan = inj.plan;

            if (inj.plan === 'TREAT' || inj.plan === 'VIAL_TEST') {
                inj._selected = !inj.planOverrideBy;
            }
            else {
                inj._selected = !!inj.planOverrideBy;
            }
        }

        let inservicePrescriptions = new Set();
        for (let inj of this.treatment.injections) {
            inservicePrescriptions.add(inj._vial.prescription.id);
            inj._needsConsult = inj.plan === 'CONSULT_TOO_LATE' || inj.plan === 'CONSULT_TOO_LARGE';
            this._setVialPlanReason(inj);
        }

        let expired = this.treatmentVialService.getRecentlyExpired(this.patient)
            .then(expiredVials => {
                this.$scope.expiredVials = expiredVials.list
                    .filter(expiredVial => !this.treatment.injections.find(m => m.vial.id === expiredVial.id))
                    .map(expiredVial => {
                        expiredVial._planReason = `Treatment from this vial is not recommended, because the vial expired on ${moment(expiredVial.useBy).format('MM/DD/YYYY')}`;
                        return expiredVial;
                    })
                    .sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
            });

        let pending = this.treatmentVialService.getPendingPatientVials(this.patient)
            .then(pendingVialsList => {
                let pendingVials = pendingVialsList.list;

                if (this.isClassical) {
                    pendingVials = pendingVials.filter(vial => {
                        if (vial.stage == 'INVENTORY') {
                            return !inservicePrescriptions.has(vial.prescription.id);
                        }
                        return vial;
                    });
                }

                this.$scope.pendingVials = pendingVials.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
            });

        this.$q.all([expired, pending]).then(() => {
            this._validateSelections();
            let overridenInjection = this.$scope.injections.find(inj => inj.planOverrideBy);
            if (overridenInjection) {
                this.$scope.physicianApproved = true;
                this.$scope.selectionNote = overridenInjection.planOverrideBy.note;
            }
        });
    }

    /**
     * @override
     */
    nextDisabled() {
        if (this.disableNext)
            return true;

        let injections = this.$scope.injections || [];
        let expiredVials = this.$scope.expiredVials || [];
        let disabled = false;

        let isValidConsultChoice = (inj) => {
            let ret = inj._consultChoiceType !== undefined;
            if (inj._consultChoiceType === 'CUSTOM_DOSE') {
                if (!inj.dosage || inj.dosage < .01 || inj.dosage > 1) {
                    ret = false;
                }
            }
            return ret;
        }

        if (injections.some(inj => inj._needsConsult && inj._selected && !isValidConsultChoice(inj))) {
            disabled = true;
        }

        /* Note if injections is an empty array the every method will return true */
        if (injections.every(inj => !inj._selected)) {
            disabled = disabled || expiredVials.every(expiredVial => !expiredVial._selected);
        }

        if (this.$scope.hasSelectionOverride) {
            disabled = disabled || !(this.$scope.physicianApproved && this.$scope.selectionNote);
        }

        return disabled;
    }

    /**
     * @override
     */
    async update() {
        this.updateFooterNote();

        /* Set plan overrides */
        this.$scope.injections.forEach(inj => {
            if ((inj.plan === 'TREAT' || inj.plan === 'VIAL_TEST') != inj._selected) {
                inj.planOverrideBy = { note: this.$scope.selectionNote };
            }
        });

        /* Create a new injection for any selected expired vials */
        for(let expiredVial of this.$scope.expiredVials) {
            if (expiredVial._selected) {
                let injection = {
                    vial: { id: expiredVial.id },
                    planOverrideBy: { note: this.$scope.selectionNote },
                    scheduleIndex: expiredVial.scheduleIndex,
                    dosage: expiredVial.dosage
                };
                this.treatment.injections.push(injection);
            }
        }

        return super.update();
    }

    _consultChoice(injection, choiceType, dosage) {
        injection.plan = injection._originalPlan;
        injection._consultChoiceType = choiceType;

        if (choiceType === 'CUSTOM_DOSE') {
            injection._customDose = true;
            injection.scheduleIndex = -1;
            injection.dosage = dosage;
            injection.idtDosage = 0;
            injection._consultChoice = 'Other';
        }
        else if (choiceType === 'VIAL_TEST') {
            injection._customDose = false;
            injection.scheduleIndex = -1;
            injection.idtDosage = injection._treatmentConfig.scit.idtDosage;
            injection.dosage = 0;
            injection._consultChoice = 'Vial Test';
        }
        else if (choiceType === 'TREATMENT_SCHEDULE') {
            injection._customDose = false;
            injection.scheduleIndex = -1;
            injection.dosage = dosage;
            injection.idtDosage = 0;
            injection._consultChoice = dosage + 'mL';
        }
    }

    _setConsultReason(inj) {
        /* Get treatment schedule for dose selection in UI */
        this.treatmentConfigService.get(inj._vial.treatmentConfig).then((treatmentConfig) => {
            inj._treatmentConfig = treatmentConfig;
            inj._treatmentSchedule = treatmentConfig.scitSchedule;
        });

        this.treatmentVialService.getInjections(inj._vial)
            .then((injections) => {
                inj._planReason = 'Consult with a physician.';

                for(let i = injections.length - 1; i--; i >= 0) {
                    if (injections[i].injected) {
                        if (inj.plan === 'CONSULT_TOO_LATE') {
                            let injectionDate = this.chronologyMappingService.utcToTimezone(injections[i].createdDateTime, this.$scope.office.timezone);
                            let daysSince = this.chronologyMappingService.daysBetween(new Date(), injectionDate);
                            inj._planReason += ` It has been ${daysSince} days since the patient's last dose of ${injections[i].dosage}mL on ${moment(injectionDate).format('MM/DD/YYYY')}.`;
                        }
                        else {
                            let injectionDate = this.chronologyMappingService.utcToTimezone(injections[i].createdDateTime, this.$scope.office.timezone);
                            inj._planReason += ` A large reaction of ${injections[i].reactionWhealSize}mm was recorded from the patient's last dose of ${injections[i].dosage}mL on ${moment(injectionDate).format('MM/DD/YYYY')}.`;   
                        }
                        break;
                    }
                }
            });
    }

    _setVialPlanReason(inj) {
        if (inj.plan === 'NO_TREAT') {
            inj._planReason = 'No treatment is scheduled or recommended at this time for this vial.';
        }
        else if (inj.plan === 'NO_TREAT_TOO_EARLY' || inj.plan === 'NO_VIAL_TEST') {
            this.treatmentService.getDaysUntilTreatment(inj._vial).then(days => {
                let planText = inj.plan === 'NO_TREAT_TOO_EARLY' ? 'treatment' : 'vial test';
                inj._planReason = `No ${planText} can be administered at this time, because the patient is ${days} days too early for treatment.`;
            });
        }
        else if (inj.plan === 'NO_TREAT_EXPIRED') {
            inj._planReason = `Treatment from this vial is not recommended, because the vial expired on ${moment(inj._vial.useBy).format('MM/DD/YYYY')}`;
        }
        else if (inj.plan === 'CONSULT') {
            inj._planReason = 'Consult with a physician.';
        }
        else if (inj.plan === 'CONSULT_TOO_LATE' || inj.plan === 'CONSULT_TOO_LARGE') {
            this._setConsultReason(inj);
        }
    }

    _validateSelections() {
        this.$scope.physicianApproved = false;

        let hasInjectionOverrides = this.$scope.injections.some(inj => (inj.plan === 'TREAT' || inj.plan === 'VIAL_TEST') != inj._selected);
        this.$scope.hasExpiredVialOverrides = this.$scope.expiredVials.some(expiredVial => expiredVial._selected);
        if (hasInjectionOverrides || this.$scope.hasExpiredVialOverrides) {
            this.$scope.hasSelectionOverride = true;
        }
        else {
            this.$scope.hasSelectionOverride = false;
            this.$scope.selectionNote = "";
        }
    }
}
