'use strict';

import angular from 'angular';

export default angular.module('widgets.questionnaire', [])
    .directive('agQuestionnaire', ["$location", agQuestionnaire])
    .name;

function agQuestionnaire( $location) {
    return {
        template: require('./questionnaire.html'),
        scope: false, // use parent scope,
        link: function(scope, element, attrs) {
        }
    };
}
