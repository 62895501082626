'use strict';

import BaseTreatmentController from '../base-treatment.controller'

export default class TreatmentTimerController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        // Load data
        this.treatmentLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TreatmentTimerController constructor completed!");
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        console.log("TreatmentTimerController.reload()");
        super.validateStage([this.TreatmentStage.IDT_TIMER, this.TreatmentStage.REACTION_TIMER]);

        this.$scope.timer = this.patient.timer;
        this.$scope.expiredTimer = false;

        if (this.treatment.stage === this.TreatmentStage.IDT_TIMER) {
            this.$scope.text = 'Vial Test Timer';
            this.initFooterNote('Measure Intradermal');
        } else {
            this.$scope.text = 'Treatment Timer';
            this.initFooterNote('Examination');
        }

        this.disableNext = false;
    }

    /**
     * @returns {boolean}
     * @override
     */
    nextDisabled() {
        return this.disableNext || !this.$scope.expiredTimer;
    }

    /**
     * Apply footer note, if any change.
     * @override
     */
    async update() {
        if (this.updateFooterNote()) {
            return super.update();
        }
        else {
            return this.treatment;
        }
    }

}
