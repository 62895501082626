"use strict";

import BaseController from './../../base.controller.js';

export default class PatientListController extends BaseController {
    static PAST_DAYS = 1;

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {

        super($scope, $injector);

        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.visitSrv = $injector.get('visitService');
        this.patientSrv = $injector.get('patientService');
        this.aptSrv = $injector.get('appointmentService');
        this.notificationType = $injector.get('NotificationType');
        this.uibModal = $injector.get('$uibModal');
        this.$filter = $injector.get('$filter');

        // Subscriptions
        this.patientVisitsSub = undefined;
        this.patientApptSub = [];
        
        this.$scope.isLoading = true;

        // Creating an object to store the table src list and display list is a must here.
        // The reason for this is because the 'View' has a transclude in it, and displayedPatientList was being
        // recreated (as a separate array with the same name) in the transclude's child scope. Wrapping the table lists in a object will stop this from occurring
        // the end result is a second displayedPatientList doesn't get created in the transcluded child scope, and updates
        // to 'patientList' are correctly reflected in 'displayedPatientList'
        this.$scope.table = {
            patientList : [],
            displayedPatientList : []
        };
        this.$scope.listFilter = {
            givenName : '',
            familyName: '',
            procedure: ''
        };

        this.$scope.procedureOptions = [
            {
                name: 'All',
                value: undefined
            },
            {
                name: 'No Appointment',
                value: 'No Appointment'
            },
            {
                name: 'Currently Checked In',
                value: 'Currently Checked In'
            },
            {
                name: 'Testing',
                value: 'TESTING'
            },
            {
                name: 'SPT',
                value: 'SPT'
            },
            {
                name: 'Treatment',
                value: 'TREATMENT'
            }
        ];

        this.$scope.procedureFilterSelection = this.$scope.procedureOptions[0];

        this.$scope.onProcedureFilterChange = (opt) => {
            this.$scope.procedureFilterSelection = opt;
            this.$scope.listFilter.procedure = opt.value;
        };

        this._getPatientList();
    }

    /**
     * This is called when the user selects a different Office.
     * @override
     */
    officeChanged() {
        super.officeChanged();

        this.$scope.table.patientList = [];
        this.$scope.table.displayedPatientList = [];

        this._getPatientList();
    }

    async _getPatientList() {
        await this.notificationService.init();
        this.unsubscribeAllSubscriptions();

        this._subscribeToPatientVisits();

        const recentList = await this.patientSrv.getRecentList(this.$scope.office, PatientListController.PAST_DAYS);
        let viewList = [];

        for (const patient of recentList.list) {
            let view = await this._buildPatientView(patient, recentList);
            viewList.push(view);
            this._subscribeToPatientAppointments(patient);
        }


        this.$scope.table.patientList = viewList;

        this.startAllSubscriptions();
        this.$scope.isLoading = false;
        this.$scope.$digest();
    }

    async _buildPatientView(patient, embeddedSource) {
        let view = {
            patient: patient,
            givenName : patient.person.givenName,
            familyName : patient.person.familyName,
            chartNum: patient.chartNum,
            dayOfBirth : patient.dayOfBirth,
            photo: patient._links.photoThumbnail
        };

        if (patient.lastVisit) {
            view.lastVisit = await this.visitSrv.get(patient.lastVisit, embeddedSource);
        }
        else {
            view.lastVisit = { checkinTime: '', procedure: '' };
        }

        if (patient.activeVisit) {
            view.activeVisit = await this.visitSrv.get(patient.activeVisit, embeddedSource);
            view.nextAppointmentText = "Currently Checked In";
        }
        else {
            view.activeVisit = null;

            if (patient.nextAppointment) {
                view.nextAppointment = await this.aptSrv.get(patient.nextAppointment, embeddedSource);
                view.nextAppointmentText = view.nextAppointment.procedure;
                this._subscribeToAppointmentRemoval(view.nextAppointment);
            }
            else {
                view.nextAppointmentText = "No Appointment";
            }
        }

        return view;
    }

    showAppointment(event, viewRow) {
        event.stopPropagation();

        this.uibModal.open({
            windowClass: 'appointment',
            size: 'md',
            template: require('../../common/appointment-details/appointment-details.html'),
            css: require('../../common/appointment-details/appointment-details.scss'),
            controller: 'AppointmentDetailsController',
            resolve: {
                patient: () => {
                    return viewRow.patient;
                }
            }
        });
    }

    toPatientDetails(viewRow) {
        this.routeToPage(this.urlPaths.PATIENT_DETAILS, /*ReferenceDTO<Patient>*/viewRow.patient);
    }

    _subscribeToPatientVisits() {
        this.registerSubscription(this.notificationService.subscribePatientVisits(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onPatientVisitNotification(notification));
    }

    async _onPatientVisitNotification(notification) {
        if (notification.type !== this.notificationType.Patient) {
            return;
        }

        let patient = notification.body;
        let view = await this._buildPatientView(patient, null);

        // Gotta get the next appointment the hard way...
        if (!patient.activeVisit) {
            try {
                let appointment = await this.aptSrv.getNextForPatient(patient, false);
                view.nextAppointment = appointment;
                view.nextAppointmentText = appointment.procedure;
                this._subscribeToAppointmentRemoval(appointment);
            } catch (error) {
                view.nextAppointmentText = "No Appointment";
            }
        }

        // find the patient in the current list, if not found add it.
        let updated = false;
        for (let index = 0; index < this.$scope.table.patientList.length; ++index) {
            const row = this.$scope.table.patientList[index];
            if (patient.id === row.patient.id) {
                updated = true;

                // Active visit went away? It's the new lastVisit
                // Had lastVisit before but no more? Keep the previous value.
                if (row.activeVisit && !view.activeVisit)
                    view.lastVisit = row.activeVisit;
                else if (row.lastVisit.procedure && !view.lastVisit.procedure)
                    view.lastVisit = row.lastVisit;

                this.$scope.table.patientList[index] = view;
                break;
            }
        }

        if (!updated) {
            this._subscribeToPatientAppointments(patient);
            this.$scope.table.patientList.push(view);
        }

        this._onModelChanged();
        this.$scope.$digest();
    }

    _subscribeToPatientAppointments(patient) {
        this.registerSubscription(this.notificationService.subscribePatientAppointments(this.$scope.practice, this.$scope.office, patient))
            .then(null, null, (notification) => this._onPatientAppointmentNotification(notification));
    }

    _onPatientAppointmentNotification(notification) {
        if (notification.type !== this.notificationType.Appointment) {
            return;
        }

        let appointment = notification.body;
        for (let patientView of this.$scope.table.patientList) {
            if (appointment.patient.id === patientView.patient.id) {
                if (!patientView.nextAppointment || patientView.nextAppointment.id !== appointment.id) {
                    this._subscribeToAppointmentRemoval(appointment);
                }

                patientView.nextAppointment = appointment;
                patientView.nextAppointmentText = appointment.procedure;
                this._onModelChanged();
                break;
            }
        }
    }

    _subscribeToAppointmentRemoval(appointment) {
        let subscription = this.registerSubscription(this.notificationService.subscribeToAppointmentRemoval(this.$scope.practice, appointment));
        subscription.then(null, null, (notification) => this._onAppointmentRemovedNotification(notification));
        subscription.start();
    }

    _onAppointmentRemovedNotification(notification) {
        let appointment = notification.body;

        for (let patientView of this.$scope.table.patientList) {
            if (appointment.patient.id === patientView.patient.id) {
                patientView.nextAppointment = undefined;
                patientView.nextAppointmentText = "No Appointment";
                this._onModelChanged();
                break;
            }
        }
    }

    // the displayedPatientList is not updated when only the content of an object within the parent 'patientList' is updated.
    // because of this we have to manually copy over the contents of the new array to the 'displayedPatientList' used by smart table.
    _onModelChanged() {
        this.$scope.table.displayedPatientList = [].concat(this.$scope.table.patientList);
    }
}
