'use strict';

import BaseTreatmentController from '../base-treatment.controller'

export default class TreatmentInjectionController extends BaseTreatmentController {

    /** {Injection} currently selected. (Includes _vial field containing TreatmentVial). */
    injection = undefined;

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.$filter = $injector.get('$filter');
        this.$q = $injector.get('$q');
        this.prescriptionService = $injector.get('prescriptionService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.globalConfigService = $injector.get('globalConfigService');
        this.TreatmentType = $injector.get('TreatmentType');

        // Scope functions
        $scope.overrideModal = () => this.overrideModal();
        $scope.barcodeModal = () => this.barcodeModal();
        $scope.barcodeMismatchModal = () => this.barcodeMismatchModal();
        $scope.skipDose = () => this.skipDose();

        // Scope data
        $scope.allowAutoBarcode = false;

        // Load data

        this._loadGlobalConfig();

        this.treatmentLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.$scope.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        console.log("TreatmentInjectionController.reload()");
        super.validateStage([this.TreatmentStage.INJECTION]);

        this.dosageSchedule = undefined;

        this.$scope.treatmentType = this.treatment.type;
        this.$scope.vial = undefined;
        this.$scope.barCode = '';
        this.injection = undefined;
        this.$scope.barCodesInTreatment = [];
        this.$scope.validBarcode = false;
        this.$scope.administerDose = true;
        this.initFooterNote('Treatment');

        // Normally location can be left selected because it's just the 1st of 2 choices,
        // but tongue lacks that sub-selection and we need the user to click on it again to
        // set the actual treatment.location value.
        if (this.$scope.location === 'Tongue')
            this.$scope.location = '';

        // Build list of vial barcodes in the treatment
        for (let i = 0; i < this.treatment.injections.length; i++ ) {
            this.$scope.barCodesInTreatment.push(this.treatment.injections[i]._vial.barcode);
        }

        // Find next injection to administer
        for (let inj of this.treatment.injections) {
            let injectable = !(inj.injected || inj.reactionWhealSize === -1) && this.isApplicableInjection(inj);
            if (injectable) {
                this.injection = inj;
                break;
            }
        }

        if (this.injection) {
            this.originalScheduleIndex = this.injection.scheduleIndex;
            this.originalDosage = this.injection.dosage;
            this.originalReason = this.injection.reason;

            // Build VM in $scope.vial
            let inj = this.injection;
            let vial = this.injection._vial;
            this.$scope.vial = {
                name: vial.name,
                volume: inj.dosage,
                barcode: vial.barcode,
                mixNumber: vial.mixNum,
                useByDate: vial.useBy,
                beyondUseDate: vial.beyondUse,
                isExpired: vial.status === 'EXPIRED',
                color: vial.color,
                location: inj.location,
                overrideNote: inj.doseOverrideBy ? inj.doseOverrideBy.note : undefined,
                pastTreatments: []
            };

            this._updateInstructions();

            // Fill in history when it arrives
            if (this.treatment.type === this.TreatmentType.SCIT) {
                this._populateVialHistory(vial);
            }
            else /*SLIT*/ {
                this._populateSlitTreatmentHistory();
            }
        }
        else {
            console.log("TreatmentInjectionController: ** No ready Injection found **");
            super.noInjectionsModal();
        }

        this.disableNext = false;
    }

    /**
     * Update the instructions line.
     * @private
     */
    _updateInstructions() {
        let inj = this.injection;
        let vial = inj._vial;

        if (this.treatment.type === this.TreatmentType.SCIT) {
            this.$scope.vial.dosage = inj.dosage.toFixed(2) + ' mL';
            if (vial.status === 'EXPIRED') {
                this.$scope.text = 'Select Dose and Apply Treatment: ' + vial.name;
            }
            else {
                this.$scope.text = 'Apply Treatment: ' + vial.name + ' (Step ' + (inj.scheduleIndex + 1) + ' - ' + this.$scope.vial.dosage + ')';
            }
        }
        else /*SLIT*/ {
            this.$scope.vial.dosage = inj.dosage + (inj.dosage === 1 ? ' drop' : ' drops');
            this.$scope.text = 'Apply Treatment - ' + vial.name + ': ' + this.$scope.vial.dosage;
        }
    }

    /**
     * Update the vial history $scope data
     * @param vial {TreatmentVial} instance
     * @private
     */
    _populateVialHistory(vial) {
        this.$scope.delayedReactionInjection = null;

        this.treatmentService.getVialHistory(vial)
            .then(treatmentList => {
                let pastTreatments = this.$scope.vial.pastTreatments = [];

                // For each treatment
                for (let i = 0; i < treatmentList.list.length; i++) {
                    let treatment = treatmentList.list[i];

                    // Skip the current treatment - yes, that'll be in the list
                    if (treatment.id === this.treatment.id)
                        continue;

                    // Find the injection that matches this vial
                    for (let injection of treatment.injections) {
                        if (injection.injected && injection.vial.id === this.injection.vial.id) {
                            // Add it to the history
                            pastTreatments.push({
                                'date': treatment.performedBy.actionDateTime,
                                'dosage': this.$filter('number')(injection.dosage,2) + ' mL',
                                'location': injection.location,
                                'reaction': injection.reactionWhealSize,
                                'warning': injection.reactionWarning,
                                'notes': this.treatmentService.getInjectionNotes(injection)
                            });

                            if (injection.delayedWhealSize) {
                                // Add delayed reactions to the history
                                pastTreatments.push({
                                    'date': injection.delayedReactionBy.actionDateTime,
                                    'dosage': 'Delayed Reaction',
                                    'location': injection.location,
                                    'reaction': injection.delayedWhealSize,
                                    'notes': [injection.delayedReactionBy.note]
                                });

                                this.$scope.delayedReactionInjection = injection;
                            }

                            break; //out of for:injection
                        }
                    }
                }
            });
    }

    /**
     * Update the treatment history $scope data
     * @private
     */
    async _populateSlitTreatmentHistory() {
        let treatmentList = await this.treatmentService.getPatientHistory(this.$scope.patient, /*maxResults*/10);
        let pastTreatments = this.$scope.vial.pastTreatments = [];

        // For each treatment
        for (let treatment of treatmentList.list) {

            // Skip the current treatment or treatments of a different type.
            if (treatment.id === this.treatment.id || treatment.type !== this.treatment.type)
                continue;

            // Find injections that look like they might be of the same prescription as this vial
            for (let injection of treatment.injections) {
                if (!injection.injected)
                    continue;

                let vial = await this.treatmentVialService.get(injection.vial, treatmentList);
                if (vial.color === this.injection._vial.color) {
                    // Add it to the history
                    pastTreatments.push({
                        'date': treatment.performedBy.actionDateTime,
                        'dosage': injection.dosage,
                        'vial' : vial.name,
                        'reaction': injection.reactionWhealSize,
                        'notes': this.treatmentService.getInjectionNotes(injection)
                    });
                }
            }
        }

        this.$scope.$digest();
    }

    _getDosageSchedule() {
        if (this.dosageSchedule) {
            return this.$q.resolve(this.dosageSchedule);
        }

        return this.treatmentConfigService.get(this.injection._vial.treatmentConfig)
            .then(config => {
                let dosageSchedule = new Set();
                config.scitSchedule.forEach(entry => dosageSchedule.add(entry.dosage));
                this.dosageSchedule = Array.from(dosageSchedule.values());
                return this.dosageSchedule;
            });
    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext)
            return true;
        else if (this.injection === undefined)
            return false; // no injection

        return !(this.$scope.validBarcode && this.$scope.vial.location && this.$scope.vial.volume) && this.$scope.administerDose;
    }

    /**
     * @override
     */
    async nextStep() {
        this.disableNext = true;

        if (this.injection && this.injection.reactionWhealSize !== -1) {
            // this.injection is a reference into this.treatment.injections[]
            this.injection.location = this.$scope.vial.location;
            this.injection.injected = true;
        }

        return super.nextStep();
    }

    /**
     * Apply footer note before saving.
     * @override
     */
    async update() {
        this.updateFooterNote();
        return super.update();
    }

    overrideModal() {
        // Load Treatment schedule to get permitted dosages.
        this._getDosageSchedule()
            .then(dosageSchedule => {

                // Show dose override modal
                let vm = {
                    vialName: this.injection._vial.name,
                    recommendedIndex: this.originalScheduleIndex,
                    recommendedDosage: this.originalDosage,
                    selectedIndex: this.injection.scheduleIndex || this.originalScheduleIndex,
                    selectedDosage: this.injection.scheduleIndex >= 0 ? this.injection.dosage : this.originalDosage,
                    customDosage: this.injection.scheduleIndex == -1 ? this.injection.dosage : undefined,
                    reason: this.injection.reason,
                    otherReason: this.injection.reason === 'other' && this.injection.doseOverrideBy ? this.injection.doseOverrideBy.note : undefined,
                    dosageSchedule: dosageSchedule,
                    isExpired: this.injection._vial.status === 'EXPIRED'
                };

                this.$scope.modalOpen = true;
                let instance = this.$uibModal.open({
                    windowClass: 'override',
                    resolve: {
                        vm: () => vm
                    },
                    template: require('./widgets/override-modal.html'),
                    css: require('./widgets/override-modal.scss'),
                    controller: function ($uibModalInstance, $scope, vm) {
                        $scope.vm = vm;
                        $scope.error = false;

                        if (vm.isExpired) {
                            vm.reason = 'Vial is expired and requires manual dose selection';
                        }

                        $scope.customDoseChange = function() {
                            // min -> 0.01
                            // max -> 1.00
                            let dose = parseFloat($scope.vm.customDosage);
                            if (dose < 0.01 || dose > 1) {
                                $scope.error = true;
                                return;
                            }
                            $scope.error = false;
                            $scope.vm.selectedDosage = dose;
                        }

                        $scope.optionChanged = function(index) {   
                            $scope.error = false;
                            $scope.vm.selectedIndex = index;

                            if (index === -1) {
                                // custom dosage
                                $scope.error = true;
                                $scope.vm.selectedDosage = "";
                                $scope.vm.customDosage = "";
                            }
                            else {
                                $scope.vm.selectedDosage = $scope.vm.dosageSchedule[index];
                            }
                        };
                        $scope.cancel = () => $uibModalInstance.dismiss();

                        $scope.save = () => {
                            let result = {
                                selectedIndex: $scope.vm.selectedIndex,
                                selectedDosage: $scope.vm.selectedIndex == -1 ? $scope.vm.customDosage : $scope.vm.selectedDosage,
                                reason: $scope.vm.reason,
                                note: $scope.vm.otherReason
                            };
                            $uibModalInstance.close(result);
                        };
                    }
                });

                return instance.result;
            })
            .then(result => {
                // Process override
                this.injection.scheduleIndex = result.selectedIndex;
                this.injection.dosage = result.selectedDosage;
                this.injection.doseOverrideBy = {note: result.reason === 'other' ? result.note : result.reason};
                this.injection.reason = result.reason;
                this.injection.reactionWhealSize = null;
                this.$scope.vial.volume = result.selectedDosage;
                this.$scope.modalOpen = false;
                this.$scope.administerDose = true;
                this._updateInstructions();
            })
            .catch(dismiss => {
                this.$scope.modalOpen = false;
            });
    }

    barcodeModal() {
        //let $this = this;

        this.$uibModal.open({
            windowClass: 'barcode',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/barcode-modal.html'),
            css: require('./widgets/barcode-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        }).result
            .catch(() => this.$scope.barCode = '');
    }

    barcodeMismatchModal() {
        //let $this = this;

        this.$uibModal.open({
            windowClass: 'barcode',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/barcode-mismatch-modal.html'),
            css: require('./widgets/barcode-mismatch-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        }).result
            .catch(() => this.$scope.barCode = '');
    }

    skipDose() {
        this.$scope.modalOpen = true;
        this.$uibModal.open({
            windowClass: 'skip',
            template: require('./widgets/skip-modal.html'),
            css: require('./widgets/skip-modal.scss'),
            resolve: {
                note: () => {
                    return this.injection.doseOverrideBy ? this.injection.doseOverrideBy.note : '';
                }
            },
            controller: function ($uibModalInstance, $scope, note) {
                $scope.note = note;
                $scope.cancel = () => $uibModalInstance.dismiss();
                $scope.continue = () => $uibModalInstance.close($scope.note);
            }
        }).result.then((note) => {
            this.$scope.modalOpen = false;

            // Reset override if applicable
            this.injection.reason = this.originalReason;
            if (this.treatment.type === this.TreatmentType.SCIT) {
                this.injection.scheduleIndex = this.originalScheduleIndex;
                this.injection.dosage = this.originalDosage;
            }

            this.$scope.administerDose = false;
            this.injection.reactionWhealSize = -1;
            this.injection.doseOverrideBy = { note: note };

            this._updateInstructions();
        }).catch(() => this.$scope.modalOpen = false);
    }
}
