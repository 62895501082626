'use strict';

import BaseService from './base.service.js';

export default class QuestionnaireService extends BaseService {

    /**
     * Get the Questionnaire for a Visit.
     *
     * @param visit the Visit to get the QUestiannaire for
     * @return Promise to a Questionnaire DTO
     */
    getForVisit(visit) {
        return this.serverAPI.get(visit.questionnaire.href, {}, visit);
    }
}
