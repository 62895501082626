'use strict';

import BaseController from './../base.controller.js';
import { ClassicalDilution, ClassicalDilutions} from "../../models/classical-dilutions";

export default class BaseMixingController extends BaseController {

    // Magic values for these constants allow for the convenience of incrementing from tab to tab,
    // while also having the value match the vial number.
    TAB_DETAILS = -2;
    TAB_CHECKLIST = -1;
    TAB_PREPARE = 0;
    TAB_VIAL1 = 1;
    TAB_VIAL2 = 2;
    TAB_VIAL3 = 3;
    TAB_VIAL4 = 4;
    TAB_VIAL5 = 5;
    TAB_VIAL6 = 6;
    TAB_VIAL7 = 7;
    TAB_VIAL8 = 8;
    TAB_VIAL9 = 9;
    TAB_VIAL10 = 10;
    TAB_VIAL11 = 11;
    TAB_VIAL12 = 12;
    TAB_VIAL13 = 13;
    TAB_VIAL14 = 14;
    TAB_VIAL15 = 15;
    TAB_VIAL16 = 16;
    TAB_VIAL17 = 17;
    TAB_VIAL18 = 18;
    TAB_VIAL19 = 19;
    TAB_VIAL20 = 20;
    TAB_REVIEW = 21;
    TAB_EXIT = 22;

    REROUTE_EXCEPTION = "Mixing tab does not match controller. Re-routed.";

    /** Is the next button disabled? */
    disableNext = true;

    /** Is the Save & Exit button disabled? */
    disableExit = true;

    /** Loaded Prescription DTO */
    pscript = null;

    /** Loaded Patient DTO */
    patient = null;

    /** Index to the vial the current tab deals with */
    vialIndex = -1;

    /** @type{PrescribedVial[]} Full (unfiltered) list of vials in the Prescription */
    _allVials;

    /** @type{boolean} */
    isClassical;

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);
        super.enableBrowserCloseWarning();

        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.$filter = $injector.get('$filter');
        this.mixService = $injector.get('mixService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.patientService = $injector.get('patientService');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.labelPrinterService = $injector.get('labelPrinterService');
        this.$q = $injector.get("$q");
        this.$uibModal = $injector.get('$uibModal');
        let $route = $injector.get('$route');

        $scope.wizardTypeLabel = 'Type';
        $scope.wizardType = '';
        $scope.nextButtonLabel = 'Next';
        $scope.mixing = true;
        $scope.isScit = true;

        // Scope functions
        $scope.nextDisabled = () => this.nextDisabled();
        $scope.exitDisabled = () => this.exitDisabled();
        $scope.update = () => this.update();
        $scope.saveAndExit = (params) => this.saveAndExit(params);
        $scope.exitToDashboard = () => this.exitToDashboard();
        $scope.nextStep = () => this.nextStep();
        $scope.print = () => this._printLabels(true);
        $scope.reloadDisplay = () => $route.reload();

        // Scope data
        $scope.vialCount = 0;
        $scope.syringeCount = 0;
        $scope.antigenCount = 0;
    }

    /**
     * Returns Promise that resolves when the Prescription DTO (and Patient) has finished loading.
     * Actually begins loading on first call.
     *
     * @param validTabs {Array<int>} array of TAB_* values that are valid from the calling controller. Reroute if no match
     * @param haveServerPrepareForMixing {boolean=false} if true, tell the server to prepare the prescription for mixing
     */
    prescriptionLoaded(validTabs, haveServerPrepareForMixing) {
        if (!this.prescriptionPromise) {
            // update the latest patient data
            let params = this.getRouteParams();
            if (!params || !params.href) {
                console.error("No prescription given to initialize mixing wizard");
                this.exitToDashboard();
                this.prescriptionPromise = Promise.reject(null);
            }
            else {
                // Loads Patient and Prescription into scope. Promise lets you know when it's all done.
                let rxResolver = this.$q.defer();
                this.rxPromise = rxResolver.promise;
                this.prescriptionPromise = this.notificationService.init()
                    .then(() => {
                        let ref = {href: params.href};
                        if (haveServerPrepareForMixing)
                            return this.prescriptionService.getForMixing(ref);
                        else
                            return this.prescriptionService.getUncached(ref);
                    })
                    .then(pscript => {
                        this.pscript = pscript;
                        return this._processRxPartial(pscript);
                    })
                    .then(pscript => {
                        rxResolver.resolve(pscript);
                        return this._processLoadedPrescription(pscript, validTabs);
                    })
                    .then(pscript => this.patientService.get(pscript.patient))
                    .then(patient => {
                        this.patient = this.$scope.patient = patient;
                        super.lockEntity(patient, () => this.exitToDashboard());
                        return this.pscript;
                    })
                    .catch(reason => {
                        console.error(reason);
                        if (reason === this.REROUTE_EXCEPTION)
                            this.autoRoute();
                        else
                            this.exitToDashboard();
                    });
            }
        }

        return this.prescriptionPromise;
    }

    /* Load substance and category for prescribed substances */
    _processRxPartial(pscript) {
        let q = [];
        for (let vial of pscript.vials) {
            for (let prescribedSubstance of vial.substances) {
                ((ps) => {
                    q.push(
                        this.substanceService.get(ps.substance)
                            .then(substance => {
                                ps._substance = substance;
                                ps._category = substance._category;

                                if (ps.dosage > 0)
                                    this.$scope.syringeCount++;

                                if (ps._category._isAntigen)
                                    this.$scope.antigenCount++;
                            })
                    );
                })(prescribedSubstance);
            }
        }

        return this.$q.all(q).then(() => pscript);
    }

    /**
     * After loading a Prescription, do some post-processing on it.
     * - Set some $scope variables.
     * - Remove unused vials.
     * - Initialize/update clientData.mixwizard.
     *
     * Within each PrescribedSubstance in each PrescribedVial in the Prescription, populate the fields:
     * - '_substance' : the Substance DTO referenced by the 'substance' field.
     * - '_category' : the SubstanceCategory DTO referenced by the '_substance.category' field.
     *
     * @param pscript {Prescription}
     * @param validTabs {Array<int>} array of TAB_* values that are valid from the calling controller. Reroute if no match.
     * @return {Promise<Prescription>} to the modified pscript.
     */
    async _processLoadedPrescription(pscript, validTabs) {
        if (pscript.mixExternal) {
            this.$scope.wizardType = `${pscript.treatmentType} Mixing Check-In`;
        }
        else {
            this.$scope.wizardType = `${pscript.treatmentType} Prescription Mixing`;
        }

        this.$scope.isScit = (pscript.treatmentType === 'SCIT');
        this.isClassical = this.$scope.isClassical = !!pscript.vials.find(v => v.classicalDilution != null);
        this.$scope.isMixExternal = pscript.mixExternal;
        // Flag mixed vials
        if (pscript.treatmentVials && validTabs && validTabs[0] !== this.TAB_REVIEW) {
            // Removed already-mixed vials - unless doing final review
            pscript.vials.forEach(pVial => pVial._isMixed = pscript.treatmentVials.find(tVial => tVial.barcode === pVial.barcode) !== undefined);
        }

        if (this.isClassical) {
            // Define _classicalDilutions: ClassicalDilution[] on the first unmixed vial of each series, indicating which dilution level are in the series.
            let seriesVialMap = {}; // key=baseName
            for (let vial of pscript.vials) {
                let series = seriesVialMap[vial.baseName] = seriesVialMap[vial.baseName] || [];
                series.push(vial);
            }

            for(let baseName in seriesVialMap) {
                let series = seriesVialMap[baseName];
                series.sort((a,b) => ClassicalDilution[a.classicalDilution].order - ClassicalDilution[b.classicalDilution].order);

                // Put details about dilutions on first unmixed vial in the series
                for (let vial of series) {
                    if (!vial._isMixed) {
                        vial._classicalDilutions = series.filter(v => !v._isMixed).map(v => ClassicalDilution[v.classicalDilution]);
                        break;
                    }
                }
            }
        }

        // Keep reference to full vial list. Needed when subsequent mixes refer to previously mixed vials as a source substance.
        this._allVials = pscript.vials;
        // Remove empty vials or mixed vials
        pscript.vials = pscript.vials.filter(vial => vial.containsAntigen && !vial._isMixed);

        this.$scope.vialCount = pscript.vials.length;

        // Get vial tab names
        let vialTabNames = [];
        for (let vial of pscript.vials) {
            if (this.isClassical) {
                // Only one tab per dilution series
                if (!vialTabNames.includes(vial.baseName))
                    vialTabNames.push(vial.baseName);
            }
            else {
                // Otolaryngic - list every vial
                vialTabNames.push(vial.name);
            }
        }
        this.$scope.vialTabNames = vialTabNames;

        // Initialize wizard tab
        if (!angular.isObject(pscript.clientData.mixwizard)) {
            pscript.clientData.mixwizard = { tab: this.TAB_DETAILS };
        }
        else if (!angular.isNumber(pscript.clientData.mixwizard.tab)) {
            pscript.clientData.mixwizard.tab = this.TAB_DETAILS;
        }
        if (pscript.clientData.mixwizard.tab > pscript.vials.length || pscript.mixed) {
            pscript.clientData.mixwizard.tab = this.TAB_REVIEW;
        }

        let tab = pscript.clientData.mixwizard.tab;
        this.vialIndex = pscript.clientData.mixwizard.vialIndex || 0;

        console.log("On Mixing Wizard tab " + tab + ", vialIndex " + this.vialIndex);
        if (validTabs && !validTabs.includes(tab)) {
            throw this.REROUTE_EXCEPTION;
        }

        return this.$q.resolve(pscript);
    }

    /**
     * Is the Next button disabled?
     * Child classes may override to add some more logic.
     *
     * @return {boolean}
     */
    nextDisabled() {
        return this.disableNext;
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Child classes may override/extend.
     * @return Promise to updated Prescription
     */
    async nextStep(advanceTab = true) {
        // Disable buttons while we work
        this.disableNext = true;
        this.disableExit = true;

        // Advance to the next tab
        const wizardData = this.pscript.clientData.mixwizard;
        if (advanceTab) {
            wizardData.tab++;

            // Checklist and Prepare tabs are not used for external mixes
            if (wizardData.tab === this.TAB_CHECKLIST && this.$scope.isMixExternal) {
                wizardData.tab = 1;
            }

            if (wizardData.tab === this.TAB_PREPARE && this.isClassical)
                wizardData.tab++;
        }

        console.log("Clicked Next button - saving and moving to tab " + wizardData.tab);

        // Save updated prescription
        await this.update();

        // Move to next tab
        this.$scope.$apply(() => this.autoRoute());
    }

    /**
     * PUT this.pscript back to the server, and received the changes back.
     *
     * Override in child class to update the DTO from the view prior to calling return super.update().
     *
     * @return Promise to the updated Prescription received back from the server
     */
    async update() {
        this.updateFooterNote();

        this.pscript = await this.prescriptionService.update(this.pscript);
        return this.pscript;
    }

    /**
     * Is the Save & Exit button disabled?
     * Child classes may override to add some more logic.
     *
     * @return {boolean}
     */
    exitDisabled() {
        return this.disableExit;
    }

    /**
     * Save the current Prescription and exit to dashboard
     */
    async saveAndExit({doMix} = {}) {
        this.disableNext = true;
        this.disableExit = true;
        let updateSuccessful = await this.update();

        if (updateSuccessful) {
            if (doMix) {
                await this._mixComplete();
            }

            this.$scope.$apply(() => this.exitToDashboard());   
        }
        else {
            this.disableNext = false;
            this.disableExit = false;
        }
    }

    /**
     * Simply exit back to the dashboard.
     * Nothing to update or query - or already have.
     */
    exitToDashboard() {
        this.disableNext = true;
        this.disableExit = true;
        this.routeToPage(this.urlPaths.DASHBOARD_MIXING);
    }

    /**
     * Automatically route the the proper wizard tab based on the state of the Prescription.
     */
    autoRoute() {
        this.disableNext = true;
        this.disableExit = true;

        let newPath = null;
        let tab = this.pscript.clientData.mixwizard.tab;

        // If mixing is done. Review results regardless of tab selection
        if (this.pscript.mixed && tab !== this.TAB_EXIT) {
            tab = this.pscript.clientData.mixwizard.tab = this.TAB_REVIEW;
        }

        switch (tab) {
            case this.TAB_DETAILS:
                if (!this.pscript.mixExternal) {
                    newPath = this.urlPaths.DASHBOARD_INTERNAL_MIXING_DETAILS;
                }
                else {
                    newPath = this.urlPaths.DASHBOARD_EXTERNAL_MIXING_DETAILS;
                }
                break;
            case this.TAB_CHECKLIST:
                newPath = this.urlPaths.DASHBOARD_MIXING_CHECKLIST;
                break;
            case this.TAB_PREPARE:
                newPath = this.urlPaths.DASHBOARD_MIXING_PREPARE;
                break;
            case this.TAB_VIAL1:
            case this.TAB_VIAL2:
            case this.TAB_VIAL3:
            case this.TAB_VIAL4:
            case this.TAB_VIAL5:
            case this.TAB_VIAL6:
            case this.TAB_VIAL7:
            case this.TAB_VIAL8:
            case this.TAB_VIAL9:
            case this.TAB_VIAL10:
            case this.TAB_VIAL11:
            case this.TAB_VIAL12:
            case this.TAB_VIAL13:
            case this.TAB_VIAL14:
            case this.TAB_VIAL15:
            case this.TAB_VIAL16:
            case this.TAB_VIAL17:
            case this.TAB_VIAL18:
            case this.TAB_VIAL19:
            case this.TAB_VIAL20:
                if (!this.pscript.mixExternal) {
                    newPath = this.urlPaths.DASHBOARD_MIXING_VIAL+tab;
                }
                else {
                    newPath = this.urlPaths.DASHBOARD_MIXING_EXTERNAL_VIAL + tab;
                }
                break;
            case this.TAB_REVIEW:
                newPath = this.urlPaths.DASHBOARD_MIXING_REVIEW;
                break;
            default:
                console.log("Unknown mixing tab: " + tab);
                break;
        }

        if (newPath)
            this.routeToPage(newPath, /** Prescription */this.pscript);
        else
            this.exitToDashboard();
    }

    /**
     * Prescriptions have UserAction objects. Calling this will result in a 'name' field being added to each one.
     *
     * @return Promise that resolves when all names are populated
     */
    loadUserActionNames() {
        let q = [];
        q.push(this.userService.populateUserAction(this.pscript.orderedBy));
        q.push(this.userService.populateUserAction(this.pscript.approvedBy));
        q.push(this.userService.populateUserAction(this.pscript.changeRequestBy));

        for(let otherAction of this.pscript.otherActions) {
            q.push(this.userService.populateUserAction(otherAction));
        }

        return Promise.all(q).then(() => this.$scope.$digest());
    }

    /**
     * Get all UserActions related to mixing this Prescription.
     * @returns {Promise<UserAction[]}
     * @protected
     */
    loadPreviousMixes() {
        this.$scope.previousMixes = [];
        return this.mixService.getForPrescription(this.pscript)
            .then(mixesList => {
                this.$scope.previousMixes = mixesList.list;

                const actions = [];
                // Populate UserAction names
                for (let mix of mixesList.list) {
                    mix.otherActions.forEach(ua => actions.push(ua));
                    actions.push(mix.mixedBy);
                    actions.push(mix.approvedBy);
                }

                if (actions.length) {
                    return Promise.all(
                        actions.map(ua => this.userService.populateUserAction(ua))
                    ).then(() => this.$scope.$digest());
                }
            });
    }

    /**
     * Initialize scope for ag-footer-note.
     * @param label The label used to identify this "other" user-action. Used to find existing note, or create new one.
     */
    initFooterNote(label) {
        this.$scope.footerNote = this.pscript.otherActions.find((ua) => ua.label === label)
            || { label: label, note: "" };
    }

    /**
     * Update allergyTest.otherActions with any change via the footer note.
     * @returns {boolean} true if footer note has changed since last save.
     */
    updateFooterNote() {
        let footerNote = this.$scope.footerNote;
        if (!footerNote)
            return false;

        // If new footer note, add it to the otherActions array.
        if (footerNote.id === undefined) {
            if (footerNote.note.length > 0) {
                this.pscript.otherActions.push(footerNote);
                return true;
            }
            else {
                return false;
            }
        }
        else {
            // Modified existing note?
            return footerNote._isModified;
        }
    }

    /**
     * Return a prescribed vial, in the loaded prescription, that matches to the given vial ID.
     * @param vialIdToFind {string} ID of vial to find
     * @return {PrescribedVial} the sought PrescribedVial, or undefined.
     */
    getVialById(vialIdToFind) {
        return this._allVials.find(other => other.id === vialIdToFind);
    }

    _printLabels(doUpdate) {
        return this.labelPrinterService.printPrescribedVials(this.pscript)
            .then(() => {
                this.pscript.labelsPrinted = this.chronologyMappingService.currentDate(this.$scope.office.timezone);

                if (doUpdate) {
                    return this.update().then(() => this.$scope.reloadDisplay());
                }
                else {
                    return this.$q.resolve();
                }
            });
    }

    /**
     * Tell the server that this pass of mixing is complete, prior to ending the wizard.
     * @protected
     */
    _mixComplete() {
        if (!this.pscript.mixed) {
            // Clear mixing wizard data - will start over if another pass is run
            this.pscript.clientData = {};

            // Tell the server we're done. It'll create the treatment vials
            return this.prescriptionService.mixed(this.pscript, "").then(pscript => {
                this.pscript = pscript;
                return pscript;
            });
        }
        else {
            return Promise.resolve(this.pscript);
        }
    }

}
