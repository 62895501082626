'use strict';

import BaseTreatmentController from '../base-treatment.controller'
import TreatmentVialService from "../../../services/treatment-vial.service";

export default class TreatmentQuestionnaireController extends BaseTreatmentController {

    /** Patient must be confirmed before continuing */
    patientConfirmed = false;

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.questionnaireService = $injector.get('questionnaireService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.TreatmentType = $injector.get('TreatmentType');
        this.isClassical = null;
        
        // Scope functions
        $scope.confirmPatientModal = () => this.confirmPatientModal();
        $scope.consentFormModal = () => this.consentFormModal();
        $scope.treatmentReactionModal = (id) => this.treatmentReactionModal(id);

        $scope.confirmPatient = true;

        $scope.showTreatmentQuestions = true;

        $scope.setOther = function (reaction) {
            console.log(reaction);
            $scope.reaction = reaction;
        };

        // Scope data
        $scope.text = "Record Patient Answers";

        this.treatmentLoaded()
            .then(() => {
                if (!this.treatment.injections.length) {
                    this.noInjectionsModal();
                    throw "No injections available";
                }
                return this.prescriptionService.get(this.treatment.injections[0]._vial.prescription);
            }).then((pscript) => {
                this.isClassical = pscript.vials.some(v => v.classicalDilution);

                if (this.visit.questionnaire) {
                    return this.questionnaireService.getForVisit(this.visit).then(questionnaire => {
                        if (!questionnaire.performedBy)
                            questionnaire.performedBy = {
                                label: "Questionnaire",
                                note: ""
                            };

                        this.$scope.footerNote = questionnaire.performedBy;
                        return this.$scope.questionnaire = questionnaire;
                    });
                }
                else {
                    // No questions. We're only here for the 'Confirm Patient' dialog.
                    return this.$scope.questionnaire = {
                        answers: []
                    };
                }
            })
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TreatmentQuestionnaireController constructor completed!");
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.QUESTIONNAIRE]);
        this.disableNext = false;

        if (!this.patient.consentsGiven.includes(this.treatment.type)) {
            await this.consentFormModal(this.treatment.type);
        }

        if (this.treatment.type === 'SLIT')
            return this.slitVialSelection();
    }

    /**
     * @override
     */
    nextDisabled() {

        if (this.disableNext || !this.patientConfirmed)
            return true;

        if (this.$scope.questionnaire) {
            for (let i = 0; i < this.$scope.questionnaire.answers.length; i++) {
                var q = this.$scope.questionnaire.answers[i].question;
                if ($('input[name=' + q.id + ']:checked').val() == undefined) {
                    return true;
                }
            }
        } else
            return true;

        return false;
    }

    /**
     * @override
     */
    async nextStep() {
        this.disableNext = true;

        if (this.$scope.questionnaire.href) {
            this.questionnaireService.update(this.$scope.questionnaire).then(questionnaire => {
                this.$scope.questionnaire = questionnaire;

                if (this.$scope.warningFormData && this.$scope.warningFormData.proceedChoice === 'Reschedule') {
                    this.abort("Reschedule due to questionnaire warning.", true);
                } else if (questionnaire.warning && questionnaire.warning.warnText) {

                    if (questionnaire.warning.override) {
                        return this.advance();
                    } else {
                        this.$scope.questionnaire = questionnaire;
                        this.$scope.warningFormData = {
                            proceedChoice: null,
                            reasonToContinue: null,
                            addNotes: null,
                            warnings: []
                        };

                        let warnText = questionnaire.warning.warnText;

                        let spitText = warnText.replace(/\n/g, "~").split("~"); // split text by /n
                        spitText.forEach(str => {
                            if (str) {
                                this.$scope.warningFormData.warnings.push(str);
                            }
                        });

                        this.warningModal();
                        this.disableNext = false;
                    }
                } else {
                    return this.advance();
                }
            });
        }
        else {
            // No answers to save - just advance
            return this.advance();
        }
    };

    /**
     * Display warnings from the server regarding the answers
     */
    warningModal() {
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/treatment-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.reset = () => {
                    $scope.warningFormData.reasonToContinue = '';
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    if ($scope.warningFormData.proceedChoice === 'Continue' && $scope.warningFormData.reasonToContinue) {
                        return ($scope.warningFormData.reasonToContinue !== 'Other' || $scope.warningFormData.addNotes);
                    } else {
                        return ($scope.warningFormData.proceedChoice === 'Reschedule');
                    }
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.continue = () => {
                    if ($scope.warningFormData.proceedChoice === 'Reschedule') {
                        $scope.questionnaire.warning.override = {
                            note: 'Reschedule'
                        };
                        $uibModalInstance.close();
                    } else if ($scope.warningFormData.reasonToContinue === 'Other' && $scope.warningFormData.addNotes) {
                        $scope.questionnaire.warning.override = {
                            note: $scope.warningFormData.addNotes
                        };
                        $uibModalInstance.close();
                    } else if ($scope.warningFormData.reasonToContinue === 'Reenter') {
                        $uibModalInstance.dismiss();
                    } else {
                        $scope.questionnaire.warning.override = {
                            note: $scope.warningFormData.reasonToContinue
                        };
                        $uibModalInstance.close();
                    }
                };
            }
        });

        modalInstance.result.then(() => {
            this.nextStep();
        });
    }

    /**
     * Display a model for confirming the Patient's identity.
     * @return {Promise} when closed
     */
    confirmPatientModal() {

        return this.$uibModal.open({
                backdrop: 'static',
                keyboard: false,
                windowClass: 'confirmPatient',
                scope: this.$scope, //passed current scope to the modal
                template: require('./widgets/patient-confirm-modal.html'),
                css: require('./widgets/patient-confirm-modal.scss'),
                controller: function ($uibModalInstance, $scope) {
                    $scope.abort = () => $uibModalInstance.close(false);
                    $scope.confirmed = () => $uibModalInstance.close(true);
                }
            }).result
            .then((isConfirmed) => {
                this.patientConfirmed = isConfirmed;
                this.$scope.patientConfirmed = isConfirmed;

                if (!isConfirmed)
                    return this.abort("Wrong Patient", true);
            });
    }

    /**
     * Display a model for getting the consent form signed.
     */
    consentFormModal(treatmentType) {
        this.$uibModal.open({
                backdrop: 'static',
                keyboard: false,
                windowClass: 'patientConsent',
                scope: this.$scope, //passed current scope to the modal
                template: require('./widgets/patient-consent-modal.html'),
                css: require('./widgets/patient-consent-modal.scss'),
                controller: function ($uibModalInstance, $scope) {
                    $scope.treatmentType = treatmentType;
                    $scope.checkConsent = (consented) => $uibModalInstance.close(consented);
                }
            }).result
            .then((consented) => {
                if (consented) {
                    this.patient.consentsGiven.push(treatmentType);
                    this.patientService.update(this.patient)
                        .then(patient => this.patient = this.$scope.patient = patient);
                } else {
                    this.abort("Treatment consent not given by patient.", true);
                }
            });
    }

    /**
     * Patient answered YES to the question about reactions to the previous treatment.
     * Ask about it, and update the previous treatment.
     */
    treatmentReactionModal(id) {
        if (!this.treatment.previous) {
            console.log("No previous treatment.");
            return;
        }

        this.treatmentService.get(this.treatment.previous)
            .then(prevTreat => {

                // Create view model
                this.prevTreat = prevTreat;
                this.$scope.prevReactions = [];
                this.$scope.delayedReactionsNote = '';
                for (let injIdx = 0; injIdx < prevTreat.injections.length; ++injIdx) {
                    let injection = prevTreat.injections[injIdx];
                    if (injection.injected) {
                        this.$scope.prevReactions.push({
                            index: injIdx,
                            prevWhealSize: injection.reactionWhealSize,
                            newWhealSize: 0, // "No Reaction", which actually means no change. :-(
                            name: injection._vial.name
                        });
                    }
                }

                // Display modal
                let uibInstance = this.$uibModal.open({
                    backdrop: 'static',
                    keyboard: false,
                    windowClass: 'treatmentReaction',
                    scope: this.$scope, //passed current scope to the modal
                    template: require('./widgets/treatment-reaction-modal.html'),
                    css: require('./widgets/treatment-reaction-modal.scss'),
                    controller: ($uibModalInstance, $scope) => {

                        $scope.reactions = [];

                        if (this.treatment.type === this.TreatmentType.SLIT) {
                            $scope.reactions.push({
                                name: "No",
                                value: 0
                            });
                            $scope.reactions.push({
                                name: "Yes",
                                value: 1
                            });
                        } else {
                            for (let i = 0; i < 32; i++) {
                                if (i === 0) {
                                    $scope.reactions.push({
                                        name: "No Reaction",
                                        value: i
                                    });
                                } else if (i === 18) {
                                    $scope.reactions.push({
                                        name: i + "mm (Dime)",
                                        value: i
                                    });
                                } else if (i === 21) {
                                    $scope.reactions.push({
                                        name: i + "mm (Nickle)",
                                        value: i
                                    });
                                } else if (i === 24) {
                                    $scope.reactions.push({
                                        name: i + 'mm (Quarter)',
                                        value: i
                                    });
                                } else if (i === 30) {
                                    $scope.reactions.push({
                                        name: i + "mm (Half Dollar)",
                                        value: i
                                    });
                                } else if (i === 31) {
                                    $scope.reactions.push({
                                        name: i + "+mm",
                                        value: i
                                    });
                                } else {
                                    $scope.reactions.push({
                                        name: i + "mm",
                                        value: i
                                    });
                                }
                            }
                        }

                        $scope.previousReactionText = (whealSize) => {
                            if (this.treatment.type === this.TreatmentType.SLIT) {
                                return whealSize ? 'Yes' : 'No';
                            }

                            return whealSize + 'mm';
                        };
                        $scope.cancel = () => {
                            document.getElementById(id + 'y').checked = false;
                            document.getElementById(id + 'n').checked = false;
                            $uibModalInstance.dismiss();
                        };
                        $scope.continue = () => {
                            $uibModalInstance.close();
                            document.getElementById(id + 'n').disabled = true;
                        };
                    }

                });
                return uibInstance.result;
            })
            .then(() => {
                // Apply changes from modal
                let foundChange = false;
                for (let reaction of this.$scope.prevReactions) {
                    let injection = this.prevTreat.injections[reaction.index];
                    if (reaction.newWhealSize > injection.reactionWhealSize) {
                        foundChange = true;
                        injection.reactionWhealSize = reaction.newWhealSize;

                        if (this.$scope.delayedReactionsNote) {
                            if (injection.examinedBy.note)
                                injection.examinedBy.note = injection.examinedBy.note + " \n" + this.$scope.delayedReactionsNote;
                            else
                                injection.examinedBy.note = this.$scope.delayedReactionsNote;
                        }
                    }
                }

                // Save changes
                return (foundChange ? this.treatmentService.update(this.prevTreat) : undefined);
            })
            .then(() => {
                // Cleanup
                this.prevTreat = undefined;
                this.$scope.prevReactions = undefined;
                this.$scope.delayedReactionsNote = undefined;
            });
    }

    /**
     * Display prompt to select which SLIT vials to treat with
     * @return {Promise} when closed
     */
    slitVialSelection() {
        /** @type (color: string) -> {vials: TreatmentVial[], selectedVial: TreatmentVial} */
        let seriesMap = new Map();

        for (let inj of this.treatment.injections) {
            let series = seriesMap.get(inj._vial.color) || {
                vials: [{
                    id: '',
                    name: "No Treatment",
                    mixNum: 0
                }]
            };
            series.vials.push(inj._vial);
            if (inj.dosage > 0)
                series.selectedVial = inj._vial;
            seriesMap.set(inj._vial.color, series);
        }

        let seriesList = Array.from(seriesMap.values()).sort((a, b) => a.mixNum - b.mixNum);

        if (seriesList.length === 1) {
            // Skip if no choice (one series, with one vial and "No Treatment"
            if (seriesList[0].vials.length === 2) {
                return Promise.resolve();
            }

            // Remove "No Treatment" option if only one series exists
            seriesList[0].vials.shift();
        }

        return this.$uibModal.open({
                backdrop: 'static',
                keyboard: false,
                windowClass: 'vialSelection',
                template: require('./widgets/slit-selection-modal.html'),
                css: require('./widgets/slit-selection-modal.scss'),
                controller: function ($uibModalInstance, $scope) {
                    $scope.seriesList = seriesList;
                    $scope.cancel = () => $uibModalInstance.dismiss(true);
                    $scope.start = () => $uibModalInstance.close();
                }
            }).result
            .then(() => {
                let selectedInjections = 0;
                let selectedVialIds = seriesList.map(series => series.selectedVial ? series.selectedVial.id : null);
                for (let inj of this.treatment.injections) {
                    let use = selectedVialIds.includes(inj.vial.id);
                    inj.scheduleIndex = use ? 0 : -1;
                    if (use)
                        selectedInjections++;
                }

                if (selectedInjections === 0)
                    this.noInjectionsModal();
                else
                    return super.update();
            })
            .catch((isCancelled) => {
                if (isCancelled) {
                    this.abort("No injections were given.", true);
                } else {
                    this.noInjectionsModal();
                }
            });
    }

    /**
     * Followup exit modal
     */
    exitModal() {
        var outsideNote;
        var parent = this;
        this.$uibModal.open({
            windowClass: 'warningModal',
            keyboard: false,
            scope: this.$scope,
            template: require('./widgets/vial-status-exit-modal.html'),
            backdrop: 'static',
            controller: function ($uibModalInstance, $scope) {

                $scope.exitFormData = {
                    endApointment: undefined,
                    addNotes: undefined
                };

                $scope.validate = () => {
                    return $scope.exitFormData.addNotes;
                };

                $scope.cancel = () => {
                    $uibModalInstance.close(false);
                };

                $scope.continue = () => {
                    outsideNote = $scope.exitFormData.addNotes;
                    if (!outsideNote)
                        return;

                    $uibModalInstance.close(true);
                };
            }
        }).result.then((abort) => {
            if (abort) {
                super.abort(outsideNote, true);
            }
        });
    }
}
