'use strict';

import BaseService from './base.service.js';

/**
 * Service that keeps track of Inventory Alerts from the server, per the office selection,
 * and broadcasts a summary of alerts to all subscribers.
 */
export default class InventoryAlertService extends BaseService {

    static EVENT_NAME = "InventoryAlertService_AlertSummary";

    /** Current office selection */
    office = null;

    /** Promise received from NotificationService.subscribeInventoryAlerts */
    notificationSubscription = null;

    /**
     * Last computed summary of Inventory Alerts. Kept here in order to retransmit to new subscribers.
     * Also, defined here as part of the public API.
     */
    summary = {
        /** Banner text to display on top-level pages. null if none. */
        bannerText: null,
        /** {AlertSeverity} of the banner. null if none. */
        bannerSeverity: null,
        /** Map of entity ID or {AlertIconPosition} where an icon should appear, to {AlertSeverity} of the icon. */
        icons: new Map()
    };

    static $inject=['serverAPI', 'notificationService', 'sessionService', 'substanceService', 'AlertIconPosition', 'AlertSeverity', 'ServiceStatus', '$timeout', '$rootScope'];
    constructor(serverAPI, notificationService, sessionService, substanceService, AlertIconPosition, AlertSeverity, ServiceStatus, $timeout, $rootScope) {
        super(serverAPI);

        this.notificationService = notificationService;
        this.sessionService = sessionService;
        this.substanceService = substanceService;
        this.AlertIconPosition = AlertIconPosition;
        this.AlertSeverity = AlertSeverity;
        this.ServiceStatus = ServiceStatus;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
    }

    /**
     * Select this Office as the current one being used by the User.
     * Retrieves alerts for the office and subscribes to received updates from the server.
     *
     * @param office new Office. Set to null to stop processing inventory alerts (such as at logout).
     */
    select(office) {
        if (office === null || this.office === null || office.id !== this.office.id) {
            this.office = office;

            // Unsubscribe to old office
            if (this.notificationSubscription) {
                this.notificationSubscription.unsubscribe();
                this.notificationSubscription = null;
            }

            // If new office actually selected (not null), then fetch and subscribe.
            if (office) {
                this.notificationService.init()
                    .then(() => {
                        // Subscribe and start right away.
                        this.notificationSubscription = this.notificationService.subscribeInventoryAlerts(office.practice, office);
                        this.notificationSubscription.then(null, null, (notification) => this._summarizeAndBroadcast(notification.body.list));
                        this.notificationSubscription.start();

                        // Initialize state with poll (Could receive notice before this completes, but duplicates are harmless.)
                        return this._getActiveAtOffice();
                    })
                    .then(alertsList => {
                        this._summarizeAndBroadcast(alertsList.list);
                    });
            }
        }
    }
    
    /**
     * Dismiss inventory alerts for a specific inventory item
     *
     * @param practice the current user's practice
     * @parm inventoryId ID of the inventory item for which to dismiss alerts
     */
     clearAlerts(practice, inventoryId) {
        return this.serverAPI.post(practice._links.clearInventoryAlerts, { inventoryId: inventoryId });
     }

    /**
     * Subscribe to receive alert summary updates, and get the current summary.
     * Callback is quickly called once with the current summary, and then each time the summary changes.
     * Subscription automatically ends when scope is destroyed.
     *
     * @param scope caller's local $scope
     * @param subscriptionCallbackFunction callback function that receives this.summary as single parameter
     * @return unsubscribe function
     */
    subscribe(scope, subscriptionCallbackFunction) {
        // Add listener to scope
        let unsubscribe = scope.$on(InventoryAlertService.EVENT_NAME, (event,data) => subscriptionCallbackFunction(data));

        // Send summary to this scope on next processing cycle (don't wait for next change)
        this.sendTo(scope);

        return unsubscribe;
    }

    /**
     * Request that the given scope be sent the current alert summary, soon.
     * @param scope
     */
    sendTo(scope) {
        scope.$broadcast(InventoryAlertService.EVENT_NAME, this.summary);
    }

    /**
     * Get (poll) the current summary.
     * @returns {{bannerText: null, bannerSeverity: null, icons: Map}}
     */
    getSummary() {
        return this.summary;
    }

    /**
     * Fetch all active alerts for an office.
     * Used to initialize upon subscribing to notifications. Once subscribed, updates are received
     * via notifications.
     *
     * @return Promise to resulting InventoryAlerts.List
     */
    _getActiveAtOffice() {
        return this.serverAPI.get(this.office._links.inventoryAlerts, {});
    }

    /**
     * From new InventoryAlerts.List.list content from the server, re-summarize into this.summary and
     * broadcast results.
     *
     * @param serverAlertsList
     * @private
     */
    async _summarizeAndBroadcast(serverAlertsList) {

        // Init to fresh summary
        this.summary.bannerText = null;
        this.summary.bannerSeverity = null;
        this.summary.icons.clear();

        // Stop if no office selected.
        if (!this.office)
            return;

        /*
         * Working data
         */
        let redBannerSubstances = new Set();
        let yellowBannerSubstances = new Set();
        let redIconIds = new Set();
        let yellowIconIds = new Set();

        /*
         * Process each InventoryAlert from the server
         */
        for (let serverAlert of serverAlertsList) {
            // Verify office match (race condition?)
            if (serverAlert.office.id !== this.office.id)
                continue;

            let substance = serverAlert.substance ? await this.substanceService.get(serverAlert.substance) : null;

            if (serverAlert.status === this.ServiceStatus.RECALLED) {
                if (substance)
                    redBannerSubstances.add(substance.name);
                serverAlert.entityIds.forEach(id => redIconIds.add(id));
                serverAlert.vialTypes.forEach(type => redIconIds.add(this._toAlertIconPosition(type)));
            }
            else if (serverAlert.status === this.ServiceStatus.EXPIRED) {
                if (substance)
                    yellowBannerSubstances.add(substance.name);
                serverAlert.entityIds.forEach(id => yellowIconIds.add(id));
                serverAlert.vialTypes.forEach(type => yellowIconIds.add(this._toAlertIconPosition(type)));
            }
        }

        if (redIconIds.size > 0)
            redIconIds.add(this.AlertIconPosition.INVENTORY);
        if (yellowIconIds.size > 0)
            yellowIconIds.add(this.AlertIconPosition.INVENTORY);

        /*
         * Build summary
         */

        // Build the BANNER alert summary
        let bannerSubstances = null;
        let bannerSeverity = null;
        if (redBannerSubstances.size > 0) {
            bannerSubstances = redBannerSubstances;
            bannerSeverity = this.AlertSeverity.RED;
        }
        else if (yellowBannerSubstances.size > 0) {
            bannerSubstances = yellowBannerSubstances;
            bannerSeverity = this.AlertSeverity.YELLOW;
        }

        if (bannerSubstances) {
            let text = "Alert: ";
            let isFirst = true;
            for (let name of bannerSubstances) {
                if (isFirst) {
                    isFirst = false;
                    text = text + name;
                }
                else {
                    text = text + ", " + name;
                }
            }

            text = text + " has been ";
            if (bannerSeverity === this.AlertSeverity.RED)
                text = text + "recalled by the manufacturer";
            else
                text = text + "expired";

            text = text + ". Do not use ";
            if (bannerSubstances.size > 1)
                text = text + "these antigens until they have been replaced.";
            else
                text = text + "this antigen until it has been replaced.";

            this.summary.bannerText = text;
            this.summary.bannerSeverity = bannerSeverity;
        }

        // Build the ICONS alert summary
        for (let id of yellowIconIds)
            this.summary.icons.set(id, this.AlertSeverity.YELLOW);

        // Add red second, as it will replace any yellow for the same ID
        for (let id of redIconIds)
            this.summary.icons.set(id, this.AlertSeverity.RED);

        // Notify listeners
        this.$timeout(() =>
            this.$rootScope.$apply(() =>
                this.$rootScope.$broadcast(InventoryAlertService.EVENT_NAME, this.summary)));
    }

    _toAlertIconPosition(vialType) {
        switch (vialType) {
            case 'CONCENTRATE':
                return this.AlertIconPosition.CONCENTRATE;
            case 'SPT_WELL':
                return this.AlertIconPosition.SPT_BOARD;
            case 'IDT_VIAL':
                return this.AlertIconPosition.IDT_BOARD;
            case 'MIX_VIAL':
                return this.AlertIconPosition.MIX_BOARD;
            case 'TREATMENT_VIAL':
                return this.AlertIconPosition.PATIENT_VIAL;
            default:
                return null;
        }
    }
}
