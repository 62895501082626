"use strict";

import BaseApprovalController from "../base-approval.controller.js";
import {ClassicalDilution, ClassicalDilutions} from "../../../../models/classical-dilutions";

/**
 * Controller for Mix Approvals.
 *
 */
export default class MixApprovalController extends BaseApprovalController {

    constructor($scope, $injector, $filter) {
        super($scope, $injector);

        this.concentrateService = $injector.get('concentrateService');
        this.mixService = $injector.get('mixService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.billService = $injector.get('billService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.$q = $injector.get('$q');
        this.$filter = $filter;

        this.$scope.setBillToProvider = (provider) => this._setBillToProvider(provider);
        this.$scope.approveRxMix = () => this._approveRxMix();
        this.$scope.saveAndExit = () => this._saveAndExit();

        this.initScope();
        this.initData({mixHref: this.$scope.mixHref}).then();
    }

    /**
     * Initialize $scope with empty data until it can be async loaded
     */
    initScope() {
        this.$scope.patient = { name: '', chartNum: '', dayOfBirth: '' };
        this.$scope.orderedBy = { name: '', actionDateTime: '' };
        this.$scope.prescription = { type: '???' };
        this.$scope.notes = [];
        this.$scope.approvalNote = '';
        this.$scope.prescribedVials = [];
        /** Prescription approval */
        this.$scope.approvedBy = undefined;
        /** Mix Approval */
        this.$scope.mixedBy = undefined;
        this.$scope.mixApprovedBy = undefined;
        this.$scope.cancelledBy = undefined;
        this.$scope.billToProvider = undefined;
    }

    /**
     * (Re)initialize the view.
     * @param mixHref href of Mix to load
     * @param mix optional Mix already available (mixHref not used)
     */
    async initData({mixHref, mix}) {

        /*
         * Load data from server, if wasn't passed in.
         */
        if (mix) {
            this.mix = mix;
        }
        else {
            if (this.$scope.isApproval)
                this.mix = await this.mixService.getForApproval({href: mixHref});
            else
                this.mix = await this.mixService.getUncached({href: mixHref});

            mix = this.mix;
        }

        this.$scope.bill = await this.billService.get(mix.bill);

        if (!this.pscript) {
            this.pscript = await this.prescriptionService.getUncached(mix.prescription);
        }
        let pscript = this.pscript;

        this.$scope.isClassical = (pscript.diluteTo != null);
        this.$scope.isCancelled = !!this.pscript.cancelledBy;

        let orderedBy = this.$scope.orderedBy = await this.userService.populateUserAction(pscript.orderedBy);
        let providerPromise = this._getProviders();

        let patient = this.$scope.patient = await this.patientService.get(pscript.patient);
        let person = patient.person;
        patient.name = person.givenName + ' ' + person.middleName + ' ' + person.familyName;

        /*
         * Organize vials. Add to each PrescribedVial:
         * - _classicalDilution: ClassicalDilution object (not just the color string)
         * - _treatmentVial: the matching TreatmentVial, or undefined if not mixed.
         * - _mixDate: date the vial was mixed, or undefined if not mixed.
         * - _isThisMix: true if vial was mixed by the Mix being viewed
         * - _classicalSeries: PrescribedVial[] on the RED vial of a classical series, listing the entire series in dilution order
         * - _classicalSeriesIsThisMix: true if any vial in this series was part of this mix
         */
        if (!this.allMixes) {
            this.allMixes = (await this.mixService.getForPrescription(pscript)).list;
        }

        // Remove empty vials
        pscript.vials = pscript.vials.filter(vial => vial.containsAntigen);

        let seriesVialMap = {}; // key=baseName, value=PrescribedVial[]
        let minClassicalDilution = pscript.treatmentConfigs.reduce((result, current) => Math.min(result,current.dilution), 100);
        for (let vial of pscript.vials) {
            // If PrescribedVial has been mixed, this will link it to the matching TreatmentVial
            vial._treatmentVial = pscript.treatmentVials.find(tv => tv.barcode === vial.barcode);
            if (vial._treatmentVial) {
                const mixId = vial._treatmentVial.mix.id;
                vial._mixDate = this.allMixes.find(m => m.id === mixId).mixedBy.actionDateTime;
                vial._isThisMix = (this.mix.id === mixId);
                vial._useBy = vial._treatmentVial.useBy;
                vial._beyondUse = vial._treatmentVial.beyondUse;
            }

            if (this.$scope.isClassical && vial.classicalDilution) {
                vial._classicalDilution = ClassicalDilution[vial.classicalDilution];

                let series = seriesVialMap[vial.baseName];
                if (!series)
                    series = seriesVialMap[vial.baseName] = [];

                series.push(vial);

                if (vial.classicalDilution === ClassicalDilutions[minClassicalDilution].color) {
                    vial._classicalSeries = series;
                }
            }
        }

        // Display only the RED vials for classical - these point to the other vials via _classicalSeries.
        if (this.$scope.isClassical) {
            pscript.vials = pscript.vials.filter(v => v.classicalDilution === ClassicalDilutions[minClassicalDilution].color);
        }
        this.$scope.prescribedVials = pscript.vials;

        // Sort the classical series into dilution order
        if (this.$scope.isClassical) {
            for (let vial of this.$scope.prescribedVials) {
                vial._classicalSeries = vial._classicalSeries.filter(m => m._isThisMix);
                vial._classicalSeries.sort((a,b) => ClassicalDilution[a.classicalDilution].order - ClassicalDilution[b.classicalDilution].order);
                vial._classicalSeriesIsThisMix = (vial._classicalSeries.length > 0);
            }
            this.$scope.prescribedVials = this.$scope.prescribedVials.filter(m => m._classicalSeriesIsThisMix);
        }

        if (angular.isDefined(pscript.approvedBy)) {
            this.$scope.approvedBy = await this.userService.populateUserAction(pscript.approvedBy);
        }

        if (angular.isDefined(mix.mixedBy)) {
            this.$scope.mixedBy = await this.userService.populateUserAction(mix.mixedBy);
        }

        if (angular.isDefined(mix.approvedBy)) {
            this.$scope.mixApprovedBy = await this.userService.populateUserAction(mix.approvedBy);
        }

        if (angular.isDefined(pscript.cancelledBy)) {
            this.$scope.cancelledBy = await this.userService.populateUserAction(pscript.cancelledBy);
        }

        this.$scope.prescription.treatmentType = pscript.treatmentType;
        this.$scope.prescription.mixExternal = pscript.mixExternal;
        this.$scope.prescription.externalMixer = pscript.externalMixer;

        // Load displayable information from substances into the scope's view-model (pscript.vials.substances)
        await Promise.all([providerPromise, this._loadVialSubstances()]);

        this.addActionNote('Ordered', orderedBy, 1);
        pscript.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 10));
        this.addActionNote('Prescription Approved', pscript.approvedBy, 90);
        mix.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 110));
        this.addActionNote('Mixed', mix.mixedBy, 120);
        this.addActionNote('Mix Approved', mix.approvedBy, 190);
        this.addActionNote('Cancelled', pscript.cancelledBy, 200);

        this.$scope.$digest();


    }

    async _getProviders() {
        if (!this.$scope.providers) {
            let users = await this.userService.getUsers(this.$scope.practice, 'DOCTOR');
            this.$scope.providers = users.list;
        }

        if (this.$scope.bill.provider) {
            this.$scope.billToProvider = this.$scope.providers.find(m => m.id === this.$scope.bill.provider.id);
        }
    }

    /**
     * @param {integer} vialIndex
     *  refers to the index of the vial whose substances we need sorted
     *
     * @private
     */
    _sortChemicalsInVial(vialIndex) {

        let
            /** @type{Angular.filter} */sortingFilter = this.$filter("orderBy");

        // get ng's OrderBy filter to do the sorting heavy lifting
        this.pscript.vials[vialIndex].substances = sortingFilter(
            this.pscript.vials[vialIndex].substances,
            (aSubstance)=> aSubstance._isControl,
            false);
    }

    /**
     * Asynchronous data model establishment; the RX object's *vials* child's *substances* requires active data loading
     * to assemble the complete View-Model state.
     *
     * Updates the state of pscript.vials[x].substances[y] for all x and y. The raw state of the
     * pscript.vials[x].substances[y] when delivered does not contain *name* fields. The individual substances' names
     * must be explicitly requested.
     *
     * @private
     * @returns {Promise.<{undefined}>}
     */
    _loadVialSubstances() {

        return this.$q.all(this.$scope.prescribedVials.map( anRxVial => {
            anRxVial.newAntigen = { dosage: ""};
            anRxVial.newDiluent = { dosage: ""};

            return this.$q.all(anRxVial.substances.map( anRxVialSubstance => {

                return this.substanceService.get( anRxVialSubstance.substance, this.pscript)
                    .then(substance => {
                        anRxVialSubstance.name = substance.name; //may be replaced below

                        if (anRxVialSubstance.substanceVialId) {
                            anRxVialSubstance.name = 'Vial ' +
                                this.pscript.vials.find((v) => v.id === anRxVialSubstance.substanceVialId).name;
                            anRxVial._isEscalationVial = true;
                            anRxVialSubstance._isEscalation = true;
                        }
                        else if (anRxVialSubstance.substanceTreatmentVial) {
                            this.treatmentVialService.get(anRxVialSubstance.substanceTreatmentVial)
                                .then(refTreatVial => anRxVialSubstance.name = refTreatVial.name);
                            anRxVial._isDilutionVial = true;
                            anRxVialSubstance._isVialDilution = true;
                        }

                        anRxVialSubstance._isManagedDiluent = (anRxVialSubstance.substance.id === this.pscript.config.diluentSubstance.id);

                        return this.substanceCategoryService.get(substance.category, this.pscript)
                            .then(substanceCategory => {
                                anRxVialSubstance._isAntigen = substanceCategory._isAntigen;
                                anRxVialSubstance._isControl = substanceCategory._isNegativeControl;
                                anRxVialSubstance._isOtherVial = substanceCategory._isVial;
                                if (anRxVialSubstance._isAntigen) {
                                    anRxVialSubstance.endPoint = anRxVialSubstance.prevEndPoint;
                                }
                            });

                    });
            }))
        }))
        .then(()=> {
            for (let iVial in this.$scope.prescribedVials) {
                this._sortChemicalsInVial(iVial);
                this._updateVialVolume(iVial);
            }
        });
    }

    /**
     * Check for the presence of a DTO in an array using identity comparison.
     * @param array
     * @param dto
     * @returns {boolean} true if found, false if not.
     * @private
     */
    _isDTOInArray(array, dto) {
        for (let element of array)
            if (element.id === dto.id)
                return true;
        return false;
    }

    /**
     * @private
     */
    _setBillToProvider(provider) {
        if (provider && (!this.$scope.billToProvider || provider.id !== this.$scope.billToProvider.id)) {
            this.$scope.bill._dirty = true;
            this.$scope.bill.provider = this.$scope.billToProvider = provider;

            this.billService.update(this.$scope.bill)
                .then(() => {
                    this.initData({mixHref: this.$scope.mixHref, mix: this.mix });
                })
                .catch(() => {
                    this.initData({mixHref: this.$scope.mixHref, mix: this.mix });
                })
        }
    }

    /**
     * @private
     */
    async _saveMix(saveNote) {
        let q = [];

        if (this.$scope.bill._dirty) {
            q.push(this.billService.update(this.$scope.bill));
        }

        if (saveNote && this.$scope.approvalNote) {
            let otherAction = { note: this.$scope.approvalNote };
            this.mix.otherActions.push(otherAction);

            q.push(this.mixService.update(this.mix).then(mix => {
                this.mix = mix;
            }));
        }

        return this.$q.all(q).then(() => {
            this.initData({mixHref: this.$scope.mixHref, mix: this.mix });
        });
    }

    /**
     * @private
     */
    async _saveAndExit() {
        await this._saveMix(true);
        this.exitUiHandler();
    }

    /**
     * @private
     */
    async _approveRxMix() {
        await this._saveMix(false);
        this.mix = await this.mixService.approve(this.mix, this.$scope.approvalNote);

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback();
            }
        });
    }

    /**
     * This routine measures the volume of the contents of the a vial and updates the scope
     * fields associated with the UI display of the vial subject.
     * It also adjusts the managed diluent, if it exists.
     *
     * @param {Integer} vialIndex
     *  identifies WHICH vial's contents require measurement
     * @private
     *
     */
    _updateVialVolume(vialIndex) {

        let vial = this.$scope.prescribedVials[vialIndex];
        let preservative = this.pscript.config.preservativeSubstance;
        let managedSubstance = this.pscript.config.diluentSubstance;

        vial.totalAntigenVolume = 0;
        vial.totalDiluentVolume = 0;
        vial.totalPreservativeVolume = 0;
        vial.preservativeName = undefined;

        for (let aSubstance of vial.substances ) {
            const dosage = Number(aSubstance.dosage) || 0;
            if (preservative && aSubstance.substance.id === preservative.id) {
                vial.preservativeName = aSubstance.name;
                vial.totalPreservativeVolume += dosage;
            }
            else if (aSubstance._isControl) {
                vial.totalDiluentVolume += dosage;
            }
            else {
                vial.totalAntigenVolume += dosage;
            }
        }

        vial.grandTotalVolume = vial.totalAntigenVolume + vial.totalDiluentVolume + vial.totalPreservativeVolume;

        // Adjust managed diluent
        let volumeAdjustment = this._roundTwoDecimals(vial.capacity - vial.grandTotalVolume);
        if (volumeAdjustment !== 0) {
            let managedVialSubstance = vial.substances.find(ps => ps.substance.id === managedSubstance.id);
            if (managedVialSubstance) {
                managedVialSubstance.dosage = this._roundTwoDecimals(managedVialSubstance.dosage + volumeAdjustment);
                if (managedVialSubstance.dosage < 0) {
                    volumeAdjustment -= managedVialSubstance.dosage;
                    managedVialSubstance.dosage = 0;
                }
                vial.totalDiluentVolume += volumeAdjustment;
                vial.grandTotalVolume += volumeAdjustment;
            }
        }

        vial.totalAntigenVolume = this._roundTwoDecimals(vial.totalAntigenVolume);
        vial.totalDiluentVolume = this._roundTwoDecimals(vial.totalDiluentVolume);
        vial.totalPreservativeVolume = this._roundTwoDecimals(vial.totalPreservativeVolume);
        vial.grandTotalVolume = this._roundTwoDecimals(vial.grandTotalVolume);
        vial._hasVolumeError = (vial.totalAntigenVolume > 0 && vial.capacity !== vial.grandTotalVolume);

        // Update global volume error flag
        this.$scope.haveVolumeError = (this.$scope.prescribedVials.find(v => v._hasVolumeError) !== undefined);
    }

    /**
     * Round the given floating point value to two decimal places.
     * @param fpValue any number
     * @return {number} the number rounded to no more than two digits after the decimal point.
     * @private
     */
    _roundTwoDecimals(fpValue) {
        return Math.round(fpValue * 100) / 100;
    }
}
