'use strict';

import BaseService from './base.service.js';

export default class PrescriptionService extends BaseService {

    /**
     * Get a Prescription by its ReferenceDTO, bypassing the browser cache, and flagging the server
     * that user is reviewing this prescription for possible approval.
     *
     * @param ref ReferenceDTO to a Prescription
     * @return Promise that will resolve to the Prescription, or reject to a ServerError.
     */
    getForApproval(ref) {
        return super.getUncached(ref, { forApproval: true });
    }

    /**
     * Get a Prescription by its ReferenceDTO, bypassing the browser cache, and flagging the server
     * that user is preparing to mix this prescription (or print treatment labels from it).
     *
     * @param ref ReferenceDTO to a Prescription
     * @return Promise that will resolve to the Prescription, or reject to a ServerError.
     */
    getForMixing(ref) {
        return super.getUncached(ref, { forMixing: true });
    }

    /**
     * Get the Prescriptions that are ready to be mixed.
     *
     * @param office fetch for this Office
     * @returns Promise to a Prescription.List
     */
    getReadyToMix(office) {
        return this.serverAPI.get(office._links.unmixedPrescriptions);
    }

    /**
     * Get Prescriptions for a patient.
     *
     * @param patient  fetch for this Patient
     * @param unmixedOnly flag for getting only not-fully-mixed prescriptions (true) or all prescriptions
     * @returns Promise to a Prescription.List
     */
    getForPatient(patient, unmixedOnly = false) {
        return this.serverAPI.get(patient._links.prescriptions, { unmixedOnly: unmixedOnly });
    }

    /**
     * Get the Prescriptions that are mixed and ready to schedule the Patient's first appointment.
     *
     * @param office fetch for this Office
     * @returns Promise to a Prescription.List
     */
    getReadyToTreat(office) {
        return this.serverAPI.get(office._links.untreatedPrescriptions);
    }

    /**
     * Create a new Prescription manually.
     * (Normally a Prescription is automatically created upon approving an AllergyTest.)
     *
     * @param practice to add the Office to
     * @param prescription new ManualPrescription DTO
     * @returns Promise to the created Prescription DTO
     */
    create(practice, prescription) {
        return this.serverAPI.post(practice._links.prescriptions, {}, prescription);
    }
    
    /**
     * Rebuild this Prescription from it's source allergy test (as a Doctor).
     *
     * @param prescription existing Prescription DTO
     * @returns Promise to the updated Prescription DTO
     */
    rebuild(prescription) {
        return this.serverAPI.put(prescription._links.rebuild);
    }

    /**
     * Create the next prescription (either advancing, repeating, or diluting).
     *
     * @param practice to add the next prescription to
     * @param vials NextPrescription[] DTO
     * @returns Promise to the created Prescription DTO
     */
    createNext(practice, vials) {
        return this.serverAPI.post(practice._links.createNextPrescription, {}, vials);
    }

    /**
     * Create a new Prescription to replace a TreatmentVial.
     *
     * @param treatmentVial {TreatmentVial} old vial to replace
     * @param reason {PrescriptionReason} reason for the replacement
     * @param noMoreStatus {ServiceStatus} status to set old vial to if reason == NOMORE
     * @returns Promise to either the created Prescription DTO, or nothing if reason == NOMORE.
     */
    createToReplaceVial(treatmentVial, reason, noMoreStatus) {
        return this.serverAPI.post(treatmentVial._links.replace, { reason: reason, noMoreStatus: noMoreStatus });
    }

    /**
     * Update a Prescription, mark it as containing a charge request, and un-approve it.
     *
     * @param prescription modified Prescription
     * @param note apply change request with this note and un-approve the prescription.
     * @return Promise that will resolve to an updated Panel DTO, or reject with a ServerError.
     */
    updateAndRequestChangeApproval(prescription, note) {
        if (!note)
            throw "note required";

        return this.serverAPI.put(prescription._links.requestChange, {note}, prescription);
    }

    /**
     * Update a Prescription and request the server to add SLIT Escalation vials to it.
     *
     * @param prescription modified Prescription
     * @return Promise that will resolve to an updated DTO, or reject with a ServerError.
     */
    updateAndRequestSlitEscalation(prescription) {
        return this.serverAPI.put(prescription.href, {addSlitEscalation: true}, prescription);
    }

    /**
     * Overrides the update from base service to include notes.
     *
     * @param dto modified DTO
     * @return Promise that will resolve to an updated DTO, or reject with a ServerError.
     */
    update(dto, note) {
        return this.serverAPI.put(dto.href, {note}, dto).then(dto => this.transform(dto));
    }

    /**
     * Approve this Prescription (as a Doctor).
     *
     * @param prescription existing Prescription DTO
     * @param note text note to add to the approval.
     * @returns Promise to the updated Prescription DTO
     */
    approve(prescription, note) {
        return this.serverAPI.put(prescription._links.approve, {note});
    }

    /**
     * Cancel this Prescription (as a Doctor).
     *
     * @param prescription existing Prescription DTO
     * @param note text note to add to the cancellation.
     * @returns Promise to the updated Prescription DTO
     */
    cancel(prescription, note) {
        return this.serverAPI.put(prescription._links.cancel, {note});
    }

    /**
     * Update a Prescription and mark it as mixing completed.
     *
     * @param dto modified Prescription
     * @param note text notes to add to the mixing action.
     * @returns Promise that will resolve to an updated DTO, including new TreatmentVials
     */
    mixed(dto, note) {
        return this.serverAPI.put(dto._links.mixed, {note}, dto);
    }

    /**
     * Is the given PrescribedVial a SLIT escalation vial?
     * @param prescribedVial {PrescribedVial}
     * @returns {boolean} true if the vial contains another prescribed vial as a source content.
     */
    isEscalationVial(prescribedVial) {
        return -1 !== prescribedVial.substances.findIndex(ps => angular.isString(ps.substanceVialId) && ps.substanceVialId.length > 0);
    }
}
