'use strict';

import BaseMixingPrescriptionDetailsController from '../../common/base-prescription-details/base-prescription-details.controller';

export default class ExternalMixingPrescriptionDetailsController extends BaseMixingPrescriptionDetailsController {

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);
        
        this.updateUiDetails("Begin Check-In", "Review Prescription Details and Apply Labels to Vials");
    }

    /**
     * What to do when the Next button is click in the wizard.
     * @return Promise to updated Prescription
     * @override
     */
    async nextStep() {
        // Disable buttons
        this.disableNext = true;
        this.disableExit = true;

        if (this.$scope.rxChangeRequest) {
            this._sendChangeRequest();
        }
        else {
            return super.nextStep();
        }
    }
}
