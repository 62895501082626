'use strict';

import BaseMixingController from '../../base-mixing.controller';
import { ClassicalDilution, ClassicalDilutions } from '../../../../models/classical-dilutions';

export default class MixingChecklistController extends BaseMixingController {

    /** Array of Treatment Boards that may be scanned */
    validBoards = [];

    static $inject = ['$scope', '$injector', 'Procedure'];

    constructor($scope, $injector, Procedure) {
        super($scope, $injector);

        this.$uibModal = $injector.get('$uibModal');
        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.panelService = $injector.get('panelService');
        this.checklistService = $injector.get('checklistService');
        this.globalConfigService = $injector.get('globalConfigService');
        this.Procedure = Procedure;
        this.allowAutoBarcode = false;

        $scope.protectiveEquipment = () => this.protectiveEquipment();
        $scope.onBarcodeChange = () => this._onBarcodeChange();

        $scope.skipMixingBoard = false;
        $scope.loadedRx = false;
        $scope.mixing = true;
        $scope.text = "Complete Mixing Checklist";
        $scope.itemLabels = [];
        $scope.itemChecked = [];
        $scope.barCode = '';
        $scope.hasTraditionalArrangement = false;

        this._loadGlobalConfig();

        this.checklistService.getForMixing(this.$scope.practice)
            .then(checklist => {
                checklist.items.forEach(item => this.$scope.itemLabels.push(item));
                return this.prescriptionLoaded([this.TAB_CHECKLIST]);
            })
            .then(() => this._finalInitialization());
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    _finalInitialization() {
        this.$scope.mix = this.pscript;
        this.initFooterNote('Checklist');
        let boardPromise = this._manageValidBoards();

        if (this.$scope.isClassical) {
            let supplies = this.$scope.supplies = {
                substanceNames: '',
                numSubstances: 0,
                numSyringes: 0,
                numVials: this.pscript.vials.length,
                vialColorSummary: '',
                vialDetails: ''
            };

            let substanceArray = [];
            let vialNameArray = [];
            let vialColorCounts = new Map();
            for (let vial of this.pscript.vials) {
                vialNameArray.push(vial.name);
                vialColorCounts.set(vial.color, (vialColorCounts.get(vial.color) || 0) + 1);

                for (let prescribedSubstance of vial.substances) {
                    // Only list antigen names in the substance list
                    if (!prescribedSubstance.substanceVialId) {
                        substanceArray.push(prescribedSubstance._substance.name);
                    }
                    supplies.numSyringes++;
                }
            }

            // Sort and remove duplicate substance names
            substanceArray = substanceArray.sort().filter((name, idx, ary) => !idx || name !== ary[idx - 1]);
            supplies.numSubstances = substanceArray.length;
            supplies.substanceNames = substanceArray.join(', ');

            // Fill in vialSummary and vialDetails
            vialColorCounts.forEach((count, color) => {
                if (supplies.vialColorSummary.length)
                    supplies.vialColorSummary += ', ';
                supplies.vialColorSummary += (count + ' ' + color.toLowerCase());
            });
            supplies.vialDetails = vialNameArray.join(', ');
        }
        else {
            this.$scope.suppliesChecked = true; // classical-style not used - treat as checked
            this.$scope.itemLabels.unshift("Collect Supplies: " + this.$scope.vialCount + (this.$scope.vialCount > 1 ? " Vials & " : " Vial & ") + this.$scope.syringeCount + " Syringes");
        }

        this.disableNext = false;
        this.disableExit = false;

        boardPromise.then(() => {
            if (this.pscript.vials.every(v => v.reason === 'DILUTE_VIAL')) {
                this.$scope.skipMixingBoard = true;
            }
            else if (this.$scope.hasTraditionalArrangement) {
                this.$scope.skipMixingBoard = this.pscript.diluteTo === ClassicalDilutions[0].color;
            }
            else if (this.$scope.isClassical) {
                this.$scope.skipMixingBoard = true;
            }
        });

        this.$scope.loadedRx = true;
    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext) {
            return true;
        }

        if (!(this.$scope.skipMixingBoard || this.$scope.validBarcode)) {
            return true;
        }

        if (!this.$scope.suppliesChecked) {
            return true;
        }

        for (let idx = 0; idx < this.$scope.itemLabels.length; ++idx) {
            if (!this.$scope.itemChecked[idx])
                return true;
        }

        return false;
    }

    /**
     * Validate the board scanned whenever the barcode input changes
     * @private
     */
    _onBarcodeChange() {
        this.$scope.validBarcode = undefined;
        this.$scope.boardError = undefined;

        if (this.$scope.barCode === '=' && this.allowAutoBarcode && this.validBoards.length) {
            this.$scope.barCode = this.validBoards[0].barcode;
        }

        let foundBoard = false;
        for (let candidateBoard of this.validBoards) {
            if (this.$scope.barCode !== candidateBoard.barcode) {
                continue;
            }

            foundBoard = true;
            this.boardService.getWithCurrentVials(candidateBoard, null, true).then(board => {
                let invalidSubstances = this.boardService.getOutOfServiceSubstanceNames(board);

                if (invalidSubstances.length) {
                    this.$scope.validBarcode = false;
                    this.$scope.boardError = 'One or more substances on this board are not in service';
                }
                else {
                    this.$scope.validBarcode = true;
                    this.pscript.mixingBoard = { id: board.id };
                }
            });

            break;
        }

        if (!foundBoard) {
            this.$scope.validBarcode = false;
        }
    }

    /**
     * Initialize this.validBoards and refresh it whenever boards at the office change.
     * @private
     */
    _manageValidBoards() {
        // When boards change at the practice, refresh this.validBoards
        this.notificationService.init().then(() => {
            super.registerSubscription(this.notificationService.subscribeAllBoardUpdatesAtPractice(this.$scope.practice))
                .then(null, null, (notification) => {
                    if (notification.body.procedure === this.Procedure.MIXING)
                        this._refreshValidBoards()
                });

            super.registerSubscription(this.notificationService.subscribeBoardCreation(this.$scope.practice))
                .then(null, null, (notification) => {
                    if (notification.body.procedure === this.Procedure.MIXING)
                        this._refreshValidBoards()
                });

            super.startAllSubscriptions();
        });

        // Init the valid boards now
        return this._refreshValidBoards();
    }

    /**
     * Load the current valid Mixing boards at an office. Only called from _manageValidBoards().
     * @private
     */
    _refreshValidBoards() {
        if (this.pscript.mixingBoard) {
            return this.boardService.getWithNoVials(this.pscript.mixingBoard)
                .then(board => {
                    this.validBoards = [board];
                    this.$scope.hasTraditionalArrangement = board.arrangement === this.BoardArrangement.TRADITIONAL;
                });
        }
        else {
            return this.boardService.getAvailableInPanel(this.$scope.practice, this.pscript.panel, this.Procedure.MIXING)
                .then(boards => {
                    this.validBoards = boards.list;
                    this.$scope.hasTraditionalArrangement = boards.list.some(m => m.arrangement === this.BoardArrangement.TRADITIONAL);
                });
        }
    }

    protectiveEquipment() {
        this.$uibModal.open({
            windowClass: 'protectiveEquipment',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/protective-equipment-modal.html'),
            css: require('./widgets/protective-equipment-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.dismiss();

            }
        });
    }

}
