"use strict";

import BaseSectionController from "../../widgets/section-content-panel/controller";

/**
 * Primary Client Controller for Dashboard's Approvals section.
 *
 * Lists Tests, Prescriptions, Mixes, and Treatments that need doctor review and approval, and
 * provides the means for performing these approvals.
 */
export default class ApprovalsSectionController extends BaseSectionController {

    static $inject =
               ["$injector","$scope","$uibModal","DashboardSections","NotificationAction","UnapprovedType","UnapprovedStatus"];
    constructor( $injector , $scope , $uibModal ,     UiSectionTypes, NotificationAction , UnapprovedType , UnapprovedStatus ) {
        super($injector, $scope, UiSectionTypes.APPROVALS);

        this.$q = $injector.get('$q');
        this.$uibModal = $uibModal;
        this.NotificationAction = NotificationAction;
        this.UnapprovedType = UnapprovedType;
        this.UnapprovedStatus = UnapprovedStatus;
        this.notificationAction = $injector.get('NotificationAction');
        this.allergyTestSvc = $injector.get('allergyTestService');
        this.patientSrv = $injector.get('patientService');
        this.prescriptionSvc = $injector.get('prescriptionService');
        this.testReviewSvc = $injector.get('testReviewService');
        this.treatmentSvc = $injector.get('treatmentService');

        $scope.statusOptions = [
            { name: 'Pending', value: '' },
            { name: 'New', value: 'NEW' },
            { name: 'Resubmitted', value: 'RESUBMITTED' },
            { name: 'Incomplete', value: 'INCOMPLETE' },
            { name: 'Viewed', value: 'VIEWED' },
            { name: 'Cancelled', value: 'CANCELLED' },
        ];

        $scope.selectedStatus = $scope.statusOptions[0];

        $scope.onRowClick = (row) => this.rowClicked(row);
        $scope.onActionClick = (row) => this.cancelApproval(row);
        $scope.onStatusHover = (row) => this.getCancelledBy(row);
        $scope.approveAllSelected = () => this._approveAllSelected();
        $scope.printSuperbillReport = () => this._printSuperbillReport();
        $scope.onSelectedStatusChange = () => this._onSelectedStatusChange();

        this.reload();
    }

    /**
     * This is called when the user selects a different Office.
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this.reload();
    }

    /**
     * Load data, or reload when something changes.
     */
    async reload() {
        this.$scope.loading = true;
        this.$scope.rowCollection = [];
        this.$scope.rowSelectedObj = { isSet: false };

        await this.notificationService.init();
        this.unsubscribeAllSubscriptions();
        this._subscribeToUnapproved();

        let cancelledOnly = this.$scope.selectedStatus.value === 'CANCELLED';
        let unapproved = await this.officeService.getUnapproved(this.$scope.office, cancelledOnly);
        for (let una of unapproved.list) {
            this.$scope.rowCollection.push(this._populateRow(una, {}));
        }

        this.startAllSubscriptions();
        this.$scope.loading = false;
        this.$scope.$digest();
    }

    /**
     * Fill in/update fields in a row object for the table.
     * @param una Unassigned DTO
     * @param row existing/new row
     * @returns the updated row
     * @private
     */
    _populateRow(una, row) {
        row.id = una.id;
        row.href = una.href;
        row.type = una.type;
        row.patientName = una.patientPerson.givenName + ' ' + una.patientPerson.middleName + ' ' + una.patientPerson.familyName;
        row.chartNumber = una.patientChartNum;
        row.dateRequested = una.dateRequested;
        row.status = this.UnapprovedStatus[una.status];
        row.isCancelled = una.status === 'CANCELLED';
        row.canCancel = !row.isCancelled;

        switch (una.type) {
            case this.UnapprovedType.TEST:
                let procedure = una.procedure === 'TESTING' ? 'SPT/IDT' : una.procedure;
                row.approvalType = 'Testing (' + procedure + ')';
                break;
            case this.UnapprovedType.MIX:
                row.approvalType = 'Mixing (' + una.treatmentType + ')';
                row.canCancel = false;
                break;
            case this.UnapprovedType.PRESCRIPTION:
                row.approvalType = 'Prescription (' + una.treatmentType + ')';
                break;
            case this.UnapprovedType.TREATMENT:
                row.approvalType = 'Treatment (' + una.treatmentType + ')';
                break;
            case this.UnapprovedType.TEST_REVIEW:
                row.approvalType = 'Test Review (SPT/IDT)';
                row.canCancel = false;
                break;
            default:
                console.error("Unknown type: " + una.type);
                break;
        }

        if (!row.provider) {
            this.userService.getUserName(una.provider).then(name => row.provider = name);
        }

        return row;
    }

    /**
     * Callback when a an approval-list row is clicked.
     * @param row
     */
    rowClicked(row) {
        let /** ReferenceDto */modelReference = { id : row.id, href : row.href };

        switch (row.type) {
            case this.UnapprovedType.TEST:
                this.openTest(modelReference);
                break;
            case this.UnapprovedType.PRESCRIPTION:
                this.openPrescription(modelReference);
                break;
            case this.UnapprovedType.MIX:
                this.openMix(modelReference);
                break;
            case this.UnapprovedType.TREATMENT:
                this.openTreatment(modelReference);
                break;
            case this.UnapprovedType.TEST_REVIEW:
                this.openTestReview(modelReference);
                break;
        }
    }

    /**
     * Callback when the cancel approval link is clicked
     * @param row
     */
    cancelApproval(row) {
        this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('../../widgets/cancel-activity-modal.html'),
            css: require('../../widgets/cancel-activity-modal.scss'),
            controller: function ($uibModalInstance, $scope, activity) {
                let map = { TEST: 'Test', PRESCRIPTION: 'Prescription', MIX: 'Prescription', TREATMENT: 'Treatment' };
                $scope.activityType = map[activity.type] || 'Activity';

                $scope.yes = () => {
                    activity.cancellationNote = $scope.note;
                    $uibModalInstance.close(activity);
                };
                $scope.no = () => $uibModalInstance.dismiss();
            },
            resolve: {
                activity: () => {
                    return this._getActivity(row).then((result) => {
                        result.type = row.type;
                        return result;
                    });
                }
            }
        }).result.then((activity) => {
            switch (activity.type) {
                case 'TEST':
                    return this._cancelTest(activity, activity.cancellationNote);
                case 'PRESCRIPTION':
                    return this._cancelPrescription(activity, activity.cancellationNote);
                case 'MIX':
                    // Cancel aready completed mixing?
                    return undefined;
                case 'TREATMENT':
                    return this._cancelTreatment(activity, activity.cancellationNote);
                case 'TEST_REVIEW':
                    return this._cancelTestReview(activity, activity.cancellationNote);
            }
        });
    }

    getCancelledBy(row) {
        this._getActivity(row).then((activity) => {
            this.userService.populateUserAction(activity.cancelledBy).then((userAction) => {
               row.cancelledBy = userAction;
            });
        });
    }

    _getActivity(activity) {
        let ref = { id: activity.id, href: activity.href };
        if (activity._promise) {
            return activity._promise;
        }

        switch (activity.type) {
            case 'TEST':
                if (activity._allergyTest) {
                    return this.$q.resolve(activity._allergyTest);
                }
                activity._promise = this.allergyTestSvc.get(ref).then(allergyTest => {
                    activity._allergyTest = allergyTest;
                    return allergyTest;
                });
                break;

            case 'PRESCRIPTION':
            case 'MIX':
                if (activity._prescription) {
                    return this.$q.resolve(activity._prescription);
                }
                activity._promise = this.prescriptionSvc.get(ref).then(prescription => {
                    activity._prescription = prescription;
                    return prescription;
                });
                break;

            case 'TREATMENT':
                if (activity._treatment) {
                    return this.$q.resolve(activity._treatment);
                }
                activity._promise = this.treatmentSvc.get(ref).then(treatment => {
                    activity._treatment = treatment;
                    return treatment;
                });
                break;

            case 'TEST_REVIEW':
                if (activity._testReview) {
                    return this.$q.resolve(activity._treatment);
                }
                activity._promise = this.testReviewSvc.get(ref).then(testReview => {
                    activity._testReview = testReview;
                    return testReview;
                });
                break;
        }

        activity._promise.catch(() => activity.error = true);
        return activity._promise;
    }

    /**
     * Open a modal-popup for viewing & approving an Allergy Test.
     * @param {ReferenceDTO.<Test>} testRef
     */
    openTest(testRef) {
        this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_TEST, testRef);
    }

    /**
     * Open a modal-popup for viewing & approving a Prescription.
     * @param {ReferenceDTO.<Prescription>} prescriptionRef
     */
    openPrescription(prescriptionRef) {
        this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_RX, prescriptionRef);
    }

    /**
     * Open a modal-popup for viewing & approving a Combined Test Review.
     * @param {ReferenceDTO.<TestReview>} testReviewRef
     */
    openTestReview(testReviewRef) {
        this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_TEST_REVIEW, testReviewRef);
    }

    /**
     * Open a modal-popup for viewing & approving a Treatment.
     * @param {ReferenceDTO.<Treatment>} treatmentRef
     */
    openTreatment(treatmentRef) {
        this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_TREATMENT, treatmentRef);
    }

    /**
     * Open the viewing & approval widget, and point at it the operand Mix-address.
     * @param {ReferenceDTO.<Mix>} mixRef
     */
    openMix(mixRef) {
        this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_MIX, mixRef);
    }

    /**
     * User clicked on the Mark Approved button.
     * @private
     */
    _approveAllSelected() {
        for (let row of this.$scope.rowCollection) {
            if (row.isSelected === true) {
                let /** ReferenceDto */modelReference = { id : row.id, href : row.href };

                switch (row.type) {
                    case 'TEST':
                        this._approveTest(modelReference);
                        break;
                    case 'PRESCRIPTION':
                        this._approvePrescription(modelReference);
                        break;
                    case 'MIX':
                        this._approveMix(modelReference);
                        break;
                    case 'TREATMENT':
                        this._approveTreatment(modelReference);
                        break;
                    case 'TEST_REVIEW':
                        this._approveTestReview(modelReference);
                        break;
                }
            }
        }
    }

    /**
     * Approve an Allergy Test, without prompting for anything.
     * @private
     */
    async _approveTest(testRef) {
        let allergyTest = await this.allergyTestSvc.get(testRef);
        return this.allergyTestSvc.approve(allergyTest, "Bulk Approval", allergyTest.treatmentType);
    }

    /**
     * Approve a Combined Test Review, without prompting for anything.
     * @private
     */
    async _approveTestReview(testReviewRef) {
        let testReview = await this.testReviewSvc.get(testReviewRef);
        return this.testReviewSvc.approve(testReview, "Bulk Approval");
    }

    /**
     * Approve a Prescription, without prompting for anything.
     * @private
     */
    async _approvePrescription(pscriptRef) {
        let pscript = await this.prescriptionSvc.get(pscriptRef);

        if (pscript.treatmentConfigs.length)
            return this.prescriptionSvc.approve(pscript, "Bulk Approval");
        else
            console.log("Skipping bulk-approve of Prescription without treatmentConfig: " + pscript.id);
    }

    /**
     * Approve a Prescription Mix, without prompting for anything.
     * @private
     */
    async _approveMix(mixtRef) {
        let mix = await this.mixSvc.get(mixtRef);
        return this.mixSvc.approve(mix, "Bulk Approval");
    }

    /**
     * Approve a Treatment, without prompting for anything.
     * @private
     */
    async _approveTreatment(treatRef) {
        let treat = await this.treatmentSvc.get(treatRef);
        return this.treatmentSvc.approve(treat, "Bulk Approval");
    }

    /**
     * Cancel an Allergy Test
     * @private
     */
    async _cancelTest(test, note) {
        return this.allergyTestSvc.cancel(test, note);
    }

    /**
     * Cancel a Combined Test Review
     * @private
     */
    async _cancelTestReview(testReview, note) {
        return this.testReviewSvc.cancel(testReview, note);
    }

    /**
     * Cancel a Prescription
     * @private
     */
    async _cancelPrescription(pscript, note) {
        return this.prescriptionSvc.cancel(pscript, note);
    }

    /**
     * Cancel a Treatment
     * @private
     */
    async _cancelTreatment(treatment, note) {
        return this.treatmentSvc.cancel(treatment, note);
    }

    /**
     * User clicked on the Print Billing Report button
     * @private
     */
    _printSuperbillReport() {

    }

    _onSelectedStatusChange() {
        let cancelledOnly = this.$scope.selectedStatus.value === 'CANCELLED';
        let needsReload =
            (this.$scope.prevSelectedStatus && this.$scope.prevSelectedStatus.value === 'CANCELLED') ||
            this.$scope.selectedStatus.value === 'CANCELLED';

        if (needsReload) {
            this.reload();
        }

        this.$scope.prevSelectedStatus = this.$scope.selectedStatus;
    }

    /**
     * Subscribe to a notification that an Unapproved entity has changed.
     * @private
     */
    _subscribeToUnapproved() {
        this.registerSubscription(this.notificationService.subscribeToAllUnapprovedAtOffice(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onUnapprovedNotification(notification));
    }

    /**
     * When an update Unapproved DTO is received, update the table.
     * @param notification
     * @private
     */
    _onUnapprovedNotification(notification) {
        // Find the Unapproved in the existing list
        let una = notification.body;
        let foundIdx = -1;
        foundIdx = this.$scope.rowCollection.findIndex(row => una.id === row.id);

        if (notification.topic.endsWith(this.NotificationAction.REMOVED)) {
            // Item approved, remove from list if found.
            if (foundIdx > -1) {
                this.$scope.rowCollection.splice(foundIdx, 1);
            }
        }
        else if (foundIdx > -1) {
            this._populateRow(una, this.$scope.rowCollection[foundIdx]);
        }
        else {
            // Add new entry
            this.$scope.rowCollection.push(this._populateRow(una, {}));
        }

        this.$scope.rowCollection.push({});
        this.$scope.rowCollection.pop();
    }

}
