'use strict';

import angular from 'angular';
import ngRoute from 'angular-route';

import LogInController from './login.controller.js';

export default angular.module('pages.login', [ngRoute])
    .config(config)
    .controller('LogInController', LogInController)
    .name;

function config($routeProvider) {
    $routeProvider
        .when('/login', {
            template: require('./login.html'),
            css: require("./login.scss"),
            controller: 'LogInController',
            resolve: {
                session: () => { return null; }
            }
        })
        .when('/sso', {
            template: require('./login.html'),
            css: require("./login.scss"),
            controller: 'LogInController',
            resolve: {
                session: () => { return null; }
            }
        });
}

config.$inject = ['$routeProvider'];

