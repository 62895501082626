'use strict';

import angular from 'angular';
import ngRoute from 'angular-route';

import UrlPaths from "../../../models/url-paths";

import SelfCheckinController from './self-checkin.controller.js';

export default angular.module('pages.kiosk.self-checkin', [
        ngRoute,
        UrlPaths
    ])
    .config(routingConfig)
    .controller('SelfCheckinController', SelfCheckinController)
    .name;

function routingConfig($routeProvider, UrlPaths) {
    $routeProvider
        .when(UrlPaths.KIOSK_PAGE, {
            template: require('./self-checkin.html'),
            css: require("./self-checkin.scss"),
            controller: 'SelfCheckinController'
        })
}

routingConfig.$inject = ['$routeProvider', 'UrlPaths'];
