"use strict";

import {ClassicalDilutions} from "../../../models/classical-dilutions";

export default class CreateNextClassicalRxModalController {
    static $inject = ['$uibModalInstance', '$scope', '$injector', 'oldVial', 'practice', 'isTraditionalArrangement'];

    constructor($uibModalInstance, $scope, $injector, oldVial, practice, isTraditionalArrangement) {
        const prescriptionService = $injector.get("prescriptionService");
        const PrescriptionReason = $injector.get('PrescriptionReason');

        $scope.oldVial = oldVial;
        $scope.isTraditionalArrangement = isTraditionalArrangement;
        $scope.dilutionCount = 1;
        $scope.ClassicalDilutions = ClassicalDilutions;
        $scope.selections = [ true, true, true, true, true ];

        $scope.toggle = (order) => {
            setTimeout(() => {
                const isSet = $scope.selections[order];
                $scope.dilutionCount = isSet ? order + 1 : order;
                for (let i = 0; i < ClassicalDilutions.length; ++i) {
                    $scope.selections[i] = isTraditionalArrangement ? i === order : (i < $scope.dilutionCount);
                }

                $scope.$digest();
            });
        };
        $scope.toggle($scope.dilutionCount-1);

        $scope.cancel = () => $uibModalInstance.dismiss();

        $scope.submit = () => {
            $scope.isSending = true;

            const request = [{
                vial: { id: oldVial.id },
                reason: PrescriptionReason.REPEAT,
                diluteTo: ClassicalDilutions[$scope.dilutionCount-1].color
            }];
            prescriptionService.createNext(practice, request)
                .then(() => $uibModalInstance.close());
        }
    }
}
