'use strict';

// Framework
import angular from 'angular';
import ngRoute from 'angular-route';

// Support from higher echelons within the project
import agStandardPageLayout from "../common/standard-layout";

// Logical Data Model
import UrlPaths from "../../models/url-paths";
// UI-Widgetry
import moduleWidgets from "./widgets";

// The primary controller impl in this module.
import PatientEditController from './patient-edit/patient-edit.controller';
import PatientListController from './patient-list/patient.list.controller.js';
import PatientDetails from "./patient-details";
import PatientDetailsController from './patient-details/patient.details.controller.js';
import AttachmentUploadController from './patient-details/attachments.controller.js';

export default angular.module('pages.patient', [
        // framework : principle-startup
        ngRoute,
        // From up above
        agStandardPageLayout,
        // data-model
        UrlPaths,
        // dynamically support UI
        moduleWidgets,
        PatientDetails
    ])
    .config(routingConfig)
    .controller('PatientEditController', PatientEditController)
    .controller('PatientListController', PatientListController)
    .controller('PatientDetailsController', PatientDetailsController)
    .controller('AttachmentUploadController', AttachmentUploadController)
    .directive('stPatientList', [stPatientList])
    .directive('btnNewPatient', [btnNewPatient])
    .directive("btnAppointmentDetails", [btnAppointmentDetails])
    .directive('pdPatientInformation', [pdPatientInformation])
    .directive('pdPatientTreatmentDetails', [pdPatientTreatmentDetails])
    .directive('pdPhotoUploader', [pdPhotoUploader])
    .directive('pdPatientDetailsNav', [pdPatientDetailsNav])
    .directive("pdPatientDetailsHistory", [pdPatientDetailsHistory])
    .directive("pdPatientDetailsHistoryTable", [pdPatientDetailsHistoryTable])
    .directive("pdPatientDetailsTests", [pdPatientDetailsTests])
    .directive("pdPatientDetailsTestsTable", [pdPatientDetailsTestsTable])
    .directive("pdPatientDetailsRx", [pdPatientDetailsRx])
    .directive("pdPatientDetailsRxTable", [pdPatientDetailsRxTable])
    .directive("pdPatientDetailsTreatment", [pdPatientDetailsTreatment])
    .directive("pdPatientDetailsTreatmentTable", [pdPatientDetailsTreatmentTable])
    .directive("pdPatientDetailsNotes", [pdPatientDetailsNotes])
    .directive("pdPatientDetailsNotesTable", [pdPatientDetailsNotesTable])
    .directive("pdPatientDetailsBilling", [pdPatientDetailsBilling])
    .directive("pdPatientDetailsBillingTable", [pdPatientDetailsBillingTable])
    .directive("pdPatientDetailsAttachments", [pdPatientDetailsAttachments])
    .directive("pdPatientDetailsAttachmentsTable", [pdPatientDetailsAttachmentsTable])
    .directive('addAttachmentType', [addAttachmentType])
    .directive('dropAttachment', [dropAttachment])
    .directive("pdPatientDetailsAlerts", [pdPatientDetailsAlerts])
    .directive("pdPatientDetailsAlertsTable", [pdPatientDetailsAlertsTable])
    .filter('agPatientListFilter', [agPatientListFilter])
    .filter('agPatientHistoryFilter', ['$filter', agPatientHistoryFilter])
    .filter('agPatientNotesFilter', ['$filter', agPatientNotesFilter])
    .name;

function routingConfig($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.PATIENT_LIST, {
            template: require('./patient-list/layout.html'),
            css: require('./patient-list/styles.scss'),
            controller: 'PatientListController',
            controllerAs: 'ctrl'
        })
        .when(UrlPaths.PATIENT_DETAILS, {
            template: require('./patient-details/layout.html'),
            css: require('./patient-details/styles.scss'),
            controller: 'PatientDetailsController',
        });

}
routingConfig.$inject = ['$routeProvider', 'UrlPaths'];

function stPatientList() {
    return {
        template: require('./widgets/st-patient-list.html'),
        css: require('./widgets/st-patient-list.scss')
    };
}

function btnNewPatient() {
    return {
        template: '<button class="btn btn-primary" ng-click="newPatientModal()">New Patient</button>',
    };
}

function btnAppointmentDetails() {
    return {
        template: '<button id="appointmentDetails" class="btn btn-primary" ng-click="appointmentDetails()">Appointment Details</button>',
        link: function(scope, element, attrs) {

        }
    };
}

function pdPatientInformation() {
    return {
        template: require('./widgets/pd-patient-information.html'),
        css: require('./widgets/pd-patient-information.scss')
    };
}

function pdPatientTreatmentDetails() {
    return {
        template: require('./widgets/pd-patient-treatment-details.html'),
        css: require('./widgets/pd-patient-treatment-details.scss')
    };
}

function pdPhotoUploader() {
    return {
        restrict: 'E',
        template: require('./widgets/uploader.html'),
        scope: {
            patient: '='
        },
        controller: ['$scope', 'patientService', 'attachmentService', function($scope, patientService, attachmentService) {
            // Send the form that contains the upload.  Will need an ajax call or something to a uploader?
            $scope.sendFile = function(el) {

                if (!el.files || !el.files[0]) {
                    return;
                }

                var file = el.files[0];

                attachmentService.createForPatient($scope.patient, file, "Patient Photo")
                    .then((attachment) => {
                        console.log('Patient photo uploaded successfully.');
                        $scope.patient.photo = attachment;
                        patientService.update($scope.patient)
                            .then((p) => {
                                $scope.patient = p;
                            });
                    }, (err) => {
                        console.log('An error occurred uploading the patient photo. [' + err + '].');
                    });
            };
        }],
        replace: false,
        link: function(scope, element, attrs, controller) {
            $(element).find('.fake-uploader').click(function() {
                $(element).find('input[type="file"]').click();
            });
        }
    };
}

function pdPatientDetailsNav() {
    return {
        template: require('./widgets/pd-patient-details-nav.html'),
        css: require('./widgets/pd-patient-details-nav.scss')
    };
}

function pdPatientDetailsHistory() {
    return {
        template: require('./widgets/pd-patient-details-history.html'),
        css: require('./widgets/pd-patient-details-history.scss')
    };
}

function pdPatientDetailsHistoryTable() {
    return {
        template: require('./widgets/pd-patient-details-history-table.html'),
        css: require('./widgets/pd-patient-details-history-table.scss')
    };
}

function pdPatientDetailsTests() {
    return {
        template: require('./widgets/pd-patient-details-tests.html'),
        css: require('./widgets/pd-patient-details-tests.scss')
    };
}

function pdPatientDetailsTestsTable() {
    return {
        template: require('./widgets/pd-patient-details-tests-table.html'),
        css: require('./widgets/pd-patient-details-tests-table.scss')
    };
}

function pdPatientDetailsRx() {
    return {
        scope: {
            addRxHandler: "&",
            viewVialHistoryHandler: "&",
            vials: "=",
            showPrintTable: "=",
            showDetails: "&",
            editVialHandler: "&",
            createNextRxHandler: "&",
            printVialLabelHandler: "&"
        },
        template: require('./widgets/pd-patient-details-rx.html'),
        css: require('./widgets/pd-patient-details-rx.scss')
    };
}

function pdPatientDetailsRxTable() {
    return {
        template: require('./widgets/pd-patient-details-rx-table.html'),
        css: require('./widgets/pd-patient-details-rx-table.scss')
    };
}

function pdPatientDetailsTreatment() {
    return {
        template: require('./widgets/pd-patient-details-treatment.html'),
        css: require('./widgets/pd-patient-details-treatment.scss')
    };
}

function pdPatientDetailsTreatmentTable() {
    return {
        template: require('./widgets/pd-patient-details-treatment-table.html'),
        css: require('./widgets/pd-patient-details-treatment-table.scss'),
        link: function(scope) {
            /*
             * Q. How does this operate? A. Instances of this directive are spawned within scopes
             * known to contain a predicate called \"showDetail\". (patient.details.controller packs it on)
             *
             * Q. Why is that predicate not attributed to our scope (that's what other ElementalDirectives do)?
             * A. The DirectiveDefinition API does not offer a la carte scope binding. If that was supported, then I
             * would use the conventional mechanisms. However, the scope also contains non-bound view-model data which
             * is an actively consumed by the rendered UI. Ergo: we cannot bind the event handler unless we also
             * establish explicit bindings for everything else. A fundamental shift in ViewImpl's data delivery is
             * beyond the scope of this change, so I'm expanding upon pre-established data-flow.
             */
            scope.onRowClick = scope.showDetails;
        }
    };
}

function pdPatientDetailsNotes() {
    return {
        template: require('./widgets/pd-patient-details-notes.html'),
        css: require('./widgets/pd-patient-details-notes.scss')
    };
}

function pdPatientDetailsNotesTable() {
    return {
        template: require('./widgets/pd-patient-details-notes-table.html'),
        css: require('./widgets/pd-patient-details-notes-table.scss')
    };
}

function pdPatientDetailsBilling() {
    return {
        template: require('./widgets/pd-patient-details-billing.html'),
        css: require('./widgets/pd-patient-details-billing.scss')
    };
}

function pdPatientDetailsBillingTable() {
    return {
        template: require('./widgets/pd-patient-details-billing-table.html'),
        css: require('./widgets/pd-patient-details-billing-table.scss')
    };
}

function pdPatientDetailsAttachments() {
    return {
        template: require('./widgets/pd-patient-details-attachments.html'),
        css: require('./widgets/pd-patient-details-attachments.scss')
    };
}

function pdPatientDetailsAttachmentsTable() {
    return {
        template: require('./widgets/pd-patient-details-attachments-table.html'),
        css: require('./widgets/pd-patient-details-attachments-table.scss')
    };
}

function addAttachmentType() {
    return {
        template: require('./widgets/add-attachment-types.html'),
        scope: {
            selectedOption: '='
        },
        link: function(scope, elem, attr) {
            scope.itemTypes = ['Test Consent', 'Treatment Consent', 'Mixing Consent', 'Epinephrine Consent', 'Anaphylaxis Plan', 'Other'];
            scope.optionChanged = function(opt) {
                scope.selectedOption = opt;
            };
            scope.optionChanged(scope.itemTypes[0]);

        }
    };
}

function dropAttachment() {
    return {
        restrict: 'A',
        scope: {
            file: '=',
            accept: '@?'
        },
        link: function(scope, elem, attrs) {
            elem.on('dragenter', (event) => {
                event.stopPropagation();
                event.preventDefault();
            });

            elem.on('dragover', (event) => {
                event.stopPropagation();
                event.preventDefault();
            });

            elem.on('drop', (event) => {
                event.stopPropagation();
                event.preventDefault();

                var dt = event.dataTransfer;
                var files = dt.files;

                // check file type dropped in.
                if (files && files[0] && files[0].type && (!scope.accept || scope.accept.indexOf(files[0].type) !== -1)) {
                    // Drop occurs outside of angular. Apply scope changes.
                    scope.$apply(($scope) => {
                        $scope.file = files[0];
                    });
                } // else invalid file type.

            });

            scope.$on('$destroy', () => {
                elem.off('dragenter');
                elem.off('dragover');
                elem.off('drop');
            });
        }
    }
}

function pdPatientDetailsAlerts() {
    return {
        template: require('./widgets/pd-patient-details-alerts.html'),
        css: require('./widgets/pd-patient-details-alerts.scss')
    };
}

function pdPatientDetailsAlertsTable() {
    return {
        template: require('./widgets/pd-patient-details-alerts-table.html'),
        css: require('./widgets/pd-patient-details-alerts-table.scss')
    };
}

function agPatientListFilter() {
    return function(patients, filters) {
        if (!filters) {
            return patients;
        }

        let items = {
            tFilters: filters,
            out: []
        };

        patients.forEach((value) => {
            let match = true;

            if (items.tFilters['givenName'] && value.givenName.toUpperCase().indexOf(items.tFilters['givenName'].toUpperCase()) === -1) {
                match = false;
            }

            if (match && items.tFilters['familyName'] && value.familyName.toUpperCase().indexOf(items.tFilters['familyName'].toUpperCase()) === -1) {
                match = false;
            }

            if (match && items.tFilters['procedure'] && value.nextAppointmentText.toUpperCase().indexOf(items.tFilters['procedure'].toUpperCase()) === -1) {
                match = false;
            }

            if (match) {
                items.out.push(value);
            }
        });

        return items.out;
    }
}

function agPatientHistoryFilter($filter) {
    return function(historyList, filters) {
        if (!filters) {
            return historyList;
        }

        let items = {
            tFilters: filters,
            out: []
        };

        historyList.forEach((value) => {
            let match = true;

            let formattedDate = $filter('date')(value.performedBy.actionDateTime, 'MM/dd/yyyy');
            let formattedAction = $filter('agActivityName')(value.activity);

            if (items.tFilters['date'] && formattedDate.indexOf(items.tFilters['date']) === -1) {
                match = false;
            }

            if (match && items.tFilters['activity'] && formattedAction.toUpperCase().indexOf(items.tFilters['activity'].toUpperCase()) === -1) {
                match = false;
            }

            if (match && items.tFilters['performedBy'] && value.performedBy.name.toUpperCase().indexOf(items.tFilters['performedBy'].toUpperCase()) === -1) {
                match = false;
            }

            if (match && items.tFilters['reaction'] !== undefined && items.tFilters['reaction'] !== "" && value.reaction != items.tFilters['reaction']) {
                match = false;
            }

            if (match) {
                items.out.push(value);
            }
        });

        return items.out;
    }
}

function agPatientNotesFilter($filter) {
    return function(notesList, filters) {
        if (!filters) {
            return notesList;
        }

        let whenFilter = filters.when;
        let createdByFilter = filters.createdBy ? filters.createdBy.toUpperCase() : undefined;
        let detailsFilter = filters.details ? filters.details.toUpperCase() : undefined;
        let textFilter = filters.text ? filters.text.toUpperCase() : undefined;

        let out = notesList.filter((value) => {
            let formattedDate = $filter('date')(value.when, 'MM/dd/yyyy');

            let match = (
                (!whenFilter || formattedDate.indexOf(whenFilter) > -1) &&
                (!createdByFilter || value.createdBy.toUpperCase().indexOf(createdByFilter) > -1) &&
                (!detailsFilter || value.details.toUpperCase().indexOf(detailsFilter) > -1) &&
                (!textFilter || value.text.toUpperCase().indexOf(textFilter) > -1)
            );

            return match;
        });

        return out.sort((a, b) => {
            let result = a.date.localeCompare(b.date);
            if (result === 0) {
                // Date matched. Sort by time
                return a.time.localeCompare(b.time);
            } else if (filters.reverse)
                return -result;
            else
                return result;
        });
    }
}
