'use strict';

/**
 * SessionService stores objects belong to user Session. The objects are survive even after user refresh the page
 * Note: $window.sessionStorage objects survive only in a tab. Create a new tab or window => new session => objects gone
 */
export default class SessionService {

    KEY_DASHBOARD_TAB = "dashboard-tab";
    KEY_INVENTORY_TAB = "inventory-tab";
    KEY_REPORTS_TAB = "reports-tab";
    KEY_OFFICE = "office";
    KEY_PRACTICE = "practice";
    KEY_USER = "user";
    KEY_NOTICES = "notices";

    _loginSessionKeys = [ this.KEY_DASHBOARD_TAB, this.KEY_INVENTORY_TAB, this.KEY_REPORTS_TAB, this.KEY_OFFICE, this.KEY_PRACTICE, this.KEY_USER, this.KEY_NOTICES ];
    _allKeys = this._loginSessionKeys;

    static $inject=['$injector', 'uuid'];
    constructor($injector, uuid) {
        this.officeService = $injector.get('officeService');
        this.practiceService = $injector.get('practiceService');
        this.substanceService = $injector.get('substanceService');
        this.userService = $injector.get('userService');
        this.serverAPI = $injector.get('serverAPI');
        this.$window = $injector.get('$window');
        this._uuid = uuid.v4();
    }

    /**
     * Returns a unique ID of this application session.
     * @returns {String}
     */
    getInstanceId() {
        return this._uuid;
    }

    set(key, value){
        console.assert(this._allKeys.includes(key),
            'Are you sure you want to store \'' + key + '\' in session storage?');

        try {
            if (this.$window.Storage) {
                this.$window.sessionStorage.setItem(key, JSON.stringify(value));
                return true;
            }
        } catch (error) {
            console.error(error, error.message);
        }

        return false;
    }

    get(key) {
        try{
            if (this.$window.Storage) {
                let value = this.$window.sessionStorage.getItem(key);
                if (value)
                    return JSON.parse(value);
            }
        } catch(error) {
            console.error(error, error.message);
        }

        return undefined;
    }

    remove(key) {
        try{
            if (this.$window.Storage) {
                this.$window.sessionStorage.removeItem(key);
                return true;
            }
        } catch(error) {
            console.error(error, error.message);
        }

        return false;
    }

    /**
     * Clear session data for a login session.
     */
    logout() {
        this._loginSessionKeys
            .forEach(key => this.remove(key));
    }

    getSession(user) {
        let session = {
            user: this.get(this.KEY_USER),
            practice: this.get(this.KEY_PRACTICE),
            office: this.get(this.KEY_OFFICE)
        };

        if (session.user && session.practice) {
            return this.serverAPI.$q.resolve(session);
        }

        let userPromise = user ? this.serverAPI.$q.resolve(user) : this.userService.getCurrent();
        return userPromise.then(user => {
            session.user = user;
            this.set(this.KEY_USER, user);
            return this.officeService.get(user.office);
        }).then(office => {
            session.office = office;
            this.set(this.KEY_OFFICE, office);
            return this.practiceService.getCurrent();
        }).then(practice => {
            session.practice = practice;
            this.set(this.KEY_PRACTICE, practice);
            this.substanceService.initForPractice(practice);
            return session;
        });
    }
}
