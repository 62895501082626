'use strict';

export default class PasswordRecoveryController {
    static $inject = ['$injector', '$scope', '$location', 'userService'];

    constructor($injector, $scope, $location, userService) {
        
        this.$scope = $scope;
        this.$location = $location;
        this.$uibModal = $injector.get('$uibModal');

        this.urlPaths = $injector.get('UrlPaths');
        this.userService = userService;

        // Validate that a token parameter exists
        let token = $location.search().token;
        if (!token) {
            // if no token, the redirect to login
            $location.path(this.urlPaths.LOGIN_PAGE);
        }
        $scope.token = token;
        
        // Call the server before doing anything else
        this.initResetPassword(token);
        
        // When this function is run, it should check the questions against the DB, and if true set reset to true (to display next page, otherwise it should return $scope.error = true to show error message.
        $scope.next = () => {
            this.validateSecurityAnswers(this.$scope.user, this.$scope.securityAnswers);
        };
        
        $scope.resetPassword = () => {
            let userSetupData = this.constructUserSetupData();
            this.resetPassword(userSetupData);
        };
    }

    /**
     * Initialized the controller.  Pulls the user and security questions server
     */
    async initResetPassword(token){
        // First resolve the global link before processing
        await this.userService.serverAPI.globalAvailable;

        // Authenticate the reset password token
        await this.userService.authToken('RESET_PASSWORD', token);

        // Get current user
        let user = await this.userService.getCurrent();

        // Gets a list of active security questions
        let securityQuestions = await this.userService.getSecurityQuestions(user);

        // Call apply to refresh the view
        this.$scope.$apply(()=>{
            // Set user
            this.$scope.user = user;

            // Set the username
            this.$scope.email = user.email;

            // Set the person
            this.$scope.person = user.person;

            // Clear the security answers
            this.$scope.securityAnswers = [];

            // Set the question in the security answers
            securityQuestions.list.forEach((e) => {
                let securityAnswer = {
                    question : e,
                    answer : null
                };

                this.$scope.securityAnswers.push(securityAnswer);
            });
        });
    }

    /**
     * Validates the security answers to the user
     * @param user user DTO object
     * @param securityAnswers List of security answers
     */
    async validateSecurityAnswers(user, securityAnswers){
        // The responseData will be true or false
        let responseData = await this.userService.validateSecurityAnswers(user, securityAnswers);

        this.$scope.$apply( () => {
           if( responseData ){
               // If true, then continue
               this.$scope.error = false;
               this.$scope.reset = true;
           }
           else{
               // Stay on same screen
               this.$scope.error = true;
           }

           // Disable the button
           this.$scope.formSubmitted = false;
        });
    }

    /**
     * Creates the user setup data
     * @returns UserSetupData Contains the token, security answers, and new password
     */
    constructUserSetupData(){
        let userSetupData = {
            // Set token
            token: this.$scope.token,

            // Set userSecurityQuestions
            securityAnswers : this.$scope.securityAnswers,

            // Set password
            password : this.$scope.newPassword
        };

        return userSetupData;
    }

    /**
     * Calls the user service to reset the password.
     * @param userSetupData Contains the token, security answers, and new password
     */
    async resetPassword(userSetupData){
        try {
            // Calls the server to reset the password
            await this.userService.resetPassword(userSetupData);

            // Successful save, redirect to login
            this.$scope.$apply( () => { this.$location.path(this.urlPaths.LOGIN_PAGE); } );
        }catch( error ){
            this.$scope.$apply( ()=> {
                this.$scope.passwordServerError = true;
                // Get the error messages from the error
                if( error.data && error.data.errors ){
                    this.$scope.passwordErrorMessages = err.data.errors;
                }
            });
        }

        // Re-enabled the button
        this.$scope.formSubmitted = false;
    }
}