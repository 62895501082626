"use strict";

/**
 * Little controller that just subscribes in updates from InventoryAlertService,
 * and displays the banner content that it provides.
 */
export default class GlobalAlertsController {

    static $inject=["$scope", "inventoryAlertService", "sessionService"];
    constructor($scope, inventoryAlertService, sessionService) {

        // Subscribe to receive changes to InventoryAlerts.
        // Callback happens immediately to initialize, and then whenever the alerts content changes.
        inventoryAlertService.subscribe($scope, alertSummary => {
            $scope.message = alertSummary.bannerText;
            $scope.severity = alertSummary.bannerSeverity;
        });
    }
}
