'use strict';

import BaseService from './base.service.js';

export default class TreatmentService extends BaseService {

    _REASON_CODE_PASS = 'Pass';
    _REASON_CODE_PROCEED = 'Proceed';
    _REASON_CODE_DILUTE = 'Dilute';
    _REASON_CODE_SKIPPED = 'Skipped';
    _REASON_CODE_CONTINUE = 'Continue';
    _REASON_CODE_UNKNOWN = undefined;

    static $inject = ['serverAPI', 'treatmentVialService', 'PrescriptionReason'];

    constructor(serverAPI, treatmentVialService, PrescriptionReason) {
        super(serverAPI);
        this.treatmentVialService = treatmentVialService;
        this.PrescriptionReason = PrescriptionReason;
    }

    /**
     * Get a Treatment by its ReferenceDTO, bypassing the browser cache, and flagging the server
     * that user is reviewing this treatment for possible approval.
     *
     * @param ref ReferenceDTO to a Treatment
     * @return Promise that will resolve to the Treatment, or reject to a ServerError.
     */
    getForApproval(ref) {
        return super.getUncached(ref, { forApproval: true });
    }

    /**
     * Get the Treatment for a Visit.
     *
     * @param visit DTO
     * @return Promise to an Treatment DTO
     */
    getForVisit(visit) {
        return super.get(visit.treatment, visit);
    }

    /**
     * Get all Treatments that include this vial; thus it's use history.
     *
     * @param treatmentVial {TreatmentVial} vial who's history is to be retrieved.
     * @returns {Promise} to Treatment.List of matches (unsorted)
     */
    getVialHistory(treatmentVial) {
        return this.serverAPI.get(treatmentVial._links.treatments);
    }

    /**
     * Get history of Treatments for this Patient.
     *
     * @query maxResults (optional) only return this many of the latest results
     * @response {Promise} to Treatment.List of matches (newest first)
     */
    getPatientHistory(patient, maxResults) {
        return this.serverAPI.get(patient._links.treatments, { maxResults: maxResults });
    }

    /**
     * Advance the test to the next TreatmentStage.
     * <p/>
     * The preconditions for the current stage must be met first, otherwise this call will not result in any change.
     *
     * @param treatment existing Treatment DTO
     * @returns Promise to the updated Treatment DTO
     */
    advance(treatment) {
        return this.serverAPI.put(treatment._links.advance, {})
            .then(dto => this.transform(dto));
    }

    /**
     * Abort (prematurely end) a treatment.
     * Change the TreatmentStage to ABORTED, unless it was already COMPLETED.
     *
     * @param treatment existing Treatment DTO
     * @param note text note to add to the performedBy UserAction.
     * @return updated DTO, with stage = ABORTED
     */
    abort(treatment, note) {
        return this.serverAPI.put(treatment._links.abort, { note })
            .then(dto => this.transform(dto));
    }

    /**
     * Approve this treatment (as a Doctor).
     *
     * @param treatment existing Treatment DTO
     * @param note text note to add to the approval.
     * @returns Promise to the updated Treatment DTO
     */
    approve(treatment, note) {
        return this.serverAPI.put(treatment._links.approve, { note: note })
            .then(dto => this.transform(dto));
    }

    /**
     * Cancel this treatment (as a Doctor).
     *
     * @param treatment existing Treatment DTO
     * @param note text note to add to the cancellation.
     * @returns Promise to the updated Treatment DTO
     */
    cancel(treatment, note) {
        return this.serverAPI.put(treatment._links.cancel, { note: note })
            .then(dto => this.transform(dto));
    }

    /**
     * Get the content for a pop-up showing notes for a given injection.
     *
     * @param injection {Injection} DTO
     * @returns {string[]} Array of notes.
     */
    getInjectionNotes(injection) {
        let out = [];
        if (injection.doseOverrideBy && injection.doseOverrideBy.note)
            out.push(injection.doseOverrideBy.note);
        if (injection.examinedBy && injection.examinedBy.note)
            out.push(injection.examinedBy.note);
        return out;
    }

    /**
     * Generate the "Reason Code" to display for the results of any Vial Test in the Injection
     * @param injection {Injection} DTO
     * @returns {string} a "Reason Code" to display
     */
    getIdtReasonCode(injection) {
        if (!angular.isNumber(injection.idtWhealSize))
            return this._REASON_CODE_UNKNOWN; // no vial test measured

        let warn = injection.idtWarning;
        if (!warn) {
            if (injection.idtWhealSize == -1)
                return this._REASON_CODE_SKIPPED;
            else
                return this._REASON_CODE_PASS;
        }

        if (warn.override)
            return this._REASON_CODE_PROCEED;
        else if (
            injection.refill === this.PrescriptionReason.DILUTE_NEW ||
            injection.refill === this.PrescriptionReason.DILUTE_VIAL
        ) {
            return this._REASON_CODE_DILUTE;
        }
        else // have warning, no override, and not dilute = I don't know what is going on
            return this._REASON_CODE_UNKNOWN;
    }

    /**
     * Generate the "Reason Code" to display for the results of any actual injection in the Injection
     * @param injection {Injection} DTO
     * @returns {string} a "Reason Code" to display
     */
    getReactionReasonCode(injection) {
        if (!angular.isNumber(injection.reactionWhealSize)) {
            return this._REASON_CODE_UNKNOWN; // no injection measured
        }

        let warn = injection.reactionWarning;
        if (!warn) {
            return this._REASON_CODE_PASS;
        }

        if (warn.override) {
            return this._REASON_CODE_PROCEED;
        }
        else if (
            injection.refill === this.PrescriptionReason.DILUTE_NEW ||
            injection.refill === this.PrescriptionReason.DILUTE_VIAL
        ) {
            return this._REASON_CODE_DILUTE;
        }
        else {
            // There's a warning and no override, and it's not dilute.
            // Must be "Continue with recommended Settings" / "BYPASS" in treatment examination controller _showWarningModal
            return this._REASON_CODE_CONTINUE;
        }
    }

    /**
     * Get days until next treatment for vial for a given treatment
     * @param treatment DTO
     * @returns number of days, -1 if no treatment
     */
    getDaysUntilTreatment(treatmentVial) {
        return this.serverAPI.get(treatmentVial._links.daysUntilTreatment);
    }

    /**
     * Transform a DTO from the server to inline embedded data in useful ways.
     *
     * Result is a Treatment DTO with:
     *
     * - Peer to each ReferenceDTO<TreatmentVial> is a member field called _vial, containing the referenced DTO.
     *
     * @override
     * @param dto a loaded Treatment
     * @return Promise to modified dto
     */
    transform(dto) {
        var deferred = this.serverAPI.$q.defer();

        // Confirm it's a Treatment DTO - could be a ServerError
        if (!dto.injections) {
            deferred.resolve(dto);
        }
        else {
            let treatment = dto;

            Promise.all(
                treatment.injections.map(injection => {

                    return this.treatmentVialService.get(injection.vial)
                        .then(vialDto => {
                            injection._vial = vialDto;
                        });
                })
            )
                .then(() => deferred.resolve(treatment))
                .catch(obj => deferred.reject(obj));
        }

        return deferred.promise;
    }
}
