import angular from 'angular';
import ngRoute from 'angular-route';

import TestResultsController from './testing-results.controller.js';

export default angular.module('pages.testing.testingResults', [ngRoute])
    .config(config)
    .controller('TestResultsController',TestResultsController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/testingResults', {
        template: require('./testing-results.html'),
        css: require('./testing-results.scss'),
        controller: 'TestResultsController'
    });
}

config.$inject = ['$routeProvider'];