import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentInitializeController from './treatment-initialize.controller.js';

export default angular.module('pages.treatment.initialize', [ngRoute])
    .config(config)
    .controller('TreatmentInitializeController',TreatmentInitializeController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_INITIALIZE, {
        template: '<div/>',
        controller: 'TreatmentInitializeController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
