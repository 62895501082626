'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import moment from 'moment';

import './styles.scss';

export default class PatientVialExpirationReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.PATIENT_VIAL_EXPIRATION);
        this.svm.futureDateRange = this.svm.dateRange;
        this._updateFutureDateRange(false);

        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getPatientVialExpiration(this.reportCriteria);
    }

    _renderReportData(reportData) {
        let currentDate = this.chronologyMappingService.currentDate(this.$scope.office.timezone);

        this.$scope.rowCollection = [];
        for(let row of reportData) {
            let expirationDate = this.chronologyMappingService.startOfDay(row.expirationDate);
            row.daysUntilExpiration = this.chronologyMappingService.daysBetween(expirationDate, currentDate);

            row.expirationDate = moment(row.expirationDate);
            row.expirationDateDisplay = row.expirationDate.format('MM/DD/YYYY');
            row.mixedDate = moment(row.mixedDate);
            row.mixedDateDisplay = row.mixedDate.format('MM/DD/YYYY');

            this.$scope.rowCollection.push(row);
        }
    }

    _getCsvMeta() {
        return [
            { name: 'Barcode', value: 'barcode' },
            { name: 'Vial Name', value: 'vialName' },
            { name: 'Patient Name', value: 'patientName' },
            { name: 'Chart Number', value: 'chartNumber' },
            { name: 'Location', value: 'location' },
            { name: 'Expiration Date', value: 'expirationDateDisplay' },
            { name: 'Mixed Date', value: 'mixedDateDisplay' },
            { name: 'Days Until Expiration', value: 'daysUntilExpiration' }
        ];
    }
}
