let viewDefinitionInstance;

import angular from "angular";

export default class View {

    // Analog of the property handling preceding the link member
    constructor() {
        this.restrict = "E";

        this.scope = {
            // Incoming assigned values from external callers

            /** @type{String} we're just passing it through */
            uiText : "@",

            /** @type{DashboardSections} the instance of this enum is part of the identity of this instance */
            sct : "=",

            /**
             * @type{Boolean} a true value indicates this instance corresponds to the current **active-section** of
             * the logical parent UI.
             */
            isActiveSection : "=",

            /** @type{String} assumed to be the terminal path of a URL */
            sectionUrl : "@",

            /** @type{string of AlertSeverity} undefined if no alert */
            alert : '='
        };

        this.css = require("./styles.scss");
        this.template = require("./layout.html");
    }

    // Analog of the familiar, old school method of same name and role
    link(scope, element, attrs) {

        // Toggles the presence/absence of the chosen item contextual modifier co-class
        scope.$watch("isActiveSection", (isActive, wasActive)=> {
            if (isActive) {
                element[0].classList.add("the-active-item");
            }
            else {
                element[0].classList.remove("the-active-item");
            }

        });
    }
};
