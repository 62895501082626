'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentCheckListController from './treatment-checklist.controller.js';

export default angular.module('pages.treatment.checklist', [ngRoute])
    .config(config)
    .controller('TreatmentCheckListController', TreatmentCheckListController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_CHECKLIST, {
        template: require('./treatment-checklist.html'),
        css: require('./treatment-checklist.scss'),
        controller: 'TreatmentCheckListController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
