"use strict";

import angular from "angular";
import View from "./view";
import Controller from "./controller";

export default angular.module("pages.common.standard-layout.account-management", [])
	.controller("AccountManagementController", Controller )
	.directive("agAccountManagement", initView )
	.name;

function initView() {
	return new View();
}
initView.$inject = [];