"use strict";

import angular from "angular";
import View from "./view";
import Controller from "./controller";

export default angular.module("pages.common.standard-layout.location-management", [])
    .controller("LocationManagementController", Controller )
    .directive("agLocationManagement", initView )
    .name;

function initView() {
    return new View();
}
initView.$inject = [];
