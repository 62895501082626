'use strict';

import StronglyCachedDtoService from './strongly-cached-dto.service.js';

/**
 * SubstanceCategory DTOs are all loaded upon first demand and stored for later fetching.
 * No updates are supported. (Use Admin U/I for updating.)
 */
export default class SubstanceCategoryService extends StronglyCachedDtoService {

    static $inject = ['serverAPI'];
    constructor(serverAPI) {
        super(serverAPI, 'substanceCategories');
    }

    /**
     * Annotate a SubstanceCategory with additional meta-data.
     *
     * @override
     * @param dto
     * @return modified dto
     */
    _transform(dto) {

        dto._isAntigen = false;
        dto._isControl = false;
        dto._isPositiveControl = false;
        dto._isNegativeControl = false;
        dto._isVial = false;

        switch (dto.type) {
            case 'ANTIGEN':
                dto._isAntigen = true;
                break;
            case 'POSITIVE_CONTROL':
                dto._isPositiveControl = true;
                dto._isControl = true;
                break;
            case 'NEGATIVE_CONTROL':
                dto._isNegativeControl = true;
                dto._isControl = true;
                break;
            case 'PRESCRIBED_VIAL':
            case 'TREATMENT_VIAL':
                dto._isVial = true;
                break;
            default:
                console.error("Unknown SubstanceType: " + JSON.stringify(dto));
                break;
        }

        return this._serverAPI.$q.resolve(dto);
    }
}
