'use strict';

import BaseService from './base.service.js';

export default class AllergyTestService extends BaseService {

    /**
     * Get an AllergyTest by its ReferenceDTO, bypassing the browser cache, and flagging the server
     * that user is reviewing this test for possible approval.
     *
     * @param ref ReferenceDTO to an AllergyTest
     * @return Promise that will resolve to the AllergyTest, or reject to a ServerError.
     */
    getForApproval(ref) {
        return super.getUncached(ref, { forApproval: true });
    }

    /**
     * Get the AllergyTest for a Visit.
     *
     * @param visit DTO
     * @return Promise to an AllergyTest DTO
     */
    getForVisit(visit) {
        return this.getUncached(visit.allergyTest);
    }

    /**
     * Advance the test to the next TestStage.
     * <p/>
     * The preconditions for the current stage must be met first, otherwise this call will not result in any change.
     *
     * @param allergyTest existing AllergyTest DTO
     * @returns Promise to the updated AllergyTest DTO
     */
    advance(allergyTest) {
        return this.serverAPI.put(allergyTest._links.advance, {});
    }

    /**
     * Abort (prematurely end) a test.
     *
     * @param allergyTest existing AllergyTest DTO
     * @param note text note to add to the performedBy UserAction.
     * @param force if false, abort may be delayed until all timers have cleared.
     * @return updated DTO, with stage = ABORTED
     */
    abort(allergyTest, note, force) {
        return this.serverAPI.put(allergyTest._links.abort, { note, force });
    }

    /**
     * Approve this test (as a Doctor).
     *
     * @param allergyTest existing AllergyTest DTO
     * @param note text note to add to the approval.
     * @param prescribeTreatment TreatmentType (DTO/enum) to prescribe.
     * @returns Promise to the updated AllergyTest DTO
     */
    approve(allergyTest, note, prescribeTreatment) {
        return this.serverAPI.put(allergyTest._links.approve, { note: note, treatment: prescribeTreatment });
    }
    
    /**
     * Cancel this test (as a Doctor).
     *
     * @param allergyTest existing AllergyTest DTO
     * @param note text note to add to the cancellation.
     * @returns Promise to the updated AllergyTest DTO
     */
    cancel(allergyTest, note) {
        return this.serverAPI.put(allergyTest._links.cancel, { note: note });
    }
    
    /**
     * Get leading allergyTest
     *
     * @param allergyTest existing AllergyTest DTO
     * @returns Promise to the leading AllergyTest DTO
     */
    getLeadingAllergyTest(allergyTest) {
        return this.serverAPI.get(allergyTest._links.leadingAllergyTest);
    }

    /**
     * Retrieves list of AllergyTest records where:
     * A. the AllergyTest occurred at the operand office, and
     * B. as per the test results, the patient was recognized as a candidate for immunotherapy, AND
     * C. the patient opted OUT of treatment
     *
     * @param office
     * @param testedStart (optional) limit results to tests performed on or after this date/time.
     * @param testedEnd (optional) limit results to tests performed on or before this date/time.
     * @returns {Array.<AllergyTest>}
     */
    getDeclinedCandidates(office, testedStart, testedEnd) {
        return this.serverAPI.get(office._links.untreatedAllergyTests, { testedStart, testedEnd });
    }
}
