"use strict";

import angular from "angular";

export default angular.module("pages.reports.navigation-panel", [])
    .directive("agReportsNavigation", ["ReportsSections", "UrlPaths", agReportsNavigation])
    .name;

function agReportsNavigation(ReportsSections, UrlPaths) {
    return {
        scope : {
            /**
             * Directive EigenValue : this is the only legit full-fledged scope variable. The impl will actively
             * perform behaviors in reaction to changes in this datum.
             *
             * @type{DashboardSection}
             *
             * This instance field represents *which* section is currently active. This allows user input to drive
             * the state of the caller.
             */
            activeSection : "=",

            /**
             * @type{Array.<String>}
             *
             * It's a list strings, where each value represents a logical role value. A user may have multiple roles
             * attributed to their account. Content to which this impl leads is restricted / conditionally withheld
             * from the user when the user lacks the required role values.
             *
             * We've bound this field 1-way because within an authenticated user-session, this value will not change.
             * Hence, the lifespan of the represented data (and that span will outlive the client logged-in
             * user-session) we can operationally consider this a configuration value as opposed to an input param
             * like *activeSection*.
             */
            userRoles : "@"

            //,
            // /**
            //  * @type{Object.<String, String>}
            //  * It's a map of strings to InventorySection values. It is not a variable! It IS a lookup map!
            //  */
            // ReportsSections : "@"
        },
        restrict : "E",
        css : require("./styles.scss"),
        template: require("./layout.html"),

        link : function(scope) {
            scope.ReportsSections = ReportsSections;
            scope.UrlPaths = UrlPaths;
        }
    };
}
