"use strict";

// This is a behavioral directive. Its presence on a compliant element causes the element to programmatically, by its
// own accord, hide itself whenever a DOM-click event occurs targeting an element which is not a child of this
// directive.
// Template e.g.:
//
// <div class="common-panel" ag-hide-on-exoclick />
//
// <ag-mighty-panel ag-hide-on-exoclick />
//
// This is a modifier directive, not an elemental species defining directive. Thus, it may appear as a co-directive.
// The inspiring use-case for this impl's creation is the need to conditionally show and hide content, with *some*
// 'modal' characteristics, in cases where a modal popup might be unwieldy. Hence, there's an implicit symmetry between
// showing and hiding content.


import angular from "angular";

export default angular.module("common.behaviors.hide-on-exoclick", [])
	.directive("agHideOnExoclick", ["$document", agHideOnExoClickImpl ])
	.name;

function agHideOnExoClickImpl($document) {

	// This primitive and ugly syntax is ES5 (non-transpiled) logic. ES6 usually provides better maintainability, but
	// it clashes horribly with NG's directive definition object / compiler API. There is no good solution for this.

	const MY_ACTIVE_HIDE_CLASS = "exoclick-hidden";

	let _hideUi = function(subjectElement) {
			var subjectClassList = angular.element(subjectElement)[0].classList;
			subjectClassList.add(MY_ACTIVE_HIDE_CLASS);
		},
		_revealUi = function(subjectElement) {
			var subjectClassList = angular.element(subjectElement)[0].classList;
			subjectClassList.remove(MY_ACTIVE_HIDE_CLASS);
		};

	return {

		/*
		 * This impl is a modifier, not the species of element, so this is the only choice for this case.
		 */
		restrict : "A",

		/*
		 * This impl's right to exist is: applying encapsulated logic to other directives (whose impl we want to spare
		 * the details of THIS behavior). NG actively opposes elements with multiple directives with isolated scopes.
		 * Since isolated scopes are fundamental to operation of content directives, this impl MUST NOT create an
		 * isolated scope, lest it won't be compatible or usable. (Fitness for duty impl constraint)
		 */
		scope : false,

		/*
		 * Readers wondering about this may be thinking "How can this require a stylesheet without having
		 * an organic layout?". The reason is, I'm supplying a custom rule to make this impl autonomous, and spare the
		 * normal cascades weird auxillia-impl support duties (those are awkward).
		 */
		styles : require("./styles.scss"),

		link : function(scope, element, attrs) {

			let hideSubject =()=> _hideUi(element),
				revealSubject =()=> _revealUi(element);

			$document.on("click", (domEvent) => {
				if (element !== domEvent.target && !element[0].contains(domEvent.target)) {
					hideSubject(element);
				}
			});

			scope.$watch(attrs.agHideOnExoclick, (newVal, oldVal)=> {
				if (newVal == oldVal) { return; }
				revealSubject(element);
			});

		}

	};

}

