"use strict";

import BaseController from "../../base.controller.js";

/**
 * Base controller for Test, Prescription, Mixing, and Treatment approvals.
 * Consolidates some shared functionality to avoid copy & pasting.
 */
export default class BaseApprovalController extends BaseController {

    constructor($scope, $injector) {
        super($scope, $injector);

        if ($scope.hasOwnProperty('isApproval') == false) {
            $scope.isApproval = false;
        }
        this.appointmentService = $injector.get('appointmentService');
        this.patientService = $injector.get('patientService');
        this.questionnaireService = $injector.get('questionnaireService');
        this.visitService = $injector.get('visitService');
        this.visitVitalsService = $injector.get('visitVitalsService');

        $scope.backToList = () => { this.exitUiHandler(); };
    }

    /**
     * Reacts to UI actions which constitute departure from the current Approval-page sub-section / tab.
     * Obviously, this impl does nothing, but subclasses may (will) have interesting and asynchronous logic. Hence, the
     * async modifier.
     *
     * @protected
     */
    async _onSubSectionDeparture() {}

    async exitUiHandler() {
        await this._onSubSectionDeparture();
        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback();
            }
        });
    }

    /**
     * Load vitals from server and organize into this.$scope.vitals.
     * @param visit
     */
    populateVitals(visit, vitals) {
        if (visit.preVitals) {
            this.visitVitalsService.getPreVitalsForVisit(visit).then(visitVitals => {

                this.addActionNote('Pre Vitals', visitVitals.performedBy, 20);
                this.addWarningNote('Pre Vitals', visitVitals.warning, 21, visitVitals.performedBy);

                visitVitals.results.forEach(result => {
                    let v = this._getVitalsHelperFindVital(result.vital, vitals);
                    v.preValue = result.value;
                });
            });
        }

        if (visit.postVitals) {
            this.visitVitalsService.getPostVitalsForVisit(visit).then(visitVitals => {

                this.addActionNote('Post Vitals', visitVitals.performedBy, 80);
                this.addWarningNote('Post Vitals', visitVitals.warning, 81, visitVitals.performedBy);

                visitVitals.results.forEach(result => {
                    let v = this._getVitalsHelperFindVital(result.vital, vitals);
                    v.postValue = result.value;
                });
            });
        }
    }

    _getVitalsHelperFindVital(vital, vitals) {
        let vitalsList = vitals || this.$scope.vitals;

        // Find and return existing entry
        for (let idx = 0; idx < vitalsList.length; ++idx) {
            let v = vitalsList[idx];
            if (v.id === vital.id)
                return v;
        }

        // Not found - create new entry
        let v = { id: vital.id, name: vital.label, position: vital.position };
        vitalsList.push(v);
        return v;
    }

    populateQuestionnaire(visit, notes) {
        if (visit.questionnaire) {
            this.questionnaireService.getForVisit(visit).then(questionnaire => {

                this.addActionNote('Questionnaire', questionnaire.performedBy, 10, notes);
                this.addWarningNote('Questionnaire', questionnaire.warning, 11, questionnaire.performedBy, notes);
            });
        }
    }
    /**
     * Add a note to the display based on a UserAction DTO.
     * Does nothing if no note is attached to the UserAction.
     * Otherwise, added to this.$scope.notes.
     *
     * @param title of the note for display
     * @param userAction
     * @param relOrder {int} relative order against other notes at the same time
     * @param notes the list of notes to append to
     */
    addActionNote(title, userAction, relOrder, notes) {
        if (userAction && userAction.note) {
            let notesList = notes || this.$scope.notes;
            this.userService.populateUserAction(userAction).then(ua => {
                notesList.push({
                    title: title,
                    by: ua.name,
                    when: ua.actionDateTime,
                    text: [ua.note],
                    position: ua.actionDateTime + '+' + relOrder
                });
            });
        }
    }

    /**
     * Add a note to the display based given text.
     * Does nothing if text is falsey.
     * Otherwise, added to this.$scope.notes.
     *
     * @param title of the note for display
     * @param userAction
     * @param text note text
     * @param relOrder {int} relative order against other notes
     */
    addTextNote(title, userAction, text, relOrder) {
        if (userAction && text) {

            this.userService.populateUserAction(userAction).then(ua => {
                this.$scope.notes.push({
                    title: title,
                    by: ua.name,
                    text: [text],
                    position: ua.actionDateTime + '+' + relOrder
                });
            });
        }
    }

    /**
     * Add a note to the display based on a Warning DTO.
     * Does nothing if the warning is empty.
     * Otherwise, added to this.$scope.notes.
     *
     * @param title of the note for display
     * @param warning a Warning DTO
     * @param relOrder {int} relative order against other notes
     * @param performedBy user who performed the action if no overriding user
     * @param notes the list of notes to append to
     */
    addWarningNote(title, warning, relOrder, performedBy, notes) {
        if (warning && warning.warnText) {
            let notesList = notes || this.$scope.notes;
            if (warning.override) {
                this.userService.getUserName(warning.override.user).then(userName => {
                    let text = [warning.warnText, warning.override.note];
                    let when = warning.override.actionDateTime;
                    notesList.push({
                        title: title,
                        by: userName,
                        when: when,
                        text: text,
                        position: when + '+' + relOrder
                    });
                });
            }
            else {
                // No override
                let when = warning.warnDateTime;
                if (performedBy && performedBy.user) {
                    this.userService.getUserName(performedBy.user).then(userName => {
                        notesList.push({
                            title: title,
                            by: userName,
                            when: when,
                            text: [warning.warnText],
                            position: when + '+' + relOrder
                        });
                    });
                } else {
                    notesList.push({
                        title: title,
                        by: when,
                        when: when,
                        text: [warning.warnText],
                        position: when + '+' + relOrder
                    });
                }
            }
        }
    }
}
