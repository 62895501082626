'use strict';

// Framework
import angular from 'angular';
import ngRoute from 'angular-route';

// Support from higher echelons within the project
import agStandardPageLayout from "../common/standard-layout";

// Logical Data Model
import ReportsSections from "./models/reports-sections";
import UrlPaths from "../../models/url-paths";

// Page UI-Constituents
import NavigationPanel from "./navigation-panel";

// The primary controller impl in this module.
import ReportsController from './controller.js';

// Directives
import Widgets from './widgets';

// Import section controllers
import ReportsSectionTotalTestsController from './sections/total-tests/controller';
import ReportsSectionDailyVisitsController from './sections/daily-visits/controller';
import ReportsSectionPatientTestingStatusController from './sections/patient-testing-status/controller';
import ReportsSectionPatientMixingStatusController from './sections/patient-mixing-status/controller';
import ReportsSectionPatientTreatmentStatusController from "./sections/patient-treatment-status/controller";
import ReportsSectionTreatmentComplianceController from "./sections/treatment-compliance/controller";
import ReportsSectionCancelledController from './sections/cancelled/controller';
import ReportsSectionConcentrateExpirationController from "./sections/concentrate-expiration/controller";
import ReportsSectionApprovedAwaitingTreatmentController from "./sections/approved-awaiting-treatment/controller";
import ReportsSectionPatientVialExpirationController from "./sections/patient-vial-expiration/controller";

export default angular.module('pages.reports', [
        ngRoute,
        agStandardPageLayout,
        ReportsSections,
        UrlPaths,
        NavigationPanel,
        Widgets
    ])
    .config(routingConfig)
    .controller('ReportsController', ReportsController)
    .name;

function routingConfig($routeProvider, UrlPaths) {
    $routeProvider.when(UrlPaths.REPORTS_PAGE, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: 'ReportsController'
    })
    .when(UrlPaths.REPORTS_TOTAL_TESTS, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionTotalTestsController
    })
    .when(UrlPaths.REPORTS_DAILY_VISIT, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionDailyVisitsController
    })
    .when(UrlPaths.REPORTS_PATIENT_TESTING_STATUS, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionPatientTestingStatusController
    })
    .when(UrlPaths.REPORTS_PATIENT_MIXING_STATUS, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionPatientMixingStatusController
    })
    .when(UrlPaths.REPORTS_PATIENT_TREATMENT_STATUS, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionPatientTreatmentStatusController
    })
    .when(UrlPaths.REPORTS_TREATMENT_COMPLIANCE, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionTreatmentComplianceController
    })
    .when(UrlPaths.REPORTS_CANCELLED, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionCancelledController
    })
    .when(UrlPaths.REPORTS_CONCENTRATE_EXPIRATION, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionConcentrateExpirationController
    })
    .when(UrlPaths.REPORTS_APPROVED_AWAITING_TREATMENT, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: ReportsSectionApprovedAwaitingTreatmentController
    })
    .when(UrlPaths.REPORTS_PATIENT_VIAL_EXPIRATION, {
        template: require( './layout.html' ),
        css: require( './styles.scss' ),
        controller: ReportsSectionPatientVialExpirationController
    });
}

routingConfig.$inject = ['$routeProvider', 'UrlPaths'];