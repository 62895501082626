import angular from 'angular';
import ngRoute from 'angular-route';
import ui_bootstrap from 'angular-ui-bootstrap';

import TestInitializeController from './testing-initialize.controller.js';


export default angular.module('pages.testing.initialize', [ngRoute, ui_bootstrap])
    .config(config)
    .controller('TestInitializeController',TestInitializeController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/initialize', {
        template: '<div/>',
        controller: 'TestInitializeController'
    });
}

config.$inject = ['$routeProvider'];
