'use strict';

import angular from 'angular';

/**
 * Base class for all controllers, containing common services, data, and utility functions.
 */
export default class BaseController {

    constructor($scope, $injector) {
        this.$scope = $scope;
        this.$uibModal = $injector.get('$uibModal');
        this.urlPaths = $injector.get('UrlPaths');
        this.inventoryAlertService = $injector.get('inventoryAlertService');
        this.routingService = $injector.get('routingService');
        this.sessionService = $injector.get('sessionService');
        this.userService = $injector.get('userService');
        this.practiceService = $injector.get('practiceService');
        this.officeService = $injector.get('officeService');
        this.notificationService = $injector.get('notificationService');

        this.$scope.user = this.sessionService.get(this.sessionService.KEY_USER);
        this.$scope.practice = this.sessionService.get(this.sessionService.KEY_PRACTICE);
        this.$scope.office = this.sessionService.get(this.sessionService.KEY_OFFICE);

        this.$scope.$on("OFFICE_CHANGED", () => this.officeChanged());
        this.$scope.$on('$destroy', () => this.destructor());

        if (!(angular.isObject(this.$scope.user) && angular.isObject(this.$scope.practice) && angular.isObject(this.$scope.office))) {
            this.routeToPage(this.urlPaths.LOGIN_PAGE);
            throw "Missing session objects. Aborting controller constructor & redirecting to login";
        }

        // Normally InventoryAlertService already knows the office selection and this has no effect.
        // But upon initial login, and page refresh, the InventoryAlertService needs to be initialized with the office.
        if (this.userService.isNormalUser(this.$scope.user))
            this.inventoryAlertService.select(this.$scope.office);
    }

    /**
     * Run when this controller is destroyed.
     * Child classes may override to add behavior.
     */
    destructor() {
        this.unsubscribeAllSubscriptions();
        this._releaseMyEntityLocks();

        if (this._browserCloseWarnFunction)
            window.removeEventListener('beforeunload', this._browserCloseWarnFunction);
    }

    /**
     * This is called when the user selects a different Office.
     * Child classes may override to add extended behavior.
     */
    officeChanged() {
        this.$scope.office = this.sessionService.get(this.sessionService.KEY_OFFICE);
        if (this.userService.isNormalUser(this.$scope.user))
            this.inventoryAlertService.select(this.$scope.office);
    }

    /**
     * Convenience method to change the current route to another page
     */
    routeToPage(path, subjectModel, routeParams) {
        this.routingService.goToPage(path, subjectModel, routeParams)
    }

    /**
     * Convenience method to get the current parameters for the current route
     */
    getRouteParams() {
        return this.routingService.getCurrentParams();
    }

    /**
     * Helper to register a subscription for use by startAllSubscriptions() and unsubscribeAllSubscriptions().
     * destructor() will also automatically unsubscribe registered subscriptions.
     *
     * @param subscription {Promise} the subscription returned from a NotificationService.subscribe*() function.
     * @returns {Promise} subscription back, allowing for chaining to then().
     */
    registerSubscription(subscription) {
        if (!this._registeredSubscriptions)
            this._registeredSubscriptions = [];
        this._registeredSubscriptions.push(subscription);

        return subscription;
    }

    /**
     * Call start() on all subscriptions registered with registerSubscription(), allowing notifications
     * to be received on them.
     */
    startAllSubscriptions() {
        if (this._registeredSubscriptions)
            for (let s of this._registeredSubscriptions)
                s.start();
    }

    /**
     * Forall subscriptions calls pause. This affords us a chance to perform atomic operations client-side between
     * notifications. Callers using this can reverse its affect by calling #startAllSubscriptions .
     */
    pauseAllSubscriptions() {
        if (this._registeredSubscriptions)
            for (let s of this._registeredSubscriptions)
                s.pause();
    }

    /**
     * Unsubscribe all subscriptions registered via registerSubscription().
     */
    unsubscribeAllSubscriptions() {
        if (this._registeredSubscriptions)
            for (let s of this._registeredSubscriptions)
                s.unsubscribe();

        delete this._registeredSubscriptions;
    }

    /**
     * Enable the warning prompt should the user try to close (or refresh) the browser.
     * Automatically turns back off when this controller exits.
     */
    enableBrowserCloseWarning() {
        this._browserCloseWarnFunction = (e) => {
            // Ask the browser to display this text in a prompt, using two mechanisms.
            // It'll probably ignore it and use it's own generic text, but we can try.
            // Why? https://developers.google.com/web/updates/2016/04/chrome-51-deprecations?hl=en#remove-custom-messages-in-onbeforeload-dialogs
            var text = "By closing the browser you will lose all unsaved data on the current screen. Click OK to exit, or Cancel to go back to save and exit the wizard.";
            e.returnValue = text;
            return text;
        };

        // Doing it this way allows the destructor to remove this listener.
        window.addEventListener('beforeunload', this._browserCloseWarnFunction);
    }

    /**
     * Attempt to lock an entity identified by myDto.
     * Locking is a cooperative system with other clients.
     * Locks are automatically released when this controller exits.
     *
     * @param myDto {AbstractVersionedDTO} entity to lock access to
     * @param conflictCallback callback function if there's a conflict with another client (should exit immediately)
     */
    lockEntity(myDto, conflictCallback) {

        // Subscribe to receive EntityLockEvents about our entity
        // Other clients have a few seconds to report a conflict, before the entity is ours.
        let subscription = this.notificationService.subscribeEntityLockEvent
            (this.$scope.practice, this.$scope.office, myDto);

        // Prepare data, that will remain available in the subscription callback and upon unsubscribe
        subscription.myOffice = this.$scope.office;
        subscription.myEvent = {
            id: myDto.id,
            href: myDto.href,
            version: myDto.version,
            createdDateTime: myDto.createdDateTime,
            modifiedDateTime: myDto.modifiedDateTime,
            lock: true,
            source: this.sessionService.getInstanceId()
        };

        let conflictTimeout = Date.now() + 3000;
        subscription.then(null, null, (notification) => {
            let event = notification.body;

            if (event.source !== subscription.myEvent.source && event.id === subscription.myEvent.id && event.lock == true) {

                if (Date.now() < conflictTimeout) {
                    // Another client quickly laid claim to this entity - so it wins
                    console.log("Received lock conflict: " + event);
                    this._entityLockConflictModal(conflictCallback);
                }
                else {
                    // Another client is trying to claim our entity - reassert our claim!
                    console.log("Reasserting lock on " + subscription.myEvent.id);
                    this.officeService.sendEntityLockEvent(subscription.myOffice, subscription.myEvent);
                }
            }
        });

        // Track this subscription to unsubscribe upon destruction.
        // These subscriptions are separate from _registeredSubscriptions, as we don't want to ever pause them.
        if (!this._entityLockSubscriptions)
            this._entityLockSubscriptions = [];
        this._entityLockSubscriptions.push(subscription);
        subscription.start();

        // We're all setup to process notifications, so now make our claim.
        console.log("Trying lock on " + subscription.myEvent.id);
        this.officeService.sendEntityLockEvent(subscription.myOffice, subscription.myEvent);
    }

    _releaseMyEntityLocks() {
        if (this._entityLockSubscriptions) {
            for (let subscription of this._entityLockSubscriptions) {
                subscription.unsubscribe();
                subscription.myEvent.lock = false;
                this.officeService.sendEntityLockEvent(subscription.myOffice, subscription.myEvent);
            }
        }
    }

    /**
     * Display error message when an entity access conflict is detected.
     * @param callback function called once user dismisses modal
     * @private
     */
    _entityLockConflictModal(callback) {
        this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('../widgets/common/conflict-modal.html'),
            css: require('../widgets/common/conflict-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.close = () => $uibModalInstance.dismiss();
            }
        }).result
            .catch(() => callback());
    }
}
