'use strict';

import BaseService from './base.service.js';
import PhoneContext from '../models/phone-context';

export default class PatientService extends BaseService {

    static $inject=['serverAPI', 'PhoneContext'];

    constructor(serverAPI, PhoneContext) {
        super(serverAPI);
        this.PhoneContext = PhoneContext;
    }

    /**
     * Get a list of patients present at an office.
     *
     * @param office Office to look at
     * @param station (optional) only patients present at a specific station
     * @return Promise to a Patient.List sorted by familyName
     */
    getAtOffice(office) {
        return this.serverAPI.get(office._links.patientsPresent);
    }

    /**
     * Search for patients by multiple optional criteria.
     * All query parameters are optional, but result must match <b>all</b> provided criteria.
     *
     * @param practice {Practice} to search
     * @param criteria {patientNum, chartNum, familyName, dayOfBirth} map of optional search criteria.
     * @return Promise to Patient.List of matches.
     */
    findPatientsBy(practice, criteria) {
       return this.serverAPI.get(practice._links.findPatientsBy, criteria);
    }

    /**
     * Get a specific patient at a practice by chart number and request updated details
	 * from any external source. Any such update will be delivered via the notification
	 * service as a Patient UPDATE.
     *
     * @param practice Practice to search
     * @param chartNum the chart number to find
     * @return Promise to the Patient, or 404
     */
    findPatientByChart(practice, chartNum) {
        return this.serverAPI.get(practice._links.findPatientByChart, { chartNum: chartNum });
    }

    /**
     * Search patients by by name, SSN, date of birth, chart number, or patient number
     *
     * @param practice Practice to search
     * @param query the string to search with
     * @return Promise to the Patient.List of matches.
     */
    findPatientByAnything(practice, query) {
        return this.serverAPI.get(practice._links.findPatients, {query});
    }

    /**
     * Manually add a Patient. (In EMR/PMS integrated practices, patients are imported automatically.)
     *
     * @param practice Practice to add patient to
     * @param patient {Patient} content for new Patient
     * @return Promise to updated Patient DTO
     */
    create(practice, patient) {
        return this.serverAPI.post(practice._links.addPatient, {}, patient);
    }

    /**
     * Removes a patient (logical delete)
     * Sets the removed flag
     *
     * @param patient {Patient}
     * @return Promise to updated Patient DTO
     */
    remove(patient) {
        return this.serverAPI.post(patient._links.remove, {}, patient);
    }

	/**
     * Refresh patient from external resource
     *
     * @param patient the patient to refresh
     * @return Promise to an empty result (actual result sent via notification service)
     */
    refresh(patient) {
        return this.serverAPI.post(patient._links.refresh, {});
    }

    /**
     * Check-in a patient per an appointment.
     *
     * @param appointment Appointment to check-in.
     * @return Promise to a Patient DTO
     */
    checkinByAppointment(appointment) {
        return this.serverAPI.put(appointment._links.checkin);
    }

    /**
     * Check-in the patient for treatment without an appointment.
     *
     * @param patient Patient DTO to check in.
     * @param office Office DTO checking in at
     * @returns Promise to a Patient DTO
     */
    checkinWithoutAppointment(patient, office) {
        return this.serverAPI.put(patient._links.checkin, {officeId: office.id});
    }

    /**
     * Get TreatmentVials for this patient.
     *
     * @param status (optional) only returns vials with this ServiceStatus.
     * @return Promise to a TreatmentVial.List of matches
     */
    treatmentVials(patient, status) {
        return this.serverAPI.get(patient._links.treatmentVials, {status: status});
    }

    /**
     * Get a list of the patient's activity history
     *
     * @param patient Patient DTO to get activity history for.
     * @param procedure (optional) history for this procedure only
     * @returns Promise to a List of PatientHistory DTO
     */
    getHistory(patient, procedure) {
        let params = {};
        params.procedure = procedure;
        return this.serverAPI.get(patient._links.history, params);
    }

    /**
     * Get a list of patients that have recently visited this office.
     * @param office Office DTO to get recent activity from
     * @param days (optional) number of days that defines "recent"
     * @response RecentPatient.List sorted by last visit time (descending)
     */
    getRecentList(office, days) {
        const query = {
            days: days
        };

        return this.serverAPI.get(office._links.patientsRecent, query);
    }

    /**
     * Attempt to check-in a self-identified patient.
     * <p>
     * Must provide either valid PIN, <i>or</i> valid dayOfBirth, phoneNumber, & names.
     *
     * @param user current user; must have SELFSERVICE role.
     * @param pin (optional) patient's PIN
     * @param dayOfBirth (optional) ISO-8601 date of patient's birth date
     * @param phoneNumber (optional) a phone number of the patient. (Any format - will be normalized to digits.)
     * @param firstName (optional) the patient's given name
     * @param lastName (optional) the patient's family name.
     * @return Promise to SelfCheckinResponse DTO.
     * @see UserService.switchToSelfService(user)
     */
    selfCheckinPatient(user, pin, dayOfBirth, phoneNumber, firstName, lastName) {
        let query = {
            pin: pin,
            dayOfBirth: dayOfBirth,
            phoneNumber: phoneNumber,
            givenName: firstName,
            familyName: lastName
        };
        return this.serverAPI.put(user._links.selfCheckin, query);
    }

    /**
     *
     *
     * @param patient
     *   The Patient model whose phonenumber we wish to acquire.
     *
     * @returns {String}
     *   Serialized representation of the telephone number associated with the operand *patient*.
     *   This impl assumes a Patient model structure in which one or more telephone numbers are mapped by contextual
     *   labels. These contexts are listed in the PhoneContext enum.
     *   Note: this impl preferentially prefers numbers mapped to the values in PhoneContext, however if there exist
     *   no values at these positions, then this routine will return the first value available within
     *   patient.phoneNumbers.
     */
    getPhonePrimaryNumber(patient) {

        for (let aCtx in this.PhoneContext) {
            let aContext = this.PhoneContext[aCtx];
            if (angular.isDefined(patient.phoneNumbers[aContext]) ) {
                return patient.phoneNumbers[aContext];
            }
        }

        // It is conceivable the user may specified a context or label which is not in our list.
        // Alternatively, if the list of expected phone-number context changes before we get a chance to update
        // this service class, then this loop will catch ANY valid object field key.
        for (let unlistedLabel in patient.phoneNumbers) {
            if (angular.isDefined(patient.phoneNumbers[unlistedLabel])) {
                return patient.phoneNumbers[unlistedLabel];
            }
        }


        return null;
    }

}
