'use strict';

import BaseService from './base.service.js';

export default class AppointmentService extends BaseService {

    /**
     * Get matching scheduled appointments at an Office
     *
     * @param office DTO
     * @param start only appointments scheduled after or at this time
     * @param end only appointments scheduled before or at this time
     * @param procedure (optional) if present, only this procedure
     * @return Promise to an Appointment.List DTO sorted by scheduledStart
     */
    getScheduledAtOffice(office, start, end, procedure) {
         return this.serverAPI.get(office._links.appointments,{
             scheduledStart: start,
             scheduledEnd: end,
             procedure: procedure
         });
    }

    /**
     * Get matching floating appointments at an Office.
     *
     * @param office DTO
     * @param start only appointments created after or at this time
     * @param end only appointments created before or at this time
     * @param procedures (optional array) only these procedures. Ex: ['TESTING','TREATMENT']
     * @return Promise to an Appointment.List DTO sorted by createdStart
     */
    getFloatingAtOffice(office, start, end, procedures) {
        return this.serverAPI.get(office._links.appointments,{
            createdStart: start,
            createdEnd: end,
            procedure: procedures,
            checkedIn: "false"
        });
    }

    /**
     * Get matching appointments for a Patient
     *
     * @param patient Patient DTO
     * @param start only appointments after or at this time
     * @param end only appointments before or at this time
     * @return Promise to an Appointment.List DTO sorted by scheduledStart
     */
    getForPatient(patient, start, end) {
        return this.serverAPI.get(patient._links.appointments,{
            start: start,
            end: end
        });
    }

    /**
     * GET the next Appointment for this Patient if it exists, or doCreate is true.
     *
     * @param patient Patient DTO
     * @param doCreate if set to true and no next appointment is found, returns a "floating" (no scheduled time) appointment for the Patient. Default value is 'true'.
     *
     * It'll reuse an existing floating appointment that hasn't been used, or create a new one if it doesn't yet exist and doCreate is true.
     *
     * @return Promise to an Appointment DTO if the appointment exists or doCreate was true.
     */
    getNextForPatient(patient, doCreate) {
        var params = {create: true};
        if (doCreate !== undefined && !doCreate) {
            params.create = false;
        }

        return this.serverAPI.get(patient._links.nextAppointment, params, undefined, [404]);
    }
    
    /**
     * Get any follow up appointments for the given appointment.
     *
     * @param appointment initial appointment
     * @returns Promise to Appointment.List
     */
    getFollowUps(appointment, procedures) {
        var params = { procedure: (procedures || []) };
        return this.serverAPI.get(appointment._links.followUps, params);
    }

    /**
     * Create a new Appointment at an Office.
     *
     * @param office office to add the appointment to
     * @param appointment initial content of the Appointment DTO
     * @returns Promise to the updated Appointment DTO
     */
    createInOffice(office, appointment) {
        return this.serverAPI.post(office._links.appointments, {}, appointment);
    }

    /**
     * Create a new Appointment for a Patient.
     *
     * @param patient patient to make the appointment for
     * @param appointment initial content of the Appointment DTO
     * @returns Promise to the updated Appointment DTO
     */
    createForPatient(patient, appointment) {
        return this.serverAPI.post(patient._links.appointments, {}, appointment);
    }
    
    /**
     * Cancel this appointment.
     *
     * @param appointment existing Appointment DTO
     * @param note text note to add to the cancellation.
     * @returns Promise to the updated Appointment DTO
     */
    cancel(appointment, note) {
        return this.serverAPI.put(appointment._links.cancel, { note: note });
    }
}
