'use strict';

import BaseService from './base.service.js';

/**
 * Loads and caches all substances used at a practice at login. This list is useful in itself,
 * but is also used first on any request before hitting the server or browser's cache.
 */
export default class SubstanceService extends BaseService {

    /** A foreign substance type that induces an immune response in the body */
    ANTIGEN = 'ANTIGEN';

    /** A substance type that is expected to produce a reaction. Ex: SP Histamine */
    POSITIVE_CONTROL = 'POSITIVE_CONTROL';

    /** A neutral substance type, used to dilute vials. Ex: Glycerine */
    NEGATIVE_CONTROL = 'NEGATIVE_CONTROL';

    /** Special place-holder substance referencing another vial in a prescription. Used for in SLIT escalation vials. */
    PRESCRIBED_VIAL = 'PRESCRIBED_VIAL';

    /** Special place-holder substance referencing an existing treatment vial. Used in some prescription dilutions. */
    TREATMENT_VIAL = 'TREATMENT_VIAL';

    /** {SubstanceCategoryService} */
    _substanceCategorySvc;


    /** {string} Practice ID of the cached content. */
    _cachePractice = '';

    /** {Substance[]} The full loaded list of Substances at _cachePractice */
    _cacheList = [];

    /** {Map<id,DTO>} Copy of _cacheList mapped by their substance IDs. */
    _cacheMap = new Map();

    /** {Map<id,Promise>} Map of substance id's to promises to retreive those substances */
    _promises = new Map();

    static $inject = ['serverAPI', 'substanceCategoryService'];
    constructor(serverAPI, substanceCategoryService) {
        super(serverAPI);
        this._substanceCategorySvc = substanceCategoryService;
    }

    /**
     * Get a DTO by its ReferenceDTO.
     *
     * @param ref ReferenceDTO to a DTO type
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     */
    get(ref) {
        let dto = this._cacheMap.get(ref.id);
        if (dto) {
            return super.resolved(dto);
        }
        else {
            let promise = this._promises.get(ref.id);
            if (!promise) {
                promise = super.get(ref);
                this._promises.set(ref.id, promise);
            }

            return promise.then((substance) => {
                return this._transform(substance);
            }).then((substance) => {
                this._cacheList.push(substance);
                this._cacheMap.set(ref.id, substance);
                this._promises.delete(ref.id);
                return substance;
            });
        }
    }

    /**
     * Retrieve an array of all substances available at the practice.
     * (Calls initForPractice() when needed.)
     * @param practice
     * @return {Promise<Array<Substance>>}
     */
    getForPractice(practice) {
        if (practice.id !== this._cachePractice) {
            return this.initForPractice(practice)
                .then(() => angular.copy(this._cacheList));
        }
        else {
            return super.resolved(angular.copy(this._cacheList));
        }
    }

    /**
     * Initialize cache of substances for a practice.
     * @param practice
     * @return {Promise} resolves upon completion.
     */
    initForPractice(practice) {
        // Reset values. Other async requests may occur while this is running. That's okay, they simply
        // won't be found and pass-through the request to the server.
        this._cacheList = [];
        this._cacheMap = new Map();
        this._cachePractice = practice.id;

        return this.serverAPI.get(practice._links.substances)
            .then(substanceList => {
                let transformations = substanceList.list.map(s => this._transform(s));
                return this.serverAPI.$q.all(transformations);
            })
            .then(substances => {
                this._cacheList = substances;
                substances.forEach(s => this._cacheMap.set(s.id, s));
            });
    }

    /**
     * GET all defined Substances of a given SubstanceType.
     *
     * @param practice
     * @param type only Substances of this SubstanceType
     * @return {Promise<Array<Substance>>}
     */
    getByType(practice, type) {
        // Copy from practice's cache
        return this.getForPractice(practice).then(() => {
            let matches = this._cacheList.filter(s => s._category.type === type);
            return super.resolved(matches);
        });
    }

    /**
     * Add a _category field with the full SubstanceCategory DTO referenced by the category field.
     *
     * @param dto
     * @return {Promise} updated DTO
     */
    _transform(dto) {
        return this._substanceCategorySvc.get(dto.category)
            .then(cat => {
                dto._category = cat;
                return dto;
            });
    }
}
