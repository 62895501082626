'use strict';

import angular from 'angular';

export default angular.module('pages.treatment.widgets', [])
    .directive('agTreatmentNavBar', ["$location", agTreatmentNavBar])
    .directive('agTreatmentFooter', ["$location", agTreatmentFooter])
    .name;

function agTreatmentNavBar($location) {
    return {
        windowClass: require('./treatment-navbar.scss'),
        template: require('./treatment-navbar.html'),
        scope: false,
        link: function (scope, element, attrs) {
            let url = $location.url();
            let classNames = [];
            
            let cur = function (className) {
                return (url.indexOf(className) > -1);
            };

            let next = function (className) {
                for (let i = 0; i < classNames.length - 1; i++) {
                    if (url.indexOf(classNames[i]) > -1 && className === classNames[i + 1]) {
                        return true;
                    }
                }
                return false;
            };

            let prev = function (className) {
                for (let i = 0; i < classNames.length; i++) {
                    if (url.indexOf(classNames[i]) > -1) {
                        for (let j = 0; j < i; j++) {
                            if (className === classNames[j]) {
                                return true;
                            }
                        }
                        return false;
                    }
                }
                return false;
            };
            
            let getClassNames = function() {
                let result = [];

                if (scope.vialSelectionTab) {
                    result.push('vial-selection');
                }
                
                result.push('questionnaire');
                
                if (scope.preVitalsTab) {
                    result.push('vitals');
                }

                result.push('checklist');
                
                if (scope.vialTestTab) {
                    result.push('vial-test');
                    result.push('idt-result');
                }
                
                if (scope.injectionTab) {
                    result.push('injection');
                    result.push('examination');
                }
                
                return result;
            };
            
            classNames = getClassNames();
            
            scope.getClasses = function(className) {
                let result = [];
                
                if (prev(className)) {
                    result.push('prev');
                }
                if (cur(className)) {
                    result.push('current');
                }
                if (next(className)) {
                    result.push('next');
                }
                if (classNames[0] === className) {
                    result.push('first');
                }
                
                return result.join(' ');
            };

            scope.$watchGroup(['vialSelectionTab', 'vialTestTab', 'injectionTab','preVitalsTab'], () => {
                classNames = getClassNames();
            });

        }
    };
}

function agTreatmentFooter($location) {
    return {
        //restrict: 'E',
        windowClass: require('./treatment-footer.scss'),
        template: require('./treatment-footer.html'),
        scope: false,
        link: function (scope, element, attrs) {
        }
    };
}
