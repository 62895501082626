"use strict";

import angular from "angular";
import $q from "angular";
import ServiceStatus from "../../../models/serviceStatus";
import boardService from "../../../services/board.service";

/**
 * This UI-layer service consolidates the heavy-lifting of selecting appropriate ServiceStatus values
 * for assignment to an SP Wells Board instance. We're abstracting this away because: multiple components need this,
 * and the components do share a good common ancestor in which to impl this.
 *
 * Mandates for this impl::
 * AAW-827, AAW-414, AAW-1013
 *
 * Supporting missions powering this impl::
 * AAW-955
 *
 * Implementation Evolution : currently, we have one known use-case with applied in 2 places, involving Board objects
 * serving in the SP Wells Board capacity. If other families of models develop similar status representations, then
 * we should explore either genericization or subclassing options get more mileage out of this impl pattern.
 *
 * The most recent applicable guidance is AAW-1013, which effectively obsoletes and overwrites all previous specs. AAW-
 * 1013 simply states: "Inventory" should be removed, leaving "In Service" and "Discarded" as the only options.
 *
 * Future Evolution: I plan to push this impl (currently only serving SP-Wells) upstream to support all Board types in
 * Inventory, giving us a single consolidated encapsulation of status eligibility. So long as all Inventory Boards abide
 * the same status rules, this single impl will work. If Audigy changes the rules for specific sections, no worries,
 * we can simply define section-specific variants, and point the logic within at the correct impl.
 */
export default class EligibleStatusService {

    /*
     * Injected Dependencies::
     * ServiceStatus := the enumeration. Not just a value within, the whole thing.
     * boardService := service impl which will communicate with the remote API on our behalf.
     * $q := Angular's Deferred-Predicate Service.
     */
    static $inject = ["ServiceStatus","boardService","$q"];

    /**
     * @param {ServiceStatus} the whole enumeration definition (not a member value).
     * @param {boardService}
     */
    constructor(ServiceStatus, boardService, $q) {
        this._ServiceStatusTypes = ServiceStatus;
        this._boardService = boardService;
        this._$q = $q;
    }

    /**
     * @public
     *
     * @param {SerializedModel<com.audigygroup.allergy.core.dto.Board> } board
     *
     * @returns {Promise.<{Array.<{ServiceStatus}>}>}
     */
    async getEligibleStatusTypesForBoard(board) {
        return this._$q.resolve( [this._ServiceStatusTypes.IN_SERVICE, this._ServiceStatusTypes.DISCARDED] );
    }

    /**
     * Provides a list of ServiceStatus values applicable to new boards. This is useful when we are in the process of
     * creating a Board. The instance is not yet available, so we cannot pass it in as a param, although its absence
     * has no effect on the outcome.
     *
     * @returns {Promise.<{Array.<{ServiceStatus}>}>}
     */
    getEligibleStatusTypesForNewBoard() {
        return this._$q.resolve( [ this._ServiceStatusTypes.IN_SERVICE ] );
    }

}
