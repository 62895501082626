"user strict";

import moment from 'moment-timezone';

/**
 * This class encapsulates data type conversions dealing with chronology. Complicated operations
 * which transform one representation to another have a home here.
 *
 * In the functinos of this class:
 * - "Date" is an ISO-8601 string of format "YYYY-MM-DD".
 * - "DateTime" as an ISO-8601 string of format "YYYY-MM-DDTHH:mm:ss".
 * - "jsDate" is the Javascript Date class.
 *
 * Timezone is specified by "UTC" or "Local", to refer to the browser's timezone, or as a string that
 * specifies a specific timezone (ex: "America/New_York")
 *
 */
export default class ChronologyMappingService {

    ISO_DATE_FORMAT = "YYYY-MM-DD";
    ISO_DATETIME_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";

    /**
     * The current date in a timezone.
     *
     * @param timezone the target timezone
     * @returns {string} in format "YYYY-MM-DD"
     */
    currentDate(timezone) {
        return moment().tz(timezone).format(this.ISO_DATE_FORMAT);
    }

    /**
     * The current date/time in a timezone.
     *
     * @param timezone the target timezone
     * @returns {string} in format "YYYY-MM-DDTHH:mm:ss"
     */
    currentDateTime(timezone) {
        return moment().tz(timezone).format(this.ISO_DATETIME_FORMAT);
    }

    /**
     * How many days between two dates in the same timezone?
     *
     * @param date1 {string} ISO-8600 Date or DateTime
     * @param date2 {string} ISO-8600 Date or DateTime
     * @returns {number}
     */
    daysBetween(date1, date2) {
        return moment(date1).diff(moment(date2), 'days');
    }

    /**
     * Return a modified date, after adding the designated number of days.
     * If given a DateTime, the time portion is maintained.
     *
     * @param isoDate ISO-8600 Date or DateTime
     * @param dayCount {int} number of days to add. Negative to subtract.
     * @return {string} ISO-8600 Date or DateTime, whichever was given as isoDate parameter.
     */
    addDays(isoDate, dayCount) {
        let m = moment(isoDate).add(dayCount, 'days');
        if (isoDate.length == 10)
            return m.format(this.ISO_DATE_FORMAT);
        else
            return m.format(this.ISO_DATETIME_FORMAT);
    }

    /**
     * Convert an ISO Date or DateTime from UTC into the same instant in another timezone.
     *
     * @param utcDateOrDateTime a date (YYYY-MM-DD) or DateTime (YYYY-MM-DDTHH:mm:ss) in UTC timezone
     * @param timezone the target timezone
     * @returns {string} of the same instance in the target timezone, and of the same format as the input.
     */
    utcToTimezone(utcDateOrDateTime, timezone) {
        if (utcDateOrDateTime.length == 10) {
            return moment(utcDateOrDateTime).tz(timezone).format(this.ISO_DATE_FORMAT);
        }
        else if (utcDateOrDateTime.length > 18) {
            return moment(utcDateOrDateTime).tz(timezone).format(this.ISO_DATETIME_FORMAT);
        }
        else {
            // Unknown format. Just return the unmodified input.
            console.log("ChronologyMappingService.utcToTimezone(" + utcDateOrDateTime + ") unknown format");
            return utcDateOrDateTime;
        }
    }

    /**
     * Same result as Date.toISOString().substring(0,10), but a bit cleaner and more efficient.
     * This is the format the server expects for DTO field of type LocalDate.
     *
     * @param date {Date} Javascript Date object, interpreted from UTC
     * @return {string} ISO-8601 Date of format "YYYY-MM-DD"
     */
    jsUTCDateToISODate(date) {
        return date.getUTCFullYear() +
            '-' + this._pad(date.getUTCMonth() + 1) +
            '-' + this._pad(date.getUTCDate());
    }

    /**
     * Similar to Date.toISOString(), but no milliseconds or 'Z' timezone on the end.
     * This is the format the server expects for DTO field of type LocalDateTime.
     *
     * @param date {Date} Javascript Date object, interpreted from UTC
     * @return {string} ISO-8601 DateTime of format "YYYY-MM-DDTHH:mm:ss"
     */
    jsUTCDateToISODateTime(date) {
        return date.getUTCFullYear() +
        '-' + this._pad(date.getUTCMonth() + 1) +
        '-' + this._pad(date.getUTCDate()) +
        'T' + this._pad(date.getUTCHours()) +
        ':' + this._pad(date.getUTCMinutes()) +
        ':' + this._pad(date.getUTCSeconds());
    }

    /**
     * Set the time portion of an ISO-860 Date or DateTime to 00:00:00.
     *
     * @param isoDateTime {string} ISO-8601 Date or DateTime.
     * @returns {string} ISO-8601 DateTime of format "YYYY-MM-DDTHH:mm:ss" with time as "00:00:00".
     */
    startOfDay(isoDateTime) {
        if (isoDateTime.length == 10)
            return isoDateTime + "T00:00:00";
        else if (isoDateTime.length > 11)
            return isoDateTime.substring(0,11)+"00:00:00";
        else
            return isoDateTime;
    }

    /**
     * Set the time portion of an ISO-860 Date or DateTime to 23:59:59.
     *
     * @param isoDateTime {string} ISO-8601 Date or DateTime.
     * @returns {string} ISO-8601 DateTime of format "YYYY-MM-DDTHH:mm:ss" with time as "23:59:59".
     */
    endOfDay(isoDateTime) {
        if (isoDateTime.length == 10)
            return isoDateTime + "T23:59:59";
        else if (isoDateTime.length > 11)
            return isoDateTime.substring(0,11)+"23:59:59";
        else
            return isoDateTime;
    }

    /**
     * Set the day-of-month portion of an ISO-860 Date to the last day of the month.
     *
     * @param isoDateTime {string} ISO-8601 Date.
     * @returns {string} ISO-8601 Date of format "YYYY-MM-DD".
     */
    endOfMonth(isoDate) {
        return moment(isoDate).endOf('month').format(this.ISO_DATE_FORMAT);
    }

    /**
     * Convert a Javascript Date object to ISO-8601 Date, according to the browser's timezone.
     * This is the format the server expects for DTO field of type LocalDate.
     *
     * @param date {Date} Javascript Date object, interpreted from browser's local timezone
     * @return {string} ISO-8601 DateTime of format "YYYY-MM-DD"
     */
    jsLocalDateToISODate(date) {
        return date.getFullYear() +
            '-' + this._pad(date.getMonth() + 1) +
            '-' + this._pad(date.getDate());
    }

    /**
     * Convert a Javascript Date object to ISO-8601 DateTime, according to the browser's timezone.
     * This is the format the server expects for DTO field of type LocalDateTime.
     *
     * @param date {Date} Javascript Date object, interpreted from browser's local timezone
     * @return {string} ISO-8601 DateTime of format "YYYY-MM-DDTHH:mm:ss"
     */
    jsLocalDateToISODateTime(date) {
        return date.getFullYear() +
            '-' + this._pad(date.getMonth() + 1) +
            '-' + this._pad(date.getDate()) +
            'T' + this._pad(date.getHours()) +
            ':' + this._pad(date.getMinutes()) +
            ':' + this._pad(date.getSeconds());
    }

    /**
     * Convert an ISO-8601 Date string, interpreted as UTC timezone, to a Javascript Date.
     * @param date {string} ISO-8601 Date string, interpreted as UTC timezone
     * @returns {Date} Javascript Date instance
     */
    isoDateToJsUTCDate(date) {
        return new Date(moment(date));
    }

    /**
     * Pad a number to two digits.
     * @param number
     * @returns {string} zero-padded number
     * @private
     */
    _pad(number) {
        return (number < 10) ? '0' + number : number;
    }
}
