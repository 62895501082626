'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import InternalMixingPrescriptionDetailsController from './prescription-details.controller.js';


export default angular.module('pages.internal.mixing.prescription.details', [ngRoute])
    .config(config)
    .controller('InternalMixingPrescriptionDetailsController', InternalMixingPrescriptionDetailsController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_INTERNAL_MIXING_DETAILS, {
        template: require('./layout.html'),
        css: require('../../common/base-prescription-details/styles.scss'),
        controller: 'InternalMixingPrescriptionDetailsController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];