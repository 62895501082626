"use strict";

import angular from "angular";

export default angular.module("widgets.drop-down-select", [])
    .directive("agDropDownSelect", [ agDropDownSelect ])
    .directive("agDropDownDilution", [ agDropDownDilution ])
    .name;


function agDropDownSelect() {

    return {
        restrict: "E",
        template : require("./layout.html"),
        css : require("./styles.scss"),
        scope : {

            /**
             * An object representing the currently selected choice. As a generic type, we're not making
             * too many assumptions about the structure, other than mandating a required field for the UI:
             *
             *   name : a string containing the user readable representation of the value.
             */
            activeValue : "=",

            /**
             * @type { Array.<{{ name : String }}> }
             *
             * The logical list of options this instance will offer. In this generic widget, we concern ourselves
             * not with the actual type of the values, or even whether or not they're homogeneous. We care only
             * that the objects possess a "name" field by which users can recognize the values.
             */
            choiceList : "=",

            /**
             * Let selectionMap be an object of the form:
             * {
             *      "theChoice" : <Data-object>
             * }
             *
             * @type { Function( selectionMap )}
             *
             * Callback routine furnished by caller. As a scope-bound function, Angular will attempt to evaluate
             * the parameters as map entries, where the parameter map is an object with the field "theChoice", whose
             * value is presumed to be a VM object associated with the UI-DOM action.
             */
            onSelect : "&",

        },
        link : function (scope, element, attr, ctrl) {

            let cancelActiveValueWatch = scope.$watch("activeValue", (thisVal, thatVal) => {
                if (thisVal === thatVal) return;
                cancelActiveValueWatch();
            });

            let cancelChoiceListWatch = scope.$watch("choiceList", (thisVal, thatVal) => {
                if (thisVal === thatVal) return;
                cancelChoiceListWatch();
            });

            scope.onSelection = (aChoice) => {
                scope.activeValue = aChoice;
                scope.onSelect({theChoice : aChoice});
            }

        }
    };
}

function agDropDownDilution(){
    return {
        template: require('./dilution-dropdown.html'),
        scope:{
            /** {integer} Minimum dilution option. Note that 0 shows as 'C' */
            min: '=',
            /** {integer} Maximum dilution option. */
            max: '=',
            /** {integer} Current value - variable is set upon selection */
            value: '='
        },
        link: function( scope, elem, attr) {

            scope.reset = () => {
                scope.choiceList = [];
                for (let d = scope.min; d <= scope.max; ++d)
                    scope.choiceList.push(d);
            };

            scope.onSelection = function(value){
                scope.value = value;
            };

            let cancelMinMaxWatch = scope.$watchGroup(['min','max'], (newValues) => {
                if (scope.min !== undefined && scope.max != undefined) {
                    scope.reset();
                    cancelMinMaxWatch();
                }
            });

            scope.reset();
        }
    };
}
