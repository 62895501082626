"use strict";

export default class AccountManagementController {

    static $inject = ["$scope", "sessionService"];
    constructor($scope, sessionService) {

        let user = sessionService.get(sessionService.KEY_USER);
        if (user) {
            let thePerson = user.person;
            $scope.currentUserName = [thePerson.givenName, thePerson.middleName, thePerson.familyName].join(" ");
            if (user.roles) {
                $scope.isSuperAdmin = user.roles.includes('SUPERADMIN');
                $scope.kioskAllowed = user.roles.includes('NURSE');
            }
        }
    }
}
