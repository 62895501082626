'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';

export default class DailyVisitsReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.DAILY_VISIT);
    }

    async $onInit() {
        // default values for UI controls
        this.filterByOffice = undefined; // value used with svc call
        this.$scope.selectedOffice = undefined; // the future value of filterByOffice; gotten from UI

        // data values which will be async populated
        this.$scope.rowCollection = [];
        await this._getOfficeData();
        await this._getReportData();
    }

    async _getReportData() {
        await super._getReportData();
        if (this.filterByOffice) {
            this.reportCriteria['officeId'] = this.filterByOffice;
        }
        else {
            delete this.reportCriteria['officeId'];
        }

        let rptData = await this.reportsService.getDailyVisits(this.reportCriteria);
        this.$scope.rowCollection = rptData;
        this.$scope.$digest();
    }
}
