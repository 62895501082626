'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import ExternalMixingPrescriptionDetailsController from './prescription-details.controller.js';


export default angular.module('pages.external.mixing.prescription.details', [ngRoute])
    .config(config)
    .controller('ExternalMixingPrescriptionDetailsController', ExternalMixingPrescriptionDetailsController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_EXTERNAL_MIXING_DETAILS, {
        template: require('./layout.html'),
        css: require('../../common/base-prescription-details/styles.scss'),
        controller: 'ExternalMixingPrescriptionDetailsController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];