'use strict';

import angular from 'angular';

export default angular.module('widgets.vitals', [])
    .directive('agPreVitals', [agPreVitals])
    .directive('agPostVitals', [agPostVitals])
    .name;
    
function vitals(template) {
    return {
        windowClass: require('./vitals.scss'),
        template: template,
        scope: false
    };
}

function agPreVitals() {
    return vitals(require('./pre-vitals.html'));
}

function agPostVitals() {
    return vitals(require('./post-vitals.html'));
}
