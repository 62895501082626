"use strict";

import angular from 'angular';
import BaseController from "../../../../pages/base.controller.js";
import AllergyTestConfigService from "../../../../services/allergy-test-config.service";

export default class AddPrescriptionModalController extends BaseController {

    constructor($scope, $injector, $uibModalInstance, patient, onCreationHandler) {

        super($scope, $injector);

        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.panelService = $injector.get("panelService");
        this.prescriptionService = $injector.get("prescriptionService");
        this.Procedure = $injector.get('Procedure');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.substanceService = $injector.get("substanceService");
        this.substanceCategoryService = $injector.get("substanceCategoryService");
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.allergyTestConfigService = $injector.get('allergyTestConfigService');
        this.$uibModalInstance = $uibModalInstance;
        this.TreatmentType = $injector.get('TreatmentType');

        // Establish own stateful behaviors

        /* UI-action which kills the modal instance and halts the RX creation */
        this.$scope.cancel = () => $uibModalInstance.close('cancel');

        /* UI-action which affirms the correctness of the form state, and a want to advance to next stage */
        this.$scope.continue = () => this._continueToNextPhase();
        /* Check whether a specific treatment config dropdown is disabled */
        this.$scope.isTreatmentConfigDisabled = (index) => this._isTreatmentConfigDisabled(index);
        /* Check whether the next button is enabled */
        this.$scope.isNextEnabled = () => this._isNextEnabled();
        /* This var tracks the running total number of antigens currently selected for the RX. */
        this.$scope.totalAntigensSelected = 0;
        /* UI-action which adds to the RX or removes from the RX the antigen represented by the params */
        this.$scope.onAntigenIsPrescribedToggle = (uiListIndex, antigenObject) => {
            antigenObject.vm.isSelected = !(antigenObject.vm.isSelected);

            if (antigenObject.vm.isSelected)
                this.$scope.totalAntigensSelected++;
            else
                this.$scope.totalAntigensSelected--;
        };

        /* UI-action which opts for the Panel represented by the param */
        this.$scope.onSelectActivePanel = (newActivePanel) => {
            if (angular.isDefined(newActivePanel)) {
                this.$scope.activePanel = newActivePanel;
                this.$scope.activeBoard = null;
                this._loadAvailableAntigens();
            }
        };

        /* UI-action which opts for the Panel represented by the param */
        this.$scope.onSelectActiveBoard = (newActiveBoard) => {
            if (angular.isDefined(newActiveBoard)) {
                this.$scope.activeBoard = newActiveBoard;
                this.$scope.activePanel = this.$scope.availablePanels.find(m => m.id === newActiveBoard.panel.id);
                this._loadAvailableAntigens();
            }
        };

        /* UI-action which opts for the TreatmentType represented by the param */
        this.$scope.onSelectTreatmentType = (newTreatmentType) => {
            if (angular.isDefined(newTreatmentType)) {
                this.$scope.activeTreatmentType = newTreatmentType;
                this.$scope.treatmentConfigs =
                    this.allTreatmentConfigs.filter(c => c.treatmentType === newTreatmentType.name);

                if (newTreatmentType.name === 'SLIT') {
                    this.$scope.addClassicalTreatmentConfig(false);
                }

                for (let idx = 0; idx < this.$scope.selectedTreatmentConfig.length; ++idx)
                    this.$scope.selectedTreatmentConfig[idx] = null;
            }
        };

        this.$scope.addClassicalTreatmentConfig = (makeItClassical) => {
            this.$scope.isClassical = makeItClassical;

            this.$scope.showBoardSelect = !this.$scope.isClassical || this.$scope.hasTraditionalArrangement;
            this.$scope.showPanelSelect = !this.$scope.showBoardSelect;

            if (makeItClassical && !(this.$scope.activeTreatmentType && this.$scope.activeTreatmentType.name === 'SLIT')) {
                while (this.$scope.selectedTreatmentConfig.length < 5)
                    this.$scope.selectedTreatmentConfig.push(null);
            }
            else if (this.$scope.selectedTreatmentConfig.length > 1) {
                this.$scope.selectedTreatmentConfig = [this.$scope.selectedTreatmentConfig[0]];
            }
        };

        /* UI-action which opts for the TreatmentConfig represented by the param */
        this.$scope.onTreatmentConfigChange = () => {};

        /* UI-action which designates the MD/physcian who is writing the current RX */
        this.$scope.onSelectRxDoctor =(chosenDr)=> {
            this.$scope.prescribingDoctor = chosenDr;
        };

        this.$scope.onCreationHandler =(rxHref)=> { onCreationHandler(rxHref);};

        /* Prepares 3rd party chronology assisting widgets for action */
        this._initChronologicUi();

        // Establish own state
        /** Stores the open vs closed stated of the DatePicker serving patient.DayOfBirth */
        this.$scope.isDobOpen = false;
        /** Stores the collection of all Panel instances available to the associated Practice */
        this.$scope.availablePanels = [];
        /** Stores a reference to the Panel currently selected for the this instance's RX */
        this.$scope.activePanel = null;
        /** Stores the collection of all possible Treatment Types */
        this.$scope.availableTreatmentTypes = [{name:this.TreatmentType.SCIT}, {name:this.TreatmentType.SLIT}];
        /** Stores the selected TreatmentType */
        this.$scope.activeTreatmentType = null;
        /** Stores the current selection of TreatmentConfigs. Changes when a TreatmentType is seected. */
        this.$scope.treatmentConfigs = [];
        /** Stores the selected TreatmentConfig[] */
        this.$scope.selectedTreatmentConfig = [null];
        /** Stores the collection of all of Antigen instances available to scope.activePanel */
        this.$scope.availableAntigens = [];
        /** Stores the collection of all User instances available to the associated Practice */
        this.$scope.availableDoctors = [];
        /** Stores a reference to the User which wrote this instance's RX */
        this.$scope.prescribingDoctor = null;
        /** Stores the expiration-date, when specified */
        this.$scope.useByScalar = null;
        /** Stores the starting mix # */
        this.$scope.mixNum = 1;
        /** Hide escalation schedule title */
        this.$scope.hideTitle = true;
        /** Hide escalation schedule warning */
        this.$scope.hideWarning = true;
        /** Force escalation schedule to be enabled */
        this.$scope.showEscalationSchedule = true;
        /** Display panel selection? */
        this.$scope.showPanelSelect = false;
        /** Display board selection? */
        this.$scope.showBoardSelect = false;

        // Get this party started!!
        this._establishState(patient).then();
    }

    async _continueToNextPhase() {

        // Post the new RX object to the backend.
        let theNewRx = await this.prescriptionService.create(this.$scope.practice, this._getSerializedRx());

        this.$uibModalInstance.close();

        this.$scope.onCreationHandler(theNewRx.href);
    }

    /**
     * @returns {SerializedManualPrescriptionDto}
     * @private
     */
    _getSerializedRx() {
        let rxModel = {};

        /* @type{ ReferenceDTO<Patient> } */
        rxModel.patient = this.$scope.patient;

        /* @type{  ReferenceDTO<Office> } */
        rxModel.office = this.$scope.office;

        /* @type{  ReferenceDTO<Panel> } */
        rxModel.panel = this.$scope.activePanel;

        /* @type{ ReferenceDTO<Board> } */
        rxModel.board = this.$scope.activeBoard;

        // User who ordered the prescription, and when. A UserAction has the form:
        // {
        //      /*ReferenceDTO<User>*/ user,  // HATEOUS reference in DTO covers it
        // }
        /* @type{  UserAction } */
        rxModel.orderedBy = {
            "user" : this.$scope.prescribingDoctor
        };

        /* @type{  List<ReferenceDTO<Substance>>  } */
        rxModel.substances = this._getSelectedSubstances();

        /* @type{  TreatmentType } backend values NONE, SCIT, SLIT */
        rxModel.treatmentType = this.$scope.activeTreatmentType.name;

        /* @type{ List<ReferenceDTO<TreatmentConfig>> } */
        rxModel.treatmentConfigs = this.$scope.selectedTreatmentConfig
            .map((tc,i) => {
                return tc ? { treatmentConfig: { id: tc.id }, dilution: i } : null;
            })
            .filter(tc => tc != null);

        // The remote API allows vialsUseBy to be omitted, so we serialize it conditionally.
        if (angular.isDate(this.$scope.useByScalar)) {
            rxModel.vialsUseBy = this.chronologyMappingService.jsUTCDateToISODate(this.$scope.useByScalar);
        }

        rxModel.mixNum = this.$scope.mixNum;

        rxModel.classical = this.$scope.isClassical;

        return rxModel;
    }

    async _establishState(subjectPatient) {

        this._initPatientFields(subjectPatient);
        await Promise.all([this._initDoctors(), this._initPanelChoices(), this._initBoardChoices(), this._initTreatmentConfigs()]);

        // Set Classical vs Otolaryngic option and default selection.
        this.$scope.haveClassicalTests = await this.allergyTestConfigService.haveClassicalTests(this.$scope.practice);
        this.$scope.haveOtolaryngicTests = await this.allergyTestConfigService.haveOtolaryngicTests(this.$scope.practice);
        this.$scope.addClassicalTreatmentConfig(this.$scope.haveClassicalTests);
        if (!this.$scope.haveOtolaryngicTests) {
            // Force SCIT only when only classical tests are available.
            this.$scope.availableTreatmentTypes = [{name:this.TreatmentType.SCIT}];
            this.$scope.onSelectTreatmentType(this.$scope.availableTreatmentTypes[0]);
        }

        // At least one of the above procedures involves asynchronous operations which do not consistently (ever)
        // finish in time for the document rendering phase. The concurrency idioms we employ in this class do not
        // work especially well with NG's digestion cycle, unless we give it nudge.
        this.$scope.$digest();
    }

    /**
     * @returns {Array.<SubstanceDtoSerialization>}
     * @private
     */
    _getSelectedSubstances() {
        // Observant readers will note that we're not exactly reassembling the substances list in reverse of the order
        // used to offer the choices. The reason is: the src list (of substances) contains non-antigen values, whereas
        // $scope.availableAntigens has already had them removed. Additionally, availableAntigen list entries contain
        // extra operational metadata which aids in their responsibilities to the view.
        let selectedSubstances = [];

        for (let aSubstance of this.$scope.availableAntigens) {

            if (true == aSubstance.vm.isSelected) {
                selectedSubstances.push(aSubstance.substance);
            }

        }

        return selectedSubstances;
    }

    /**
     * This is code for "init-the-datepicker UI support".
     *
     * @private
     */
    _initChronologicUi() {
        this.$scope.dp = {
            format: "MM/dd/yyyy",
            maxDate: new Date(),
            startingDay: 1,
            showWeeks: false
        };

        this.$scope.dateOptions = {
            startingDay: 1,
            showWeeks: false
        };
    }

    /**
     * @param patient
     * The logical subject Patient to which this instance is associated. The spawning progenitor UI had to
     * either explicitly has a subject Patient, OR the progenitor UI was spawned by component with one.
     * Either way, there MUST exist a Patient object driving this operation.
     *
     * @private
     */
    _initPatientFields(patient) {
        this.$scope.patient = patient;
        this.$scope.patient.dayOfBirth = patient.dayOfBirth;
    }

    _isTreatmentConfigDisabled(index) {
        if (this.$scope.hasTraditionalArrangement) {
            let populatedIndex = this.$scope.selectedTreatmentConfig.findIndex(m => m !== null);
            return populatedIndex >= 0 && populatedIndex !== index;
        }
        else {
            return index > 0 && this.$scope.selectedTreatmentConfig[index-1] == null;
        }
    }

    _isNextEnabled() {
        let scope = this.$scope;
        return scope.prescribingDoctor
            && scope.prescriptionDate
            && scope.selectedTreatmentConfig
            && scope.selectedTreatmentConfig.some(m => m !== null)
            && scope.totalAntigensSelected > 0
            && scope.mixNum >= 1;
    }

    /**
     * Asynchronously loads the available Panel values into $scope.availablePanels
     *
     * Note: Caller is responsible for giving the scope the heimlich maneuver!
     *
     * @private
     */
    async _initPanelChoices() {
        let practicePanels = await this.panelService.getActiveAtPractice(this.$scope.practice);
        this.$scope.availablePanels = practicePanels.list.filter(m => m.type === 'MIXING');
    }

    /**
     * Asynchronously loads the available Board values into $scope.availableBoards
     *
     * Note: Caller is responsible for giving the scope the heimlich maneuver!
     *
     * @private
     */
    async _initBoardChoices() {
        let officeBoards = await this.boardService.getAtOffice(this.$scope.practice, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS);
        this.$scope.availableBoards = officeBoards.list;
        this.$scope.hasTraditionalArrangement = officeBoards.list.some(m => m.arrangement === this.BoardArrangement.TRADITIONAL);
    }

    /**
     * Asynchronously loads the available TreatmentConfig values.
     * @private
     */
    async _initTreatmentConfigs() {
        this.allTreatmentConfigs =
            (await this.treatmentConfigService.getAtPractice(this.$scope.practice)).list.filter(tc => tc.active);
    }

    /**
     * Asynchronously loads the applicable User values into $scope.availableDoctors .
     * Note: we're only packing instances which represent a Doctor; nurses, administrators and otber non-MD types
     * are filtered out. The only values logically eligible for display are those eligible to write an RX.
     *
     * @private
     */
    async _initDoctors() {

        let practiceUsers = await this.userService.getUsers(this.$scope.practice, 'DOCTOR');

        for (let aUser of practiceUsers.list) {
            let aProviderChoice = {
                id : aUser.id
            };

            aProviderChoice.name = await this.userService.getUserName(aUser);
            this.$scope.availableDoctors.push(aProviderChoice);
        }

    }

    /**
     * Acquires data for, and populates $scope.activePanel.substances . Said field houses the Antigens appearing
     * following the Panel in the form layout.
     *
     * @private
     */
    async _loadAvailableAntigens() {

        this.$scope.activePanel = await this.panelService.get(this.$scope.activePanel);

        let panelAntigens = [];

        for (let aPanelSubstance of this.$scope.activePanel.substances) {
            if (aPanelSubstance.substance._dto.category._dto._isAntigen) {

                aPanelSubstance.vm = {}; // vm := View-Model; stuff intended for View, not for persistence.
                aPanelSubstance.vm.id = aPanelSubstance.substance.id;
                aPanelSubstance.vm.isSelected = false;
                aPanelSubstance.vm.name = aPanelSubstance.substance._dto.name;
                aPanelSubstance.vm.panelPos = aPanelSubstance.panelPos;

                panelAntigens.push(aPanelSubstance);
            }

        }

        this.$scope.availableAntigens = angular.copy(panelAntigens.sort((a,b) => a.panelPos - b.panelPos));
        this.$scope.$digest();
    }
}
