"use strict";

import angular from 'angular';
import BaseController from './../base.controller.js';
/**
 * Current OP: endow impl with User-role sensitivity.
 * Initial signs of effectiveness will be in the Navigation.
 * The Plan::
 * Secure access to UserSvc
 * Secure access to the current session user
 * Learn to read user-model's role-state && learn to twerk role-state from the DBMS
 * Enforce the role-state delimitted access to DB-Sections.
 * Keep strategy & tactics flexible enough to enforce Section-Specific feature-access limitted by role as well.
 *
 * Updated Responsibilities: It is now encumbant on this class to provide the initial data state for the
 * section controllers. This class will tap into the session-storage, acquire the requisite data and furnish it to
 * subordinate section controllers.
 *
 */
export default class InventoryController extends BaseController {

    static $inject = ['$scope', '$injector', 'InventorySections', 'activeSection', 'AlertIconPosition'];

    /**
     * @param {Angular.Scope} $scope
     *   a normal Scope instance Angular controllers manage
     *
     * @param {InventorySections} Sections
     *   enumerated representation of the UI sections managed by this controller
     *   This is the whole InventorySections anthology
     *
     * @param {InventorySections} activeSetion
     *   a scalar InventorySections value representing which section we wish to access
     */
    constructor($scope, $injector, InventorySections, activeSection, AlertIconPosition) {
        super($scope, $injector);

        // Resource establishment

        this.$scope.InventorySections = InventorySections;

        // Initial model state establishment

        if (activeSection) {
            this.sessionService.set(this.sessionService.KEY_INVENTORY_TAB, activeSection);
        } else {
            // Inventory tab was clicked. Reselect the last used inventory section.
            activeSection = this.sessionService.get(this.sessionService.KEY_INVENTORY_TAB);

            if (!activeSection) {
                // Oh, first time here. Default to Concentrates
                activeSection = InventorySections.CONCENTRATES;
                this.sessionService.set(this.sessionService.KEY_INVENTORY_TAB, activeSection);
            }
        }

        this.$scope.activeSection = activeSection;

        this.inventoryAlertService.subscribe($scope, alertSummary => {
            $scope.concentrateSectionAlert = alertSummary.icons.get(AlertIconPosition.CONCENTRATE);
            $scope.sptBoardSectionAlert = alertSummary.icons.get(AlertIconPosition.SPT_BOARD);
            $scope.idtBoardSectionAlert = alertSummary.icons.get(AlertIconPosition.IDT_BOARD);
            $scope.mixBoardSectionAlert = alertSummary.icons.get(AlertIconPosition.MIX_BOARD);
            $scope.patientVialSectionAlert = alertSummary.icons.get(AlertIconPosition.PATIENT_VIAL);
        });
    };
}
