'use strict';

import angular from 'angular';

import TestApprovalController from "./test-approval.controller.js";


export default angular.module('pages.common.approvals.test', [])
    .controller('TestApprovalController', TestApprovalController)
    .filter('bool2YesNo', function(){
        return function(arg){
            if (arg==true) return "YES";
            else return "NO";
        };
    })
    .directive('agTestApproval', [agTestApproval])
    .directive('agDisplayAntigens', [agDisplayAntigens])
    .directive('atrDisplayReview', [atrDisplayReview])
    .name;

// Directive to present the test approvals screen.
function agTestApproval() {
    return {
        template: require('./test-approve.html'),
        css: require('./test-approve.scss'),
        controller: 'TestApprovalController',
        scope: {
            allergyTestHref: '=',
            /** @type{boolean} Display testing results for final approval (and prescription creation)? */
            isApproval: '=',
            /** @type{boolean} Display test results from within the Testing Wizard? */
            isTestWizard: '=',
            /** Callback function upon exit. If isTestWizard and AllergyTest is modified,
                will pass the modified object as first parameter to callback function. */
            onExitCallback: '&?'
        },
        link: function(scope, element, attrs) {

        }
    }
}

// Directive that displays the list of antigens
function agDisplayAntigens() {
    return {
        template: require('./widgets/display-antigens.html'),
        css: require('./widgets/display-antigens.scss'),
        scope: {
            results: '=',  // array of TestAntigenResult and TestControlResult DTOs with added meta-data field
            dilutionRanges: '=',
            validEndPoints: '=', // @type {Array.<Integer>}
            endpointChangeHandler: '&', // callback we can call whenever a UI change hits the endPoint
            prescriptionChangeHandler: '&', // callback we can call whenever a UI change hits the Prescription field
            isApproval: '=', // If true allow the Dr to edit antigens.
            editDelayedReactions: '=', // If true enable editing of delayed reactions
            isDelayedReactionPresent: '=', // I/O field : this impl assigns this, caller reads it
            antigenTableConfig: '=' // { isClassical: boolean, sptWhealUnit, sptErythemaUnit, sptHistWhealUnit, sptHistErythemaUnit, idtWhealUnit, idtErythamaUnit, idtHistWhealUnit, idtHistErythamaUnit}
        },
        link : function(scope, element, attrs) {

            let
                /**
                 * @type {function({{ 'antigenIndex' : Number, 'updatedEndPoint' : Number }})}
                 */
                theEndPointChangeHandler = scope.endpointChangeHandler,

                /**
                 * @type {function({{ 'antigenIndex' : Number, 'hasPrescription' : Boolean }})}
                 */
                thePrescriptionChangeHandler = scope.prescriptionChangeHandler,

                /**
                 * @type {function} when called, the progenitor watch instance is gracefully.
                 * The watch listener routine tests the data-state to determine whether or not the Delayed Reaction
                 * column should be displayed or hidden.
                 */
                deregisterAntigenResultsWatch = scope.$watch(
                    ()=> scope.results,
                    newVal => {
                        if (newVal.length > 0) {
                            // Set isDelayedReactionPresent to true when any of the values in the results indicate a Delayed-Reaction has been applied
                            // Set haveIdMeasurement to true if any result has an Intradermal measurement
                            for (let aResult of newVal) {
                                if (!aResult._isControl && aResult.delayedSize > 0)
                                    scope.isDelayedReactionPresent = true;

                                if (aResult.sptMeasurements && aResult.sptMeasurements.wheal >= 0)
                                    scope.haveSptWheals = true;
                                if (aResult.sptMeasurements && aResult.sptMeasurements.erythema >= 0)
                                    scope.haveSptErythemas = true;

                                if (aResult.idtMeasurements && aResult.idtMeasurements.wheal >= 0)
                                    scope.haveIdtWheals = true;
                                if (aResult.idtMeasurements && aResult.idtMeasurements.erythema >= 0)
                                    scope.haveIdtErythemas = true;
                            }
                            deregisterAntigenResultsWatch();
                        }
                    }, true);

            scope.onEndPointChange = (antigenUiIndex, newEndPoint, newValue) => {
                newValue.endPoint = newEndPoint;
                let newEndPointValue = newValue.endPoint;
                theEndPointChangeHandler({
                    antigenIndex : antigenUiIndex ,
                    updatedEndPoint : newEndPointValue
                });
            };

            scope.onPrescriptionCheckToggle = (antigenUiIndex, isChecked) => {
                thePrescriptionChangeHandler({
                    antigenIndex : antigenUiIndex,
                    hasPrescription : isChecked
                });
            };

            scope.onDelayedReactionChange = () =>
                scope.isDelayedReactionPresent =
                    isAnyDelayedReactionsPresent(scope.results);

            /**
             * @type {boolean}
             * This a View layer only state flag indicating whether or not any of the members of scope.results
             * have a *delayedSize* field value greater than zero. The motivation is providing this state to the
             * template, which is required to hide the "Delayed Reaction Wheal Size" column whenever there are no
             * meaningful (scope.results.delayedSize are greater than zero) values.
             */
            scope.isDelayedReactionPresent = false;

            // histamine - true or false
            // wheal - range if false
            // spt - idt if false
            scope.displayMeasurement = (value, histamine, wheal, spt) => {
                if (value == undefined || value < 0)
                    return 'Not Tested';

                let config;
                if (spt) {
                    if (histamine) {
                        config = wheal ? scope.antigenTableConfig.sptHistWhealUnit : scope.antigenTableConfig.sptErythemaUnit; 
                    }
                    else {
                        config = wheal ? scope.antigenTableConfig.sptWhealUnit : scope.antigenTableConfig.sptErythemaUnit;
                    }
                }
                else {
                    if (histamine) {
                        config = wheal ? scope.antigenTableConfig.idtHistWhealUnit : scope.antigenTableConfig.idtErythemaUnit; 
                    }
                    else {
                        config = wheal ? scope.antigenTableConfig.idtWhealUnit : scope.antigenTableConfig.idtErythemaUnit;
                    }
                }

                return value + ' ' + config;
            };
        }
    };
}

// Directive for the template that displays the review section of approve test results
function atrDisplayReview() {
    return {
        template: require('./widgets/tr-display-review.html'),
        css: require('./widgets/tr-display-review.scss'),
        link: function(scope, elem, attr){

            scope.dosingScheduleHeadings = ['Red (1:1)', 'Blue (1:10)', 'Yellow (1:100)', 'Green (1:1,000)', 'Silver (1:10,000)'];
            scope.treatmentChanged = (item) => {
                if (scope.doctorSelectedTreatment !== item) {
                    scope.doctorSelectedTreatment = item;

                    // When treatment type changed, clear all dosing schedule selections
                    for (let idx = 0; idx < scope.selectedTreatmentConfig.length; ++idx)
                        scope.selectedTreatmentConfig[idx] = null;
                }
            };

            scope.panelChanged = (panel) => {
                scope.selectedPanel = panel;
            };

            scope.boardChanged = (board) => {
                scope.selectedBoard = board;
                scope.selectedPanel = scope.panels.find(m => m.id === board.panel.id);
            };

            scope.isDisplayEditable = () => {
                return scope.isDoctor && (scope.isApproval || scope.isTestWizard);
            };

            scope.tcSelectButtonText = (i) => {
                let result = 'No Schedule';
                if (scope.selectedTreatmentConfig[i]) {
                    result = scope.selectedTreatmentConfig[i].name;
                }
                else if (i === 0 && !scope.hasTraditionalArrangement) {
                    result = 'Select One';
                }
                return result;
            };

            scope.showTcNoSchedule = (i) => {
                if (scope.hasTraditionalArrangement) {
                    return true;
                }
                else {
                    return i > 0 && (i+1 >= scope.selectedTreatmentConfig.length || scope.selectedTreatmentConfig[i+1] === null);
                }
            };

            scope.isPatientConsentGiven = () => {
                return scope.patient
                    && scope.patient.consentsGiven
                    && scope.patient.consentsGiven.includes(scope.doctorSelectedTreatment);
            };
        }
    };
}
