'use strict';

import BaseSectionController from '../../widgets/section-content-panel/controller';
import moment from 'moment-timezone';

export default class BillingSectionController extends BaseSectionController {

    static $inject =  ['$injector', '$scope', 'DashboardSections'];

    constructor($injector, $scope, UiSectionTypes) {
        super($injector, $scope, UiSectionTypes.BILLING);

        this.$filter = $injector.get('$filter');
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.$uibModal = $injector.get('$uibModal');
        this.billService = $injector.get('billService');
        this.chronologyService = $injector.get('chronologyMappingService');
        this.officeService = $injector.get('officeService');
        this.patientService = $injector.get('patientService');
        this.reportsService = $injector.get('reportsService');

        this.currentPage = 1;

        $scope.reload = () => this.reload();
        $scope.onPatientClick = (row) => this._gotoPatientDetails(row.patientRef);
        $scope.sendBills = () => this._sendBills();
        $scope.toDateChanged = () => this._onToDateChange();
        $scope.fromDateChanged = () => this._onFromDateChange();
        $scope.setOfficeFilter = (office) => this._setOfficeFilter(office);
        $scope.searchFilterChanged = () => this.reload();
        $scope.editProvider = (row) => this._onEditProvider(row);
        $scope.onProviderDropdownToggle = (open, row) => this._onProviderDropdownToggle(open, row);
        $scope.setProvider = (row, provider) => this._setProvider(row, provider);
        $scope.editEncounter = (row) => this._onEditEncounter(row);
        $scope.encounterChanged = (row) => this._onEncounterChange(row);
        $scope.downloadBillCsv = () => this._downloadBillCsv();
        $scope.onPageChange = (newPage) => this._onPageChange(newPage);

        // Default scope values
        this.$scope.pageSize = 50;
        this.$scope.submitted = false;
        this.$scope.notSubmitted = false;
        this.$scope.downloaded = false;
        this.$scope.notDownloaded = false;

        this.$scope.fromDate = new Date();
        this.$scope.toDate = new Date();

        this.$scope.dpFormat = 'MM/dd/yyyy';
        this.$scope.dpFromOptions = { showWeeks: false };
        this.$scope.dpToOptions = { showWeeks: false };

        // Set $scope.canSendBills to true or false depending on if the practice is configured for billing integration
        this.$scope.canSendBills = this.$scope.practice.externalBilling;

        this._loadOffices();
        this._loadProviders();

        // End default scope values
        this.reload();
    }

    _loadOffices() {
        return this.officeService.getInPractice(this.$scope.practice, true)
            .then(offices => {
                this.$scope.allOffices = offices.list;
            });
    }

    _loadProviders() {
        this.userService.getUsers(this.$scope.practice, 'DOCTOR').then(users => {
            let providers = users.list;
            for(let provider of providers) {
                provider.person.familyName = provider.person.familyName || '';
                provider._fullName = provider.person.givenName + ' ' + provider.person.familyName;
            }
            this.$scope.providers = providers.sort((a,b) => a.person.familyName.localeCompare(b.person.familyName));
        });
    }

    /* Load data, or reload when something changes */
    async reload() {
        /*
         * submitted = null if submitted or notSubmitted both checked (or both not checked),
         * false if notSubmitted checked
         * true if submitted checked
         */
        let submitted = this.$scope.submitted ? true : this.$scope.notSubmitted ? false : null;

        /*
         * downloaded = null if downloaded or notDownloaded both checked (or both not checked),
         * false if notDownloaded checked
         * true if downloaded checked
         */
        let downloaded = this.$scope.downloaded ? true : this.$scope.notDownloaded ? false : null;

        let officeId = this.$scope.selectedOffice ? this.$scope.selectedOffice.id : null;

        await this._loadBills(officeId, this.$scope.fromDate, this.$scope.toDate, downloaded, submitted);
    }

    _onToDateChange() {
        if (this.$scope.toDate) {
            let delta = moment(this.$scope.toDate).diff(this.$scope.fromDate, 'days');
            if (delta < 0) {
                this.$scope.fromDate = this.$scope.toDate;
            }
            this.reload();
        }
    }

    _onFromDateChange() {
        if (this.$scope.fromDate) {
            let delta = moment(this.$scope.toDate).diff(this.$scope.fromDate, 'days');
            if (delta < 0) {
                this.$scope.toDate = this.$scope.fromDate;
            }
            this.reload();
        }
    }

    _setOfficeFilter(office) {
        let a = this.$scope.selectedOffice;
        let b = office;

        /* Return if no change */
        if (!a && !b || (a && b && a.id === b.id)) {
            return;
        }

        this.$scope.selectedOffice = office;
        this.reload();
    }

    // Download billing report for any checked bills in the table
    _downloadBillCsv() {
        let pageSize = this.$scope.pageSize;
        this.$scope.downloadingBillCsv = true;
        this.$scope.pageSize = 100000;
        this.$timeout(() => { }, 0)
            .then(() => {
                let bills = this.$scope.displayedCollection.filter((row, i) => row.isSelected === true).map(m => m.bill.id);
                if (bills && bills.length) {
                    this.billService.serverAPI.post(this.$scope.practice._links.downloadBill, null, bills).then(content => {
                        let link = document.createElement('a')
                        link.id = 'bill-csv'
                        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
                        link.setAttribute('download', 'BillingReport.csv');
                        document.body.appendChild(link);
                        document.querySelector('#bill-csv').click();
                        document.body.removeChild(link);
                        this.$scope.pageSize = pageSize;
                        this.$scope.downloadingBillCsv = false;
                    });
                }
                else {
                    this.$scope.pageSize = pageSize;
                    this.$scope.downloadingBillCsv = false;
                }
            });
    }

    _onPageChange(newPage) {
        this.currentPage = newPage;
    }

    // Load all bills based on the current filters and subscribe to updates
    async _loadBills(officeId, from, to, downloaded, submitted) {
        this.$scope.rowCollection = [];
        this.$scope.loading = true;

        from = this.chronologyService.startOfDay(this.chronologyService.jsLocalDateToISODate(from));
        to = this.chronologyService.endOfDay(this.chronologyService.jsLocalDateToISODate(to));

        let criteria = {
            practiceId: this.$scope.practice.id,
            officeId: officeId,
            timeFrameFrom: from,
            timeFrameTo: to,
            page: 1,
            pageSize: 10000
        };

        if (downloaded !== null) {
            criteria.downloaded = +downloaded;
        }
        if (submitted != null) {
            criteria.submitted = +submitted;
        }

        let vms = [];
        let hasResults = true;
        while (hasResults && vms.length < 10000) {

            let billHistoryResponse = await this.reportsService.getBillHistory(criteria);
            for (let item of billHistoryResponse.list.sort((a,b) => a.performedDate.localeCompare(b.performedDate))) {
                vms.push(await this._buildViewModel(billHistoryResponse, item, {}));
            }

            criteria.page++;
            hasResults = billHistoryResponse.list.length > 0;
        }

        this._resetSmartTable();
        this.$scope.rowCollection = vms;
        this.$scope.loading = false;
        this._updateSmartTable();
    }

        /*
     * Build view model for a given billHistory item
     * @param bills BillHistory response
     * @param item the specific BillHistory object
     * @param vm object to put the view model into - pass in {} if new.
     * @returns vm
     */
    async _buildViewModel(billHistoryResponse, item, vm) {

        vm.bill = item.bill;
        vm.bill._links = { send: this._createHref(item.bill.id, billHistoryResponse._linkTemplates.sendBill) };
        vm.bill.href = this._createHref(item.bill.id, billHistoryResponse._linkTemplates.bill);
        vm.patientRef = item.patient;
        vm.patientRef.href = this._createHref(item.patient.id, billHistoryResponse._linkTemplates.patient);
        vm.patientName = item.patient.name;
        vm.chartNumber = item.chartNumber;
        vm.activity = this.$filter('agActivityName')(item.activity);
        vm.datePerformed = moment(item.performedDate);
        vm.datePerformedDisplay = vm.datePerformed.format('MM/DD/YYYY');
        vm.dateApproved = moment(item.approvedDate);
        vm.dateApprovedDisplay = vm.dateApproved.format('MM/DD/YYYY');
        vm.editingEncounter = item.bill.encounter ? null : '';
        vm.editingProvider = item.bill.provider ? null : '';

        let office = this.$scope.allOffices.find(m => m.id === item.office.id);
        if (office) {
            vm.locationName = office.name;
        }

        if (item.bill.provider) {
            vm.provider = this.$scope.providers.find(provider => provider.id === item.bill.provider.id);
            vm.provider.href = this._createHref(item.bill.provider.id, billHistoryResponse._linkTemplates.user);
            vm.providerName = await this.userService.getUserName(vm.provider);
        }

        vm.actionsBy = [];

        for(let download of item.bill.downloads) {
            download.user.href = this._createHref(download.user.id, billHistoryResponse._linkTemplates.user);
            this.userService.populateUserAction(download);
            download.isDownload = true;
            vm.actionsBy.push(download);
        }
        for(let submission of item.bill.transmissions) {
            submission.sentBy.user.href = this._createHref(submission.sentBy.user.id, billHistoryResponse._linkTemplates.user);
            this.userService.populateUserAction(submission.sentBy);
            submission.isSubmission = true;
            vm.actionsBy.push(submission.sentBy);
        }
        vm.haveActions = vm.actionsBy.length ? 'Yes' : 'No';

        this._sortViewModelActions(vm);

        return vm;
    }

    _createHref(id, template) {
        return template.replace('{id}', id);
    }

    // Sort actionsBy list on the bill by name
    _sortViewModelActions(vm) {
        vm.actionsBy.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                let aDate = new Date(a.actionDateTime).getTime();
                let bDate = new Date(b.actionDateTime).getTime();
                return aDate - bDate;
            }
        });
    }

    _resetSmartTable() {
        this.$scope.rowCollection = [];
        this.$scope.displayedCollection = [];
        this.$scope.rowSelectedObj = { isSet: false };
    }

    _updateSmartTable() {
        this.$scope.$apply(() => { this.$scope.displayedCollection = [].concat(this.$scope.rowCollection) } );
    }

    _gotoPatientDetails(patient) {
        this.routeToPage(this.urlPaths.PATIENT_DETAILS, patient, this.routingService.createLocationParams('Billing'));
    }

    /** User has selected to edit a provider */
    _onEditProvider(row) {
        row.editingProvider = true;
    }

    /** Provider dropdown has been toggled, either because the user chose a provider or clicked something else */
    _onProviderDropdownToggle(open, row) {
        row.editingProvider = open;
    }

    /** User has selected to edit an encounter */
    _onEditEncounter(row) {
        if (row.editingEncounter === null) // Not already editing
            row.editingEncounter = row.bill.encounter;
    }

    /** User has changed a Provider on the page */
    _setProvider(row, provider) {
        // Only proceed if value has changed
        if (provider && (!row.bill.provider || provider.id !== row.bill.provider.id)) {
            // Set & save the new value
            row.bill.provider = provider;
            this.billService.update(row.bill)
                .then(newBill => {
                    row.bill = newBill;
                    this.userService.getUserName(row.bill.provider).then(userName => {
                        row.providerName = userName;
                    });
                });
        }

        // Close editor
        row.editingProvider = false;
    }

    /** User had finished editing an Encounter # on the page */
    _onEncounterChange(row) {
        // Only proceed if value has changed
        if (row.bill.encounter !== row.editingEncounter) {
            // Set & save the new value
            row.bill.encounter = row.editingEncounter;
            this.billService.update(row.bill)
                .then(newBill => {
                    row.bill = newBill
                });
        }

        row.editingEncounter = null;
    }

    _openSendBillModal(results) {
        return this.$uibModal.open({
            template: require('./widgets/send-bill-modal.html'),
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
                results: () => {
                    return results;
                }
            },
            controller: function ($uibModalInstance, $scope, results) {
                $scope.results = results;

                $scope.status = (item) => {
                    for(let a of $scope.results.succeeded) {
                        if (a.bill.id == item.bill.id) {
                            return 1;
                        }
                    }

                    for(let a of $scope.results.failed) {
                        if (a.bill.id == item.bill.id) {
                            return -1;
                        }
                    }

                    return 0;
                };

                $scope.close = () => $uibModalInstance.close();
            }
        });
    }

    _sendBills() {
        if (this.$scope.rowCollection.length > 0) {
            let results = {
                all: [],
                succeeded: [],
                failed: []
            };

            let q = [];

            for(let item of this.$scope.rowCollection) {
                if (item.isSelected === true) {
                    results.all.push(item);
                    let promise = this.billService.send(item.bill).then(() => {
                        results.succeeded.push(item);
                    }).catch(() => {
                        results.failed.push(item);
                    });

                    q.push(promise);
                }
            }

            this._openSendBillModal(results);

            this.$q.all(q).then(() => {
                this.reload();
            });
        }
    }
}
