'use strict';

import BaseTreatmentController from '../base-treatment.controller'

/**
 * Simple controller that examines the Treatment and routes to the actual correct tab of the Treatment Wizard.
 */
export default class TreatmentInitializeController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        console.log("TreatmentInitializeController started");

        let params = this.getRouteParams();
        if (!params || !params.href) {
            console.error("No treatment given to initialize treatment wizard");
            this.exitToDashboard();
        }
        else {
            // Loads Patient and Treatment into scope. Promise lets you know when it's all done.
    		this.treatmentService.getUncached({href: params.href})
                .then(treatment => {
                    this.treatment = treatment;
                    return this.visitService.get(this.treatment.visit);
                })
                .then(visit => {
                    this.visit = visit;
                    this.autoRoute();
                })
                .catch(error => console.log(error));
        }
    }
}
