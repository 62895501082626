'use strict';

import angular from 'angular';
import moment from 'moment-timezone';
import PatientAlertWarningController from "./patient-alert-warning.controller";

export default angular.module('widgets.notForProduction', [])
    .directive('agNotForProduction', [agNotForProduction])
    .directive('agPatientAlertWarning', [agPatientAlertWarning])
    .directive('agImpersonationNotice', [agImpersonationNotice])
    .directive('agFocus', agFocus)
    .directive('agBarcodeEntry', ["$window", agBarcodeEntry])
    .directive('agCheckbox', agCheckbox)
    .directive('agFooterNote', agFooterNote)
    .directive('agWizardAlert', agWizardAlert)
    .directive('noFutureDate', [noFutureDate])
    .directive('noPastDate', [noPastDate])
    .directive('digestOnResize', ['$window', digestOnResize])
    .name;

function agNotForProduction() {
    return {
        scope: {
            type: '@'
        },
        winddowTopClass: require('./not-for-production.scss'),
        template: require('./not-for-production.html')
    };
}

function agPatientAlertWarning() {
    return {
        controller: PatientAlertWarningController,
        scope: {
            patient: '=',
            location: '='
        },
        windowClass: require('./patient-alert-warning.scss'),
        template: require('./patient-alert-warning.html')
    };
}

function agImpersonationNotice() {
    return {
        scope: {
            user: '='
        },
        windowClass: require('./impersonation-notice.scss'),
        template: require('./impersonation-notice.html')
    };
}

/*
 * From https://coderwall.com/p/a41lwa/angularjs-auto-focus-into-input-field-when-ng-show-event-is-triggered
 * Directive/attribute value is a boolean condition. When it goes true, assigns focus to this input field.
 * Usage: <input type="text" ag-focus="isEditing" ng-model="data">
 */
function agFocus($timeout) {
    return {
        restrict: "A",
        scope: false,
        link: function (scope, element, attrs) {
            scope.$watch(attrs.agFocus,
                function (newValue) {
                    $timeout(function() {
                        newValue && element[0].focus();
                    });
                },true);
        }
    };
}

/**
 * Put this directive as an attribute on an input intended for barcode scan or magcard swipe entry.
 * - Automatically give that input focus upon numeric input to the browser.
 * - At the start of entry, the input value is cleared too. A new-line or carriage return indicates the end
 * of input.
 * - Input to the tagged element is restricted to only digits.
 * - Only enabled when attribute value is 'true', so that caller can disable behavior.
 * - Disables self when user is typing into any input or textarea.
 */
function agBarcodeEntry($window) {
    return {
        restrict: "A",
        scope: {},
        link: function (scope, element, attrs) {
            const targetElement = element[0];
            scope.enteringBarcode = false;

            // Watch keypresses on the page
            angular.element($window).on('keydown', function (e) {
                const activeElement = document.activeElement;
                const tagName = activeElement.tagName.toLowerCase();

                if (tagName === 'input' || tagName === 'textarea') {
                    // If focus is already on an input area, don't steal it away!
                }
                else if (attrs.agBarcodeEntry !== 'true') {
                    // Ignore if caller has disabled focus stealing
                }
                else if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) {
                    // Ignore if modifier pressed too.
                }
                else if (e.keyCode === 10 || e.keyCode === 13) {
                    scope.enteringBarcode = false;
                }
                else if (e.keyCode >= 48 && e.keyCode <= 57) {
                    if (!scope.enteringBarcode || activeElement !== targetElement) {
                        scope.enteringBarcode = true;
                        targetElement.value = '';
                        targetElement.focus();
                    }
                }
            });

            // Restrict keypresses in the element to digits, ENTER, and = (which has some special handling)
            element.on('keypress', function(event) {
                let keyCode = event.keyCode;
                if (keyCode !== 10 && keyCode !== 13 && keyCode !== 61 && (keyCode < 48 || keyCode > 57)) {
                    event.preventDefault();
                }
            });

            scope.$on("$destroy", function() {
                angular.element($window).off('keydown');
                element.off('keypress');
            });
        }
    };
}

function agCheckbox($timeout) {
    return {
        scope: {
            ngModel: '=',
            ngClick: '&',
            readonly: '=',
            label: '@'
        },
        template: require('./ag-checkbox.html'),
        css: require('./ag-checkbox.scss'),
        link: function (scope, elem, attrs) {
            if (attrs.ngModel !== undefined) {
                scope.checked = scope.ngModel;
            }
            else {
                scope.checked = false;
            }

            scope.toggle = function ($event) {
                if (attrs.readonly === undefined || !scope.readonly) {
                    scope.checked = !scope.checked;
                    if (attrs.ngModel) {
                        scope.ngModel = scope.checked;
                    }

                    // Call ng-click handler if supplied
                    $timeout(() => {
                        scope.ngClick();
                    });
                }

                $event.stopPropagation();
            };

            scope.$watch(() => {
                return scope.ngModel;
            }, (newValue) => {
                scope.checked = newValue;
            });
        }
    };
}

function agFooterNote($timeout) {
    return {
        scope: {
            userAction: '=',
            readOnly: '='
        },
        template: require('./footer-note.html'),
        css: require('./footer-note.scss'),
        link: function (scope, element, attrs) {

            scope.getEditor = () => document.getElementById('footer-note-text');

            // Use to set focus on textarea when opened.
            scope.uponIconClick = () => {
                // Wait for editor to open or close.
                $timeout(function() {
                    let editor = scope.getEditor();
                    if (editor !== null) {
                        scope.isHovering = false;
                        editor.focus()
                    }
                }, 200);
            };

            scope.isEditorShowing = () => {
                return scope.getEditor() !== null;
            }
        }
    };
}

function agWizardAlert() {
    return {
        scope: {
            message: '='
        },
        transclude: true,
        template: require('./wizard-alert.html'),
        css: require('./wizard-alert.scss'),
        link: function (scope, element, attrs) {

        }
    };
}

function noFutureDate() {
    return {
        require: 'ngModel',
        link: function(scope, elem, attr, ngModel) {

            ngModel.$parsers.unshift((value) => {
                var today = moment().startOf('day');
                var amoment = moment(value, 'MM/DD/YYYY');

                var valid = false;
                if (amoment.isValid() && amoment.isSameOrBefore(today)) {
                    valid = true;
                }

                ngModel.$setValidity('noFutureDate', valid);
                return valid ? value : undefined;
            });

            ngModel.$formatters.unshift((value) => {
                var today = moment().startOf('day');
                var amoment = moment(value, 'MM/DD/YYYY');

                var valid = amoment.isValid() && amoment.isSameOrBefore(today);
                ngModel.$setValidity('noFutureDate', valid);

                return value;
            });
        }
    };
}

function noPastDate() {
    return {
        require: 'ngModel',
        link: function(scope, elem, attr, ngModel) {

            ngModel.$parsers.unshift((value) => {
                var today = moment().startOf('day');
                var amoment = moment(value, 'MM/DD/YYYY');

                var valid = false;
                if (amoment.isValid() && amoment.isSameOrAfter(today)) {
                    valid = true;
                }

                ngModel.$setValidity('noPastDate', valid);
                return valid ? value : undefined;
            });

            ngModel.$formatters.unshift((value) => {
                var today = moment().startOf('day');
                var amoment = moment(value, 'MM/DD/YYYY');

                var valid = amoment.isValid() && amoment.isSameOrAfter(today);
                ngModel.$setValidity('noPastDate', valid);

                return value;
            });
        }
    };
}

function digestOnResize($window) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            var onResize = () => {
                scope.$digest(); 
            };
            
            var destroy = () => {
                angular.element($window).off('resize', onResize);
            };
            
            angular.element($window).bind('resize', onResize);            
            scope.$on('$destroy', destroy);
        }
    };
}
