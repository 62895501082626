
import angular from 'angular';
import ngRoute from 'angular-route';

import TestIdtHistamineChecklistController from './idt-histamine-checklist.controller.js';
import TestIdtHistamineTimerController from './idt-histamine-timer.controller.js';
import TestIdtHistamineMeasureController from './idt-histamine-measure.controller';

export default angular.module('pages.testing.idt-histamine', [ngRoute])
    .config(config)
    .controller('TestIdtHistamineChecklistController',TestIdtHistamineChecklistController)
    .controller('TestIdtHistamineTimerController',TestIdtHistamineTimerController)
    .controller('TestIdtHistamineMeasureController',TestIdtHistamineMeasureController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/idtHistamine/checklist', {
        template: require('./idt-histamine-checklist.html'),
        css: require('../idt-checklist/idt-checklist.scss'),
        controller: 'TestIdtHistamineChecklistController'
    }).when('/testing/idtHistamine/timer', {
        template: require('./idt-histamine-timer.html'),
        css: require('../measure-spt-result/testing-measure-spt-timer.scss'),
        controller: 'TestIdtHistamineTimerController'
    }).when('/testing/idtHistamine/measure', {
        template: require('./idt-histamine-measure.html'),
        controller: 'TestIdtHistamineMeasureController'
    });
}

config.$inject = ['$routeProvider'];

