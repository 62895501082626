'use strict';

import BaseService from './base.service.js';

export default class VisitVitalsService extends BaseService {

    /**
     * Get the pre-vitals for a Visit.
     * @return Promise to a VisitVitals DTO
     */
    getPreVitalsForVisit(visit) {
        return this.serverAPI.get(visit.preVitals.href, {}, visit);
    }

    /**
     * Get the post-vitals for a Visit.
     * @return Promise to a VisitVitals DTO
     */
    getPostVitalsForVisit(visit) {
        return this.serverAPI.get(visit.postVitals.href, {}, visit);
    }
}
