"use strict";

import BaseController from "../../../base.controller";

export default class SpWellModalController extends BaseController {

    static COLUMNS_PER_PRICKER = 2; // Currently, all prickers are of the 2 column varieties.
    static UseCaseEdit = "EDIT";
    static UseCaseAdd = "ADD";

    static $inject =
              ["$uibModalInstance","$scope","$injector","useCase"];
    constructor($uibModalInstance , $scope , $injector , useCase ) {
        super($scope, $injector);

        this.$scope.UseCaseEdit = SpWellModalController.UseCaseEdit;
        this.$scope.UseCaseAdd = SpWellModalController.UseCaseAdd;
        this.$uibModalInstance = $uibModalInstance;
        this.$scope.useCase = this._useCase = useCase;// internal meta-data

        this._initInjections($injector);

        // Scope Initialization
        /** @type{String} UI-metadata; not part of DM */
        this.$scope.submissionFailureMessage = undefined;
        /** @type{Boolean} UI-metadata; not part of DM */
        this.$scope.isSubmissionFailure = false;
        /** @type{String} */
        this.$scope.boardName = undefined; // User selected name of subject

        /** @type{Panel-model-object} */
        this.$scope.panel = undefined; // Panel associated with subject (HUGE data structure)
        /** @type{String} */
        this.$scope.panelId = undefined; // Opaque identifier representing scope.panel

        /** @type{String} */
        this.$scope.prickerTypeN = undefined; // Configuration-Expression formatted string
        /** @type{Integer} */
        this.$scope.totalPrickersPerTray = undefined; // How many prickers are in each tray
        /** @type{Integer} */
        this.$scope.totalTrays = undefined; // How many trays in the board

        /** @type{ServiceStatus} */
        this.$scope.status = undefined; // ServiceStatus value of subject
        /** @type{Array.<ServiceStatus>} */
        this.$scope.eligibleStatusTypes = undefined;// Choices offered to the user, to assign to subject.status


        // Events
        this.$scope.cancel =()=> $uibModalInstance.dismiss();

        // I leave it the concrete subclasses to initiate bootstrapping the instance
    }

    /**
     * Subclasses should call this and apply their own impl. We've consolidated the litany of injections outside of the
     * constructor because this class (planned as an incomplete-abstract base class) has subclasses which have different
     * injection addenda. The subclasses use normal super-constructor in their instantiations, which causes this impl's
     * constructor to run to completion before processing any of the remaining constructor logic in the subclasses.
     * This means that any injections occurring within a subclass's constructor will not happen until AFTER this impl's
     * constructor is done. This in turn means subclasses' cannot inject in the traditional fashion.
     *
     * That's this method's Raison d'Etre! Subclasses' injection logic in overrides of this method WILL make it into the
     * instance before this constructor is done!
     *
     * @protected
     */
    _initInjections($injector) {
        this.ProcedureType = $injector.get("Procedure");
        this.ServiceStatus = $injector.get("ServiceStatus");
        this.panelSvc = $injector.get("panelService");
        this.boardService = $injector.get("boardService");
        this.eligibleStatusService = $injector.get("eligibleStatusService");
    }

    /**
     * Subclasses should override or supplement this. At a bare minimum, they should call this impl, and
     * digest the scope.
     *
     * @protected
     */
    async _initialize() {
        await this._loadServiceStatusValues();
        await this._loadPrickerData();
    }

    async _loadPrickerData() {

        if (false == angular.isDefined(this.$scope.panel))
            return;

        this._updatePrickerConfig();

    }

    /**
     * @protected
     */
    _updatePrickerConfig() {

        let practiceCfg = this.$scope.practice.config,
            totalSubstances = (this.$scope.panel.substances.length | 0);

        this.$scope.columnsPerPricker = SpWellModalController.COLUMNS_PER_PRICKER;

        this.$scope.rowsPerPricker =
            ((practiceCfg.sptSubstancesPerTray / practiceCfg.sptPrickersPerTray) / this.$scope.columnsPerPricker);

        //  # of Prickers Per Tray - PracticeConfig.sptPrickersPerTray (configurable through Super Admin UI)
        this.$scope.totalPrickersPerTray = practiceCfg.sptPrickersPerTray;
        // # of Trays - count panelSubstance where matching panelSubstance.panel_id divided by PracticeConfig.sptSubstancesPerTray
        this.$scope.totalTrays = Math.ceil(totalSubstances / practiceCfg.sptSubstancesPerTray);

    }

    /**
     * @protected
     * "virtual"
     * This impl is handled by subclasses according to their needs.
     */
    async _loadServiceStatusValues() {}

    /**
     * @param {Panel-model-object} panel
     * @protected
     */
    _assignPanel(panel) {
        this.$scope.panel = panel;
        // use this id to retrieve the Panel-object from the eligiblePanels Map, then assign it to scope.panel!
        this._updatePrickerConfig();
    }

    /**
     * @param {BoardModel} refreshedModelState
     *  updated state of the subject model instance of this controller instance.
     *
     * @protected
     */
    _closeModal(refreshedModelState) {
        this.$uibModalInstance.close(refreshedModelState);
    }

}
