'use strict';

import angular from 'angular';

import TreatmentApprovalController from "./review-treatment.controller.js";

export default angular.module('pages.dashboard.sections.approvals.treatment', [])
    .controller('TreatmentApprovalController', TreatmentApprovalController)
    .directive('agTreatmentReview', agTreatmentReview)
    .directive('rtrTreatmentDetails',["$injector", rtrTreatmentDetails])
    .directive('rtrVialTestDetails',["$injector", rtrVialTestDetails])
    .filter("reactionWhealSize", reactionWhealSize)
    .name;

// Directive to present the treatment review.
function agTreatmentReview() {
    return {
        template: require('./review-treatment.html'),
        css: require("./review-treatment.scss"),
        controller: 'TreatmentApprovalController',
        scope: {
            treatmentHref: '=',
            isApproval: '=',
            onExitCallback: '&?'
        },
        link: function(scope) {

            let onVmReadyWatchDismiss = scope.$watch(
                ()=> scope.vm,
                (newVm)=> {
                    if (false == angular.isDefined(newVm))
                        return;
                    if (angular.isDefined(newVm.testingVialList)) {
                        scope.isVialTestUseCase = (newVm.testingVialList.length > 0);
                        onVmReadyWatchDismiss();
                    }
                }
            );

        }
    }
}

function rtrTreatmentDetails($injector){
    return {
        scope: {
            showLastColumn: '=',
            vialData: '=',
            treatmentType: '='
        },
        template: require('../widgets/treatment-details.html'),
        css: require("../widgets/treatment-details.scss"),
        link: function(scope) {
            scope.VialReasonCode = $injector.get("TreatmentVialReasonCode");

            let endWatch = scope.$watch('treatmentType', (newType) => {
                if (newType) {
                    scope.isSCIT = (newType === 'SCIT');
                    scope.isSLIT = (newType === 'SLIT');
                    scope.historyLabel = (scope.isSCIT ? 'Vial' : 'Treatment') + ' History';
                    endWatch();
                }
            });
        }
    };
}

function rtrVialTestDetails($injector) {
    return {
        scope: {
            vialData: '='
        },
        template: require('../widgets/vial-test-details.html'),
        css: require('../widgets/vial-test-details.scss'),
        link: function(scope) {
            scope.VialReasonCode = $injector.get("TreatmentVialReasonCode");
        }
    };
}

/**
 * This is an implementation of the lexical UI guidelines for presenting IntraDermal Test Reaction values.
 * The applicable UI-guidelines were laid out in:    https://nbdevs.atlassian.net/browse/AAW-196
 *
 * @returns {Filter({Number}) : {String}}
 */
function reactionWhealSize() {
    return (x)=> {
        if (false == angular.isDefined(x)) return "";
        if (x < 1) { return "No Reaction"; }
        if (x === 18) { return "18mm (Dime)"; }
        if (x === 21) { return "21mm (Nickle)"; }
        if (x === 24) { return "24mm (Quarter)"; }
        if (x === 30) { return "30mm (Half Dollar)"; }
        if (x < 31) { return x+"mm"; }
        if (x >= 31) { return "30+mm";}
        return x;
    };
};
