"use strict";

import BaseApprovalController from "../base-approval.controller.js";
import { ClassicalDilution,ClassicalDilutions } from "../../../../models/classical-dilutions";

/**
 * Controller for Prescription Approvals.
 *
 */
export default class PrescriptionApprovalController extends BaseApprovalController {

    static SOLUTION_OVERDILUTED = "OVERDILUTED"; // internally used data constant
    static SOLUTION_OVERCONCENTRATED = "OVERCONCENTRATED"; // internally used data constant
    static OP_STATE_ERROR_DILUTION_VIOLATION = "OP_STATE_ERROR_DILUTION_VIOLATION"; // an operational problem condition

    /**
     * @private
     * @type {boolean}
     * We actively set it true upon detection of a substance being drug out from one vial.
     * We actively set it false once a substance completes the transfer to a new vial.
     *
     * Corolaries to kindred D&D support members' semantics:
     * when false, #_migratingVialIndex and _migratingSubstanceIndex will be undefined
     * when true,  #_migratingVialIndex and _migratingSubstanceIndex will both contain
     * positive integer values.
     *
     * TODO: identify a way to detect a broken D&D-xfer, then impl false setting once a D&D xfer is foresaken.
     */
    _isSubstanceInDrag = false;
    /**
     * @private
     * @type {Number|undefined}
     * A transient storage of treatment-vial index of the substance subject of a UI drag & drop action.
     */
    _migratingVialIndex = undefined;
    /**
     * @private
     * @type {Number|undefined}
     * A transient storage of the index (within a treatment-vial) of substance subject of a UI drag & drop action.
     */
    _migratingSubstanceIndex = undefined;

    constructor($scope, $injector, $filter, UrlPaths) {
        super($scope, $injector);

        this.BoardArrangement = $injector.get('BoardArrangement');
        this.concentrateService = $injector.get('concentrateService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.boardService = $injector.get('boardService');
        this.panelService = $injector.get('panelService');
        this.Procedure = $injector.get('Procedure');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.PrescriptionReason = $injector.get('PrescriptionReason');
        this.$q = $injector.get('$q');
        this.$filter = $filter;
        this.$uiModal = $injector.get('$uibModal');
        this._UrlPaths = UrlPaths;
        this.$scope.isEditable = this.$scope.isApproval;
        this.$scope.haveVolumeError = false;
        this.$scope.skipMixingBoard = false;

        // Per business rules: theBoard does not specify any specific lower bounds on substance dilution.
        // However, the rules do specify integral units. Hence, we'll assume the logical lower bound is: not diluting
        // the agent at all. A.K.A.: the fully concentrated, uncut, straight-up raw form of the substance, which is 0.
        this.$scope.MINIMUM_DILUTION = 0;
        // Per business rules: theBoard contains the systemic limit on how much a substance may be diluted
        this.$scope.MAXIMUM_DILUTION = this.$scope.practice.config.mixDilutionsCount;

        /**
         * Reacts to UI actions requesting changes to the prescription's substance configuration.
         *
         * @param {Number} vialIndex : an integer [0,n], where the index indicates the sequence in UI presentation of
         *  this instance's logical RX.
         *
         * @param {Number} substanceIndex : an integer [0,n], where the index indicates the sequence in UI presentation
         *  of this instance's logical RX.vial[*vialIndex*].
         */
        this.$scope.savePrescriptionUpdate = (vialIndex) => {
            this._validateSubstanceFields();
            this._updateVialVolume(vialIndex);
        };

        /**
         * @param vialIndex : indicates which Vial (of all vials in the prescription)
         * @param dosage : the quantity of the active agent
         * @param substanceId : opaque identifier of the substance in the DB.
         *
         * Alters the prescription by adding *dosage* milliLiters of diluting agent (identified by *substanceId*) to
         * the vial (identified by *vialIndex*), as ingredient number (identified by *substanceIndex*).
         */
        this.$scope.vialDilutantAdditionHandler = (vialIndex, dosage, substanceId) => {

            this.pscript.vials[vialIndex].substances.push(this._createRxVialSubstance(
                dosage, /* _isControl */true, /* dilution */0, /* endPoint */0,
                /*maintPotency*/0, /*maintVolume*/0, /*concPotency*/0, /*potencyUnit*/null,
                substanceId, vialIndex
            ));
            this._loadAvailableSubstances();
            this._sortChemicalsInVial(vialIndex);
            this._validateSubstanceFields();
            this._updateVialVolume(vialIndex);

            this.$scope.prescribedVials[vialIndex].newDiluent.id = "";
            this.$scope.prescribedVials[vialIndex].newDiluent.name = "";
            this.$scope.prescribedVials[vialIndex].newDiluent.dosage = "";
        };

        /**
         * @param vialIndex : indicates which Vial (of all vials in the prescription)
         * @param dosage : quantity (volume in milliLiters) of the active agent
         * @param dilution : integer scale index for the degree of dilution the agent will undergo
         * @param endPoint : integer scale index for the severity of the reaction to this substance during testing
         * @param substanceId : opaque identifier of the substance in the DB.
         *
         * Alters the prescription by adding *dosage* milliLiters of the antigen (identified by *substanceId*),
         * diluted to level *dilution*, to the vial (identified by *vialIndex*), as ingredient number (identified by
         * *substanceIndex*). We also perpetuate continuity of the patient's vulnerablility/sensitivity to the substance
         * using a numeric scale represented by *endPoint*.
         */
        this.$scope.vialAntigenAdditionHandler = (vialIndex, dosage, dilution, endPoint, maintPotency, maintVolume, concPotency, potencyUnit, substanceId) => {

            this.pscript.vials[vialIndex].substances.push(this._createRxVialSubstance(
                dosage, /* _isControl */false, dilution, endPoint,
                maintPotency, maintVolume, concPotency, potencyUnit,
                substanceId, vialIndex
            ));
            this._loadAvailableSubstances();
            this._sortChemicalsInVial(vialIndex);
            this._validateSubstanceFields();
            this._updateVialVolume(vialIndex);

            this.$scope.prescribedVials[vialIndex].newAntigen = /** @type{PrescribedSubstance} */ {
                id: '',
                name: '',
                dilution: '',
                dosage: '',
                endPoint: '',
                maintPotency: '',
                potencyUnit: null,
                maintVolume: '',
                concPotency: '',
            };
        };

        /**
         * @param {Integer} vialIndex
         * @param {Integer} substanceIndex
         *
         * Alters the prescription by removing substance number *substanceIndex* from vial number *vialIndex*
         */
        this.$scope.vialSubstanceRemovalHandler = (vialIndex, substanceIndex) => {
            this.$scope.prescribedVials[vialIndex].substances.splice(substanceIndex, 1);
            this._loadAvailableSubstances();
            this._validateSubstanceFields();
            this._updateVialVolume(vialIndex);
        };

        this.$scope.onSubstanceDraggedOutHandler = (vialIndex, substanceIndex) => {
            this._isSubstanceInDrag = true;
            this._migratingVialIndex = vialIndex;
            this._migratingSubstanceIndex = substanceIndex;
        };

        this.$scope.onSubstanceDroppedInHandler = (vialIndex) => {

            if (this._isSubstanceInDrag) {
                if (vialIndex === this._migratingVialIndex) {
                    this._isSubstanceInDrag = false;
                    this._migratingVialIndex = undefined;
                    this._migratingSubstanceIndex = undefined;
                    return;
                }

                let fromVial = this.$scope.prescribedVials[this._migratingVialIndex],
                    toVial = this.$scope.prescribedVials[vialIndex];

                // BEGIN ATOMIC OPERATION :: these activities are all-or-nothing; do it now! and do it uninterupted
                toVial.containsAntigen = true;

                toVial.substances.push(
                    (fromVial.substances.splice(this._migratingSubstanceIndex, 1))[0]);

                this._loadAvailableSubstances();
                this._sortChemicalsInVial(vialIndex);
                this._sortChemicalsInVial(this._migratingVialIndex);
                this._validateSubstanceFields();
                this._updateVialVolume(vialIndex);
                this._updateVialVolume(this._migratingVialIndex);

                this._isSubstanceInDrag = false;
                this._migratingVialIndex = undefined;
                this._migratingSubstanceIndex = undefined;

                // END ATOMIC OPEATION
            }
            else  {
                console.error("We're not expecting any incoming substancs! Geaux away!");
            }

        };

        /**
         * @param {Integer} substanceIndex
         *  zero-based sequential index of the substance relative to vial (whose index = fmVialIndex).
         * @param {Integer} srcVialIndex
         *  zero-based sequential index of the vial relative to the prescription - specifies which vial the
         *  substance currently inhabits.
         * @param {Integer} destVialIndex
         *  zero-based sequential index of the vial relative to the prescription - specifies the destination vial
         *  to which we're transferring the substance.
         */
        this.$scope.doSubstanceVialXfer = (substanceIndex, srcVialIndex, destVialIndex) => {
            let
            /** {VialDm} */fromVial = this.$scope.prescribedVials[srcVialIndex],
            /** {VialDm} */toVial = this.$scope.prescribedVials[destVialIndex];

            // BEGIN ATOMIC OPERATION :: these activities are all-or-nothing; do it now! and do it uninterupted
            toVial.containsAntigen = true;

            toVial.substances.push(
                (fromVial.substances.splice(substanceIndex, 1))[0]);

            this._loadAvailableSubstances();
            this._sortChemicalsInVial(srcVialIndex);
            this._sortChemicalsInVial(destVialIndex);
            this._validateSubstanceFields();
            this._updateVialVolume(srcVialIndex);
            this._updateVialVolume(destVialIndex);
            this._saveUpdatedRxAux(false, false)
                .then(() => this.initData(this.$scope.prescriptionHref, this.pscript));
            // END ATOMIC OPERATION
        };

        /**
         * @param {Integer} vialIndex
         * Alters the prescription by diluting the mixture inside vial number *vialIndex* by 1 unit.
         */
        this.$scope.diluteVialHandler = (vialIndex) => {
            this._alterVialDilution(vialIndex, 1);
            this._validateSubstanceFields();
        };
        /**
         * @param {Integer} vialIndex
         * Alters the prescription by concentrating the mixture inside vial number *vialIndex* by 1 unit.
         */
        this.$scope.advanceVialHandler = (vialIndex) => {
            this._alterVialDilution(vialIndex, -1);
            this._validateSubstanceFields();
        };


        this.$scope.submitToHistoricRecords = () => {
            // When submitting a new prescription for historical record, set the fulfill flag to false, save the
            // changes, and approve the prescription.
            this.pscript.fulfill = false;
            this._approvePrescription();
        };

        // Approve this RX. This predicate initiates approval action.
        this.$scope.approveRx =() => {
            this._approvePrescription();
        };

        // Approve this RX-mix. This predicate initiates mix-approval action.
        this.$scope.approveRxMix =() => this._approveRxMix();

        // Approve this RX. This predicate puts the RX in position to be approved.
        this.$scope.submitForApproval =() => {
            this.pscript.fulfill = true;
            return this._saveUpdatedRx(true)
                .then(/**onSuccess*/ () => (this.$scope.onExitCallback ? this.$scope.onExitCallback() : angular.noop()));
        };

        this.$scope.editPrescription = () => {
            if (this.$scope.allowUpdates)
                this.$scope.isEditable = true;
        };

        /**
         *
         * @param {Array.<String>} missingFieldNames
         * a collection of the names of the data fields which were omitted. The names should correspond to labels
         * visible in the UI.
         */
        this.$scope.onIncompleteSubstanceSave = (missingFieldNames) =>
            this._showIncompleteSubstanceModal(missingFieldNames);

        this.$scope.isTreatmentConfigDisabled = (index) => this._isTreatmentConfigDisabled(index);

        this.$scope.onTreatmentConfigChange = (i) => {
            if (this.$scope.selectedTreatmentConfig.every(m => m === null)) {
                return;
            }

            this._saveUpdatedRxAux(false, false)
                .then(() => this.initData(this.$scope.prescriptionHref, this.pscript))
                .catch(() => {
                    this.$scope.selectedTreatmentConfig[i] = null;
                });
        };

        this.$scope.setOrderedByProvider = (provider) => {
            if (provider && (!this.$scope.orderedByProvider || provider.id !== this.$scope.orderedByProvider.id)) {
                this.$scope.orderedByProvider = provider;
                this._saveUpdatedRxAux(false, false)
                    .then(() => this.initData(this.$scope.prescriptionHref, this.pscript));
            }
        };

        this.$scope.selectPanel = (panel) => {
            if (panel && (!this.$scope.selectedPanel || panel.id !== this.$scope.selectedPanel.id)) {

                this.$scope.selectedPanel = panel;

                this._saveUpdatedRxAux(false, false)
                    .then(() => this.initData(this.$scope.prescriptionHref, this.pscript))
                    .catch(() => {
                        this.$scope.selectedPanel = null;
                        this.$scope.$digest();
                    });
            }
        };

        this.$scope.selectBoard = (board) => {
            if (board && (!this.$scope.selectedBoard || board.id !== this.$scope.selectedBoard.id)) {

                this.$scope.selectedBoard = board;
                let associatedPanel = this.$scope.panels.find(m => m.id === board.panel.id);
                this.$scope.selectedPanel = associatedPanel;

                this._saveUpdatedRxAux(false, false)
                    .then(() => this.initData(this.$scope.prescriptionHref, this.pscript))
                    .catch(() => {
                        this.$scope.selectedPanel = null;
                        this.$scope.selectedBoard = null;
                        this.$scope.$digest();
                    });
            }
        };

        this.$scope.toggleMixExternal = () => {
            this.pscript.mixExternal = this.$scope.mixExternal;
        }

        this.$scope.externalMixerChanged = () => {
            this.pscript.externalMixer = this.$scope.externalMixer;
        }

        this.$scope.addSlitEscalationVials = () => {
            this._saveUpdatedRxAux(true, false)
                .then(() => this.initData(this.$scope.prescriptionHref, this.pscript));
        };

        this.$scope.canRebuildPrescription = () => {
            return this.$scope.isApproval
                && this.$scope.allowUpdates
                && !this.$scope.cancelledBy
                && this.pscript
                && this.pscript.canRebuild;
        };

        this.$scope.rebuildPrescription = () => {
            this.$scope.isLoading = true;
            return this.prescriptionService.rebuild(this.pscript)
                .then((pscript) => {
                    /* initData will set isLoading to false once complete */
                    return this.initData(this.$scope.prescriptionHref, pscript);
                })
                .catch(() => { this.$scope.isLoading = false; });
        };

        /**
         * For basic prescription or vial changes that don't include any substance changes
         */
        this.$scope.saveRxChanges = () => {
            return this._saveUpdatedRxAux(false, false)
                .then(() => this.initData(this.$scope.prescriptionHref, this.pscript));
        };

        this.$scope.isSaveDisabled = () => {
            // Still initializing?
            if ($scope.isLoading)
                return true;

            // Saving?
            if ($scope.isSaving)
                return true;

            // Have a problem?
            if ($scope.selectedTreatmentConfig.every(m => m === null) || $scope.isDilutionOutOfRange || $scope.haveVolumeError)
                return true;

            // Can't save if a dosing schedule "Select..." prompt is showing.
            if ($scope.selectedTreatmentConfig.find(c => c && c.id === ''))
                return true;

            if (!$scope.skipPanel && !($scope.selectedPanel || $scope.selectedBoard))
                return true;

            return this.$scope.isClassical && this.$scope.prescribedVials.some((vial) => {
                return vial.substances.some((sub) => sub._isAntigen && !(sub.maintPotency && sub.maintVolume && sub.concPotency && sub.dosage));
            });
        };

        this.initScope();
        this.initData(this.$scope.prescriptionHref);
    }

    initScope() {
        this.$scope.patient = { name: '', chartNum: '', dayOfBirth: '' };
        this.$scope.orderedBy = { name: '', actionDateTime: '' };
        this.$scope.orderedByProvider = undefined;
        this.$scope.prescription = { type: '' };
        this.$scope.notes = [];
        this.$scope.prescribedVials = [];

        /**
         * @type{Array.<SubstanceModelObject>}
         *
         * Antigens which are currently available for addition to the prescription. As per business logic rules,
         * a particular antigen may only appear in a prescription once. Hence, any antigen listed by the UI as part of
         * the current prescription, should not exist in this list!
         */
        this.$scope.availableAntigens = [];

        /**
         * @type{Map.<String, Array.<SubstanceModelObject>>}
         *
         * A diluent chemical may appear once per vial in a prescription. Hence, we'll need a list foreach vial in the
         * RX. Like #$scope.availableAntigens, we're tracking serialized models of the substances. Unlike Antigens, we
         * need to track all of the vials at once. Hence, the structure will be a Map whose keys are the
         * vialIndex strings used by the scope. Each value in the map will be a list of diluting agents available to
         * for each vial.
         */
        this.$scope.availableDilutants = new Map();

        /**
         * @type{Object.<string, {potencyUnit: string, potency: number, formulation: string}[]}
         * key'd by substance ID to sorted array of formulation description objects
         */
        this.$scope.availableConcentrates = undefined;
    }

    /**
     * (Re)initialize the view.
     * @param prescriptionHref href of Prescription to load
     * @param pscript optional Prescription already available (prescriptionHref not used)
     */
    async initData(prescriptionHref, pscript) {
        this.$scope.isLoading = true;

        /*
         * Initialize $scope with empty data until it can be async loaded
         */
        this.$scope.approvalNote = '';
        this.$scope.allowUpdates = true;
        this.$scope.approvedBy = undefined;
        this.$scope.mixApprovedBy = undefined;
        this.$scope.cancelledBy = undefined;
        this.$scope.isDoctor = this.userService.isDoctorUser(this.$scope.user);

        this.$scope.isDilutionOutOfRange = false; // When true, the VM contains an invalid dilution level.

        /*
         * Load data from server, if wasn't passed in.
         */
        if (pscript) {
            this.pscript = pscript;
        }
        else {
            if (this.$scope.isApproval && !this.$scope.isManualRxCase)
                this.pscript = await this.prescriptionService.getForApproval({href: prescriptionHref});
            else
                this.pscript = await this.prescriptionService.getUncached({href: prescriptionHref});
            pscript = this.pscript;
        }

        this.$scope.mixExternal = this.pscript.mixExternal;
        this.$scope.externalMixer = this.pscript.externalMixer;
        this.$scope.isEditable = this.$scope.isApproval && !this.pscript.cancelledBy;
        this.$scope.isCancelled = !!this.pscript.cancelledBy;
        this.$scope.isVialEdit = this.pscript.vials.some(m => m.reason && m.reason !== 'NEW') && !this.pscript.preparative;

        let orderedBy = this.$scope.orderedBy = await this.userService.populateUserAction(pscript.orderedBy);

        let patient = this.$scope.patient;
        if (!(patient && patient.id === pscript.patient.id)) {
            patient = this.$scope.patient = await this.patientService.get(pscript.patient);
            let person = patient.person;
            patient.name = person.givenName + ' ' + person.middleName + ' ' + person.familyName;
        }

        // Don't show classical dilution vials - only the maintenance (red) vial.
        this.$scope.isClassical = (this.pscript.diluteTo != null);
        this.$scope.targetDilution = null;
        if (this.$scope.isClassical) {
            let minDilution = pscript.treatmentConfigs.reduce((result, current) => Math.min(result, current.dilution), 100);
            pscript.vials = pscript.vials.filter(v => v.classicalDilution === ClassicalDilutions[minDilution].color);
            this.$scope.targetDilution = ClassicalDilution[this.pscript.diluteTo];
        }
        if (this.$scope.isApproval && pscript.treatmentVials) {
            this.$scope.mixInProgress = true;
            this.$scope.prescribedVials = pscript.vials.filter(rxVial => !pscript.treatmentVials.find(tVial => tVial.barcode == rxVial.barcode));
        }
        else {
            this.$scope.mixInProgress = false;
            this.$scope.prescribedVials = pscript.vials;
        }

        this.$scope.hasChangeRequest = angular.isObject(pscript.changeRequestBy);
        this.$scope.isDueToRecall =
            (this.$scope.isApproval &&
             pscript.vials.filter(vial => vial.reason === this.PrescriptionReason.RECALLED).length > 0);

        if (angular.isDefined(pscript.approvedBy)) {
            this.$scope.allowUpdates = false;
            this.$scope.approvedBy = await this.userService.populateUserAction(pscript.approvedBy);
        }

        if (angular.isDefined(pscript.mixApprovedBy)) {
            this.$scope.mixApprovedBy = await this.userService.populateUserAction(pscript.mixApprovedBy);
        }

        if (angular.isDefined(pscript.cancelledBy)) {
            this.$scope.cancelledBy = await this.userService.populateUserAction(pscript.cancelledBy);
        }

        this.$scope.prescription.treatmentType = pscript.treatmentType;

        /* Load displayable information from substances into the scope's view-model (pscript.vials.substances).
         * Load TreatmentConfigs and Panels */
        await Promise.all([this._loadVialSubstances(), this._loadPanels(), this._loadBoards()]);

        await this._loadTreatmentConfigs();
        this.$scope.mayAddSlitEscalation = this._mayAddSlitEscalation();

        // Need the inventory of concentrates if there's a classical-style vial
        let concentratePromise = this._loadAvailableConcentrates();

        // Load availableDilutants, availableAntigen scope lists (required by Add-Substance behaviors)
        if (this.$scope.isEditable) {
            await this._loadAvailableSubstances();
        }

        this.$scope.notes = [];
        this.addActionNote('Ordered', orderedBy, 1);
        this.addActionNote('Approved', pscript.approvedBy, 100);
        this.addActionNote('Change Request', pscript.changeRequestBy, 100);
        this.addActionNote('Mixed', pscript.mixedBy, 100);
        this.addActionNote('Mix Approved', pscript.mixApprovedBy, 100);
        this.addActionNote('Cancelled', pscript.cancelledBy, 101);
        pscript.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 0));

        this.$scope.skipPanel = this.$scope.prescribedVials.filter(v => v.containsAntigen).every(v => v.reason === 'DILUTE_VIAL') && pscript.panel;
        this.$scope.showPanelSelect = !this.$scope.skipPanel && this.$scope.isClassical && !this.$scope.hasTraditionalArrangement;
        this.$scope.showBoardSelect = !this.$scope.skipPanel && !this.$scope.showPanelSelect;

        this.$scope.$apply(() => {this.$scope.isDilutionOutOfRange = !(this._areAllDilutionsValid());});
        await this._loadProviders();

        await concentratePromise;

        this.$scope.isLoading = false;
        this.$scope.$digest();
    }


    /** May request SLIT escalation? */
    _mayAddSlitEscalation() {
        // Is editing and current TreatmentConfig allows for SLIT Escalation?
        if (this.$scope.isApproval && this.$scope.selectedTreatmentConfig && this.$scope.selectedTreatmentConfig[0] && this.$scope.selectedTreatmentConfig[0].slit && this.$scope.selectedTreatmentConfig[0].slit.fillVolumeEsc) {
            // Make sure there's no SLIT Escalation or TreatmentVial dilution already in the prescription
            for (let vial of this.pscript.vials)
                for (let ps of vial.substances)
                    if (ps.substanceVialId || ps.substanceTreatmentVial)
                        return false;

            return true;
        }
        else {
            return false;
        }
    }

    /**
     * This is where we'll initially receive word the UI's has a chemical with an invalid dilution value.
     * This needs to know ISO:
     * 1. locking down the save-svc
     * 2. alerting the view so that it can display the form validation error UI.
     * Key take away: the View is reading/watching * scope.isDilutionOutOfRange *
     * look at the attrib *is-dilution-out-of-range* on apr-vial-table, that's our flag!
     *
     * @private
     */
    _validateSubstanceFields() {
        this.$scope.isDilutionOutOfRange = !(this._areAllDilutionsValid());
    }

    /**
     * @private
     * @param {Boolean} addScitEscalation : update and request SLIT escalation vials to be created?
     * @param {Boolean} saveNote : save the current note as an other action?
     * @returns {Promise<Prescription>}
     */
    async _saveUpdatedRxAux(addSlitEscalation, saveNote) {
        this.$scope.isDilutionOutOfRange = false;
        this.pscript.orderedBy.user = this.$scope.orderedByProvider ? { id: this.$scope.orderedByProvider.id, href: this.$scope.orderedByProvider.href } : this.pscript.orderedBy.user;
        this.pscript.panel = this.$scope.selectedPanel ? { id: this.$scope.selectedPanel.id } : null;
        this.pscript.mixingBoard = this.$scope.selectedBoard ? { id: this.$scope.selectedBoard.id } : null;

        if (this.$scope.selectedTreatmentConfig) {
            this.pscript.treatmentConfigs = this.$scope.selectedTreatmentConfig
                .map((tc,i) => {
                    return tc && tc.id ? { treatmentConfig: { id: tc.id }, dilution: i } : null;
                })
                .filter(tc => tc != null);

            if (this.$scope.isClassical) {
                let maxDilution = this.pscript.treatmentConfigs.reduce((result, current) => Math.max(result,current.dilution), 0);
                this.pscript.diluteTo = this.$scope.isVialEdit ? this.pscript.diluteTo : ClassicalDilutions[maxDilution].color;
            }
            else {
                this.pscript.diluteTo = null;
            }
        }

        for (let aVial of this.pscript.vials) {
            for (let aSubstance of aVial.substances) {
                // ClientSide to RemoteService namespace shift
                aSubstance.prevEndPoint = aSubstance.endPoint;
            }
        }

        let updatedRx;
        if (addSlitEscalation) {
            updatedRx = await this.prescriptionService.updateAndRequestSlitEscalation(this.pscript);
        }
        else {
            if (saveNote && this.$scope.approvalNote) {
                let otherAction = { note: this.$scope.approvalNote };
                this.pscript.otherActions.push(otherAction);
            }

            updatedRx = await this.prescriptionService.update(this.pscript);
        }

        this.pscript = updatedRx;
        return this.pscript;
    }

    /**
     * @private
     * @param {Boolean} saveNote : save the current note as an other action?
     *
     * Persists changes to the current prescription.
     *
     * @returns {Promise.<{Void}>}
     *  Promise which delivers nothing, but expresses a state by its existence (the instance members are altered).
     */
    _saveUpdatedRx(saveNote) {
        if (this._areAllDilutionsValid()) {
            return this._saveUpdatedRxAux(false, saveNote);
        }
        else {
            this.$scope.isDilutionOutOfRange = true;
            return this.$q.reject(PrescriptionApprovalController.OP_STATE_ERROR_DILUTION_VIOLATION);
        }
    }

    /**
     * Establishes the scope's treatmentConfigs state.
     * @private
     */
    async _loadTreatmentConfigs() {
        const needSelectionConfig = { id: '', name: 'Select...' };

        if (!this.$scope.treatmentConfigs) {
            // Only load active treatmentConfigs or the treatmentConfig attached to the prescription
            let treatmentConfigList = await this.treatmentConfigService.getAtPractice(this.$scope.practice);
            this.$scope.treatmentConfigs = treatmentConfigList.list.filter(config => {
                return config.treatmentType === this.pscript.treatmentType && config.active;
            });
        }

        this.$scope.selectedTreatmentConfig = this.$scope.isClassical ? [null,null,null,null,null] : [null];
        this.pscript.treatmentConfigs.map((ref,idx) => {
            if (ref == null) {
                this.$scope.selectedTreatmentConfig[idx] = needSelectionConfig;
            }
            else {
                let treatmentConfig = this.$scope.treatmentConfigs.find(tc => tc.id === ref.treatmentConfig.id);
                if (treatmentConfig) {
                    this.$scope.selectedTreatmentConfig[ref.dilution] = treatmentConfig.active || !this.$scope.isEditable ? treatmentConfig : needSelectionConfig;
                }
            }
        });
    }

        /**
     * Establishes the scope's panels state.
     * @private
     */
    async _loadPanels() {
        if (!this.$scope.panels) {
            let panelDto = await this.panelService.getActiveAtPractice(this.$scope.practice);
            this.$scope.panels = panelDto.list.filter(m => m.type === 'MIXING');
        }

        this.$scope.selectedPanel = this.$scope.panels.find(m => this.pscript.panel && m.id === this.pscript.panel.id);
    }

    /**
     * Establishes the scope's boards state.
     * @private
     */
    async _loadBoards() {
        if (!this.$scope.boards) {
            let boards = await this.boardService.getAtPractice(this.$scope.practice, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS)
            this.$scope.boards = boards.list.slice();
        }

        let selectedBoard = this.$scope.boards.find(m => this.pscript.mixingBoard && m.id === this.pscript.mixingBoard.id);
        if (!this.$scope.selectedBoard || !selectedBoard || this.$scope.selectedBoard.id !== selectedBoard.id) {
            this.$scope.selectedBoard = selectedBoard;
        }

        let traditionalBoards = this.$scope.boards.filter(m => m.arrangement === this.BoardArrangement.TRADITIONAL);
        this.$scope.hasTraditionalArrangement = traditionalBoards.length > 0;
        this.$scope.boards = this.$scope.hasTraditionalArrangement ? traditionalBoards : this.$scope.boards;
    }

    /**
     * Establishes the scope's providers state.
     * @private
     */
    async _loadProviders() {
        if (!this.$scope.providers) {
            this.$scope.providers = (await this.userService.getUsers(this.$scope.practice, 'DOCTOR')).list;
        }

        if (this.pscript.orderedBy) {
            this.$scope.orderedByProvider = this.$scope.providers.find(p => p.id === this.pscript.orderedBy.user.id);
        }
    }

    /**
     * @param {integer} vialIndex
     *  refers to the index of the vial whose substances we need sorted
     *
     * @private
     */
    _sortChemicalsInVial(vialIndex) {

        let
            /** @type{Angular.filter} */sortingFilter = this.$filter("orderBy");

        // get ng's OrderBy filter to do the sorting heavy lifting
        this.pscript.vials[vialIndex].substances = sortingFilter(
            this.pscript.vials[vialIndex].substances,
            (aSubstance) => aSubstance._isControl,
            false);
    }

    /**
     * Asynchronous data model establishment; the RX object's *vials* child's *substances* requires active data loading
     * to assemble the complete View-Model state.
     *
     * Updates the state of pscript.vials[x].substances[y] for all x and y. The raw state of the
     * pscript.vials[x].substances[y] when delivered does not contain *name* fields. The individual substances' names
     * must be explicitly requested.
     *
     * @private
     * @returns {Promise.<{undefined}>}
     */
    _loadVialSubstances() {

        return this.$q.all(this.pscript.vials.map(anRxVial => {
            anRxVial.newAntigen = { dosage: ""};
            anRxVial.newDiluent = { dosage: ""};

            return this.$q.all(anRxVial.substances.map(anRxVialSubstance => {

                return this.substanceService.get(anRxVialSubstance.substance, this.pscript)
                    .then(substance => {
                        anRxVialSubstance.name = substance.name; //may be replaced below

                        if (anRxVialSubstance.substanceVialId) {
                            anRxVialSubstance.name = 'Vial ' +
                                this.pscript.vials.find((v) => v.id === anRxVialSubstance.substanceVialId).name;
                            anRxVial._isEscalationVial = true;
                            anRxVialSubstance._isEscalation = true;
                        }
                        else if (anRxVialSubstance.substanceTreatmentVial) {
                            this.treatmentVialService.get(anRxVialSubstance.substanceTreatmentVial)
                                .then(refTreatVial => anRxVialSubstance.name = refTreatVial.name);
                            anRxVial._isDilutionVial = true;
                            anRxVialSubstance._isVialDilution = true;
                        }

                        anRxVialSubstance._isManagedDiluent = (anRxVialSubstance.substance.id === this.pscript.config.diluentSubstance.id);

                        return this.substanceCategoryService.get(substance.category, this.pscript)
                            .then(substanceCategory => {
                                anRxVialSubstance._isAntigen = substanceCategory._isAntigen;
                                anRxVialSubstance._isControl = substanceCategory._isNegativeControl;
                                anRxVialSubstance._isOtherVial = substanceCategory._isVial;
                                if (anRxVialSubstance._isAntigen) {
                                    anRxVialSubstance.endPoint = anRxVialSubstance.prevEndPoint;
                                }
                            });

                    });
            }))
        }))
        .then(() => {
            for (let iVial in this.$scope.prescribedVials) {
                this._sortChemicalsInVial(iVial);
                this._updateVialVolume(iVial);
            }
        });
    }

    /**
     * Asynchronous data model establishment, prepares this instance's lists of chemicals which can be introduced
     * to a treatment vial.
     *
     * @private
     */
    async _loadAvailableSubstances() {
        if (!(this.pscriptPanel && this.pscriptPanel.id === this.pscript.panel.id)) {
            this.pscriptPanel = await this.panelService.get(this.pscript.panel);
        }

        this.$scope.availableAntigens.length = 0;

        this.$scope.availableDilutants = new Map();
        for (let iVial = 0; iVial < this.pscript.vials.length; iVial++) {
            this.$scope.availableDilutants.set(iVial, []);// initializing Map with empty arrays
        }

        for(let aPanelSubstance of this.pscriptPanel.substances) {
            let aSubstance = aPanelSubstance.substance;
            let category = aSubstance._dto.category._dto;
            aSubstance._isAntigen = category._isAntigen;
            aSubstance._isControl = category._isNegativeControl;
            aSubstance._isOtherVial = false;
            aSubstance.name = aSubstance._dto.name;
            aSubstance._isManagedDiluent = (aSubstance.id === this.pscript.config.diluentSubstance.id);

            if (aSubstance._isControl) {

                for (let iVial = 0; iVial < this.pscript.vials.length; iVial++) {
                    if (false == this._isSubstanceInVial(aSubstance, iVial)) {
                        this.$scope.availableDilutants.get(iVial).push(aSubstance);
                    }
                }

            }
            else if (category._isAntigen) {
                if (false == this._isSubstanceInThisPrescription(aSubstance)) {
                    this.$scope.availableAntigens.push(aSubstance);
                }
            }

        }

        // Any negative control defined by the system may be used as a diluent.
        // Be sure not to add one that the previous loop already added though.
        let negControls = await this.substanceService.getByType(this.$scope.practice, this.substanceService.NEGATIVE_CONTROL);

        for (let aSubstance of negControls) {
            aSubstance._isAntigen = false;
            aSubstance._isControl = true;
            aSubstance._isOtherVial = false;

            for (let iVial = 0; iVial < this.pscript.vials.length; iVial++) {
                if (false == this._isSubstanceInVial(aSubstance, iVial)) {
                    let availableToVialArray = this.$scope.availableDilutants.get(iVial);
                    if (!this._isDTOInArray(availableToVialArray, aSubstance)) {
                        availableToVialArray.push(aSubstance);
                    }
                }
            }
        }

        this.$scope.$digest();
    }

    _isTreatmentConfigDisabled(index) {
        if (this.$scope.isVialEdit) {
            return false;
        }
        else if (this.$scope.hasTraditionalArrangement) {
            let populatedIndex = this.$scope.selectedTreatmentConfig.findIndex(m => m !== null);
            return populatedIndex >= 0 && populatedIndex !== index;
        }
        else {
            let tc = this.$scope.selectedTreatmentConfig[index-1];
            return index > 0 && (tc == null || tc != null && tc.id === '');
        }
    }

    async _loadAvailableConcentrates() {
        if (!this.$scope.isClassical) {
            return;
        }

        this.$scope.availableConcentrates = {};
        let concentratesMap = new Map();
        let dilution = this.$scope.selectedTreatmentConfig.findIndex(m => m !== null);

        if (this.$scope.hasTraditionalArrangement && dilution > 0) {
            /* Get tray vials for board traditional (non red vial) prescription */
            let traditionalTrayVials = await this.boardService.getTraditionalTrayVials(this.pscript);

            let q = [];
            let trayVials = traditionalTrayVials.list.filter(m => m.dilution === dilution)
            for(let trayVial of trayVials) {
                /* The actual concentrate may be embedded in traditionalTrayVials resulting in no actual server request here */
                q.push(this.concentrateService.get(trayVial.concentrate, traditionalTrayVials).then(concentrate => {
                    /* It's ok if the concentrate is depleted since that doesn't mean the tray vial is depleted */
                    if (concentrate.status === this.ServiceStatus.IN_SERVICE ||
                        concentrate.status === this.ServiceStatus.INVENTORY ||
                        concentrate.status === this.ServiceStatus.DEPLETED) {
                        this._addConcentrateToMap(concentrate, concentratesMap);
                    }
                }));
            }
            await Promise.all(q);
        }
        else {
            let officeRef = this.$scope.selectedBoard ? this.$scope.selectedBoard.office : this.$scope.office;
            let office = await this.officeService.get(officeRef);
            let concentrates = await this.concentrateService.getInServiceAtOffice(office);
            for (let conc of concentrates.list) {
                this._addConcentrateToMap(conc, concentratesMap);
            }
        }

        this._buildAvailableConcentrates(concentratesMap);

        /* Set the selected concentration for each substance */
        for (let i = 0; i < this.$scope.prescribedVials.length; i++) {
            let vial = this.$scope.prescribedVials[i];

            /* Ignore empty vials or diluted vials */
            if (!vial.containsAntigen || vial.reason == 'DILUTE_VIAL') {
                continue;
            }

            let needsVolumeUpdate = false;
            for (let rxSubstance of vial.substances) {
                /* Ignore non antigens (preservatives, diluents, etc) */
                if (!rxSubstance._isAntigen) {
                    continue;
                }

                const substanceConcentrates = this.$scope.availableConcentrates[rxSubstance.substance.id];

                if (substanceConcentrates && rxSubstance.concPotency && rxSubstance.potencyUnit) {
                    rxSubstance._concentration = substanceConcentrates.find(c => c.potencyUnit === rxSubstance.potencyUnit && c.potency == rxSubstance.concPotency);
                }
                else if (rxSubstance.dosage) {
                    /* Reset concPotency and dosage because no matching concentrates are available */
                    rxSubstance.concPotency = 0;
                    rxSubstance.dosage = 0;
                    needsVolumeUpdate = true;
                }
            }

            if (needsVolumeUpdate) {
                this._updateVialVolume(i);
            }
        }
    }

    _addConcentrateToMap(concentrate, concentratesMap) {

        // Don't allow 'mL' potencyUnit
        if (concentrate.potencyUnit === 'mL') {
            return;
        }

        const key = concentrate.substance.id + concentrate.potencyUnit + concentrate.potency;
        if (!concentratesMap.has(key)) {
            concentratesMap.set(key, {
                substance: concentrate.substance,
                potencyUnit: concentrate.potencyUnit,
                potency: concentrate.potency,
                formulation: this.$filter('agFormulation')(concentrate)
            });
        }
    }

    _buildAvailableConcentrates(concentratesMap) {
        if (concentratesMap.size === 0) {
            /* Create empty availableConcentrates map to signal that the data is loaded */
            this.$scope.availableConcentrates = {0: 0};
            return;
        }

        // @type{Object.<string, {potencyUnit: string, potency: number, formulation: string}[]}
        this.$scope.availableConcentrates = {};
        for (let conc of Array.from(concentratesMap.values())) {
            if (!this.$scope.availableConcentrates[conc.substance.id]) {
                this.$scope.availableConcentrates[conc.substance.id] = [];
            }

            this.$scope.availableConcentrates[conc.substance.id].push(conc);
        }
    }

    /**
     * Check for the presence of a DTO in an array using identity comparison.
     * @param array
     * @param dto
     * @returns {boolean} true if found, false if not.
     * @private
     */
    _isDTOInArray(array, dto) {
        for (let element of array)
            if (element.id === dto.id)
                return true;
        return false;
    }

    /**
     * @param {PanelSubstanceObject} argPanelSubstance
     * The substance whose presence or absence in the current subject prescription we want to ascertain.
     * Note this species of the operand 'substance' object is not of the same species as the values
     * through which we'll be iterating; the arg substance comes from the PanelService, whereas the
     * substance representations attached the RX.vials.substances come the SubstanceService. Origin
     * stories aside, the types structurally differ in the PanelSubstance objects "id" field is a direct
     * child, whereas SubstanceService substances keep their "id" inside their "substance" children.
     *
     * @returns {boolean}
     * True : when any vial in the subject prescription contains the operand substance
     * False : if the prescription is totally devoid of the chemical agent
     *
     * @private
     */
    _isSubstanceInThisPrescription(argPanelSubstance) {
        // strategy absent until proven proven present, bail on match right away.
        for (let aVial of this.pscript.vials) {
            for (let aSubstance of aVial.substances) {
                if (aSubstance.substance.id === argPanelSubstance.id)
                    return true;
            }
        }

        return false;
    }

    /**
     *
     * @param argPanelSubstance
     * The substance whose presence or absence in the current subject prescription we want to ascertain.
     * Note this species of the operand 'substance' object is not of the same species as the values
     * through which we'll be iterating; the arg substance comes from the PanelService, whereas the
     * substance representations attached the RX.vials.substances come the SubstanceService. Origin
     * stories aside, the types structurally differ in the PanelSubstance objects "id" field is a direct
     * child, whereas SubstanceService substances keep their "id" inside their "substance" children.
     *
     * @param vialIndex
     * @returns {boolean}
     * True : when the RX vial #[vialIndex] contains the operand substance
     * False : if the vial is totally devoid of the chemical agent
     *
     * @private
     */
    _isSubstanceInVial(argPanelSubstance, vialIndex) {
        let theVial = this.pscript.vials[vialIndex];

        for (let aSubstance of theVial.substances) {
            if (aSubstance.substance.id === argPanelSubstance.id)
                return true;
        }
        return false;
    }

    /**
     * @param {Number} dilution
     *
     * @returns {boolean}
     *  true : 0 <= dilution <= board-specified-maximum-dilution
     *  false : all other cases
     *
     * @private
     */
    _isDilutionValid(dilution) {
        return ((0 <= dilution ) && (dilution <= this.$scope.MAXIMUM_DILUTION));
    }

    /**
     *
     * @returns {boolean}
     *  true :
     *      when forAll v, where v is a vial in this instance's prescription,
     *          forAll u, where s is a non-control-substance in v,
     *          0 <= u <= board-specified-maximum-dilution
     *  false : all other cases
     *
     * @private
     */
    _areAllDilutionsValid() {

        // strategy: assume flawed until proven perfect
        for (let aVial of this.pscript.vials) {
            for (let aSubstance of aVial.substances) {
                if (false == this._isDilutionValid(aSubstance.dilution) ) {
                    return false;
                }
            }
        }
        // if any of the substances were out of bounds, we would not have reached this part of the routine scope.
        // we're here, and we're done; we're good!
        return true;
    }

    /**
     * @param {Integer} vialIndex : where 0 <= vialIndex < TOTAL_VIALS_PRESENT
     * @param {Integer} delta : negative values are considered decremental cases.
     *
     * Usage details: the predicate actively alters the VM. The effect will apply to ALL substances in the vial.
     *
     * When a call to this routine would violate the Board's dilution limits (in other words: over-dilution),
     * the VM will not be altered. Instead, the input-error-case scope flag will be raised. The View will know what do
     * in that situation.
     *
     * @private
     */
    _alterVialDilution(vialIndex, delta) {

        /** Logical treatment-vial configuration; it supports acquisition of operational data on the substances. */
        let vialCfg = this.pscript.vials[vialIndex];

        angular.forEach(vialCfg.substances, aSubstance => {
            if (!aSubstance._isAntigen) {// only antigens are eligible for dilution, skip.
                return;
            }
            aSubstance.dilution = (Number(aSubstance.dilution) + delta);
            if (aSubstance.dilution < 0) {
                aSubstance.dilution = 0;
            }
            else if (aSubstance.dilution > this.$scope.MAXIMUM_DILUTION) {
                aSubstance.dilution = this.$scope.MAXIMUM_DILUTION;
            }
        });

        this._updateVialAllButtons(vialCfg);
    }

    /**
     * Reaction to the user leaving this controller's UI content (AKA: leaving the page, navigating to a different UI
     * section).
     *
     * @override
     * The super class method #exitUiHandler calls this method
     * @protected
     */
    async _onSubSectionDeparture() {
        try {
            if (this.$scope.isEditable) {
                await this._saveUpdatedRxAux(false, true);
            }
        }
        catch(updateFail) {
            console.error(updateFail);
        }
    }

    /**
     * @private
     */
    async _approvePrescription() {

        this.$scope.isSaving = true;
        try {
            await this._saveUpdatedRx(false);
            this.pscript = await this.prescriptionService.approve(this.pscript, this.$scope.approvalNote);

            this.$scope.$apply((scope) => {
                if (scope.onExitCallback) {
                    scope.onExitCallback();
                }
            });
        }
        catch(error) {
            this.$scope.isSaving = false;
        }
    }

    /**
     * @private
     */
    async _approveRxMix() {
        this.pscript = await this.prescriptionService.approveMix(this.pscript, this.$scope.approvalNote);

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback();
            }
        });
    }

    /**
     *
     * @param {Array.<String>} missingFieldNames
     * a collection of the names of the data fields which were omitted. The names should correspond to labels
     * visible in the UI.
     */
    _showIncompleteSubstanceModal(missingFieldNames) {

        this.$uiModal.open({
            windowTopClass: 'warningModal',
            windowClass: 'mia-substance-params-error',
            size: 'md',
            template: require("./widgets/mia-substance-params-error.html"),
            css: require('./widgets/mia-substance-params-error.scss'),
            controller: function ($uibModalInstance, $scope, missingParams) {
                $scope.miaParams = missingParams;
                $scope.onAcknowledgement =() => $uibModalInstance.dismiss();
            },
            resolve : {
                "missingParams" : () => missingFieldNames
            }
        });

    };

    _getAvailableAntigenById(substanceId) {
        for (let anAntigen of this.$scope.availableAntigens) {
            if (anAntigen.id === substanceId) {
                return anAntigen;
            }
        }
    }

    _getAvailableDiluentByIdAndVial(substanceId, whichVial) {
        let diluentsAvailableToVial = this.$scope.availableDilutants.get(whichVial);
        for (let aDiluent of diluentsAvailableToVial) {
            if (aDiluent.id === substanceId) {
                return aDiluent;
            }
        }
    }

    /**
     * This predicate may be thought of as "reverse-marshalling". We're gathering deserialized components of the
     * object we need and marrying them up with any values furnished by the user and structuring them in the form
     * required by the remote API.
     *
     * @param dosage
     * @param isControl
     * @param dilution
     * @param endPoint
     * @param substanceId
     * @param vialIndex
     * @returns {{dosage: *, mixed: boolean, _isControl: *, name: *, substance: {href: *, id: *}}}
     * A serialized VialSubstance, as it would exist within a Vial object of a Prescription object acquired from the
     * PrescriptionService.
     *
     * @private
     */
    _createRxVialSubstance(dosage, isControl, dilution, endPoint, maintPotency, maintVolume, concPotency, potencyUnit, substanceId, vialIndex) {

        let
            theAvailableSubstance =
                ((isControl) ?
                    this._getAvailableDiluentByIdAndVial(substanceId, vialIndex) :
                    this._getAvailableAntigenById(substanceId)),

            substanceHateRef = {
                "href" : theAvailableSubstance.href,
                "id" : theAvailableSubstance.id
            },

            vialSubstance = {
                "dosage" : Number(dosage) || 0, // Needed by API Predicate
                "mixed" : false, // Along for the ride
                "_isAntigen" : !isControl, // Needed by View
                "_isControl" : isControl, // Needed by View
                "_isOtherVial" : false,
                "_isManagedDiluent" : (theAvailableSubstance.id === this.pscript.config.diluentSubstance.id),
                "name" : theAvailableSubstance.name, // Needed by View
                "substance" : substanceHateRef,
                "dilution" : Number(dilution),
                "endPoint" : Number(endPoint),
                "maintPotency": maintPotency,
                "maintVolume": maintVolume,
                "concPotency": concPotency,
                "potencyUnit": potencyUnit
            };

        return vialSubstance;
    }

    /**
     * Update flags as to whether or not the Advance All and Dilute All buttons should be enabled.
     * @param vial
     * @private
     */
    _updateVialAllButtons(vial) {
        let allAtConcentrate = true;
        let allFullDilution = true;

        for (let aSubstance of vial.substances) {
            if (aSubstance._isAntigen) {
                let dilution = aSubstance.dilution;
                if (dilution > 0)
                    allAtConcentrate = false;
                if (dilution < this.$scope.MAXIMUM_DILUTION)
                    allFullDilution = false;
                if (!allAtConcentrate && !allFullDilution)
                    break;
            }
        }

        vial._disableAdvanceAll = allAtConcentrate || this.$scope.isDilutionOutOfRange;
        vial._disableDiluteAll = allFullDilution || this.$scope.isDilutionOutOfRange;
    }

    /**
     * This routine measures the volume of the contents of the a vial and updates the scope
     * fields associated with the UI display of the vial subject.
     * It also adjusts the managed diluent, if it exists.
     *
     * @param {Integer} vialIndex
     *  identifies WHICH vial's contents require measurement
     * @private
     *
     */
    _updateVialVolume(vialIndex) {

        let vial = this.$scope.prescribedVials[vialIndex];
        let preservative = this.pscript.config.preservativeSubstance;
        let managedSubstance = this.pscript.config.diluentSubstance;

        vial.totalAntigenVolume = 0;
        vial.totalDiluentVolume = 0;
        vial.totalPreservativeVolume = 0;
        vial.preservativeName = undefined;

        for (let aSubstance of vial.substances ) {
            const dosage = Number(aSubstance.dosage) || 0;
            if (preservative && aSubstance.substance.id === preservative.id) {
                vial.preservativeName = aSubstance.name;
                vial.totalPreservativeVolume += dosage;
            }
            else if (aSubstance._isControl) {
                vial.totalDiluentVolume += dosage;
            }
            else {
                vial.totalAntigenVolume += dosage;
            }
        }

        vial.grandTotalVolume = vial.totalAntigenVolume + vial.totalDiluentVolume + vial.totalPreservativeVolume;

        // Adjust managed diluent
        let volumeAdjustment = this._roundTwoDecimals(vial.capacity - vial.grandTotalVolume);
        if (volumeAdjustment !== 0) {
            let managedVialSubstance = vial.substances.find(ps => ps.substance.id === managedSubstance.id);
            if (managedVialSubstance) {
                managedVialSubstance.dosage = this._roundTwoDecimals(managedVialSubstance.dosage + volumeAdjustment);
                if (managedVialSubstance.dosage < 0) {
                    volumeAdjustment -= managedVialSubstance.dosage;
                    managedVialSubstance.dosage = 0;
                }
                vial.totalDiluentVolume += volumeAdjustment;
                vial.grandTotalVolume += volumeAdjustment;
            }
        }

        vial.totalAntigenVolume = this._roundTwoDecimals(vial.totalAntigenVolume);
        vial.totalDiluentVolume = this._roundTwoDecimals(vial.totalDiluentVolume);
        vial.totalPreservativeVolume = this._roundTwoDecimals(vial.totalPreservativeVolume);
        vial.grandTotalVolume = this._roundTwoDecimals(vial.grandTotalVolume);
        vial._hasVolumeError = (vial.totalAntigenVolume > 0 && vial.capacity !== vial.grandTotalVolume);

        // Update global volume error flag
        this.$scope.haveVolumeError = (this.$scope.prescribedVials.find(v => v._hasVolumeError) !== undefined);
        this._updateVialAllButtons(vial);
    }

    /**
     * Round the given floating point value to two decimal places.
     * @param fpValue any number
     * @return {number} the number rounded to no more than two digits after the decimal point.
     * @private
     */
    _roundTwoDecimals(fpValue) {
        return Math.round(fpValue * 100) / 100;
    }
}
