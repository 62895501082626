'use strict';

import BaseMixingController from '../../base-mixing.controller'

export default class MixingPrepareVialsController extends BaseMixingController {
    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        this.boardService = $injector.get('boardService');
        this.panelService = $injector.get('panelService');

        this.prescriptionLoaded([this.TAB_PREPARE])
            .then(() => this._prepareData())
            .catch(ex => console.error(ex));
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Step to next antigen until have gone through all.
     * When all instructions have been given, then call super.nextStep() to advance to the next stage.
     *
     * @return Promise to updated Prescription
     * @override
     */
    async nextStep() {
        let $scope = this.$scope; //lazy programmer

        if ($scope.arrayCount < $scope.totalArrayCount) {
            $scope.arrayCount++;

            //Increase count and set counter on page
            this._moveToPosition(this.allergenPositionsIndex + 1);
            $scope.setDilutionHeader($scope.number);

            $scope.changeGrid();

        } else {
            return super.nextStep();
        }

    }

    _moveToPosition(position) {
        this.allergenPositionsIndex = position;

        let allergenPosition = this.allergenPositions[position];
        let item = this.$scope.allergens[allergenPosition];
        this.$scope.allergenPosition = allergenPosition + 1;
        this.$scope.count = item.allergen;
        this.$scope.allergenName = item.allergenName;
        this.$scope.number = item.dilution;
        this.$scope.text = "Insert Needle Into Vial - Draw " + item.dosage.toFixed(2) + " mL";
    }

    async _prepareData() {
        // If there is nothing to draw from the mixing board, then skip ahead.
        // This can happen when diluting an existing treatment vial.
        if (this.$scope.antigenCount === 0) {
            return this.nextStep();
        }

        this.$scope.antigenSubstances = [];
        this.$scope.haveConcentrate = false;
        this.$scope.haveAbove6 = false;
        this.initFooterNote('Prepare Vials');

        let board = this.$scope.board = await this.boardService.getWithNoVials(this.pscript.mixingBoard);
        let panel = await this.panelService.get(board.panel, board);

        //How many dilutions, starting with highest number and working down
        this.$scope.dilutions = [];   // How many dilutions of each substance

        for (let i = board.dilutionsCount; i > 0; i--) {
            this.$scope.dilutions.push(i);
        }
        // Variable to set how large a chunk of data should be.
        this.$scope.dataChunkSize = board.substancesPerTray;

        this.$scope.allergens = [];
        for (let i = 1; i <= board.substancesPerTray * board.trayCount; i++) {
            this.$scope.allergens.push({
                allergen: i
            });
        }

        // Build map of Substance to PrescribedSubstance in the prescription
        this.substanceMap = new Map();
        for (let vial of this.pscript.vials) {
            for (let psSubstance of vial.substances) {
                if (psSubstance.dosage > 0)
                    this.substanceMap.set(psSubstance.substance.id, psSubstance);
            }
        }

        this.$scope.totalSubstances = panel.substances.length;

        let allergenPosition = 1;
        for (let panelSubs of panel.substances) {
            let substance = panelSubs.substance._dto;

            if (!substance.category._dto._isAntigen)
                continue; // only antigens

            let ps = this.substanceMap.get(substance.id);
            if (!ps)
                continue; //not prescribed

            let dilution = ps.dilution;
            // skip all dilutions out of board's range
            if (dilution >= 0 && dilution <= board.dilutionsCount) {
                let allergen = {
                    substance: substance,
                    pos: panelSubs.mixPos,
                    dilution: dilution
                };
                this.$scope.antigenSubstances.push(allergen);

                this.$scope.allergens[panelSubs.mixPos] = {
                    allergen: panelSubs.mixPos + 1,
                    allergenName: substance.name,
                    dilution: dilution,
                    dosage: ps.dosage,
                    position: allergenPosition
                };
                allergenPosition++;

                if (dilution === 0)
                    this.$scope.haveConcentrate = true;
                else if (dilution > 6)
                    this.$scope.haveAbove6 = true;
            }
            else {
                console.error("Dilution for substance " + substance.name + " out of range at " + dilution);
            }
        }


        this.allergenPositions = [];

        // this.allergenPositions: store the locations of allergens indexes which have position values
        for (let i = 0; i < this.$scope.allergens.length; i++) {
            if (this.$scope.allergens[i].position > 0) {
                this.allergenPositions.push(i);
            }
        }

        this.$scope.arrayCount = 1;
        this.$scope.totalArrayCount = this.allergenPositions.length;

        //set the first allergen
        this._moveToPosition(0);

        // Function to break down data set into smaller chunks, for dynamic grid layout.
        let chunk = (arr, size) => {
            var newArr = [];
            for (var i = 0; i < arr.length; i += size) {
                newArr.push(arr.slice(i, i + size));
            }
            return newArr;
        };

        // Running the chunk function
        this.$scope.chunkedData = chunk(this.$scope.allergens, this.$scope.dataChunkSize);

        // Setting the amount of allergens
        this.$scope.totalAllergenCount = this.$scope.allergens.length;
        this.$scope.lgWidth = (44 * this.$scope.dataChunkSize + 30) + "px";
        this.$scope.smWidth = (17 * this.$scope.dataChunkSize) + "px";
        this.$scope.init();

        this.disableNext = false;
        this.disableExit = false;
        this.$scope.$digest();
    }

}
