"use strict";
import angular from "angular";

// package imports
import appointment from "./appointment-details";
import approvals from "./approvals";
import labelDetails from "./label-details";
import standardLayout from "./standard-layout";
import treatmentVialDetails from "./treatment-vial-details";

export default angular.module("pages.common", [
    appointment,
    approvals,
    labelDetails,
    standardLayout,
    treatmentVialDetails
]).name;
