'use strict';

import BaseService from './base.service.js';

export default class UserService extends BaseService {

    userNameCache = new Map();
    promiseCache = new Map();

    static $inject=['$location','serverAPI'];

    constructor($location, serverAPI) {
        super(serverAPI);
        this.$location = $location;
    }

    reset() {
        this.userNameCache.clear();
        this.promiseCache.clear();
    }

    /**
     * Authenticate / Login a user
     * credentials is a map containing 'email' and 'password'
     * @return Promise to new href to redirect to
     */
    login(credentials) {
        this.reset();
        return this.getGlobalLinks()
            .then(global => {
                return this.serverAPI.$http({
                    method: 'POST',
                    url: global.login,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function (obj) {
                        var str = [];
                        for (var p in obj)
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        return str.join("&");
                    },
                    data: {email: credentials.email, password: credentials.password}
                });
            })
            .then(response => {
                return response.headers['Location'];
            });
    }

    /**
     * Authenticate a user and return it, whether in a session or not and without starting one.<br/>
     * Useful to re-authenticate a user for any reason.
     *
     * @param email user email address (username)
     * @param password user's raw typed-in password
     * @return Promise to authenticated User DTO, or ServerError with 401 - Unauthorized
     */
    authenticate(email, password) {
        let fd = new FormData();
        fd.append('email', email);
        fd.append('password', password);

        return this.getGlobalLinks()
            .then(global => this.serverAPI.post(global.authenticateUser, {}, fd, [401,403]));
    }

    /**
     * Log current user out and, optionally, redirect back to login.
     *
     * @param options if is an object, redirect to login page with this defining the
     *          query-string parameters to pass, upon logout completion.
     * @return Promise that resolves when logout complete
     */
    logout(options) {
        this.reset();
        return this.getGlobalLinks()
            .then(global => this.serverAPI.post(global.logout))
            .then(response => {
                this.serverAPI.reset();
                if (options)
                    this.$location.path('/login').search(options);
                return response;
            });
    }

    /**
     * @return Promise to the User DTO of the logged-in user, reject upon Unauthorized.
     */
    getCurrent() {
        return this.getGlobalLinks()
            .then(global => this.serverAPI.get(global.sessionUser, undefined, undefined, [401,403]));
    }

    /**
     * Get all users for current practice
     * @returns {Promise<User.List>}
     */
    getAll() {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.get(globalLinks.users));
    }

    /**
     * Change the user's password.
     * @return Promise that resolves if successful, or rejects with a ServerError if not (probably old password didn't match)
     */
    changePassword(user, oldPassword, newPassword) {
        return this.serverAPI.put(user._links.changePassword, {
            oldPassword: oldPassword, newPassword: newPassword
        });
    }

    /**
     * send email to reset the user's password
     * @return Promise that resolves if successful, or rejects with a ServerError.
     *      The response is the ForgotPasswordResponse.
     *          Success is true, then emailFrom and emailRecipient is populated
     *          Success is false, then emailFrom and emailRecipient is null
     */
    forgotPassword(userEmail) {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.put(globalLinks.forgotPassword, {userEmail}));
    }

    /**
     * Given a UserAction DTO, add a 'name' field with the user's name.
     * Caches names as the same small set of users will likely be looked up repeatedly.
     * Browser cache will prevent it from hitting the server most of the time, but there's still extra overhead.
     *
     * Works like getUserName(), just different way of getting at the same information.
     *
     * @param userAction a UserAction
     * @return Promise to the same userAction, now with a 'name' field
     */
    populateUserAction(userAction) {
        if (angular.isObject(userAction) && !userAction.name) {
            return this.getUserName(userAction.user)
                .then(name => {
                    userAction.name = name;
                    return userAction;
                });
        }
        else {
            return this.resolved(userAction);
        }
    }

    /**
     * Get the display name for a User.
     * Caches names as the same small set of users will likely be looked up repeatedly.
     *
     * Works like populateUserAction(), just different way of getting at the same information.
     *
     * @param userRef a ReferenceDTO<User>
     * @return Promise to a name string
     */
    getUserName(userRef) {
        if (!userRef) {
            // No reference, return a blank name
            return this.resolved('');
        }

        let cachedValue = this.userNameCache.get(userRef.id);
        if (cachedValue) {
            return this.resolved(cachedValue);
        }

        let cachedPromise = this.promiseCache.get(userRef.id);
        if (cachedPromise) {
            return cachedPromise;
        }

        // Fetch from server, cache, and return result
        let promise = this.serverAPI.get(userRef.href).then(user => {
            let p = user.person;
            let name = p.givenName + ' ' + p.familyName;
            if (p.credential && p.credential.length)
                name = name + ', ' + p.credential;

            this.userNameCache.set(user.id, name);
            return name;
        });

        this.promiseCache.set(userRef.id, promise);
        return promise;
    }

    /**
     * GET list of users at this practice.
     * @param userRole if present, users with this role
     *
     * @return Promise to a User.List with only public User data.
     */
    getUsers(practice, userRole) {
        return this.serverAPI.get(practice._links.users, { userRole: userRole });
    }

    /**
     * POST Saves the security question
     */
    saveUserSetup(userSetupData) {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.post(globalLinks.userSetup, null, userSetupData ));
    }

    /**
     * authenticate the session with a token type and token
     * @param tokenType type of token to validate
     * @param token token value to validate
     * @returns Promise with no body.  will return http status 200 if success, 401 if unauthorized
     */
    authToken(tokenType, token) {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.post(globalLinks.tokenAuthentication, {"type" : tokenType, "token" : token }));
    }

    /**
     * GET a list of security questions for the user
     * @param user DTO
     * @return Promise of a list of Security Questions configured for the user
     */
    getSecurityQuestions(user) {
        return this.serverAPI.get(user._links.securityQuestions);
    }

    /**
     * POST validates the security answers for the user
     * @param user user DTO
     * @param securityAnswers list of security answers input from the ui
     * @return Promise.  return true is security answers is validated, false if not validated
     */
    validateSecurityAnswers(user, securityAnswers){
        return this.serverAPI.post(user._links.validateSecurityAnswers, null, securityAnswers);
    }

    /**
     * POST resets the password
     * @param userSetupData setup DTO
     * @return Promise.  http status 200 if success, 403 if invalid token or the security questions were not validated
     */
    resetPassword(userSetupData) {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.post(globalLinks.resetPassword, null, userSetupData, [403]));
    }

    /**
     * Switch an authenticated nurse user session to the current office's special Self-Service kiosk user account.
     *
     * @param user current user; must have NURSE role.
     * @return Promise to the self-service User
     */
    switchToSelfService(user) {
        return this.serverAPI.put(user._links.goSelfService);
    }

    /**
     * Is the given user defined and a self-service kiosk user?
     * @param user
     * @returns {boolean} true if user has role SELFSERVICE.
     */
    isSelfServiceUser(user) {
        return user && user.roles && user.roles.includes('SELFSERVICE');
    }

    /**
     * Is the given user defined and *not* a self-service kiosk user?
     * @param user
     * @returns {boolean} true if user has a normal user role.
     */
    isNormalUser(user) {
        return user && user.roles && user.roles.length > 0 && !user.roles.includes('SELFSERVICE');
    }

    /**
     * Is the given user a DOCTOR?
     * @param user
     * @returns {boolean}
     */
    isDoctorUser(user) {
        return user && user.roles && user.roles.includes('DOCTOR');
    }

    /**
     * Is the given user a NURSE?
     * @param user
     * @returns {boolean}
     */
    isNurseUser(user) {
        return user && user.roles && user.roles.includes('NURSE');
    }
}
