'use strict';

import angular from 'angular';
import $filter from "angular";
import $parse from "angular";
import ngDraggable from 'ngdraggable';
import dilutionFilter from "../../../../common/filters/dilution";
import { ClassicalDilution,ClassicalDilutions } from "../../../../models/classical-dilutions";
import PrescriptionApprovalController from "./prescription-approval.controller";

export default angular.module('pages.dashboard.sections.approvals.prescription', [dilutionFilter, 'ngDraggable'])
    .controller('PrescriptionApprovalController', PrescriptionApprovalController)
    .directive('agPrescriptionApproval', agPrescriptionApproval)
    .directive('agEditButtons', [agEditButtons])
    .directive('aprEscalationSchedule', ['$q', aprEscalationSchedule])
    .directive('aprPatientInfo', [aprPatientInfo])
    .directive('aprVialTable', ['$timeout', aprVialTable])
    .directive('aprVialVolumeModel', ["$filter", "$parse", aprVialVolumeModel])
    .directive('aprVolumeInput', [aprVolumeInput])
    .directive('aprPotencyInput', [aprPotencyInput])
    .directive('aprEndPointInput', [aprEndPointInput])
    .name;

function calculateClassicalDosage(rxSubstance, selectedTreatmentConfig) {
    let tc = selectedTreatmentConfig.find(m => m !== null);
    if (!(tc && tc.scit && rxSubstance.maintPotency && rxSubstance.maintVolume && rxSubstance.concPotency)) {
        return;
    }

    // Apply the magic equation for classical dosage calculation
    const c1 = rxSubstance.maintPotency / rxSubstance.maintVolume;
    const c2 = rxSubstance.concPotency;
    const v1 = tc.scit.fillVolume;
    const v2 = (v1 * c1) / c2;
    rxSubstance.dosage = Math.round(v2 * 100.0) / 100.0;
}

// Directive to wrap prescription review / approval
function agPrescriptionApproval() {
    return {
        template: require('./prescription-approve.html'),
        css: require('./styles.scss'),
        controller: 'PrescriptionApprovalController',
        scope: {
            prescriptionHref: '=',
            /**
             * @type{boolean} when present and true this install will apply PreMix-PrescriptionApproval-specific
             * semantics.
             */
            isApproval: '=',
            /** @type{boolean} when present and true this instance will apply ManualPrescription-specific semantics */
            isManualRxCase : '<?',
            /** @type{function()} called upon form submission. */
            onExitCallback: '&?',
            /**
             * @type{ function({ missingFields : {Array.<String>} }) }
             * This callback spawns a modal explaining how an attempted vial-substance save call failed. The param
             * object's "missingFields" should be an Array of the UI recognizable field names which the user failed
             * to provide.
             */
            onIncompleteSubstanceSave: '&'
        },
        link: function(scope, element, attrs) {

        }
    }
}

// Directive for the functionality to edit a row on the vial table
function agEditButtons() {
    return {
        link: function (scope, element, attrs) {

            // Variables for edit vial table for Prescription Approval page
            scope.editingData = {};

            // Loop though data to set editing area to false for default.
            for (var i = 0, length = scope.prescribedVials.length; i < length; i++) {
                scope.editingData[scope.prescribedVials[i].name] = false;
            }

            // Reacts to clicks on a substance row's 'modify' AKA: row should go into editing mode.
            scope.modify = function (prescribedSubstance) {
                scope.editingData[prescribedSubstance.name] = true;
            };

            // Reacts to clicks on a substance row's 'update' AKA: save changes, row should get out of editing mode.
            scope.update = function (prescribedSubstance, vialIndex) {
                scope.editingData[prescribedSubstance.name] = false;

                if (scope.isClassical && !prescribedSubstance._isControl) {
                    calculateClassicalDosage(prescribedSubstance, scope.selectedTreatmentConfig);
                }

                scope.substanceModificationHandler({"vialIndex" : vialIndex });
            };

            scope.removeRow = function (vial, index) {
                scope.removeSubstanceHandler({ "vialIndex" : vial, "substanceIndex" : index });
            };

        }
    };
}

// Directive that contains the template for the escalation schedule dropdown
function aprEscalationSchedule($q) {
    return {
        template: require('./widgets/escalation-schedule.html'),
        css: require('./widgets/escalation-schedule.scss'),
        link: function(scope, element, attrs, ctrl) {
            scope.dosingScheduleHeadings = ['Red (1:1)', 'Blue (1:10)', 'Yellow (1:100)', 'Green (1:1,000)', 'Silver (1:10,000)'];

            // null-safe object id retriever
            const getId = (obj) => obj ? obj.id : null;

            scope.hasEscalation = () => {
                return scope.selectedTreatmentConfig
                    && scope.selectedTreatmentConfig.some(m => m !== null);
            };

            scope.selectEscalation = (idx, config) => {
                if (getId(scope.selectedTreatmentConfig[idx]) !== getId(config))  {
                    scope.selectedTreatmentConfig[idx] = config;
                    scope.onTreatmentConfigChange(idx);
                }
            };

            scope.isEscalationDisabled = () => {
                if (scope.showEscalationSchedule) {
                    return false;
                }
                else {
                    return !scope.isApproval || scope.isCancelled;
                }
            };

            scope.tcSelectButtonText = (idx) => {
                let result = 'No Schedule';
                if (scope.selectedTreatmentConfig[idx]) {
                    result = scope.selectedTreatmentConfig[idx].name;
                }
                else if (idx === 0 && !scope.hasTraditionalArrangement) {
                    result = 'Select One';
                }
                return result;
            };

            scope.showTcSelection = (idx) => {
                if (scope.isVialEdit && scope.isClassical) {
                    return scope.targetDilution.order === idx;
                }
                return true;
            };

            scope.showTcNoSchedule = (idx) => {
                if (scope.isVialEdit) {
                    return false;
                }
                else if (scope.hasTraditionalArrangement) {
                    return true;
                }
                else {
                    return idx > 0 && (idx+1 >= scope.selectedTreatmentConfig.length || scope.selectedTreatmentConfig[idx+1] === null);
                }
            };
        }
    };
}

// Directive for the template that contains the patient info section of the approve prescription page
function aprPatientInfo() {
    return {
        template: require('./widgets/apr-patient-info.html'),
        css: require('./widgets/apr-patient-info.scss')
    };
}

// Directive that contains the vial table(s) and function to add a new row to the data.
function aprVialTable($timeout) {
    return {
        template: require('./widgets/apr-vial-table.html'),
        css: require('./widgets/apr-vial-table.scss'),
        scope: {
            treatmentType: '=',
            addAntigenHandler: "&",
            addDilutantHandler: "&",
            /**
             * @type {Array.<{{id: {String} , name: {String} }}>}
             * List of antigens available for adding to the prescription. To adequately fullfil their roles, the object
             * representations must contain these fields:
             *   "id" : an opague identifier associated with the substance,
             *   "name" : user facing text which identifies the chemical to the user
             */
            availableAntigens: "=",
            /**
             * @type { Map.< string , Array.<{{id: {String} , name: {String} }}> >}
             * A map, where the keys are indices uses to specify which vial in the current prescription, and the values
             * are arrays, with each containing diluent agents available for adding to their vial.
             * To adequately fullfil their roles, the object representations must contain these fields:
             *   "id" : an opague identifier associated with the substance,
             *   "name" : user facing text which identifies the chemical to the user
             */
            availableDilutants: "=",
            /**
             * @type {Object.<string, {potencyUnit: string, potency: number, formulation: string}[]}
             * key'd by substance ID to sorted array of formulation description objects
             */
            availableConcentrates: "=",
            prescribedVials: '=',
            removeSubstanceHandler : "&",
            substanceModificationHandler: "&",
            /** @type{function()} requires parameter map of the form { 'vialIndex' : {Integer} } */
            diluteAllHandler:"&",
            /** @type{function()} requires parameter map of the form { 'vialIndex' : {Integer} } */
            advanceAllHandler:"&",
            editable: "=",
            /** @type{boolean} Is this a classical process prescription? */
            isClassical: "=",
            /** @type{boolean} when present and true this instance will support inter-vial antigen drag & drop */
            isDragdropEnabled : "=?",
            /** {integer} the maximum value for a dilution */
            maximumDilution : "=",
            /**
             * Drag&Drop controller support. Accepts a parameter map of the form:
             *  vialIndex : {Number} must be a integer >= 0, indicates the index (relative to #prescribedVials) of the
             *      rx-vial into which a substance is being dropped.
             */
            onSubstanceDroppedIn : "&",
            /**
             * Drag&Drop controller support. Accepts a parameter map of the form:
             *  vialIndex : {Number} must be a integer >= 0, indicates the index (relative to #prescribedVials) of the
             *     rx-vial from which we're extricating the antigen.
             *  substanceIndex : {Number} must be a integer >= 0, indicates the index (relative to
             *      #prescribedVials[ vialIndex].substances ) of the substance we're pulling out of the rx-vial.
             *
             * Operationally, the ngDraggable impl will call this method first, identifying the src vial and subject
             * antigen. A subsequent call to #onSubstanceDroppedIn will reify the change. Despite the visual state of
             * vial-table UI, this predicate will not mutate the DM by itself. The transfer is not reified until the
             * pending #onSubstanceDroppedIn occurs. Were it not for this the atomization of the drag & drop, we could
             * concieveably be allowing users to accidentally discard substances if they miss the drop-zone!
             */
            onSubstanceDraggedOut : "&",
            /**
             * @type{ function({ missingFields : {Array.<String>} }) }
             * This callback spawns a modal explaining how an attempted vial-substance save call failed. The param
             * object's "missingFields" should be an Array of the UI recognizable field names which the user failed
             * to provide.
             */
            onIncompleteSubstanceSave : "&",
            /**
             * @type{ function({{
             *     substanceIndex : {Integer},
             *     fmVialIndex : {Integer},
             *     toVialIndex : {Integer}
             * }}) }
             *
             * Externally defined (SPOILER ALERT: prescription-approval.controller.js ) handler which takes care of
             * migrating a substance from one vial to another.
             */
            substanceVialXferHandler : "&",
            /**
             * For basic prescription or vial changes that don't include any substance changes
             */
            saveRxHandler : "&",
            selectedTreatmentConfig: "="

        },
        link: function (scope, element, attrs) {

            /* Determine if the available concentrates map is loaded. If the map cannot be loaded
             * for any reason, return undefined. Otherwise return true if the map has any keys or
             * false otherwise */
            let hasLoadedConcentrates = () => {
                if (!scope.availableConcentrates) {
                    return undefined;
                }

                return Object.keys(scope.availableConcentrates).length > 0
            };

            scope.loadedConcentrates = hasLoadedConcentrates();

            scope.$watch('availableConcentrates', () => {
                scope.loadedConcentrates = hasLoadedConcentrates();
            });

            scope.onAntigenSelection = (vial) => {
                vial.newAntigen = {id: vial.newAntigen.id, name: vial.newAntigen.name };
            };

            scope.onConcentrateSelection = (rxSubstance) => {
                if (rxSubstance._concentration) {
                    rxSubstance.concPotency = rxSubstance._concentration.potency;

                    // If potencyUnit changes, change the maintPotency to match concentrate and clear maintVolume
                    if (rxSubstance.potencyUnit !== rxSubstance._concentration.potencyUnit) {
                        rxSubstance.potencyUnit = rxSubstance._concentration.potencyUnit;
                    }
                }
                else {
                    rxSubstance.concPotency = undefined;
                }
            };

            let isNumber = (v) => v != undefined && v !== '' && isFinite(v);

            /**
             * @param {Integer} vialIndex
             *      index of the logical TreatmentVial being altered
             *
             * @param {Object} newAntigen
             *
             *      id {String} unique identifier of the PanelSubstance subject
             *
             *      dosage {Number} the fluid volume (in milliliters) of the diluent identified by
             *          #newDiluentId the user wants to add to the vial id'd by #vialIndex.
             *
             *      dilution {Integer} specifies the degree of dilution the user intends for the antigen.
             */
            scope.addAntigenRowData = (vialIndex, newAntigen) => {

                let missingFieldNames = [];

                /*
                 * Field validation
                 */
                if (!newAntigen.id) {
                    missingFieldNames.push("Antigen");
                }

                if (scope.isClassical) {
                    if (!isNumber(newAntigen.maintPotency)) {
                        missingFieldNames.push("Injection Dose");
                    }
                    if (!isNumber(newAntigen.maintVolume)) {
                        missingFieldNames.push("Injection Volume");
                    }
                    if (!isNumber(newAntigen.concPotency) || !newAntigen.potencyUnit) {
                        missingFieldNames.push("Stock Concentration");
                    }

                    if (!missingFieldNames.length) {
                        // Init fields unused by classical
                        newAntigen.dilution = 0;
                        newAntigen.endPoint = 0;

                        calculateClassicalDosage(newAntigen, scope.selectedTreatmentConfig);
                    }
                }
                else {
                    // Otolaryngic
                    if (!isNumber(newAntigen.endPoint)) {
                        missingFieldNames.push("End Point");
                    }
                    if (!isNumber(newAntigen.dilution)) {
                        missingFieldNames.push("Dilution");
                    }
                    if (!(newAntigen.dosage > 0)) {
                        missingFieldNames.push("Volume");
                    }
                }

                if (missingFieldNames.length) {
                    return scope.onIncompleteSubstanceSave({"missingFields": missingFieldNames});
                }
                else {
                    return scope.addAntigenHandler({
                        "vialIndex": vialIndex,
                        "dosage": Number(newAntigen.dosage),
                        "dilution": Number(newAntigen.dilution),
                        "endPoint": Number(newAntigen.endPoint),
                        "maintPotency": Number(newAntigen.maintPotency),
                        "maintVolume": Number(newAntigen.maintVolume),
                        "concPotency": Number(newAntigen.concPotency),
                        "potencyUnit": newAntigen.potencyUnit,
                        "substanceId": newAntigen.id
                    });
                }
            };

            /**
             * @param {Integer} vialIndex
             *      index of the logical TreatmentVial being altered
             *
             * @param {Object} newDiluent
             *  Specifications for a diluent to apply to the subject vial. Required fields::
             *
             *      id {String} unique identifier of the PanelSubstance used to dilute the vial
             *
             *      dosage {Number} the fluid volume (in milliliters) of the diluent
             */
            scope.addDilutantRowData = (vialIndex, newDiluent) => {
                if (newDiluent.id && newDiluent.dosage > 0) {
                    scope.addDilutantHandler({
                        "vialIndex": vialIndex,
                        "dosage": Number(newDiluent.dosage),
                        "substanceId": newDiluent.id
                    });
                }
            };

            scope.onUiSubstanceDroppedIn = (vialIndex) =>
                scope.onSubstanceDroppedIn({ "vialIndex": vialIndex });

            scope.onUiSubstanceDraggedOut = (vialIndex, substanceIndex) =>
                scope.onSubstanceDraggedOut({
                    "vialIndex": vialIndex,
                    "substanceIndex": substanceIndex
                });

            /**
             * @param {Integer} substanceIndex
             *  zero-based sequential index of the substance relative to vial (whose index = fmVialIndex) - specifies
             *  the substance we're migrating.
             * @param {Integer} fmVialIndex
             *  zero-based sequential index of the vial relative to the prescription - specifies which vial the
             *  substance currently inhabits.
             * @param {Integer} toVialIndex
             *  zero-based sequential index of the vial relative to the prescription - specifies the destination vial
             *  to which we're transferring the substance.
             */
            scope.xfer = (substanceIndex, fmVialIndex, toVialIndex) =>
                scope.substanceVialXferHandler({
                    "substanceIndex" : substanceIndex,
                    "srcVialIndex" : fmVialIndex,
                    "destVialIndex" : toVialIndex
                });

            scope.toggleNameEdit = (vial, $event) => {
                if (scope.editable && !scope.isClassical && !vial._saving) {
                    vial._editingName = !vial._editingName;
                    vial._name = scope.isClassical ? vial.baseName : vial.name;
                    if (vial._editingName) {
                        $timeout(() => {
                            document.getElementById('vialName' + vial.id).focus();
                        });
                    }
                }
            };

            scope.saveName = (vial) => {
                const setVialName = (value) => {
                    if (scope.isClassical)
                        vial.baseName = value;
                    else
                        vial.name = value;
                };
                const saveComplete = () => {
                    vial._saving = false;
                    vial._editingName = false;
                    setTimeout(() => scope.$digest());
                };

                vial._saving = true;
                vial._editingName = true;

                let originalName = scope.isClassical ? vial.baseName : vial.name;
                setVialName(vial._name);

                scope.saveRxHandler()
                    .then(() => {
                        saveComplete();
                    })
                    .catch(() => {
                        vial._name = originalName;
                        setVialName(originalName);
                        saveComplete();
                    });
            }

            scope.substanceRowClass = (substance) => {
                if (substance.changeRequest) {
                    if (scope.isClassical && substance._isAntigen) {
                        return 'concentrate-alert';
                    }
                    else if (!scope.isClassical) {
                        return 'dilution-change-alert';
                    }
                }

                return '';
            }

            scope.noAvailableConcentratesMessage = () => {
                if (scope.loadedConcentrates === undefined) {
                    return '';
                }
                else if (scope.loadedConcentrates === false) {
                    return 'Loading...';
                }
                else {
                    return 'No Inventory';
                }
            }

        } // end link()

    }; // end Directive-Definition

}// end aprVialTable predicate

function aprVialVolumeModel($filter, $parse) {
    return {
        require : "ngModel",
        restrict : "A",

        link : function (scope, element, attrs, ngModelCtrl) {

            let
                // I'm ashamed of having to resort to using eval(evil)
                myOptions = scope.$eval(attrs.aprVialVolumeModel),
                //myOptions = $parse(attrs.aprVialVolumeModel),
                // I really wanted to use the parseProvider, but it's not available
                precision = myOptions.precision,
                minVal = myOptions.min,
                maxVal = myOptions.max;

            ngModelCtrl.$formatters.unshift( dataModelValue =>
                ( $filter("number")(dataModelValue, precision) ) );

            ngModelCtrl.$parsers.push( rawString=>
                ( angular.isNumber(rawString) ? rawString : Number.parseFloat(rawString) || 0 ) );

            ngModelCtrl.$validators.LEXICAL_COMPOSITION = (modelValue, viewValue) =>
                (angular.isDefined(viewValue) ? (angular.isNumber(modelValue)) : undefined);

            ngModelCtrl.$validators.NUMERIC_RANGE = (modelValue, viewValue) =>
                ((minVal <= modelValue) && (modelValue <= maxVal));

        }

    };
}

/**
 * This elemental directive abstracts away the configuration details of the aprVialVolumeModel attribute directive.
 * This impl's template applies aprVialVolumeModel to this impl's element. The value provides by this impl is sparing
 * parent layout with recurring long attribute lists.
 *
 */
function aprVolumeInput() {
    return {
        restrict : "E",
        template: require("./widgets/apr-volume-input.html"),
        css: require("./widgets/apr-volume-input.scss"),
        scope: {

            /** @type {Number} Constraint property : instance will enforce this bound */
            maxValue : "=",

            /** @type {Number} Constraint property : instance will enforce this bound */
            minValue : "=",

            /** @type {Number} The subject value housed by an instance */
            ngModel : "=",

            /** @type {Number} expected to be a positive integer: limit on digits right of the decimal */
            precision : "="

        },
        require : "ngModel",
        link : function (scope, element, attrs, ngModel) {}
    };
}

/**
 * Input an antigen potency value.
 * Handles conversion between model and view values, depending on the potency unit.
 */
function aprPotencyInput() {
    return {
        restrict : "E",
        template: require("./widgets/apr-potency-input.html"),
        css: require('./widgets/apr-potency-input.scss'),
        scope: {

            /** @type {string} potency unit to transform value in/out of */
            unit : "=",

            /** @type {Number} The model potency value */
            potency : "="
        },
        link : function (scope, element, attrs, ngModelController) {
            let convert = (value) => scope.unit === 'W/V' && value ? 1 / value : value;

            scope.$watch('potency', () => {
                scope.value = convert(scope.potency);
            });

            scope.onChange = () => {
                scope.potency = convert(scope.value);
            };
        }
    };
}

/**
 * This elemental direction abstracts EndPoint UI-fields. An "endPoint" is logically a score within an index, where
 * low values indicate the patients' resistance/tolerance/ability to harmoniously in the presence of a substance. High
 * values indicate patients' weakness/vulnerability/reactiveness to a substance. The index does not necessarily map
 * 1:1 to any specific physical phenomenon, nor should it be assumed to reflect a linear gradient.
 *
 * Take any and all liberties with DOM actions and aesthetics.
 * But make NO assumptions about parent model.
 *
 * Data value characteristics as late June 2016
 * BaseType: Integer
 * Range: [0,99]
 */
function aprEndPointInput() {
    return {
        restrict : "E",
        template :
            "<input type='number' name='endPoint' " +
            "   min='{{min}}' max='{{max}}' step='1' " +
            "   ng-model='ngModel' " +
            "   ng-model-options=\"{updateOn: 'default blur', getterSetter : true, allowInvalid : false }\"" +
            " />",
        require : "ngModel",

        scope : {

            /**
             * Instances' characteristic subject matter, AKA the eigenValue. Same scope as parent, we're mutating
             * its state on the caller's behalf. Because of the limit of designed-usage (I would say 'scope', but
             * wanted to avoid namespace collision with Ng.Scope), we know the type will be an Integer within some
             * range. The range is delimited by min and max.
             */
            ngModel : "=",

            /**
             * Instance configuration: establishes the floor of accepted values.
             * For performance, this binding is 1 way, ergo the "<" modifier token.
             * This setting is optional (hence "?"), and defaults to 0 when omitted.
             */
            min : "<?minValue",

            /**
             * Instance configuration: establishes the ceiling of accepted values.
             * For performance, this binding is 1 way, ergo the "<" modifier token.
             * This setting is optional (hence "?"), and defaults to 99 when omitted.
             */
            max : "<?maxValue"
        },

        link : function (scope, element, attrs, ngModelController) {

            scope.min = ( (angular.isDefined(attrs.min)) ? attrs.min : 0);
            scope.max = ( (angular.isDefined(attrs.max)) ? attrs.max : 99);

            ngModelController.$parsers.push( rawString=> Number.parseInt(rawString) || 0);

            ngModelController.$validators.LEXICAL_COMPOSITION = (modelValue, viewValue) =>
                (angular.isDefined(viewValue) ? (angular.isNumber(modelValue)) : undefined);

            ngModelController.$validators.NUMERIC_RANGE = (modelValue, viewValue) =>
                ((scope.min <= modelValue) && (modelValue <= scope.max));

        }
    };
}
