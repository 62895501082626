import angular from 'angular';
import ngRoute from 'angular-route';
import ui_bootstrap from 'angular-ui-bootstrap';

import IntradermalDetailsController from './intradermal-details.controller.js';


export default angular.module('pages.testing.intradermal-details', [ngRoute, ui_bootstrap])
    .config(config)
    .controller('IntradermalDetailsController', IntradermalDetailsController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/idtDetails', {
        template: require('./intradermal-details.html'),
        css: require('./intradermal-details.scss'),
        controller: 'IntradermalDetailsController'
    });
}

config.$inject = ['$routeProvider'];
