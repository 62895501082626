'use strict';

import TestingController from '../testing.controller'

export default class TestIdtHistamineChecklistController extends TestingController {

    static $inject = ['$scope', '$injector', 'checklistService', 'concentrateService', 'substanceService'];
    constructor($scope, $injector, checklistService, concentrateService, substanceService) {

        super($scope, $injector);

        this.checklistService = checklistService;
        this.concentrateService = concentrateService;
        this.substanceService = substanceService;
        this.globalConfigService = $injector.get('globalConfigService');
        this.disableNext = true;
        this.allowAutoBarcode = false;

        $scope.nextButonLabel = this.$scope.practice.config.minIdtHistamineReactionWait > 0 ? 'Start Histamine Timer' : undefined;

        this._loadGlobalConfig();

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    reload() {
        super.validateStage(['IDT_HISTAMINE_CHECKLIST']);

        this.$scope.text = 'Complete Intradermal Test Checklist';
        this.initFooterNote('IDT Histamine');

        Promise.all([
            this._manageValidConcentrates(),
            this._loadChecklist()
        ]).then(() => this.disableNext = false);
    }

    _loadChecklist() {
        return this.checklistService.getForIDTHistamine(this.$scope.practice)
            .then(checklist => {
                this.$scope.itemLabels = checklist.items;
                this.$scope.itemChecked = [];
            });
    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext)
            return true;

        this.$scope.allergyTest.idtHistamine = undefined;
        if (this.$scope.barCode === '=' && this.allowAutoBarcode) {
            this.$scope.barCode = this.validConcentrates[0].barcode;
        }
        for (let conc of this.validConcentrates) {
            if (this.$scope.barCode === conc.barcode) {
                if (!this._concentrateHasAlert(conc))
                    this.$scope.allergyTest.idtHistamine = { id: conc.id };
                break;
            }
        }

        this.$scope.validBarcode = angular.isDefined(this.$scope.allergyTest.idtHistamine);
        if (!this.$scope.validBarcode) {
            return true;
        }

        for (let idx = 0; idx < this.$scope.itemLabels.length; ++idx) {
            if (!this.$scope.itemChecked[idx])
                return true;
        }

        return false;
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        this.updateFooterNote();
        return super.update();
    }

    /**
     * Initialize this.validConcentrates and refresh it whenever concentrates at the office change.
     * @returns {Promise} that resolves when complete
     * @private
     */
    async _manageValidConcentrates() {
        this.idHistamineSubstance = (await this.substanceService.getByType(this.$scope.practice, this.substanceService.POSITIVE_CONTROL)).pop() || {id:''};

        // When concentrates change at the practice, refresh this.validConcentrates
        await this.notificationService.init();
        super.registerSubscription(this.notificationService.subscribeAllConcentrateUpdates(this.$scope.practice))
            .then(null, null, (notification) => {
                let modifiedConc = notification.body;
                if (modifiedConc.substance.id === this.idHistamineSubstance.id)
                    return this._refreshValidConcentrates()
            });

        await this._refreshValidConcentrates();

        super.startAllSubscriptions();
    }

    /**
     * Load the current valid ID Histamine concentrates at an office. Only called from _manageValidConcentrates().
     * @returns {Promise} that resolves when complete
     * @private
     */
    _refreshValidConcentrates() {
        return this.concentrateService.getInServiceSubstanceAtOffice(this.$scope.office, this.idHistamineSubstance)
            .then(concs => this.validConcentrates = concs.list.filter(c => c.forIdt));
    }

    /**
     * Is there an Inventory Alert active for the given concentrate?
     * @private
     */
    _concentrateHasAlert(concentrate) {
        let alertSummary = this.inventoryAlertService.getSummary();
        return alertSummary.icons.has(concentrate.id);
    }
}
