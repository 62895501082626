"use strict";

import angular from 'angular';

import DisplayBillingCodesController from "./widgets/display-billing-codes.controller.js";
import MixApproval from "./mix-approval";
import PrescriptionApproval from "./prescription-approval";
import TestApproval from "./test-approval";
import TestReviewApproval from "./test-review-approval";
import TreatmentReview from "./review-treatment";

export default angular.module('pages.common.approvals', [
    MixApproval,
    PrescriptionApproval,
    TestApproval,
    TestReviewApproval,
    TreatmentReview
])
    .directive('agAddCode', [agAddCode])
    .directive('agAddNotes', [agAddNotes])
    .directive('agDisplayNotes', [agDisplayNotes])
    .directive('agPageHeader', [agPageHeader])
    .directive('btnAddAlert', [btnAddAlert])
    .directive('btnAddPrescription', [btnAddPrescription])
    .directive('btnBackToList', [btnBackToList])
    .directive('agDisplayBillingCodes', [agDisplayBillingCodes])
    .directive('endpointDropdown', [endpointDropdown])
    .directive('atrPatientInfo', [atrPatientInfo])
    .name;


// Directive that adds a new input for a IDC10 code
function agAddCode() {
    return {
        template: require('./widgets/icd10.html'),
        css: require('./widgets/icd10.scss'),
        link: function (scope, element, attrs) {
            scope.addCode = function () {
                scope.newInput = '<div class="button-left"><input type="text"></div>';
                $('#idc10Codes').append(scope.newInput);

            };
        }
    };
}

// Directive for the template that contains the section to add notes for a patient
function agAddNotes() {
    return {
        template: require('./widgets/add-notes.html'),
        css: require('./widgets/add-notes.scss'),
        scope: {
            text: '='
        }
    };
}

// Directive for template that displays the collected notes for a patient
function agDisplayNotes() {
    return {
        template: require('./widgets/display-notes.html'),
        css: require('./widgets/display-notes.scss'),
        scope: {
            notes: '=',
            title : "@?"
        },
        link : (scope, element, attrs) => {}
    };
}

// Directive for the creation of the page header
function agPageHeader() {
    return {
        template: require('./widgets/page-header.html'),
        css: require('./widgets/page-header.scss'),
        scope: {
            title: '@',
            backToList: '&?',
            linktitle: '@'
        },
        link : (scope, element, attrs) => {
            scope.practice = scope.$parent.practice;
            scope.office = scope.$parent.office;
            scope.address = scope.$parent.office.physicalAddresses.office;
        }
    };
}

// Directive for a button to add an alert
function btnAddAlert() {
    return {
        template: '<button class="btn btn-primary flRt" ng-click="addAlertModal()">Add Patient Alert</button>',
        link: function (scope, element, attrs) {
            scope.selectAll = function (source) {
                scope.checkboxes = $('.modal-content input[type=checkbox]');
                for (var i = 0, n = scope.checkboxes.length; i < n; i++) {
                    scope.checkboxes[i].checked = true;
                }
            };
        }
    };
}

// Directive for a button to add a prescription
function btnAddPrescription() {
    return {
        template: '<button class="btn btn-primary" ng-click="addPrescriptionModal()">Add Prescription</button>'
    };
}

// Directive for back to list button
function btnBackToList() {
    return {
        template: '<a href="" ng-click="onClick()">< {{linktitle}}</a>',
        scope: {
            onClick: '&',
            linktitle: "@"
        }
    };
}

function agDisplayBillingCodes() {
    return {
        template: require('./widgets/display-billing-code.html'),
        css: require('./widgets/display-billing-code.scss'),
        controller: DisplayBillingCodesController,
        controllerAs: 'dbc',
        bindToController: true
    };
}

function endpointDropdown() {
    return {
        template: require('./widgets/endpoint-dropdown.html')  ,
        css: require('./widgets/endpoint-dropdown.scss'),
        require: 'ngModel',
        scope: {
            onEndPointChange: '&onEndPointChange',
            validEndPoints: '=endpoints',
            result: '=ngModel',
            antigenIndex: '='
        },
        link: function (scope) {
            scope.changeEndPoint = function(antigenIndex, anEndPoint){
                scope.onEndPointChange({
                    antigenIndex : antigenIndex,
                    newEndPoint : anEndPoint,
                    antigenData : scope.result
                });
            };
        }
    };
}

// Directive for template that contains the patient information on the approve test and review treatment pages.
function atrPatientInfo() {
    return {
        template: require('./widgets/tr-patient-info.html'),
        css: require('./widgets/tr-patient-info.scss'),
        link: function(scope, element, attrs, ctrl) {
            scope.isEditable = () => {
                return scope.isApproval && !scope.approvedBy;
            };

            scope.getAllProviders = () => {
                let result = scope.users && scope.users.filter(user => user.roles.find(m => m === 'DOCTOR'));
                return result;
            }

            scope.setPerformedByUser = (user) => {
                if (user && (!scope.performedByUser || user.id !== scope.performedByUser.id)) {
                    scope.performedByUser = user;
                }
            };

            scope.setOrderedByProvider = (provider) => {
                if (provider && (!scope.orderedByProvider || provider.id !== scope.orderedByProvider.id)) {
                    scope.orderedByProvider = provider;
                }
            };

            scope.setBillToProvider = (provider) => {
                if (provider && (!scope.billToProvider || provider.id !== scope.billToProvider.id)) {
                    scope.billToProvider = provider;
                    scope.bill._dirty = true;
                }
            }
        }
    };
}
