'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import MixingChecklistController from './checklist.controller.js';


export default angular.module('pages.mixing.checklist', [ngRoute])
    .config(config)
    .controller('MixingChecklistController',MixingChecklistController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_MIXING_CHECKLIST, {
        template: require('./layout.html'),
        css: require('./styles.scss'),
        controller: 'MixingChecklistController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
