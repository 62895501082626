'use strict';

import BaseSectionController from '../../new-dashboard/widgets/section-content-panel/controller';
import {ChronoUnit, LocalDateTime, Period, TemporalAdjusters} from 'js-joda';
import moment from 'moment';

export default class BaseReportsSectionController extends BaseSectionController {

    static TIMEFRAME_THIS_MONTH = 'THIS-MONTH';
    static TIMEFRAME_THIS_YEAR = 'THIS-YEAR';

    /**
     * @param $injector common infrastructure facility empowered DI
     * @param $scope the concrete instance's UI data state
     * @param reportsSectionType determines WHICH subclass of BaseReportsSectionController should serve the instance
     */
    constructor($injector, $scope, reportsSectionType) {
        super($injector, $scope, reportsSectionType);
        this.reportsService = $injector.get('reportsService'); // our main API for Reports data src
        this.chronologyMappingService = $injector.get('chronologyMappingService'); // supports dateRange calculus
        this.$filter = $injector.get('$filter');

        // Map of filtering constraints for the report; they share many params
        this.reportCriteria = {};

        // Initialize common utilities and auxilia for parts used in the various reports...
        this.$scope.svm = this.svm = {}; // Shared view model
        this.svm.dateRange = BaseReportsSectionController.TIMEFRAME_THIS_MONTH;
        this.$scope.TIMEFRAME_THIS_MONTH = BaseReportsSectionController.TIMEFRAME_THIS_MONTH;
        this.$scope.TIMEFRAME_THIS_YEAR = BaseReportsSectionController.TIMEFRAME_THIS_YEAR;

        this.$scope.datePickerFormat = 'MM/dd/yyyy';
        this.$scope.datePickerFromOptions = { showWeeks: false };
        this.$scope.datePickerToOptions = { showWeeks: false };

        this.svm.customDateFrom = new Date();
        this.svm.customDateTo = new Date();
        this.$scope.customDateFromChanged = () => this._onCustomDateFromChange();
        this.$scope.customDateToChanged = () => this._onCustomDateToChange();

        this.$scope.pastDateRangeChanged = () => this._updatePastDateRange(true);
        this.$scope.futureDateRangeChanged = () => this._updateFutureDateRange(true);
        this.$scope.setOfficeFilter = (office) => this._setOfficeFilter(office);
        this.$scope.exportToCsv = () => this._exportToCsv();
        this.$scope.getReportColumnCount = () => this._getReportColumnCount();

        this._updatePastDateRange(false);

        this._getCurrentPractice();
    }

    async onInit() {
        // Default values for UI controls
        this.filterByOffice = undefined; // Value used with service call
        this.$scope.selectedOffice = undefined; // The future value of filterByOffice; gotten from UI

        // data values which will be async populated
        this.$scope.rowCollection = [];
        await this._getOfficeData();
        await this._loadReport();
    }

    async _getCurrentPractice() {
        if (this.currentPractice) {
            return this.currentPractice;
        }

        this.currentPractice = await this.practiceService.getCurrent();
        return this.currentPractice;
    }

    async _getOfficeData() {
        this.$scope.allOffices = [];

        // Practice and office services injected into BaseController
        let practice = await this._getCurrentPractice();

        // We gather offices by gettings each practices' list of offices.
        // Because different practices sharing an office is allowed within the scheme, and we wish avoid dupes,
        // we'll accumulate the offices in a map then convert that to a list at the end.
        let officeIdMap = new Map();
        let officesForPractice = await this.officeService.getInPractice(practice, true);
        for (let anOffice of officesForPractice.list) {
            if (!officeIdMap.has(anOffice.id)) {
                officeIdMap.set(anOffice.id, anOffice);
            }
        }

        for (let anOffice of officeIdMap.values()) {
            this.$scope.allOffices.push(anOffice);
        }
    }

    async _loadReport() {
        this.$scope.loadingReport = true;

        let currentPractice = await this._getCurrentPractice();
        this.reportCriteria['practiceId'] = currentPractice.id;

        if (this.filterByOffice) {
            this.reportCriteria['officeId'] = this.filterByOffice;
        }
        else {
            delete this.reportCriteria['officeId'];
        }

        let reportData = await this._getReportData();
        await this._renderReportData(reportData);

        this.$scope.loadingReport = false;
        this.$scope.$digest();
    }

    // Call out to data source for report data and retrieve it
    async _getReportData() {

    }

    // Format report data for the view
    async _renderReportData(reportData) {
        this.$scope.rowCollection = reportData;
    }

    _convertUiDateToCriteriaFormat(longDate) {
        return this.$filter('date')(longDate, 'yyyy-MM-dd');
    }

    _setOfficeFilter(office) {
        this.$scope.selectedOffice = office;
        this.filterByOffice = (office ? office.id : undefined);
        this._loadReport();
    }

    _onCustomDateFromChange() {
        this.reportCriteria.timeFrameFrom = this._convertUiDateToCriteriaFormat(this.svm.customDateFrom);
        this._loadReport();
    }

    _onCustomDateToChange() {
        this.reportCriteria.timeFrameTo = this._convertUiDateToCriteriaFormat(this.svm.customDateTo);
        this._loadReport();
    }

    async _updatePastDateRange(doUpdate) {
        if (this.svm.dateRange !== undefined) {
            let parsedPeriod, myTimeFrameFrom;

            let currentDateTime = this.chronologyMappingService.currentDateTime(this.$scope.office.timezone);
            let now = LocalDateTime.parse(currentDateTime);
            let rangeExpression = this.svm.dateRange; // Either duration OR CodeWord-value
            if (rangeExpression === BaseReportsSectionController.TIMEFRAME_THIS_MONTH) {
                // Special Case :: limit results to those created the current Gregorian calendar month.
                // This is NOT necessarily equivalent to 'Gimme the past 30 days!'.
                myTimeFrameFrom = now.withDayOfMonth(1);
            }
            else if (rangeExpression === BaseReportsSectionController.TIMEFRAME_THIS_YEAR) {
                // Special Case :: limit results to those created the current Gregorian calendar year.
                // This is NOT necessarily equivalent to 'Gimme the past 365 days!'.
                myTimeFrameFrom = now.withDayOfYear(1);
            }
            else {
                parsedPeriod = Period.parse(rangeExpression);
                myTimeFrameFrom = now.minusTemporalAmount(parsedPeriod);
            }

            let truncatedRangeFrom = myTimeFrameFrom.truncatedTo(ChronoUnit.DAYS).toString();
            let truncatedRangeTo = now.truncatedTo(ChronoUnit.DAYS).toString();

            this.svm.customDateFrom = moment(truncatedRangeFrom).toDate();
            this.svm.customDateTo = moment(truncatedRangeTo).toDate();
            this.reportCriteria.timeFrameFrom = this._convertUiDateToCriteriaFormat(truncatedRangeFrom);
            this.reportCriteria.timeFrameTo = this._convertUiDateToCriteriaFormat(truncatedRangeTo);

            if (doUpdate) {
                await this._loadReport();
            }
        }
    }

    async _updateFutureDateRange(doUpdate) {

        if (this.svm.futureDateRange !== undefined) {
            let parsedPeriod, myTimeFrameFrom, myTimeFrameTo;

            let currentDateTime = this.chronologyMappingService.currentDateTime(this.$scope.office.timezone);
            let now = LocalDateTime.parse( currentDateTime );
            let rangeExpression = this.svm.futureDateRange; // Either duration OR CodeWord-value
            if (rangeExpression === BaseReportsSectionController.TIMEFRAME_THIS_MONTH) {
                // Special Case :: limit results to those created the current Gregorian calendar month.
                // This is NOT necessarily equivalent to 'Gimme the past 30 days!'.
                myTimeFrameFrom = now;
                myTimeFrameTo = now.with(TemporalAdjusters.lastDayOfMonth());
            }
            else if (rangeExpression === BaseReportsSectionController.TIMEFRAME_THIS_YEAR) {
                // Special Case :: limit results to those created the current Gregorian calendar year.
                // This is NOT necessarily equivalent to 'Gimme the past 365 days!'.
                myTimeFrameFrom = now.withDayOfYear(1);
                myTimeFrameTo = now.with(TemporalAdjusters.lastDayOfYear());
            }
            else {
                parsedPeriod = Period.parse(rangeExpression);
                myTimeFrameFrom = now;
                myTimeFrameTo = now.plusTemporalAmount(parsedPeriod);
            }

            let truncatedRangeFrom = myTimeFrameFrom.truncatedTo(ChronoUnit.DAYS).toString();
            let truncatedRangeTo = myTimeFrameTo.truncatedTo(ChronoUnit.DAYS).toString();

            this.svm.customDateFrom = moment(truncatedRangeFrom).toDate();
            this.svm.customDateTo = moment(truncatedRangeTo).toDate();
            this.reportCriteria.timeFrameFrom = this._convertUiDateToCriteriaFormat(truncatedRangeFrom);
            this.reportCriteria.timeFrameTo = this._convertUiDateToCriteriaFormat(truncatedRangeTo);

            if (doUpdate) {
                await this._loadReport();
            }
        }
    }

    /* Get header fields and data fields to use for CSV export */
    _getCsvMeta() {

    }

    /* Generate file name from current section */
    _getCsvFileName() {
        let words = [];
        let tokens = this.$scope.ownSectionType.split('_');
        for(let token of tokens) {
            const str = [];
            for(let i = 0; i < token.length; i++) {
                let char = token.charAt(i);
                if (i == 0) {
                    str.push(char.toUpperCase());
                }
                else {
                    str.push(char.toLowerCase());
                }
            }
            words.push(str.join(''));
        }

        let reportName = words.join(' ');
        return reportName + ' ' + moment().format('YYYY-MM-DD HH-mm-ss') + '.csv';
    }

    /* Generate CSV content from report data */
    _getCsvContent() {
        let csvColumns = this._getCsvMeta();

        let headerRow = csvColumns.map(m => m.name).join(',');
        let content = headerRow + '\n';

        for(let row of this.$scope.rowCollection) {

            for(let i = 0; i < csvColumns.length; i++) {
                let fieldName = csvColumns[i].value;

                let value = null;
                if (csvColumns[i].getValue) {
                    value = csvColumns[i].getValue(row);
                }
                else {
                    value = row[fieldName];
                }

                if (value === undefined || value === null) {
                    value = '';
                }

                /* Replace double quotes with two double quotes */
                value = value.toString().replace(/"/g, '""');
                /* Add quotes if the value contains a comma */
                if (value.indexOf(',') >= 0) {
                    value = '"' + value + '"';
                }

                content += value + (i < csvColumns.length - 1 ? ',' : '');
            }

            content += '\n';
        }
        return content;
    }

    _exportToCsv() {
        if (this.exportingToCsv) {
            return;
        }

        this.exportingCsv = true;

        let content = this._getCsvContent();

        let link = document.createElement('a')
        link.id = 'report-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        link.setAttribute('download', this._getCsvFileName());
        document.body.appendChild(link);
        document.querySelector('#report-csv').click();
        document.body.removeChild(link);

        this.exportingCsv = false;
    }

    _getReportColumnCount() {
        return document.querySelectorAll('.report-table .table-header th').length;
    }
}
