'use strict'

/**
 * Inventorial status of a Concentrate, Board, TrayVial, or TreatmentVial.
 */
export default angular.module("models.serviceStatus", [])
    .constant("ServiceStatus", {
        INVENTORY : "INVENTORY",
        IN_SERVICE : "IN_SERVICE",
        DEPLETED : "DEPLETED",
        RECALLED : "RECALLED",
        EXPIRED : "EXPIRED",
        DISCARDED : "DISCARDED",
        END_SERVICE: "END_SERVICE"
    })
    .filter('serviceStatusFilter', serviceStatusFilter)
    .name;

function serviceStatusFilter() {
    return (type) => {
        let displayName = type;

        switch (type) {
            case "INVENTORY":
                displayName = "Inventory";
                break;
            case "IN_SERVICE":
                displayName = "In-Service";
                break;
            case "DEPLETED":
                displayName = "Depleted";
                break;
            case "RECALLED":
                displayName = "Mfr. Recalled";
                break;
            case "EXPIRED":
                displayName = "Expired";
                break;
            case "DISCARDED":
                displayName = "Discarded";
                break;
            case "END_SERVICE":
                displayName = "Service Ended";
                break;
            default:
                break;
        }

        return displayName;
    }
}
