'use strict';

export default class UserSetupController {
    static $inject = ['$injector', '$scope', '$location', 'userService', 'securityQuestionService'];

    constructor($injector, $scope, $location, userService, securityQuestionService) {

        this.$scope = $scope;
        this.$location = $location;
        this.$uibModal = $injector.get('$uibModal');

        this.urlPaths = $injector.get('UrlPaths');
        this.userService = userService;
        this.securityQuestionService = securityQuestionService;

        // Validate that a token exists
        let token = $location.search().token;

        if (!token) {
            // If no token, the redirect to login
            $location.path(this.urlPaths.LOGIN_PAGE);
        }

        $scope.token = token;

        // Number of security questions
        this.NUM_QUESTIONS = 3;

        // Call the server before doing anything else
        this.initUserSetup(token);

        // Init the user security questions model
        this.initUserSecurityQuestions();

        $scope.newPassword = '';
        
        // When this function is run, it should check the questions against the DB, and if true set reset to true (to display next page, otherwise it should return $scope.error = true to show error message.
        $scope.next = () => {
            $scope.savedSecurityQuestion = true;
        };
        
        //  Action selected when a new value selected for security question.  Will filter the all the drop down list for security questions
        $scope.filterAllSecurityQuestions = () => {
            this.filterAllSecurityQuestions(this.$scope.userSecurityQuestions, this.$scope.allQuestions);
        };

        //  Action selected when a new value selected for security question.  Will filter the all the drop down list for security questions
        $scope.finishSetup = () => {
            let userSetupData = this.constructUserSetupData();

            this.saveUserSetup(userSetupData);
        };
    }
    
    /**
     * Initialized them user's question and answer
     */
    initUserSecurityQuestions(){
        this.$scope.userSecurityQuestions = [];
 
        for( let i = 0; i < this.NUM_QUESTIONS; i++ ){
            let userQuestion = {};
            userQuestion.question = '';
            userQuestion.answer = '';
            userQuestion.filteredQuestions = [];
            this.$scope.userSecurityQuestions.push(userQuestion);
        }
    }
    
    /**
     * Loads the security question of the server
     */
    async initUserSetup(token){
        // First resolve the global link before processing
        await this.userService.serverAPI.globalAvailable;

        // Auth token
        await this.userService.authToken('SETUP', token);

        // get current user
        let user = await this.userService.getCurrent();

        // Gets a list of active security questions
        let securityQuestions = await this.securityQuestionService.getAllActive();

        // Call apply to refresh the view
        this.$scope.$apply(()=>{
            this.$scope.user = user;

            // Set the username
            this.$scope.email = user.email;

            // Set the person
            this.$scope.person = user.person;

            // Store all the security questions
            this.$scope.allQuestions = securityQuestions.list;

            for( let i = 0; i < this.NUM_QUESTIONS; i++ ){
                this.$scope.userSecurityQuestions[i].filteredQuestions = securityQuestions.list;
            }
        });
    }
    
    /**
     * Filters the security questions where only shows questions that were not selected
     */
    filterAllSecurityQuestions(userSecurityQuestions, allQuestions){
        // Create an array of all selected questions
        let selectedQuestions = [];
        userSecurityQuestions.forEach(q => selectedQuestions.push(q.questionId) );
        
        // Generate list for all
        userSecurityQuestions.forEach(uq => {
            let newSecurityQuestions = [];
            allQuestions.forEach(q => {
                // If question is not selected || is the current question matches, then add
                if( !selectedQuestions.includes(q.id) || q.id == uq.questionId )
                    newSecurityQuestions.push(q);
            });
            uq.filteredQuestions = newSecurityQuestions;
        });
    }

    /**
     * Creates the user setup data
     * @returns UserSetupData
     */
    constructUserSetupData(){
        let userSetupData = {};

        // set token
        userSetupData.token = this.$scope.token;

        // set userSecurityQuestions
        userSetupData.securityAnswers = [];

        this.$scope.userSecurityQuestions.forEach(q => {
            let userAnswer = {};
            userAnswer.question = {};
            userAnswer.question.id = q.questionId;
            userAnswer.answer = q.answer;
            userSetupData.securityAnswers.push(userAnswer);
        });

        // set password
        userSetupData.password = this.$scope.newPassword;

        return userSetupData;
    }

    /**
     * Calls the user service to save the account setup data.
     * @param userSetupData
     */
    async saveUserSetup(userSetupData){
        try {
            // Load AllergyTestConfigs
            await this.userService.saveUserSetup(userSetupData);

            // Successful save, redirect to login
            this.$scope.$apply( () => { this.$location.path(this.urlPaths.LOGIN_PAGE); } );
        }catch( error ){
            console.log(error);
            this.$scope.$apply( ()=> {
                this.$scope.passwordServerError = true;
                this.$scope.passwordErrorMsg = error.errors;
            });
        }
    }
}