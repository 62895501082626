"use strict";

import BaseSectionController from '../../widgets/section-content-panel/controller';
import TreatmentVialDetailsController from '../../../../pages/common/treatment-vial-details/treatment-vial-details.controller';
import ClearInventoryAlertController from '../../widgets/clear-inventory-alert-modal/controller';

export default class PatientVialsSectionController extends BaseSectionController {

    /*
     * @define TreatmentVialViewModel {{
     *      firstName : {String},
     *      lastName : {String},
     *      vialType : {Procedure},
     *      vialName : {String},
     *      barcode : {String},
     *      useByDate : {Date},
     *      beyondUseDate: {Date},
     *      status : {ServiceStatus},
     *      expired : {boolean},
     *      recalled : {boolean},
     *      _patient : {ReferenceDto.<Patient>},
     *      _treatmentConfig: {ReferenceDto.<TreatmentConfig>},
     *      hasNote : {boolean},
     *      alert : undefined,
     *      isReplaceable : {boolean},
     *      treatmentEndedBy : {{
     *          user : {{ uiName : {String} }},
     *          actionDateTime : {Date}
     *      }}
     * }}
     *
     * @define TreatmentVialsListViewModel {{
     *      vialsList : {Array.<{TreatmentVialViewModel}>}
     * }}
     */

    static $inject = ['$injector','$scope','InventorySections','ServiceStatus','ModelStateChange'];

    constructor($injector, $scope, UiSectionTypes, ServiceStatus, ModelStateChange) {
        super($injector, $scope, UiSectionTypes.VIALS);

        this._initInjections($injector);
        this.$scope.ServiceStatus = ServiceStatus;
        this.ModelStateChange = ModelStateChange;

        this.pageActive = true;

        // UI-Reactions
        $scope.onUiGotoPatientDetails = (/** {TreatmentVialViewModel} */uiRow) => this._gotoPatientDetails(uiRow);
        $scope.onUiEditVial = (/** {TreatmentVialViewModel} */uiRow) => this.offerReplaceVialModal(uiRow);
        $scope.printLabel = (/** {TreatmentVialViewModel} */uiRow) => this.printLabel(uiRow);
        $scope.clearInventoryAlertModal = (uiRow) => this.clearInventoryAlertModal(uiRow);

        // Destroy handler
        $scope.$on('$destroy', () => {
            this.pageActive = false;
        });

        this.inventoryAlertService.subscribe(this.$scope, alertSummary => this._updateAlerts(alertSummary));
        this._initialize();
    }

    /**
     * @param {AngularInjectorService} $injector
     * @protected
     */
    _initInjections($injector) {
        this.$filter = $injector.get('$filter');
        this.treatmentConfigService = $injector.get("treatmentConfigService");
        this.treatmentVialService = $injector.get("treatmentVialService");
        this.PrescriptionReason = $injector.get("PrescriptionReason");
        this.prescriptionService = $injector.get("prescriptionService");
        this.patientService = $injector.get("patientService");
        this.labelPrinterService = $injector.get("labelPrinterService");
        this.$q = $injector.get("$q");
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                         Lifecycle Management
    //
    // Instances will load the subject, the first time driven by _processRoute, and following subsequent
    // mutations, the model is refreshed by _refreshDataModel .
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /** @private */
    _initialize() {
        this.$scope.vm = { vialsList: [] };

        this.notificationService.init()
            .then(() => this.unsubscribeAllSubscriptions())
            .then(() => this._subscribeToDmChangeNotifs())
            .then(() => this._createDataModel(0, 20))
            .then(() => this.startAllSubscriptions())
            .then(() => this.inventoryAlertService.sendTo(this.$scope));
    }

    _getUserName(user) {
        this._userNamePromises = this._userNamePromises || {};

        if (!this._userNamePromises[user.id]) {
            this._userNamePromises[user.id] = this.userService.getUserName(user);
        }

        return this._userNamePromises[user.id];
    }

    /**
     * @private
     * @returns {Promise.<{Array.<{PatientVialDataModel}>}>}
     */
    _createDataModel(page, pageSize) {
        if (!this.pageActive) {
            return;
        }

        return this.treatmentVialService.getPatientVials(this.$scope.office, null, page, pageSize)
            .then( /** {{list : Array.< PatientVialDm > }} */dataResponse => {

                let deferredUserNames = [];
                dataResponse.list.forEach(aVial => {
                    if ((angular.isDefined(aVial.treatmentEndedBy)) && (angular.isDefined(aVial.treatmentEndedBy.user))) {
                        let aDeferredUserName = this.$q.defer();

                        this._getUserName(aVial.treatmentEndedBy.user)
                            .then(actionUserName => {
                                aVial.treatmentEndedBy.user.uiName = actionUserName;
                                aDeferredUserName.resolve();
                            });

                        deferredUserNames.push(aDeferredUserName.promise);
                    }
                });

                return this.$q.all(deferredUserNames).then(()=> dataResponse);
            })
            .then( /** {{list : Array.< PatientVialDm > }} */dataResponse => {
                if (dataResponse.list.length > 0) {
                    return this._renderVm(dataResponse.list).then(() => {
                        /* Load the first 5 pages with a pageSize of 20 for performance, then increase pageSize to 100 */
                        if (pageSize < 100 && pageSize * (page + 1) === 100) {
                            pageSize = 100;
                            page = 0;
                        }
                        return this._createDataModel(page + 1, pageSize);
                    });
                }

                return this.$q.resolve();
            });
    }

    /**
     * @private
     * @param {Array.<{PatientVialDataModel}>} dm
     * @returns {Promise.<void>}
     */
    _renderVm(dm) {
        let deferredRender = this.$q.defer();
        this._createVm(dm)
            .then(vm => {
                deferredRender.resolve();
            });
        return deferredRender.promise;
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                     View Model creation/mapping
    // These routines produce expressions of the subject data-model in a form the amenable to the UI-View layout.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     *
     * @param {Array.<{PatientVialDataModel}>} dm
     * @returns {Promise.<TreatmentVialsListViewModel>}
     * @private
     */
    _createVm(dm) {

        let
            /** {Array.<{TreatmentVialViewModel}>} */
            vialVmList = dm.map(this._createVialVmFromDm, /** {PatientVialsSectionController} */this);

        this.$scope.vm.vialsList.push(...vialVmList);

        return this.$q.resolve(this.$scope.vm);
    }

    _createVialVmFromDm(dm) {
        const VERBATIM_FIELDS = ["id", "href", "barcode", "status", "color"];
        const MAPPED_FIELDS = [
            { vm : "vialType", dm : "treatmentType"},
            { vm : "vialName" , dm : "name" },
            { vm : "useByDate" , dm : "useBy" },
            { vm: "beyondUseDate", dm: "beyondUse" },
            { vm : "isReplaceable", dm :"replaceable"},
            { vm : "isEscalation", dm : "escalation"}
        ];

        let vm = {};

        VERBATIM_FIELDS.forEach(aFieldName => vm[aFieldName] = dm[aFieldName]);
        MAPPED_FIELDS.forEach(aFieldMapping => vm[aFieldMapping.vm] = dm[aFieldMapping.dm]);
        vm.firstName = dm.patient.person.givenName;
        vm.lastName = dm.patient.person.familyName;
        vm._patient = /** @type{ReferenceDto.<Patient>} */{
            id: dm.patient.id,
            href: dm.patient.href
        };
        vm._treatmentConfig = /** @type{ReferenceDto.<TreatmentConfig>} */{
            id: dm.treatmentConfig.id,
            href: dm.treatmentConfig.href
        };
        vm.alert = undefined;

        vm.hasNote = false;
        if (angular.isDefined(dm.treatmentEndedBy)) {
            vm.hasNote = true;
            vm.treatmentEndedBy = angular.copy(dm.treatmentEndedBy);
        }

        return vm;
    }

    _updateVial(/** {PatientVialDataModel} */newVialState) {

        let /**
             * {PatientVialViewModel} a member of the scope representing the PatientVial which purportedly
             * underwent a value change. Alterations to its state are reflected in the UI in real time. This is
             * more efficient than re-requesting, re-marshalling and re-rendering the whole list.
             */
            subjectVialVm;

        for (let iVial in this.$scope.vm.vialsList) {
            if (this.$scope.vm.vialsList[iVial].id === newVialState.id) {
                subjectVialVm = this.$scope.vm.vialsList[iVial];
                break;
            }
        }
        subjectVialVm.href = /** {URI} */newVialState.href; // the embedded version number is updated
        subjectVialVm.status = /** {ServiceStatus} */newVialState.status;
        subjectVialVm.isReplaceable = /** {Boolean} */newVialState.replaceable;

        this.$scope.$broadcast(this.ModelStateChange);
    }

    _insertVial(/** {PatientVialDataModel} */newVial) {
        this.$scope.vm.vialsList.push( this._createVialVmFromDm(newVial));
        this.$scope.$broadcast(this.ModelStateChange);
    }

    _removeVial(/** {PatientVialDataModel} */removedVial) {

        for (let idx = 0; idx < this.$scope.vm.vialsList.length; ++idx) {
            if (this.$scope.vm.vialsList[idx].id === removedVial.id) {
                this.$scope.vm.vialsList.splice(idx, 1);
                break;
            }
        }

        this.$scope.$broadcast(this.ModelStateChange);
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                        UI/DOM Event Reactions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @param {{ _patient : {ReferenceDto.<Patient>} }} vm
     * @private
     */
    _gotoPatientDetails(vm) {
        this.routeToPage(
            this.urlPaths.PATIENT_DETAILS,
            vm._patient,
            this.routingService.createLocationParams('Patient Vials')
        );
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                         Notification Support
    //
    // Additional notification mechansims are impl'd in parent, see also:
    //     super.startAllSubscriptions,
    //     super.unsubscribeAllSubscriptions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @private
     * @returns {Promise.<void>}
     * We resolve this immediately because the service is synchronous. We reply with a Promise because everything else
     * does.
     */
    _subscribeToDmChangeNotifs() {

        this.registerSubscription(
            this.notificationService.subscribePatientVialUpdates(
                this.$scope.practice, this.$scope.office))
            .then(null, null, (notification => {
                    this._updateVial(/** {PatientVialDataModel} */notification.body);
                }
            ));

        this.registerSubscription(
            this.notificationService.subscribePatientVialCreation(
                this.$scope.practice, this.$scope.office))
            .then(null, null, (notification => {
                this._insertVial(/** {PatientVialDataModel} */notification.body);
            }));

        this.registerSubscription(
            this.notificationService.subscribePatientVialRemoval(
                this.$scope.practice, this.$scope.office))
            .then(null, null, (notification => {
                this._removeVial(/** {PatientVialDataModel} */notification.body);
            }));

        return this.$q.resolve();
    }

    /**
     * Update alerts on vials in the view according to the summary from InventoryAlertService.
     *
     * @param alertSummary see InventoryAlertService
     * @private
     */
    _updateAlerts(alertSummary) {
        if (this.$scope.vm && this.$scope.vm.vialsList) {
            for (let vial of this.$scope.vm.vialsList) {
                if (!vial._alertDismissed) {
                    vial.alert = alertSummary.icons.get(vial.id);
                }
            }
        }
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                 Session-State-Driven Event Reactions
    //
    // These are external stimuli requiring this act (that are driven by neither notifications, nor DOM via
    // scope callback.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this._initialize();
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                       Vial Editing/Replacement
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    offerReplaceVialModal(vialVm) {
        this.$uibModal.open({
            windowClass: 'vialDetails',
            scope: this.$scope, //passed current scope to the modal
            template: require("../../../../pages/common/treatment-vial-details/treatment-vial-details.html"),
            css: require("../../../../pages/common/treatment-vial-details/treatment-vial-details.scss"),
            controller : TreatmentVialDetailsController,
            resolve : {
                /** {PatientDataModel} */
                patient : () => this.patientService.get(vialVm._patient),
                /** {VialViewModel} */
                vial : () => vialVm
            }
        });
    }

    printLabel(vial) {
        this.treatmentVialService.get(vial).then((tVial) => this.labelPrinterService.printTreatmentVials(tVial));
    }

    clearInventoryAlertModal(vialVm) {
        if (!vialVm.alert) return;

        let confirmModal = this.$uibModal.open({
            windowClass: 'clearInventoryAlert',
            template: require('../../widgets/clear-inventory-alert-modal/layout.html'),
            css: require('../../widgets/clear-inventory-alert-modal/styles.scss'),
            controller: ClearInventoryAlertController,
            resolve: {
                'inventoryItem' : () => {
                    return { name: vialVm.vialName, serviceStatus: vialVm.status };
                }
            }
        });

        confirmModal.result.then(() =>  {
            this.inventoryAlertService.clearAlerts(this.$scope.practice, vialVm.id)
                .then(() => {
                    vialVm.alert = null;
                    vialVm._alertDismissed = true;
                });
        });
    }
}
