'use strict';

import angular from 'angular';

export default angular.module('widgets.password', [])
    .directive('agPasswordValidation', [agPasswordValidation])
    .directive('agPasswordConfirm', [agPasswordConfirm])
    .name;

function agPasswordValidation() {
    return {
        // restrict to an attribute type.
        restrict: 'A',
        // element must have ng-model attribute.
        require: 'ngModel',
        link: function(scope, ele, attrs, ctrl){
            // regex if contains number
            const NUM_REGEX = /[0-9]+/;

            ctrl.$validators.numberRequired = function( modelValue ) {
                if (modelValue) {
                    return NUM_REGEX.test(modelValue);
                }

                return true;
            }

            // check if symbols are here:
            // here is the list of characters that are symbols:
            // ( ) ` ~ ! @ # $ % ^ & * - + = | \ { } [ ] : ; " ' < > , . ? / _

            // regex if contains non-numeric symbol
            const SYMBOL_REGEX = /[\(\)\`\~\!\@\#\$\%\^\&\*\-\+\=\|\\\{\}\[\]\:\;\"\'\<\>\,\.\?\_]/;

            ctrl.$validators.symbolRequired = function( modelValue ) {
                if (modelValue) {
                    return SYMBOL_REGEX.test(modelValue);
                }

                return true;
            }
        }
    };
}

function agPasswordConfirm() {
    return {
        // restrict to an attribute type.
        restrict: 'A',
        // element must have ng-model attribute.
        require: 'ngModel',
        scope: {
            passwordConfirm: "=agPasswordConfirm"
        },
        link: function(scope, element, attributes, ngModel) {

            // set the validator to passwordConfirm
            ngModel.$validators.passwordConfirm = function(modelValue) {
                return modelValue === scope.passwordConfirm;
            };

            // add the watch on the model of the password confirm
            scope.$watch("passwordConfirm", function() {
                ngModel.$validate();
            });
        }
    };
}
