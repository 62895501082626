export default class AddIdtControlModalController {
    static $inject = ['$uibModalInstance', '$injector', '$scope', 'practice', 'allergyTest', 'panel']

    constructor($uibModalInstance, $injector, $scope, practice, allergyTest, panel) {
        this.$uibModalInstance = $uibModalInstance;
        this.concentrateService = $injector.get('concentrateService');
        this.substanceService = $injector.get('substanceService');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.$filter = $injector.get('$filter');
        this.$scope = $scope;

        this.practice = practice;
        this.allergyTest = allergyTest;
        this.panel = panel;

        $scope.barcodeChanged = () => this.barcodeChanged();
        $scope.remove = (index) => this.remove(index);
        $scope.clear = () => this.clear();
        $scope.close = () => this.close();

        this.init();
    }

    async init() {
        this.forcedIdtHistamine = !!this.allergyTest.idtHistamineMeasurement;
        this.removedIdtHistamine = false;
        this.availableSubstances = this.panel.substances.map(s => s.substance._dto);
        this.$scope.adHocControls = [];
        this.$scope.barcodeDisabled = false;

        for(let controlResult of this.allergyTest.controlResults) {

            let substance = this.availableSubstances.find(ps => ps.id === controlResult.substance.id);
            if (!substance) {
                substance = await this.substanceService.get(controlResult.substance);
            }
            if (substance) {
                let adHocControl = {
                    barcode: controlResult.idtFromBarcode,
                    substance: substance,
                    dilution: controlResult.idtDilution
                };

                if (substance._category._isPositiveControl) {
                    adHocControl.concentrate = this.allergyTest.idtHistamine;
                }

                if (adHocControl.barcode) {
                    this.$scope.adHocControls.push(adHocControl);
                }
            }
        }
    }

    async barcodeChanged() {
        let barcode = this.$scope.formData.barcode;
        if (barcode && barcode.length == 8) {
            this.$scope.barcodeDisabled = true;

            let prevScanned = this.$scope.adHocControls.filter(adHoc => adHoc.barcode === barcode && adHoc.dilution != -1).length;
            if (prevScanned) {
                return this._setBarcodeError('You are already testing this control.');
            }

            let concentrateVial = await this.concentrateService.getByBarcode(this.practice, barcode).catch((error) => { return; });
            // Go through each of the substances on the panel to see if it lines up with one
            let scannedSubstance = undefined;
            if (concentrateVial) {
                scannedSubstance = this.availableSubstances.find(s => s.id === concentrateVial.substance.id);
                if (!scannedSubstance) {
                    scannedSubstance = await this.substanceService.get(concentrateVial.substance);
                }
            }
            if (!scannedSubstance || !scannedSubstance._category._isControl) {
                return this._setBarcodeError('Invalid barcode scanned.');
            }

            if (!concentrateVial.forIdt) {
                return this._setBarcodeError('This control is not marked for IDT use.');
            }

            if (scannedSubstance._category._isPositiveControl) {
                if (this.forcedIdtHistamine || this.$scope.adHocControls.find(aC => aC.substance._category._isPositiveControl && aC.dilution != -1)) {
                    return this._setBarcodeError('ID Histamine can only be administered once per test.');
                }
            }

            let substanceAddedAlready = this.$scope.adHocControls.find(adHoc => adHoc.substance.id == scannedSubstance.id);
            if (substanceAddedAlready && substanceAddedAlready.dilution != -1) {
                console.log(substanceAddedAlready);
                return this._setBarcodeError('You have already scanned this control.');
            }

            if (concentrateVial.status !== this.ServiceStatus.IN_SERVICE) {
                return this._setBarcodeError('The control scanned must be ' + this.$filter('serviceStatusFilter')(this.ServiceStatus.IN_SERVICE) + '.');
            }

            concentrateVial._substance = scannedSubstance;
            if (!substanceAddedAlready) {
                this.$scope.adHocControls.push({
                    concentrate: concentrateVial,
                    barcode: concentrateVial.barcode,
                    substance: scannedSubstance,
                    dilution: concentrateVial.dilution
                });
            }
            else {
                substanceAddedAlready.concentrate = concentrateVial;
                substanceAddedAlready.barcode = concentrateVial.barcode;
                substanceAddedAlready.dilution = concentrateVial.dilution;
            }
            return this._setBarcodeError(undefined);
        }
    }

    remove(index) {
        this.$scope.adHocControls[index].dilution = -1;
        this.$scope.adHocControls[index].barcode = undefined;
    }

    clear() {
        for (let i = this.$scope.adHocControls.length - 1; i >= 0; i--){
            this.remove(i);
        }
        this.close();
    }

    close() {
        // Update all controlResults on the allergyTest, set dilution to -1 if no adHocControl created for it. otherwise set to dilution specified
        let test = this.allergyTest;
        let idtHistamineSet = false;
        for (let i = 0; i < this.$scope.adHocControls.length; i++){
            let adHocControl = this.$scope.adHocControls[i];
            let controlResult = test.controlResults.find(cR => cR.substance.id == adHocControl.substance.id);
            if (controlResult) {
                controlResult.idtDilution = adHocControl.dilution;
                controlResult.idtFromBarcode = adHocControl.barcode;
            }
            else {
                test.controlResults.push({
                    substance: adHocControl.substance,
                    idtDilution: adHocControl.dilution,
                    idtFromBarcode: adHocControl.barcode,
                });
            }

            if (adHocControl.substance._category._isPositiveControl && adHocControl.dilution >= 0) {
                idtHistamineSet = true;
                test.idtHistamine = { id: adHocControl.concentrate.id };
            }
        }
        if (!idtHistamineSet) {
            test.idtHistamine = null;
        }

        this.$uibModalInstance.close();
    }

    _setBarcodeError = function (errorMessage, keepBarcode) {
        this.$scope.barcodeError = errorMessage;
        if (!keepBarcode) {
            this.$scope.formData.barcode = '';
        }
        this.$scope.barcodeDisabled = false;

        this.$scope.$digest();
    }
}