"use strict";

import angular from "angular";
import Controller from "./controller";
import agHideOnExoclick from "../../../../common/behaviors/hide-on-exoclick";
import AppointmentDetailsController from '../../appointment-details';

// My debut controlled-AND-directed arrangement. We're doing stuff that should only happen in each layer, so both are
// needed.
export default angular.module("pages.common.standard-layout.search", [agHideOnExoclick, AppointmentDetailsController])
    /* The controller has jurisdiction on the entire INSIDE layout. Basically total overlap with the Directive, except
     * the Directive's root element is the <ag-app-search>, whereas the controller covers all elements inside it.
     */
    .controller("AppSearchController", Controller)
    /* There is exactly 1 <ag-app-search> instance in the layout. */
    .directive("agAppSearch", agAppSearch)
    .directive("agAppSearchClear", [agAppSearchClear])
    .directive('agSearchResults', ["$document", agSearchResults])
    .name;

function agAppSearch() {
    return {
        scope : true,
        restrict : "E",
        css : require("./styles.scss"),
        template : require("./layout.html"),
        controller : Controller,

        /**
         * @param scope
         * @param element
         * @param attr
         * @param controllerInst
         */
        link : function(scope, element, attr, controllerInst) {
            /**
             * @param {W3C-DomEvent.<KeyboardEvent>}
             */
            scope.onUiKeyUp =($domKeyboardEvent) =>
                controllerInst.onCriteriaChange($domKeyboardEvent.target.value)
        }
    };
}


function agAppSearchClear() {
    return {
        template: '<span ng-click="clearSearch()" ng-show="searchCriteria.length > 0"></span>',
        link: function (scope, elem, attr) {
            scope.clearSearch = function(){
                scope.searchCriteria = '';
            }
        }
    };
}

function agSearchResults($document){
    return{
        scope: {
            patients : "=",
            onAppointmentSelection: '&',
            onPatientDetails: '&',
            onClosed: "&",
            isClosed: "=",
            isLoading: '='
        },
        template: require('../../../../widgets/patient/search-results/search-results.html'),
        css: require('../../../../widgets/patient/search-results/search-results.scss'),
        link: function(scope, element, attrs) {
            element.data('search-results-container', true);

            let handler = (e) => {
                var clickArea = angular.element(e.target).inheritedData('search-results-container');
                if (!clickArea) {
                    scope.onClosed();
                }
            };

            let onOpen = () => { angular.element($document[0].body).bind('click', handler) };
            let onClose = () => { angular.element($document[0].body).unbind('click', handler); };

            scope.$watch(() => { return scope.isClosed; }, (newVal, oldVal)=> {
                if (newVal === undefined) {
                    return;
                }

                if (newVal) {
                    onOpen();
                } else {
                    onClose();
                }
            });

            scope.noResults = () => {
                return !scope.isLoading && (!scope.patients || scope.patients.length === 0);
            }
        }
    };
}