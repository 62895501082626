"use strict";

import angular from 'angular';
export default angular.module("pages.dashboard.models.DashboardSections", [])
    .constant("DashboardSections", {
        APPROVALS : "APPROVALS",
        MIXING : "MIXING",
        ORDERS_NEEDED : "ORDERS_NEEDED",
        NO_TREATMENT : "NO_TREATMENT",
        ORDERED_NOT_TESTED : "ORDERED_NOT_TESTED",
        APPOINTMENTS : "APPOINTMENTS",
        BILLING: "BILLING"
    })
    .name;

