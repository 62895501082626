
'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import MixingReviewOrderController from './mixing-review-order.controller.js';


export default angular.module('pages.mixing.reviewOrder', [ngRoute])
    .config(config)
    .controller('MixingReviewOrderController',MixingReviewOrderController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_MIXING_REVIEW, {
        template: require('./mixing-review-order.html'),
        css: require('./mixing-review-order.scss'),
        controller: 'MixingReviewOrderController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
