'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import './styles.scss';

export default class PatientMixingStatusReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.PATIENT_MIXING_STATUS);

        $scope.mixingStatusSort = (row) => this._mixingStatusSort(row);

        this.mixingStatusOrders = new Map();
        this.mixingStatusOrders.set('rx waiting approval', '1000');
        this.mixingStatusOrders.set('approved waiting for mix', '2000');
        this.mixingStatusOrders.set('mixed ready for use', '3000');
        this.mixingStatusOrders.set('in use', '4000');

        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getPatientMixingStatus(this.reportCriteria);
    }

    /* Sort by mixing status, chart number, then vial name */
    _mixingStatusSort(row) {
        let mixingStatus = (row.mixingStatus || '').toLowerCase();
        let sortString = '';


        if (!mixingStatus) {
            sortString = '';
        }
        else if (!this.mixingStatusOrders.has(mixingStatus)) {
            sortString = '0';
        }
        else {
            sortString = this.mixingStatusOrders.get(mixingStatus);
        }

        sortString += row.chartNumber + row.vialName;
        return sortString;
    }

    _getCsvMeta() {
        return [
            { name: 'Patient Name', value: 'patientName' },
            { name: 'Chart Number', value: 'chartNumber' },
            { name: 'Patient Status', value: 'patientStatus' },
            { name: 'Mixing Status', value: 'mixingStatus' },
            { name: 'Location', value: 'location' },
            { name: 'Performed By', value: 'performedBy' },
            { name: 'Billing Provider', value: 'billingProvider' },
            { name: 'Vial Name', value: 'vialName' }
        ];
    }
}
