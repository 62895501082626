'use strict';

import TestingController from '../testing.controller'

export default class TestQuestionnaireController extends TestingController {

    static $inject = ['$scope', '$injector', 'questionnaireService'];
    constructor($scope, $injector, questionnaireService) {
        super($scope, $injector);

        this.questionnaireService = questionnaireService;

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    reload() {
        console.log("Loading data in TestQuestionnaireController");
        super.validateStage(['QUESTIONNAIRE']);

        this.$scope.text = 'Record Patient Answers';

        if (!this.$scope.idtOnly) {
            this.$scope.openPanelGroupsModal = () => this.openPanelGroupsModal();
        }

        this.questionnaireService.getForVisit(this.visit).then(questionnaire => {
            if (!questionnaire.performedBy)
                questionnaire.performedBy = { label: "Questionnaire", note: "" };

            this.$scope.footerNote = questionnaire.performedBy;
            this.$scope.questionnaire = questionnaire;
        });
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        return this.questionnaireService.update(this.$scope.questionnaire).then(questionnaire => {
                this.$scope.questionnaire = questionnaire;

                return this.isPanelGroupsModified
                    ? super.update() : this.$scope.allergyTest;
            });
    }

    /**
     * @override
     */
    nextDisabled() {
        if (this.disableNext)
            return true;

        if (this.$scope.questionnaire) {
            for (let i = 0; i < this.$scope.questionnaire.answers.length; i++) {
                var q = this.$scope.questionnaire.answers[i].question;
                if ($('input[name=' + q.id + ']:checked').val() == undefined) {
                    return true;
                }
            }
        }
        else
            return true;

        return false;
    }

    /**
     * @override
     */
    next() {
        this.disableNext = true;

        this.update().then(() => {
            let questionnaire = this.$scope.questionnaire;

            if (this.$scope.warningFormData && this.$scope.warningFormData.proceedChoice === 'Reschedule') {
                this.abort("Reschedule due to questionnaire warning.");
            }
            else if (questionnaire.warning && questionnaire.warning.warnText) {

                if (questionnaire.warning.override) {
                    this.advance();
                }
                else {
                    this.$scope.warningFormData = {
                        proceedChoice: null,
                        reasonToContinue: null,
                        addNotes: null,
                        warnings: []
                    };

                    let warnText = questionnaire.warning.warnText;

                    let spitText = warnText.replace(/\n/g, "~").split("~");// split text by /n
                    spitText.forEach(str => {
                        if (str) {
                            this.$scope.warningFormData.warnings.push(str);
                        }
                    });

                    this.warningModal();
                    this.disableNext = false;
                }
            } else {
                this.advance();
            }
        });
    };

    warningModal() {
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./testing-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.reset = () => {
                    $scope.warningFormData.reasonToContinue = '';
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    if ($scope.warningFormData.proceedChoice === 'Continue' && $scope.warningFormData.reasonToContinue) {
                        return ($scope.warningFormData.reasonToContinue !== 'Other' || $scope.warningFormData.addNotes);
                    }
                    else {
                        return ($scope.warningFormData.proceedChoice === 'Reschedule');
                    }
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.continue = () => {
                    $uibModalInstance.close('success');

                    if ($scope.warningFormData.proceedChoice === 'Reschedule') {
                        $scope.questionnaire.warning.override = {note: 'Reschedule'};
                        $scope.next();
                    }
                    else if ($scope.warningFormData.reasonToContinue === 'Other' && $scope.warningFormData.addNotes) {
                        $scope.questionnaire.warning.override = {note: $scope.warningFormData.addNotes};
                        $scope.next();
                    }
                    else if ($scope.warningFormData.reasonToContinue === 'Reenter') {
                        // Just go back to page for edit
                    }
                    else {
                        $scope.questionnaire.warning.override = {note: $scope.warningFormData.reasonToContinue};
                        $scope.next();
                    }
                }
            }
        });
    }
}
