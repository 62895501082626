'use strict';

import angular from 'angular';

export default angular.module('widgets.agFitlers', [])
    .filter('agPhoneNumber', [phoneNumberFilter])
    .filter('agActivityName', [activityTypeFilter])
    .filter('agVialAction', [vialActionFilter])
    .filter('agTreatmentAction', [treatmentActionFilter])
    .filter('agFormulation', [formulationFilter])
    .filter('combinedFilter', [combinedFilter])
    .directive('agNumberInput', [agNumberInput])
    .directive('agPhoneNumberInput', agPhoneNumberInput)
    .name;

// ----------------------------------------------------
// Author: Alexandra Berke (aberke)
// Written: Summer 2014
// ----------------------------------------------------
// see gist: https://gist.github.com/aberke/042eef0f37dba1138f9e
function phoneNumberFilter() {
    /*
     Format phonenumber as: c (xxx) xxx-xxxx
     or as close as possible if phonenumber length is not 10
     if c is not '1' (country code not USA), does not use country code
     */

    return function (number) {
        /*
         @param {Number | String} number - Number that will be formatted as telephone number
         Returns formatted number: (###) ###-####
         if number.length < 4: ###
         else if number.length < 7: (###) ###

         Does not handle country codes that are not '1' (USA)
         */
        if (!number) { return ''; }

        number = String(number).replace(/[^0-9]+/g, '');;

        // Will return formattedNumber.
        // If phonenumber isn't longer than an area code, just show number
        var formattedNumber = number;

        // if the first character is '1', strip it out and add it back
        var c = (number[0] == '1') ? '1 ' : '';
        number = number[0] == '1' ? number.slice(1) : number;

        // # (###) ###-#### as c (area) front-end
        var area = number.substring(0,3);
        var front = number.substring(3, 6);
        var end = number.substring(6, 10);

        if (front) {
            formattedNumber = (c + "(" + area + ") " + front);
        }
        if (end) {
            formattedNumber += ("-" + end);
        }
        return formattedNumber;
    };
}

/** @see http://stackoverflow.com/questions/22057481/a-directive-to-format-phone-number */
function agPhoneNumberInput() {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs, ctrl, ngModel) {
            elem.on('input', (event) => {
                var input = elem.val();
                // Strip all characters from the input except digits
                input = input.replace(/\D/g, '');

                // Trim the remaining input to ten characters, to preserve phone number format
                input = input.substring(0, 10);

                // Based upon the length of the string, we add formatting as necessary
                var size = input.length;
                if (size === 0) {
                } else if (size < 4) {
                    input = '(' + input;
                } else if (size < 7) {
                    input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
                } else {
                    input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
                }
                elem.val(input);
            });
        }
    }
}

/***
 * agNumberInput : strips out non-numeric characters from an input.
 *                 optionally - ensures the sanitized value is less than or equal to a max value (must be greater than zero). Also, by default zero is accepted, set 'allowZero' to false to ensure a positive number.
 *
 * usage : <input type="text" ng-model="yourModel" ag-number-input="{allowZero:true, max:100}" />
 * @returns {{require: string, restrict: string, link: link}}
 */
function agNumberInput() {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, ele, attrs, ctrl) {
            var opts = attrs.agNumberInput;
            if (opts) {
                opts = scope.$eval(opts);
            }

            let sanitizeInput = (value) => {
                let sanitizedValue = value.replace(/[^0-9]+/g, '');

                // Check for max value and zero values.
                if (opts) {
                    if (sanitizedValue.length === 1 && opts.allowZero !== undefined && !opts.allowZero && sanitizedValue[0] == '0') {
                        sanitizedValue = "";
                    } else if (opts.max && parseInt(sanitizedValue) > opts.max) {
                        sanitizedValue = sanitizedValue.substring(0, sanitizedValue.length - 1);
                    }
                }

                return sanitizedValue;
            };

            ctrl.$parsers.unshift((inputValue) => {
                var sanitizedValue = sanitizeInput(inputValue);

                if (inputValue != sanitizedValue) {
                    ctrl.$setViewValue(sanitizedValue);
                    ctrl.$render();
                }

                return sanitizedValue;
            });
        }
    }
}

function activityTypeFilter() {
    return (type) => {
        let displayName = type;

        switch (type) {
            case "MQT_TESTING":
                displayName = "MQT Testing";
                break;
            case "SPT_TESTING":
                displayName = "SPT Testing";
                break;
            case "CLASSICAL_SPT_TESTING":
                displayName = "Traditional SPT Testing";
                break;
            case "CLASSICAL_SPTIDT_TESTING":
                displayName = "Traditional SPT+IDT Testing";
                break;
            case "SCIT_TREATMENT":
                displayName = "SCIT Treatment";
                break;
            case "SLIT_TREATMENT":
                displayName = "SLIT Treatment";
                break;
            case "VIAL_TEST":
                displayName = "Vial Test";
                break;
            case "VIAL_TEST_SCIT_TREATMENT":
                displayName = "Vial Test/SCIT Treatment";
                break;
            case "VIAL_TEST_SLIT_TREATMENT":
                displayName = "Vial Test/SLIT Treatment";
                break;
            case "NEW_RX":
                displayName = "Prescription";
                break;
            case "APPROVED_SCIT_RX":
                displayName = "SCIT Prescription";
                break;
            case "APPROVED_SLIT_RX":
                displayName = "SLIT Prescription";
                break;
            case "CANCELLED_RX":
                displayName = "Prescription";
                break;
            case "MIXED_SCIT_RX":
                displayName = "SCIT Prescription Mix";
                break;
            case "MIXED_SLIT_RX":
                displayName = "SLIT Prescription Mix";
                break;
            case "HISTORICAL_RX":
                displayName = "Historical Prescription";
                break;
            case "PLAN_UPDATE":
                displayName = "Updated Treatment plan";
                break;
            default:
        }

        return displayName;
    }
}

function vialActionFilter() {
    return (action) => {
        let displayName = action;

        switch (action) {
            case "ADVANCE":
                displayName = "Advance Prescription";
                break;
            case "REPEAT":
                displayName = "Repeat Prescription";
                break;
            case "DILUTE_NEW":
                displayName = "Dilute Prescription";
                break;
            default:
        };

        return displayName;
    }
}

function treatmentActionFilter() {
    return (action) => {
        let displayName = action;

        switch (action) {
            case "NONE":
                displayName = "No Action!";
                break;
            case "VIAL_TEST":
                displayName = "Vial Test";
                break;
            case "TREAT":
                displayName = "Treatment";
                break;
            case "NO_VIAL_TEST":
                displayName = "No Vial Test";
                break;
            case "NO_TREAT":
            case "NO_TREAT_TOO_EARLY":
            case "NO_TREAT_EXPIRED":
                displayName = "No Treatment";
                break;
            case "CONSULT":
            case "CONSULT_TOO_LATE":
            case "CONSULT_TOO_LARGE":
                displayName = "Consult Doctor";
                break;
            default:
        };

        return displayName;
    }
}

/**
 * Use: {{concentrate | agFormulation}}
 * Where 'concentrate' has signature { potency: number, potencyUnit: string, capacity: number}
 */
function formulationFilter() {
    return (concentrate) => {
        if (concentrate.potency == undefined || concentrate.potencyUnit == undefined)
            return '';

        switch (concentrate.potencyUnit) {
            case 'W/V':
                return '1:' + (1 / concentrate.potency) + ' W/V';

            case 'mL':
                if (concentrate.capacity)
                    return (concentrate.potency * concentrate.capacity).toLocaleString() + ' mL';
                else
                    return '';

            default:
                return Number(concentrate.potency).toLocaleString() + ' ' + concentrate.potencyUnit + '/mL';
        }
    };
}

function combinedFilter(){
    return function(searchedItems, filterInput) {
        var out = [];

        let searchTerm = filterInput.search;
        let combinedFields = filterInput.combinedFields;
        
        if (Array.isArray(searchedItems)) {
            searchedItems.forEach(function(searchedItem) {
                // Combine properties so end user can search, for example, given and family names at the same time
                let searchedItemString = "";
                combinedFields.forEach(function(fieldToCombine) {
                    // Functionality to select sub properties within objects, e.g. provider.person.givenName
                    let subProperties = fieldToCombine.split('.');
                    let itemSubProperty = searchedItem;

                    let finalSubProperty = subProperties[subProperties.length - 1];
                    subProperties.forEach(function(subProperty) {
                        if(itemSubProperty === undefined)
                            return;

                        itemSubProperty = itemSubProperty[subProperty];

                        if(subProperty === finalSubProperty && itemSubProperty !== undefined) {
                            searchedItemString += itemSubProperty + ' ';
                        }
                    });
                });

                if(searchedItemString.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                    out.push(searchedItem);
                }
            });
        } else {
            out = searchedItems;
        }
    
        return out;
    }
}