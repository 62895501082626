'use strict';

// Stations a patient may be at in an office.
export default angular.module("models.station", [])
    .constant("Station", {
        NONE: 'NONE',
        WAITING_ROOM: 'WAITING_ROOM',
        TREATMENT: 'TREATMENT',
        TESTING: 'TESTING',
        ALL: 'ALL'
    })
    .filter('stationFilter', stationFilter)
    .name;

function stationFilter() {
    return (type) => {
        return type
            .toLowerCase()
            .replace(/_/, ' ')
            .replace(/(^| )(\w)/g, (c) => c.toUpperCase());
    };
}
