'use strict';

import BaseService from './base.service.js';

export default class TreatmentVialService extends BaseService {

    getById(treatmentVialId) {
        let href = `/api/treatmentVials/${treatmentVialId}`;
        return this.serverAPI.get(href);
    }

    getByBarcode(practice, barcode) {
        return this.serverAPI.get(practice._links.findTreatmentVialByBarcode, { barcode: barcode }, undefined, [404]);
    }

    /**
     * Get PatientVials at an Office.
     *
     * A PatientVial DTO is an alternative view of a TreatmentVial, containing exactly what is needed
     * for the Inventory / Patient Vials screen. They are more light-weight, since we'll have to deal
     * with large lists of these.
     *
     * @param office DTO
     * @param status (optional) Only vials with this ServiceStatus
     * @return Promise to a PatientVial.List of matches
     */
    getPatientVials(office, status, page, pageSize) {
        return this.serverAPI.get(office._links.patientVials, { status:status, page:page, pageSize:pageSize });
    }

    /**
     * Get PendingPatientVials for a patient
     *
     * "Pending" patient vials are those that have been prescribed (even if not yet approved) for a patient
     * but not yet manifest of In-Service TreatmentVials, for various reasons.
     *
     * @param patient DTO
     * @return Promise to a PendingPatientVial.List of matches
     */
    getPendingPatientVials(patient) {
        return this.serverAPI.get(patient._links.pendingVials);
    }

    /**
     * Get PrescribedVials created from a TreatmentVial.
     *
     * @param treatmentVial DTO
     * @return Promise to an array of matching PrescribedVials
     */
    getNextPrescribed(treatmentVial) {
        return this.serverAPI.get(treatmentVial._links.nextPrescribed);
    }

    /**
     * Get Injections given from a TreatmentVial.
     *
     * @param treatmentVial DTO
     * @return Promise to an array of matching Injections
     */
    getInjections(treatmentVial) {
        return this.serverAPI.get(treatmentVial._links.injections);
    }

    /**
     * Get Injections given from a TreatmentVial.
     *
     * @param patient DTO
     * @return Promise to an array of matching TreatmentVials
     */
    getRecentlyExpired(patient) {
        return this.serverAPI.get(patient._links.recentlyExpiredVials);
    }
}
