"use strict";

/**
 * Enum field values from the Unapproved DTO.
 */
export default angular.module("models.unapproved", [])
    .constant("UnapprovedType", {
        TEST : "TEST",
        MIX : "MIX",
        PRESCRIPTION : "PRESCRIPTION",
        TREATMENT : "TREATMENT",
        TEST_REVIEW : "TEST_REVIEW"
    })
    .constant("UnapprovedStatus", {
            NEW : "New",
            RESUBMITTED : "Resubmitted",
            INCOMPLETE : "Incomplete",
            VIEWED : "Viewed",
            CANCELLED: "Cancelled",
            APPROVED: "Approved"
        }
    ).name;
