'use strict';

window._prum = '';

export default class AnalyticsService {
    static $inject = ['$timeout', 'serverAPI'];
    
    constructor($timeout, serverAPI) {
        this.$timeout = $timeout;
        this.serverAPI = serverAPI;
        this.initialized = false;
        window.ga = null;
        this.q = [];
        this.queueScheduled = false;
        
        this.serverAPI.getGlobalLinks().then((links) => {
            if (links.gaTrackingId) {
                this._initializeGa(links.gaTrackingId);
                this.initialized = true;
            } else {
                this._warnNotInitialized('GA');
            }
            
            if (links.rumTrackingId) {
                this._initializeRum(links.rumTrackingId);
            } else {
                this._warnNotInitialized('RUM');
            }
        });
    }
    
    _initializeGa(gaTrackingId) {
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        window.ga('create', gaTrackingId, 'auto');
    }
    
    _initializeRum(rumTrackingId) {
        window._prum = [['id', rumTrackingId],
             ['mark', 'firstbyte', (new Date()).getTime()]];
        (function() {
            var s = document.getElementsByTagName('script')[0]
              , p = document.createElement('script');
            p.async = 'async';
            p.src = '//rum-static.pingdom.net/prum.min.js';
            s.parentNode.insertBefore(p, s);
        })();
    }
    
    _warnNotInitialized(type) {
        console.log(`No ${type} tracking ID found: could not initialize ${type} analytics service`);
    }
    
    /**
      * Release the page view queue if the analytics code has finished loading. Otherwise
      * re-schedule the queue to be released
      */
    _releaseQueue(milliseconds) {
        if (window.ga) {
            let q = this.q;
            this.q = null;
            for(let queuedPage of q) {
                window.ga('send', 'pageview', queuedPage);
            }
        } else {
            /**
              * If we haven't loaded the analytics code after one minute something is clearly
              * wrong and there's no reason to continue scheduling the queue to be released
              */             
            const ONE_MINUTE = 60000;
            if (milliseconds > ONE_MINUTE) {
                this._warnNotInitialized();
            } else {
                this._scheduleQueue(milliseconds * 2);
            }
        }
    }
    
    /**
      * Schedule the page view queue to be released
      */
    _scheduleQueue(milliseconds) {
        this.$timeout(() => {
            this._releaseQueue(milliseconds)
        }, milliseconds);
    }
    
    sendPageView(page) {
        if (window.ga) {
            window.ga('send', 'pageview', page);
        } else {
            /**
              * We may receive requests to send page views before the analytics code has finished loading.
              * For such cases, we store the page view in a queue and release the queue when the analytics
              * code has finished loading
              */
            if (this.q) {
                this.q.push(page);
                if (!this.queueScheduled) {
                    this.queueScheduled = true;
                    this._scheduleQueue(100);
                }
            }
        }
    }
}
