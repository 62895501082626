'use strict';

import AddIdtControlModalController from './add-idt-control-modal.controller';
import TestingController from '../testing.controller'

class SptAntigenView {
    /** @type{string} Substance ID of SPT antigen */
    sptId;
    /** @type{string} Name used during SPT */
    sptAntigenName;

    /** @type{number} List position during SPT */
    sptPos;
    /** @type{number|string} Measured SPT wheal reaction value or 'Not Tested' */
    sptWheal;
    /** @type{number|string} Measured SPT erythema reaction value or 'Not Tested' */
    sptErythema;
    /** @type{boolean} Does SPT result indicate a reaction? */
    sptReaction;

}

/** Declaration helps with code assistance in IDE */
class IdtSubstanceView {
    /** @type{SptAntigenView[]} SPT results that map to this IDT */
    spt;

    /** @type{number} List position in panel & antigenResults */
    panelPos;

    /** @type{string} Substance ID of the substance to be tested */
    substanceId;
    /** @type{string} Name used during IDT */
    idtAntigenName;
    /** @type{number} List position during IDT */
    idtPos;

    /** @type{boolean} Apply this antigen for IDT? */
    applyIdt;
    /** @type{boolean} Original value of applyIdt - used to detect change */
    prevApplyIdt;

    /** @type{string} Lot number of concentrate to draw from, or 'multiple' if more than one option */
    lotNum;

    /** @type{string[]} Barcodes of available concentrates */
    availableBarcodes;
    /** @type{string} Concentrate barcode scanned for this antigen */
    barcodeInput;
    /** @type{string|undefined} Barcode that has been successfully scanned */
    idtFromBarcode;
    /** @type{boolean} Is the barcode input a failure? */
    error;

    /** @type{boolean} Display this antigen in the list? */
    isShown;
    /** @type{boolean} Original value of isShown - used to detect change */
    prevIsShown;

    /** @type{boolean} Is this concentrate available and in service? */
    available;

    /** @type{boolean} Is this concentrate a control? */
    isControl;
    /** @type{boolean} Is this concentrate a positive control? */
    isPositiveControl;
}

/**
 * The "Prepare IDT" step is entirely different for Classical vs Otolaryngic workflows.
 * We're forced to implement them together here though; sub-classing doesn't do the trick,
 * and mixins are a mess.
 *
 * This implementation is thus split into sections. The top has common code, then Otolaryngic,
 * then Classical.
 */
export default class TestPrepareIntradermalController extends TestingController {

    /////////////////////////////////////////////////////////////////////////
    ////////////////////         Common Code          ///////////////////////
    /////////////////////////////////////////////////////////////////////////

    static $inject = ['$scope', '$injector', 'boardService', 'checklistService', 'panelService', 'substanceService', 'substanceCategoryService'];
    constructor($scope, $injector, boardService, checklistService, panelService, substanceService, substanceCategoryService) {

        super($scope, $injector);

        this.boardService = boardService;
        this.checklistService = checklistService;
        this.boardService = $injector.get('boardService');
        this.concentrateService = $injector.get('concentrateService');
        this.globalConfigService = $injector.get('globalConfigService');
        this.panelService = panelService;
        this.substanceService = substanceService;
        this.substanceCategoryService = substanceCategoryService;

        this.disableNext = true;

        this.allergyTestLoaded()
            .then(() => this._loadAllergyTestConfig())
            .then(() => this._loadPanel())
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    /**
     * Initialize/reinitialize controller.
     * @returns {Promise}
     */
    reload() {
        super.validateStage(['IDT_PREPARE']);

        this.$scope.text = 'Prepare Intradermal Test';
        this.initFooterNote('Prepare Intradermal');

        this.$scope.isClassical = !!this.testConfig.classicalIdt;
        this.$scope.isOtolaryngic = !this.$scope.isClassical;
        this.$scope.skipIDT = false;
        this.$scope.chunkedData = [];

        if (this.$scope.isClassical) {
            return this.reloadClassical();
        }
        else {
            return this.reloadOtolaryngic();
        }
    }

    /**
     * Is the Next button disabled?
     *
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext)
        return true;
        
        if (this.$scope.isClassical)
            return this.nextDisabledClassical();
        else
            return false;
        }

    /**
     * Control for what the next buttons does.
     * @override
     */
    next() {
        if (this.$scope.isClassical)
            this.nextClassical();
        else
            this.nextOtolaryngic();
    }

    /**
     * Save if something changed.
     * @override
     */
    update() {
        this.updateFooterNote();
        return super.update();
    }


    /////////////////////////////////////////////////////////////////////////
    ////////////////////         Classical            ///////////////////////
    /////////////////////////////////////////////////////////////////////////

    /**
     * undefined = init or otolaryngic,
     * 'select' = select antigens.
     * 'skip' = skip ID (nothing selected).
     * 'checklist' = checklist.
     * 'scan' = barcode scan vials
     */
    classicalPrepStep;

    /** @type{Array<Concentrate>} All concentrates in service at the office */
    inServiceConcentrates;

    /** @type{Map<string,Concentrate[]>} Map of substanceId to array of Concentrates that may be used for it. */
    substanceConcentrates = new Map();

    /**
     * Initialize/reinitialize controller for Classical allergy.
     * Called by common reload().
     * @returns {Promise}
     */
    async reloadClassical() {
        await this._loadConcentrates(true);

        if (this.visit.procedure === 'IDT') {
            this.prevAllergyTest = await this.allergyTestService.getLeadingAllergyTest(this.$scope.allergyTest);
        }

        this._buildClassicalAntigenArray();
        this.$scope.substancesChecked = false;
        this.$scope.suppliesChecked = false;
        this.$scope.idtDosage = this.testConfig.idtDosage;

        this._loadGlobalConfig().then();

        await this._loadClassicalChecklist();

        this.nextClassical();
    }

    /**
     * Is the Next button disabled for Classical?
     * Called by common nextDisabled().
     *
     * @returns {boolean}
     */
    nextDisabledClassical() {
        switch (this.classicalPrepStep) {
            case 'select': {
                return true;
            }
            case 'skip': {
                return false;
            }
            case 'checklist': {
                if (!this.$scope.substancesChecked || !this.$scope.suppliesChecked)
                    return true;

                for (let idx = 0; idx < this.$scope.itemLabels.length; ++idx) {
                    if (!this.$scope.itemChecked[idx])
                        return true;
                }

                return false;
            }
            case 'scan': {
                const isIncomplete = this.$scope.substancesToTest.find(a => !a.idtFromBarcode || a.error);
                return isIncomplete;
            }
        }
    }

    /**
     * Control for what the next buttons does for Classical tests
     * @override
     */
    nextClassical() {
        if (!this.classicalPrepStep) {
            this.classicalPrepStep = this.$scope.classicalPrepStep = 'select';
            if (this.visit.procedure === 'IDT') {
                // Don't show the Administer Idt Prompt if the visit itself is an IDT visit
                this.disableNext = false;
                this.classicalPrepStep = 'checklist';
                this._showIntradermalSelectionModal();
            }
            else {
                this._showAdministerIdtPrompt()
                    .then((showIdtSelection) => {
                        if (showIdtSelection) {
                            this._showIntradermalSelectionModal();
                        }
                    })
                    .catch((showIdtSelectionModal) => {
                        /* If the user cancels from the administer IDT prompt, they will be sent
                         * back to the intradermal selection modal */
                        if (showIdtSelectionModal) {
                            this.classicalPrepStep = undefined;
                            this.nextClassical();
                        }
                    });
            }
        }
        else {
            switch (this.classicalPrepStep) {
                case 'select': {
                    this.disableNext = false;
                    this.classicalPrepStep = this.$scope.classicalPrepStep =
                        this.$scope.substancesToTestCount > 0 ? 'checklist' : 'skip';
                    break;
                }
                case 'skip': {
                    super.next();
                    break;
                }
                case 'checklist': {
                    this.classicalPrepStep = this.$scope.classicalPrepStep = 'scan';
                    this.disableNext = false;
                    this._checkAllAntigensAvailable();
                    break;
                }
                case 'scan': {
                    this._updateResultsFromBarcodeScan();
                    super.next();
                    break;
                }
            }
        }
    }

    /**
     * Display a prompt when doing Classical SPT+IDT to ask if user actually wants to proceed with IDT.
     */
    _showAdministerIdtPrompt() {
        let modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('./administer-idt-modal.html'),
            css: require('./administer-idt-modal.scss'),
            scope: this.$scope,
            controller: function ($uibModalInstance, $scope) {
                $scope.selection = null;

                $scope.done = () => $uibModalInstance.close($scope.selection);
                $scope.cancel = () => $uibModalInstance.dismiss(true);
            }
        });

        return modalInstance.result.then(administerSelection => {
            let showIdtSelectionModalAfter = false;
            let doIdt = administerSelection !== "NO";
            if (!doIdt) {
                // What do?
                this.$scope.allergyTest.idtLocation = 'NONE';
                this.update().then(() => {
                    this.classicalPrepStep = 'skip';
                    this.next();
                });
            }
            else {
                let doIdtToday = administerSelection === 'NOW';
                if (!doIdtToday) {
                    this.$scope.allergyTest.idtLocation = 'NONE';
                    this.classicalPrepStep = this.$scope.classicalPrepStep = 'skip';

                    this._loadAppointment().then(() => {
                        this.$scope.allergyTest.idtLater = true;
                        return this.update();
                    }).then(() => {
                        let leadAppointment = this.$scope.idtOnly ? this.appointment.leadAppointment : this.appointment;

                        let appt = {
                            office: this.visit.office,
                            patient: this.$scope.patient,
                            provider: this.appointment.provider,
                            procedure: 'IDT',
                            allergyTestConfig: this.$scope.allergyTest.config,
                            panel: this.appointment.panel,
                            leadAppointment: { id: leadAppointment.id }
                        };

                        if (this.$scope.idtOnly) {
                            let onExitConfirmation = () => this.appointmentService.createForPatient(appt.patient, appt);
                            let onExitCancellation = () => {
                                this.classicalPrepStep = undefined;
                                this.nextClassical();
                            };

                            // If we are already in an IDT only appointment, display exit modal to abort the current test so it can be restarted at a later date/time
                            this.exitModal(onExitConfirmation, onExitCancellation);
                        }
                        else {
                            // Create the next appointment for IDT to be completed later, then proceed to next step
                            this.appointmentService.createForPatient(appt.patient, appt);
                            this.nextClassical();
                        }
                    });
                }
                else {
                    this.nextClassical();
                    // Return value dictates if the intradermal selection modal is showed after or not
                    showIdtSelectionModalAfter = true;
                }
            }

            return showIdtSelectionModalAfter;
        });
    }

    _showIntradermalSelectionModal() {
        let modal = this.$uibModal.open({
            windowClass: 'intradermalSelectionModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./intradermal-selection-modal.html'),
            styles: require('./intradermal-selection-modal.scss'),
            backdrop: 'static',
            keyboard: false,
            controller: function ($uibModalInstance, $scope) {
                $scope.allAvailable = true;

                $scope.continue = () => {
                    $scope.allAvailable = true;
                    for (let entry of $scope.substances) {
                        if (!entry.applyIdt) continue;

                        entry.available = entry.availableBarcodes.length > 0;
                        if (!entry.available) {
                            $scope.allAvailable = false;
                        }
                    }

                    if ($scope.allAvailable) {
                        $uibModalInstance.close();
                    }
                }

                $scope.substanceEntryToAdd = null;
                $scope.addSubstance = () => {
                    let substance = $scope.substanceEntryToAdd;
                    if (substance) {
                        substance.applyIdt = true;
                        substance.isShown = true;
                        if (substance.spt.length === 0) {
                            substance.spt.push({
                                sptId: substance.id,
                                sptAntigenName: substance.idtAntigenName,
                                sptPos: 0,
                                sptWheal: 'Not Tested',
                                sptErythema: 'Not Tested',
                                sptReaction: false
                            });
                        }
                        substance.viewIndex = substance.viewIndex || (Math.max($scope.substances.map(e => e.viewIndex)) + 1);
                        $scope.substanceEntryToAdd = null;
                    }
                };

                $scope.getElementStyle = (otherElementId, verticalAlign) => {
                    let sibling = document.getElementById(otherElementId);
                    if (sibling !== null) {
                        let height = sibling.offsetHeight || 10;

                        let result = {
                            'height': height + 'px'
                        };

                        if (verticalAlign) {
                            result['line-height'] = height + 'px';
                        }

                        return result;
                    }

                    return null;
                }
            }
        });

        // When modal is successfully closed, update allergyTest
        return modal.result.then(() => {
            for (let entry of this.$scope.substances) {
                // Skip if no change
                if (entry.applyIdt === entry.prevApplyIdt && entry.isShown === entry.prevIsShown)
                    continue;
                let measurements = (entry.applyIdt)
                    ? { wheal: null, erythema: null, position: null}
                    : { wheal: -1, erythema: -1, position: -1 }; // -1 = NOT TESTED
                let idtDilution = entry.applyIdt ? /*concentrate*/0 : /*not tested*/ -1;

                let anyResult;
                if (entry.isControl) {
                    anyResult = this.$scope.allergyTest.controlResults.find(m => m.substance.id === entry.substanceId);
                    if (!anyResult) {
                        // This could be a control that's being added adhoc, need to create the controlResult for it
                        anyResult = {
                            substance: { id: entry.substanceId }
                        }
                        this.$scope.allergyTest.controlResults.push(anyResult);
                    }
                } else {
                    anyResult = this.$scope.allergyTest.antigenResults.find(m => m.substance.id === entry.substanceId);
                }
                anyResult.idtDilution = idtDilution;
                anyResult.idtMeasurements = measurements;
            }

            this._buildClassicalAntigenArray();
        });
    }

    async _buildClassicalAntigenArray() {
        this.$scope.measureWheal = false;
        this.$scope.measureErythema = false;

        /*
         * If this is an IDT only appointment, the SPT results will be in the previous allergy test. This is complicated because the SPT measurements
         * to use here will be on the current test if this is an SPT or SPT+IDT appointment, but on the leading test if this is the IDT later appointment.
         * In both cases the decision to administer IDT or not for each antigen is saved to this.allergyTest.
         */
        let sptResultsBySubstance = new Map();
        (this.prevAllergyTest ? [... this.prevAllergyTest.antigenResults, ... this.prevAllergyTest.controlResults] : [... this.$scope.allergyTest.antigenResults, ... this.$scope.allergyTest.controlResults])
            .forEach(r => {
                    sptResultsBySubstance.set(r.substance.id, r.sptMeasurements);
            });
        let antigenResults = this.$scope.allergyTest.antigenResults;
        let controlResults = this.$scope.allergyTest.controlResults;

        const panel = this.$scope.panel;
        let panelSubstMap = new Map();
        panel.substances.forEach(pS => {
            if (pS.idtPos > -1) {
                panelSubstMap.set(pS.idtPos, pS);
            }
        });
        /** @type{Map<SubstanceId, IdtSubstanceView>} */
        let substanceMap = new Map();
        let canTestIdtHistamine = !!this.testConfig.idtHistamine;
        let hasWheal = (size) => (this.$scope.measureWheal || (size !== false && size != null && size !== -1)) ? true : false;
        let hasErythema = (size) => (this.$scope.measureErythema || (size !== false && size != null && size !== -1)) ? true : false;
        let measuredSize = (size) => (size !== false && size != null && size !== -1) ? size : 'Not Tested';

        let maxViewIndex = -1;
        /*
         * Build list of IDT Antigens
         */
        for (let testResult of [...antigenResults, ...controlResults]) {
            let substance;
            let panelSubs = panel.substances.find(ps => ps.substance.id == testResult.substance.id);
            if (panelSubs) {
                substance = panelSubs.substance;
                if (substance._dto) {
                    panelSubs.substance = substance = substance._dto;
                }
            } else {
                substance = await this.substanceService.get(testResult.substance);
            }
            let mapToPanelSubs = panelSubs;
            let mappingToSubstance = false;
            if (panelSubs && panelSubs.mapToIdtPos > -1) {
                if (panelSubstMap.get(panelSubs.mapToIdtPos)) {
                    mapToPanelSubs = panelSubstMap.get(panelSubs.mapToIdtPos);
                    mappingToSubstance = true;
                }
            }
            let mappedToSubstance = mapToPanelSubs ? mapToPanelSubs.substance : substance;

            let category = substance.category;
            if (category._dto) {
                category = category._dto;
            }
            let concList = this.substanceConcentrates.get(mappedToSubstance.id) || [];

            let sptMeasurements = sptResultsBySubstance.get(substance.id);
            let measuredSpt = sptMeasurements && sptMeasurements.wheal != null && sptMeasurements.wheal != undefined && sptMeasurements.wheal != -1;
            let measuringIdt = testResult.idtDilution > -1;
            let shownByDefault = measuredSpt || measuringIdt;
            let entry = substanceMap.get(mappedToSubstance.id) || {
                substanceId: mappedToSubstance.id,
                idtAntigenName: mappedToSubstance.name,
                panelPos: mapToPanelSubs ? mapToPanelSubs.panelPos : undefined,
                idtPos: mapToPanelSubs && mapToPanelSubs.idtPos != null ? mapToPanelSubs.idtPos : undefined,
                applyIdt: testResult.idtDilution === 0,
                prevApplyIdt: testResult.idtDilution === 0,
                availableBarcodes: concList.map(conc => conc.barcode),
                barcodeInput: '',
                lotNum: concList.length ? concList.map(conc => conc.lot).reduce((a, v) => (a !== undefined && a !== v) ? undefined : v) || 'multiple' : '',
                error: false,
                spt: [],
                isShown: shownByDefault,
                prevIsShown: shownByDefault,
                available: true,
                isControl: category._isControl,
                isPositiveControl: category._isPositiveControl
            };
            entry.idtAntigenName = mappedToSubstance.name;

            if (sptMeasurements && hasWheal(!!sptMeasurements && sptMeasurements.wheal) && sptMeasurements.wheal !== undefined) {
                // We do not want to add a spt entry if it was not tested in SPT (particularly w/ mapped to substances)
                // If there is no panel substance, then it's an adhoc control
                if (!panelSubs || panelSubs.sptPos != -1) {
                    const sptEntry = {
                        sptId: substance.id,
                        sptAntigenName: substance.name,
                        sptPos: panelSubs ? panelSubs.sptPos : undefined,
                        sptWheal: measuredSize(!!sptMeasurements && sptMeasurements.wheal),
                        sptErythema: measuredSize(!!sptMeasurements && sptMeasurements.erythema),
                        sptReaction: testResult.treat
                    };
                    entry.spt.push(sptEntry);
                }
            }
            if (this.$scope.isClassical && mappingToSubstance) {
                // In Classical, test a mapped substance only if all of the substances mapped to it had no reaction
                let doTest = entry.applyIdt || (entry.spt.length != 0 && entry.spt.filter(s => s.sptReaction).length == 0);
                entry.applyIdt = doTest;
            }
            this.$scope.measureWheal = hasWheal(!!sptMeasurements && sptMeasurements.wheal);
            this.$scope.measureErythema = hasErythema(!!sptMeasurements && sptMeasurements.erythema);
            entry.prevApplyIdt = entry.prevApplyIdt || testResult.idtDilution === 0;
            entry.isShown = entry.prevIsShown = entry.spt.length > 0 || entry.applyIdt;

            //Handle View Indexes - Adhoc Antigens are not possible at this time, so the view index is the idt position
            entry.viewIndex = isNaN(entry.idtPos) ? -1 : entry.idtPos;
            maxViewIndex = Math.max(maxViewIndex, entry.viewIndex);

            substanceMap.set(mappedToSubstance.id, entry);
        }

        for (let control of this.inServiceControls) {
            let substance = control._substance;
            let category = substance._category;

        /** @type{IdtSubstanceView} */
            let entry = substanceMap.get(substance.id);
            if (!entry) {
                maxViewIndex++;
                entry = {
                    substanceId: substance.id,
                    idtAntigenName: substance.name,
                    panelPos: undefined,
                    idtPos: undefined,
                    applyIdt: false,
                    prevApplyIdt: false,
                    availableBarcodes: [],
                    barcodeInput: '',
                    lotNum: control.lot,
                    error: false,
                    spt: [],
                    isShown: false,
                    prevIsShown: false,
                    available: control.forIdt,
                    isControl: true,
                    isPositiveControl: category._isPositiveControl,
                };
            }
            if (control.forIdt) {
                entry.availableBarcodes.push(control.barcode);
            }
            substanceMap.set(entry.substanceId, entry);
        }

        for (let substance of substanceMap.values()) {
            if (isNaN(substance.viewIndex) || substance.viewIndex == -1) {
                maxViewIndex++;
                substance.viewIndex = maxViewIndex;
            }
        }

        let substances = this.$scope.substances = Array.from(substanceMap.values());
        substances.sort((a,b) => a.viewIndex - b.viewIndex);

        let substancesToTest = this.$scope.substancesToTest = substances
            .filter(e => e.applyIdt)
            .sort((a, b) => a.viewIndex - b.viewIndex);

        this.$scope.substancesToTestCount = substancesToTest.length;
        this.$scope.substancesToTestNames
            = substancesToTest.map(e => e.idtAntigenName).sort((a,b) => a.localeCompare(b)).join(", ");
        this.$scope.isShowAddSubstance = (substances.find(a => !a.isShown) !== undefined);
    }

    /**
     * After user has scanned all the barcodes that will be used for Classical IDT,
     * set those back into the AllergyTest.antigenResults[] to send to the server.
     * @private
     */
    _updateResultsFromBarcodeScan() {
        for (let substance of this.$scope.substancesToTest) {
            if (substance.isPositiveControl) {
                let inServiceConcentrate = this.inServiceConcentrates.find(m => m.barcode === substance.idtFromBarcode);
                if (inServiceConcentrate) {
                    this.$scope.allergyTest.idtHistamine = { id: inServiceConcentrate.id };
                }
            }
            else {
                let result = substance.isControl ? this.$scope.allergyTest.controlResults.find(m => m.substance.id === substance.substanceId) : this.$scope.allergyTest.antigenResults.find(m => m.substance.id === substance.substanceId);
                if (!result) {
                    result = {
                        substance: { id: substance.substanceId },
                        idtDilution: substance.applyIdt ? 0 : -1,
                    }
                    this.$scope.allergyTest.controlResults.push(result);
                }
                result.idtFromBarcode = substance.idtFromBarcode;
            }
        }
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.$scope.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    _loadClassicalChecklist() {
        return this.checklistService.getForIDTPrepare(this.$scope.practice)
            .then(checklist => {
                this.$scope.itemLabels = checklist.items;
                this.$scope.itemChecked = [];
            });
    }

    /**
     * Populate this.inServiceConcentrates and this.substanceConcentrates
     * with Antigen concentrates in service at the office.
     *
     * @param onlyForIdt {boolean|undefined} if true, only use concentrates with the 'forIdt' flag true
     * @returns {Promise} of true when complete
     * @private
     */
    async _loadConcentrates(onlyForIdt) {

        this.inServiceConcentrates = (await this.concentrateService.getInServiceAtOffice(this.$scope.office)).list;
        this.inServiceControls = (await this.concentrateService.getInServiceControlsAtOffice(this.$scope.office)).list;
        this.inServiceControlSubstances = new Map();
        this.substanceConcentrates.clear();
        for (let conc of this.inServiceConcentrates) {
            if (onlyForIdt && !conc.forIdt)
                continue;

            let concArray = this.substanceConcentrates.get(conc.substance.id) || [];
            concArray.push(conc);
            this.substanceConcentrates.set(conc.substance.id, concArray);
        }

        for (let control of this.inServiceControls) {
            let preLoadedSubstance = this.inServiceControlSubstances.get(control.substance.id);
            if (!preLoadedSubstance) {
                preLoadedSubstance = await this.substanceService.get(control.substance);
            }
            this.inServiceControlSubstances.set(preLoadedSubstance.id, preLoadedSubstance);
            control._substance = preLoadedSubstance;
        }
        return true;
    }

    _checkAllAntigensAvailable() {
        const missing = this.$scope.substancesToTest.filter(a => a.availableBarcodes.length === 0);
        if (missing.length) {
            console.log('F');
            this.$uibModal.open({
                windowClass: 'notInService',
                backdrop: 'static',
                keyboard: false,
                template: require('./not-in-service-modal.html'),
                css: require('./not-in-service-modal.scss'),
                controller: function ($uibModalInstance, $scope) {

                    $scope.missingNames = missing.map(a => a.idtAntigenName);
                    $scope.close = () => $uibModalInstance.close();
                }
            }).result.then(() => this.saveAndExit());
        }
    }

    /////////////////////////////////////////////////////////////////////////
    ////////////////////         Otolaryngic          ///////////////////////
    /////////////////////////////////////////////////////////////////////////

    /**
     * Initialize/reinitialize controller for Otolaryngic allergy.
     * Called by common reload().
     * @returns {Promise}
     */
    async reloadOtolaryngic() {
        await this._loadConcentrates(true);
        this.$scope.textrightjustify = `Insert needle into vial and draw ${this.testConfig.idtDosage} mL of dilution`;

        let practiceConfig = this.$scope.practice.config;

        // Initialize tray data
        let trayCount = Math.ceil(this.$scope.allergyTest.antigenResults.length / practiceConfig.idtSubstancesPerTray);
        let len =  trayCount * practiceConfig.idtSubstancesPerTray;
        this.$scope.allergens = [];
        for (let i = 1; i <= len; i++) {
            this.$scope.allergens.push({
                allergen: i
            });
        }

        this.$scope.antigentResults = this.$scope.allergyTest.antigenResults;

        this.loadAntigenSubstances();

        // Determine if the Show Add IDT control button should be shown or not
        if (this.inServiceControls.length && !this.$scope.skipIDT) {
            this.$scope.showAddIdtControlButton = true;
            this.$scope.showAddIdtControlModal = () => this._showAddIdtControlModal();
        }
    }


    /**
     * Control for what the next buttons does for Otolaryngic tests
     * @override
     */
    async nextOtolaryngic() {
        let $scope = this.$scope; //lazy programmer

        if ($scope.arrayCount < $scope.totalArrayCount) {
            $scope.arrayCount++;

            //Increase count and set counter on page
            $scope.allergenPositionsIndex++;

            let position = this.$scope.allergenPositions[this.$scope.allergenPositionsIndex];
            let item = this.$scope.allergens[position];

            this.$scope.allergenPosition = position + 1;
            this.$scope.count = item.allergen;
            this.$scope.allergenName = item.allergenName;
            this.$scope.number = item.dilution;

            $scope.setDilutionHeader($scope.number);

            $scope.changeGrid();
        }
        else {
            // Update allergyTest to propogate changes moving forward in wizard
            await super.update();
            super.next();
        }
    };

    loadAntigenSubstances() {
        this.disableNext = true;
        this.$scope.substancesubstances = [];

        let practiceConfig = this.$scope.practice.config;

        //How many dilutions, starting with highest number and working down
        this.$scope.dilutions = [];   // How many dilutions of each substance
        this.$scope.haveConcentrate = false;
        this.$scope.haveAbove6 = false;


        for (let i = practiceConfig.idtDilutionsCount; i > 0; i--) {
            this.$scope.dilutions.push(i);
        }

        // Variable to set how large a chunk of data should be.
        this.$scope.dataChunkSize = practiceConfig.idtSubstancesPerTray;

        const panel = this.$scope.panel;
        this.$scope.totalSubstances = panel.substances.length;

        for (let panelSubs of panel.substances) {
            let substance = panelSubs.substance._dto;
            let category = substance.category._dto;

            if (category._isAntigen) {
                let dilution = this.$scope.antigentResults.find(aR => aR.substance.id == substance.id).idtDilution;

                // skip all dilutions out of board's range
                if (dilution > 0 && dilution <= practiceConfig.idtDilutionsCount) {
                    //this.$scope.allergenCount++;
                    let allergen = {
                        substance: substance,
                        idtPos: panelSubs.idtPos,
                        dilution: dilution
                    };
                    this.$scope.substancesubstances.push(allergen);

                    if (dilution === 0)
                        this.$scope.haveConcentrate = true;
                    else if (dilution > 6)
                        this.$scope.haveAbove6 = true;
                }
            }
        }

        this.prepareTrayData();
        this.disableNext = false;
        this.setAllergenPos();
    }

    prepareTrayData() {
        let position = 1;
        for (let i = 0; i < this.$scope.allergens.length; i++) {

            this.$scope.substancesubstances.forEach(allergen => {
                if (this.$scope.allergens[i].allergen === (allergen.idtPos + 1)) {
                    this.$scope.allergens[i] = {
                        allergen: allergen.idtPos + 1,
                        allergenName: allergen.substance.name,
                        dilution: allergen.dilution,
                        position: position
                    };
                    position++;
                }
            });
        }

        this.$scope.skipIDT = (position === 1);
    }

    setAllergenPos() {

        this.$scope.allergenPositions = [];

        // this.$scope.allergenPositions: store the locations of allergens indexes which have position values
        for (let i = 0; i < this.$scope.allergens.length; i++) {
            if (this.$scope.allergens[i].position) {
                this.$scope.allergenPositions.push(i);
            }
        }

        this.$scope.arrayCount = 1;
        this.$scope.totalArrayCount = this.$scope.allergenPositions.length;

        if (this.$scope.skipIDT) {
            this.$scope.textrightjustify = '';
        }
        else {
            //set the first allergen
            this.$scope.allergenPositionsIndex = 0;

            let position = this.$scope.allergenPositions[this.$scope.allergenPositionsIndex];
            let item = this.$scope.allergens[position];

            this.$scope.allergenPosition = position + 1;
            this.$scope.count = item.allergen;
            this.$scope.allergenName = item.allergenName;
            this.$scope.number = item.dilution;

            // Function to break down data set into smaller chunks, for dynamic grid layout.
            let chunk = (arr, size) => {
                let newArr = [];
                for (let i = 0; i < arr.length; i += size) {
                    newArr.push(arr.slice(i, i + size));
                }
                return newArr;
            };

            // Running the chunk function
            this.$scope.chunkedData = chunk(this.$scope.allergens, this.$scope.dataChunkSize);

            // Setting the amount of allergens
            this.$scope.totalAllergenCount = this.$scope.allergens.length;
            this.$scope.lgWidth = (44 * this.$scope.dataChunkSize + 30) + "px";
            this.$scope.smWidth = (17 * this.$scope.dataChunkSize) + "px";
            this.$scope.init();
        }
    }

    _showAddIdtControlModal() {
        this.$uibModal.open({
            windowClass: 'addIdtControlModal',
            template: require('./add-idt-control-modal.html'),
            styles: require('./add-idt-control-modal.scss'),
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
            controller: AddIdtControlModalController,
            resolve: {
                practice: () => this.$scope.practice,
                allergyTest: () => this.$scope.allergyTest,
                panel: () => this.$scope.panel
            }
        });
    }
}