"use strict";

import angular from "angular";
import BaseController from "../../../../base.controller";

export default class EditVialController extends BaseController {

    /*
     * Scope:
     * UI portrays the state of an Antigen Tray Vial; VM is mash-up amalgam of TreatmentVial and Substance models.
     *
     * VM metadata:
     *      {boolean} availableSources : Array of Concentrate and TrayVial objects that may be used as a replacement source.
     */

    /**
     * Dependency Injection
     *
     * $uibModalInstance
     *  The controller instance handling the DOM-UI popup-presentation.
     *  @see https://angular-ui.github.io/bootstrap/#/modal
     *  @see https://github.com/angular-ui/bootstrap/tree/master/src/modal
     *  We presume this instance will be spawned during the birth of a $uibModal instance. $uibModal's initialization
     *  offers its content "guest-controller" a reference its own newborn instance; that's what the $uibModalInstance
     *  value is!
     *
     * $scope
     *  The Angular-Scope instance in which this instance will operate.
     *
     * $injector
     *  Angular-InjectorService
     *
     * subjectBoard {BoardDataModel}
     *  The board that contains subjectVial. Must contain the other in-service TrayVials as well.
     *
     * subjectVial {TrayVialDataModel}
     *  The data-model operand value we're serving in this instance. It is from this model that the VM's * dilution *
     *  value is acquired.
     *
     *  Dependency Sources:
     *      Resolved via bootstap.ui.modal constructor::
     *          * {TrayVialDataModel} subjectVial : subject model
     *          * concentrateService : supporting data-acquisition facility
     *      When in doubt, see inv-board-details/controller.js
     */
    static $inject =
               ["$uibModalInstance","$scope","$injector","subjectBoard","subjectVial","concentrateService"];
    constructor( $uibModalInstance , $scope , $injector , subjectBoard , subjectVial , concentrateService ) {
        super($scope, $injector);
        this._initInjections($injector);
        this._concentrateService = concentrateService;

        $scope.scanBarcode = () => this._scanBarcode();
        $scope.scanBarcodeKeydown = (event) => this._scanBarcodeKeydown(event);
        $scope.onUiCancel =()=> $uibModalInstance.dismiss();

        $scope.setDilution =(dilution)=> {
            $scope.replacementDilution = dilution;
        };
        
        $scope.onUiSubmit = () => {
            $uibModalInstance.close(this.$scope.selectedSource);
        };

        this._boardModel = subjectBoard;
        this._dataModel = subjectVial;
        this._loadViewMetaData(); // supporting data
        this._renderVm(); // subject value

    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                        Instance Initialization
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @param {AngularInjectorService} $injector
     * @protected
     */
    _initInjections($injector) {
        this._$q = $injector.get("$q");
        this._boardService = $injector.get("boardService");
        this._eligibleStatusService = $injector.get("eligibleStatusService");
        this._globalConfigService = $injector.get('globalConfigService');
        this.$scope.ServiceStatus = this.ServiceStatus = $injector.get("ServiceStatus");
    }

    _loadViewMetaData() {

        this._globalConfigService.get().then((config) => {
            this._allowAutoBarcode = config.allowAutoBarcode;
        });

        this.$scope.availableSources = [];
        this.$scope.selectedSource = null;

        // Find possible sources from higher-concentration TrayVials on the board
        for (const trayVial of this._boardModel.vials) {
            if (trayVial.status === this.ServiceStatus.IN_SERVICE &&
                trayVial.substance.id === this._dataModel.substance.id &&
                trayVial.dilution < this._dataModel.dilution) {

                this.$scope.availableSources.push(trayVial);
            }
        }

        this._concentrateService.getInServiceSubstanceAtOffice(
            /** {OfficeDataModelReference} */this.$scope.office,
            /** SubstanceDataModel */this._dataModel.substance._dto)
            .then( /** {{ list : {Array.<{ConcentrateDataModel}>} }} */inServiceConcentrates => {

                for (const conc of inServiceConcentrates.list) {
                    conc.dilution = 0;
                    this.$scope.availableSources.push(conc);
                }

                // Now sort availableSources
                this.$scope.availableSources.sort((a,b) => a.dilution - b.dilution);

                for (const vial of this.$scope.availableSources)
                    console.log("Available source dilution " + vial.dilution + " barcode " + vial.barcode);
            });
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                            Event Reactions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                     View Model creation/mapping
    // These routines produce expressions of the subject data-model in a form the amenable to the UI-View layout.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *


    _renderVm() {
        this.$scope.vm = this._vmFromDm();
    }

    /** @returns{VialEditorViewModel} */_vmFromDm() {
        let
            /** anonymous-vm-field */vmSubstance = {
                name : this._dataModel.substance._dto.name
            },
            /** anonymous-vm-field */vmConcentrate = {
                barcode : this._dataModel.concentrate.barcode,
                startService : this._dataModel.concentrate.startService,
                status : this._dataModel.concentrate.status
            };

        return {
            /** @type{Integer} logically this is the Dilution level of the vial being being replaced. */
            "dilution" : this._dataModel.dilution,
            "substance" : vmSubstance,
            "concentrate" : vmConcentrate
        };
    }

    _scanBarcodeKeydown(event) {
        if (event.which === 13) {
            event.preventDefault();
            this._scanBarcode();
        }
    }

    _scanBarcode() {
        const barcodeInput = this.$scope.barcodeInput;
        this.$scope.barcodeError = null;
        console.log("Barcode input '" + barcodeInput + "'");
        if (!barcodeInput) {
            // No input
            return;
        }

        // Auto-fill all selections for dev & QA
        if (barcodeInput === '=' && this._allowAutoBarcode) {
            this.$scope.selectedSource = this.$scope.availableSources[0];
        }
        else if (barcodeInput.length !== 8) {
            this.$scope.barcodeError = "Invalid barcode: " + barcodeInput;
            this.$scope.selectedSource = null;
        }
        else {
            // Find match
            this.$scope.selectedSource = this.$scope.availableSources.find(v => v.barcode === barcodeInput);
            if (!this.$scope.selectedSource) {
                this.$scope.barcodeError = "Source vial " + barcodeInput + " not available.";
            }
        }

        this.$scope.barcodeInput = '';
    }
}
