"use strict";

import PrescriptionService from "../../../services/prescription.service";
import PrescriptionReason from "../../../models/prescriptionReason";

export default class CreateNextRxModalController {
    static $inject = ['$uibModalInstance', '$scope', '$injector', 'vials', 'practice'];

    constructor($uibModalInstance, $scope, $injector, vials, practice) {
        this.prescriptionService = $injector.get("prescriptionService");
        this.PrescriptionReason = $injector.get("PrescriptionReason");

        $scope.vials = vials;
        $scope.practice = practice;

        $scope.checkSelected = () => {
            $scope.hasSelected = $scope.vials.some(v => v.selected) && $scope.vials.filter(v => v.selected).every(v => v.action);
        };

        $scope.cancel = () => {
            for (let v of $scope.vials) {
                v.selected = false;
                v.action = false;
            }
            $uibModalInstance.close();
        }

        $scope.onSubmit = () => {
            const selectedVials = $scope.vials.filter(v => v.selected).map(v => {
                return {
                    vial: v,
                    reason: v.action
                }
            });

            this.prescriptionService.createNext($scope.practice, selectedVials).then(() => {
                for (let v of $scope.vials) {
                    v.selected = false;
                    v.action = false;
                }
                $uibModalInstance.close();
            });
        }
    }
}