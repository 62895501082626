"use strict";

import angular from 'angular';
import BaseSectionController from "../../widgets/section-content-panel/controller";
import DashboardSections from "../../models/dashboard-sections";

export default class MixingSectionController extends BaseSectionController {

    static $inject = ["$injector", "$scope", "DashboardSections", "UnapprovedType"];

    constructor($injector, $scope, UiSectionTypes, UnapprovedType) {
        super($injector, $scope, UiSectionTypes.MIXING);

        this.UnapprovedType = UnapprovedType;
        this._initInjections($injector);

        $scope.moveOpen = {};
        $scope.actionsOpen = {};

        $scope.onAwaitingSelected = (item) => this._onAwaitingSelected(item);
        $scope.onReadyMixSelected = (item) => this._onReadyMixSelected(item);
        $scope.cancelMix = (item) => this._cancelMix(item);
        $scope.onMixedSelected = (item) => this._onMixedSelected(item);
        $scope.printMixingLabels = () => this.printMixingLabels();
        $scope.stopPropagation = ($event) => $event.stopPropagation();
        $scope.transferPrescription = (item, office) => this._transferPrescription(item, office);
        $scope.dispersePrescription = (item) => this._dispersePrescription(item);
        $scope.toggleFlag = ($event, item) => this._toggleFlag($event, item);
        $scope.toggleMove = (id, doShow) => this._toggleDropdown(this.$scope.moveOpen, id, doShow);
        $scope.toggleActions = (id, doShow) => this._toggleDropdown(this.$scope.actionsOpen, id, doShow);
        $scope.toggleDropdowns = (id, doShow) => {
            $scope.toggleMove(id, doShow);
            $scope.toggleActions(id, doShow);
        };

        this._loadOffices()
        this._reload();
    }

    _initInjections($injector) {
        this.$filter = $injector.get('$filter');
        this.$uibModal = $injector.get('$uibModal');
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.NotificationAction = $injector.get('NotificationAction');
        this.patientService = $injector.get('patientService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.substanceService = $injector.get('substanceService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.$q = $injector.get('$q');
        this.labelPrinterService = $injector.get('labelPrinterService');
    }

    /**
     * This is called when the user selects a different Office.
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this._reload();
    }

    async _reload() {
        await this.notificationService.init();
        this.unsubscribeAllSubscriptions();
        await this._subscribeAll();

        await Promise.all([
            this._loadReadyToMix(),
            this._loadReadyToTreat(),
            this._loadReadyToApprove(),
        ]);

        this.startAllSubscriptions();
    }

    _loadOffices() {
        return this.officeService.getInPractice(this.$scope.practice).then((officeList) => {
            this.$scope.offices = officeList.list;
        });
    }

    _loadReadyToApprove() {
        this.$scope.awaitingList = [];

        return this.officeService.getUnapproved(this.$scope.office).then((unapprovedList) => {

            for (let una of unapprovedList.list) {
                if (una.type !== this.UnapprovedType.PRESCRIPTION || una.status === "CANCELLED") {
                    continue;
                }

                this.$scope.awaitingList.push(this._populateRxApprovableRow(una, {}));
            }
        });
    }

    _loadReadyToMix() {
        this.$scope.approvedList = [];

        return this.prescriptionService.getReadyToMix(this.$scope.office).then((rxList) => {
            for (let pscript of rxList.list) {
                this.$scope.approvedList.push(this._populateListRow(pscript, {}));
            }
        });
    }

    _loadReadyToTreat() {
        this.$scope.mixedList = [];

        return this.prescriptionService.getReadyToTreat(this.$scope.office).then((rxList) => {
            for (let pscript of rxList.list) {
                this.$scope.mixedList.push(this._populateListRow(pscript, {}));
            }
        });
    }

    _populateListRow(dto, row) {
        row.id = dto.id;
        row.href = dto.href;
        row.patientId = dto.patient.id;
        row.patientHref = dto.patient.href;
        row.type = dto.treatmentType;
        row.orderedDate = dto.orderedBy.actionDateTime;
        row.person = dto.patient.person;
        row.flagged = dto.flagged;

        // Add pscript as property if this is the full pscript DTO
        if (dto.vials) {
            row.pscript = dto;
        }

        return row;
    }

    _populateRxApprovableRow(rxApproval, row) {
        row.id = rxApproval.prescription.id;
        row.href = rxApproval.prescription.href;
        row.patientId = rxApproval.patient.id;
        row.patientHref = rxApproval.patient.href;
        row.type = rxApproval.treatmentType;
        row.orderedDate = rxApproval.dateRequested;
        row.person = rxApproval.patientPerson;
        return row;
    }

    _onAwaitingSelected(/** Prescription */item) {
        if (this.$scope.user.roles.indexOf('DOCTOR') > -1)
            this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_RX, /** ReferenceDTO.<Prescription> */item);
        else {
            let /** ReferenceDTO.<Patient> */patientReference = {
                id: item.patientId,
                href: item.patientHref
            };
            this.routeToPage(this.urlPaths.PATIENT_DETAILS, patientReference, this.routingService.createLocationParams('Dashboard'));
        }
    }

    _onReadyMixSelected(/** Mix */item) {
        this.routeToPage(this.urlPaths.DASHBOARD_MIXING_INITIALIZE, item);
    }

    _cancelMix(item) {
        let modalInstance = this.$uibModal.open({
            windowClass: 'userActionModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/user-action-modal.html'),
            css: require('./widgets/user-action-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.title = 'Cancel Prescription Mix';
                $scope.prompt = 'Please confirm you would like to cancel this prescription. Please note that this action cannot be reversed.';

                $scope.confirm = () => $uibModalInstance.close($scope.notes);
                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        });

        modalInstance.result.then((notes) => {
            this.prescriptionService.cancel(item.pscript, notes)
                .then((pscript) => {
                    let foundIdx;
                    for (let list of [this.$scope.awaitingList, this.$scope.approvedList, this.$scope.mixedList]) {
                        foundIdx = this._findInTable(list, pscript.id);
                        if (foundIdx > -1) {
                            list.splice(foundIdx, 1);
                            break;
                        }
                    }
                });
        });
    }

    _onMixedSelected(/** Patient */item) {
        let patientReference = {
            id: item.patientId,
            href: item.patientHref
        }
        this.routeToPage(this.urlPaths.PATIENT_DETAILS, patientReference, this.routingService.createLocationParams('Dashboard'));
    }

    /**
     * Setup all notification subscriptions.
     * @private
     */
    _subscribeAll() {
        // Listen for changes to Unapproved entities
        this.registerSubscription(this.notificationService.subscribeToAllUnapprovedAtOffice(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onUnapprovedNotification(notification));

        // Listen for Prescriptions being MIXED
        this.registerSubscription(this.notificationService.subscribeToAllMixedAtOffice(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onMixedNotification(notification));

        // Listen for Prescriptions being UPDATED
        this.registerSubscription(this.notificationService.subscribeToPrescriptionUpdates(this.$scope.practice))
            .then(null, null, (notification) => this._onPrescriptionNotification(notification));

        // Listen changes in Patient Appointments
        this.registerSubscription(this.notificationService.subscribeAllPatientAppointments(this.$scope.practice))
            .then(null, null, (notification) => this._onAppointmentNotification(notification));

        // Listen for other users openning/closing Mixing Wizard
        this.registerSubscription(this.notificationService.subscribeOfficeEntityLockEvents(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onEntityLockNotificationReceived(notification));
    }

    /* Is the Prescription valid for this page? */
    _isRxApplicableForPage(pscript) {
        if (pscript.office.id !== this.$scope.office.id || !!pscript.cancelledBy || pscript.dispersedBy) {
            return false;
        }

        return true;
    }

    /* Return true if a prescription belongs in the supplied list, false otherwise */
    _handlePrescriptionNotificationUpdate(pscript, list, belongsInList) {
        let foundIdx = this._findInTable(list, pscript.id);
        if (foundIdx > -1) {
            if (!this._isRxApplicableForPage(pscript)) {
                list.splice(foundIdx, 1);
            }
            else {
                /* Was the prescription just updated? Update the object in the list */
                this._populateListRow(pscript, list[foundIdx]);
            }
            return true;
        }
        else if (this._isRxApplicableForPage(pscript) && belongsInList(pscript)) {
            list.push(this._populateListRow(pscript, {}));
            return true;
        }

        return false;
    }

    /**
     * When a Prescription is updated, update our copies. We do this primarily
     * to update the href and copy of the pscript in the row, so that the DTO
     * version field is kept current.
     *
     * @param notification
     * @private
     */
    _onPrescriptionNotification(notification) {
        let pscript = notification.body;

        let populatableLists = [
            { list: this.$scope.awaitingList, belongsInList: (pscript) => !pscript.approvedBy },
            { list: this.$scope.approvedList, belongsInList: (pscript) => pscript.approvedBy && !pscript.mixed },
            { list: this.$scope.mixedList, belongsInList: (pscript) => pscript.mixed }
        ];

        // Search each list and update there.
        // If found in one, we can stop because a Prescription can't be in multiple lists.
        for (let populatableList of populatableLists) {
            let list = populatableList.list;
            let belongsInList = populatableList.belongsInList;

            let handled = this._handlePrescriptionNotificationUpdate(pscript, list, belongsInList);
            if (handled) {
                break;
            }
        }
    }

    /**
     * When an update Unapproved DTO is received, update the tables.
     * @param notification
     * @private
     */
    _onUnapprovedNotification(notification) {
        let una = notification.body;
        if (una.type !== this.UnapprovedType.PRESCRIPTION || una.status === "CANCELLED")
            return;

        let pscriptId = una.prescription.id;

        /*
         * Update awaitingList (needs-approval)
         */
        let foundIdx = this._findInTable(this.$scope.awaitingList, pscriptId);

        if (notification.topic.endsWith(this.NotificationAction.REMOVED)) {
            // Item approved, remove from list if found.
            if (foundIdx > -1) {
                this.$scope.awaitingList.splice(foundIdx, 1);
            }
        } else if (foundIdx == -1) {
            // Add new entry
            this.$scope.awaitingList.push(this._populateRxApprovableRow(una, {}));
        }

        /*
         * Update approvedList (ready-to-mix)
         */
        if (notification.topic.endsWith(this.NotificationAction.REMOVED)) {
            // Removing Unapproved means it's now approved. Add to list
            this.$scope.approvedList.push(this._populateRxApprovableRow(una, {}));
        }
    }

    /**
     * When a notification that a Prescription is MIXED is received, update the tables.
     * @param notification
     * @private
     */
    _onMixedNotification(notification) {
        let pscript = notification.body;

        /*
         * Update approvedList (ready-to-mix)
         */
        let foundIdx = this._findInTable(this.$scope.approvedList, pscript.id);
        if (foundIdx > -1) {
            // Remove from ready-to-mix
            this.$scope.approvedList.splice(foundIdx, 1);
        }

        /*
         * Update mixedList (Ready-to-treat)
         * Add to table
         */
        this.$scope.mixedList.push(this._populateListRow(pscript, {}));
    }

    /**
     * When a notification that an has changed Appointment is received, update the table.
     * @param notification
     * @private
     */
    _onAppointmentNotification(notification) {
        let appointment = notification.body;

        /*
         * Update mixedList (Ready-to-treat)
         * Remove from table
         */
        let findId = appointment.patient.id;
        for (let i = 0; i < this.$scope.mixedList.length; ++i) {
            if (findId === this.$scope.mixedList[i].patientId) {
                this.$scope.mixedList.splice(i, 1);
                break;
            }
        }
    }

    /**
     * When a notification of an entity being locked/unlocked is received, updated
     * the Approved, Ready For Mixing list to grey-out a patient being mixed by
     * another user.
     *
     * @param notification
     * @private
     */
    _onEntityLockNotificationReceived(notification) {
        let lockEvent = notification.body;

        // Update _isLocked flag on patient if found in the approved list
        for (let row of this.$scope.approvedList) {
            if (row.patientId === lockEvent.id) {
                row._isLocked = lockEvent.lock;
                // Continue - might be more than one prescription for the patient.
            }
        }
    }

    /**
     * Search for an entry by id in the designated table
     * @returns {int} index in the table, or -1 if not found
     * @private
     */
    _findInTable(table, id) {
        for (let i = 0; i < table.length; ++i) {
            if (id === table[i].id) {
                return i;
            }
        }

        return -1;
    }

    printMixingLabels() {
        /* Build view-model for dialog */
        let viewModelMap = new Map();
        for (let row of this.$scope.approvedList) {
            let patient = viewModelMap.get(row.patientId);
            if (angular.isDefined(patient)) {
                patient.vialCount += row.pscript.vials.filter(vial => vial.containsAntigen).length;
            }
            else {
                patient = {
                    person: row.person,
                    patientId: row.patientId,
                    vialCount: row.pscript.vials.filter(vial => vial.containsAntigen).length,
                };
                viewModelMap.set(patient.patientId, patient);
            }
        }

        this.$scope.printLabelsList = Array.from(viewModelMap.values());

        /* Show modal */
        let modalInstance = this.$uibModal.open({
            windowClass: 'printLabels',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/print-mixing-labels.html'),
            css: require('./widgets/print-mixing-labels.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.isUserSelected = false;
                $scope.checkedCheckboxes = false;

                $scope.selectAll = () => {
                    $scope.selectedAll = !$scope.selectedAll;
                    $scope.printLabelsList.forEach(patient => {
                        patient.selected = $scope.selectedAll;
                    });
                    $scope.checkedCheckboxes = $scope.selectedAll;
                };

                $scope.checked = function () {
                    for (let patient of $scope.printLabelsList) {
                        if (patient.selected) {
                            $scope.checkedCheckboxes = true;
                            return;
                        }
                    }
                    $scope.checkedCheckboxes = false;
                };

                $scope.printLabels = function () {

                    //Set array to hold selected items
                    let printThese = [];

                    // Loop through checkboxes on page, if checked add to array
                    for (var i = 0; i < $scope.printLabelsList.length; i++) {
                        if ($scope.printLabelsList[i].selected == true) {
                            printThese.push($scope.printLabelsList[i]);
                        }
                    }

                    $uibModalInstance.close(printThese);
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

            }
        });

        modalInstance.result.then(printPatients => {

            if (printPatients.length > 0) {
                // Print the array of selected items
                this._printPrescriptionLabels(printPatients);
            }
        });
    }

    async _printPrescriptionLabels(patients) {
        // Grab prescriptions to be printed and send them to the LabelPrinterService
        let prescriptionsToPrint = [];
        for (let patient of patients) {
            for (let row of this.$scope.approvedList) {
                if (row.patientId === patient.patientId) {
                    prescriptionsToPrint.push(row.pscript);
                }
            }
        }

        return this.labelPrinterService.printPrescribedVials(prescriptionsToPrint);
    }

    /**
     * HTML escape a string. Why is this not a built-in?
     * @param str
     * @returns sanitized string
     * @private
     */
    _escapeHtml(str) {
        var div = document.createElement('div');
        div.appendChild(document.createTextNode(str));
        return div.innerHTML;
    }

    /**
     * Move a prescription within a container list to a different office
     */
    _transferPrescription(item, office) {
        let pscriptPromise = item.pscript ? this.$q.resolve(item.pscript) : this.prescriptionService.get(item);
        pscriptPromise.then((pscript) => { // Retrieve Prescription dto using ReadyPrescription dto
            pscript.office = office;
            this.prescriptionService.update(pscript);
            this.$scope.moveOpen[pscript.id] = false;
        });
    }

    _dispersePrescription(item) {

        let modalInstance = this.$uibModal.open({
            windowClass: 'rxDispersedModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/rx-dispersed-modal.html'),
            css: require('./widgets/rx-dispersed-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.reason = null;

                $scope.setReason = (reason) => {
                    $scope.reason = reason;
                }

                $scope.reasons = [
                    { key: 'PICKED_UP', value: 'Patient Vial Picked-Up' },
                    { key: 'DELIVERED', value: 'Patient Vial Mailed/Delivered' },
                    { key: 'NO_MORE_TREATMENT', value: 'Patient No Longer Receiving Treatment' }
                ];

                $scope.confirm = () => $uibModalInstance.close($scope.reason);
                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        });

        modalInstance.result.then((reason) => {
            this.prescriptionService.get(item).then((pscript) => {
                pscript.dispersedBy = { label: 'Prescription Distributed', note: reason.value };
                this.prescriptionService.update(pscript);
            });
        });
    }

    _toggleFlag($event, item) {
        $event.stopPropagation();
        item.pscript.flagged = item.flagged = !item.flagged;
        this.prescriptionService.update(item.pscript);
    }

    _toggleDropdown = (openStatus, id, doShow) => {
        if (doShow === undefined) {
            openStatus[id] = !openStatus[id];
        }
        else {
            openStatus[id] = doShow;
        }
    }
}
