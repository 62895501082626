'use strict';

import TestingController from '../testing.controller'

export default class TestPostVitalsController extends TestingController {

    static $inject = ['$scope', '$injector', '$uibModal', 'visitVitalsService'];
    constructor($scope, $injector, $uibModal, visitVitalsService) {

        super($scope, $injector, $uibModal);

        this.visitVitalsService = visitVitalsService;

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    _attachPreVitals(postVitals) {
        if (this.visit.preVitals) {
            return this.visitVitalsService.getPreVitalsForVisit(this.visit)
                .then((preVitals) => {
                    for (let postVitalResult of postVitals.results) {
                        let preVitalResult = preVitals.results.find(m => m.vital.id === postVitalResult.vital.id);
                        if (preVitalResult) {
                            postVitalResult._preValue = preVitalResult.value;
                        }
                    }
                    return postVitals;
                });
        }
        else {
            return Promise.resolve(postVitals);
        }
    }

    reload() {
        super.validateStage(['POST_VITALS']);

        this.$scope.text = 'Record Patient Post Vitals';

        // get vital for this visit
        if (this.visit.postVitals) {
            this.visitVitalsService.getPostVitalsForVisit(this.visit).then(vitals => {
                if (!vitals.performedBy) {
                    vitals.performedBy = {label: "Post Vitals", note: ""};
                }

                this.$scope.footerNote = vitals.performedBy;
                this.$scope.vitals = vitals;
                return this._attachPreVitals(vitals);
            });
        }
        else {
            console.error("No post-vitals. Server should not have put us in this stage. Advancing to next stage.");
            return this.advance();
        }
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        return this.visitVitalsService.update(this.$scope.vitals)
            .then(vitals => {
                this.$scope.vitals = vitals;
                return this._attachPreVitals(vitals);
            })
            .then(() => {
                // Contract of update() is to return the allergyTest
                return this.$scope.allergyTest;
            });
    }

    /**
     * @override
     */
    nextDisabled() {
        let vitals = this.$scope.vitals;

        if (this.disableNext || !vitals)
            return true;

        let allAnswered = true;
        vitals.results.forEach(r => {
            if (r.vital.postRequired || r.value) {
                let v = Number.parseFloat(r.value);
                r._isInvalid = (isNaN(v) || v < 0.0 || v > 999.99);
                if (r._isInvalid) {
                    allAnswered = false;
                }
            }
        });

        return !allAnswered;
    }

    /**
     * @override
     */
    next() {
        this.disableNext = true;

        this.visitVitalsService.update(this.$scope.vitals).then(vitals => {
            this.$scope.vitals = vitals;
            return this._attachPreVitals(vitals);
        })
        .then((vitals) => {
            if (vitals.warning && vitals.warning.warnDateTime) {

                if (vitals.warning.override) {
                    this.advance();
                } else {
                    this.$scope.warningFormData = {
                        reasonToContinue: null,
                        addNotes: null,
                        warnings: []
                    };

                    for(let vitalResult of vitals.results) {
                        if (vitalResult.warnText) {
                            this.$scope.warningFormData.warnings.push({
                                text: vitalResult.warnText,
                                preValue: vitalResult._preValue
                            });
                        }
                    }

                    this.warningModal();
                    this.disableNext = false;
                }
            } else {
                this.advance();
            }
        });
    }

    warningModal() {
        this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./post-vitals-warning-modal.html'),
            css: require('./post-vitals-warning-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    return ($scope.warningFormData.reasonToContinue);
                };

                $scope.continue = () => {
                    $uibModalInstance.close('success');

                    if ($scope.warningFormData.reasonToContinue === 'Other') {
                        let note = $scope.warningFormData.addNotes;
                        if (!note)
                            note = "Continue.";
                        $scope.vitals.warning.override = {note: note };
                        $scope.next();
                    }
                    else {
                        // Just go back to page for edit
                    }
                }
            }
        });
    }

}

