'use strict';

export const ClassicalDilution = {
    'RED': { order: 0, color: 'RED', dilution: 1 },
    'BLUE': { order: 1, color: 'BLUE', dilution: 10 },
    'YELLOW': { order: 2, color: 'YELLOW', dilution: 100 },
    'GREEN': { order: 3, color: 'GREEN', dilution: 1000 },
    'SILVER': { order: 4, color: 'SILVER', dilution: 10000 }
};
export const ClassicalDilutions = [
    ClassicalDilution.RED, ClassicalDilution.BLUE, ClassicalDilution.YELLOW,
    ClassicalDilution.GREEN, ClassicalDilution.SILVER
];

export const toPascalCase = (str) => {
  return str.match(/[a-z]+/gi)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    })
    .join('')
}
