'use strict';

// Framework
import angular from 'angular';

import UrlPaths from "../../models/url-paths";

import treatment_initialize from './initialize';
import treatment_vial_selection from './vial-selection';
import treatment_questionnaire from './questionnaire';
import treatment_vitals from './vitals';
import treatment_checklist from './checklist';
import treatment_vial_test from './vial-test';
import treatment_measure_intradermal from './measure-idt';
import treatment_injection from './injection';
import treatment_examination from './examination';
import treatment_timer from './timer';
import treatment_widgets from './widgets';

export default angular.module('pages.treatment',
    [
        UrlPaths,
        treatment_widgets,
        treatment_timer,
        treatment_initialize,
        treatment_vial_selection,
        treatment_questionnaire,
        treatment_vitals,
        treatment_checklist,
        treatment_vial_test,
        treatment_measure_intradermal,
        treatment_injection,
        treatment_examination
    ]
).name;
