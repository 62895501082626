"use strict";

import angular from "angular";
import StandardLayoutNavController from "./controller";

export default angular.module("pages.common.standard-layout.nav", [])
    .controller("StandardLayoutNavController", StandardLayoutNavController)
    .directive("agAppNav", initAgAppNav)
    .name;

function initAgAppNav($location, UrlPaths) {
    return {
        controller: "StandardLayoutNavController",
        scope : {},
        restrict : "E",
        template : require("./layout.html")
    }
}
initAgAppNav.$inject = ["$location", "UrlPaths"];
