"use strict";

import angular from "angular";

export default angular.module("pages.dashboard.sections.appointments.current-visits-list", [])
	.directive("agDbCurrentVisitsList", ['Station', initView] )
	.name;

function initView(Station) {
	return {
		restrict: "E",
		css: require("./styles.scss"),
		template: require("./layout.html"),
		scope: {
			/**
			 * @type {Array<PatientView>}
			 *
			 * This is the eigenValue of the instance. The collection contains model objects representing patients who
			 * have an appointment scheduled for today.
			 */
			"list": "=",

			/**
			 * @type {Function( {PatientView} )}
			 *
			 * This is a callback routine passed to instances from the caller. The params:
			 * a PatientView should probably be one of the values arriving in the bound *list* reference.
			 */
			clickCallback: "=",

			/**
			 * @type {Station}
			 */
			"station": "@"
		},
		link: (scope, element, attr) => {

			scope.stationIconSrc = ()=> {
				switch (scope.station) {
					case Station.WAITING_ROOM:
						return require('../../../../../common/img/icons/waiting-room.png');
					case Station.TESTING:
						return require('../../../../../common/img/icons/testing.png');
					case Station.TREATMENT:
						return require('../../../../../common/img/icons/shots.png');
				}
			};

			scope.instanceContextCoClass = ()=> {
				switch (scope.station) {
					case Station.WAITING_ROOM:
						return "waiting-room";
					case Station.TESTING:
						return "testing";
					case Station.TREATMENT:
						return "shots";
				}
			};

			scope.$watch('list', function (newValue) {
				if (newValue) {
					scope.count = newValue.length;
				}
			}, true);
		}
	};
}
