"use strict";

/**
 * Little controller that just subscribes in updates from InventoryAlertService,
 * and displays the banner content that it provides.
 */
export default class PatientAlertWarningController {

    static $inject = ["$scope", "sessionService", "patientAlertService"];
    constructor($scope, sessionService, patientAlertService) {
        this.$scope = $scope;
        this.sessionService = sessionService;
        this.patientAlertService = patientAlertService;

        this.practice = this.sessionService.get(this.sessionService.KEY_PRACTICE);

        $scope.expanded = false;
        $scope.dismissed = true;

        $scope.toggleExpanded = this._toggleExpanded;
        $scope.dismiss = this._dismiss;

        $scope.$watch('patient', () => {
            if ($scope.patient && this.practice) {
                this.patientAlertService.subscribe(this.practice, $scope.patient, this.$scope, (data) => {
                    this.patientAlerts = data;
                    this._setAlertsScope();
                });
            }
        });
    }

    _setAlertsScope = () => {
        this.$scope.alerts = [];
        if (this.patientAlerts) {
            for (let i = 0; i < this.patientAlerts.length; i++){
                let patientAlert = this.patientAlerts[i];
                if (patientAlert.active && patientAlert.types.indexOf(this.$scope.location) !== -1) {
                    this.$scope.alerts.push(patientAlert);
                }
            }
            this.$scope.alertTitles = this.$scope.alerts.map(a => a.title).join(', ');
            this.$scope.dismissed = this._setDismissed();
        }
    }

    _toggleExpanded = () => {
        this.$scope.expanded = !this.$scope.expanded;
    }

    _setDismissed = () => {
        for (let i = 0; i < this.$scope.alerts.length; i++){
            if (!this.patientAlertService.isAlertDismissed(this.$scope.alerts[i], this.$scope.location)) {
                return false;
            }
        }
        return true;
    }

    _dismiss = () => {
        for (let i = 0; i < this.$scope.alerts.length; i++) {
            this.patientAlertService.dismissAlert(this.$scope.alerts[i], this.$scope.location);
        }
        this.$scope.dismissed = true;
    }
}
