'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentExaminationController from './examination.controller.js';

export default angular.module('pages.treatment.examination', [ngRoute])
    .config(config)
    .controller('TreatmentExaminationController', TreatmentExaminationController)
    .directive('agTreatmentMeasureValues', agTreatmentMeasureValues)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_EXAMINATION, {
        template: require('./examination.html'),
        css: require('./examination.scss'),
        controller: 'TreatmentExaminationController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];

function agTreatmentMeasureValues($timeout) {
    return {
        restrict: 'AE',
        scope: false,
        link: function (scope, element, attrs) {

            scope.autoSelectPosition = function() {
                $timeout(() => { scope.nextTabIndex(1)}, 100);
            };

            scope.nextTabIndex = function (tabindex) {

                for (let attempt = 0; attempt < 2; ++attempt) {
                    for (; tabindex < 9; ++tabindex) {
                        let box = $("input[name='section" + tabindex + "']");
                        if (!box.prop('disabled') && box.prop('value') === '') {
                            box.focus();
                            console.log("nextTabIndex focus on " + tabindex);
                            return;
                        }
                    }

                    // try again from beginning
                    tabindex = 1;
                }
            };

            scope.setValue = function(value) {
                if (isNaN(parseInt(value)))
                    value = undefined;
                scope[scope.thisInput] = value;
                scope.injection.reactionWhealSize = value;
            };

            scope.setFocus = function () {
                var currentFocus = $(document.activeElement);
                scope.currentFocus = currentFocus;
                scope.currentId = currentFocus.attr('id');
                scope.thisInput = currentFocus.attr('name');
                scope.tabindex = currentFocus.attr('tabindex');
                scope.setInstructionText(scope.tabindex);
            };

            scope.selectWheal = function (whealValue) {
                // Set value of grid section to selected wheal value
                document.getElementById(scope.currentId).value = whealValue;
                scope[scope.thisInput] = whealValue;
                scope.injection.reactionWhealSize = whealValue;
            };

            scope.autoSelectPosition();
        }
    };
}
