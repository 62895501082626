"use strict";

import angular from "angular";
import InventorySectionController from "../section-content-panel/controller";
import InventorySections from "../../models/inventory-sections";
import ProcedureType from "../../../../models/procedure";
import CreateBoardModalController from "../create-inv-board-modal/controller";

export default class BaseBoardListController extends InventorySectionController {

    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .
    //                                             Service Impl's
    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .

    /**
     * @type {services.BoardService}
     */
    _boardService = undefined;

    /**
     * @type {services.PanelService}
     */
    _panelService = undefined;

    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .
    //                                           Stateful members
    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .

    /**
     * @type {Procedure}
     * Implicitly maps 1:1 to the use-case variations. It is part of the type/case identity of this instance.
     */
    _useCaseProcedure = undefined;

    /**
     * @type {String}
     *
     * This URL-Path fragment will be used in the addresses leading the Details-UI for members of this instance's list.
     * We can't treat this as a constant because the appropriate value varies with the use-case (the type of Board this
     * instance is handling).
     */
    _detailsUrlPath;

    /**
     * @type {Map.<{String},{PanelDataModel}>}
     *
     * During DM assembly, we acquire Panel models related to the subject Boards. Sometimes, many Board instances have
     * the same Panel. To avoid redundant requests, we stash the ones we fetch in this map.
     */
    _panelsMap;

    /**
     * @param {angular.injectionService} $injector
     * @param {angular.Scope} $scope
     * @param {pages.inventory.models.InventorySections} instanceSectionType
     * @param {String} detailsUrlPath : URL path component, facilitates list-items Detail-UI navigiation
     * @param {models.procedure} boardProcedureType : specifies which kind of Boards this instance supports
     */
    constructor($injector, $scope, instanceSectionType, detailsUrlPath, boardProcedureType) {

        super($injector, $scope, instanceSectionType);

        this.ServiceStatusType = $injector.get("ServiceStatus");

        this._boardService = $injector.get("boardService");
        this._panelService = $injector.get("panelService");
        this._detailsUrlPath = detailsUrlPath;
        this._useCaseProcedure = boardProcedureType;

        $scope.onUiBoardPick =(rowVm)=> this._gotoDetails(rowVm);
        $scope.onUiCreateBoard =()=> this._showAddBoardModal();

        this._loadListData(this._useCaseProcedure);
        this.inventoryAlertService.subscribe(this.$scope, alertSummary => this._updateBoardAlerts(alertSummary));
    }

    /**
     * This is called when the user selects a different Office.
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this._loadListData(this._useCaseProcedure);
    }

    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .
    //                                     Subject DataModel Assembly
    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .

    /**
     * @param {models.procedure} boardProcedureType
     *      required by BoardService API, to specify the variety of board needed
     *
     * No return value, but asynchronous side effect is the $scope.rowCollection, the customary operand for SmartTable
     * UI cases.
     */
    async _loadListData(caseProcedure) {
        
        await this.notificationService.init();
        // and then...
        this.unsubscribeAllSubscriptions();
        this._subscribeToNotifications();

        let
            /** {BoardDm} */
            coreBoardDm = await this._boardService.getAtOffice(
                this.$scope.office, caseProcedure, undefined, this._boardService.NO_VIALS);

        /** {Map.<{String},{PanelDm}>} */
        this._panelsMap = new Map();

        // ... and then later on ...
        for (let aBoardDm of coreBoardDm.list) {

            let assocPanel = await this._fetchAssocPanel(aBoardDm);
            // ... and then later on ...
            aBoardDm._panel = assocPanel;
        }

        this.$scope.rowCollection = this._createBoardListVm(coreBoardDm.list);
        this.startAllSubscriptions();
        this.inventoryAlertService.sendTo(this.$scope);
        this.$scope.$digest();

    }

    /**
     * @param {BoardDm} board
     *   subject model whose Panel model we want
     *
     * @returns {PanelDm}
     *   the Panel model which is logically associated with the *board* operand
     */
    async _fetchAssocPanel(board) {

        let panelDm;

        if (false == this._panelsMap.has(board.panel.id)) {

            panelDm = await this._panelService.get(board.panel, /** parent-object reference for caching */board);
            // ... and then later on ...
            this._panelsMap.set(board.panel.id, panelDm);
        }

        return this._panelsMap.get(board.panel.id);
    }

    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .
    //                                     View Modeling from Data State
    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .

    /**
     * @param {Array.<{BoardDm}>} dmList
     * @returns {Array.<{BoardVm}>}
     */
    _createBoardListVm(dmList) {

        let vmList = [];

        dmList.forEach( aBoardDm =>
            vmList.push( /** @type{BoardViewModel} */this._createVm(aBoardDm) ) );

        return vmList;
    }

    /**
     * Accepts a raw data-model (presumed to be a form of inventory Board model), and returns the VM as UI-template
     * expects the fields.
     *
     * @param {BoardDm} dm
     * @returns {BoardVm}
     */
    _createVm(dm) {

        let
            /** @type {BoardViewModel} */
            vm = {},
            /** @type {Array.<{String}>} the names for fields that are the same in DM and VM */
            unchangingFields = ["name", "status","href","id","barcode"];

        unchangingFields.forEach( aFieldName => vm[aFieldName] = dm[aFieldName]);

        vm.inServiceDate = dm.startService;
        vm.endServiceDate = dm.endService;
        vm.dateCreated = dm.createdDateTime;
        vm.panelName = dm._panel.name;
        vm.alert = undefined;

        return vm;
    }

    /**
     * Update alerts on board in the view according to the summary from InventoryAlertService.
     *
     * @param alertSummary see InventoryAlertService
     * @private
     */
    _updateBoardAlerts(alertSummary) {
        if (this.$scope.rowCollection) {
            for (let board of this.$scope.rowCollection) {
                board.alert = alertSummary.icons.get(board.id);
            }
        }
    }

    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .
    //                                   Mid-Lifecycle Reactionary Behavior
    // . . .. ... ..... ........ ............. ..................... ............. ........ ..... ... .. . .


    /**
     * Triggers the display of a modal UI offering the user the means to create a new Board instance.
     */
    _showAddBoardModal() {

        let creationModal = this.$uibModal.open({
            windowClass: "addBoards",
            template: require("../create-inv-board-modal/layout.html"),
            css: require("../create-inv-board-modal/styles.scss"),
            scope: this.$scope,
            controller: CreateBoardModalController,
            resolve: {
                "procedureType" : ()=> this._useCaseProcedure,
                "detailsUrlPath" : ()=> this._detailsUrlPath
            }
        });

        creationModal.result.then( modalResult => console.log("Add-Board modal result ==> " , modalResult ));

    }

    /**
     * Triggers the redirection of the UI to the Board-Details UI with *boardListItem* as the subject.
     *
     * @param {BoardViewModel} boardListItem
     */
    _gotoDetails(boardListItem) {
        this.routeToPage(this._detailsUrlPath, /** ReferenceDTO.<Board> */boardListItem);
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                         Notification Support
    //
    // Additional notification mechansims are impl'd in parent, see also:
    //     super.startAllSubscriptions,
    //     super.unsubscribeAllSubscriptions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * Establishes the passive commo facility giving us a window of opportunity to update the list state in reaction
     * to any of the constituents getting their status updated.
     */
    _subscribeToNotifications() {

        let
            /** {Promise.<Subscription>}*/newListItemSubscription =
            this.notificationService.subscribeBoardCreation(this.$scope.practice, this.$scope.office),
            /** {Promise.<Subscription>}*/updatedListItemSubscription =
            this.notificationService.subscribeAllBoardUpdatesAtPractice(this.$scope.practice);

        // Board Creation
        this.registerSubscription(newListItemSubscription)
            .then(null, null, (notification) => this._onNewBoardAdded(notification));

        // Board Updates : for any of the boards in my list.
        this.registerSubscription(updatedListItemSubscription)
            .then(null, null, (notification) => this._onListItemChanged(notification));

    }

    /**
     * @param {Notification} notif : conveys the news that a new  Board instance has been added to this office.
     */
    async _onNewBoardAdded(notif) {

        let /** {BoardDataModel}*/newDm = notif.body;
        if (newDm.procedure !== this._useCaseProcedure)
            return undefined;

        newDm._panel = await this._fetchAssocPanel(newDm);

        this.$scope.$apply( ()=>
            this.$scope.rowCollection.push(/** BoardViewModel*/this._createVm(newDm)));
    }

    /**
     * @param {Notification} notif : conveys the news that a Board instance in this logical DM list, has been altered
     * in a way which warrants a UI update. Currently: a user changing a Board's status field meets this criteria.
     */
    async _onListItemChanged(notif) {

        let /** {BoardDataModel}*/updatedBoardDm = notif.body;
        if (updatedBoardDm.procedure !== this._useCaseProcedure)
            return undefined;

        for (let iBoard in this.$scope.rowCollection) {

            if (updatedBoardDm.id === this.$scope.rowCollection[iBoard].id) {

                if (updatedBoardDm.office.id === this.$scope.office.id) {
                    let panelDm = await this._fetchAssocPanel(updatedBoardDm);
                    // ... and then ...
                    updatedBoardDm._panel = panelDm;

                    this.$scope.$apply(()=>
                        this.$scope.rowCollection[iBoard] = /** BoardViewModel*/this._createVm(updatedBoardDm));
                }
                else {
                    // Board moved away
                    this.$scope.rowCollection.splice(iBoard, 1);
                }
                
                return;
            }
        }

        // Wasn't found - add it if for visible office
        if (updatedBoardDm.office.id === this.$scope.office.id) {
            this._onNewBoardAdded(notif);
        }
    }
}
