import angular from 'angular';
import ngRoute from 'angular-route';

import TestSptCheckListController from './spt-checklist.controller.js';

export default angular.module('pages.testing.sptCheckList', [ngRoute])
    .config(config)
    .controller('TestSptCheckListController', TestSptCheckListController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/sptCheckList', {
        template: require('./spt-checklist.html'),
        css: require('./spt-checklist.scss'),
        controller: 'TestSptCheckListController'
    });
}

config.$inject = ['$routeProvider'];
