'use strict';

import TestingController from '../testing.controller'

export default class TestIdtHistamineMeasureController extends TestingController {

    INPUT_INDEX = 1;

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {

        super($scope, $injector);

        this.$scope.setInstructionText= (index) => {
            this.$scope.tabindex = this.INPUT_INDEX;
        };

        $scope.onWhealChange = () => {};
        $scope.switchArm = () => {};

        $scope.gs = {
            substanceCount: 1,
            setFocus: $scope.setFocus,
            onWhealChange: $scope.onWhealChange,
            switchArm: $scope.switchArm,
            wheals: [],
            focused: [],
            leftArm: false,
            rightArm: false,
            measureConfig: {}
        };

        this.disableNext = true;
        this.allergyTestLoaded()
            .then(() => this._loadAllergyTestConfig())
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TestIdtHistamineMeasureController constructor completed!");
    }

    reload() {
        console.log("Loading data in TestIdtHistamineMeasureController");
        super.validateStage(['IDT_HISTAMINE_RESULTS']);

        this.$scope.isLoaded = false;
        this.$scope.text = 'Measure Histamine Size';
        this.$scope.textrightjustify = 'TIME ELAPSED:';
        this.initFooterNote('IDT Histamine');
        
        this.$scope.gs.measureConfig = this._getMeasureConfig(true);

        this.$scope.showModal = false;
        this.$scope.toggleModal = () => {
            this.$scope.showModal = !this.$scope.showModal;
        };

        // Set up some fake structure to make the testing widget happy without an actual TestLocation view.
        this.$scope.singleMeasurement = true;
        this.$scope.tabindex = this.INPUT_INDEX;
        this.$scope.thisInput = this.INPUT_INDEX;
        this.$scope.section1 = this._measurementsToView(this.$scope.allergyTest.idtHistamineMeasurement, true);
        this.disableNext = false;
        this.$scope.isLoaded = true;
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        console.log("IDT histamine wheal = " + this.$scope.gs.wheals[this.INPUT_INDEX]);
        const measurements = this._viewToMeasurements(this.$scope.gs.wheals[this.INPUT_INDEX], true);
        this.$scope.allergyTest.idtHistamineMeasurement = measurements;
        this.updateFooterNote();
        return super.update();
    }

    /**
     * @override
     */
    nextDisabled() {
        if (this.disableNext || !this.$scope.allergyTest) {
            return true;
        }

        const measureConfig = this.$scope.gs.measureConfig;
        const measurements = this._viewToMeasurements(this.$scope.gs.wheals[this.INPUT_INDEX], true);
        return (measureConfig.wheal && measurements.wheal == undefined) ||
            (measureConfig.erythema && measurements.erythema == undefined);
    }
}
