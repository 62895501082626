'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import moment from 'moment';

export default class TreatmentComplianceReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.TREATMENT_COMPLIANCE);
        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getTreatmentCompliance(this.reportCriteria);
    }

    _renderReportData(reportData) {
        let currentDate = this.chronologyMappingService.currentDate(this.$scope.office.timezone);

        this.$scope.rowCollection = [];
        for(let row of reportData) {
            let lastTreatmentDate = this.chronologyMappingService.startOfDay(row.lastTreatmentDate);
            row.daysSinceLastTreatment = this.chronologyMappingService.daysBetween(currentDate, lastTreatmentDate);

            row.lastTreatmentDate = moment(row.lastTreatmentDate);
            row.lastTreatmentDateDisplay = row.lastTreatmentDate.format('MM/DD/YYYY');
            this.$scope.rowCollection.push(row);
        }
    }

    _getCsvMeta() {
        return [
            { name: 'Patient Name', value: 'patientName' },
            { name: 'Chart Number', value: 'chartNumber' },
            { name: 'Patient Status', value: 'patientStatus' },
            { name: 'Home Phone', value: 'homePhoneNumber' },
            { name: 'Mobile Phone', value: 'mobilePhoneNumber' },
            { name: 'Email', value: 'email' },
            { name: 'Location', value: 'location' },
            { name: 'Vial Name', value: 'vialName' },
            { name: 'Vial Status', value: 'vialStatus' },
            { name: 'Dosing Schedule', value: 'dosingSchedule' },
            { name: 'Last Treatment Date', value: 'lastTreatmentDateDisplay' },
            { name: 'Days Since Last Treatment', value: 'daysSinceLastTreatment' },
            { name: 'Ordering Provider', value: 'orderingProvider' }
        ];
    }
}
