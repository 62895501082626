'use strict';

import angular from 'angular';

import TestReviewApprovalController from "./test-review-approval.controller.js";

export default angular.module('pages.common.approvals.test-review', [])
    .controller('TestReviewApprovalController', TestReviewApprovalController)
    .directive('agTestReviewApproval', [agTestReviewApproval])
    .name;

// Directive to present the test approvals screen.
function agTestReviewApproval() {
    return {
        template: require('./test-review-approve.html'),
        css: require('./test-review-approve.scss'),
        controller: 'TestReviewApprovalController',
        scope: {
            testReviewHref: '=',
            /** @type{boolean} Display testing results for final approval (and prescription creation)? */
            isApproval: '=',
            /** @type{boolean} Display test results from within the Testing Wizard? */
            isTestWizard: '=',
            /** Callback function upon exit. If isTestWizard and AllergyTest is modified,
                will pass the modified object as first parameter to callback function. */
            onExitCallback: '&?'
        },
        link: function(scope, element, attrs) {

        }
    }
}
