'use strict';

import BaseTreatmentController from '../base-treatment.controller'

export default class TreatmentVialTestController extends BaseTreatmentController {

    /** {Injection} currently selected. (Includes _vial field containing TreatmentVial). */
    injection = undefined;

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);
        this.globalConfigService = $injector.get('globalConfigService');

        // Scope functions
        $scope.barcodeModal = () => this.barcodeModal();
        $scope.barcodeMismatchModal = () => this.barcodeMismatchModal();
        $scope.skipDose = () => this.skipDose();
        $scope.administerDose = true;

        // Scope data
        $scope.allowAutoBarcode = false;

        // Load data

        this._loadGlobalConfig();

        this.treatmentLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.$scope.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.VIAL_TEST]);

        if (!this.treatment.clientData.treatwiz.vialTestsPerformed)
            this.treatment.clientData.treatwiz.vialTestsPerformed = 0;

        this.$scope.validBarcode = false;
        this.$scope.administerDose = true;
        this.$scope.treatmentType = this.treatment.type;
        this.$scope.barCode = '';
        this.$scope.vial = undefined;
        this.$scope.testingVial = this.treatment.clientData.treatwiz.vialTestsPerformed;
        this.$scope.barCodesInTreatment = [];
        this.initFooterNote('Vial Test');

        //Build list of vial barcodes in the treatment
        for (let i=0; i<this.treatment.injections.length; i++ ) {
            this.$scope.barCodesInTreatment.push(this.treatment.injections[i]._vial.barcode);
        }

        // Find next injection to administer
        this.injection = this.$scope.vialTests[this.treatment.clientData.treatwiz.vialTestsPerformed];

        if (this.injection) {
            // Build VM in $scope.vial
            let inj = this.injection;
            let vial = this.injection._vial;
            this.$scope.vial = {
                name: vial.name,
                volume: inj.idtDosage,
                barcode: vial.barcode,
                mixNumber: vial.mixNum,
                useBy: vial.useBy,
                beyondUse: vial.beyondUse,
                color: vial.color,
                location: this.treatment.clientData.treatwiz.vialTestLocation
            };

            this.$scope.text = 'Apply Vial Test - ' + vial.name + ': ' + inj.idtDosage.toFixed(2) + ' mL';
        }
        else {
            console.log("TreatmentVialTestController: ** No Vial Test Injection found **");
            super.nextStep();
        }

        this.disableNext = false;
    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext)
            return true;
        else if (this.injection === undefined)
            return false; // no injection

        return !this.$scope.validBarcode && this.$scope.administerDose;
    }

    /**
     * @override
     */
    async nextStep() {
        this.disableNext = true;

        let idx = ++this.treatment.clientData.treatwiz.vialTestsPerformed;

        // Save updated position & note
        this.updateFooterNote();
        await this.update();

        if (idx >= this.$scope.vialTests.length) {
            // Advance to next step of wizard
            return super.nextStep();
        }
        else {
            // Move on to next vial
            await this.reload();
            this.$scope.$digest();
        }
    }

    barcodeModal() {
        //let $this = this;

        this.$uibModal.open({
            windowClass: 'barcode',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/barcode-modal.html'),
            css: require('./widgets/barcode-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        }).result
            .catch(() => this.$scope.barCode = '');
    }

    barcodeMismatchModal() {
        //let $this = this;

        this.$uibModal.open({
            windowClass: 'barcode',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/barcode-mismatch-modal.html'),
            css: require('./widgets/barcode-mismatch-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        }).result
            .catch(() => this.$scope.barCode = '');
    }

    skipDose() {
        this.$scope.modalOpen = true;
        this.$uibModal.open({
            windowClass: 'skip',
            template: require('./widgets/skip-modal.html'),
            css: require('./widgets/skip-modal.scss'),
            resolve: {
                note: () => {
                    return this.injection.doseOverrideBy ? this.injection.doseOverrideBy.note : '';
                }
            },
            controller: function ($uibModalInstance, $scope, note) {
                $scope.note = note;
                $scope.cancel = () => $uibModalInstance.dismiss();
                $scope.continue = () => $uibModalInstance.close($scope.note);
            }
        }).result.then((note) => {
            this.injection.idtWhealSize = -1;
            this.injection.idtDoseOverrideBy = { note: note };
            this.$scope.administerDose = false;
        }).catch(() => this.$scope.modalOpen = false);
    }
}
