"use strict";

/**
 * An office procedure.
 */
export default angular.module("models.procedure", [])
    .constant("Procedure", {
        TESTING : "TESTING",
        IDT : "IDT",
        SPT : "SPT",
        TREATMENT : "TREATMENT",
        MIXING: "MIXING",
        IDT_HISTAMINE : "IDT_HISTAMINE"
    }).name;