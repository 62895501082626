import angular from 'angular';
import ngRoute from 'angular-route';

import TestMeasureIntradermalController from './testing-measure-intradermal.controller';
import TestMeasureIntradermalTimerController from './testing-measure-intradermal-timer.controller.js';

export default angular.module('pages.testing.measureIntradermal', [ngRoute])
    .config(config)
    .controller('TestMeasureIntradermalController',TestMeasureIntradermalController)
    .controller('TestMeasureIntradermalTimerController',TestMeasureIntradermalTimerController)
    .name;

function config($routeProvider) {
    $routeProvider.when('/testing/measureIntradermal', {
        template: require('./testing-measure-intradermal.html'),
        controller: 'TestMeasureIntradermalController'
    }).when('/testing/measureIntradermal/timer',{
        template: require('../measure-spt-result/testing-measure-spt-timer.html'),
        controller: 'TestMeasureIntradermalTimerController'
    });
}

config.$inject = ['$routeProvider'];
