import angular from 'angular';
import ngRoute from 'angular-route';

import WidgetsController from './widgets.controller.js';

export default angular.module('pages.widgets', [ngRoute])
    .config(config)
    .controller('WidgetsController', WidgetsController)
    .directive('btnPrintReport', [btnPrintReport])
    .directive('styleCheckbox', [styleCheckbox])
    .directive('doctorSignature', [doctorSignature])
    .directive('contenteditable', [contenteditable])
    .name;

function config($routeProvider) {

//    $routeProvider.when('/test_layouts/doctor', {
//        template: require('./doctor.html'),
//        controller: 'WidgetsController'
//    });
//    
//    $routeProvider.when('/test_layouts/review-prescription-mix', {
//        template: require('./review-prescription-mix.html'),
//        controller: 'WidgetsController'
//    });
//    
//    $routeProvider.when('/test_layouts/test-approve', {
//        template: require('./test-approve.html'),
//        controller: 'WidgetsController'
//    });
//
//    $routeProvider.when('/test_layouts/review-treatment', {
//        template: require('./review-treatment.html'),
//        controller: 'WidgetsController'
//    });
//    
//    $routeProvider.when('/test_layouts/review-vial-test-and-treatment', {
//        template: require('./review-vial-test-and-treatment.html'),
//        controller: 'WidgetsController'
//    });
}

config.$inject = ['$routeProvider'];


// Directive to create and use stylized checkboxes
function styleCheckbox() {
    return {
        scope: {
            id: '@checkboxId',
            isChecked: '=',
            label: '@',
            checkAllSelected: '=?'
        },
        template: require('../common/approvals/widgets/style-checkbox.html'),
        css: require('../common/approvals/widgets/style-checkbox.scss')
    };
}

// Directives for buttons across the doctor pages

// Directive for print report button
function btnPrintReport() {
    return {
        scope: {
            isDisabled: "="
        },
        template: '<button ng-disabled="isDisabled" class="btn btn-primary" ng-click="printReport()" >Print Report</button>',
        link: function (scope) {
            scope.printReport = function () {
                window.print();
            };
        }
    };
}


//Directive to create an editable table cell
function contenteditable() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
            element.bind('blur', function () {
                scope.$apply(function () {
                    ctrl.$setViewValue(element.html());
                });
            });

            ctrl.render = function (value) {
                element.html(value);
            };

            ctrl.$setViewValue(element.html());

            element.bind('keydown', function (event) {
                var esc = event.which == 27,
                    el = event.target;

                if (esc) {
                    ctrl.$setViewValue(element.html());
                    el.blur();
                    event.preventDefault();
                }

            });
        }
    };
}

// Directive that contains the template for a doctor signature
function doctorSignature() {
    return {
        template: require('./doctors-signature.html'),
        css: require('./doctors-signature.scss')
    };
}
