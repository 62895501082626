
export const DYMO30336_WIDTH_IN = 1
export const DYMO30336_LENGTH_IN = 2.125

const globalCss = landscape => {
	const width = landscape ? DYMO30336_LENGTH_IN : DYMO30336_WIDTH_IN
	const height = landscape ? DYMO30336_WIDTH_IN : DYMO30336_LENGTH_IN

	return `
		@page {
			size: ${width}in ${height}in;
			margin: .0625in; /* 1/16in print margin each side */
		}
		html, body {
			padding: 0;
			margin: 0;
		}
		body {
			min-height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: white;
			color: black;
			font: 8pt sans-serif;
			line-height: 1em;
		}
	`
}
export const dymo30336PortraitGlobalCss = globalCss(false)
export const dymo30336LandscapeGlobalCss = globalCss(true)
