"use strict";

import angular from "angular";
import { ClassicalDilution, ClassicalDilutions, toPascalCase } from "../../../../models/classical-dilutions";
import BaseController from "../../../base.controller";
import EditBoardController from "../edit-inv-board-modal/controller";
import EditVialController from "./vial-editor/controller";
import ClearInventoryAlertController from '../../widgets/clear-inventory-alert-modal/controller';

import React from 'react'
import { submitPrintJob } from '../../../../react/print/Printer'
import { PRINTER_DYMO30336_PORTRAIT, SingleDilutionLabel } from "../../../../react/print/Dymo30336Portrait"
import { PRINTER_DYMO30336_LANDSCAPE, TrayLabel, LandscapeToPortrait } from "../../../../react/print/Dymo30336Landscape"

export default class BaseBoardDetailsController extends BaseController {

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                              Model Types
    // Enumerations and other constant types used to support the proceedings
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     * @type {Enumeration.<{InventorySections}>}
     * This value is a enumeration definition. Not to be confused wtih #_sectionType, which is an instance of this type
     */
    _INVENTORY_SECTIONS;

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                            Service Impl's
    // Injected resources providing ongoing data support.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     * @type {BoardService}
     */
    _boardService;

    /**
     * @protected
     * @type {ConcentrateService}
     */
    _concentrateService;

    /**
     * @protected
     * @type {EligibleStatusService}
     */
    _eligibleStatusService;

    /**
     * @protected
     * @type {PanelService}
     */
    _panelService;

    /**
     * @protected
     * @type {PromiseService}
     * Angular's PromiseService impl. See: https://code.angularjs.org/1.5.0/docs/api/ng/service/$q
     * This service produces defered logic instances, capable of managing asynchronous operations smoothly.
     */
    _$q;

    /**
     * @protected
     * @type {Angular-Bootstrap-UiModal-serviceProvider}
     *
     * 3rd party libary; arbiter of the modal-popup presentation.
     *
     * @see https://angular-ui.github.io/bootstrap/#/modal
     * @see https://github.com/angular-ui/bootstrap/tree/master/src/modal
     */
    _$uibModal;

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                        Instance State Members
    // Resolved data values representing facets of this instance's and the subject model's logical state.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     * @type {ReferenceDTO.<Board>}
     *
     * A reference to this instance's subject value. We'll gain access to this value immediately upon reciept of the
     * routeParams and use it for any data dependencies that need it before we acquire the actual data model value.
     */
    _subjectReference;

    /**
     * @protected
     * @type {Object}
     *
     * the Board model star of the show. Basically, the value is a serialized BoardDTO instance augmented with
     * serialized forms of the DTO representing child member models of the Board instance. The attached child models
     * include the associated Panel and the list of Vials (which in turn lazy-load Concentrates).
     */
    _dataModel;

    /**
     * @protected
     * @type {InventorySections}
     * the instance's "flavor"
     */
    _sectionType;

    /**
     * @protected
     * @type {String}
     * logically, it's a URL-fragment associated with the BoardList. The same list of which the logical subject Board
     * value (this instance's subject) is a member.
     */
    _sectionUrlPath;

    /**
     * @type {Object}
     * When dispatching this instance's logical 'root' request (for the subject's logical list of Boards), we store the
     * initial response value for subsequent use as the *ReferencingDTO* param for lazily loaded, associated child
     * models. This supports our remote DM service API's list-acquisition performance optimization.
     *
     * @protected
     */
    _subjectRootReference = undefined;

    /**
     * @protected
     * @type{Map.<String,{ConcentrateModelObject}>}
     *
     * Purpose: maps unique identifiers to Concentrate models. Allows us to request distinct instances exactly once.
     */
    _concentrateIdMap = new Map();

    /**
     * @protected
     * @type{Map.<Integer,Array.<{VialModelObject}>>}
     *
     * Relevant during model building, obsolete once state is established.
     * Purpose: maps logical tray indices [0,1,2...] to an Array of Vial-VM .
     */
    _trayVialsMap = new Map();


    static $inject = ["$scope","$injector","activeSection","sectionUrlPath","subjectTypeAdjective"];
    constructor( $scope , $injector , activeSection , sectionUrlPath , subjectTypeAdjective ) {

        super($scope, $injector);

        this._sectionType = activeSection;
        this._sectionUrlPath = this.$scope.sectionUrlPath = sectionUrlPath;
        this._initInjections($injector);

        this.$scope.subjectTypeAdjective = subjectTypeAdjective;
        this.showHistoricalVials = false;

        this.$scope.printBarcodeModal = () => this._showPrintUi();
        this.$scope.editBoardModal = () => this._showEditUi();
        this.$scope.onStatusChange = () => this._updateSubjectStatus();
        this.$scope.toConcentrateDetails = (subjVial) => this._navigateToConcentrateDetails(subjVial);
        this.$scope.editVialModal = (subjVial) => this._showEditVialUi(subjVial);
        this.$scope.clearInventoryAlertModal = (subjVial) => this._clearInventoryAlertModal(subjVial);
        this.$scope.printVialLabel = (trayVialVm) => this._reprintVialLabel(trayVialVm);
        this.$scope.toggleHistoricalVials = () => this._toggleHistoricalVials();
        this.$scope.getTrayVialColumnCount = () => this._getTrayVialColumnCount();

        this._processRoute();

        this.inventoryAlertService.subscribe(this.$scope, alertSummary => this._updateTrayVialAlerts(alertSummary));
    }

    /**
     * @param {AngularInjectorService} $injector
     * @protected
     */
    _initInjections($injector) {
        this._$q = $injector.get("$q");
        this._$timeout = $injector.get("$timeout");
        this._boardService = $injector.get("boardService");
        this._concentrateService = $injector.get("concentrateService");
        this._chronologyMappingService = $injector.get("chronologyMappingService");
        this._eligibleStatusService = $injector.get("eligibleStatusService");
        this._panelService = $injector.get("panelService");
        this._$uibModal = $injector.get("$uibModal");
        this._INVENTORY_SECTIONS = $injector.get("InventorySections");
        this.$scope.ServiceStatus = this.ServiceStatus = $injector.get("ServiceStatus");
    }


    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                         Lifecycle Management
    //
    // Instances will load the subject, the first time driven by _processRoute, and following subsequent
    // mutations, the model is refreshed by _refreshDataModel .
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     *
     * @return {Promise.<{void}>}
     * We Promise to update the scope, defining *eligibleStatusTypes* as an Array of
     * ServiceStatus values.
     */
    _loadServiceStatusValues() {
        return this._eligibleStatusService.getEligibleStatusTypesForBoard( /** @type{BoardDataModel} */ this._dataModel)
            .then(eligibleStatusTypes => (this.$scope.eligibleStatusTypes = eligibleStatusTypes));
    }

    /** @protected */
    _processRoute() {

        let routeParams = this.getRouteParams();

        if (!routeParams || !routeParams.href) {
            console.error("Failed to load Inventory-Board Details due to missing route params.");
            this.routeToPage(this._sectionUrlPath);
        }
        else {
            this.$scope.fromLocation = this.routingService.extractLocationParams(routeParams, `#${this.$scope.sectionUrlPath}`, `${this.$scope.subjectTypeAdjective} Board List`);

            this._subjectReference = {
                href: routeParams.href,
                id:routeParams.id
            };
            this._initDataModel();
        }

    }

    /** @protected */
    _initDataModel() {
        this.$scope.loadingBoard = true;

        this._trayVialsMap.clear();
        this.notificationService.init()
            // serves & affects DM
            .then(() => this.unsubscribeAllSubscriptions())
            .then(() => this._subscribeToBoardChangeNotifs())
            // acquire our core-DM
            .then(() => {
                if (this.showHistoricalVials) {
                    return this._boardService.getWithAllVials(this._subjectReference, null, true);
                }
                else {
                    return this._boardService.getWithCurrentVials(this._subjectReference, null, true);
                }
            })
            // acquire child-DM
            .then(boardResponse => {
                this._subjectRootReference = boardResponse;
                this._dataModel = boardResponse;
                return this._panelService.get(this._dataModel.panel, this._subjectRootReference);
            })
            // build the logical Tray-model
            .then(panelResponse => {
                // We got the subject board's Panel; nail it to parent
                this._panelService.populateSptGroupNames(this.$scope.practice, panelResponse);
                this._dataModel._panel = panelResponse;

                // Load the vial tray map for use downstream
                for (let aVial of this._dataModel.vials) {

                    if (!this._trayVialsMap.has(aVial.trayNum)) {
                        this._trayVialsMap.set(aVial.trayNum, new Array());
                    }
                    this._trayVialsMap.get(aVial.trayNum).push(aVial);
                }

            })
            .then(() => {
                this._renderVm();
                this.$scope.loadingBoard = false;
            });
    }

    _renderVm() {
        return this._createVm(this._dataModel)
            .then(vm => {
                this.$scope.vm = vm;
                this.inventoryAlertService.sendTo(this.$scope);

                this._initTraysVm();
            })
            .then(() => this._loadServiceStatusValues())
            .then(() => this.startAllSubscriptions());
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                            Event Reactions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     *
     * @returns {Promise.<{void}>}
     *
     * Updates this instance's DM.status (assigning it the value from VM.status), saves the updated DM, then upon
     * receipt of the save reloads this instance's models. Even thought the refreshed model will recreate the same
     * VM state, the DM state MUST necessarily be updated (if for no other reason, the HATEFUL href field must include
     * the correct version token lest the remote DM will get out of sync with client-sdie models.
     */
    _updateSubjectStatus() {
        this._dataModel.status = this.$scope.vm.status;

        return this._boardService.update(this._dataModel);
    }

    /**
     * @protected
     *
     * @param {{ id: String, href: URL }} concentrate
     * Serialized reference to the Concentrate of interest;
     * AKA the subject of the destination
     */
    _navigateToConcentrateDetails(concentrate) {
        this.routeToPage(this.urlPaths.INVENTORY_CONCENTRATE_DETAILS, concentrate);
    }

    /**
     * @protected
     *
     */
    _showPrintUi() {
        let parent = this;
        this._$uibModal.open({
            windowClass: 'printBarcode',
            scope: this.$scope, //passed current scope to the modal
            template: require("../print-barcode-modal.html"),
            css: require("../print-barcode-modal.scss"),
            controller: ($uibModalInstance, $scope, subjectBoard, $filter) => {

                // DOM/UI-event predicates
                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.printBarcodeLabel = (data, labelAmount, showBoard, showVials) => {

                    //let vials = $scope.$parent.vm.trayVials;

                    let vials = $filter('orderBy')($scope.$parent.vm.trayVials, ['tray','antigen','description','dilution']);

                    parent._printTrayLabels(data, labelAmount, showBoard, showVials, vials);

                    this._$timeout(() => {
                        $uibModalInstance.close();
                    }, 500);
                };

                // stateful support

                $scope.getNumber = (num) => (new Array(num));

                $scope.$watch("labelAmount", (newVal, oldVal) => {
                    if ((angular.isDefined(newVal)) && (newVal !== oldVal)) {
                        $scope.getNumber(newVal);
                    }
                });

                // State Initialization
                $scope.boardType = parent._sectionType;
                $scope.data = {};
                $scope.data.boardName = subjectBoard.name;
                $scope.data.panelName = subjectBoard._panel.name;
                $scope.data.totalTrays = subjectBoard.trayCount;
                $scope.data.totalAnitgens = (subjectBoard.substancesPerTray * subjectBoard.trayCount);
                $scope.data.barcode = subjectBoard.barcode;
                $scope.labelAmount = $scope.data.totalTrays;
            },
            resolve: {
                "subjectBoard": () => this._dataModel
            }
        });

    }

    /**
     * @protected
     *
     */
    _showEditUi() {

        /**
         * Callback function that EditBoardController may use via the shared scope.
         * @return {Promise} that resolves when printing is complete.
         */
        this.$scope.printEditedBoard = (board) => {
            let data = {
                boardName: board.name,
                barcode: board.barcode
            };
            return this._printTrayLabels(data, board.trayCount, true, false);
        };

        let modal = this._$uibModal.open({
            windowClass: 'editBoard',
            scope: this.$scope, //passed current scope to the modal
            template: require("../edit-inv-board-modal/layout.html"),
            css: require("../edit-inv-board-modal/styles.scss"),
            controller: EditBoardController,
            resolve: {
                "subjectBoard": () => this._dataModel
            }
        });
    }

    /**
     * @param {TrayVialViewModel} trayVialVm
     * @returns {Promise} You may as well consider promised type to be void. The deferred predicate will unleash a
     *  document reload, and we plan no further actions afterwards. There are 2 "happy" post-condition-states for this
     *  method:
     *      A. Abortion : user bailed out of the operation via modal's "Cancel" / dismissal UI action. Result: this
     *          instance's passive lifecylce continues.
     *      B. Fruition : user accepts the replace-vial-UI's condition. Result: this instance performs the svc-calls
     *          to change the DM state. The nature of change is such that we need to reload the document. This document
     *          instance will be obliterated shortly before the rerouting logic rebuilds the UI.
     */
    _showEditVialUi(trayVialVm) {

        let
        /** function({TrayVialDataModel}) : {TrayVialDataModel} */attachConcentrateDm = dm => {
            dm.concentrate = angular.copy(/** ConcentrateDataModel */this._concentrateIdMap.get(dm.concentrate.id));
            return dm;
        },
        /** TrayVialDataModel */trayVialDm = attachConcentrateDm(this._dataModel.vials[trayVialVm._dmIndex]),
        /** Bootstrap-ui.modal */editorModal = this._$uibModal.open({
            windowClass: 'editVial',
            scope: this.$scope, //passed current scope to the modal for ease of callback access
            template: require("./vial-editor/layout.html"),
            css: require("./vial-editor/styles.scss"),
            controller: EditVialController,
            resolve: {
                "subjectBoard": () => this._dataModel,
                "subjectVial": () => trayVialDm,
                "concentrateService" : ()=> this._concentrateService
            }
        });

        editorModal.result.then(/** TrayVialDataModel */replacementFromVial => {
            let
            /** PanelDataModel */myPanel = this._dataModel._panel,

            /** boolean */isExpiredOrRecalled =
                ((trayVialDm.concentrate.status === this.ServiceStatus.EXPIRED)
                    || (trayVialDm.concentrate.status === this.ServiceStatus.RECALLED)),

            /** ServiceStatus */replacedConcentrateStatus = isExpiredOrRecalled ? trayVialDm.concentrate.status : this.ServiceStatus.DEPLETED,

            /** BoardFilter */vialFilter = this.showHistoricalVials ? this._boardService.ALL_VIALS : this._boardService.CURRENT_VIALS;

            super.pauseAllSubscriptions();

            /* Traditional boards have a special arrangement and utilize the antigen number in place of board column by the server */
            if (this._dataModel.arrangement === 'TRADITIONAL') {
                let panelSubstance = myPanel.substances.find(m => m.substance.id === trayVialDm.substance.id);
                switch (this._dataModel.procedure) {
                    case 'SPT':
                        trayVialDm.boardColumn = panelSubstance.sptPos;
                        break;
                    case 'IDT':
                        trayVialDm.boardColumn = panelSubstance.idtPos;
                        break;
                    case 'MIXING':
                        trayVialDm.boardColumn = panelSubstance.mixPos;
                        break;
                    default:
                        trayVialDm.boardColumn = -1;
                        break;
                }
            }

            return this._boardService.replaceVial(
                    this._dataModel,
                    trayVialDm,
                    replacedConcentrateStatus,
                    replacementFromVial.id,
                    vialFilter
                )
                .then(/** BoardDataModel */updatedBoard => {
                    this._dataModel = updatedBoard;
                    this._dataModel._panel = myPanel;

                    this._printVialLabel(
                        /** TrayVialDataModel */this._boardService.getCurrentVial(
                            updatedBoard,
                            trayVialDm.substance,
                            trayVialDm.dilution
                        ));
                })
                .then(() => this._renderVm());
        });

    }

    _clearInventoryAlertModal(trayVialVm) {
        if (!trayVialVm.alert) return;

        let confirmModal = this.$uibModal.open({
            windowClass: 'clearInventoryAlert',
            template: require('../../widgets/clear-inventory-alert-modal/layout.html'),
            css: require('../../widgets/clear-inventory-alert-modal/styles.scss'),
            controller: ClearInventoryAlertController,
            resolve: {
                'inventoryItem' : () => {
                    return { name: trayVialVm.description, serviceStatus: trayVialVm.concStatus };
                }
            }
        });

        confirmModal.result.then(() =>  {
            this.inventoryAlertService.clearAlerts(this.$scope.practice, trayVialVm._dmId)
                .then(() => {
                    trayVialVm.alert = null;
                    trayVialVm._alertDismissed = true;
                });
        });
    }

    _toggleHistoricalVials() {
        this.showHistoricalVials = !this.showHistoricalVials;
        this._initDataModel();
    }

    _getTrayVialColumnCount() {
        return document.querySelectorAll('.tray-details .table-header th').length;
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                     View Model creation/mapping
    // These routines produce expressions of the subject data-model in a form the amenable to the UI-View layout.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     *
     * @param {BoardDataModel} dm
     *
     * @returns {Promise.<{BoardDetailsViewModel}>}
     */
    _createVm(dm) {

        let
            /** @type{BoardDetailsViewModel} */vm = {
                "panelName": dm._panel.name,
                "totalAntigens": dm.vials.length,
                "barcode": dm.barcode,
                "createdDateTime":
                    this._chronologyMappingService.utcToTimezone(dm.createdDateTime, this.$scope.office.timezone)
            };

        for (let aField of["name", "trayCount", "startService", "endService", "status", "procedure", "arrangement"]) {
            vm[aField] = dm[aField];
        }

        return this._createTrayVialsListVm(dm.vials)
            .then(trayVialsVm => {
                vm.trayVials = trayVialsVm;
                return vm;
            });

    }

    /**
     * @protected
     * @param {Array.<{BoardDetailsTrayVialDataModel}>} vialsDmList
     * @returns {Promise.<{Array.<{BoardDetailsTrayVialViewModel}>}>}
     */
    _createTrayVialsListVm(dmList) {
        let
        /** @type{Array.<{BoardDetailsTrayVialViewModel}>} */
            vmList = [];

        for (let vialIndex = 0; vialIndex < dmList.length; ++vialIndex) {
            let /** @type{TrayVialDataModel} */dm = this._dataModel.vials[vialIndex];
            let trayVialVm = this._trayVialDmToVm(dm, vialIndex);
            vmList.push(trayVialVm);
        }

        // Pull in concentrate details in the background - don't wait for results before displaying
        // this list, because this can take a few seconds.
        this._populateConcentrateDetail(dmList, vmList);

        return this._$q.resolve(vmList);
    }

    /**
     * @protected
     *
     * @param {VialDataModel} trayVialDm
     * @param {Integer} parentDmListIndex
     * @returns {BoardDetailsTrayVialViewModel}
     */
    _trayVialDmToVm(trayVialDm, parentDmListIndex) {
        let antigenNumber = trayVialDm.boardColumn + 1;
        let dilution = trayVialDm.dilution;

        if (this._dataModel.arrangement === 'TRADITIONAL') {
            let panelSubstance = this._dataModel._panel.substances.find(m => m.substance.id === trayVialDm.substance.id);
            switch (this._dataModel.procedure) {
                case 'SPT':
                    antigenNumber = panelSubstance.sptPos + 1;
                    break;
                case 'IDT':
                    antigenNumber = panelSubstance.idtPos + 1;
                    break;
                case 'MIXING':
                    antigenNumber = panelSubstance.mixPos + 1;
                    break;
                default:
                    antigenNumber = -1;
                    break;
            }
            dilution = ClassicalDilutions[dilution] ? toPascalCase(ClassicalDilutions[dilution].color) : dilution;
        }

        return /** @type{BoardDetailsTrayVialViewModel} */ {
            // Data Fields whose UI-fieldname != DataModel fieldname
            "tray": trayVialDm.trayNum,
            "antigen": antigenNumber,
            "description": '',
            "dilution": dilution,
            "barcode": trayVialDm.barcode,
            "inServiceDate": trayVialDm.startService,
            "concInServiceDate": undefined,
            "concEndServiceDate": undefined,
            "concentrateStatus": '',
            "alert": undefined,
            "concStatus": undefined,
            // Metadata
            "_dmIndex": parentDmListIndex,
            "_concentrate": null,
            "_dmId" : trayVialDm.id,
            "current" : trayVialDm.current
        };
    }

    /**
     * Add Concentrate details to a VM TrayVial
     *
     * @param trayVialVm {VialDataModel} VM vial to update
     * @param controllerDm {Concentrate} source DM concentrate
     * @private
     */
    static _addConcentrateDmToVm(trayVialVm, concentrateDm) {
        trayVialVm.description = concentrateDm.substance.name;
        trayVialVm.sptGroup = concentrateDm.substance.sptGroup;
        trayVialVm.concInServiceDate = concentrateDm.startService;
        trayVialVm.concEndServiceDate = concentrateDm.endService;
        trayVialVm.concStatus = concentrateDm.status;
        trayVialVm._concentrate = angular.copy(concentrateDm);
    }

    /**
     * Asynchronously load Concentrate and Substance details, and populate them into the VM.
     *
     * @param dmVialList {Array<TrayVial>}
     * @param vmVialList {Array{TrayVialVM>}
     * @private
     */
    _populateConcentrateDetail(dmVialList, vmVialList) {
        /**
         * First build a mapping of what Concentrate (by href) is needed by each vmVial.
         * @type Map<Concentrate.href, Array<TrayVialVM>>
         */
        let concentrateToVm = new Map();

        for (let vialIndex = 0; vialIndex < dmVialList.length; ++vialIndex) {
            let dmVial = dmVialList[vialIndex];
            let vmVial = vmVialList[vialIndex];

            let concentrateHref = dmVial.concentrate.href;
            let pendingVms = concentrateToVm.get(concentrateHref);
            if (pendingVms === undefined)
                concentrateToVm.set(concentrateHref, [vmVial]);
            else
                pendingVms.push(vmVial);
        }

        /*
         * Now that we have the complete list of concentrates and where they need to go,
         * we can asynchronously load each one and populate the VM without race conditions.
         */
        for (let concentrateHref of concentrateToVm.keys()) {
            this._concentrateService.get({href:concentrateHref}, this._subjectRootReference)
                .then( /** {Concentrate} */ concentrate => {
                    this._concentrateIdMap.set(concentrate.id, concentrate);
                    concentrate.substance = this._dataModel._panel._substances.get(concentrate.substance.id);

                    let vmVialArray = concentrateToVm.get(concentrateHref);
                    vmVialArray.forEach(vmVial =>
                        BaseBoardDetailsController._addConcentrateDmToVm(vmVial, concentrate));
                });
        }
    }

    /**
     * @protected
     *
     * Synchronous, zero-arg, void return type...
     */
    _initTraysVm() {
        this.$scope.vm.trays = [];
        for (let i = 0; i < this._dataModel.trayCount; i++) {
            this.$scope.vm.trays.push(i);
        }
    }

    /**
     * Update alerts on each TrayVial in the view according to the summary from InventoryAlertService.
     *
     * @param alertSummary see InventoryAlertService
     * @private
     */
    _updateTrayVialAlerts(alertSummary) {
        if (this.$scope.vm) {
            for (let vial of this.$scope.vm.trayVials) {
                if (!vial._alertDismissed) {
                    vial.alert = alertSummary.icons.get(vial._dmId);
                }
            }

            this.$scope.vm.boardAlert = alertSummary.icons.get(this._dataModel.id);
        }
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                         Notification Support
    //
    // Additional notification mechansims are impl'd in parent, see also:
    //     super.startAllSubscriptions,
    //     super.unsubscribeAllSubscriptions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @protected
     * @return {Promise.<{void}>}
     *
     * I've specified a 'void' Promise, which is to say we're NOT supplying any downstream value. No param means no
     * type to expect, hence a "void Promise".
     */
    _subscribeToBoardChangeNotifs() {

        let statusChangeSubscription = this.notificationService.subscribeBoardUpdates(
            this.$scope.practice, this._subjectReference);

        this.registerSubscription(statusChangeSubscription)
            .then(null, null, (notification) => this._onBoardChange(notification));
    }

    /**
     * @protected
     *
     * @param {Notification} notification
     * A notification indicative a change in this subject instance's state.
     *
     */
    _onBoardChange(notification) {

        let
            refreshedModel = /** @type {BoardDataModel} */ (notification.body),
            cachedPanelState = /** @type {PanelDataModel} */this._dataModel._panel;

        if (this._dataModel.version !== refreshedModel.version) {
            super.pauseAllSubscriptions();// The conjugate start is at the tail end of _renderVm .

            let boardPromise = this.showHistoricalVials ?
                this._boardService.getWithAllVials(refreshedModel) :
                this._boardService.getWithCurrentVials(refreshedModel);

            boardPromise.then(updatedBoardDm => {
                this._dataModel = updatedBoardDm;
                this._dataModel._panel = cachedPanelState;
                this._dataModel.vials = updatedBoardDm.vials;
                return this._renderVm();
            });
        }
        // No else? NEAUX!!! If the version of the DM is stable, then its value is stable, so there's nothing to do.
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                            Label Printing
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * Print.
     * Model must not dismiss until this function resolves.
     *
     * @param data {{boardName,barcode}}
     * @param labelAmount {int} how many board labels to print
     * @param showBoard {boolean} print board labels?
     * @param showVials {boolean} print vial labels?
     * @param vials {Array} vials to print
     * @return {Promise} that resolves when printing completes
     * @private
     */
    _printTrayLabels(data, labelAmount, showBoard, showVials, vials) {
        const labels = []
        let printerName = PRINTER_DYMO30336_PORTRAIT
        let boardAsPortrait = true
        if (showBoard === true && showVials !== true) {
            // allow mixing board labels to preview as native landscape
            // if we aren't mixing vial labels into the same print job
            printerName = PRINTER_DYMO30336_LANDSCAPE
            boardAsPortrait = false
        }
        if (showBoard === true) {
            for (var i = 0; i < labelAmount; i++) {
                let traylabel = <TrayLabel
                    key={`tray-${i}`}
                    barcode={data.barcode}
                    description={data.boardName}
                />
                labels.push(boardAsPortrait ? (
                    <LandscapeToPortrait key={`tray-${i}`}>
                        {traylabel}
                    </LandscapeToPortrait>
                ) : traylabel)
            }
        }
        if (showVials === true) {
            for (var j = 0; j < vials.length; j++) {
                labels.push(
                    <SingleDilutionLabel
                        key={`vial-${j}`}
                        barcode={vials[j].barcode}
                        description={vials[j].description}
                        dilution={vials[j].dilution}
                    />
                )
            }
        }
        submitPrintJob(printerName, labels)
    }

    /**
     * @param {TrayVialViewModel} trayVialVm
     * @private
     */
    _reprintVialLabel(trayVialVm) {
        let trayVial = this._dataModel.vials[trayVialVm._dmIndex];
        this._printVialLabel(trayVial);
    }

    /**
     * @param {TrayVialDataModel} replacementVial
     * @private
     */
    _printVialLabel(replacementVial) {
        submitPrintJob(
            PRINTER_DYMO30336_PORTRAIT,
            <SingleDilutionLabel
                barcode={replacementVial.barcode}
                description={replacementVial.substance._dto.name}
                dilution={replacementVial.dilution}
            />
        )
    }


}
