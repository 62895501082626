'use strict';
import angular from 'angular';
import moment from 'moment';
import ngRoute from 'angular-route';

import MixingExternalVialController from './mixing-external-vial.controller.js';

export default angular.module('pages.mixing.mixingExternalVial', [ngRoute])
    .config(config)
    .controller('MixingExternalVialController', MixingExternalVialController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_MIXING_EXTERNAL_VIAL + ':number', {
        template: require('./mixing-external-vial.html'),
        css: require('./mixing-external-vial.scss'),
        controller: 'MixingExternalVialController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
