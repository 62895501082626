// Import libraries here
import './common/style.css';
import './common/styles.scss';
import './common/print-styles.scss';

import 'jquery';
import 'jquery-ui';
import '@babel/polyfill';
import 'font-awesome/css/font-awesome.css';
import angular from 'angular';
import ngRoute from 'angular-route';
import bootstrap from 'bootstrap';
import smartTable from 'angular-smart-table';
import lrStickyHeader from 'lr-sticky-header';
import smartTableStickyHeader from 'smart-table-sticky-header';
import 'angular-utf8-base64';
import 'angular-uuid';
import angularBootstrap from 'angular-ui-bootstrap';
import ngIdle from 'ng-idle';
import 'ui-select';
import ngSanitize from "angular-sanitize";

// Import components here
import configuration from 'configuration';
import login from 'pages/login';
import password_recovery from "pages/reset-password";
import user_setup from "pages/user-setup";
import dashboard from "pages/new-dashboard";
import treatment from "pages/treatment";
import patient from "pages/patient";
import inventory from "pages/inventory";
import reports from "pages/reports";
import test_layouts from 'pages/test_layouts';
import common from "pages/common";

import selfCheckin from 'pages/kiosk/self-checkin';

import widgets_password from 'widgets/password';
import widgets_testing from 'widgets/testing';
import widgets_vitals from 'widgets/vitals';
import widgets_questionnaire from 'widgets/questionnaire';
import widgets_patientInfo from 'widgets/patient-info';
import widgets_timer from 'widgets/timer';
import widgets_not_for_production from 'widgets/common';
import widgets_filters from 'widgets/filters';
import widgets_smartTable from 'widgets/smart-table';
import widgets_freshDeskSupportTicket from "widgets/fd-support-ticket";

import widgets_testing_grids from 'widgets/testing/grids';
import widgets_mixing from 'pages/mixing/widgets';

import testing_initialize from 'pages/testing/initialize';
import testing_questionnaire from 'pages/testing/questionnaire';
import testing_vitals from 'pages/testing/vitals';
import testing_sptCheckList from 'pages/testing/spt-checklist';
import testing_idtHistamine from 'pages/testing/idt-histamine';
import testing_measureSptResult from 'pages/testing/measure-spt-result';
import testing_idtDetails from 'pages/testing/intradermal-details';
import testing_prepareIntradermal from 'pages/testing/prepare-intradermal';
import testing_idtCheckList from 'pages/testing/idt-checklist';
import testing_measureIntradermal from 'pages/testing/measure-intradermal';
import testing_postVitals from 'pages/testing/post-vitals';
import testing_testingResults from 'pages/testing/testing-results';

import mixing_initialize from 'pages/mixing/common/initialize';
import mixing_reviewOrder from 'pages/mixing/common/review-order';
import internalMixing_checklist from 'pages/mixing/internal/checklist';
import internalMixing_prescription_details from 'pages/mixing/internal/prescription-details';
import internalMixing_prepare_vials from 'pages/mixing/internal/prepare-vials';
import internalMixing_mixingVial from 'pages/mixing/internal/mixing-vial';
import externalMixing_prescription_details from 'pages/mixing/external/prescription-details';
import externalMixing_mixingVial from 'pages/mixing/external/mixing-external-vial';

import settings from 'pages/settings';

import services from 'services';

import models from "models";

import './react/app'

angular
    .module('app', [
        ngRoute,
        ngIdle,
        smartTable,
        'utf8-base64',
        'angular-uuid',
        configuration,
        login,
        password_recovery,
        user_setup,
        dashboard,
        treatment,
        patient,
        common,
        inventory,
        reports,
        angularBootstrap,
        'ui.select',
        ngSanitize,
        //
        widgets_password,
        widgets_testing,
        widgets_vitals,
        widgets_questionnaire,
        widgets_patientInfo,
        widgets_testing_grids,
        widgets_mixing,
        widgets_timer,
        widgets_not_for_production,
        widgets_filters,
        widgets_smartTable,
        widgets_freshDeskSupportTicket,
        //
        test_layouts, // REMOVE ME WHEN GOING TO PROD
        //
        testing_initialize,
        testing_questionnaire,
        testing_vitals,
        testing_sptCheckList,
        testing_idtHistamine,
        testing_measureSptResult,
        testing_idtDetails,
        testing_prepareIntradermal,
        testing_idtCheckList,
        testing_measureIntradermal,
        testing_postVitals,
        testing_testingResults,
        //
        mixing_initialize,
        mixing_reviewOrder,
        internalMixing_checklist,
        internalMixing_prescription_details,
        internalMixing_prepare_vials,
        internalMixing_mixingVial,
        externalMixing_mixingVial,
        externalMixing_prescription_details,
        //
        selfCheckin,
        //
        settings,
        //
        services,
        //
        models
    ]).filter('trustIt', function($sce) { return $sce.trustAsHtml })

console.log("allergy-main: Hello, world!");
