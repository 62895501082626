'use strict';

import angular from 'angular';

export default angular.module('widgets.timer', [])
    .directive('agTimer', ["$interval",'serverAPI', agTimer])
    .directive('agBigTimer', [agBigTimer])
    .name;

function agTimer($interval, serverAPI) {
    return {
        windowClass: require('./timer.scss'),
        template: require('./timer.html'),
        scope: {
            /** {PatientTimer} input object */
            timer: '=',
            /** {boolean} Output value. Set to true when timer has expired. */
            expiredTimer: '=?',
            /** {boolean} Set to true when the timer should play a notification sound upon expiring. */
            repeatExpireChime: '='
        },
        link: function (scope, element) {

            let zeropad = (number) => {
                if (number < 10) {
                    return '0' + number;
                } else {
                    return number.toString();
                }
            };

            let updateUI = () => {
                let elapsedSec = Math.floor((Date.now() + scope.clockOffset - scope.startTime)/1000);
                if (elapsedSec < 0)
                    elapsedSec = 0;

                scope.second = zeropad(elapsedSec % 60);
                scope.minute = zeropad(Math.floor(elapsedSec / 60));

                scope.isExpired = (Date.now() + scope.clockOffset) >= scope.expireTime;
                if (angular.isDefined(scope.expiredTimer))
                    scope.expiredTimer = scope.isExpired;

                if(scope.isExpired) {
                    let elapsedSinceExpire = Math.floor((Date.now() + scope.clockOffset - scope.expireTime)/1000);

                    // Always play chime once upon expiring
                    if(elapsedSinceExpire === 0)
                        timeExpiredPlayer.play();

                    // Repeat chime every 5 seconds if repeatExpireChime is set to true
                    if(scope.repeatExpireChime && elapsedSinceExpire > 0 && elapsedSinceExpire % 5 === 0)
                        timeExpiredPlayer.play();
                }
            };

            var timerPromise = null;
            var timeExpiredPlayer = new Audio(require('../../common/sounds/timer-chime.mp3'));

            scope.$watch('timer', () => {
                if (scope.timer) {
                    //console.log(scope.timer);
                    scope.startTime = new Date(scope.timer.startTime + 'Z').getTime();
                    scope.expireTime = new Date(scope.timer.expireTime + 'Z').getTime();

                    serverAPI.getGlobalLinks().then(global => {
                        scope.clockOffset = global.clockOffset;
                        console.log("Timer start=" + (scope.startTime % 10000000) + " expire=" + (scope.expireTime % 10000000) + " now=" + (Date.now() % 10000000) + " + " + scope.clockOffset);
                        updateUI();

                        if (!timerPromise) {
                            timerPromise = $interval(function () {
                                updateUI();
                            }, 1000);
                        }
                    });
                }
            });

            scope.$on('$destroy', function () {
                if (timerPromise) {
                    $interval.cancel(timerPromise);
                }
            });
        }
    }
}

function agBigTimer() {
    return {
        windowClass: require('./timer.scss'),
        template: require('./big-timer.html'),
        scope: {
            /** {PatientTimer} input object */
            timer: '=',
            /** {boolean} Output value. Set to true when timer has expired. */
            expiredTimer: '='
        }
    }
}
