'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentTimerController from './treatment-timer.controller.js';

export default angular.module('pages.treatment.timer', [ngRoute])
    .config(config)
    .controller('TreatmentTimerController', TreatmentTimerController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_IDT_TIMER, {
        template: require('./timer.html'),
        css: require('./timer.scss'),
        controller: 'TreatmentTimerController'
    });
    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_REACTION_TIMER, {
        template: require('./timer.html'),
        css: require('./timer.scss'),
        controller: 'TreatmentTimerController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
