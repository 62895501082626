'use strict';

import BaseService from './base.service.js';

export default class ProcedureCodeService extends BaseService {    
    /**
     * Get all defined Procedure Codes. (These are defined globally.)
     *
     * @return Promise to ProcedureCode.List (unordered)
     */
    getAll() {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.get(globalLinks.procedureCodes));
    }
}
