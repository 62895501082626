'use strict';

import angular from 'angular';

export default class ClearInventoryAlertController {

    static $inject = ['$uibModalInstance','$scope','inventoryItem'];
    constructor($uibModalInstance, $scope, inventoryItem) {
        $scope.prompt = `${inventoryItem.name} is in ${inventoryItem.serviceStatus.toLowerCase()} status. Are you sure you would like to dismiss this alert?`;

        $scope.cancel = () => $uibModalInstance.dismiss();
        $scope.ok = () => $uibModalInstance.close();
    }
}
