'use strict';

import BaseService from './base.service.js';

/**
 * Service for managing PatientNotes.
 */
export default class PatientNoteService extends BaseService {

    /**
     * GET all notes for a Patient.
     *
     * @param patient to get notes for
     * @return Promise to a Patient.List DTO in priority order
     */
    getForPatient(patient) {
        return this.serverAPI.get(patient._links.notes);
    }

    /**
     * Create a Patient Note.
     *
     * @param patient Patient to add note to
     * @param patient {PatientNote} content for new PatientNote
     * @return Promise to created PatientNote DTO
     */
    create(patient, patientNote) {
        return this.serverAPI.post(patient._links.notes, {}, patientNote);
    }
}
