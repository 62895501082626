'use strict';

import BaseMixingController from '../../base-mixing.controller'

/**
 * Simple controller that examines the Prescriptions and routes to the actual correct tab of the Mixing Wizard.
 */
export default class MixingInitializeController extends BaseMixingController {

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        this.prescriptionLoaded(null)
            .then(() => this.autoRoute())
            .catch(ex => console.error(ex));
    }
}
