import angular from 'angular';
import ngRoute from 'angular-route';

import TestMeasureSptResultController from './testing-measure-spt.controller';
import TestMeasureSptTimerController from './testing-measure-spt-timer.controller';

export default angular.module('pages.testing.measureSptResult', [ngRoute])
    .config(config)
    .controller('TestMeasureSptResultController', TestMeasureSptResultController)
    .controller('TestMeasureSptTimerController', TestMeasureSptTimerController)
    .directive('modal', ['$timeout', modal])
    .directive('ngEnter', [ngEnter])
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/measureSptResult', {
            template: require('./testing-measure-spt.html'),
            css: require('./testing-measure-spt.scss'),
            controller: 'TestMeasureSptResultController'
        })
        .when('/testing/measureSptResult/timer', {
            template: require('./testing-measure-spt-timer.html'),
            css: require('./testing-measure-spt-timer.scss'),
            controller: 'TestMeasureSptTimerController'
        });
}

config.$inject = ['$routeProvider'];

function ngEnter(){
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.ngEnter);
                });
                event.preventDefault();
            }
        });
    };
}

function modal($timeout) {
    return {
        template: '<div class="modal fade">' +
            '<div class="modal-dialog">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<h4 class="modal-title">{{ title }}</h4>' +
            '</div>' +
            '<div class="modal-body wheal-testing-size" ng-transclude></div>' +
            '<div class="modal-footer">' +
            '<div class="button-left">' +
            '<button class="btn btn-primary" id="cancel" ng-click="closeModal()" data-dismiss="modal">Cancel</button>' +
            '</div>' +
            '<div class="button-right">' +
            '<button class="btn btn-primary" ng-click="setInput()" id="done">Done</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>',
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: false,
        link: function postLink(scope, element, attrs) {
            scope.title = attrs.title;

            scope.$watch(attrs.visible, function (value) {
                if (value == true)
                    $(element).modal('show');
                else
                    $(element).modal('hide');
            });

            scope.setInput = function () {
                var inputValue = $(element).find("#wheal-size").val();
                if (inputValue !== undefined && inputValue !== null && inputValue !== '') {
                    scope.selectWheal(inputValue);

                    if(scope.treatmentIDT){
                        scope.injection.idtWhealSize = Number(inputValue);
                    } else if (scope.treatmentExamination) {
                        scope.injection.reactionWhealSize = Number(inputValue);
                    }
                }

                $(element).modal('hide');
                $(element).find("#wheal-size").val('');
            };

            scope.closeModal = function () {
                $(element).find("#wheal-size").val('');
                scope.$watch(attrs.tabindex, function () {
                    scope.nextTabIndex(scope.tabindex);
                });
            };

            $(element).on('shown.bs.modal', function () {
                scope.$apply(function () {
                    scope.$parent[attrs.visible] = true;

                    $(element).find('#wheal-size').focus();

                });
            });

            $(element).on('hidden.bs.modal', function () {
                scope.$apply(function () {
                    scope.$parent[attrs.visible] = false;
                    scope.showModal = false;
                });
            });
        }
    };
}
