"use strict";

import angular from 'angular';
export default angular.module("pages.inventory.models.InventorySections", [])
    .constant("InventorySections", {
        CONCENTRATES : "CONCENTRATES",
        WELLS : "WELLS",
        TESTING : "TESTING",
        MIXING : "MIXING",
        VIALS : "VIALS"
    })
    .name;

