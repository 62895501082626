'use strict';

import BaseController from '../../base.controller.js';

/**
 * Controller for the Appointment Details model. See wireframe screen 404.
 */
export default class AppointmentDetailsController extends BaseController {
    constructor($scope, $injector, $uibModalInstance, patient) {
        super($scope, $injector);

        /*
         * Initialize fields and scope methods
         */
        this.$scope = $scope;
        this.$scope.patient = patient;
        this.$scope.lungBaseline = patient.lungBaseline || '';
        this.$scope.diagnosisCode = '';
        this.$scope.diagnosisCodes = [];
        this.$scope.isPanelRequired = true;
        this.$scope.provider = undefined;

        this.$uibModalInstance = $uibModalInstance;
        this.appointmentService = $injector.get('appointmentService');
        this.billService = $injector.get('billService');
        this.insurancePolicyService = $injector.get('insurancePolicyService');
        this.patientService = $injector.get('patientService');
        this.diagnosisCodeService = $injector.get('diagnosisCodeService');
        this.Duration = require('js-joda').Duration;
        this.$uibModal = $injector.get('$uibModal');

        // Subscribe to patient updates
        this.registerSubscription(this.notificationService.subscribePatientUpdates(this.$scope.practice, this.$scope.patient))
            .then(null, null, (notification) => {
                this.$scope.patient = notification.body;
                this._getInsurance();
            });
        this.startAllSubscriptions();

        /** Cancel button clicked */
        $scope.cancel = () => {
            // If cancelling dialog on appointment that was just created, delete it.
            if (this.apt && this.apt.createdDateTime === this.apt.modifiedDateTime && this.apt.procedure !== 'IDT')
                this.appointmentService.delete(this.apt);

            $uibModalInstance.close(null);
        };

        /** Save & Close button clicked */
        $scope.save = () => {
            this.updateServer().then((apt) => {
                $uibModalInstance.close(apt); // Return appointment created / updated from the server to the caller.
            });
        };

        /** Check In button clicked */
        $scope.checkin = () => {
            if (this.$scope.patient.active != false) {
                this.updateServer().then(apt => {
                    this.patientService.checkinByAppointment(apt).then((apt) => {
                        $uibModalInstance.close(apt); // Return appointment created / updated from the server to the caller.
                    })
                });
            } else {
                // Shouldn't be possible to get here. Fall back to ugly
                alert("The patient cannot be checked in because the patient is 'Inactive'.");
            }
        };

        $scope.goToPatientDetails = () => {
            $uibModalInstance.close('cancel');
            this.routeToPage(this.urlPaths.PATIENT_DETAILS, /** PatientDTO */this.$scope.patient);
        };

        /** Appointment type selection **/
        $scope.setAppointmentType = (appointmentType) => {
            /* Ensure special IDT appointment type remains unchanged */
            if (appointmentType === 'TESTING' && this.apt.procedure === 'IDT') {
                this.$scope.appointmentType = this.apt.procedure;
            }
            else {
                this.$scope.appointmentType = appointmentType;
            }
        };

        /** Test type dropdown selection **/
        $scope.selectTestType = (config) => {
            $scope.testConfigName = config.name;
            $scope.testConfigId = config.id;
            $scope.isPanelRequired = config.spt && config.spt.length;
        };

        /** Panel dropdown selection **/
        $scope.selectPanel = (name, value) => {
            $scope.panelName = name;
            $scope.panelId = value;
        };

        /** Provider dropdown selection **/
        $scope.selectProvider = (newProvider) => {
            $scope.provider = newProvider;
        };

        /** Office dropdown selection **/
        $scope.selectOffice = (name, value) => {
            $scope.officeName = name;
            $scope.officeId = value;
        };

        /** Get all diagnosis codes which are not already on the bill object of the appointment **/
        $scope.getDiagnosisCodes = () => {
            let result = [];
            for(let diagnosisCode of $scope.diagnosisCodes) {
                if ($scope.bill.diagnosisCodes.findIndex(m => m.code === diagnosisCode.code) === -1) {
                    result.push(diagnosisCode.code);
                }
            }
            return result;
        };

        /** Add diagnosis code to the appointment **/
        $scope.addDiagnosisCode = (item) => {
            $scope.bill._dirty = true;
            $scope.bill.diagnosisCodes.push({ code: item });
            $scope.diagnosisCode = '';
        };

        /** Remove diagnosis code from the appointment **/
        $scope.removeDiagnosisCode = (i) => {
            $scope.bill._dirty = true;
            $scope.bill.diagnosisCodes.splice(i, 1);
        };

        /** Flag that a bill content has changed */
        $scope.billChanged = () => $scope.bill._dirty = true;

        /**
          * Check if the Medicare checkbox can be set. If the Medicare checkbox cannot be set,
          * display a warning message
          */
        $scope.validateMedicare = () => {
            if (!this.insurancePolicies || this.insurancePolicies.length === 0) {
                this.$scope.isMedicare = false;
                this.$scope.medicareWarning = true;
            }
        };

        /** All asynchronous data loading has completed */
        $scope.isInitialized = () => {
            return this.$scope.testConfigs && this.$scope.testConfigs.length &&  this.$scope.panels && this.$scope.panels.length && this.$scope.providers && this.apt;
        };

        $scope.mayCheckin = () => {
            if (!this.$scope.isInitialized() ||
                !this.$scope.appointmentType ||
                !this.$scope.provider ||
                this.$scope.appointmentDetails.$invalid)
                return false;
            if (this.$scope.appointmentType === "TREATMENT" || this.$scope.appointmentType === "IDT") {
                return true;
            } else {
                return this.$scope.testConfigId && (!this.$scope.isPanelRequired || this.$scope.panelId);
            }
        };


        /*
         * Load data from server
         */
        if (this.$scope.patient.active !== false && !this.$scope.patient.activeVisit) {
            // Load AllergyTestConfigs
            this.$scope.testConfigs = [];
            let testConfigsAsync = $injector.get('allergyTestConfigService').getAtPractice(this.$scope.practice).then(configs => {
                configs.list.forEach(config => {
                    if (config.active) {
                        this.$scope.testConfigs.push(config);
                    }
                });
            });

            // Load Panels
            this.$scope.panels = [];
            let panelsAsync = $injector.get('panelService').getActiveAtPractice(this.$scope.practice).then(panels => {
                this.$scope.panels = (panels.list || []).filter(m => m.type === 'TESTING');
            });

            // Load Providers
            this.$scope.providers = [];
            let providersAsync = $injector.get('userService').getUsers(this.$scope.practice, 'DOCTOR').then(providers => {
                providers.list.forEach(provider => {
                    let person = provider.person;
                    this.$scope.providers.push({
                        id: provider.id,
                        name: person.givenName + ' ' + person.familyName
                    });
                });
            });

            // Load next appointment
            let appointmentAsync = this.appointmentService.getNextForPatient(this.$scope.patient).then(apt => {
                this.apt = apt;
                this.$scope.appointmentType = apt.procedure;
                this.$scope.testConfigId = apt.allergyTestConfig ? apt.allergyTestConfig.id : '';
                this.$scope.panelId = apt.panel ? apt.panel.id : '';
                if(apt.provider) {
                    this.$scope.provider = this.$scope.providers.find((provider) => {
                        if(provider.id === apt.provider.id){
                            return provider;
                        }
                    });
                }
                this.$scope.officeId = apt.office.id;
                this.$scope.bill = {};

                return this.billService.get(apt.bill).then(bill => {
                    this.$scope.bill = bill;
                });
            });

            // Load available offices
            let officeAsync = this.officeService.getInPractice(this.$scope.practice).then(officeList => {
                this.$scope.offices = officeList.list;
            });

            let insuranceAsync = null;
            if (this.$scope.patient.active) {
                insuranceAsync = Promise.resolve(true);
            }
            else {
                insuranceAsync = this._getInsurance();
            }

            // Load diagnosis codes
            let diagnosisCodesAsync = this.diagnosisCodeService.getAll().then(diagnosisCodeList => {
                this.$scope.diagnosisCodes = diagnosisCodeList.list;
            });

            // Finish up when everything has loaded
            Promise.all([testConfigsAsync, panelsAsync, providersAsync, appointmentAsync, insuranceAsync, diagnosisCodesAsync]).then(() => {
                // Auto-select only panel, test config, or provider.
                if (this.$scope.testConfigId === '' && this.$scope.testConfigs.length === 1) {
                    this.$scope.testConfigId = this.$scope.testConfigs[0].id;
                }

                if (this.$scope.panelId === '' && this.$scope.panels.length === 1) {
                    this.$scope.panelId = this.$scope.panels[0].id;
                }

                if (this.$scope.provider === undefined && this.$scope.providers.length === 1) {
                    this.$scope.provider = this.$scope.providers[0];
                }

                // Set the names of selected items
                this.$scope.testConfigName = this._getNameById(this.$scope.testConfigs, this.$scope.testConfigId);
                this.$scope.panelName = this._getNameById(this.$scope.panels, this.$scope.panelId);
                this.$scope.officeName = this._getNameById(this.$scope.offices, this.$scope.officeId);

                this.$scope.$digest();
            });
        }
    }

    _getInsurance() {
        return this.insurancePolicyService.getForPatient(this.$scope.patient).then(policies => {
            if (policies.list.length) {
                this.insurancePolicies = policies;
            }
            this.$scope.isMedicare = this.insurancePolicyService.isMedicare(policies);
        });
    }

    /**
     * Save user selections to the server.
     *
     * @returns Promise to new Appointment that completes when save is complete.
     */
    async updateServer() {
        this.apt.procedure = this.$scope.appointmentType;
        let isTest = (this.$scope.appointmentType === 'TESTING' || this.$scope.appointmentType === 'IDT');

        if (isTest && this.$scope.testConfigId)
            this.apt.allergyTestConfig = {
                id: this.$scope.testConfigId
            };
        else
            this.apt.allergyTestConfig = null;

        if (isTest && this.$scope.panelId)
            this.apt.panel = {
                id: this.$scope.panelId
            };
        else
            this.apt.panel = null;

        if (this.$scope.provider)
            this.apt.provider = this.$scope.provider;
        else
            this.apt.provider = null;

        if (this.$scope.officeId)
            this.apt.office.id = this.$scope.officeId;

        // Patient lungBaseline
        if (this.$scope.lungBaseline && this.$scope.patient.lungBaseline != this.$scope.lungBaseline) {
            this.$scope.patient.lungBaseline = this.$scope.lungBaseline;
            this.$scope.patient = await this.patientService.update(this.$scope.patient);
        }

        // InsurancePolicy Medicare flag
        if (this.insurancePolicies && this.$scope.isMedicare != this.insurancePolicyService.isMedicare(this.insurancePolicies)) {
            await this.insurancePolicyService.setMedicareFlag(this.insurancePolicies, this.$scope.isMedicare);
        }

        if (this.$scope.bill._dirty) {
            this.$scope.bill = await this.billService.update(this.$scope.bill);
        }

        this.apt = await this.appointmentService.update(this.apt);
        return this.apt;
    }

    /**
     * Return the name property of an object in the array that have the given id.
     */
    _getNameById(array, id) {
        for (let item of array) {
            if (item.id === id)
                return item.name;
        }

        return '';
    }
}
