"use strict";

import angular from "angular";
import CorpLogoController from "./controller";

export default angular.module("pages.common.standard-layout.corp-logo", [])
    .controller("CorpLogoController", CorpLogoController)
	.directive("agAppCorpLogo", initView )
	.name;

function initView() {
	return {
        controller: 'CorpLogoController',
        scope: {},
        restrict: "E",
        css: require("./styles.scss"),
        template: require("./layout.html")
    };
}
