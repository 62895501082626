"use strict";

import angular from 'angular';
import controller from "./controller";

export default angular.module('pages.dashboard.sections.billing', [])
    .controller('BillingSectionController', controller)
    .directive('checkboxFilter', ['$timeout', checkboxFilter])
    .directive('agFocusId', ['$timeout', agFocusId])
    .name;

function checkboxFilter($timeout) {
    return {
        scope: {
            id: '@checkboxId',
            ngModel: '=',
            ngChange: '&',
            ngDisabled: '=',
            label: '@'
        },
        template: require('./widgets/checkbox-filter.html'),
        css: require('./widgets/checkbox-filter.scss'),
        link: function(scope, element, attrs) {
            // Delay, or else parent ngChange will be called before parent ngModel is updated.
            scope.onChange = () => $timeout(scope.ngChange);
        }
    };
}

/**
 * Use as attribute directive: ag-focus-id="id-of-other-element'.
 * When the element with this attribute is clicked, focus will be set on the element with the given id.
 */
function agFocusId($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('click', () => {
                $timeout(() => $('#' + attrs.agFocusId).focus());
            });
        }
    };
}