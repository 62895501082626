'use strict';

import BaseService from './base.service.js';

/**
 * Service for managing PatientAlerts.
 */
export default class PatientAlertService extends BaseService {

    static EVENT_NAME = "PatientAlertService_Alert";
    static NUM_DISMISSALS_STORED = 100;

    dismissedAlerts = new DismissedAlertsQueue(this.NUM_DISMISSALS_STORED);

    /** Practice/Patient that currently cached alerts are for */
    practice = null;
    patient = null;

    patientAlertCache = new Map();
    
    /** Promise received from NotificationService.subscribePatientAlerts */
    notificationSubscription = null;

    static $inject = ['serverAPI', 'notificationService', '$rootScope'];
    constructor(serverAPI, notificationService, $rootScope) {
        super(serverAPI);

        this.notificationService = notificationService;
        this.$rootScope = $rootScope;
    }

    /**
     * GET all alerts for a Patient.
     *
     * @param patient to get alerts for
     * @return Promise to a Patient.List DTO in priority order
     */
    getForPatient(patient) {
        return this.serverAPI.get(patient._links.alerts);
    }

    /**
     * Create a Patient Alert.
     *
     * @param patient Patient to add alert to
     * @param patient {PatientAlert} content for new PatientAlert
     * @return Promise to created PatientAlert DTO
     */
    create(patient, patientAlert) {
        return this.serverAPI.post(patient._links.alerts, {}, patientAlert);
    }

    isAlertDismissed(patientAlert, location) {
        return this.dismissedAlerts.get(patientAlert.id, location);
    }

    dismissAlert(patientAlert, location) {
        this.dismissedAlerts.set(patientAlert.id, location);
    }

    clearDismissedAlerts() {
        this.dismissedAlerts = new DismissedAlertsQueue(this.NUM_DISMISSALS_STORED);
    }

    /**
     * Subscribe to receive patient alert updates, and get the list of patient alerts.
     * Callback is quickly called once with the current list of patient alerts, and then each time the list changes.
     * Subscription automatically ends when scope is destroyed.
     *
     * @param practice practice
     * @param patient patient to get alerts for
     * @param scope caller's local $scope
     * @param subscriptionCallbackFunction callback function that receives this.patientAlertCache array as single parameter
     * @return unsubscribe function
     */
    subscribe(practice, patient, scope, subscriptionCallbackFunction) {
        this.select(practice, patient);

        // Add listener to scope
        let unsubscribe = scope.$on(PatientAlertService.EVENT_NAME, (event, data) => subscriptionCallbackFunction(data));

        // Send summary to this scope on next processing cycle (don't wait for next change)
        this.sendTo(scope);

        return unsubscribe;
    }

    _handleNotification(notification) {
        let patientAlert = notification.body;
        // update information in cache
        this.patientAlertCache.set(patientAlert.id, patientAlert);
        // If there was an update to the alert, we don't want it to be considered dismissed any more.
        this.dismissedAlerts.delete(patientAlert.id);

        this._broadcast();
    }

    /**
     * Select this Practice, Patient as the current ones being looked at by the User.
     * Retrieves alerts for the patient and subscribes to receive updates from the server.
     *
     * @param practice new practice. Set to null to stop processing patient alerts (such as at logout);
     * @param patient new Patient. Set to null to stop processing patient alerts (such as at logout).
     */
    select(practice, patient) {
        if ((practice === null || this.practice === null || practice.id !== this.practice.id) ||
            (patient === null || this.patient === null || patient.id !== this.patient.id)) {
            this.practice = practice;
            this.patient = patient;
            this.patientAlertCache.clear();

            // Unsubscribe from old patient
            if (this.notificationSubscription) {
                this.notificationSubscription.unsubscribe();
                this.notificationSubscription = null;
            }

            // If new patient actually selected (not null), then fetch and subscribe.
            if (practice && patient) {
                this.notificationService.init()
                    .then(() => {
                        // Subscribe and start right away.
                        this.notificationSubscription = this.notificationService.subscribePatientAlerts(practice, patient);
                        this.notificationSubscription.then(null, null, (notification) => this._handleNotification(notification));
                        this.notificationSubscription.start();

                        // Initialize state with poll (Could receive notice before this completes, but duplicates are harmless.)
                        return this._getForCurrentPatient();
                    })
                    .then(patientAlertsList => {
                        this._broadcast();
                    });
            }
        }
    }

    /**
     * Request that the given scope be sent the current alert summary, soon.
     * @param scope
     */
    sendTo(scope) {
        scope.$broadcast(PatientAlertService.EVENT_NAME, Array.from(this.patientAlertCache.values()));
    }

    /**
     * Fetch all alerts for the current patient.
     * Used to initialize upon subscribing to notifications. Once subscribed, updates are received
     * via notifications.
     *
     * @return Promise to resulting PatientAlert.List DTO
     */
    _getForCurrentPatient() {
        return this.serverAPI.get(this.patient._links.alerts).then((patientAlerts) => {
            for (let i = 0; i < patientAlerts.list.length; i++) {
                let patientAlert = patientAlerts.list[i];
                this.patientAlertCache.set(patientAlert.id, patientAlert);
            }
            return patientAlerts;
        });
    }

    _broadcast() {        
        this.$rootScope.$broadcast(PatientAlertService.EVENT_NAME, Array.from(this.patientAlertCache.values()));
    }

    /** Start with a clean slate */
    cleanSession() {
        this.select(null, null);
        this.clearDismissedAlerts();
    }
}

class DismissedAlertsQueue {
    constructor(max = 100) {
        this.max = max;
        this.cache = new Map();
    }

    get(key, location) {
        let val = this.cache.get(key);
        return val && val.indexOf(location) !== -1;
    }

    //val is the location
    set(key, val) {
        let locations = this.cache.get(key) || [];
        if (locations.length > 0) {
            this.cache.delete(key);
        }
        else if (this.cache.size == this.max) {
            this.cache.delete(this._first());   
        }
        
        if (locations.indexOf(val) === -1) {
            locations.push(val);
        }

        this.cache.set(key, locations);
    }

    delete(key) {
        this.cache.delete(key);
    }

    _first() {
        return this.cache.keys().next().value;
    }
}
