"use strict";

/**
 * A board arrangement.
 */
export default angular.module("models.boardArrangement", [])
    .constant("BoardArrangement", {
        STANDARD : "STANDARD",
        OTOLARYNGIC : "OTOLARYNGIC",
        TRADITIONAL : "TRADITIONAL"
    }).name;
