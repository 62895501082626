"use strict";

import angular from 'angular';
import controller from "./controller";

export default angular.module('pages.dashboard.sections.no-treatment', [])
    .controller('NoTreatmentSectionController', controller)
    .directive('stNoTreatment', [stNoTreatment])
    .name;

function stNoTreatment(){
    return {
        scope: {
            rowCollection: '=',
            patientPickHandler : '&',
            testPickHandler : '&',
            showFilter: "=",
            loading: "="
        },
        template: require('./widgets/st-no-treatment-list.html'),
        css: require('./widgets/st-no-treatment-list.scss'),
        link: function ($scope, element, attrs) {
            $scope.onPatientClick =(row)=> $scope.patientPickHandler({argRow: row});
            $scope.onTestClick =(row)=> $scope.testPickHandler({argRow: row});
            $scope.printDate = new Date();
        }

    }
}
