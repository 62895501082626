'use strict';

import BaseService from './base.service.js';

export default class ChecklistService extends BaseService {

    /**
     * Get the Skin-Prick Test Checklist for the user's practice
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForSPT(practice) {
        return this.serverAPI.get(practice._links.checklistSPT, {});
    }

    /**
     * Get the Intradermal Test Checklist for the user's practice.
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForIDT(practice) {
        return this.serverAPI.get(practice._links.checklistIDT, {});
    }

    /**
     * Get the Intradermal Histamine Test Checklist for the user's practice.
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForIDTHistamine(practice) {
        return this.serverAPI.get(practice._links.checklistIDTHistamine, {});
    }

    /**
     * Get the Prepare Intradermal Test Checklist for the user's practice.
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForIDTPrepare(practice) {
        return this.serverAPI.get(practice._links.checklistIDTPrepare, {});
    }

    /**
     * Get the Mixing Checklist for the user's practice.
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForMixing(practice) {
        return this.serverAPI.get(practice._links.checklistMixing, {});
    }

    /**
     * Get the SCIT Treatment Checklist for the user's practice.
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForScitTreatment(practice) {
        return this.serverAPI.get(practice._links.checklistTreatmentSCIT, {});
    }

    /**
     * Get the SLIT Treatment Checklist for the user's practice.
     *
     * @param practice DTO
     * @return Promise to a Checklist DTO
     */
    getForSlitTreatment(practice) {
        return this.serverAPI.get(practice._links.checklistTreatmentSLIT, {});
    }
}
