import React from 'react'
import styled from '@emotion/styled'
import { DYMO30336_WIDTH_IN, DYMO30336_LENGTH_IN } from './Dymo30336'
import LabelBarcode from './LabelBarcode'
import { Line, DottedInstructions } from './LabelFeatures'

export const PRINTER_DYMO30336_PORTRAIT = 'dymo30336-portrait'

export const PortraitSizedDiv = styled.div({
	width: `${DYMO30336_WIDTH_IN - .125}in`, /* - 1/16in print margin each side */
	maxWidth: `${DYMO30336_WIDTH_IN - .125}in`,
	height: `${DYMO30336_LENGTH_IN - .125}in`, /* - 1/16in print margin each side */
	maxHeight: `${DYMO30336_LENGTH_IN - .125}in`,
})

const PortraitLabel = styled(PortraitSizedDiv)({
	pageBreakAfter: 'always',
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	gap: '.5em',
})

export const ConcentrateLabel = React.memo(props => {
	const {
		barcode,
		description,
		manufacturer,
		lot,
		volume,
		formulation,
		ubd,
		dilution,
	} = props.concentrate
	return (
		<PortraitLabel>
			<LabelBarcode value={barcode} />
			<div />
			<Line bold>{description}</Line>
			<Line bold>{manufacturer}</Line>
			<Line>Lot #: {lot}</Line>
			<Line bold>{volume}</Line>
			<Line bold>{formulation}</Line>
			{dilution ? <Line bold>{dilution}</Line> : null}
			<Line size="7pt">UBD: <b>{ubd}</b></Line>
		</PortraitLabel>
	)
})

export const SingleDilutionLabel = React.memo(props => {
	const {
		barcode,
		description,
		dilution,
	} = props
	return (
		<PortraitLabel>
			<LabelBarcode value={barcode} />
			<div />
			<Line bold>{description}</Line>
			{dilution ? <Line>Dilution: <b>{dilution}</b></Line> : null}
		</PortraitLabel>
	)
})

export const PatientVialLabel = React.memo(props => {
	const {
		isScit,
		barcode,
		lastName,
		firstName,
		dob,
		vialName,
		volume,
		doses,
		ubd,
		bud,
		instructions,
		compounded,
	} = props.vial

	const Spacer = () => <div style={{height: '.5em'}} />

	return (
		<PortraitLabel style={{gap: '0'}}>
			<LabelBarcode value={barcode} />
			<Spacer />
			<Line bold>{lastName}</Line>
			<Line bold>{firstName}</Line>
			<Spacer />
			<Spacer />
			<Line size="7pt">DOB: <b>{dob}</b></Line>
			<Line bold>{vialName}</Line>
			<Line size="7pt">VOL: <b>{volume}</b></Line>
			{isScit ? <Line size="7pt">DOSES: <b>{doses}</b></Line> : null}
			<Line size="7pt">UBD: <b>{ubd}</b></Line>
			<Line size="7pt">BUD: <b>{bud}</b></Line>
			<Spacer />
			{isScit ? <Line bold>Store @ 2-8&deg;C</Line> : <DottedInstructions>{instructions}</DottedInstructions>}
			<Spacer />
			<Line size="5pt">Compounded {compounded} RX</Line>
		</PortraitLabel>
	)
})
