'use strict';

import moment from 'moment';

import manufacturers from '../../../../models/manufacturers';

import BaseMixingController from '../../base-mixing.controller'

export default class MixingExternalVialController extends BaseMixingController {

    /** All concentrates in-service at the office */
    inServiceExternalConcentrates;

    /** Map of substanceId to array of Concentrates that may be used for it. i.e. Map<string,Concentrate[]> */
    substanceConcentrates = new Map();

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.externalConcentrateService = $injector.get('externalConcentrateService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.globalConfigService = $injector.get('globalConfigService');
        this.chronologyMappingService = $injector.get("chronologyMappingService");

        $scope.text = "Loading ...";
        $scope.note = "";
        $scope.allowAutoBarcode = false;
        $scope.isReadyToMix = () => this._isReadyToMix();
        $scope.showTraditionalColor = (substance) => this._showTraditionalColor(substance);
        $scope.advanceToNextSubstance = () => this._advanceToNextSubstance();
        $scope.useByDateChanged = (substance) => this._useByDateChanged(substance);
        $scope.substanceInputBlur = (substance, focusInputId) => this._substanceInputBlur(substance, focusInputId);
        $scope.externalConcentrateIdentifierChanged = (substance, focusInputId) => this._externalConcentrateIdentifierChanged(substance, focusInputId);
        $scope.substanceInputCompleted = (substance) => this._substanceInputCompleted(substance);

        // Date Picker Options
        $scope.dp = {
            opened: false,
            format: "MM/dd/yyyy",
            minDate: new Date(),
            showWeeks: false,
            datepickerMode: 'day',
            minMode: 'day'
        };

        $scope.manufacturers = manufacturers;
        this._setFormulationUnits();
        this._loadGlobalConfig();

        this.prescriptionLoaded([this.TAB_VIAL1, this.TAB_VIAL2, this.TAB_VIAL3, this.TAB_VIAL4,
                                 this.TAB_VIAL5, this.TAB_VIAL6, this.TAB_VIAL7, this.TAB_VIAL8,
                                 this.TAB_VIAL9, this.TAB_VIAL10, this.TAB_VIAL11, this.TAB_VIAL12,
                                 this.TAB_VIAL13, this.TAB_VIAL14, this.TAB_VIAL15, this.TAB_VIAL16,
                                 this.TAB_VIAL17, this.TAB_VIAL18, this.TAB_VIAL19, this.TAB_VIAL20]);

        /*
         * Since this._loadExternalConcentrates() can take a fair amount of time
         * we use the this.rxPromise variable to only wait until the base prescription
         * is loaded before calling this._loadExternalConcentrates()
         * in order to improve the performance of loading data onto the UI
         */
        this.rxPromise
            .then(() => this._loadExternalConcentrates())
            .then(() => this.prescriptionPromise)
            .then(() => this._populateDisplay())
            .catch(ex => console.error(ex));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.$scope.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    /**
     * Populate this.substanceConcentrates with Antigen ExternalConcentrates that have been entered previously
     *
     * @returns {Promise} of true when complete
     * @private
     */
    async _loadExternalConcentrates() {
        this.inServiceExternalConcentrates = (await this.externalConcentrateService.getAtPractice(this.$scope.practice, 'IN_SERVICE')).list;
        for (let i = 0; i < this.inServiceExternalConcentrates.length; i++) {
            let conc = this.inServiceExternalConcentrates[i];
            conc._useBy = this.chronologyMappingService.isoDateToJsUTCDate(conc.useBy);
            let concArray = this.substanceConcentrates.get(conc.substance.id) || [];
            concArray.push(conc);
            this.substanceConcentrates.set(conc.substance.id, concArray);
        }

        return true;
    }

    /**
     * All data has been loaded - update scope for display.
     * @return Promise that completes
     * @private
     */
    async _populateDisplay() {
        let vial = this.$scope.vial = this.pscript.vials[this.vialIndex];
        let beyondUseDate = this.pscript.clientData.beyondUseDate;
        if (beyondUseDate && beyondUseDate[vial.id]) {
            vial._beyondUse = beyondUseDate[vial.id];
        }

        this.$scope.text = "Mix External Vial " + vial.name;
        this.initFooterNote("External Vial " + vial.name);

        // Remove substances of 0 volume
        vial.substances = vial.substances.filter(ps => ps.dosage > 0);

        for (let ps of vial.substances) {
            ps._name = ps._substance.name;
            ps._requiresInput = false;
            // Load Available External Concentrates/Substances for this particular prescribed substance
            ps._availableSubstances = [];
            if (ps.substanceVialId) {
                // This is supposed to be mixed from another prescribed substance.
                let maintVial = super.getVialById(ps.substanceVialId);
                ps._name = maintVial.name;
                ps.mixedFromBarcode = maintVial.barcode;

                //Load useByDate for the substanceVial from mixing wizard clientData
                let beyondUseDate = this.pscript.clientData.beyondUseDate;
                if (beyondUseDate && beyondUseDate[maintVial.id]) {
                    ps.useByDateInput = this.chronologyMappingService.isoDateToJsUTCDate(beyondUseDate[maintVial.id]);
                }
            }
            else if (ps.substanceTreatmentVial) {
                // This is supposed to be mixed from a treatment vial
                let sourceVial = await this.treatmentVialService.get(ps.substanceTreatmentVial);
                ps._name = sourceVial.name;
                ps.mixedFromBarcode = sourceVial.barcode;
                ps.useByDateInput = this.chronologyMappingService.isoDateToJsUTCDate(sourceVial.useBy);
            }
            else {
                // External concentrate information will have to be manually entered, so we're not populating the prescribedSubstance fields here
                ps._requiresInput = true;
                let concList = this.substanceConcentrates.get(ps.substance.id);
                if (concList) {
                    ps._availableSubstances.push(...concList);
                }
            }
            this._advanceToNextSubstance();
        }

        this.disableNext = false;
        this.disableExit = false;

        // awaits may or may not run - so may or may not have active $digest
        setTimeout(() => this.$scope.$digest());
    }

    _showTraditionalColor(substance) {
        return (this.$scope.isTraditionalArrangement && !substance._substance._category._isControl)
            || (this.isClassical && (substance.substanceVialId || substance.substanceTreatmentVial));
    }

    /**
     * Is the Next button disabled?
     *
     * @override
     * @return {boolean}
     */
    nextDisabled() {
        if (this.disableNext || !this.pscript) {
            return true;
        }

        for (let ps of this.$scope.vial.substances) {
            // If all of the information for each is not filled out, fail
            if (!this._substanceInputCompleted(ps)) {
                return true;
            }
        }
        return false;
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Child classes may override/extend.
     * @return Promise to updated Prescription
     */
    async nextStep() {
        let vial = this.$scope.vial;

        // Create/Update any external concentrates used
        let externalConcentrateRequests = [];
        for (let ps of this.$scope.vial.substances) {
            if (!ps._requiresInput) {
                // If the user is not required to input anything, the ps is not an external concentrate
                // and there will be no create/update request (substanceVial or substanceTreatmentVial)
                continue;
            }

            let concentratePromise = undefined;
            // No _externalConcentrate means all data was manually entered, external concentrate must be created
            if (!ps._externalConcentrate) {
                let conc = {
                    lot: ps.lotNumInput,
                    manufacturer: ps.manufacturerInput,
                    potency: ps.potencyInput,
                    potencyUnit: ps.potencyUnitInput,
                    substance: ps.substance,
                    status: 'IN_SERVICE',
                    useBy: this.chronologyMappingService.jsUTCDateToISODate(ps.useByDateInput),
                }

                concentratePromise = this.externalConcentrateService.create(this.$scope.practice, conc);
            }
            else {
                let externalConc = ps._externalConcentrate;
                externalConc.potency = ps.potencyInput;
                externalConc.potencyUnit = ps.potencyUnitInput;
                externalConc.useBy = this.chronologyMappingService.jsUTCDateToISODate(ps.useByDateInput);

                concentratePromise = this.externalConcentrateService.update(externalConc);
            }
            concentratePromise.then((externalConcentrate) => {
                // Set MixedFromBarcode on the prescribed substance.
                ps.mixedFromBarcode = externalConcentrate.barcode;
            })
            externalConcentrateRequests.push(concentratePromise);
        }

        await Promise.all(externalConcentrateRequests);

        /* Update BUD in client Data */
        this.pscript.clientData.beyondUseDate = this.pscript.clientData.beyondUseDate || {};
        let beyondUseDate = this.pscript.clientData.beyondUseDate;
        if (beyondUseDate[vial.id] !== vial._beyondUse) {
            beyondUseDate[vial.id] = vial._beyondUse;
        }

        /** Always print a label for the vial when it gets checked in */
        await this._printLabelModal();

        // Intelligently advance vialIndex and tab
        const nextVialIndex = this.pscript.clientData.mixwizard.vialIndex = this.vialIndex + 1;
        let advanceTab = !this.isClassical;

        if (nextVialIndex >= this.pscript.vials.length) {
            this.pscript.clientData.mixwizard.tab = this.TAB_REVIEW;
            advanceTab = false;
        }
        else if (this.$scope.vial.baseName !== this.pscript.vials[nextVialIndex].baseName) {
            advanceTab = true;
        }

        return super.nextStep(advanceTab);
    }

    _printLabelModal() {
        let parent = this;
        return this.$uibModal.open({
            windowClass: 'printLabels',
            backdrop: 'static',
            keyboard: false,
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/print-label-modal.html'),
            css: require('../../widgets/print-labels-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.print = () => {
                    parent.labelPrinterService.printPrescribedVial(parent.pscript, $scope.vial).then(() => {
                        $uibModalInstance.close();
                    });
                };
            }
        }).result;
    }

    /**
     * The Mix can be completed if at least one vial has been completely mixed, and no vial is partially mixed.
     * @private
     */
    _isReadyToMix() {
        let haveFilledVial = false;

        for (let vial of this.pscript.vials) {
            let vialInProgress = false;
            let vialNeedsInfo = false;

            for (let ps of vial.substances) {

                if (ps.mixedFromBarcode || this._substanceInputCompleted(ps)) {
                    vialInProgress = true;
                } else {
                    vialNeedsInfo = true;
                }

                //Don't continue looping if we have enough information about the vial
                if (vialInProgress && vialNeedsInfo) {
                    break;
                }
            }
            if (vialInProgress && vialNeedsInfo) {
                console.log("Partially filled vial - can't mix");
                return false;
            }
            else if (vialInProgress) {
                haveFilledVial = true;
            }
        }

        // Ready to mix if a vial is filled, and haven't aborted due to a partial vial
        return haveFilledVial;
    }

    _setFormulationUnits() {
        this.$scope.formulationUnits = ['AgE/mL', 'AU/mL', 'BAU/mL', 'mg/mL', 'µg/mL', 'PNU/mL', 'W/V', 'mL'];
    }

    _advanceToNextSubstance() {
        this.$scope.substanceIndex = -1;
        for (let i = 0; i <= this.$scope.vial.substances.length; i++) {
            let substance = this.$scope.vial.substances[i];
            if (!this._substanceInputCompleted(substance)) {
                this.$scope.substanceIndex = i;
                break;
            }
        }
        let substance = this.$scope.vial.substances[this.$scope.substanceIndex];
        this.$scope.selectedSubstance = substance;
    }

    _externalConcentrateIdentifierChanged = function (substance, focusInputId) {
        if (!substance) {
            return;
        }
        if (this.$scope.allowAutoBarcode && substance.lotNumInput === '=' && substance._availableSubstances.length) {
            substance.manufacturerInput = substance._availableSubstances[0].manufacturer;
            substance.lotNumInput = substance._availableSubstances[0].lot;
        }
        if (substance.manufacturerInput && substance.lotNumInput) {
            let foundMatch = false;
            // Look in _availableSubstances to see if there are any that match
            for (let pSubstance of substance._availableSubstances) {
                if (pSubstance.lot === substance.lotNumInput && pSubstance.manufacturer === substance.manufacturerInput) {
                    foundMatch = true;
                    // pre-fill input information
                    substance._externalConcentrate = pSubstance;
                    substance.potencyInput = pSubstance.potency;
                    substance.potencyUnitInput = pSubstance.potencyUnit;
                    substance.useByDateInput = pSubstance._useBy;
                    break;
                }
            }

            // Don't want to accidentally update the wrong ExternalConcentrate with new data
            if (!foundMatch) {
                substance._externalConcentrate = undefined;
            }
        }
        this._substanceInputBlur(substance, focusInputId);
    }

    _substanceInputBlur(substance, focusInputId) {
        if (this._substanceInputCompleted(substance)) {

            let bud = moment().add(365, 'days');
            for(let ps of this.$scope.vial.substances) {
                if (!ps.useByDateInput) {
                    continue;
                }

                let useByDate = moment(ps.useByDateInput);
                if (useByDate.isBefore(bud)) {
                    bud = useByDate;
                }
            }
            this.$scope.vial._beyondUse = bud.format('YYYY-MM-DD');

            this._advanceToNextSubstance();
        }

        //Dropdown selections are passing the button element to allow tab-through to be consistent
        if (focusInputId) {
            let element = document.getElementById(focusInputId);
            if (element) {
                element.focus();
            }
        }
    }

    _substanceInputCompleted(substance) {
        if (!substance) {
            return undefined;
        }
        // Only External Concentrates are entered manually. PrescribedSubstancesVials and TreatmentVials are auto-filled
        if (substance._requiresInput) {
            // Check each of the 5 input fields to ensure they're filled in
            // These fields need to have values associated with them
            let externalConcentrateFields = [
                'lotNumInput',
                'manufacturerInput',
                'potencyInput',
                'potencyUnitInput',
                'useByDateInput',
            ];

            for (let field of externalConcentrateFields) {
                if (!substance[field]) {
                    return false;
                }
            }
        }
        return true;
    }
}
