"use strict";

import SpWellModalController from "./sp-well-modal.controller";

import React from 'react'
import { submitPrintJob } from '../../../../react/print/Printer'
import { PRINTER_DYMO30336_LANDSCAPE, TrayLabel } from "../../../../react/print/Dymo30336Landscape"

export default class AddWellController extends SpWellModalController {

    /**
     * @type {Array<Concentrate>}
     * @private
     *
     * Concentrates available for use.
     */
    _availableConcentrates = [];

    static $inject = ["$uibModalInstance", "$scope", "$injector"];

    /**
     * @param {$uibModalInstance} AngularBootstrap uibModel instance controller
     * @param {AngularScope} $scope
     * @param {AngularInjectorServiceProvider} $injector
     */
    constructor($uibModalInstance, $scope, $injector) {
        super($uibModalInstance, $scope, $injector, SpWellModalController.UseCaseAdd);

        // In order to reuse (instead of replacing) superclass's init activity, we inject dependencies in a
        // dedicated method. See super#_initInjections. Okay, so we weren't injecting anything for this impl...
        // but in the future, if we do, use the _initInjections!


        /** @type{Array.<Panel-model-object>} */
        this.$scope.eligiblePanels = undefined; // Panels eligible for association with subject
        /**
         * @type{Map.<string ,Panel-model-object>}
         * maps panel.id to panel-object. Panel objects are HUGE, we don't need to be flinging these boulders to and
         */
        this._eligiblePanelMap = undefined;

        this.$scope.onBoardNameChange = () => this._onBoardNameChange();

        this.$scope.setSelectedPanel = function(panel) {
            $scope.selectedPanel = panel;
            $scope.panelId = panel.id;
            $scope.onPanelChange($scope.panelId);
        };

        this.$scope.onPanelChange = (newPanelId) => {
            this._assignPanel(this._eligiblePanelMap.get(newPanelId));
        };

        this.$scope.toBarcodeList = (concentrates) => this._toBarcodeList(concentrates);
        this.$scope.scanBarcode = () => this._scanBarcode();
        this.$scope.scanBarcodeKeydown = (event) => this._scanBarcodeKeydown(event);
        this.$scope.createBoard = () => this._createBoard();
        this._initialize();
    }

    /**
     * @protected
     * @override
     */
    _initInjections($injector) {
        super._initInjections($injector);
        this._$q = $injector.get("$q");
        this._globalConfigService = $injector.get('globalConfigService');
        this._substanceService = $injector.get('substanceService');
        this._concentrateService = $injector.get('concentrateService');
    }

    /**
     * Not overwrite overiding. More like a "me-too", override.
     *
     * @protected
     */
    async _initialize() {
        await super._initialize();

        await this._initAvailableConcentrates();
        await this._loadPanels();

        this._globalConfigService.get().then((config) => {
            this._allowAutoBarcode = config.allowAutoBarcode;
        });

        this.$scope.$digest();
    }

    // initializes scope.panels
    async _loadPanels() {
        // From JIRA AAW-290 ::
        // Panel Name (drop down populated from master list of active panels in Super UI screen 1430) - display
        // panel.name where panel.active is true, write to board.panel_id

        let myPanels = await this.panelSvc.getActiveAtPractice(this.$scope.practice);

        this.$scope.eligiblePanels = myPanels.list.filter(m => m.type === 'TESTING');
        this._eligiblePanelMap = new Map();

        for (let aPanel of myPanels.list) {
            this._eligiblePanelMap.set(aPanel.id, aPanel);
        }

        if (this.$scope.eligiblePanels.length === 1) {
            this.$scope.setSelectedPanel(this.$scope.eligiblePanels[0]);
        }
    }

    async _initAvailableConcentrates() {
        let concentrates = await this._concentrateService.getInServiceAtOffice(this.$scope.office);
        this._availableConcentrates = concentrates.list;
    }

    /**
     * @protected
     * @override
     */
    async _loadServiceStatusValues() {
        this.$scope.eligibleStatusTypes =
            await this.eligibleStatusService.getEligibleStatusTypesForNewBoard();
    }

    _prepBarcodeScan() {
        if (this.$scope.boardName && this.$scope.selectedPanel) {
            $('#concentrateBarcode').focus();
        }
    }

    _onBoardNameChange() {
        this._prepBarcodeScan();
    }

    /**
     * @param {Panel-model-object} panel
     * @override
     */
    _assignPanel(panel) {
        super._assignPanel(panel);
        this._prepBarcodeScan();
        this._resetConcentrates(panel).then();
    }

    async _resetConcentrates(newPanel) {
        // Set/reset selected concentrates
        this.$scope.concentrates = [];

        for (const orderedSubstance of newPanel.substances) {
            const pos = orderedSubstance.sptPos;
            if (pos >= 0) {
                const substance = await this._substanceService.get(orderedSubstance.substance);

                // Check if only one concentrate of this substance is available. If so, it'll be auto selected.
                const concOfSubstance = this._availableConcentrates.filter(c => c.substance.id === substance.id);

                this.$scope.concentrates.push({
                    sortOrder: pos,
                    substanceId: substance.id,
                    substanceName: substance.name,
                    selected: (concOfSubstance.length === 1) ? [concOfSubstance[0]] : []
                });
            }
        }

        // Sort in defined order
        this.$scope.concentrates.sort((a,b) => a.sortOrder - b.sortOrder);

        this.$scope.areConcentratesSelected = this.$scope.concentrates.every(entry => entry.selected.length === 1);
        this.$scope.$digest();
    }

    _printTrayLabels(data) {
        const labels = []
        for (var i = 0; i < data.trayCount; i++) {
            labels.push(
                <TrayLabel
                    key={i}
                    barcode={data.barcode}
                    description={data.name}
                />
            )
        }
        submitPrintJob(PRINTER_DYMO30336_LANDSCAPE, labels)
    }

    /**
     * Excerpts From: https://nbdevs.atlassian.net/browse/AAW-290  description
     *
     * If the user clicks "Create Board", the following should be set:
     *  board.name - user entry above
     *  board.procedureType - SPT
     *  Panel Name - board.panel_id (from above)
     *  board.dilutionsCount - 1
     *  board.substancesPerTray - PracticeConfig.sptSubstancesPerTray
     *  Date Created - board.created_date_time (though display is always truncated to date)
     *  If the board was set to In-Service, the In Service Date should be set to today's date - board.startService
     *  Barcode - automatically assign a unique barcode - board.barcode
     *  Tray count - see above
     *  Records in the trayVial table/object - one for each substance in the panel
     *  Records in the concentrateUse table/object - one for each substance in the panel (with concentrate_id being the only In-Service concentrate for that panelSubstance) and trayVial_id from the above bullet
     *
     * @private
     */
    async _createBoard() {

        /* Set beenClicked to true, so the save buton can not be clicked again */
        this.$scope.beenClicked = true;

        let boardModel = {};
        boardModel.name = this.$scope.boardForm.boardName.$modelValue;
        boardModel.status = this.ServiceStatus.IN_SERVICE;
        boardModel.procedure = this.ProcedureType.SPT;
        boardModel.panel = { "id" : this.$scope.boardForm.panelId.$modelValue };

        const concentrates = this.$scope.concentrates.map(c => {
            return { id: c.selected[0].id };
        });

        this.boardService.create(this.$scope.office, boardModel, concentrates)
            .then((genesisResponse) => {
                this._printTrayLabels(genesisResponse)
                super._closeModal(genesisResponse);
                this.routeToPage(this.urlPaths.INVENTORY_WELL_DETAILS, /** BoardDTO */genesisResponse);
            })
            .catch((genesisFailure)=> {
                /*
                 * If control flow reaches this point, we've got a show-stopping problem. We also know the systemic
                 * ErrorInterception mechanisms have been selectively disarmed for this species of request. There are
                 * multiple reasons we want override that behavior and substitute in this activity; not the least of
                 * which is: the main-stream API error messaging uses a modal-popup. That's problematic because this
                 * UI controller is already serving a modal layout. We just can't have a stack a modals!
                 *
                 * In lieu of piling on the error modal on top of this modal, we'll simply reveal a 'ground-level'
                 * section of the UI containing the error message in threatening theme.
                 */

                this.$scope.isSubmissionFailure = true;
                this.$scope.submissionFailureMessage = genesisFailure;
            });
    }

    _scanBarcodeKeydown(event) {
        if (event.which === 13) {
            event.preventDefault();
            this._scanBarcode();
        }
    }

    _scanBarcode() {
        const barcodeInput = this.$scope.barcodeInput;
        this.$scope.barcodeError = null;
        console.log("Barcode input '" + barcodeInput + "'");
        if (!barcodeInput) {
            // No input
            return;
        }

        // Auto-fill all selections for dev & QA
        if (barcodeInput === '=' && this._allowAutoBarcode) {
            for (let entry of this.$scope.concentrates) {
                const c = this._availableConcentrates.find(c => c.substance.id === entry.substanceId);
                if (c)
                    entry.selected = [c];
            }
        }
        else if (barcodeInput.length !== 8) {
            this.$scope.barcodeError = "Invalid barcode: " + barcodeInput;
        }
        else {
            // Find concentrate match
            const conc = this._availableConcentrates.find(c => c.barcode === barcodeInput);
            if (conc) {
                const entry = this.$scope.concentrates.find(item => item.substanceId === conc.substance.id);
                if (entry) {
                    const exists = entry.selected.find(c => c.id === conc.id);
                    if (!exists) {
                        // Add to selection
                        entry.selected.push(conc);
                    }
                    else {
                        // May be a tie breaker - make this now be the only selection
                        entry.selected = [conc];
                    }
                }
                else {
                    this.$scope.barcodeError = "Substance not in panel.";
                }
            }
            else {
                this.$scope.barcodeError = "Concentrate " + barcodeInput + " not available.";
            }
        }

        this.$scope.barcodeInput = '';
        this.$scope.areConcentratesSelected = this.$scope.concentrates.every(entry => entry.selected.length === 1);
    }

    /**
     * Return a comma-separated list of barcode numbers
     * @param concentrates array of Concentrate objects
     */
    _toBarcodeList(concentrates) {
        return concentrates.map(c => c.barcode).toString();
    }
}
