'use strict';

export function panelGroupsModal(availablePanels, initSelectedPanel, initSptGroups) {
    return {
        windowClass: 'panelGroupsModal',
        size: 'lg',
        template: require('./panel-group-select-modal.html'),
        css: require('./panel-group-select-modal.scss'),
        controller: function ($uibModalInstance, $scope) {
            $scope.panels = availablePanels;
            $scope.sptGroups = initSptGroups;

            let defineGroups = () => {
                let groups = {};

                if ($scope.selectedPanel.id) {
                    $scope.selectedPanel.substances.sort((a,b) => a.sptPos - b.sptPos);

                    for (let panelSubstance of $scope.selectedPanel.substances) {
                        if (panelSubstance.sptPos < 0)
                            continue;

                        const sptGroup = panelSubstance.sptGroup;
                        if (!groups[sptGroup])
                            groups[sptGroup] = [];

                        const substance = panelSubstance.substance._dto;
                        const category =  substance.category._dto;
                        groups[sptGroup].push({
                            name: substance.name,
                            isControl: category._isControl
                        });
                    }
                }

                $scope.allGroups = '';
                $scope.panelGroups = [];
                for (let letter of 'ABCDEFGHIJKLMNOP') {
                    if (groups[letter]) {
                        $scope.allGroups += letter;
                        $scope.panelGroups.push({
                            letter: letter,
                            checked: $scope.sptGroups.indexOf(letter) >= 0,
                            substances: groups[letter]
                        });
                    }
                }
            };

            let selectAllGroups = () => {
                $scope.sptGroups = $scope.allGroups;
                $scope.panelGroups.forEach(g => g.checked = true);

            };

            /** Panel dropdown selection **/
            $scope.selectPanel = (panel) => {
                $scope.selectedPanel = panel;

                defineGroups();

                // By default, all groups are selected
                selectAllGroups();
            };

            $scope.onGroupChanged = (group) => {
                let sptGroups = $scope.sptGroups;
                if (group.checked && sptGroups.indexOf(group.letter) === -1) {
                    sptGroups += group.letter;
                }
                else {
                    sptGroups = sptGroups.replace(group.letter, "");
                }

                // Alphabetize for orderly display on checklist screen
                $scope.sptGroups = Array.from(sptGroups).sort().join('');
            };

            $scope.done = () => {
                $uibModalInstance.close({panel: $scope.selectedPanel, sptGroups: $scope.sptGroups});
            };

            $scope.cancel = () => {
                $uibModalInstance.dismiss();
            };

            // Initial selection
            if (initSelectedPanel.id) {
                $scope.selectedPanel = initSelectedPanel;
                defineGroups();

                // If no groups selected, select them all.
                if (!$scope.sptGroups)
                    selectAllGroups();
            }
            else if ($scope.panels.length === 1) {
                $scope.selectPanel($scope.panels[0]);
            }
            else {
                $scope.selectPanel({ name: 'Select Panel' });
            }
        }
    };
}
