'use strict';

export default class ReportsService {
    // Normally, we'd extend this from the BaseService. However, this impl will pull it data from a different
    // remote source than other DataService implementations.

    // In core-app; api offers allergy-web API's. This is the serverless API for reporting....
    static $inject = ['$http', '$q', 'serverAPI'];

    constructor($http, $q, serverAPI) {
        this.$http = $http;
        this.$q = $q;
        this.serverAPI = serverAPI;

        this.dataServiceMode = process.env.REPORTS_SERVICE_MODE; // CLOUD || LOCAL

        this.initPromise = this.serverAPI.getGlobalLinks().then(links => {
            this.remoteApiUrl = links.reports;
        });
    }

    _safelyCopyMapEntry(paramKey, sourceMap, destinationMap) {
        try {
            let val = sourceMap[paramKey];
            if (val !== undefined && val !== null) {
                destinationMap[paramKey] = sourceMap[paramKey];
            }
        } catch (mapAccessFail) {
            console.warn("While copying '" + paramKey + "' to request");
            console.warn(mapAccessFail);
            throw mapAccessFail;
        }
    }

    _mapParams(criteria, requestParams) {
        // Only copy primitive values
        for(let key in criteria) {
            let val = criteria[key];
            if (val !== Object(val)) {
                this._safelyCopyMapEntry(key, criteria, requestParams);
            }
        }
    }

    _getReportMetaData(reportType, criteria) {
        let deferred = this.$q.defer();

        let requestUrl = this.remoteApiUrl;
        let requestParams = {
            "reportType" : reportType,
        };

        this._mapParams(criteria, requestParams);

        let request = {
            method: "GET",
            url: requestUrl,
            params: requestParams,
            // added to stifle ERRORs from headers that shouldn't be included...
            headers: {
                "x-requested-with" : undefined
            }
        };

        // dispatch request
        this.$http(request).then(
            (response) => {
                if (response.status === 200) {
                    deferred.resolve(response.data);
                }
                else {
                    // Failure. If result from the service is a ServerError, return it.
                    // Otherwise construct our own ServerError properties.
                    if (Number.isInteger(response.data.status))
                        deferred.reject(response.data);
                    else
                        deferred.reject({status: response.status, errors: []});
                }

            },
            (rejection) => {
                console.log("Rejection ==>");
                console.log(rejection);
                console.log("Request ==>");
                console.log(request);
            }
        );

        return deferred.promise;
    }

    pollForReportData(requestCall, timeout, interval) {
        const MAX_TRIES = 8;
        let triesCounter = 0;
        let endTime = Number(new Date())  + (timeout || 2048);
        interval = interval || 100;

        let checkFile = async (resolve, reject) => {
            triesCounter++;
            console.log("CheckFile() {} try #" + triesCounter );
            let result;
            try {
                result = await requestCall();
            }
            catch(httpFail) {
                console.warn(httpFail);
                if (httpFail.status === 404) {
                    if (triesCounter < MAX_TRIES) {
                        console.log("CASE 404 : retrying...");
                        setTimeout(checkFile, interval, resolve, reject);
                    }
                    else {
                        //throw httpFail;
                        console.warn("I'm ignoring this 404, because I'm polling!");
                    }
                }
            }

            if (result && result.data) {
                resolve(result.data);
            }
            else if (Number(new Date()) < endTime) {
                setTimeout(checkFile, interval, resolve, reject);
            }
            else {
                console.warn("Request exceeded timeout");
                //reject(new Error('timed out for ' + requestCall ));
            }

        };
        return new Promise(checkFile);
    }

    async _getReportFile(fileUrl) {
        let request = {
            method: "GET",
            url: fileUrl,
            // added to stifle ERRORs from headers that shouldn't be included...
            headers: { "x-requested-with" : undefined}
        };

        let requestCall = ()=> {
            return this.$http(request);
        };

        let results = await this.pollForReportData(requestCall, 3000, 512);
        return results;
    }

    /**
     * This impl acquires report data from the cloud based service. The protocol is:
     * GET reportArgs yields a report-meta-data result containing a signedUrl to the endPoint from whence the
     * completed report data-set will be accessible.
     * (This client instance) polls the signedUrl (see  _getReportFile)
     *
     * @param reportType
     * @param criteria
     * @return {Promise<*>}
     * @private
     */
    async _getReportFromCloud(reportType, criteria) {
        let reportMetaData = await this._getReportMetaData(reportType, criteria);
        let reportDataUrl = reportMetaData.reportDataUrl;
        // Use the metadata to learn the URL of the data we want
        let reportFile = await this._getReportFile(reportDataUrl);
        return reportFile;
    }

    /**
     * This impl acquires report data from an SLS Offline mode ran instance. The protocol is simple:
     * GET reportArgs yields the report data in the response.
     * @param reportType
     * @param criteria
     * @return {Promise<*>}
     * @private
     */
    async _getReportLocally(reportType, criteria) {
        let deferred = this.$q.defer();

        let requestUrl = this.remoteApiUrl;
        let requestParams = {
            "reportType" : reportType
        };
        this._mapParams(criteria, requestParams);

        let request = {
            method: "GET",
            url: requestUrl,
            params: requestParams,
            withCredentials: true,
            headers: {
                "x-requested-with": undefined
            }
        };

        // dispatch request
        this.$http(request).then(
            (response) => {
                if (response.status === 200) {
                    deferred.resolve(response.data);
                }
                else {
                    // Failure. If result from the service is a ServerError, return it.
                    // Otherwise construct our own ServerError properties.
                    if (Number.isInteger(response.data.status))
                        deferred.reject(response.data);
                    else
                        deferred.reject({status: response.status, errors: []});
                }
            },
            (rejection) => {
                console.log("Rejection ==>");
                console.log(rejection);
                console.log("Request ==>");
                console.log(request);
            }
        );

        return deferred.promise;
    }

    // Workaround: uses alternate impl of the cloud service in the interim (SQS support issues with SLS)
    async _getReportFromCloudSynchronously(reportType, criteria) {
        let deferred = this.$q.defer();
        let requestUrl = this.remoteApiUrl;
        let requestParams = {
            "reportType" : reportType
        };

        this._mapParams(criteria, requestParams);
        let request = {
            method: "GET",
            url: requestUrl,
            params: requestParams,
            withCredentials: true,
            headers: {
                "x-requested-with": undefined
            }
        };

        // dispatch request
        this.$http(request).then(
            (response) => {
                if (response.status === 200) {
                    deferred.resolve(response.data);
                }
                else {
                    // Failure. If result from the service is a ServerError, return it.
                    // Otherwise construct our own ServerError properties.
                    if (Number.isInteger(response.data.status))
                        deferred.reject(response.data);
                    else
                        deferred.reject({status: response.status, errors: []});
                }
            },
            (rejection) => {
                console.log("Rejection ==>");
                console.log(rejection);
                console.log("Request ==>");
                console.log(request);
            }
        );

        return deferred.promise;
    }

    async _getReport(reportType, criteria) {
        await this.initPromise;

        if (this.dataServiceMode === "LOCAL") {
            return this._getReportLocally(reportType, criteria);
        }
        else {
            //return this._getReportFromCloud(reportType, criteria);
            return this._getReportFromCloudSynchronously(reportType, criteria);
        }
    }

    /*
     * TODO: use an enum type for the report flavors
     */

    async getBillHistory(criteria) {
        return this._getReport("BillHistory", criteria);
    }

    async getTotalTests(criteria) {
        return this._getReport("TotalTests", criteria);
    }

    async getApprovedAwaitingTreatment(criteria) {
        return this._getReport("ApprovedAwaitingTreatment", criteria);
    }

    async getDailyVisits(criteria) {
        return this._getReport("DailyVisits", criteria);
    }

    async getTreatmentCompliance(criteria) {
        return this._getReport("TreatmentCompliance", criteria);
    }

    async getCancelled(criteria) {
        return this._getReport("Cancelled", criteria);
    }

    async getConcentrateExpiration(criteria) {
        return this._getReport("ConcentrateExpiration", criteria);
    }

    async getPatientVialExpiration(criteria) {
        return this._getReport("PatientVialExpiration", criteria);
    }

    async getPatientTestingStatus(criteria) {
        return this._getReport("PatientTestingStatus", criteria);
    }

    async getPatientMixingStatus(criteria) {
        return this._getReport("PatientMixingStatus", criteria);
    }

    async getPatientTreatmentStatus(criteria) {
        return this._getReport("PatientTreatmentStatus", criteria);
    }
}
