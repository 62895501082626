import React from 'react'
import styled from '@emotion/styled'
import Barcode from 'react-barcode'

const barcodeProps = {
	// Chrome/edge have problems printing the svg version
	// (lines too thick)
	// there are known problems with Chrome SVG rendering like
	// https://gist.github.com/BenjaminVerble/643de7d5ed5f35edce7d7752692d8101
	// Anyway, I fiddled a while and couldn't get it behave
	// So canvas version it is
	renderer: 'canvas',

	// the canvas version fonts are completely unreadable
	// so we'll put our own type in there
	displayValue: false,
	// fontSize: 11,
	// textMargin: 1,

	flat: true,
	format: 'EAN8',
	margin: 0,
	width: 1,  // bars 1 px wide each
	height: 30,
}

const LabelBarcodeNumber = styled.div({
	marginTop: '.25em',
	fontSize: '8pt',
	fontFamily: 'monospace',
})

const LabelBarcode = React.memo(props => (
	<div>
		<Barcode
			{...barcodeProps}
			{...props}
		/>
		<LabelBarcodeNumber>{props.value}</LabelBarcodeNumber>
	</div>
))

export default LabelBarcode
