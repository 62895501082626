'use strict';

import angular from 'angular';

export default angular.module('pages.reports.widgets',[])
    .directive('agReportActions', agReportActions)
    .directive('stBooleanFilter', stBooleanFilter)
    .name;

function agReportActions() {
    return {
        template: require('./ag-report-actions.html'),
        scope: false
    };
}

function stBooleanFilter() {
    return {
        restrict: 'E',
        scope: {
            predicate: '@'
        },
        require: '^stTable',
        styles: require('./st-boolean-filter.scss'),
        template: require('./st-boolean-filter.html'),
        link: function (scope, element, attr, ctrl) {
            scope.options = [
                { name: 'All', value: null },
                { name: 'Yes', value: true },
                { name: 'No', value: false }
            ];

            scope.selectedOption = scope.options[0];

            scope.selectOption = (option) => {
                scope.selectedOption = option;

                if (option.value === true) {
                    ctrl.search(option.value, scope.predicate);
                }
                else if (option.value === false) {
                    // false is replaced with null, so use the string 'false' here
                    ctrl.search('false', scope.predicate);
                }
                else {
                    ctrl.search(null, scope.predicate);
                }
            };
        }
    };
}