import angular from 'angular';
import ngRoute from 'angular-route';
import ui_bootstrap from 'angular-ui-bootstrap';

import MixingInitializeController from './mixing-initialize.controller.js';


export default angular.module('pages.mixing.initialize', [ngRoute, ui_bootstrap])
    .config(config)
    .controller('MixingInitializeController',MixingInitializeController)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_MIXING_INITIALIZE, {
        template: '<div/>',
        controller: 'MixingInitializeController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];
