"use strict";

import angular from "angular";

export default function agAccountManagement() {

	return {
		controller : "AccountManagementController",
		scope : false,
		restrict : "E",
		css : require("./styles.scss"),
		template : require("./layout.html"),

		link : function(scope, element, attrs, controller) {}

	};
};
