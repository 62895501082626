'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TestPostVitalsController from './post-vitals.controller.js';

export default angular.module('pages.testing.postVitals', [ngRoute])
    .config(config)
    .controller('TestPostVitalsController',TestPostVitalsController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/postVitals', {
        template: require('./post-vitals.html'),
        controller: 'TestPostVitalsController'
    });
}

config.$inject = ['$routeProvider'];
