import React from 'react'
import styled from '@emotion/styled'

export const Line = styled.div(props => ({
	fontSize: props.size || '8pt',
	fontFamily: props.mono ? 'monospace' : 'sans-serif',
	fontWeight: props.bold ? 'bold' : 'normal',
	maxWidth: '100%',
}))

export const DottedInstructions = styled.div({
	border: '1px dotted black',
	padding: '.5em',
	fontSize: '7pt',
})
