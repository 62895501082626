"use strict";

export default class CorpLogoController {

    // Nothing special about userService - any API service would do.
    static $inject=["$scope", 'sessionService', 'userService'];
    constructor($scope, sessionService, userService) {

        userService.getGlobalLinks().then(globalLinks => {
            $scope.nonProductionType = globalLinks.environment == 'PRODUCTION' ? null : 'training/testing'
        });
        
        $scope.currentUser = sessionService.get(sessionService.KEY_USER);
    }
}
