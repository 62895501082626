"use strict";

import BaseBoardListController from "../../widgets/inv-board-list/controller";

export default class MixingBoardsController extends BaseBoardListController {

    static $inject =
    /*fmIndex*/["$injector","$scope","InventorySections","UrlPaths","Procedure"];
    constructor( $injector , $scope , InventorySections , UrlPaths , Procedure ) {
        super($injector, $scope, InventorySections.MIXING, UrlPaths.INVENTORY_MIXING_DETAILS, Procedure.MIXING);
    }
}
