"use strict";

import angular from "angular";
import Controller from "./controller";

export default angular.module("pages.dashboard.global-alerts-panel", [])
    .controller("GlobalAlertsController", Controller)
    .directive("agDbGlobalAlerts", [agDbGlobalAlerts])
    .name;

function agDbGlobalAlerts() {
    return {
        controller: "GlobalAlertsController",
        scope: false,
        restrict: "E",
        css: require("./styles.scss"),
        template: require("./layout.html")
    };
}
