'use strict';

import SockJS from "sockjs-client";
import Stomp from "../../../../node_modules/stompjs/lib/stomp";

/**
 * Stomp Client using an SockJs connection to manage stomp message subscriptions.
 */
export default class StompClient {

    static $inject = ['$q', 'uuid'];

    constructor($q, uuid) {
        this.client = undefined;
        this.$q = $q;
        this._uuid = uuid;

        this._outboundHeartbeat = 25000; // 25 seconds on outgoing heartbeat.
        this._inboundHeartbeat = 25000; // 25 seconds to receive heartbeats from the server.
        this._debugLogging = false; // change to true to see stompjs debug logging.
    }

    /**
     * Disconnects if necessary and then creates the SockJs connection to the endpoint @ url
     * @param url - SockJs endpoint
     * @returns {Promise that resolves (stomp client)}
     * @private
     */
    _init(url) {
        let deferred = this.$q.defer();
        let client = undefined;

        if (this.client) { // it is bad to have multiple ws connections. disconnect if the client is created.
            this.disconnect().then(() => {
                client = this._initClient(url);
                deferred.resolve(client);
            });
        } else {
            client = this._initClient(url);
            deferred.resolve(client);
        }

        return deferred.promise;
    }

    /**
     * Creates the SockJs connection to the endpoint @ url
     * @param url - conection url
     * @returns {stomp client}
     * @private
     */
    _initClient(url) {
        this.websocket = new SockJS(url, null, { transports: 'websocket'});
        this.client = Stomp.Stomp.over(this.websocket);
        this.client.heartbeat.outgoing = this._outboundHeartbeat;
        this.client.heartbeat.incoming = this._inboundHeartbeat;

        if (!this._debugLogging) {
            this.client.debug = null;
        }

        return this.client;
    }

    /**
     * Makes the Stomp CONNECT over the established SockJS socket.
     * @param url
     * @returns {Promise} of true when connected, or false if failed.
     *   (Always resolves rather than rejecting, because the rest of the application should continue to function without this service.)
     */
    connect(url, login, passcode) {
        let deferred = this.$q.defer();
        this.passcode = passcode;

        if (!this.client || !this.client.connected) {
            this._init(url).then((client) => {
                client.connect(login, passcode, () => {
                    console.log("STOMP connected");
                    deferred.resolve(true);
                }, (error) => {
                    console.log("STOMP failed to connect: " + error);
                    deferred.resolve(false);
                });
            });
        } else {
            deferred.resolve(true);
        }

        return deferred.promise;
    }

    /**
     * Send the Stomp DISCONNECT message, which disconnects the client and closes underlying SockJs socket.
     * @return Promise that resolves true when disconnected.
     */
    disconnect() {
        let deferred = this.$q.defer();

        if (this.client && this.client.connected) {
            // Close underlying websocket first so that STOMP can't send a DISCONNECT message.
            // A DISCONNECT frequently results in a useless error being logged on the server by StompBrokerRelayMessageHandler.
            // Simply dropping the connection actually cleans up better than a "clean" disconnect (on the server).
            this.websocket.close();

            // Still let this side clean up.
            this.client.disconnect(() => {
                console.log("STOMP disconnected");
                deferred.resolve(true)
            });
        }
        else
            deferred.resolve(true);

        return deferred.promise;
    }

    /**
     * Subscribe to a topic using stomp protocol.
     * @param topic - topic routing key
     * @param notifyCallback - callback to receive notifications on.
     * @param referenceOnly - set to true to request a referenceDTO rather than the full DTO.
     * @returns {Promise} that when resolved contains an object with the 'unsubscribe()' method that cancels the created subscription, or rejects if not connected.
     */
    subscribe(topic, notifyCallback, referenceOnly) {
        var deferred = this.$q.defer();

        let id = this.passcode + '_' + this._uuid.v4();
        if (referenceOnly)
            id = id + "-refDto";

        if (!this.client || !this.client.connected) {
            let s = "Not connected to STOMP server - cannot subscribe";
            console.log(s);
            deferred.reject(s);
        } else {
            //console.log("Subscribing to " + topic);
            deferred.resolve(this.client.subscribe(topic, notifyCallback, {id: id}));
        }

        return deferred.promise;
    }
}