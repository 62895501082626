'use strict';

/**
 * Little controller that handles global notices.
 */
export default class StandardLayoutNoticeController {

    static $inject=['$scope', 'noticeService', 'sessionService'];
    constructor($scope, noticeService, sessionService) {
        this.$scope = $scope;
        this.sessionService = sessionService;
        
        let activeNotices = this.sessionService.get(this.sessionService.KEY_NOTICES);
        if (activeNotices) {
            this.$scope.notices = activeNotices;
        } else {
            noticeService.getActive().then((result) => {
                this.$scope.notices = result;
                this.sessionService.set(this.sessionService.KEY_NOTICES, this.$scope.notices);
            });
        }
			
        this.$scope.hideNotice = (i) => {
            if (this.$scope.notices && this.$scope.notices[i]) {
                this.$scope.notices.splice(i, 1);
                this.sessionService.set(this.sessionService.KEY_NOTICES, this.$scope.notices);
            }
        }
    }
}
