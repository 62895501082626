"use strict";

export default angular.module('pages.patient.details.add-rx-modal.widgets.pd-date-field', [])
    .directive("pdDateField", [pdDateField])
    .name;

function pdDateField() {
    return {
        scope : {
            subjectModel : "=",
            dateOptions: "="
        },
        template : require("./layout.html"),
        css : require("./styles.scss"),
        link : function(scope, elem, attrs) {
            //console.log("pdDateField::Link()!! \t\tmy scope ==> ");
            //console.log(scope);
        }
    };
}
