'use strict';

import BaseService from './base.service.js';

export default class ExternalConcentrateService extends BaseService {

    /**
     * Get the URL to a concentrate given it's ID
     */
    getHrefForId(concentrateId) {
        return `/api/concentrates/external/${concentrateId}`
    }

    /**
     * Add a new external concentrate
     *
     * @param {Practice} practice
     * The office to which this new ExternalConcentrate value will be applied
     * @param {ExternalConcentrate} externalConcentrate
     * The logical value we're introducing
     *
     * @return Promise to new ExternalConcentrate DTO
     */
    create(practice, externalConcentrate) {
        return this.serverAPI.post(practice._links.externalConcentrates, {}, externalConcentrate);
    }

    /**
     * Fetch all Concentrates at an Office.
     *
     * @param {Practiec} practice
     * @return Promise to a Concentrate.List of matches.
     */
    getAtPractice(practice, status) {
        return this.serverAPI.get(practice._links.externalConcentrates, {status: status});
    }

    /**
     * Fetch all Concentrates at an Office that are IN_SERVICE.
     *
     * @param {Office} office
     * @return Promise to a Concentrate.List of matches.
     */
    getInServiceAtOffice(office) {
        return this.serverAPI.get(office._links.concentratesInService);
    }

    /**
     * Fetch all Concentrates at an Office that are IN_SERVICE and of a specific Substance
     *
     * @param {Office} office
     * @param {Substance} substance
     * @return Promise to a Concentrate.List of matches. Should be zero or one.
     */
    getInServiceSubstanceAtOffice(office, substance) {
        return this.serverAPI.get(office._links.concentratesInService, { substance: substance.id });
    }

    /**
     * GET history of uses of this Concentrate.
     *
     * @param concentrate a Concentrate DTO
     * @return Promise to a ConcentrateUse.List sorted by useDate
     */
    getUses(concentrate) {
        return this.serverAPI.get(concentrate._links.uses, {});
    }

    /**
     * GET an ExternalConcentrate given it's barcode
     *
     * @param practice a Practice DTO
     * @param barcode a barcode for an external concentrate
     * @return Promise to an ExternalConcentrate, or 404
     */
    getByBarcode(practice, barcode) {
        return this.serverAPI.get(practice._links.findExternalConcentrateByBarcode, { barcode: barcode }, undefined, [404]);
    }
}
