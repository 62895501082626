"use strict";

import BaseApprovalController from "../base-approval.controller.js";

/**
 * Controller for Test Approvals.
 */
export default class TestApprovalController extends BaseApprovalController {

    constructor($scope, $injector) {
        super($scope, $injector);

        this.allergyTestService = $injector.get('allergyTestService');
        this.allergyTestConfigService = $injector.get('allergyTestConfigService');
        this.billService = $injector.get('billService');
        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.panelService = $injector.get('panelService');
        this.Procedure = $injector.get('Procedure');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.testReviewService = $injector.get('testReviewService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.$sce = $injector.get('$sce');
        this.$q = $injector.get('$q');

        /**
         * Logical State change handler. Authorized users may alter an antigen test results' stats.
         * This routine reacts to such changes to the endPoint. We alter the test row's data-object's "treat" field
         * This routine reacts to such changes to the endPoint. We alter the test row's data-object's "treat" field
         * to reflect special cases. "treat" logically means "should I treat the patient for this?"
         *
         * @param {Integer} antigenIndex; is the logical list index within scope.vmResults
         * @param {Integer} updatedEndPoint; where 0 < x < MAX-END-POINT
         */
        this.$scope.onAntigenEndPointUpdate = (antigenIndex, updatedEndPoint) => {
            if (updatedEndPoint == 0) {
                this.$scope.vmResults[antigenIndex].treat = false;
            }
            this._updateTreatable();
        };

        // This callback reacts to UI changes on the Prescription field of an Antigen row in the subject test result.
        this.$scope.onAntigenPrescriptionChange = (antigenIndex, createRx) => {
            this._updateTreatable();
        };

        this.$scope.isTreatmentConfigDisabled = (index) => this._isTreatmentConfigDisabled(index);
        this.$scope.canStartPrescription = () => this._canStartPrescription();
        this.$scope.approveTest = (createRx, cancelIdtOrder) => this._approveAllergyTest(createRx, cancelIdtOrder);
        this.$scope.createTestReview = () => this._createTestReview();
        this.$scope.editDelayedReactions = () => { this.$scope.enableDelayedReactions = true; };
        this.$scope.saveDelayedReactions = () => this._saveDelayedReactions();
        this.$scope.saveAndExit = () => this._saveAndExit();

        this.initData(this.$scope.allergyTestHref);
    }

    initTestResults(allergyTest) {
        // Add meta-data to controlResults and add them to scope
        const sptGroupSize = this.$scope.practice.config.sptSubstancesPerTray / this.$scope.practice.config.sptPrickersPerTray;
        let tmpResults = [];

        // If any of the antigens in an SPT group are TESTED,
        // then we assume the SPT group was selected for measurement and show the antigens in
        // that group on the approval. Small edge case where all antigens were skipped on a group
        // intentionally marked for testing, best fixed at the point we remove data duplication of spt
        // measurements in scalability release

        for (let result of allergyTest.controlResults) {
            this.substanceService.get(result.substance, allergyTest).then(subst => {
                result._name = subst.name;
            });

            let os = this.panel.substances.find((os) => os.substance.id === result.substance.id);
            result._sptPos = result._orderBy = os ? Number(os.sptPos) : -1;
            result._isControl = true;
            result._sptGroup = os ? os.sptGroup : null;
            result._groupPos = os ? (os.sptPos % sptGroupSize) + 1 : -1;
            result._idtDilutionDisplay = result.idtDilution === 0 ? 'C' : result.idtDilution;

            let classes = {};
            classes['adhoc-control'] = (result.idtMeasurements && result.idtDilution !== -1);
            classes['grayed-out'] = !classes['adhoc-control'] || result.idtDilution < 0 || result.idtDilution > 99;
            result._styleClass = classes;

            this._setDilutionGroup(result);

            let measuredAtSpt = result.sptMeasurements && result.sptMeasurements.wheal != -1;
            if (result.idtDilution === 0 || measuredAtSpt) {
                tmpResults.push(result);
            }
        }

        let groupsWithMeasuredAntigens = [];
        for (let pos = 0; pos < allergyTest.antigenResults.length; ++pos) {
            let result = allergyTest.antigenResults[pos];

            let os = this.panel.substances.find((os) => os.substance.id === result.substance.id);
            if (os) {
                if (groupsWithMeasuredAntigens.indexOf(os.sptGroup) == -1 && result.sptMeasurements && result.sptMeasurements.wheal != -1 && result.sptMeasurements.wheal != null) {
                    groupsWithMeasuredAntigens.push(os.sptGroup);
                }
            }
        }

        // Add meta-data to antigenResults and add them to scope
        this.$scope.isTreatable = false;
        for (let pos = 0; pos < allergyTest.antigenResults.length; ++pos) {
            let result = allergyTest.antigenResults[pos];
            result._isControl = false;

            // Adhoc antigens are not a thing, so panel substance should always be set
            let os = this.panel.substances.find((os) => os.substance.id === result.substance.id);
            if (os) {
                let subst = os.substance._dto;
                result._name = subst.name;
                result._substanceCategoryId = subst.category.id;
                result._sptPos = result._orderBy = Number(os.sptPos);
                result._sptGroup = os.sptGroup;
                result._groupPos = (os.sptPos % sptGroupSize) + 1;
                result._idtDilutionDisplay = result.idtDilution === 0 ? 'C' : result.idtDilution;
                result._styleClass = {
                    'grayed-out': result.idtDilution < 0 || result.idtDilution > 99
                };

                if (result.computedEndPoint > 0) {
                    this.panel._categories.get(subst.category.id)._isPositive = true;
                }

                if (result.treat) {
                    this.$scope.isTreatable = true;
                }

                this._setDilutionGroup(result);

                // If the group was tested at SPT, we want to show this antigen in the results
                // (even if it was skipped individually)
                let isMeasuredSptGroup = groupsWithMeasuredAntigens.indexOf(os.sptGroup) != -1;
                if (isMeasuredSptGroup || result.idtDilution === 0) {
                    tmpResults.push(result);
                }
            }
        }

        // Wait until here to copy into scope, so that all the data arrives at once.
        // index.js stops listening after the first bit of results (an optimization)
        this.$scope.vmResults = tmpResults.sort((a,b) => a._orderBy - b._orderBy);
    }

    initDilutionRanges(testConfig) {

        /*
         * Compute IDT Dilution ranges
         */
        let dilutionGroups = new Map();
        for (let sptAlg of testConfig.spt) {
            let sptWheal = sptAlg.sptWhealSize;
            let dilution = sptAlg.idtDilution;
            if (sptWheal < 0 || sptWheal > 99 || dilution > 99)
                continue;
            let group = dilutionGroups.get(dilution);
            if (group === undefined) {
                group = {
                    dilution: dilution,
                    styleClass: 'dil' + dilution,
                    min: 99,
                    max: 0
                };
                dilutionGroups.set(dilution, group);
            }
            if (sptWheal < group.min)
                group.min = sptWheal;
            if (sptWheal > group.max)
                group.max = sptWheal;
        }

        this.$scope.dilutionRanges = [];
        for (let group of dilutionGroups.values()) {
            if (group.min === 0)
                group.label = this.$sce.trustAsHtml("&le; " + group.max);
            else if (group.max === 99 || group.dilution === 100)
                group.label = this.$sce.trustAsHtml("&ge; " + group.min);
            else if (group.min === group.max)
                group.label = this.$sce.trustAsHtml(group.min.toString());
            else
                group.label = this.$sce.trustAsHtml(group.min + "-" + group.max);

            this.$scope.dilutionRanges.push(group);
        }
        this.$scope.dilutionRanges.sort((a, b) => a.dilution - b.dilution);
    }

    _setDilutionGroup(measurementResult) {
        let dilutionRanges = this.$scope.dilutionRanges;
        let idtDilution = measurementResult.idtDilution;
        if (dilutionRanges.length === 0 || idtDilution < 0 || idtDilution > 99) {
            return;
        }

        if (measurementResult.sptMeasurements) {
            let measured = measurementResult.sptMeasurements;
            let dilutionRange = dilutionRanges.find(dilutionRange => measured.wheal >= dilutionRange.min && measured.wheal <= dilutionRange.max);
            // Draw dilution in highest dilution range column if there were SPT measurements but the measurementResult doesn't fit into a proper group
            measurementResult._dilutionGroup = dilutionRange ? dilutionRange.dilution : dilutionRanges[dilutionRanges.length - 1].dilution;
        }
        // Draw dilution in lowest dilution range column if there were no SPT measurements
        if (measurementResult._dilutionGroup === undefined) {
            measurementResult._dilutionGroup = dilutionRanges[0].dilution;
        }
    }

    async initData(allergyTestHref) {
        this.isClassical = undefined;
        this.combinedTestReview = undefined;

        /*
         * Initialize $scope with empty data until it can be async loaded
         */
        this.$scope.relatedActionName = 'Test';
        this.$scope.patient = { name: '', chartNum: '', dayOfBirth: '' };
        this.$scope.performedBy = { name: '', actionDateTime: '' };
        this.$scope.vitals = [];
        this.$scope.vmResults = [];
        this.$scope.antigenTableConfig = {};
        this.$scope.antigenCategories = []; // SubstanceCategory DTO + 'positive' true if any computedEndPoint > 0
        this.$scope.notes = [];
        this.$scope.doctorSelectedTreatment = this.$scope.patientSelectedTreatment = 'NONE';
        this.$scope.isCandidate = false;
        this.$scope.approvalNote = '';
        this.$scope.prescribed = undefined;
        this.$scope.enableDelayedReactions = false;
        this.$scope.approvedBy = undefined;
        this.$scope.cancelledBy = undefined;
        this.$scope.warning = undefined;
        this.$scope.isDoctor = this.userService.isDoctorUser(this.$scope.user);
        this.$scope.hasIdtLater = undefined;
        this.$scope.isIdtOnly = undefined;
        this.$scope.performedByUser = null;
        this.$scope.orderedByProvider = null;
        this.$scope.billToProvider = null;
        this.$scope.showBoardSelect = false;
        this.$scope.showPanelSelect = false;

        /*
         * Load data from server
         */
        if (this.$scope.isApproval)
            this.allergyTest = await this.allergyTestService.getForApproval({href: allergyTestHref});
        else
            this.allergyTest = await this.allergyTestService.getUncached({href: allergyTestHref});

        let allergyTest = this.allergyTest;
        this.$scope.hasIdtLater = allergyTest.idtLater;

        if (allergyTest.cancelledBy) {
            this.$scope.isApproval = false;
        }

        this.$scope.patientSelectedTreatment = allergyTest.treatmentType;
        this.$scope.doctorSelectedTreatment = allergyTest.recommendedTreatmentType ? allergyTest.recommendedTreatmentType : allergyTest.treatmentType;
        this.$scope.isCandidate = allergyTest.candidate;
        this.$scope.prescribed = allergyTest.prescribed;

        let userPromise = this.userService.getUsers(this.$scope.practice, 'NURSE').then((users) => {
           this.$scope.users = users.list;
        });

        let boardPromise = this.boardService.getAtPractice(this.$scope.practice, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS);

        if (allergyTest.approvedBy) {
            this.$scope.approvedBy = await this.userService.populateUserAction(allergyTest.approvedBy);
        }

        if (allergyTest.cancelledBy) {
            this.$scope.cancelledBy = await this.userService.populateUserAction(allergyTest.cancelledBy);
        }

        this.userService.populateUserAction(allergyTest.performedBy).then(() => {
            this.$scope.performedBy = allergyTest.performedBy;
        });

        if (allergyTest.stage === 'ABORTED' && allergyTest.performedBy.note) {
            this.$scope.warning = allergyTest.performedBy.note;
        }

        let patient = this.$scope.patient = await this.patientService.get(allergyTest.patient, allergyTest);
        let person = patient.person;
        patient.name = person.givenName + ' ' + person.middleName + ' ' + person.familyName;

        this.visit = await this.visitService.getUncached(allergyTest.visit);
        this.$scope.isIdtOnly = this.visit.procedure === 'IDT';

        // Combined SPT+IDT Test review will load here if it exists, otherwise 404 will be returned and .then will not be called.
        if (this.$scope.isIdtOnly) {
            this.testReviewService.getByTest(allergyTest).then((combinedTestReview) => {
                this.combinedTestReview = combinedTestReview;
            });
        }

        if (!this.$scope.isTestWizard) {
            this.$scope.bill = await this.billService.get(this.visit.bill);
        }

        this.populateVitals(this.visit);
        this.populateQuestionnaire(this.visit);

        let testConfigPromise = this.allergyTestConfigService.get(this.allergyTest.config);
        let appointmentPromise = this.appointmentService.get(this.visit.appointment);
        let [testConfig, appointment] = await Promise.all([testConfigPromise, appointmentPromise]);
        this.isClassical = !!testConfig.classicalSpt;
        this.appointment = appointment;

        /* Load the panel, but only if it was set, since the appointment may have been aborted */
        let panel = await this._getPanel(allergyTest);

        this.initDilutionRanges(testConfig);
        this.initTestResults(this.allergyTest);

        this.$scope.antigenCategories = [];
        if (this.panel) {
            this.panel._categories.forEach(cat => {
                if (cat._isAntigen)
                    this.$scope.antigenCategories.push(cat);
            });
        }

        await userPromise;
        this.$scope.performedByUser = this.$scope.users.find(p => p.id === allergyTest.performedBy.user.id);
        this._processAppointment();
        this._processBill();

        this.addActionNote('Test Procedure', allergyTest.performedBy, 1);
        this.addWarningNote('Histamine', allergyTest.warnings.SPT_HISTAMINE, 40, allergyTest.performedBy);
        this.addActionNote('Approved', allergyTest.approvedBy, 100);
        this.addActionNote('Cancelled', allergyTest.cancelledBy, 101);
        allergyTest.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 0));

        // Prepare the range of EndPoints. View components will need to be provided the collection values as they
        // lack the means to generate them in their given impl (Angular's ng-options constraints)
        let practiceConfig = this.$scope.practice.config;
        let dilutionsCount = Math.max(practiceConfig.idtDilutionsCount, practiceConfig.mixDilutionsCount);
        this.$scope.validEndPoints = [];
        for(let iEndPoint=0; iEndPoint <= dilutionsCount; iEndPoint++) {
            this.$scope.validEndPoints.push(iEndPoint);
        }

        /*
         * Antigen/Control results table config
         */
        this.$scope.antigenTableConfig = {
            isClassical: this.isClassical,
            sptWhealUnit: testConfig.classicalSpt && testConfig.classicalSpt.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalSpt.wheal.unit) : 'mm',
            sptErythemaUnit: testConfig.classicalSpt && testConfig.classicalSpt.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalSpt.erythema.unit) : 'mm',
            idtWhealUnit: testConfig.classicalIdt && testConfig.classicalIdt.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalIdt.wheal.unit) : 'mm',
            idtErythemaUnit: testConfig.classicalIdt && testConfig.classicalIdt.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalIdt.erythema.unit) : 'mm',

            sptHistWhealUnit: testConfig.sptHistamine && testConfig.sptHistamine.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.sptHistamine.wheal.unit) : 'mm',
            sptHistErythemaUnit: testConfig.sptHistamine && testConfig.sptHistamine.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.sptHistamine.erythema.unit) : 'mm',
            idtHistWhealUnit: testConfig.idtHistamine && testConfig.idtHistamine.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.idtHistamine.wheal.unit) : 'mm',
            idtHistErythemaUnit: testConfig.idtHistamine && testConfig.idtHistamine.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.idtHistamine.erythema.unit) : 'mm'
        };

        /*
         * Init TreatmentConfig and Panel variables
         */
        this.$scope.selectedTreatmentConfig = testConfig.classicalSpt ? [null,null,null,null,null] : [null];
        let isHistorical = !(this.$scope.isApproval || this.$scope.isTestWizard);
        allergyTest.treatmentConfigs.map((tcRef, idx) => {
            return this.treatmentConfigService.get(tcRef.treatmentConfig).then(tc => {
                this.$scope.selectedTreatmentConfig[tcRef.dilution] = tc.active || isHistorical ? tc : null;
            });
        });

        let panelBoardQueue = [];
        this.$scope.selectedPanel = null;
        if (allergyTest.mixingPanel) {
            panelBoardQueue.push(this.panelService.get(allergyTest.mixingPanel).then(panel => this.$scope.selectedPanel = panel));
        }

        this.$scope.selectedBoard = null;
        if (allergyTest.mixingBoard) {
            panelBoardQueue.push(this.boardService.getWithNoVials(allergyTest.mixingBoard).then(board => this.$scope.selectedBoard = board));
        }

        this.$scope.boards = (await boardPromise).list;
        let traditionalBoards = this.$scope.boards.filter(m => m.arrangement === this.BoardArrangement.TRADITIONAL);
        this.$scope.hasTraditionalArrangement = traditionalBoards.length > 0;
        this.$scope.boards = this.$scope.hasTraditionalArrangement ? traditionalBoards : this.$scope.boards;
        this.$scope.showBoardSelect = !this.isClassical || this.$scope.hasTraditionalArrangement;
        this.$scope.showPanelSelect = !this.$scope.showBoardSelect;

        /* Get panels and set selectedPanel if not yet set */
        let allPanelsPromise = this.$q.all(panelBoardQueue).then(() => {
            return this.panelService.getActiveAtPractice(this.$scope.practice)
                .then(panels => {
                    this.$scope.panels = panels.list.filter(m => m.type === 'MIXING');
                    this._setPanelFromBoard(this.$scope.panels);
                });
        });

        if (this.$scope.isApproval || this.$scope.isTestWizard) {
            let treatmentConfigs = (await this.treatmentConfigService.getAtPractice(this.$scope.practice)).list;
            this.$scope.treatmentConfigs = treatmentConfigs.filter(config => config.active);

            // Initialize default treatment config and mixing board, if applicable
            if (this.$scope.doctorSelectedTreatment !== 'NONE') {
                let relevantConfigs = this.$scope.treatmentConfigs.filter(m => m.treatmentType === this.$scope.doctorSelectedTreatment);
                if (relevantConfigs.length === 1 && allergyTest.treatmentConfigs.length === 0) {
                    this.$scope.selectedTreatmentConfig[0] = relevantConfigs[0];
                }

                if (this.$scope.boards.length === 1) {
                    this.$scope.selectedBoard = this.$scope.boards[0];
                    await allPanelsPromise;
                    this._setPanelFromBoard(this.$scope.panels);
                }
            }
        }

        this.$scope.$digest();
    }

    _processAppointment() {
        if (this.appointment.provider) {
            this.$scope.orderedByProvider = this.$scope.users.find(p => p.id === this.appointment.provider.id);
        }
    }

    _processBill() {
        if (this.$scope.bill && this.$scope.bill.provider) {
            this.$scope.billToProvider = this.$scope.users.find(p => p.id === this.$scope.bill.provider.id);
        }
    }

    _setPanelFromBoard(panels) {
         if (this.$scope.selectedBoard && !this.$scope.selectedPanel) {
            this.$scope.selectedPanel = panels.find(m => m.id === this.$scope.selectedBoard.panel.id);
        }
    }

    async _getPanel(allergyTest) {
        this.panel = null;

        if (this.$scope.isIdtOnly) {
            if (this.appointment.panel) {
                this.panel = await this.panelService.get(this.appointment.panel);
            }
        } else if (allergyTest.sptBoard) {
            let board = await this.boardService.getWithNoVials(allergyTest.sptBoard);
            this.panel = await this.panelService.get(board.panel);
            this.panelService.populateSptGroupNames(this.$scope.practice, this.panel);
        }

        return this.panel;
    }

    _isTreatmentConfigDisabled(index) {
        if (this.$scope.hasTraditionalArrangement) {
            let populatedIndex = this.$scope.selectedTreatmentConfig.findIndex(m => m !== null);
            return populatedIndex >= 0 && populatedIndex !== index;
        }
        else {
            return index > 0 && this.$scope.selectedTreatmentConfig[index-1] == null;
        }
    }

    /**
     * Returns true if the configuration for approving a test and starting a prescription
     * prescription is valid, false otherwise
     */
    _canStartPrescription() {
        return this.$scope.isCandidate
            && this.$scope.isTreatable
            && this.$scope.doctorSelectedTreatment !== 'NONE'
            && this.$scope.selectedTreatmentConfig
            && this.$scope.selectedTreatmentConfig.some(m => m !== null)
            && this.$scope.selectedPanel !== undefined
            && this.$scope.selectedPanel !== null
            && this.$scope.performedByUser
            && this.$scope.orderedByProvider
            && !this.$scope.saving;
    }

    async _cancelIdtOrderModal(createRx) {
        let modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('./widgets/confirm-cancel-idt-modal.html'),
            controller: function ($uibModalInstance, $scope) {
                $scope.continue = () => $uibModalInstance.close();
                $scope.cancel = () => $uibModalInstance.dismiss();
                if (createRx) {
                    $scope.title = "Create Prescription?";
                    $scope.message = "Are you sure you would like to create a prescription from the skin prick results? Creating this prescription will cancel the previously ordered intradermal test.";
                }
                else {
                    $scope.title = "Confirm No Prescription?"
                    $scope.message = "Are you sure you want to make the decision not to create a prescription now? Continuing will cancel the previously ordered intradermal test.";
                }
            }
        });

        return modalInstance.result.then(() => true).catch(() => false);
    }

    /**
     * @param {boolean} createRx
     * @private
     */
    async _approveAllergyTest(createRx, cancelIdtOrder) {
        this.$scope.saving = true;

        try {
            await this._saveAllergyTest(false);
        }
        catch(error) {
            this.$scope.saving = false;
            return;
        }

        if (cancelIdtOrder) {
            let cancelConfirmed = await this._cancelIdtOrderModal(createRx);
            if (!cancelConfirmed) {
                this.$scope.saving = false;
                return;
            }
        }

        try {
            /* Approve the allergy test */
            let prescribeTreatment = (createRx ? this.allergyTest.recommendedTreatmentType : 'NONE');
            await this.allergyTestService.approve(this.allergyTest, this.$scope.approvalNote, prescribeTreatment);
        }
        catch(error) {
            this.$scope.saving = false;
            return;
        }

        /* Cancel the IDT appointment created by this appointment, if applicable */
        if (cancelIdtOrder) {
            try {
                let appt = await this.appointmentService.get(this.visit.appointment);
                let followUps = await this.appointmentService.getFollowUps(appt, ['IDT']);
                for(let followUp of followUps.list) {
                    this.appointmentService.cancel(followUp, 'IDT was cancelled');
                }
            }
            catch(error) {
                this.$scope.saving = false;
                return;
            }
        }

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback();
            }
        });
    }

    /**
     * Saved modified allergy test to server and exit.
     * @returns {Promise<void>}
     * @private
     */
    async _saveAndExit() {
        this.$scope.saving = true;
        await this._saveAllergyTest(true);

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback({allergyTest: this.allergyTest});
            }
        });
    }

    /**
     * Create a combined SPT+IDT test review and navigate to the review page.
     * @private
     */
    _createTestReview() {
        if (!this.$scope.isIdtOnly)
            return;

        let promise = this.$q.resolve();
        if (!this.combinedTestReview) {
            this.$scope.saving = true;

            /* Set to empty object here in case the user calls _createTestReview more than once before the test review is created */
            this.combinedTestReview = {};

            /* Update the allergy test, then approve, then create the combined test review */
            promise = this._saveAllergyTest(false)
                .then((allergyTest) => {
                    this.allergyTest = allergyTest;
                    let prescribedTreatment = 'NONE';
                    return this.allergyTestService.approve(this.allergyTest, this.$scope.approvalNote, prescribedTreatment);
                }).then(() => {
                   return this.testReviewService.create(this.allergyTest);
                }).then((combinedTestReview) => {
                    this.combinedTestReview = combinedTestReview;
                }).catch((error) => {
                    console.log(error);
                    this.$scope.saving = false;
                });
        }

        promise.then(() => {
            this.$scope.saving = false;
            this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_TEST_REVIEW, this.combinedTestReview);
        });
    }

    /**
     * Update this.allergyTest with changes in $scope.
     * Helper for _approveAllergyTest() and _saveAndExit()
     * @param saveNote if true, save the approval note as an other action
     * @private
     */
    _saveAllergyTest(saveNote) {
        let q = [];

        if (this.$scope.bill && this.$scope.bill._dirty) {
            this.$scope.bill.provider = this._createRef(this.$scope.billToProvider);
            q.push(this.billService.update(this.$scope.bill).then(bill => {
                this.$scope.bill = bill;
                this._processBill();
            }));
        }

        if (this.$scope.orderedByProvider.id !== this.appointment.provider.id) {
            this.appointment.provider = this._createRef(this.$scope.orderedByProvider);
            q.push(this.appointmentService.update(this.appointment).then(appointment => {
                this.appointment = appointment;
                this._processAppointment();
            }));
        }

        this.allergyTest.candidate = this.$scope.isCandidate;
        this.allergyTest.recommendedTreatmentType = (this.$scope.isCandidate ? this.$scope.doctorSelectedTreatment : 'NONE');
        this.allergyTest.treatmentConfigs = this.$scope.selectedTreatmentConfig
            .map((tc,i) => {
                return tc ? { treatmentConfig: { id: tc.id }, dilution: i } : null;
            })
            .filter(tc => tc != null);
        this.allergyTest.mixingPanel = (this.$scope.selectedPanel ? { id: this.$scope.selectedPanel.id } : null);
        this.allergyTest.mixingBoard = (this.$scope.selectedBoard ? { id : this.$scope.selectedBoard.id } : null);
        this.allergyTest.performedBy.user = this._createRef(this.$scope.performedByUser);

        if (saveNote && this.$scope.approvalNote) {
            let otherAction = { note: this.$scope.approvalNote };
            this.allergyTest.otherActions.push(otherAction);
        }

        return this.$q.all(q)
            .then(() => this.allergyTestService.update(this.allergyTest))
            .then(allergyTest => {
                this.allergyTest = allergyTest;
                this.initTestResults(this.allergyTest);
                return this.allergyTest;
            });
    }

    _createRef(obj) {
        return obj ? { id: obj.id, href: obj.href } : null;
    }

    /**
     * Update this.$scope.isTreatable to true or false depending on whether or not any antigens
     * have the "Prescription" checkbox checked
     * @private
     */
    _updateTreatable() {
        this.$scope.isTreatable = false;
        for(let result of this.$scope.vmResults) {
            if (result.treat) {
                this.$scope.isTreatable = true;
            }
        }
    }

    /**
     * User has edited some delayed reactions to antigens and wishes to save the results.
     * @private
     */
    async _saveDelayedReactions() {
        this.$scope.enableDelayedReactions = false;
        let allergyTest = await this.allergyTestService.update( this.allergyTest );

        // Have to reload, because we need to use the new content of allergyTest, but this controller
        // adds new fields to its data that is not in the new object we just received from the server.
        this.initData(allergyTest.href);
    }
}
