"use strict";

import angular from "angular";
import uiSectionNavigationItemWidget from "./section-navigation-item/view";

export default angular.module("pages.inventory.widgets",[])
    .name;

function initView(sectionTypes) {
    return new uiSectionNavigationItemWidget(sectionTypes);
}
initView.$inject = ["InventorySections"];

