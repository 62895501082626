"use strict";

import angular from "angular";

import AlertIconPosition from "./alertIconPosition";
import AlertSeverity from "./alertSeverity";
import BoardArrangement from "./boardArrangement";
import ConcentrateUseType from "./concentrateUseType";
import ExternalResourceType from './externalResourceType';
import NotificationAction from "./notificationAction";
import NotificationType from "./notificationType";
import PhoneContext from "./phone-context";
import PrescriptionReason from './prescriptionReason';
import Procedure from "./procedure";
import ServiceStatus from "./serviceStatus";
import Station from "./station";
import TreatmentStage from "./treatmentStage";
import TreatmentType from "./treatmentType";
import TreatmentVialReasonCode from "./treatmentVialReasonCode";
import Unapproved from "./unapproved";
import UrlPaths from "./url-paths";

/**
 * Application Client model layer
 *
 * This module covers data-model object representations for the client-side of the application. Data-Model values
 * are currently read and handled as partially deserialized remote data-service acquired values. Some of these
 * data-values are unchanging declarations and configurations, while others are frequently mutating.
 *
 * If time permits, a comprehensive set of POJO like structures which more or less reflects the remote data
 * service API is ideal. In practice, we probably won't have the chronologic resources allocated to pull it
 * off.
 *
 * However, constant definitions (typically enumerations and resource/configuration mappings) are less numerous, and
 * the role they play in implementation give them a "more-bang-for-less-buck" case.
 */
export default angular.module('models',[
    AlertIconPosition,
    AlertSeverity,
    BoardArrangement,
    ConcentrateUseType,
    ExternalResourceType,
    NotificationAction,
    NotificationType,
    PhoneContext,
    PrescriptionReason,
    Procedure,
    ServiceStatus,
    Station,
    TreatmentStage,
    TreatmentType,
    TreatmentVialReasonCode,
    Unapproved,
    UrlPaths
]).name;

