'use strict';

import BaseController from './../base.controller.js';

import * as View from './users-view';

/**
 * Right now we only have one Settings controller, so this is okay. As soon as we add a second 'tab' though,
 * this will need to be organized more like Dashboard and Inventory.
 */
export default class UserSettingsController extends BaseController {

    // Member variables
    $scope;
    $timeout;

    static $inject = ['$scope', '$injector', '$timeout'];
    constructor($scope, $injector, $timeout) {
        super($scope, $injector);

        this.$scope = $scope;
        this.$timeout = $timeout;

        $scope.users = new View.UsersTable();
        this._populateView();
    }

    /**
     * Populate the view.
     * @private
     */
    _populateView() {
        this.userService.getAll()
            .then((userList) => {
                this.$scope.users.rowCollection = [];
                for (let user of userList.list)
                    this.$scope.users.rowCollection.push(new View.UserRow(this, user));
            });
    }

    /**
     * Disable a user.
     * @param userRef
     */
    async disableUser(userRef) {
        let user = await this.userService.getUncached(userRef);

        if (user.access === 'NORMAL') {
            user.access = 'DISABLED';
            user = await this.userService.update(user);
        }

        this._updateView(user);
        this.$scope.$digest();
    }

    /**
     * Enable a user.
     * @param userRef
     */
    async enableUser(userRef) {
        let user = await this.userService.getUncached(userRef);

        if (user.access === 'DISABLED') {
            user.access = 'NORMAL';
            user = await this.userService.update(user);
        }

        this._updateView(user);
        this.$scope.$digest();
    }

    /**
     * Send a user a password reset e-mail.
     * @param userRef
     */
    async resetPassword(userRef) {
        let user = await this.userService.get(userRef);
        await this.userService.forgotPassword(user.email);
        this._updateView(user);
        this.$scope.$digest();

        // Display success message modal and auto-close
        let modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('./password-reset-modal.html'),
            css: require('./password-reset-modal.scss'),
        });

        this.$timeout(() => modalInstance.dismiss(), 2500);
    }

    /**
     * Update the view with new User information.
     * @param user
     * @private
     */
    _updateView(user) {
        // Find the user's row & update it
        let rows = this.$scope.users.rowCollection;
        for (let rowIdx = 0; rowIdx < rows.length; ++rowIdx) {

            if (rows[rowIdx].userRef.id === user.id) {
                rows[rowIdx].reset(user);
                return;
            }
        }
    }
}
