"use strict";

import angular from 'angular';
import controller from "./sp-wells.controller.js";

export default angular.module('pages.inventory.sections.wells', [])
    .controller('SpWellsSectionController', controller)
    .directive('spWellsTable', [spWellsTable])
    .name;

function spWellsTable(){
    return {
        scope : {
            showFilter: "=",
            rowCollection: '=',
            rowPickHandler : '&'
        },
        template: require('./sp-wells-table.html'),
        css: require('./sp-wells-table.scss'),
        link: function ($scope, element, attrs) {
            $scope.onRowClick = (listEntryModel) => $scope.rowPickHandler({ "listItem" : listEntryModel});
            $scope.printDate = new Date();
        }
    };
}
