'use strict';

import BaseService from './base.service.js';

/**
 * Service for managing Attachments.
 *
 * Note:
 * - To download the content of an attachment, just have an <a href='{attachmentDTO._links.download}'/>
 * - To view a patient's photo thumbnail, which is also an attachment, just get that from the patient:
 *     <img src="{patient._links.photoThumbnail}">
 * - To set a patient's photo based on an attachment, simply set patientDTO.photo.id = attachmentDTO.id and call PatientService.update(patientDTO).
 */
export default class AttachmentService extends BaseService {

    /**
     * GET all attachments to a patient.
     *
     * @param patient to get attachments for
     * @return Promise to an Attachment.List DTO (unsorted)
     */
    getForPatient(patient) {
        return this.serverAPI.get(patient._links.attachments);
    }

    /**
     * Create a new Attachment for a Patient.
     *
     * @param patient patient to make the appointment for
     * @param file a scope variable from something like <input type = "file" file-model = "file"/>
     * @param displayType Display-friendly type of the file.
     * @return Promise to the created Attachment DTO
     */
    createForPatient(patient, file, displayType) {
        let fd = new FormData();
        fd.append('file', file);
        fd.append('displayType', displayType);
        return this.serverAPI.post(patient._links.attachments, {}, fd);
    }
}
