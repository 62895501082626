'use strict';

import BaseService from './base.service.js';
import moment from "moment-timezone";

export default class OfficeService extends BaseService {

    /** getInPractice is called upon every page load, doesn't use the browser's cache, and yet almost never changes */
    officeListCache = {
        /** practice.href of the cached list. Href includes the ID and version, so it'll change when practice changes */
        practiceHref: null,
        /** {Office.List} last returned - excludes inactive Offices */
        officeList: null
    };

    static $inject=['serverAPI'];
    constructor(serverAPI) {
        super(serverAPI);
    }

        /**
     * Get a list of Offices in the User's practice.
     *
     * @param practice the Practice DTO whos offices to fetch
     * @param includingInactive (optional) If true, don't filter out inactive offices.
     * @return Promise to an Office.List, unsorted
     */
    getInPractice(practice, includingInactive) {
        if (!includingInactive && practice.href === this.officeListCache.practiceHref) {
            // Return copy, in case the client messes with content.
            return this.resolved(angular.copy(this.officeListCache.officeList));
        }
        else {
            return this.serverAPI.get(practice._links.offices)
                .then(officeList => {
                    if (includingInactive !== true) {
                        officeList.list = officeList.list.filter(office => office.active);
                        this.officeListCache.officeList = officeList;
                        this.officeListCache.practiceHref = practice.href;
                    }

                    return officeList;
                });
        }
    }

    /**
     * Select this Office as the current one being used by the User.
     * (Update's the authenticated user's User.office reference.)
     *
     * @return a Promise that will return success without content, or ServerError DTO upon failure.
     */
    select(office) {
        return this.serverAPI.put(office._links.select);
    }

    /**
     * Create a new Office in a Practice. (Super-admin only.)
     *
     * @param practice to add the Office to
     * @param office new Office DTO
     * @returns Promise to the created Office DTO
     */
    create(practice, office) {
        return this.serverAPI.post(practice._links.offices, {}, office);
    }

    /**
     * Get a list of unapproved AllergyTests, Prescriptions, and Treatments pending for this Office.
     *
     * @param office {Office} the Office to retrieve approvals for
     * @param cancelledOnly if true, only retrieve cancelled approvals
     * @return Promise to an Unapproved.List, unsorted.
     */
    getUnapproved(office, cancelledOnly) {
        let params = {};
        if (cancelledOnly) {
            params.cancelledOnly = cancelledOnly;
        }
        
        return this.serverAPI.get(office._links.unapproved, params);
    }

    /**
     * Send an EntityLockEvent to other browsers accessing this Office
     *
     * @param office {Office} Office context in which to send the event.
     * @param event {EntityLockEvent}
     */
    sendEntityLockEvent(office, event) {
        return this.serverAPI.put(office._links.entityLock, {}, event);
    }
}
