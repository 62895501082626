'use strict';

/**
 * Base class for services that return immutable DTOs.
 * Content is loaded eagerly and cached.
 */
export default class StronglyCachedDtoService {

    /** API to the server */
    _serverAPI;

    /** {string} field name in GlobalLinks used to load all the content to cache. */
    _globalLinkField;

    /** Set when a reload is in progress, completed when done initializing. */
    _initPromise = null;

    /** {EmbeddedListDTO} The full loaded list of DTOs */
    _dtoList = null;

    /** {Map<id,DTO>} The DTOs mapped by their IDs. */
    _dtoCache = null;


    //static $inject = ['serverAPI'];
    constructor(serverAPI, globalLinkField) {
        this._serverAPI = serverAPI;
        this._globalLinkField = globalLinkField;
    }

    /**
     * Get a DTO by its ReferenceDTO.
     *
     * @param ref ReferenceDTO to a DTO type
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     */
    get(ref) {
        return this._init()
            .then(() => {
                let dto = this._dtoCache.get(ref.id);

                if (dto)
                    return angular.copy(dto);
                else
                    throw {status:404, errors: [ref.id+' not found']};
            });
    }

    /**
     * Get all DTOs.
     *
     * @return Promise to DTO.List (unordered)
     */
    getAll() {
        return this._init()
            .then(() => {
                return angular.copy(this._dtoList);
            });
    }

    /**
     * Initialize cache, if not already available.
     * @return {Promise} that resolves when the cache is ready.
     * @private
     */
    _init() {
        if (this._initPromise)
            return this._initPromise;

        this._dtoCache = null;
        this._dtoList = null;

        this._initPromise = this._serverAPI.globalLinksPromise
            .then(globalLinks => {
                let href = globalLinks[this._globalLinkField];
                return this._serverAPI.get(href);
            })
            .then(dtoList => {
                this._dtoList = dtoList;
                this._dtoCache = new Map();
                dtoList.list.forEach(dto => this._dtoCache.set(dto.id, dto));

                // Transform values in place - results reflected in _dtoList and _dtoCache.
                let transformations = dtoList.list.map(dto => this._transform(dto));
                return this._serverAPI.$q.all(transformations);
            });

        return this._initPromise;
    }

    /**
     * Hook for sub-classes to transform a DTO from the server into client data model.
     * Normally this will modify the DTO with additional meta-data.
     *
     * @param dto
     * @return {Promise} to the dto received - after being modified.
     */
    _transform(dto) {
        return this._serverAPI.$q.resolve(dto);
    }
}
