"use strict";

import BaseController from "../../../../base.controller.js";
import EditWellController from "../../../widgets/edit-board-modal/edit-well.controller";
import ClearInventoryAlertController from '../../../widgets/clear-inventory-alert-modal/controller';

import React from 'react'
import { submitPrintJob } from '../../../../../react/print/Printer'
import { PRINTER_DYMO30336_LANDSCAPE, TrayLabel } from "../../../../../react/print/Dymo30336Landscape"

export default class BoardDetailsController extends BaseController {

    /** @type{Map.<String, Object>} : concentrate-ID to DM */
    _concentrateMap = new Map();

    /** @type{Board} */
    _subjectBoard;

    static ALL_TRAYS_KEY = "ALL";
    static $inject = ["$scope", "$injector","$filter"];

    constructor($scope, $injector, $filter) {
        super($scope, $injector);

        this.$filter = $filter;
        this.$q = $injector.get('$q');
        this.$uibModal = $injector.get('$uibModal');
        this.Procedure = $injector.get("Procedure");
        this.boardSvc = $injector.get("boardService");
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.concentrateSvc = $injector.get("concentrateService");
        this.eligibleStatusService = $injector.get("eligibleStatusService");
        this.notificationType = $injector.get('NotificationType');
        this.panelSvc = $injector.get("panelService");
        this.globalConfigService = $injector.get('globalConfigService');

        this.$scope.ServiceStatus = this.ServiceStatus = $injector.get("ServiceStatus");
        this.$scope.ALL_TRAYS_KEY = BoardDetailsController.ALL_TRAYS_KEY;

        /**
         * @type{Array.<{ Number || String }>}
         * a list of logical tray filtering configurations the UI can display. Each value defines a UI state
         * where the st-board-table displays either tray identified or all of the trays (special value
         * BoardDetailsController.ALL_TRAYS_KEY).
         *
         * More constraints::
         * forall entries x, when x is numeric: 1 <= x <= n, where is the number of assoc trays.
         * When x is numeric, x must be an integer.
         * When x is a string, x must equal BoardDetailsController.ALL_TRAYS_KEY
         */
        this.$scope.filteredByTrayStates = undefined;

        /**
         * @type {Array.<{{TrayVM}}>}
         * Foreach logical tray associated with the subject, we'll have a VM-object.
         */
        this.$scope.trayDetails = undefined;

        /**
         * @type {BoardModelObject}
         * a copy of the raw serialized DM of the instance subject. We cache this copy, not for UI support,
         * but for DM building. The backend uses HATEOUS style impl, so progenitor models actively help
         * make DM request efficient. Hence, we'll keep this around to support Tray-Table UI building.
         *
         */
        this._subjectBoard = undefined;

        /**
         * Display historical SP well vials?
         */
        this.showHistoricalVials = false;

        // UI-Event-Reactionary Predicates
        $scope.printBarcodeModal = (data, board) => this.printBarcodeModal(data, board);

        $scope.onTrayTabClick =(tabKey)=> {
            if (tabKey == BoardDetailsController.ALL_TRAYS_KEY)
                this.showAllTrays();
            else
                this.showTrayExclusively(tabKey);
        };
        $scope.editBoardModal = (arg) => this.editBoardModal();
        $scope.editTrayVialModal = (arg) => this.editTrayVialModal(arg);
        $scope.clearInventoryAlertModal = (subjVial) => this.clearInventoryAlertModal(subjVial);

        $scope.onStatusChange =()=> {
            this._updateSubjectStatus(this.$scope.boardDetails.status);
        };
        $scope.toConcentrateDetails = (subjVial) => this._navigateToConcentrateDetails(subjVial);

        $scope.toggleHistoricalVials = () => this._toggleHistoricalVials();

            // From UI R&D impl
        $scope.currentTab = BoardDetailsController.ALL_TRAYS_KEY;

        let routeParams = this.getRouteParams();
        if (!routeParams || !routeParams.href) {
            console.error("Failed to load SP-Wells details due to missing route params.");
            this.routeToPage(this.urlPaths.INVENTORY_WELLS);
        }
        else {
            this.$scope.fromLocation = this.routingService.extractLocationParams(routeParams, `#${this.urlPaths.INVENTORY_WELLS}`, 'SP Well Board List');
            this._loadDataModel({ href : routeParams.href });
            this.inventoryAlertService.subscribe(this.$scope, alertSummary => this._updateTrayVialAlerts(alertSummary));
        }

    };

    /**
     * Acquires the SP Well Board DM state (the current instance's eigenValue)
     *
     * @param {HATEfulReference} subjHateRef : an opaque HATEOAS reference to current subject model
     *
     * @private
     */
    async _loadDataModel(subjHateRef) {
        this.$scope.loadingBoard = true;

        await this.notificationService.init();
        this.unsubscribeAllSubscriptions();

        let myBoard = null;
        if (this.showHistoricalVials) {
            myBoard = await this.boardSvc.getWithAllVials(subjHateRef);
        }
        else {
            myBoard = await this.boardSvc.getWithCurrentVials(subjHateRef);
        }

        this._subscribeToStatusChangeNotifs(myBoard);
        this._subjectBoard = myBoard;

        let myPanel = await this.panelSvc.get(myBoard.panel, myBoard);
        this.panelSvc.populateSptGroupNames(this.$scope.practice, myPanel);
        this.$scope.boardDetails = this._coreDmToVm(myBoard,myPanel);

        await this._loadTrayTableau(myBoard, myPanel);
        await this._loadServiceStatusValues();

        this.startAllSubscriptions();
        this.inventoryAlertService.sendTo(this.$scope);
        this.$scope.loadingBoard = false;

        this.$scope.$digest();
    }

    /**
     * Acquires DM state of Trays associated with the subject Well-Board.
     * Front load this acquisition, then just filter what is shown.
     *
     * @private
     */
    async _loadTrayTableau(board, myPanel) {

        /** @type{Map.<Integer,Array.<VialModelObject>>} */
        let trayVialsMap = new Map();
        this.$scope.trayDetails = [];
        this._concentrateMap.clear();

        for (let aVial of board.vials) {
            if (!trayVialsMap.has(aVial.trayNum)) {
                trayVialsMap.set(aVial.trayNum, []);
            }
            trayVialsMap.get(aVial.trayNum).push(aVial);

            const substance = myPanel._substances.get(aVial.substance.id);
            let trayVm = await this._trayDmtoVm(aVial, substance);
            this.$scope.trayDetails.push(trayVm);
        }

        this._sortAntigenTrays();

        /** @type{Array.<Integer>}*/
        this.$scope.filteredByTrayStates = [];

        for (let aTrayIndex of trayVialsMap.keys()) {
            this.$scope.filteredByTrayStates.push(aTrayIndex);
        }

    }

    /**
     * Guidance for sorting taken from: https://nbdevs.atlassian.net/browse/AAW-822
     * For the SP Well Board Antigen Listing (screen 1105), the table should be automatically sorted by Tray #, then
     * Antigen #.
     *
     * @private
     */
    _sortAntigenTrays() {
        this.$scope.trayDetails =
            this.$filter("orderBy")(this.$scope.trayDetails, [ "tray", "antigen" ], false);
    }

    async _loadServiceStatusValues() {
        this.$scope.eligibleStatusTypes =
            await this.eligibleStatusService.getEligibleStatusTypesForBoard(this._subjectBoard);
    }


    /**
     * TARGET VIEW component ==> st-board-table + shared scope == Instant Spaghetti!!!
     *
     * @param dm {TrayVial} a vial on the board
     * @param substance {Substance} that is in the TrayVial
     * @returns {{tray: *, antigen: *, description: *, barcode: *, status: *, inServiceDate: (Date|undefined)}}
     * @private
     */
    async _trayDmtoVm(dm, substance) {

        let assocConcentrate;
        if (!this._concentrateMap.has(dm.concentrate.id)) {
            // 1st arg is HATE-reference to value we need. 2nd arg is parent value ISO request optimization.
            assocConcentrate = await this.concentrateSvc.get(dm.concentrate, this._subjectBoard);
            this._concentrateMap.set(dm.concentrate.id, assocConcentrate);
        }
        else {
            assocConcentrate = this._concentrateMap.get(dm.concentrate.id);
        }

        let vm = {
            // Tray Index : [1,2,3,...]
            "tray" : dm.trayNum,
            "antigen" : dm.boardColumn + 1,
            "sptGroup" : substance.sptGroup,
            // Description - substance.name : dm has a "substance", dm.substance has a _dto, w/ "name"
            "description": dm.substance._dto.name,
            // Barcode # - concentrate.barcode : dm has a "concentrate", but it's just HATE-ref...
            "barcode" : assocConcentrate.barcode,
            "vialStatus" : dm.status,
            "inServiceDate" : dm.startService,
            "concStatus" : assocConcentrate.status,
            "concInServiceDate" : assocConcentrate.startService,
            "concEndServiceDate" : assocConcentrate.endService,
            "alert" : undefined,
            "_vialDm" : dm,
            "current" : dm.current
        };

        return vm;
    }

    /**
     * Update alerts on each TrayVial in the view according to the summary from InventoryAlertService.
     *
     * @param alertSummary see InventoryAlertService
     * @private
     */
    _updateTrayVialAlerts(alertSummary) {
        if (this.$scope.trayDetails) {
            for (let vial of this.$scope.trayDetails) {
                if (!vial._alertDismissed) {
                    vial.alert = alertSummary.icons.get(vial._vialDm.id);
                }
            }

            this.$scope.boardAlert = alertSummary.icons.get(this._subjectBoard.id);
        }
    }

    /**
     * This routine maps the subject board's DM to VM representation. Note, it's the subject-proper only. The Trays
     * Tableau are NOT covered in this routine.
     *
     * @private
     */
    _coreDmToVm(boardDm, panelDm) {

        let vm = {
            "boardName": boardDm.name,
            "panelName": panelDm.name,
            "totalTrays" : boardDm.trayCount,
            "distinctAntigenCount" : 0,
            "status" : boardDm.status,
            "dateCreated" : this.chronologyMappingService.utcToTimezone(boardDm.createdDateTime, this.$scope.office.timezone),
            "inServiceDate" : boardDm.startService,
            "barcode": boardDm.barcode,
            "endServiceDate" : boardDm.endService
        };

        panelDm.substances.forEach( aSubstance => {
            let theSubstanceCategory = aSubstance.substance._dto.category._dto;

            if (theSubstanceCategory._isAntigen) {
                vm.distinctAntigenCount++;
            }
        });

        return vm;
    }

    /**
     * @param {ServiceStatus} newStatus
     *  The ServiceStatus member value this subject model is assigning to its *status* field.
     * @private
     */
    async _updateSubjectStatus(newStatus) {

        this._subjectBoard.status = newStatus;
        let thePreviousHref = this._subjectBoard.href;
        let updatedSubjectBoard = await this.boardSvc.update(this._subjectBoard);

        if (updatedSubjectBoard.href === thePreviousHref) {
            // Then the model is stable; no action necessary.
            // How can this occur, in a non-problematic case? User clicked 'Save' w/out changing anything.
        }
        else {
            await this._loadDataModel(updatedSubjectBoard);
        }

    }

    /**
     * @param {com.audigygroup.allergy.core.dto.Notification} notif
     * @private
     */
    async _onBoardStatusChange(notif) {

        if (this._subjectBoard.version !== notif.body.version) {
            await this._loadDataModel(this._subjectBoard);
        }
    }

    /**
     * @param {com.audigygroup.allergy.core.dto.Board} subjectBoard
     * @private
     */
    _subscribeToStatusChangeNotifs(subjectBoard) {

        this.registerSubscription(this.notificationService.subscribeBoardUpdates(this.$scope.practice, subjectBoard))
            .then(null, null, (notification) => this._onBoardStatusChange(notification));

    }

    /**
     * @param {{ id: String, href: URL }} concentrate
     * Serialized reference to the Concentrate of interest;
     * AKA the subject of the destination
     *
     * @private
     */
    _navigateToConcentrateDetails(concentrate) {
        this.routeToPage(this.urlPaths.INVENTORY_CONCENTRATE_DETAILS, concentrate);
    }

    _toggleHistoricalVials() {
        this.showHistoricalVials = !this.showHistoricalVials;
        this._loadDataModel(this._subjectBoard);
    }

    showAllTrays() {
        this.$scope.currentTab = BoardDetailsController.ALL_TRAYS_KEY;

        let trayFilterElem = angular.element($('#tray'));
        trayFilterElem.val("");
        trayFilterElem.triggerHandler('input');
    }

    showTrayExclusively(u) {
        this.$scope.currentTab = u;

        let trayFilterElem = angular.element($('#tray'));
        trayFilterElem.val(u);
        trayFilterElem.triggerHandler('input');
    }

    replaceTraySubstance(trayVial, sourceVial) {

        const oldVial = trayVial._vialDm;
        let replacedConcentrateStatus = oldVial.status;
        if (replacedConcentrateStatus !== this.ServiceStatus.EXPIRED && replacedConcentrateStatus !== this.ServiceStatus.RECALLED) {
            replacedConcentrateStatus = this.ServiceStatus.DEPLETED;
        }

        this.boardSvc.replaceVial(
            this._subjectBoard,
            oldVial,
            replacedConcentrateStatus,
            sourceVial.id,
            this.boardSvc.CURRENT_VIALS
        ).then( updatedBoard => this._loadDataModel(updatedBoard) );
    }

    editBoardModal() {

        let editorModal = this.$uibModal.open({
            windowClass: 'addBoards',
            scope: this.$scope, //passed current scope to the modal
            template: require("../../../widgets/edit-board-modal/layout.html"),
            css: require("../../../widgets/edit-board-modal/styles.scss"),
            controller: EditWellController,
            resolve: {
                "subjectBoard" : ()=> this._subjectBoard
            }
        });

        editorModal.result.then(
            updatedModel =>
                this.routeToPage(this.urlPaths.INVENTORY_WELL_DETAILS, updatedModel));
    }

    editTrayVialModal(subjWell) {
        const parent = this;
        if (this._allowAutoBarcode === undefined) {
            this.globalConfigService.get().then((config) => {
                this._allowAutoBarcode = config.allowAutoBarcode;
            });
        }

        this.$scope.availableSources = [];
        this.$scope.selectedSource = null;

        const subjWellDm = subjWell._vialDm;
        const subjSubstance = subjWellDm.substance;
        this.concentrateSvc.getInServiceSubstanceAtOffice(
            /** {OfficeDataModelReference} */this.$scope.office,
            /** SubstanceDataModel */subjSubstance)
            .then( /** {{ list : {Array.<{ConcentrateDataModel}>} }} */inServiceConcentrates => {

                for (const conc of inServiceConcentrates.list) {
                    this.$scope.availableSources.push(conc);
                    console.log("Available source concentrate barcode " + conc.barcode);
                }
            });


        let editorModal = this.$uibModal.open({
            windowClass: 'editAntigen',
            scope: this.$scope, //passed current scope to the modal
            template: require('./edit-tray-vial-modal.html'),
            css: require('./edit-tray-vial-modal.scss'),
            controller: function ($uibModalInstance, $scope, subjectWell) {

                // Pregame predicates
                $scope.cancel = () => $uibModalInstance.dismiss();
                $scope.onSubmit = () => $uibModalInstance.close($scope.selectedSource);

                $scope.scanBarcodeKeydown = (event) => {
                    if (event.which === 13) {
                        event.preventDefault();
                        $scope.scanBarcode();
                    }
                };

                $scope.scanBarcode = () => {
                    const barcodeInput = $scope.barcodeInput;
                    $scope.barcodeError = null;
                    console.log("Barcode input '" + barcodeInput + "'");
                    if (!barcodeInput) {
                        // No input
                        return;
                    }

                    // Auto-fill all selections for dev & QA
                    if (barcodeInput === '=' && parent._allowAutoBarcode) {
                        $scope.selectedSource = $scope.availableSources[0];
                    }
                    else if (barcodeInput.length !== 8) {
                        $scope.barcodeError = "Invalid barcode: " + barcodeInput;
                        $scope.selectedSource = null;
                    }
                    else {
                        // Find match
                        $scope.selectedSource = $scope.availableSources.find(v => v.barcode === barcodeInput);
                        if (!$scope.selectedSource) {
                            $scope.barcodeError = "Source vial " + barcodeInput + " not available.";
                        }
                    }

                    $scope.barcodeInput = '';
                };

                // Pregame object states
                $scope.vm = subjectWell;
                $scope.isApplicableConcentrateAvailable = false;
            },
            resolve: {
                'subjectWell' : ()=> subjWell
            }
        });


        editorModal.result.then(selectedSourceConcentrate =>
            this.replaceTraySubstance(subjWell, selectedSourceConcentrate));
    }

    clearInventoryAlertModal(subjWell) {
        if (!subjWell.alert) return;

        let confirmModal = this.$uibModal.open({
            windowClass: 'clearInventoryAlert',
            template: require('../../../widgets/clear-inventory-alert-modal/layout.html'),
            css: require('../../../widgets/clear-inventory-alert-modal/styles.scss'),
            controller: ClearInventoryAlertController,
            resolve: {
                'inventoryItem' : () => {
                    return { name: subjWell.description, serviceStatus: subjWell.vialStatus };
                }
            }
        });

        confirmModal.result.then(() =>  {
            this.inventoryAlertService.clearAlerts(this.$scope.practice, subjWell._vialDm.id)
                .then(() => {
                    subjWell.alert = null;
                    subjWell._alertDismissed = true;
                });
        });
    }

    async _printTrayLabels(data, labelAmount) {
        const labels = []
        for (var i = 0; i < labelAmount; i++) {
            labels.push(
                <TrayLabel
                    key={i}
                    barcode={data.barcode}
                    description={data.boardName}
                />
            )
        }
        submitPrintJob(PRINTER_DYMO30336_LANDSCAPE, labels)
    }

    printBarcodeModal(data, board) {
        let parent = this;

        this.$uibModal.open({
            windowClass: 'printBarcode',
            scope: this.$scope, //passed current scope to the modal
            template: require('../../../widgets/print-barcode-modal.html'),
            css: require('../../../widgets/print-barcode-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.getNumber = function (num) {
                    return new Array(num)
                };

                $scope.$watch('labelAmount', function (newValue, oldValue) {
                    if (newValue != undefined) {
                        $scope.getNumber(newValue)
                    }
                });

                $scope.data = data;
                $scope.boardType = board;
                $scope.cancel = () => $uibModalInstance.close('cancel');
                $scope.labelAmount = data.totalTrays;
                $scope.printBarcodeLabel = (data, labelAmount) => {

                    parent._printTrayLabels(data, labelAmount);

                    setTimeout(function () {
                        $uibModalInstance.close('printed');
                    }, 500);
                };

            }
        });
    }

}
