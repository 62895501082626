"use strict";

import angular from 'angular';
import controller from "./controller";

export default angular.module('pages.inventory.sections.concentrate.details', [])
    .controller('ConcentrateDetailsController', controller)
    .directive('stConcentrateHistoryTable', ['$injector', '$filter', stConcentrateHistoryTable])
    .name;

function stConcentrateHistoryTable($injector, $filter){
    return {
        scope : {
            showFilter: "=",
            rowCollection: '=',
            toDetails: "&",
            concentrate: "@"
        },
        link: function(scope, ele, attrs) {

            scope.printDate = new Date();

            let concentrateUseType = $injector.get('ConcentrateUseType');
            let serviceStatus = $injector.get('ServiceStatus');

            scope.typeFilterOpts = [
                {name: 'All', value: undefined},
                {name: concentrateUseType.TESTING_TRAY, value: concentrateUseType.TESTING_TRAY},
                {name: concentrateUseType.SP_WELL_TRAY, value: concentrateUseType.SP_WELL_TRAY},
                {name: concentrateUseType.MIXING_TRAY, value: concentrateUseType.MIXING_TRAY},
                {name: concentrateUseType.TREATMENT_VIAL, value: concentrateUseType.TREATMENT_VIAL}
            ];
            scope.typeFilterSelect = scope.typeFilterOpts[0];

            scope.onTypeFilterSelect = (opt) => {
                scope.typeFilterSelect = opt;
                scope.historyTableFilter.type = opt.value;
            };

            scope.statusFilterOpts = [
                {name: 'All', value: undefined},
                {name: $filter('serviceStatusFilter')(serviceStatus.INVENTORY), value: $filter('serviceStatusFilter')(serviceStatus.INVENTORY)},
                {name: $filter('serviceStatusFilter')(serviceStatus.IN_SERVICE), value: $filter('serviceStatusFilter')(serviceStatus.IN_SERVICE)},
                {name: $filter('serviceStatusFilter')(serviceStatus.EXPIRED), value: $filter('serviceStatusFilter')(serviceStatus.EXPIRED)},
                {name: $filter('serviceStatusFilter')(serviceStatus.RECALLED), value: $filter('serviceStatusFilter')(serviceStatus.RECALLED)},
                {name: $filter('serviceStatusFilter')(serviceStatus.DISCARDED), value: $filter('serviceStatusFilter')(serviceStatus.DISCARDED)}
            ];
            scope.statusFilterSelect = scope.statusFilterOpts[2];

            scope.onStatusFilterSelect = (opt) => {
                scope.statusFilterSelect = opt;
                scope.historyTableFilter.status = opt.value;
            };

            scope.historyTableFilter = {
                type: scope.typeFilterSelect.value,
                displayName: undefined,
                barcode: undefined,
                dateAdded: undefined,
                status: scope.statusFilterSelect.value // Default to in-service AAW-404
            };

        },
        template: require('../widgets/st-concentrate-history-table.html'),
        css: require('../widgets/st-concentrate-history-table.scss')
    };
}