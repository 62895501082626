"use strict";

export default class PrintTestReportModal {
    static $inject = ['$uibModalInstance', '$scope', 'allergyTest'];
    
    constructor($uibModalInstance, $scope, allergyTest) {
        $scope.allergyTest = allergyTest;
        
        $scope.print = (reportUrl) => {
            $uibModalInstance.close(reportUrl);
        };
        
        $scope.cancel = () => $uibModalInstance.dismiss();    
    }
}

