'use strict';

import angular from "angular";

/**
 * Service to manage notification subscriptions.
 *
 * All subscriptions start off in a "paused" state, where notifications are received and queued up.
 * Clients <b>must</b> call the start() function on the returned Promise to start receiving the notifications -
 * first any queued up and then the live ones. This two-part mechanism is needed to close race conditions.
 * Create your subscriptions, <i>then</i> load your data, and <i>then</i> start() processing notifications.
 */
export default class NotificationService {

    /** Holds deferred object while processing, to protect against race condition. */
    initDeferred = null;

    static $inject = ['$q', 'stompClient', 'NotificationAction', 'NotificationType','sessionService'];

    constructor($q, stompClient, NotificationAction, NotificationType, sessionService) {
        this.$q = $q;
        this.NotificationAction = NotificationAction;
        this.NotificationType = NotificationType;
        this.client = stompClient;
        this.sessionService = sessionService;

        this.topicTemplate = "/topic/{practice}.{office}.{type}.{id}.{action}";
    }

    /**
     * To be called before subscribing to a topic.
     * This function will check the underlying stomp and ws connection, reconnecting if necessary.
     * @returns {Promise} of true when connected, or false if failed.
     *   (Always resolves rather than rejecting, because the rest of the application should continue to function without this service.)
     */
    init() {
        // Init, if not already in progress
        if (!this.initDeferred) {
            this.initDeferred = this.$q.defer();
            let user = this.sessionService.get('user');
            let parts = user._links.notificationService.split('\?');

            this.client.connect(parts[0], parts[1], parts[2]).then(success => {
                this._initCompletion(success);
            }, () => {
                this._initCompletion(false);
            });
        }

        return this.initDeferred.promise;
    }

    /**
     * Helper for reporting completion of init().
     * Includes careful handling to prevent race-condition.
     * @param isConnected true if connected
     * @private
     */
    _initCompletion(isConnected) {
        let tempDeferred = this.initDeferred;
        this.initDeferred = null;
        tempDeferred.resolve(isConnected);
    }

    /**
     * Unsubscribe to all notifications and disconnect from the server.
     * @return Promise that resolves when disconnected.
     */
    disconnect() {
        this.client.disconnect();
    }

    /**
     * Is the Notification's topic about the given NotificationAction?
     * @param notification {Notification) DTO to check
     * @param action {string} NotificationAction to compare for
     */
    isNotificationOfAction(notification, action) {
        if (anguar.isString(notification.topic)) {
            notification.topic.endsWith(action);
        }
    }

    /**
     * Subscribe to receive notifications that a patient's next appointment has changed.
     * @param practice - The user's practice.
     * @param office - The office the user is currently viewing.
     * @param patient - The patient of interest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientAppointments(practice, office, patient) {
        let params = {
            practice: practice.id,
            office: office.id,
            type: this.NotificationType.Patient,
            id: patient.id,
            action: this.NotificationAction.APPOINTMENT
        };

        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to receive notifications that about any new or modified Appointment at a practice.
     * @param practice - The user's practice.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeAllPatientAppointments(practice) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.Patient,
            id: '*',
            action: this.NotificationAction.APPOINTMENT
        };

        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to receive notifications that a specific appointment has been removed.
     * @param practice - The user's practice.
     * @param appointment - The appointment of interest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeToAppointmentRemoval(practice, appointment) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.Appointment,
            id: appointment.id,
            action: this.NotificationAction.REMOVED
        };

        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a patient's information has been updated.
     * @param practice - The user's practice.
     * @param patient - The patient of intrest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientUpdates(practice, patient) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.Patient,
            id: patient.id,
            action: this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that any patient's visit details have changed at an office. (Includes check-in, check-out, patient stations)
     * @param practice - The user's practice.
     * @param office - The office the user is viewing.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientVisits(practice, office) {
        let params =  {
            practice: practice.id,
            office: office.id,
            type: this.NotificationType.Patient,
            id: '*',
            action: this.NotificationAction.VISIT
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications for a patient's alerts at a practice.
     * @param practice - The user's practice.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientAlerts(practice, patient) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.PatientAlert,
            id: patient.id,
            action: this.NotificationAction.EVENT
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a specific patient's visit details have changed. (Includes check-in, check-out, and patient stations).
     * @param practice - The user's practice.
     * @param patient - The patient of intrest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeVisitForPatient(practice, patient) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.Patient,
            id: patient.id,
            action: this.NotificationAction.VISIT
        };

        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that any concentrate at a practice was created or updated.
     * @param practice - The user's practice.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeAllConcentrateUpdates(practice) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.Concentrate,
            id: '*',
            action: '*'
        };

        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a specific concentrate at an office was updated.
     * @param practice - The user's practice.
     * @param office - The office the user is currently viewing.
     * @param concentrate - The concentrate of interest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeUpdateForConcentrate(practice, office, concentrate) {
        let params = {
            practice: practice.id,
            office: office.id,
            type: this.NotificationType.Concentrate,
            id: concentrate.id,
            action: this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications on any changes to Unapproved entities (includes tests, treatments, and prescription).
     * @param practice - The user's practice.
     * @param office - The office the user is currently viewing.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeToAllUnapprovedAtOffice(practice, office) {
        let params = {
            practice: practice.id,
            office: office.id,
            type: this.NotificationType.Unapproved,
            id: '*',
            action: '*'
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to be notified when a prescription is mixed at an office.
     * @param practice - The user's practice.
     * @param office - The office the user is currently viewing.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeToAllMixedAtOffice(practice, office) {
        let params = {
            practice: practice.id,
            office: office.id,
            type: this.NotificationType.Prescription,
            id: '*',
            action: this.NotificationAction.MIXED
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a prescription has been updated at a practice
     *
     * @param practice - The user's practice.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeToPrescriptionUpdates(practice) {
        let params = {
            practice : practice.id,
            office : '*',
            type : this.NotificationType.Prescription,
            id : '*',
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications of patient search updates.
     * @param practice - The user's practice.
     * @param query - The search text to receive notifications for.
     * @returns {promise that is used to 'notify' that a notification has arrived.}
     */
    subscribeToPatientSearch(practice, query) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.PatientList,
            id: btoa(query),
            action: this.NotificationAction.SEARCH
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a board's information has been updated.
     *
     * @param practice - The user's practice.
     * @param {BoardDTO | ReferenceDTO.<Board>} board - The subject board of interest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeBoardUpdates(practice, board) {
        let params = {
            practice : practice.id,
            office : "*",
            type : this.NotificationType.Board,
            id : board.id,
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that any board at a practice has been updated.
     *
     * @param practice - The user's practice.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeAllBoardUpdatesAtPractice(practice) {
        let params = {
            practice : practice.id,
            office : "*",
            type : this.NotificationType.Board,
            id : "*",
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that any board at an office has been updated.
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeAllBoardUpdatesAtOffice(practice, office) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.Board,
            id : "*",
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a board has been created at a practice or office.
     *
     * @param practice - The user's practice.
     * @param office - (optional) The user's office. If undefined, subscribes to entire practice.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeBoardCreation(practice, office) {
        let params = {
            practice : practice.id,
            office : (office ? office.id : "*"),
            type : this.NotificationType.Board,
            id : "*",
            action : this.NotificationAction.CREATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that any PatientVial at an office has been updated.
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientVialUpdates(practice, office) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.PatientVial,
            id : "*",
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a specific PatientVial has been updated.
     *
     * @param practice - The user's practice.
     * @param vial - The PatientVial or TreatmentVial
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeToAPatientVialUpdates(practice, vial) {
        let params = {
            practice : practice.id,
            office : "*",
            type : this.NotificationType.PatientVial,
            id : vial.id,
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that a PatientVial has been created at an office.
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientVialCreation(practice, office) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.PatientVial,
            id : "*",
            action : this.NotificationAction.CREATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that any PatientVial at an office has been removed (from that of office).
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribePatientVialRemoval(practice, office) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.PatientVial,
            id : "*",
            action : this.NotificationAction.REMOVED
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications that InventoryAlerts have changed at an office.
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeInventoryAlerts(practice, office) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.InventoryAlert,
            id : "*",
            action : this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications of all EntityLockEvents at an office.
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeOfficeEntityLockEvents(practice, office) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.EntityLockEvent,
            id : "*",
            action : this.NotificationAction.EVENT
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Subscribe to notifications of EntityLockEvents regarding a specific entity at an office.
     *
     * @param practice - The user's practice.
     * @param office - The user's office
     * @param dto - {DTO|ReferenceDTO} an AllergyTest, Treatment, or Prescription.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeEntityLockEvent(practice, office, dto) {
        let params = {
            practice : practice.id,
            office : office.id,
            type : this.NotificationType.EntityLockEvent,
            id : dto.id,
            action : this.NotificationAction.EVENT
        };
        return this._subscribe(this._expandTopic(params));
    }
    
    /**
     * Subscribe to notifications that a bill's information has been updated.
     * @param practice - The user's practice.
     * @param bill - The bill of interest.
     * @returns {promise that is used to 'notify' that a notification has arrived. With an attached unsubscribe() method.}
     */
    subscribeBillUpdates(practice, bill) {
        let params = {
            practice: practice.id,
            office: '*',
            type: this.NotificationType.Bill,
            id: bill.id,
            action: this.NotificationAction.UPDATE
        };
        return this._subscribe(this._expandTopic(params));
    }

    /**
     * Cancel a subscription
     * @param promise - The promise returned for the subscription being cancelled.
     */
    unsubscribe(promise) {
        if (promise) {
            promise.unsubscribe();
        }
    }

    /**
     * Helper to expand a params to a topic string.
     * @param params - topic params.
     * @returns {string}
     * @private
     */
    _expandTopic(params) {
        var topic = this.topicTemplate;
        for (var prop in params) {
            topic = topic.replace("{" + prop + "}", params[prop]);
        }

        return topic;
    }

    /**
     * Subscribe to a topic and set callback.
     * @param topic - subscription topic key.
     * @param referenceOnly - pass true to receive a ReferenceDTO in the notification body rather than a full DTO.
     * @returns {promise that is used to 'notify' that a notification has arrived. Includes attached unsubscribe(), start(), and pause() functions.}
     * @private
     */
    _subscribe(topic, referenceOnly) {
        let deferred = this.$q.defer();
        deferred.promise.unsubscribe = () => { console.error("Failed to unsubscribe from topic " + topic); };

        // Start paused. Caller must use start()
        this._pauseAndQueue(deferred);
        deferred.promise.pause = () => { this._pauseAndQueue(deferred); return deferred.promise; };
        deferred.promise.start = () => { this._releaseAndResume(deferred); return deferred.promise; };

        let cb = (message) => {
            let dto = angular.fromJson(message.body);

            if (deferred.pauseQueue)
                deferred.pauseQueue.push(dto);
            else
                deferred.notify(dto);
        };

        this.client.subscribe(topic, cb, referenceOnly).then((unsubscriber) => {
            deferred.promise.unsubscribe = unsubscriber.unsubscribe;
        }, (error) => {
            deferred.reject("Subscribe to topic " + topic + " failed.");
        });

        return deferred.promise;
    }

    /**
     * Pause a subscription, queuing up notifications while paused.
     * Don't use directly; instead call the pause() function on the Promise returned by the subscribe* function.
     *
     * @see releaseAndResume()
     */
    _pauseAndQueue(deferred) {
        // Create a pauseQueue, which serves both to hold notifications and as a flag
        if (!deferred.pauseQueue) {
            deferred.pauseQueue = [];
        }
    }

    /**
     * Release (notify) any notifications that have been queued up while paused, and then
     * return to live notice of notifications.
     * Don't use directly; instead call the ready() function on the Promise returned by the subscribe* function.
     *
     * @see pauseAndQueue()
     */
    _releaseAndResume(deferred) {
        if (deferred.pauseQueue) {
            for (let dto of deferred.pauseQueue)
                deferred.notify(dto);

            delete deferred.pauseQueue;
        }
    }
}
