'use strict';

import BaseService from './base.service.js';

export default class GlobalConfigService extends BaseService {
    /**
     * Get global configurations.
     *
     * @return Promise to global configuration
     */
    get() {
        return this.getGlobalLinks()
            .then(globalLinks => {
                return {
                    allowAutoBarcode: globalLinks.environment !== 'PRODUCTION'
                };
            });
    }
}
