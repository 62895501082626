"use strict";

import BaseSectionController from "../../widgets/section-content-panel/controller";
import InventorySections from "../../models/inventory-sections";
import AddWellController from "../../widgets/edit-board-modal/add-well.controller";

export default class SpWellsSectionController extends BaseSectionController {

    static $inject = ["$injector", "$scope", "InventorySections"];

    constructor($injector, $scope, UiSectionTypes) {
        super($injector, $scope, UiSectionTypes.WELLS);

        this.Procedure = $injector.get("Procedure");
        this.boardSvc = $injector.get("boardService");
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.panelSvc = $injector.get("panelService");
        this.notificationType = $injector.get('NotificationType');

        this.$scope.ServiceStatus = $injector.get("ServiceStatus");

        this.$scope.onRowSelection = (/* see #_dmToVm() */boardListItem)=> this._gotoDetails(boardListItem);

        this.$scope.addBoardModal = (mode) => this.addBoardModal(mode);

        this._loadListData();
        this.inventoryAlertService.subscribe(this.$scope, alertSummary => this._updateBoardAlerts(alertSummary));
    }

    /**
     * This is called when the user selects a different Office.
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this.unsubscribeAllSubscriptions();
        this._loadListData();
    }

    _gotoDetails(boardObject) {
        this.routeToPage(this.urlPaths.INVENTORY_WELL_DETAILS, boardObject);
    }

    async _loadListData() {
        await this.notificationService.init();
        this._establishNotifListening();

        let
            vmList = [],
            panelMap = new Map(),
            boardData = await this.boardSvc.getAtOffice(this.$scope.office, this.Procedure.SPT, null, 'NO_VIALS'),
            boardsList = boardData.list;

        for (let aBoard of boardsList) {

            let aPanel = null;

            if (panelMap.has(aBoard.panel.id)) {
                aPanel = panelMap.get(aBoard.panel.id);
            }
            else {
                aPanel = await this.panelSvc.get(aBoard.panel, boardData);
                panelMap.set(aBoard.panel.id, aPanel);
            }

            vmList.push(this._dmToVm(aBoard, panelMap.get(aBoard.panel.id)));
        }

        this.$scope.rowCollection = vmList;
        this.startAllSubscriptions();
        this.inventoryAlertService.sendTo(this.$scope);
        this.$scope.$digest();
    }

    _dmToVm(boardDm, panelDm) {

        let vm = {
            "boardName": boardDm.name,
            "panelName": panelDm.name,
            "barcode": boardDm.barcode,
            "dateCreated": this.chronologyMappingService.utcToTimezone(boardDm.createdDateTime, this.$scope.office.timezone),
            "inServiceDate": boardDm.startService,
            "endServiceDate": boardDm.endService,
            "status": boardDm.status,
            "alert": undefined,
            // We're cooking the HATE-Ref's into the VM to allow them to facilitate routing to details
            "href": boardDm.href,
            "id": boardDm.id
        };

        return vm;
    }

    addBoardModal() {

        let creationModal = this.$uibModal.open({
            windowClass: 'addBoards',
            scope: this.$scope, //passed current scope to the modal
            template: require("../../widgets/edit-board-modal/layout.html"),
            css: require('../../widgets/edit-board-modal/styles.scss'),
            controller: AddWellController
        });

        creationModal.result.then(newModel => this._loadListData());
    }

    /**
     * @param {com.audigygroup.allergy.core.dto.Notification} notif
     * @private
     */
    _onListChange(notif) {
        this._loadListData();
    }

    _establishNotifListening() {

        // Board Creation
        this.registerSubscription(this.notificationService.subscribeBoardCreation(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onListChange(notification));

        // Board Updates : for any of the boards in my list.
        this.registerSubscription(this.notificationService.subscribeAllBoardUpdatesAtPractice(this.$scope.practice))
            .then(null, null, (notification) => this._onListChange(notification));
    }

    /**
     * Update alerts on board in the view according to the summary from InventoryAlertService.
     *
     * @param alertSummary see InventoryAlertService
     * @private
     */
    _updateBoardAlerts(alertSummary) {
        if (this.$scope.rowCollection) {
            for (let board of this.$scope.rowCollection) {
                board.alert = alertSummary.icons.get(board.id);
            }
        }
    }
}
