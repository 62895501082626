'use strict';

import angular from 'angular';
import BaseService from './base.service.js';

export default class RoutingService {

    static $inject = ['$injector'];

    constructor($injector) {
        this.base64 = $injector.get('base64'); //angular-utf8-base64 module
        this.$location = $injector.get('$location');
    }

    /**
     * Create an object containing the current URL and a name to associate with it
     * @param {String} name the name for the return path
     */
    createLocationParams(name) {
        return {
            fromLocation: {
                url: '#' + this.$location.url(),
                name: name
            }
        };
    }

    /**
     * If present, extract location url and name passed to the current page
     * @param {Object} params params object passed to the current page
     * @param {String} defaultPath path to return if none present in params
     * @param {String} defaultName name to return if none present in params
     */
    extractLocationParams(params, defaultPath, defaultName) {
        return params.fromLocation || { url: defaultPath, name: defaultName };
    }

    /**
     * Reload the current page
     */
    reload() {
        this.$location.path(this.$location.path).search(this.$location.search());
    }

    /**
     * Navigate to another page (controller) in the application.
     * @template T genericType, must be a subclass of DTO.
     * @param {UrlPath} path : destination page
     * @param { T | ReferenceDTO.<T> } subjectModel (optional) When defined, and populated by a DTO type, this impl will
     * @param {Object} routeParams (optional) When defined, any additional parameters to pass to the target path
     * serialize and embed the referential fields in a URI and redirect the browser to it.
     */
    goToPage(path, subjectModel, routeParams) {
        let params = routeParams || {};
        let /** {Boolean} */isDto = angular.isObject(subjectModel)
            && angular.isDefined(subjectModel.id)
            && angular.isDefined(subjectModel.href);

        if (isDto) {
            params.id = subjectModel.id,
            params.href = subjectModel.href;

            let serializedReference = JSON.stringify(params);
            let encodedQueryString = this.base64.urlencode(serializedReference);

            this.$location.path(path).search('_', encodedQueryString);
            console.log('routeToPage ' + path + '?' + subjectModel.id);
        }
        else {
            console.log('routeToPage ' + path);
            this.$location.path(path).search(params);
        }
    }

    /**
     * Get the parameters object passed by the page that navigated to this page.
     *
     * @return the object, or undefined if none.
     * @see routeToPage(path, params)
     */
    getCurrentParams() {
        let params = this.$location.search()._;
        if (params) {
            return JSON.parse(this.base64.urldecode(params));
        }

        return undefined;
    }
}
