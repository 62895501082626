'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import moment from 'moment';
import './styles.scss';

export default class ApprovedAwaitingTreatmentReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.APPROVED_AWAITING_TREATMENT);

        this.$timeout = $injector.get('$timeout');
        this.treatmentVialService = $injector.get('treatmentVialService');

        $scope.updateCalled = (row) => this._updateCalled(row);
        $scope.updateCallNotes = (row) => this._updateCallNotes(row);

        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getApprovedAwaitingTreatment(this.reportCriteria);
    }

    async _renderReportData(reportData) {
        this.$scope.rowCollection = [];
        for(let row of reportData) {
            row.editingCallNotes = !row.callNotes;

            row.approvedDate = moment(row.approvedDate);
            row.approvedDateDisplay = row.approvedDate.format('MM/DD/YYYY');
            /* Called is returned as 0 or 1, but we need it to be a boolean for the checkbox */
            row.called = !!row.called;
            this.$scope.rowCollection.push(row);
        }
        this.$scope.$digest();
    }

    _updateCalled(row) {
        this._updateTreatmentVial(row);
    }

    _updateCallNotes(row) {
        this._updateTreatmentVial(row);
    }

    _updateTreatmentVial(row) {
        row.saving = true;

        /* Force 250ms delay for UI fluidity on loading GIF */
        let timeout = this.$timeout(() => {}, 250);

        this._getTreatmentVial(row).then(treatmentVial => {
            treatmentVial.called = row.called;
            treatmentVial.callNotes = row.callNotes;
            return this.treatmentVialService.update(treatmentVial);
        }).then(treatmentVial => {
            row._treatmentVial = treatmentVial;
            row.editingCallNotes = !treatmentVial.callNotes;
        }).then(() => timeout)
        .then(() => {
            row.saving = false;
        }).catch(() => {
            row.saving = false;
        });
    }

    _getTreatmentVial(row) {
        if (row._treatmentVial) {
            return this.treatmentVialService.resolved(row._treatmentVial);
        }

        return this.treatmentVialService.getById(row.treatmentVialId).then(treatmentVial => {
            row._treatmentVial = treatmentVial;
            return treatmentVial;
        });
    }

    _getCsvMeta() {
        return [
            { name: 'Patient Name', value: 'patientName' },
            { name: 'Chart Number', value: 'chartNumber' },
            { name: 'Home Phone', value: 'homePhoneNumber' },
            { name: 'Mobile Phone', value: 'mobilePhoneNumber' },
            { name: 'Treatment Type', value: 'treatmentType' },
            { name: 'Vial Name', value: 'vialName' },
            { name: 'Location', value: 'location' },
            { name: 'Ordering Provider', value: 'orderingProvider' },
            { name: 'Performed By', value: 'performedBy' },
            { name: 'Billing Provider', value: 'billingProvider' },
            { name: 'Approved By', value: 'approvedBy' },
            { name: 'Approved Date', value: 'approvedDateDisplay' },
            { name: 'Called', getValue: (row) => { return row.called ? 'Yes' : 'No'; } },
            { name: 'Notes', value: 'callNotes' }
        ];
    }
}
