"use strict";

import angular from "angular";
import StandardLayoutNoticeController from "./controller";

export default angular.module("pages.common.standard-layout.notice", [])
    .controller("StandardLayoutNoticeController", StandardLayoutNoticeController)
    .directive("agAppNotice", initAgAppNotice)
    .name;

function initAgAppNotice() {
    return {
        controller: "StandardLayoutNoticeController",
        scope : {},
        restrict : "E",
        css: require("./styles.scss"),
        template : require("./layout.html")
    }
}
