'use strict';

import BaseReportsSectionController from '../base-reports-section.controller';
import moment from 'moment';

import './styles.scss';

export default class ConcentrateExpirationReportSectionController extends BaseReportsSectionController {

    static $inject = ['$injector', '$scope', 'ReportsSections'];

    constructor($injector, $scope, ReportsSections) {
        super($injector, $scope, ReportsSections.CONCENTRATE_EXPIRATION);

        this.concentrateService = $injector.get('concentrateService');
        this.urlPaths = $injector.get('UrlPaths');

        $scope.goToDetails = (row) => {
            this._gotoDetails(row.concentrateId);
        };

        this.svm.futureDateRange = this.svm.dateRange;
        this._updateFutureDateRange(false);

        super.onInit();
    }

    async _getReportData() {
        return await this.reportsService.getConcentrateExpiration(this.reportCriteria);
    }

    async _renderReportData(reportData) {
        let currentDate = this.chronologyMappingService.currentDate(this.$scope.office.timezone);

        this.$scope.rowCollection = [];
        for(let row of reportData) {
            let expirationDate = this.chronologyMappingService.startOfDay(row.expirationDate);
            row.daysUntilExpiration = this.chronologyMappingService.daysBetween(expirationDate, currentDate);

            row.expirationDate = moment(row.expirationDate);
            row.expirationDateDisplay = row.expirationDate.format('MM/DD/YYYY');
            row.inServiceDate = moment(row.inServiceDate);
            row.inServiceDateDisplay = row.inServiceDate.format('MM/DD/YYYY');
            this.$scope.rowCollection.push(row);
        }
    }

    _gotoDetails(concentrateId) {
        let href = this.concentrateService.getHrefForId(concentrateId);
        this.routeToPage(
            this.urlPaths.INVENTORY_CONCENTRATE_DETAILS,
            { id: concentrateId, href: href },
            this.routingService.createLocationParams('Concentrate Expiration Report')
        );
    }

    _getCsvMeta() {
        return [
            { name: 'Barcode', value: 'barcode' },
            { name: 'Description', value: 'description' },
            { name: 'Location', value: 'location' },
            { name: 'Lot Number', value: 'lotNumber' },
            { name: 'Manufacturer', value: 'manufacturer' },
            { name: 'Status', value: 'status' },
            { name: 'Expiration Date', value: 'expirationDateDisplay' },
            { name: 'In Service Date', value: 'inServiceDateDisplay' },
            { name: 'Days Until Expiration', value: 'daysUntilExpiration' },
            { name: 'Patient Vial Count', value: 'patientVialCount' }
        ];
    }
}
