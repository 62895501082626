"use strict";

import SpWellModalController from "./sp-well-modal.controller";

export default class EditWellController extends SpWellModalController {

    static $inject = ["$uibModalInstance", "$scope", "$injector", "subjectBoard"];

    /**
     * @param {$uibModalInstance} AngularBootstrap uibModel instance controller
     * @param {AngularScope} $scope
     * @param {AngularInjectorServiceProvider} $injector
     * @param {SerializedModel<com.audigygroup.allergy.core.dto.Board> } subjectBoard
     *  This is the logical DM of the instance. We expect a serialized BoardDto value. Qualified values CAN be
     *  manually assembled, and they'll work, so long as the significant fields used match-up exactly with the
     *  server-side DTO. If this proves to be too onerous in practice, then we'll change this be the board id, and
     *  directly request in here. However, if we CAN swing this approach (objects versus ID's), then we'll be spared
     *  an appreciable degree of complexity and gain performance from eschewing unneeded requests.
     */
    constructor($uibModalInstance, $scope, $injector, subjectBoard) {
        super($uibModalInstance, $scope, $injector, SpWellModalController.UseCaseEdit);

        // In order to reuse (instead of replacing) superclass's init activity, we inject dependencies in a
        // dedicated method. See super#_initInjections.

        this.$scope.updateBoard =()=> this._updateBoard();
        this.$scope.onOfficeChange = (newOffice) => { this.$scope.selectedOffice = newOffice; };

        /** The current logical subject value of this instance. */
        this._subjectDm = subjectBoard;
        this.$scope.boardName = this._subjectDm.name;
        this.$scope.status = this._subjectDm.status;
        this._initialize();
    }

    /**
     * @override
     * @protected
     */
    async _initialize() {
        await super._initialize();
        await this._loadUiFormValues();
        this.$scope.$digest();
    }

    /**
     * @protected
     */
    async _loadUiFormValues() {
        this.$scope.panel = await this._acquirePanelDm();

        this.$scope.officeChoices = (await this.officeService.getInPractice(this.$scope.practice)).list;
        this.$scope.selectedOffice = this.$scope.officeChoices.find(o => o.id === this._subjectDm.office.id) || this.$scope.office;

        super._updatePrickerConfig();
    }

    /**
     * @protected
     * @override
     */
    async _loadServiceStatusValues() {
        this.$scope.eligibleStatusTypes =
            await this.eligibleStatusService.getEligibleStatusTypesForBoard(this._subjectDm);
    }

    /**
     * @private
     * @returns {SerializedModel<com.audigygroup.allergy.core.dto.Panel>}
     */
    async _acquirePanelDm() {
        return await this.panelSvc.get(/** HATE-Ref */this._subjectDm.panel, /** parent BoardDto */this._subjectDm);
    }

    async _updateBoard() {
        let boardModel = this._subjectDm;
        boardModel.name = this.$scope.boardForm.boardName.$modelValue;
        boardModel.status = this.$scope.boardForm.status.$modelValue;
        boardModel.procedure = this.ProcedureType.SPT;
        boardModel.office = { id: this.$scope.selectedOffice.id };

        super._closeModal(await this.boardService.update(boardModel));
    }

}
