"use strict";
import angular from "angular";
import agAppCorpLogo from "./corp-logo";
import agAppNav from "./nav";
import agAppNotice from "./notice";
import agAppSearch from "./search";
import agAccountManagement from "./account-management";
import agLocationManagement from "./location-management";

export default angular.module("pages.common.standard-layout", [
        agAppCorpLogo,
        agAppNav,
        agAppNotice,
        agAppSearch,
        agAccountManagement,
		agLocationManagement
	])
    .directive("agStandardPageLayout", initView)
    .controller('SessionCheck', ['$scope', '$timeout', 'Idle', 'Keepalive', '$uibModal', 'userService', function ($scope, $timeout, Idle, Keepalive, $uibModal, userService) {
        const SHORT_IDLE = 2100;
        const LONG_IDLE = 28800;

        function closeModals() {
            if ($scope.idleWarning) {
                $scope.idleWarning.close();
                $scope.idleWarning = null;
            }
        }

        function isSelfService() {
            return ($scope.user && $scope.user.roles && $scope.user.roles.includes('SELFSERVICE'));
        }

        $scope.$on('IdleStart', function () {
            /* If the idle value doesn't match the logged in user (due to delay in loading user), update it */
            if (Idle.getIdle() == SHORT_IDLE && isSelfService()) {
                Idle.setIdle(LONG_IDLE);
                return;
            }

            closeModals();

            $scope.idleWarning = $uibModal.open({
                templateUrl: 'warning-dialog.html',
                windowClass: 'modal-danger'
            });
        });

        $scope.$on('IdleEnd', function () {
            closeModals();
        });

        $scope.$on('IdleTimeout', function () {
            closeModals();
            userService.logout({timeout:''});
        });

        $timeout(function () {
            let isKiosk = isSelfService();

            if (isKiosk) {
                // Kiosk timeout: 8 hours.
                Idle.setIdle(LONG_IDLE);
            }
            else {
                // Default timeout is 35 minutes.
                Idle.setIdle(SHORT_IDLE);
            }

            Idle.watch();
        });
    }])
    .config(['IdleProvider', 'KeepaliveProvider', function (IdleProvider, KeepaliveProvider) {
        // Warn for 60 seconds
        IdleProvider.timeout(60);

        // Keep alive every 13 minutes (two within idle period).
        // Thus we ensure that as long as ng-idle is running, the server won't timeout the user
        // when that user is indeed still active client-side.
        KeepaliveProvider.interval(780);

        // Would prefer to get it from GlobalLinks, but not available at this point. :-(
        KeepaliveProvider.http("/api/ping");
    }])
    .name;

function initView() {
    return {
        scope: {},
        transclude: true,
        restrict: "E",
        css: require("./styles.scss"),
        template: require("./layout.html")
    };
}
