import angular from 'angular';
import ngRoute from 'angular-route';
import ui_bootstrap from 'angular-ui-bootstrap';

import TestQuestionnaireController from './testing-questionnaire.controller.js';


export default angular.module('pages.testing.questionnaire', [ngRoute, ui_bootstrap])
    .config(config)
    .controller('TestQuestionnaireController',TestQuestionnaireController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/questionnaire', {
        template: require('./testing-questionnaire.html'),
        css: require('./questionnaire.scss'),
        controller: 'TestQuestionnaireController'
    });
}

config.$inject = ['$routeProvider'];
