"use strict";

export default angular.module("models.treatmentType", [])
    .constant("TreatmentType", {
        NONE : "NONE",
        /** SubCutaneous ImmunoTherapy (injections) */
        SCIT : "SCIT",
        /** SibLingual ImmunoTherapy (oral drops) */
        SLIT : "SLIT"
    })
    .filter("treatmentTypeFilter", treatmentTypeFilterImpl)
    .name;

/**
 * @returns a filter instance which consumes values (which are members type TreatmentType, or a lexical facsimile)
 * and returns a String instance contextually appropriate for use in the UI as user-facing text.
 *
 * Template expression examples:
 * Given scope.foo.treatmentType = TreatmentType.SCIT
 *
 * {{ foo.treatmentType | treatmentTypeFilter }} ==> "SCIT"
 * {{ foo.treatmentType | treatmentTypeFilter : false }} ==> "SCIT"
 * {{ foo.treatmentType | treatmentTypeFilter : true }} ==> "SCIT Treatment"
 */
function treatmentTypeFilterImpl() {
    return (/**{TreatmentType}*/type, /**{boolean=}*/withNounSuffix)=> {
        let displayName = type;

        // calls devoid of *withNounSuffix* param value default to false.
        withNounSuffix = ((typeof withNounSuffix) == "boolean" ) ? withNounSuffix : false;

        switch (type) {
            case "SCIT" : displayName = "SCIT"; break;
            case "SLIT" : displayName = "SLIT"; break;
            case "NONE" : return "None";
            default : return displayName;
        }

        return (withNounSuffix ? [displayName, "Treatment"].join(" ") : displayName );
    }
}