"use strict";

import angular from 'angular';
import controller from "./controller";

export default angular.module('pages.inventory.sections.concentrates', [])
    .controller('ConcentratesSectionController', controller)
    .directive('stConcentratesTable', [stConcentratesTable])
    .name;

function stConcentratesTable() {
    return {
        template: require('./widgets/st-concentrates-table.html'),
        css: require('./widgets/st-concentrates-table.scss'),
        link: function ($scope, element, attrs) {
            $scope.printDate = new Date();
        }
    };
}
