'use strict';

import TestingController from '../testing.controller'

export default class TestMeasureSptTimerController extends TestingController {

    static $inject = ['$scope','$injector'];
    constructor($scope, $injector) {

        super($scope, $injector);

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TestMeasureSptTimerController constructor completed!");
    }

    reload() {
        super.validateStage(['SPT_HISTAMINE_TIMER', 'SPT_ANTIGEN_TIMER']);

        this.$scope.expiredTimer = false;
        this.disableNext = false;

        if(this.$scope.allergyTest.stage === 'SPT_HISTAMINE_TIMER') {
            this.$scope.text = 'Histamine Timer';
        } else if (this.$scope.allergyTest.stage === 'SPT_ANTIGEN_TIMER') {
            this.$scope.text = 'Skin Prick Test Timer';
        }

        this.initFooterNote("Measure SPT Results");
    }

    /**
     * Save if something changed.
     * @override
     */
    update() {
        if (this.updateFooterNote())
            return super.update();
        else
            return Promise.resolve(this.$scope.allergyTest);
    }

    /**
     * @override
     */
    nextDisabled() {
        return this.disableNext || !this.$scope.expiredTimer;
    }
}
