'use strict';

import angular from 'angular';

export default angular.module('widgets.testing', [])
    .directive('agTestingNavBar', ["$location", agTestingNavBar])
    .directive('agTestingFooter', [agTestingFooter])
    .directive('agInstructions', [agInstructions])
    .directive('agWhealSizePanel', [agWhealSizePanel])
    .directive('agMeasureValues', ['$timeout', agMeasureValues])
    .directive('agAllergenPrepareInput', [agAllergenPrepareInput])
    .directive('agAllergenPrepareDilution', [agAllergenPrepareDilution])
    .directive('agAllergenPrepareGrids', [agAllergenPrepareGrids])
    .directive('agAllergenGridHeaderDilution', [agAllergenGridHeaderDilution])
    .directive('agAllergenGridHeaderAllergen', [agAllergenGridHeaderAllergen])
    .directive('agAllergenGridHeader', [agAllergenGridHeader])
    .directive('agExitIcon', [agExitIcon])
    .name;

function agTestingNavBar($location) {
    return {
        windowClass: require('./testing-navbar.scss'),
        template: require('./testing-navbar.html'),
        scope: false,
        link: function (scope, element, attrs) {
            let url = $location.url();
            let classNames = [];

            let cur = function (className) {
                return (url.indexOf(className) > -1);
            };

            let next = function (className) {
                for (let i = 0; i < classNames.length - 1; i++) {
                    if (url.indexOf(classNames[i].name) > -1 && className === classNames[i + 1].name) {
                        return true;
                    }
                }
                return false;
            };

            let prev = function (className) {
                for (let i = 0; i < classNames.length; i++) {
                    if (url.indexOf(classNames[i].name) > -1) {
                        for (let j = 0; j < i; j++) {
                            if (className === classNames[j].name) {
                                return true;
                            }
                        }
                        return false;
                    }
                }
                return false;
            };

            let link = function (className) {
                let currentIx = classNames.findIndex(m => url.indexOf(m.name) > -1);
                let vitalsIx = classNames.findIndex(m => m.name === 'vitals');
                return className === 'vitals' && currentIx > vitalsIx;
            };

            let getClassNames = function() {
                let result = [];

                if (scope.idtOnly) {
                    result.push({ name: 'idtDetails' }); 
                }

                if (scope.questionnaireTab) {
                    result.push({ name: 'questionnaire' });
                }

                if (scope.preVitalsTab) {
                    result.push({name: 'vitals', route: '/testing/vitals'});
                }

                if (!scope.idtOnly) {
                    result.push({ name: 'sptCheckList' });
                    if (scope.idtHistamineTab) {
                        result.push({ name: 'idtHistamine' });
                    }
                    result.push({ name: 'measureSptResult' });
                }

                if (scope.showIdtTabs) {
                    result.push({ name: 'prepareIntradermal' }, { name: 'idtCheckList' }, { name: 'measureIntradermal' });
                }

                if (scope.postVitalsTab) {
                    result.push({name: 'postVitals'});
                }

                result.push({name: 'testingResults'});

                return result;
            };

            classNames = getClassNames();

            scope.getClasses = function(className) {
                let result = [];

                if (prev(className)) {
                    result.push('prev');
                }
                if (cur(className)) {
                    result.push('current');
                }
                if (next(className)) {
                    result.push('next');
                }
                if (link(className)) {
                    result.push('link');
                }
                if (classNames[0].name === className) {
                    result.push('first');
                }

                return result.join(' ');
            };

            /* Go to route if the route is accessible (as defined by link function) */
            scope.routeTo = function(className) {
                let target = classNames.find(m => m.name === className);
                if (target && target.route && link(className)) {
                    scope.routeToPage(target.route, scope.allergyTest);
                }
            };

            scope.$watchGroup(['idtOnly','idtHistamineTab','showIdtTabs','questionnaireTab','preVitalsTab','postVitalsTab'], () => {
                classNames = getClassNames();
            });
        }
    };
}

function agTestingFooter() {
    return {
        //restrict: 'E',
        template: require('./testing-footer.html'),
        css: require('./testing-footer.scss'),
        scope: false,
        link: function (scope, element, attrs) {
            if (scope.nextButonLabel == undefined) scope.nextButonLabel = 'Next';
        }
    };
}


function agInstructions() {
    return {
        template: require('./instructions.html'),
        scope: {
            text: '=',
            textrightjustify: '@',
            timer: '=?'
        },
        link: function (scope, element, attrs) {
            scope.timerExpired = false;
        }
    };
}

function agWhealSizePanel() {
    return {
        template: require('./wheal-size-panel.html'),
        css: require('./wheal-size-panel.scss'),
        link: function (scope, element, attrs) {
            scope.showNotTest = false;

            const adjustConfig = () => {
                scope.showNotTest = !!scope.nottest;

                let gs = scope.gs;

                if (gs && gs.measureConfig.wheal && !scope.isSecondPart)
                    scope.activeConfig = gs.measureConfig.wheal;
                else if (gs && gs.measureConfig.erythema)
                    scope.activeConfig = gs.measureConfig.erythema;
                else
                    scope.activeConfig = undefined;

                if (scope.activeConfig) {
                    scope.unit = (scope.activeConfig.unit === 'RANGE') ? '+' : 'mm';
                    scope.wheals = new Array(Math.min(scope.activeConfig.max+1, 17));
                    const numBtns = 1 + scope.activeConfig.max + (scope.showNotTest ? 2 : 0);
                    scope.widthClass = numBtns < 11 ? 'large' : numBtns < 16 ? 'medium' : 'small';
                }
                else {
                    scope.unit = '';
                    scope.wheals = [];
                    scope.widthClass = '';
                }
            };
            adjustConfig();

            scope.$watchGroup(['gs.measureConfig','isSecondPart'], () => {
                adjustConfig();
            });

            scope.$watch('nottest', () => {
                adjustConfig();
            }, true);

            scope.notTested = () => {
                scope.nottest();
            };
        }
    };
}

function agMeasureValues($timeout) {
    return {
        restrict: 'AE',
        scope: false,
        link: function (scope, element, attrs) {

            let selectNoInput = () => {
                // When all inputs are entered or while switching the view, remove input focus so
                // that user can't click a measurement button and the old measurement appended to.
                $(document.activeElement).blur();
                scope.tabindex = scope.thisInput = undefined;
            };

            scope.$on('$destroy', () => {
                if (scope.$parent) {
                    scope.$parent.sizeSelectionType = undefined;
                }
            });

            scope.inputBoth = () => {
                return !!(scope.gs.measureConfig.wheal && scope.gs.measureConfig.erythema);
            };

            scope.nextTabIndex = function (tabindex) {
                const substanceCount = scope.gs.substanceCount || 300;
                const testedSubstanceCount = scope.gs.testedSubstanceCount;

                // Find the next item to focus on
                let inputFound = null;
                while (tabindex <= substanceCount) {

                    /* Change the sheet, if applicable */
                    if (scope.gs.sheetCount && (scope.gs.sheet+1) < scope.gs.sheetCount && tabindex > scope.gs.lastIdxCurrentSheet) {
                        scope.gs.sheet++;
                        selectNoInput();

                        /* Delay here to allow time for the sheet change. This same function will be re-called after
                         * the timeout expires, and the current call will simply return
                         */
                        $timeout(() => {
                            scope.nextTabIndex(tabindex);
                        }, 100);

                        return;
                    }

                    let box = $("input[name='section" + tabindex + "']");
                    if (box !== null && box.length >= 1 && !box.prop('disabled')) {

                        let value = scope.gs.wheals[tabindex];
                        if (value == undefined || value === '' || (scope.inputBoth() && !('' + value).match(/\d+-\d+/) && value !== 'X')) {
                            scope.dontClearUponFocus = true;
                            box.focus(); // triggers setFocus() below
                            //console.log("Set focus on position " + tabindex);
                            inputFound = box;
                            break;
                        }
                    }

                    tabindex++;
                }

                if (!inputFound) {
                    if (scope.allergyTest) {

                        // Special handling of ARMs
                        let doSwitch = false;
                        let stage = scope.allergyTest.stage;
                        /**
                         * Currently it is assumed that there's only 2 columns on the pricker (SA options are 2x4, 2x5).
                         * It is also assumed that if there are more than two grids to be tested, grid 3 and up go on the right arm
                         * If there are any inputs on the next arm which would not be disabled, switch arms - doing the check this way
                         * automatically handles Control vs Antigen steps
                         * */
                        let countPerGrid = 2 * scope.stampRows;

                        let firstRightArmIndex = (countPerGrid * 2);
                        if (scope.allergyTest.sptLocation === 'ARM' && (stage === 'SPT_ANTIGEN_RESULTS' || stage === 'SPT_HISTAMINE_RESULTS') && !scope.gs.rightForearm) {
                            // Loop through inputs on right arm, switch if any are not disabled

                            // Because SptGroups shown can be affected by the testing of Controls on an otherwise untested group,
                            // We need to set the first RightArm grid offset to the position of the first spt group not on the first page
                            // I.E. When testing A,D and E, we need to set the offset to the first index belonging to group 'E'
                            let firstRightArmGrid = scope.gs.sptGroups && scope.gs.sptGroups.length > 2 ? scope.gs.sptGroups[2] : undefined;

                            let firstRightArmGridOffset = (firstRightArmGrid.charCodeAt(0) - 'A'.charCodeAt(0));
                            firstRightArmIndex = (countPerGrid * firstRightArmGridOffset) + 1;
                            for (let i = firstRightArmIndex; i <= substanceCount; i++){
                                if (!scope.gs.setDisabled(i)) {
                                    scope.gs.rightForearm = true;
                                    scope.gs.leftForearm = false;
                                    doSwitch = true;
                                    break;
                                }
                            }
                        }
                        else if (scope.allergyTest.idtLocation === 'ARM' && stage === 'IDT_ANTIGEN_RESULTS' && !scope.gs.rightArm) {
                            for (let i = firstRightArmIndex; i <= substanceCount; i++) {
                                if (!scope.gs.setDisabled(i)) {
                                    scope.gs.rightArm = true;
                                    scope.gs.leftArm = false;
                                    doSwitch = true;
                                }
                            }
                        }

                        if (doSwitch) {
                            selectNoInput();
                            scope.gs.switchArm();
                            // Resume search after the right-side inputs are made visible.
                            $timeout(() => scope.nextTabIndex(firstRightArmIndex), 100);
                            return;
                        }
                    }

                    // All set.
                    selectNoInput();
                }
            };

            // FIXME: Used by treatment. Not yet supporting wheal+erythema
            scope.setValue = function (value) {
                if (isNaN(parseInt(value)))
                    value = undefined;
                scope.gs.wheals[scope.thisInput] = value;
            };

            scope.setFocus = function () {
                let currentFocus = $(document.activeElement);
                scope.tabindex = Number(currentFocus.attr('tabindex'));
                scope.thisInput = scope.tabindex; //@deprecated
                //console.log("Focus on position " + scope.tabindex);

                if (scope.gs.onInputFocus) {
                    scope.gs.onInputFocus(scope.tabindex);
                }

                // Did we get here via nextTabIndex(), not a manual click?
                if (scope.dontClearUponFocus) {
                    scope.dontClearUponFocus = false;

                    const value = scope.gs.wheals[scope.tabindex];
                    if (value != undefined && value !== '' && value !== 'X' && scope.inputBoth() && !('' + value).match(/\d+-\d+/)) {
                        scope.isSecondPart = true;
                        if (typeof value === 'number' || value.indexOf('-') < 0) {
                            scope.gs.wheals[scope.tabindex] = value + '-';
                        }
                    }
                    else {
                        scope.isSecondPart = false;
                    }
                }
                else {
                    scope.isSecondPart = false;
                }

                scope.setInstructionText(scope.tabindex);
                scope.$parent.sizeSelectionType = ((scope.gs.measureConfig.erythema && !scope.gs.measureConfig.wheal) || (scope.inputBoth() && scope.isSecondPart))
                    ? 'Erythema' : 'Wheal';
            };

            scope.gs.setFocus = scope.setFocus;

            scope.selectWheal = function (whealValue) {
                // Input not yet selected, or reached end of inputs.
                if (scope.tabindex == undefined)
                    return;

                // Set value of grid section to selected wheal value
                let oldValue = scope.gs.wheals[scope.tabindex];
                let newValue = '' + whealValue;
                if (oldValue != null && oldValue !== '' && scope.isSecondPart) {
                    if (oldValue.endsWith('-'))
                        newValue = oldValue + whealValue;
                    else if (oldValue.indexOf('-') > 0)
                        return; // Already has a dash not at the end? Abort! Race condition.
                    else
                        newValue = oldValue + '-' + whealValue;
                }

                scope.gs.wheals[scope.tabindex] = newValue;

                if (oldValue !== newValue) {
                    scope.gs.onWhealChange(scope.tabindex);
                }

                // This is where the tabindex moves on to next input
                scope.nextTabIndex(scope.tabindex);
            };

            // Auto-focus on next input once loading completes
            scope.$watch('isLoaded', () => {
                if (scope.isLoaded === true) {
                    $timeout(() => scope.nextTabIndex(1), 100);
                }
            });
        }

    };
}

function agAllergenPrepareInput() {
    return {
        template: require('./prepare-allergen-input.html'),
        scope: false
    };
}

function agAllergenPrepareDilution() {
    return {
        template: require('./prepare-dilution-rows.html'),
        scope: false
    };
}

function agAllergenPrepareGrids() {
    return {
        template: require('./prepare-allergen-grids.html'),
        scope: false,
        link: function (scope, element, attrs) {
            const MAX_STANDARD_DILUTION = 6;

            scope.getPrepareClasses = (allergen, dilution, highDilution) => {
                let classes = [];

                /* Always set the allergen-# class */
                classes.push('allergen-' + allergen.allergen);

                /* Standard case, set dilution number based on position in the grid */
                if (!highDilution || allergen.dilution > MAX_STANDARD_DILUTION) {
                    classes.push('dilution-' + dilution);
                /*
                 * If method is called from high dilution section but the dilution for the allergen isn't high
                 * (above MAX_STANDARD_DILUTION) just put the dilution class
                 */
                } else {
                    classes.push('dilution');
                }

                /* If no allergen position this allergen will be skipped */
                if (!allergen.position) {
                    classes.push('skip');
                } else if (allergen.dilution == dilution && (!highDilution || (highDilution && allergen.dilution > MAX_STANDARD_DILUTION))) {

                    /* Allergen position and scope count match, this is the current allergen */
                    if (allergen.allergen == scope.count) {
                        classes.push('active');
                    /* Allergen position is less than scope count, this allergen has been processed already */
                    } else if (allergen.allergen < scope.count) {
                        classes.push('set');
                    }
                }

                return classes.join(' ');
            };

            scope.getPrepareValue = (allergen, dilution, highDilution) => {

                /* No allergen position, skip this position */
                if (!allergen.position) {
                    return 'X';
                /* Standard case, return dilution number */
                } else if (allergen.dilution === dilution && (!highDilution || (highDilution && allergen.dilution > MAX_STANDARD_DILUTION))) {
                    return '#' + dilution;
                }

                return '';
            }
        }
    };
}

function agAllergenGridHeaderDilution() {
    return {
        template: require('./prepare-grid-header-dilution.html'),
        scope: false
    };
}

function agAllergenGridHeaderAllergen() {
    return {
        template: require('./prepare-grid-header-allergen.html'),
        scope: false
    };
}

function agAllergenGridHeader() {
    return {
        template: require('./prepare-grid-header.html'),
        scope: false
    };
}

function agExitIcon() {
    return {
        winddowTopClass: require('./exit-icon.scss'),
        template: require('./exit-icon.html'),
        scope: false,
        link: function (scope, element, attrs) {
            scope.showExit = false;
            scope.toggleExit = function () {
                scope.showExit = scope.showExit === false ? true : false;
            };
        }
    };
}
