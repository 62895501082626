"use strict";

import angular from 'angular';

export default angular.module("pages.inventory.widgets.inv-board-list.tray-list", [])
    .directive("invBoardTrayList", ['$filter', invBoardTrayList])
    .name;

function invBoardTrayList ($filter) {

    /**
     * {{TrayVialViewModel}} := {{
     *    tray : {Integer},
     *    antigen : {Integer},
     *    sptGroup : {String}
     *    description : {String},
     *    dilution : {Integer},
     *    barcode : {String},
     *    inServiceDate : {Date},
     *    endServiceDate : {Date},
     *    concentrateStatus : {ServiceStatus},
     *  }}
     */
    return {
        restrict: "E",
        css : require("./styles.scss"),
        template : require("./layout.html"),

        link : function($scope, element, attrs) {

            // We maintain this reference for quick access when the user changes the filtering state.
            let
                traySearchElem = angular.element($('#traySearch input')),
                dilutionSearchElem = angular.element($('#dilutionSearch input'))

            $scope.tvlRowCollection = [];
            $scope.tvlDisplayedCollection = [];

            $scope.$watch(
                ()=> ($scope.vm && $scope.vm.trays ? $scope.vm.trays.length : null),
                newCount => {
                    $scope.vialTrays = [];
                    for (let aTrayIndex = 1; aTrayIndex <= newCount; aTrayIndex++) {
                        $scope.vialTrays.push(aTrayIndex);
                    }
                }
            );

            // This watch powers the by-tray membership filtering, when specified externally via *currentTrayTab* .
            // Whenever an external UI action alters currentTrayTab, this watch updates the tray column header to force the
            // SmartTable to update the filtering/sorting data state.
            $scope.$watch(
                ()=> $scope.currentTrayTab,
                newVal => {
                    traySearchElem.val(angular.isNumber(newVal) ? (1 + newVal) : "");
                    traySearchElem.triggerHandler('input');
                    if (angular.isDefined(newVal)) {
                        $scope.isTrayColumnVisible = false;
                        // Entire column hidden, so don't bother updating scope.trayFilter
                    }
                    else {
                        $scope.isTrayColumnVisible = true;
                        $scope.trayFilter = "All";
                    }
                }
            );

            // This watch is the reciprocal of the currentTrayTab watch. This one listens to the parent scope's
            // model of interest and actively updates it to match currentTrayTab.
            $scope.$watch(
                ()=> $scope.trayFilter,
                newVal => {
                    $scope.currentTrayTab = (angular.isNumber(newVal) ? (newVal - 1) : undefined);
                }
            );

            $scope.printDate = new Date();

            $scope.$watchCollection(
                ()=> $scope.vm ? $scope.vm.trayVials : null,
                newVal => {
                    if (!newVal) return;
                    if (newVal.length < 1) return;

                    let
                        dilutionMin = newVal[0].dilution,
                        dilutionMax = newVal[0].dilution;

                    $scope.tvlRowCollection = newVal;

                    $scope.tvlRowCollection = $filter('orderBy')($scope.tvlRowCollection, ['tray','antigen','description','dilution']);

                    $scope.tvlDisplayedCollection = [].concat($scope.tvlRowCollection);

                    $scope.tvlRowCollection.forEach( aVial => {
                        dilutionMin = Math.min(aVial.dilution, dilutionMin);
                        dilutionMax = Math.max(aVial.dilution, dilutionMax);
                    });

                    $scope.dilutionValuesInUse = [];

                    for (let aDilutionLevel = dilutionMin; aDilutionLevel <= dilutionMax; aDilutionLevel++)
                        $scope.dilutionValuesInUse.push(aDilutionLevel);

                    $scope.showSptGroup = $scope.vm.procedure !== 'MIXING';
                }
            );
            
            $scope.exactSearch = (actual, expected) => {
                return !expected || actual == expected;
            };
        }
    }
}
