'use strict';

import BaseTreatmentController from '../base-treatment.controller'
import { ClassicalDilution, ClassicalDilutions } from '../../../models/classical-dilutions';
import CreateNextRxModalController from '../../../pages/common/create-next-rx/controller';
import CreateNextClassicalRxModalController from '../../../pages/common/create-next-classical-rx/controller';

export default class TreatmentExaminationController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.PrescriptionReason = $injector.get('PrescriptionReason');
        this.$q = $injector.get('$q');
        this.TreatmentType = $injector.get('TreatmentType');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.Procedure = $injector.get('Procedure');
        this.ServiceStatus = $injector.get("ServiceStatus");
        this.boardService = $injector.get('boardService');
        this.BoardArrangement = $injector.get('BoardArrangement');

        this.bypassedWarnings = new Set();

        // Scope functions
        $scope.setInstructionText = (pos) => this._setInstructionText(pos);
        $scope.setDisabled = (pos) => this._isDisabled(pos);
        $scope.toggleModal = () => {
            this.$scope.showModal = !this.$scope.showModal;
        };
        $scope.updatePrescription = () => this._showUpdatePrescriptionModal(false);
        $scope.updatePrescriptionLabel = 'Update Prescription';

        // Scope data
        $scope.text = '';
        $scope.treatmentExamination = true;
        $scope.wheals = new Array(17);
        $scope.showModal = false;
        $scope.showNotes = true;

        this.$scope.createNextRx = async (vial, vials) => {
            if (vial.isClassical)
                return this._createNextClassicalRx(vial);
            else
                return this._createNextRx(vials);
        };

        // Load data
        this.treatmentLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.EXAMINATION, this.TreatmentStage.REFILL]);
        this.$scope.textrightjustify = 'TIME ELAPSED:';
        this.$scope.timer = this.patient.timer;
        this.$scope.treatmentType = this.treatment.type;
        this.initFooterNote('Examination');

        for (let idx = 0; idx < this.$scope.injections.length; ++idx) {
            let wheal = this.$scope.injections[idx].reactionWhealSize;
            if (wheal === -1) {
                this.$scope['section' + (idx + 1)] = 'X';
            }
            else if (angular.isNumber(wheal)) {
                this.$scope['section' + (idx + 1)] = wheal;
            }
            else {
                this.$scope['section' + (idx + 1)] = '';
            }
        }

        this.$scope.gs = {};
        this.$scope.gs.measureConfig = {
            wheal: {
                unit: 'MM',
                max: 99
            }
        };

        this.disableNext = false;

        switch (this.treatment.stage) {
            case this.TreatmentStage.EXAMINATION:
            {
                /* Automatically advance if all measurements complete and all warnings (if any) handled */
                await this._checkWarnings();
                if (!this._haveWarnings() && this._haveAllMeasurements()) {
                    await this.nextStep();
                }
                break;
            }
            case this.TreatmentStage.REFILL:
            {
                await this.nextStep();
                break;
            }
        }

        this.$scope.autoSelectPosition();
        this.$scope.$digest();
    }

    /**
     * Is the Next button disabled?
     * @return {boolean}
     * @override
     */
    nextDisabled() {
        if (this.disableNext)
            return true;

        let haveAllMeasurements = this._haveAllMeasurements();
        let proceed = false;
        if (this.$scope.injection) {
            if (angular.isNumber(this.$scope.injection.reactionWhealSize)) {
                proceed = true;
            }
        }

        this.$scope.nextButtonLabel = haveAllMeasurements ? 'Check Out Patient' : 'Next';

        return !(proceed || haveAllMeasurements);
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Child classes should override/extend.
     * @return Promise to updated Treatment
     */
    async nextStep() {
        this.disableNext = true;

        // Save updated prescription
        await this.update();

        // Check for reaction warnings, and reload if any exist
        if (this.$scope.injections.some(inj => this._haveActiveWarning(inj))) {
            this.reload();
            return;
        }

        // Check if treatment completed for any of the vials given
        await this._checkTreatmentCompleted();

        // Try to advance to the next stage
        await this.advance();

        return this.treatment;
    }

    /**
     * Apply footer note before saving.
     * @override
     */
    async update() {
        this.updateFooterNote();
        return super.update();
    }

    /**
     * Advance treatment.
     * @override
     */
    async advance() {
        console.log("TreatmentExaminationController.advance()");
        let startingStage = this.treatment.stage;

        this.treatment = await this.treatmentService.advance(this.treatment);
        this._updateScopeFromTreatment();

        if (startingStage === this.treatment.stage) {
            // Server declined to advance. Reload the controller
            console.log("Treatment didn't advance the stage - reloading the current controller");
            this.reload();
            return false;
        }
        else {
            if (this.treatment.stage == this.TreatmentStage.COMPLETE) {
                // Successfully completed - checkout the patient
                this.visit = await this.visitService.checkout(this.visit);
            }

            // Stage advanced - route to next controller
            this.$scope.$apply(() => this.autoRoute());
            return true;
        }
    }

    /**
     * Retrieve prescription and prescribed vial for an injection
     * @param inj injection
     * @private
     */
    async _getPrescription(inj) {
        if (!this.pscriptPromises) {
            this.pscriptPromises = new Map();
        }

        let rxRef = inj._vial.prescription;
        let promise = this.pscriptPromises.get(rxRef.id);
        if (!promise) {
            promise = this.prescriptionService.get(rxRef);
            this.pscriptPromises.set(rxRef.id, promise);
        }

        inj._prescription = await promise;
        let pVial = inj._prescription.vials.find(pVial => pVial.barcode === inj._vial.barcode);
        inj._prescribedVial = pVial;
    }

    /**
     * Update the instructions when a new position receives focus.
     * @param pos 1-based position
     * @private
     */
    _setInstructionText(pos) {
        this.$scope.text = '';

        if (this.$scope.injections) {
            let injection = this.$scope.injections[pos - 1];
            if (!injection.examinedBy) {
                injection.examinedBy = {};
            }
            this.$scope.injection = injection;

            if (injection) {
                let vial = injection._vial;

                if (this.treatment.type === this.TreatmentType.SCIT) {
                    this.$scope.text = 'Record Reaction: ' + vial.name + ' (Step ' + (injection.scheduleIndex + 1) + ' - ' + injection.dosage.toFixed(2) + ' mL)';
                    this._populateLastShot(vial);
                }
                else /*SLIT*/ {
                    this.$scope.text = 'Record Reaction - ' + vial.name + ': ' + injection.dosage + (injection.dosage === 1 ? ' drop' : ' drops');
                }
            } else {
                this.$scope.text = '';
            }
        }
    }

    /**
     * Is the input box at a position disabled?
     * @param pos 1-based position
     * @returns {boolean}
     * @private
     */
    _isDisabled(pos) {
        if (this.$scope.injections)
            return pos > this.$scope.injections.length
            || this._haveInactiveWarning(this.$scope.injections[pos - 1])
            || this.$scope.injections[pos - 1].reactionWhealSize === -1;
        else
            return true;
    }


    /**
     * Update the vial history $scope data
     * @param vial {TreatmentVial} instance
     * @private
     */
    _populateLastShot(vial) {
        if (!this.$scope.injection._vial._lastShot) {
            this.treatmentService.getVialHistory(vial)
                .then(treatmentList => {

                    // The treatments are not sorted, so process each one and update if it's more recent than the last.
                    let lastShot = {};

                    for (let treatment of treatmentList.list) {

                        // Skip the current treatment - yes, that'll be in the list
                        if (treatment.id == this.treatment.id)
                            continue;

                        // Find the injection that matches this vial
                        for (let injection of treatment.injections) {
                            if (injection.vial.id === this.$scope.injection.vial.id) {
                                if (!lastShot.date || lastShot.date < treatment.performedBy.actionDateTime) {
                                    lastShot = {
                                        'date': treatment.performedBy.actionDateTime,
                                        'dosage': injection.dosage,
                                        'reaction': injection.reactionWhealSize,
                                        'notes': this.treatmentService.getInjectionNotes(injection)
                                    };
                                }

                                break; //out of for:injection
                            }
                        }
                    }

                    // Update view and save in vial for reuse if the user navigates back to it.
                    this.$scope.injection._vial._lastShot = lastShot;
                });
        }
    }

    _getNextPrescribedVials(treatmentVial) {
        let promise = new Promise((resolve, reject) => {
            if (treatmentVial.hasNextVials !== undefined && treatmentVial.hasNextVials !== null) {
                resolve();
            }

            this.treatmentVialService.getNextPrescribed(treatmentVial)
                .then((nextVials) => {
                    treatmentVial.hasNextVials = nextVials && nextVials.length;
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });

        return promise;
    }

    /**
     * User clicked the Update Prescription button. Show a modal.
     * @private
     * @param recommendedRefills {boolean} true if server auto-set some refills.
     * @return Promise resolves when complete
     */
    _showUpdatePrescriptionModal(recommendedRefills) {

        // Create view model
        let vm = {
            vials: [],
            recommend: recommendedRefills,
            treatmentType: this.treatment.type
        };

        // Show modal
        let modalInstance = this.$uibModal.open({
            windowClass: 'updatePrescription',
            resolve: {
                vm: () => {
                    let q = [];

                    /* For each treatment vial retrieve the list of preparative vials created from the treatment vial */
                    for(let i = 0; i < this.treatment.injections.length; i++) {
                        let inj = this.treatment.injections[i];

                        q.push(this._getPrescription(inj));
                        q.push(this._getNextPrescribedVials(inj._vial));
                    }

                    return this.$q.all(q).then(() => {
                        this.treatment.injections.forEach(injection => {
                            injection._vial._isClassical = injection._prescription.vials.some(v => v.classicalDilution);
                            vm.vials.push({
                                /** {guid} ID of vial */
                                id: injection._vial.id,
                                /** {string} Display name */
                                name: injection._vial.name,
                                /** {PrescriptionReason} refill selection - undefined for none. */
                                action: injection.refill,
                                /* Does this vial have any preparatory vials created from it or has it been edited already? */
                                hasNextVials: injection._vial.hasNextVials,
                                /* Is this a classical vial? */
                                isClassical: injection._vial._isClassical,
                                /* Classical dilution of the vial, if applicable */
                                classicalDilution: injection._vial._isClassical ? injection._vial.color : undefined
                            });
                        });

                        return vm;
                    });
                }
            },
            template: require('./widgets/update-prescription.html'),
            css: require('./widgets/update-prescription.scss'),
            controller: function($uibModalInstance, $scope, vm) {
                $scope.vm = vm;

                let getDilutionTitlecase = (classicalDilution) => {
                    return classicalDilution[0] + classicalDilution.substr(1).toLowerCase();
                };

                $scope.options = {
                    slit: ['ADVANCE', 'REPEAT', 'DILUTE_NEW', 'NOMORE'],
                    classical: ['ADVANCE', 'REPEAT', 'DILUTE_VIAL', 'NOMORE']
                };

                $scope.isValidOption = (option, vial) => {
                    if (vm.treatmentType === 'SLIT') {
                        return $scope.options.slit.indexOf(option) > -1;
                    }
                    else if (vial.isClassical) {
                        if (option === 'ADVANCE' && vial.classicalDilution === ClassicalDilutions[0].color) {
                            return false;
                        }
                        else if (option === 'DILUTE_VIAL' && vial.classicalDilution === ClassicalDilutions[ClassicalDilutions.length - 1].color) {
                            return false;
                        }
                        return $scope.options.classical.indexOf(option) > -1;
                    }

                    return true;
                };

                $scope.advanceLabel = (vial) => {
                    if (vial.isClassical && vial.classicalDilution !== ClassicalDilutions[0].color) {
                        const currentDilution = ClassicalDilution[vial.classicalDilution];
                        let currentVialColor = getDilutionTitlecase(currentDilution.color);
                        const nextDilution =  ClassicalDilutions[currentDilution.order - 1]; // - 1 to get less diluted vial
                        let nextVialColor = getDilutionTitlecase(nextDilution.color);

                        let label = `Advance to the next vial: ${nextVialColor}`;
                        return label;
                    }

                    return 'Advance prescription';
                };

                $scope.repeatLabel = (vial) => {
                    if (vial.isClassical) {
                        const currentDilution = ClassicalDilution[vial.classicalDilution];
                        let currentVialColor = getDilutionTitlecase(currentDilution.color);

                        let label = `Repeat current prescription: ${currentVialColor}`;
                        return label;
                    }

                    return 'Repeat current prescription';
                };

                $scope.diluteLabel = (vial) => {
                    if (vial.isClassical && vial.classicalDilution !== ClassicalDilutions[ClassicalDilutions.length - 1].color) {
                        const currentDilution = ClassicalDilution[vial.classicalDilution];
                        let currentVialColor = getDilutionTitlecase(currentDilution.color);
                        const nextDilution =  ClassicalDilutions[currentDilution.order + 1]; // + 1 to get more diluted vial
                        let nextVialColor = getDilutionTitlecase(nextDilution.color);

                        let label = `Dilute ${currentVialColor} to make: ${nextVialColor}`;
                        return label;
                    }

                    return 'Dilute using current vial';
                };

                $scope.setChecked = (vial) => {
                    if (!vial._checked) {
                        vial.action = undefined;
                    }
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.save = () => $uibModalInstance.close(vm);
            }
        });

        modalInstance.rendered
            .then(() => {
                for (let i = 0; i < vm.vials.length; i++) {
                    vm.vials[i]._checked = !!vm.vials[i].action;
                }
            });

        // Save changes
        return modalInstance.result
            .then(vm => {
                for (let idx = 0; idx < vm.vials.length; ++idx) {
                    this.treatment.injections[idx].refill = vm.vials[idx].action;
                }
                return true;
            })
            .catch(() => false)
    }

    _haveAllMeasurements() {
        for (let inj of this.$scope.injections) {
            if (!angular.isNumber(inj.reactionWhealSize)) {
                return false;
            }
        }
        return true;
    }

    _haveWarnings() {
        for(let injection of this.$scope.injections) {
            if (this._haveActiveWarning(injection)) {
                return true;
            }
        }
        return false;
    }

    async _checkWarnings() {
        let hasWarnings = false;
        for (let warnCheckIndex = 0; warnCheckIndex < this.$scope.injections.length; warnCheckIndex++) {
            let inj = this.$scope.injections[warnCheckIndex];

            if (this._haveActiveWarning(inj)) {
                await this._showWarningModal(inj, warnCheckIndex);
                hasWarnings = true;
            }
        }
        return hasWarnings;
    }

        /**
     * Display reaction warning from the server
     */
    async _showWarningModal(injection, injIndex) {
        return this.$q.all([
            this._getPrescription(injection),
            this._getNextPrescribedVials(injection._vial)
        ])
        .then(() => {
            let hasPrepVial = injection._vial.hasNextVials;

            let vm = {
                name: injection._vial.name,
                color: injection._vial.color,
                dosage: injection.dosage,
                measurementPos: (injIndex + 1),
                warningText: injection.reactionWarning.warnText,
                options: [],
                userSelection: null,
                hasPrepVial: hasPrepVial
            };

            if (hasPrepVial) {
                vm.options = [];
            }
            else if (injection._prescribedVial.classicalDilution) {
                let canDilute = injection._prescribedVial.classicalDilution !== ClassicalDilutions[ClassicalDilutions.length - 1].color;
                vm.options = canDilute ? [this.PrescriptionReason.DILUTE_VIAL] : [];
            }
            else {
                vm.options = [this.PrescriptionReason.DILUTE_VIAL, this.PrescriptionReason.DILUTE_NEW];
            }

            var modalInstance = this.$uibModal.open({
                windowClass: 'warningModal',
                resolve: {
                    vm: () => vm
                },
                template: require('../widgets/reaction-warning-modal.html'),
                controller: function($uibModalInstance, $scope, $sce, vm) {
                    $scope.vm = vm;
                    $scope.vm.warningText = $sce.trustAsHtml($scope.vm.warningText.replace(/[0-9]+mm/, "<span class='measure'>$&</span>"));

                    $scope.continue = () => $uibModalInstance.close(vm.userSelection);
                }
            });

            return modalInstance.result;
        })
        .then(userSelection => {
            if (userSelection === 'REMEASURE') {
                this.$scope.thisInput = 'section' + (injIndex + 1);
                this.$scope[this.$scope.thisInput] = '';
                injection.reactionWhealSize = null;
                this.$scope.autoSelectPosition();
            }
            else if (userSelection === 'OVERRIDE') {
                // Physician approved override
                injection.reactionWarning.override = {
                    note: "Proceed with Physician's Approval"
                };
            }
            else if (userSelection === 'BYPASS') {
                this.bypassedWarnings.add(injection.id);
            }
            else {
                injection.refill = userSelection;
            }
        });
    }

    /**
     * (SCIT Only) Check if all treatments have been given for one or more of the treatment vials which are being used to treat the patient today.
     * If any vials are complete, show the finished treatment vials modal.
     */
    async _checkTreatmentCompleted() {
        if (this.treatment.type === this.TreatmentType.SCIT && !this.$scope.injections.some(inj => this._haveActiveWarning(inj)) && this.$scope.injections.every(inj => angular.isNumber(inj.reactionWhealSize))) {
            let finalInjections = [];
            let remixInjections = [];

            for (let inj of this.treatment.injections) {
                if (!inj.injected) {
                    // If the injection was not truly injected, we don't want to consider whether it should advance or if it is completed
                    continue;
                }
                await this._getPrescription(inj);

                let config = null;
                if (inj._prescribedVial.classicalDilution) {
                    let dilution = ClassicalDilution[inj._prescribedVial.classicalDilution].order;
                    config = await this.treatmentConfigService.get(inj._prescription.treatmentConfigs.find(m => m.dilution === dilution).treatmentConfig);
                }
                else {
                    config = await this.treatmentConfigService.get(inj._prescription.treatmentConfigs[0].treatmentConfig);
                }

                let injTreatments = (await this.treatmentService.getVialHistory(inj._vial)).list
                    .filter(treat => treat.id !== this.treatment.id)
                    .map(treat => treat.injections)
                    .reduce((arr, cur) => arr.concat(cur), [])
                    .filter(i => i.injected && i.vial.id === inj.vial.id);

                let injectedDoses = injTreatments.length;
                let shotNumber = injectedDoses + 1;

                inj.shotNumber = shotNumber;
                inj.scheduleLength = config.scitSchedule.length;
                inj.scheduleName = config.name;

                if (shotNumber === config.remixPrompt) {
                    await this._getNextPrescribedVials(inj._vial);
                    // Can only create next rx if there isn't another one mixed already
                    if (!inj._vial.hasNextVials) {
                        remixInjections.push(inj);
                    }
                }

                if (shotNumber >= config.scitSchedule.length) {
                    finalInjections.push(inj);
                }
            }

            if (remixInjections.length >= 1) {
                await this._showRemixTreatmentVialsModal(remixInjections);
                await this.update();
            }
            if (finalInjections.length >= 1) {
                await this._showFinishTreatmentVialsModal(finalInjections);
                await this.update();
            }
        }
    }

    async _showFinishTreatmentVialsModal(finalInjections) {
        for(let inj of finalInjections) {
            await this._getNextPrescribedVials(inj._vial);
        }

        let modalInstance = this.$uibModal.open({
			windowClass: 'finishTreatment',
            template: require('./widgets/finish-treatment-vials.html'),
            css: require('./widgets/finish-treatment-vials.scss'),
            resolve: {
                injections: () => { return finalInjections; }
            },
            backdrop: 'static',
            controller: function($uibModalInstance, $scope, injections) {
                $scope.injections = injections;
                
                for(let inj of $scope.injections) {
                    inj._canStartNext = false;
                    inj._canCreateNext = false;
                    let hasReadyVial = inj._vial.hasNextVials ||
                        (inj._prescribedVial.classicalDilution && inj._prescribedVial.classicalDilution !== ClassicalDilutions[0].color);
                    inj._canStartNext = hasReadyVial;
                    inj._canCreateNext = !hasReadyVial;
                }

                let getDilutionTitlecase = (classicalDilution) => {
                    return classicalDilution[0] + classicalDilution.substr(1).toLowerCase();
                };

                $scope.startNextLabel = (inj) => {
                    let label = 'Start treatment with the next vial';
                    if (inj._prescribedVial.classicalDilution && inj._prescribedVial.classicalDilution !== ClassicalDilutions[0].color) {
                        const currentDilution = ClassicalDilution[inj._prescribedVial.classicalDilution];
                        let currentVialColor = getDilutionTitlecase(currentDilution.color);
                        const nextDilution =  ClassicalDilutions[currentDilution.order - 1]; // - 1 to get less diluted vial
                        let nextVialColor = getDilutionTitlecase(nextDilution.color);

                        label += ` (${nextVialColor})`;
                    }
                    label += ' and end service on this vial';

                    return label;
                };

                $scope.confirm = () => {
                    $uibModalInstance.close();
                };
            }
        });

        return modalInstance.result;
    }

    /**
     * Create next rx modal
     * @private
     */
    _createNextRx(vials) {
        let modalInstance = this.$uibModal.open({
            template: require('../../../pages/common/create-next-rx/layout.html'),
            css: require('../../../pages/common/create-next-rx/styles.scss'),
            controller: CreateNextRxModalController,
            resolve: {
                vials: () => vials,
                practice: () => this.$scope.practice
            }
        });
        return modalInstance.closed;
    }

    /**
     * Create next traditional rx modal
     * @private
     */
    async _createNextClassicalRx(oldVial) {
        let modalInstance = this.$uibModal.open({
            template: require('../../../pages/common/create-next-classical-rx/layout.html'),
            css: require('../../../pages/common/create-next-classical-rx/styles.scss'),
            controller: CreateNextClassicalRxModalController,
            windowClass: 'create-next-classical-rx',
            resolve: {
                oldVial: () => oldVial,
                practice: () => this.$scope.practice,
                isTraditionalArrangement: () => {
                    return this.boardService.getAtPractice(this.$scope.practice, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS)
                        .then((boards) => boards.list.some(board => board.arrangement === this.BoardArrangement.TRADITIONAL));
                }
            }
        });

        return modalInstance.closed;
    }

    async _showRemixTreatmentVialsModal(remixInjections) {
        let $scope = this.$scope;

        return this.$uibModal.open({
            windowClass: 'remixTreatment',
            template: require('./widgets/remix-treatment-vials.html'),
            css: require('./widgets/remix-treatment-vials.scss'),
            resolve: {
                injections: () => { return remixInjections; }
            },
            backdrop: 'static',
            controller: function ($uibModalInstance, $scope, injections) {
                $scope.injections = injections;
                
                $scope.confirm = () => {
                    $uibModalInstance.close(!!$scope.createNextRx);
                };
            }
        }).result.then(async (createNextRx) => {
            if (!createNextRx) {
                return;
            }
            else {
                let injectionVials = remixInjections.map(i => {
                    i._vial._prescription = i._prescription;
                    return i._vial;
                });
                console.log('remixInjections', remixInjections);
                if (!remixInjections[0]._prescription.diluteTo) {
                    await $scope.createNextRx(injectionVials[0], injectionVials);
                }
                else {
                    for (let i = 0; i < injectionVials.length; i++){
                        injectionVials[i].isClassical = true;

                        await $scope.createNextRx(injectionVials[i], undefined);
                    }
                }
            }
        });
    }

    /**
     * Have an warning, and it has not been bypassed in some way
     * @param inj {Injection}
     * @returns {boolean} true if have a warning and it has been bypassed - false if no warning
     * @private
     */
    _haveActiveWarning(inj) {
        return inj.reactionWarning && !(inj.reactionWarning.override || inj.refill || this.bypassedWarnings.has(inj.id));
    }

    /**
     * Have an warning, but it's been bypassed by physician or refill?
     * @param inj {Injection}
     * @returns {boolean} true if have a warning, but it has been overridden or refilled - false if no warning.
     * @private
     */
    _haveInactiveWarning(inj) {
        return inj.reactionWarning && (inj.reactionWarning.override || inj.refill || this.bypassedWarnings.has(inj.id));
    }
}
