'use strict';

import BaseTreatmentController from '../base-treatment.controller'

export default class TreatmentCheckListController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.checklistService = $injector.get('checklistService');
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.uibDateParser = $injector.get('uibDateParser');
        this.Procedure = $injector.get('Procedure');
        this.TreatmentType = $injector.get('TreatmentType');

        // Scope functions
        $scope.noEpiPen = () => this._patientDoesntHaveEpiPen();

        // Scope data
        $scope.text = "Complete Patient Treatment Checklist";
        $scope.itemLabels = [];
        $scope.itemChecked = [];
        $scope.epiPenVerified = undefined;


        // Date picker Options
        $scope.dp = {
            opened: false,
            format: "MM/yyyy",
            minDate: new Date(),
            minMode: 'month'
        };

        $scope.dateOptions = {
            minDate: new Date(),
            showWeeks: false,
            datepickerMode: 'month',
            minMode: 'month'
        };
        // End Date Picker Options


        // Set new date from datepicker
        this.$scope.setNewEpiDate = () => {
            if(this.$scope.datepickerepiPenExpirationObj !== undefined) {
                this.$scope.epiPenExpiration =
                    this.chronologyMappingService.jsUTCDateToISODate(this.$scope.datepickerepiPenExpirationObj);
            }
        };

        // Load data
        this.treatmentLoaded()
            .then(() => {
                if (this.treatment.type === this.TreatmentType.SCIT)
                    return this.checklistService.getForScitTreatment(this.$scope.practice);
                else //SLIT
                    return this.checklistService.getForSlitTreatment(this.$scope.practice);
            })
            .then(checklist => {
                checklist.items.forEach(item => this.$scope.itemLabels.push(item));
                return this.treatmentLoaded();
            })
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.CHECKLIST]);
        this.disableNext = false;

        this.$scope.location = this.$scope.vialTests.length > 0
            ? this.treatment.clientData.treatwiz.vialTestLocation || 'Arm'
            : undefined;

        this.$scope.epiPenExpiration = this.patient.epiPenExpiration;
        this.$scope.datepickerepiPenExpirationObj = this.chronologyMappingService.isoDateToJsUTCDate(this.$scope.epiPenExpiration);
        this.initFooterNote('Checklist');

        if (this.treatment.epiWarning)
            this._epiPenWarning(this.treatment.epiWarning);
    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext)
            return true;

        let isEpiProblem = !this.$scope.epiPenVerified;
        if (isEpiProblem && this.treatment.epiWarning && this.treatment.epiWarning.override)
            isEpiProblem = false;

        if (isEpiProblem)
            return true;

        for (let idx = 0; idx < this.$scope.itemLabels.length; ++idx) {
            if (!this.$scope.itemChecked[idx])
                return true;
        }

        return false;
    }

    /**
     * PUT this.treatment back to the server, and receive the changes back.
     *
     * @return Promise to the updated Treatment received back from the server
     * @override
     */
    async update() {
        this.treatment.clientData.treatwiz.vialTestLocation = this.$scope.location;
        this.updateFooterNote();

        if (this.patient.epiPenExpiration !== this.$scope.epiPenExpiration) {
            this.patient.epiPenExpiration =  this.chronologyMappingService.endOfMonth(this.$scope.epiPenExpiration);
            this.patient = await this.patientService.update(this.patient);
        }

        this.treatment = await this.treatmentService.update(this.treatment);
        return this.treatment;
    }

    /**
     * Nurse clicked NO, the patient doesn't have their Epinephrine pen.
     * Show a dialog.
     * @private
     */
    _patientDoesntHaveEpiPen() {
        console.log("_patientDoesntHaveEpiPen");
        // Define warning, and then pretend like the server issued it.
        this.treatment.epiWarning = {
            warnText: "Patient does not have an Epinephrine pen."
        };

        this._epiPenWarning(this.treatment.epiWarning);
    }

    /**
     * Server issued a warning about the patient's Epinephrine pen. (No expiration date, or expired.)
     *
     * @param warning {Warning} contains warnText to display. May set override.
     * @private
     */
    _epiPenWarning(warning) {
        let warnText = warning.warnText;
        console.log("_epiPenWarning: " + warnText);

        this.$uibModal.open({
            backdrop: 'static', 
            keyboard: false,
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/epi-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.reset = () => {
                    $scope.overrideText = '';
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    if ($scope.proceedChoice === 'Continue' && $scope.overrideText) {
                        return ($scope.overrideText !== 'Other' || $scope.addNotes);
                    }
                    else {
                        return ($scope.proceedChoice === 'Reschedule');
                    }
                };

                $scope.warnText = warnText;
                $scope.submit = () => {
                    let text = $scope.overrideText;
                    if ($scope.overrideText === 'Other' && $scope.addNotes)
                        text = $scope.addNotes;
                    $uibModalInstance.close(text);
                }
            }
        }).result
            .then(overrideText => {
                if (overrideText === 'Reenter') {
                    // Allow for more data entry.
                }
                else if(overrideText) {
                    warning.override = {note: overrideText};

                    if (!this.nextDisabled())
                        this.nextStep();
                } else {
                    this.abort("Reschedule due to Epinephrine problem.", true);
                }
            });
    }
}
