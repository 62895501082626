'use strict';

export default angular.module("models.notification.action", [])
    .constant("NotificationAction", {
        APPOINTMENT: 'APPOINTMENT',
        CREATE: 'CREATE',
        MIXED: 'MIXED',
        UPDATE: 'UPDATE',
        REMOVED: 'REMOVED',
        VISIT: 'VISIT',
		SEARCH: 'SEARCH',
        EVENT: 'EVENT'
    }).name;