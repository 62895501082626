"use strict";

export default class FreshDeskSupportTicketController {

    static $inject = ["$scope","freshWidgetService"];

    constructor ( $scope , freshWidgetService ) {
        this._freshWidgetService = freshWidgetService;
        this.$scope = $scope;
    }

    /** @public */onUiOpenSupportModal() {
        this._freshWidgetService.getApi().show();
    }

    /** @public */closePopup() {
        this._freshWidgetService.getApi().close();
    }
}