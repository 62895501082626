'use strict';

import BaseController from "../../../../base.controller";
import EditConcentrateModalController from "../../../widgets/edit-concentrate-modal/controller";

import React from 'react'
import { submitPrintJob } from "../../../../../react/print/Printer"
import { PRINTER_DYMO30336_PORTRAIT, ConcentrateLabel } from "../../../../../react/print/Dymo30336Portrait"

export default class ConcentrateDetailsController extends BaseController {

    static $inject = ["$injector", "$scope"];

    constructor($injector, $scope) {
        super($scope, $injector);

        this.$filter = $injector.get('$filter');
        this.$q = $injector.get('$q');
        this.concentrateService = $injector.get('concentrateService');
        this.substanceService = $injector.get('substanceService');
        this.boardService = $injector.get('boardService');
        this.procedure = $injector.get('Procedure');
        this.concentrateUseType = $injector.get('ConcentrateUseType');
        this.notificationType = $injector.get('NotificationType');
        this.$scope.ServiceStatus = this.serviceStatus = $injector.get('ServiceStatus');

        this.$scope.concentrateHistory = [];

        let routeParams = this.getRouteParams();
        if (!routeParams || !routeParams.href) {
            console.log("Failed to load concentrate details due to missing route params.");
            this.routeToPage(this.urlPaths.INVENTORY_CONCENTRATES);
        }
        else {
            this.$scope.fromLocation = this.routingService.extractLocationParams(routeParams, `#${this.urlPaths.INVENTORY_CONCENTRATES}`, 'Concentrate List');
        }

        this.$scope.printBarcodeLabel = (data) => {
            this._printConcentrate(data);
        };

        this._loadConcentrate(routeParams);

        this.$uibModal = $injector.get('$uibModal');
        $scope.concentrateStatusModal = (newStatus) => {
            this.concentrateStatusModal(newStatus);
        };

        this.$scope.onStatusChange =(newStatus)=> {
            if (newStatus === this.serviceStatus.RECALLED || newStatus === this.serviceStatus.EXPIRED) {
                this.$scope.concentrateStatusModal(newStatus);
            }
            else {
                $scope.isRecalled = false;
                this.$scope.concentrateDetails.status = newStatus;
                this._promogulateStatusChange();
            }
        };

        this.$scope.toDetails = (use) => { this._navigateToVialDetails(use); };
        this.$scope.editConcentrateModal =()=> this.editConcentrateModal(this.concentrate.href, this.$scope.concentrateHistory.length);
    } // end constructor

    async _loadConcentrate(href) {
        await this.notificationService.init();

        let concentrate = this.concentrate = await this.concentrateService.get(href);
        this._subscribeToUpdates(concentrate);

        let mySubstance = this.substance = await this.substanceService.get(concentrate.substance, concentrate);

        this.$scope.concentrateDetails = this._buildConcentrateDetails(concentrate, mySubstance);

        await this._loadConcentrateUse(concentrate);
        this._loadEligibleStatusValues();

        this.startAllSubscriptions();
        this.$scope.$digest();
    }

    _buildConcentrateDetails(concentrate, substance) {
        let concentrateDetails = {
            id: concentrate.id,
            barcode: concentrate.barcode,
            description: substance.name,
            mfg: concentrate.manufacturer,
            lot: concentrate.lot,
            volume: concentrate.capacity,
            formulation: this.$filter('agFormulation')(concentrate),
            dilution: this.$filter('dilution')(concentrate.dilution)
        };

        concentrateDetails.status = concentrate.status;
        concentrateDetails.inServiceDate = concentrate.startService;
        concentrateDetails.expirationDate = concentrate.useBy;
        concentrateDetails.endServiceDate = concentrate.endService;

        this.$scope.concentrateDetails = concentrateDetails;
        this.$scope.recallReportHref = concentrate._links.recallReport;

        return concentrateDetails;
    }

    async _loadConcentrateUse(concentrate) {
        let boardSet = new Set();
        let uses = await this.concentrateService.getUses(concentrate);

        for (let use of uses.list) {
            use.status = this.$filter('serviceStatusFilter')(use.status);
            use.dateAdded = this.$filter('date')(use.useDate, 'MM/dd/yyyy');

            // The same board may appear several times in the ConcentrateUse list, but only display it once.
            if (use.board && !boardSet.has(use.board.id)) {
                let board = await this.boardService.getWithNoVials(use.board);

                use.board = board;
                if (board.procedure == this.procedure.SPT) {
                    use.type = this.concentrateUseType.SP_WELL_TRAY;
                } else if (board.procedure == this.procedure.IDT) {
                    use.type = this.concentrateUseType.TESTING_TRAY;
                } else if (board.procedure == this.procedure.MIXING) {
                    use.type = this.concentrateUseType.MIXING_TRAY;
                }

                boardSet.add(use.board.id);
                this.$scope.concentrateHistory.push(use);
            }
            else if (use.treatmentVial) {
                use.type = this.concentrateUseType.TREATMENT_VIAL;

                this.$scope.concentrateHistory.push(use);
            }
        }

        this.$scope.$digest();
    }

    /**
     * EndState: this instance's scope's *eligibleStates* (Array) field will be populated by instances of ServiceStatus
     * (the enumeration of operational states in which a Concentrate may exist). Audigy's business rules lay out
     * operational relations between some states and the subject DM state. This routine sniffs out the DM and populates
     * scope.eligibleStates accordingly.
     *
     * @private
     */
    _loadEligibleStatusValues() {

        this.$scope.eligibleStates = [];

        // As per AAW-826: In Service (can only be selected if Inventory was selected previously)
        if (this.$scope.concentrateDetails.status === this.serviceStatus.INVENTORY) {
            this.$scope.eligibleStates.push(this.serviceStatus.IN_SERVICE);
        }

        // As per AAW-826: Inventory (can only be selected if there are no records in concentrateUse)
        let isNotInUseByAnyConcetrates = (this.$scope.concentrateHistory.length == 0);
        if (isNotInUseByAnyConcetrates) {
            this.$scope.eligibleStates.push(this.serviceStatus.INVENTORY);
        }

        // As per AAW-826 : no operational restrictions.
        this.$scope.eligibleStates.push(this.serviceStatus.DEPLETED);
        this.$scope.eligibleStates.push(this.serviceStatus.RECALLED);
        this.$scope.eligibleStates.push(this.serviceStatus.EXPIRED);
        // As per AAW-826 : Discarded is withheld.
    }

    _navigateToVialDetails(use) {
        let locationParams = this.routingService.createLocationParams('Concentrate Details');
        if (use.board) {
            let /** ReferenceDTO.<Board>*/board = use.board;
            if (board.procedure == this.procedure.SPT) {
                this.routeToPage(this.urlPaths.INVENTORY_WELL_DETAILS, board, locationParams);
            }
            else if (board.procedure == this.procedure.IDT) {
                this.routeToPage(this.urlPaths.INVENTORY_TESTING_DETAILS, board, locationParams);
            }
            else if (board.procedure == this.procedure.MIXING) {
                this.routeToPage(this.urlPaths.INVENTORY_MIXING_DETAILS, board, locationParams);
            }
        } else if (use.patient) {
            this.routeToPage(this.urlPaths.PATIENT_DETAILS, /** ReferenceDTO.<Patient> */use.patient, locationParams);
        } else {
            // should never occur.
            console.log('Unknown concentrate use details route...');
        }
    }

    /**
     * Confirm concentrate status selection.
     * If confirmed, saves the update to the server.
     *
     * @param newStatus {ServiceStatus} to submit if confirmed by user
     * @return {Promise} to null upon completion, regardless of answer.
     */
    concentrateStatusModal(newStatus) {
        let acknowledgeText, paragraphText, titleText;
        if (newStatus == this.serviceStatus.RECALLED) {
            acknowledgeText = 'Mfr. Recalled';
            paragraphText = 'manufacturer recalled';
            titleText = 'Recall';
        }
        else if (newStatus == this.serviceStatus.EXPIRED) {
            acknowledgeText = 'Expired';
            paragraphText = 'expired';
            titleText = 'Expire';
        }
        return this.$uibModal.open({
            windowClass: 'changeConcentrateStatus',
            scope: this.$scope, //passed current scope to the modal
            resolve: {
                statusDisplay: {
                    acknowledgeText: acknowledgeText,
                    paragraphText: paragraphText,
                    titleText: titleText,
                }
            },
            template: require('../../../widgets/concentrate-status-modal.html'),
            css: require('../../../widgets/concentrate-status-modal.scss'),
            controller: function ($uibModalInstance, $scope, statusDisplay) {
                $scope.statusDisplay = statusDisplay;
                $scope.cancel = () => $uibModalInstance.dismiss();
                $scope.acknowledgeChange = () => $uibModalInstance.close();
            }
        }).result
            .then(() => {
                this.$scope.isRecalled = newStatus === this.serviceStatus.RECALLED;
                this.$scope.concentrateDetails.status = newStatus;
                return this._promogulateStatusChange();
            })
            .then(newConc => {
                let href = newConc._links.recallReport;
                if (href)
                    window.location.href = href;
                return null;
            })
            .catch(() => null);
    }

    editConcentrateModal(href, historySize) {

        this.$uibModal.open({
            windowClass: 'addConcentrate',
            scope: this.$scope, //passed current scope to the modal
            template: require("../../../widgets/edit-concentrate-modal/layout.html"),
            css: require("../../../widgets/edit-concentrate-modal/styles.scss"),
            controller: EditConcentrateModalController,
            resolve : {
                "useCase" : ()=> {
                    return historySize === 0 ? EditConcentrateModalController.UseCaseEdit
                                             : EditConcentrateModalController.UseCaseLimited;
                },
                "concentrateHref" : ()=> href
            }
        });
    }

    /**
     * This is more than "setStatusChange". This is setStatusChange AND propogate this news externally
     * to all concerned entities, or put simply, "promogulate" the change.
     */
    _promogulateStatusChange() {

        let originalStatus = this.concentrate.status;
        this.concentrate.status = this.$scope.concentrateDetails.status;

        return this.concentrateService.update(this.concentrate)
            .then(dto => {
                this.concentrate = dto;
                return dto;
            })
            .catch(error => {
                this.concentrate.status = originalStatus;
                this.$scope.concentrateDetails.status = originalStatus;
            });
    }


    async _printConcentrate(data) {
        const concentrate = {
            barcode: data.barcode,
            description: data.description,
            manufacturer: data.mfg,
            lot: data.lot,
            volume: data.volume + ' mL',
            formulation: data.formulation,
            ubd: data.expirationDate,
            dilution: (data.dilution.toLowerCase() !== 'c') ? data.dilution + ' Dilution' : null,
        }
        submitPrintJob(PRINTER_DYMO30336_PORTRAIT, <ConcentrateLabel concentrate={concentrate} />)
    }


    _subscribeToUpdates(concentrate) {
        this.registerSubscription(this.notificationService.subscribeUpdateForConcentrate(this.$scope.practice, this.$scope.office, concentrate))
            .then(null, null, (notification) => {
                this._onUpdateNotification(notification);
            });
    }

    async _onUpdateNotification(notification) {
        let concentrate = notification.body;
        if (notification.type != this.notificationType.Concentrate || this.$scope.concentrateDetails.id != concentrate.id) {
            return;
        }

        this.concentrate = concentrate;
        this.$scope.concentrateDetails = this._buildConcentrateDetails(concentrate, this.substance);
    }
}
