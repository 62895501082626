'use strict';

import BaseService from './base.service.js';

export default class PracticeService extends BaseService {

    /**
     * Retrieve practice the user belongs to.
     *
     * @param user
     * @return Promise to a Practice DTO
     */
    getForUser(user) {
        return this.serverAPI.get(user.practice.href);
    }
    
    /**
     * Return the Practice DTO of the logged-in user,
     * or the one selected by a Super-Admin. (See select(practice).)
     *
     * @return Promise to a Practice DTO
     */
    getCurrent() {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.get(globalLinks.sessionPractice));
    }
}
