"use strict";

import angular from "angular";
import uiSectionNavigationItemWidget from "./section-navigation-item/view";

export default angular.module("pages.dashboard.widgets",[])
    .directive("agSectionNavigationItem" , initView)
    .directive('stSelectDateRange', ['$timeout', stSelectDateRange])
    .directive('stFutureDateRange', ['$timeout', stFutureDateRange])
    .name;

function initView(sectionTypes) {
    return new uiSectionNavigationItemWidget(sectionTypes);
}
initView.$inject = ["DashboardSections"];

function dateRangeDirective($timeout, fileName) {
    return {
        template: require(`./${fileName}/${fileName}.html`),
        css: require(`./${fileName}/${fileName}.scss`),
        scope: {
            dateRange: '=',
            allMonth: '=',
            allYear: '=',
            onChange: '='
        },
        link: function(scope) {
            scope.dateRange = scope.allMonth; // Bootstrap instance's VM model
            scope.uiText = "This Month"; // Bootstrap instance's View UI state

            scope.optionChanged = function(jodaPeriodExpression) {
                scope.dateRange = jodaPeriodExpression;
                if (scope.onChange) {
                    $timeout(() => {
                        scope.onChange(scope.dateRange);
                    });
                }
            };
        }
    };
}

function stSelectDateRange($timeout) {
    return dateRangeDirective($timeout, 'stDateFilter');
}

function stFutureDateRange($timeout) {
    return dateRangeDirective($timeout, 'stFutureDateFilter');
}