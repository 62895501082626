"use strict";

import angular from 'angular';

import controller from "./controller";
import ApprovalDetailsController from "./approval-details.controller.js";

export default angular.module('pages.dashboard.sections.approvals', [ ])
    .controller('ApprovalsSectionController', controller)
    .controller('ApprovalDetailsController', ApprovalDetailsController)
    .directive('stApprovalList', [stApprovalList])
    .name;

// Directive for the template containing the smart table approval list (Table of waiting approvals).
function stApprovalList() {
    return {
        template: require('./widgets/st-approval-list.html'),
        css: require('./widgets/st-approval-list.scss')
    };
}
