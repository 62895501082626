'use strict';

import BaseController from './../base.controller.js';
import { panelGroupsModal } from './panel-groups/index';

/**
 * Base class for all testing wizard controllers.
 * Initializes the wizard and includes utility functions.
 *
 * Note: Don't use async/await in there. It messes with the Angular.js $digest
 * cycle and that can't be adjusted for without dictating how the functions
 * must be used by the child classes.
 */
export default class TestingController extends BaseController {

    disableNext = false;
    /** @type{Appointment} DTO */
    appointment = null;

    /** @type{AllergyTestConfig} DTO */
    testConfig;
    /** @type{boolean} Is this a classical allergy test? */
    isClassical;
    /** @type{boolean} Is this a Otolaryngic (ENT) allergy test? */
    isOtolaryngic;

    visit; //{Visit} DTO

    /** @type{boolean} Has the panel or SPT group selection been modified? */
    isPanelGroupsModified = false;

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);
        super.enableBrowserCloseWarning();

        this.allergyTestConfigService = $injector.get('allergyTestConfigService');
        this.appointmentService = $injector.get('appointmentService');
        this.panelService = $injector.get('panelService');
        this.visitService = $injector.get('visitService');
        this.allergyTestService = $injector.get('allergyTestService');
        this.patientService = $injector.get('patientService');
        this.$uibModal = $injector.get('$uibModal');

        $scope.wizardTypeLabel = 'Appointment Type';
        $scope.wizardType = '';
        $scope.questionnaireTab = false;
        $scope.preVitalsTab = false;
        $scope.postVitalsTab = false;
        $scope.idtHistamineTab = false;
        $scope.showIdtTabs = false;
        $scope.idtOnly = false;

        // Scope functions
        $scope.exitModal = () => this.exitModal();
        $scope.disabled = () => this.nextDisabled();
        $scope.update = () => this.update();
        $scope.saveAndExit = () => this.saveAndExit();
        $scope.next = () => this.next();
        $scope.routeToPage = (route, allergyTest) => this.routeToPage(route, allergyTest);

        // update the latest patient data
        let params = this.getRouteParams();
        if (!params || !params.href) {
            console.error("No allergyTest given to initialize testing wizard");
            this.exitToDashboard();
            this.allergyTestPromise = Promise.reject(null);
            return;
        }

        // Loads Patient, Visit, and AllergyTest into scope. Promise lets you know when it's all done.
        this.allergyTestPromise = this.notificationService.init()
            .then(() => this.allergyTestService.getUncached({href: params.href }))
            .then(allergyTest => {
                this.$scope.allergyTest = allergyTest;

                return this.patientService.get(allergyTest.patient, allergyTest); //probably embedded
            })
            .then(patient => {
                if (!patient.activeVisit) {
                    throw "Provided patient doesn't have an activeVisit";
                }

                this.$scope.patient = patient;
                super.lockEntity(patient, () => this.exitToDashboard());
                return this.visitService.get(patient.activeVisit, patient);
            })
            .then(visit => {
                if (visit.allergyTest.id !== this.$scope.allergyTest.id) {
                    throw "Provided patient's activeVisit isn't for this Allergy Test";
                }

                this.visit = visit;
                this.$scope.idtHistamineTab = this.$scope.allergyTest.histamineState.endsWith('IDT') && visit.procedure === 'TESTING';
                this.$scope.questionnaireTab = !!visit.questionnaire;
                this.$scope.preVitalsTab = !!visit.preVitals;
                this.$scope.postVitalsTab = !!visit.postVitals;
                this.$scope.idtOnly = visit.procedure === 'IDT';

                return this._loadAllergyTestConfig();
            })
            .then(() => {
                return this.$scope.allergyTest;

            })
            .catch(reason => {
                console.error(reason);
                this.exitToDashboard();
            });
    }

    /**
     * Returns Promise that resolves when the AllergyTest DTO (and Patient) has finished loading.
     */
    allergyTestLoaded() {
        return this.allergyTestPromise;
    }

    _getRoute(allergyTest) {
        let newPath = null;

        switch (allergyTest.stage) {
            case 'QUESTIONNAIRE':
                newPath = '/testing/questionnaire';
                break;
            case 'PRE_VITALS':
                newPath = '/testing/vitals';
                break;
            case 'SPT_CHECKLIST':
                newPath = '/testing/sptCheckList';
                break;
            case 'SPT_HISTAMINE_TIMER':
                newPath = '/testing/measureSptResult/timer';
                break;
            case 'SPT_HISTAMINE_RESULTS':
                newPath = '/testing/measureSptResult';
                break;
            case 'SPT_ANTIGEN_TIMER':
                newPath = '/testing/measureSptResult/timer';
                break;
            case 'SPT_ANTIGEN_RESULTS':
                newPath = '/testing/measureSptResult';
                break;
            case 'IDT_HISTAMINE_CHECKLIST':
                newPath = '/testing/idtHistamine/checklist';
                break;
            case 'IDT_HISTAMINE_TIMER':
                newPath = '/testing/idtHistamine/timer';
                break;
            case 'IDT_HISTAMINE_RESULTS':
                newPath = '/testing/idtHistamine/measure';
                break;
            case 'IDT_DETAILS':
                newPath = '/testing/idtDetails';
                break;
            case 'IDT_PREPARE':
                newPath = '/testing/prepareIntradermal';
                break;
            case 'IDT_CHECKLIST':
                newPath = '/testing/idtCheckList';
                break;
            case 'IDT_ANTIGEN_TIMER':
                newPath = '/testing/measureIntradermal/timer';
                break;
            case 'IDT_ANTIGEN_RESULTS':
                newPath = '/testing/measureIntradermal';
                break;
            case 'POST_VITALS':
                newPath = '/testing/postVitals';
                break;
            case 'REVIEW':
                newPath = '/testing/testingResults';
                break;
        }

        return newPath;
    }

    /**
     * Route to the correct controller for the Allergy Test's new stage.
     * @param allergyTest
     */
    routing(allergyTest) {
        if (allergyTest.stage === 'NEW') {
            this.advance();
            return;
        }

        if (allergyTest.stage === 'COMPLETE' || allergyTest.stage === 'ABORTED') {
            this.visitService.get(allergyTest.visit).then(visit => {
                if (!visit.checkoutTime) {
                    this.visitService.checkout(visit);
                }
                this.exitToDashboard();
            });
        } else {
            let newPath = this._getRoute(allergyTest);

            if (newPath) {
                this.routeToPage(newPath, allergyTest);
            } else {
                console.log('Unknown TestStage: ' + allergyTest.stage);
                this.exitToDashboard();
            }
        }
    }

    /**
     * If the Allergy Test's stage does not match one of the valid stages accepted by the controller,
     * call routing to get to the right controller.
     *
     * @param validStages
     */
    validateStage(validStages) {
        if (!validStages.includes(this.$scope.allergyTest.stage)) {
            this.routing(this.$scope.allergyTest);
            throw "Test Stage does not match controller. Re-routed.";
        }
    }

    /**
     * Is the Next button disabled?
     * Child classes may override to add some actual logic.
     *
     * @returns {boolean}
     */
    nextDisabled() {
        return this.disableNext;
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Child classes may override.
     */
    next() {
        this.disableNext = true;
        this.update()
            .then(() => this.advance())
            .catch(() => {
                this.disableNext = false;
            });
    }

    advance() {
        let startingTest = this.$scope.allergyTest;
        return this.allergyTestService.advance(startingTest).then(allergyTest => {
            if (startingTest.stage === allergyTest.stage && this.reload) {
                // Server declined to advance. Reload the controller (if it has a reload() function)
                console.log("AllergyTest didn't advance the stage - reloading the current controller");
                this.$scope.allergyTest = allergyTest;
                this.reload();
                return Promise.reject(allergyTest);
            } else {
                this.routing(allergyTest);
                return Promise.resolve(allergyTest);
            }
        });
    }

    /**
     * Abort (prematurely end) a test.
     *
     * @param note text note to add to the performedBy UserAction.
     * @param force if false, abort may be delayed until all timers have cleared.
     */
    abort(note, force) {
        if (!note) {
            return this.exitModal();
        }

        this.disableNext = true;
        let startingTest = this.$scope.allergyTest;
        this.update()
            .then(allergyTest => this.allergyTestService.abort(allergyTest, note, force))
            .then(allergyTest => {
                if (startingTest.stage === allergyTest.stage && this.reload) {
                    // Server declined to abort immediately. Reload the controller (if it has a reload() function)
                    console.log("AllergyTest didn't abort immediately - reloading the current controller");
                    this.$scope.allergyTest = allergyTest;
                    this.reload();
                } else {
                    this.routing(allergyTest);
                }
            });
    }

    /**
     * PUT this.$scope.allergyTest back to the server, and received the changes back into the scope.
     *
     * Override in child class to update the DTO from the view prior to calling super.update().
     *
     * @return Promise to the updated AllergyTest received back from the server
     */
    update() {
        return (this.isPanelGroupsModified
                ? this.appointmentService.update(this.appointment)
                : this.appointmentService.resolved(this.appointment)
            )
            .then((appointment) => {
                this.appointment = appointment;
                this.isPanelGroupsModified = false;

                return this.allergyTestService.update(this.$scope.allergyTest);
            })
            .then(allergyTest => {
                this.$scope.allergyTest = allergyTest;
                return allergyTest;
            });
    }

    /**
     * Save the current allergyTest and exit to dashboard
     */
    saveAndExit() {
        this.disableNext = true;
        this.update().then(() => this.exitToDashboard());
    }

    /**
     * Simply exit back to the dashboard.
     * Nothing to update or query - or already have.
     */
    exitToDashboard() {
        this.routeToPage(this.urlPaths.DASHBOARD_APPOINTMENTS);
    }

    exitModal(onExitConfirmation, onExitCancellation) {
        let haveTimer = angular.isObject(this.$scope.patient.timer);
        let $this = this;

        this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('../../widgets/testing/exit-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.exitFormData = {
                    endApointment: undefined,
                    addNotes: undefined,
                    showRecordReactions: false
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    let reason = $scope.exitFormData.endApointment;
                    if (!reason) {
                        return false;
                    }
                    else if (reason === 'Other') {
                        $scope.exitFormData.showRecordReactions = haveTimer;
                        return !!$scope.exitFormData.addNotes;
                    }
                    else if (reason.includes('reaction')) {
                        $scope.exitFormData.showRecordReactions = haveTimer;
                        return true;
                    }
                    else {
                        $scope.exitFormData.showRecordReactions = false;
                        return true;
                    }
                };

                $scope.cancel = () => {
                    if (onExitCancellation) {
                        onExitCancellation();
                    }
                    $uibModalInstance.dismiss();
                }

                $scope.continue = () => {
                    let note;
                    if ($scope.exitFormData.endApointment === 'Other') {
                        note = $scope.exitFormData.addNotes;
                        if (!note)
                            return;
                    } else {
                        note = $scope.exitFormData.endApointment;
                    }

                    if (onExitConfirmation) {
                        onExitConfirmation();
                    }

                    let force = haveTimer && !$scope.exitFormData.showRecordReactions;
                    $this.abort(note, force);
                    $uibModalInstance.close();
                };
            }
        });
    }

    /**
     * Initialize scope for ag-footer-note.
     * @param label The label used to identify this "other" user-action. Used to find existing note, or create new one.
     */
    initFooterNote(label) {
        this.$scope.footerNote = this.$scope.allergyTest.otherActions.find((ua) => ua.label === label)
            || { label: label, note: "" };
    }

    /**
     * Update allergyTest.otherActions with any change via the footer note.
     * @returns {boolean} true if footer note has changed since last save.
     */
    updateFooterNote() {
        let footerNote = this.$scope.footerNote;

        // If new footer note, add it to the otherActions array.
        if (footerNote.id === undefined) {
            if (footerNote.note.length > 0) {
                this.$scope.allergyTest.otherActions.push(footerNote);
                return true;
            }
            else {
                return false;
            }
        }
        else {
            // Modified existing note?
            return footerNote._isModified;
        }
    }

    /**
     * Load (and cache) the Appointment for this test.
     * @returns {Promise<Appointment>}
     * @protected
     */
    _loadAppointment() {
        if (this.appointment) {
            return Promise.resolve(this.appointment);
        }
        else {
            return this.appointmentService.get(this.visit.appointment)
                .then(appointment => {
                    this.appointment = appointment;
                    return appointment;
            });
        }
    }

    /**
     * Load the Panel selected for this test.
     *
     * @returns {Promise<Panel>}
     * @protected
     */
    _loadPanel() {
        if (this.$scope.panel) {
            return Promise.resolve(this.$scope.panel);
        }
        else {
            return this._loadAppointment()
                .then(appointment => this.panelService.get(appointment.panel))
                .then(panel => {
                    this.$scope.panel = panel;
                    return panel;
                })
        }
    }

    _getMeasureConfig(isPositiveControl) {
        let stage = this.$scope.allergyTest.stage;
        let isSpt = stage === 'SPT_HISTAMINE_RESULTS' || stage === 'SPT_ANTIGEN_RESULTS';

        let measureConfig;
        if (isSpt) {
            if (isPositiveControl) {
                measureConfig = this.testConfig.sptHistamine;
            }
            else {
                measureConfig = this.testConfig.classicalSpt || {wheal: {unit: 'MM', max: 99}};
            }
        }
        else {
            if (isPositiveControl) {
                measureConfig = this.testConfig.idtHistamine || this.testConfig.sptHistamine;
            }
            else {
                measureConfig = this.testConfig.classicalIdt || {wheal: {unit: 'MM', max: 99}};
            }
        }

        return {
            wheal: measureConfig.wheal,
            erythema: measureConfig.erythema
        };
    }

    /**
     * Load (and cache) the AllergyTestConfig for this test.
     * @returns {Promise<AllergyTestConfig>}
     * @protected
     */
    _loadAllergyTestConfig() {
        if (this.testConfig) {
            return Promise.resolve(this.testConfig);
        }
        else {
            return this.allergyTestConfigService.get(this.$scope.allergyTest.config)
                .then(testConfig => {
                    this.testConfig = testConfig;
                    this.isClassical = !!this.testConfig.classicalSpt;
                    this.isOtolaryngic = (this.testConfig.spt.length > 0);

                    const hasIdt = (this.$scope.allergyTest.idtLocation !== 'NONE');
                    this.$scope.showIdtTabs = hasIdt;
                    this.$scope.wizardType =
                        this.isClassical && !hasIdt ? 'Traditional SPT Testing' :
                            this.isClassical && hasIdt ? 'Traditional SPT+IDT Testing' :
                                hasIdt ? 'MQT Testing' :
                                    'SPT Testing';
                });
        }
    }

    /**
     * Translate a {Measurements} DTO to a view input string
     * @param m {Measurements}
     * @param isPositiveControl {boolean}
     * @returns {string|number}
     * @protected
     */
    _measurementsToView(m, isPositiveControl) {
        if (!m) {
            return '';
        }

        let measureConfig = this._getMeasureConfig(isPositiveControl);
        if (measureConfig.wheal && m.wheal != undefined)  {
            if (m.wheal < 0)
                return 'X';
            else if (measureConfig.erythema && m.erythema != undefined)
                return m.wheal + '-' + m.erythema;
            else
                return m.wheal;
        }
        else if (measureConfig.erythema && m.erythema != undefined) {
            return (m.erythema < 0) ? 'X' : m.erythema;
        }
        else {
            return '';
        }
    }

    /**
     * Translate an input from the view into a {Measurements} DTO.
     * @param value {string|number}
     * @param isPositiveControl {boolean}
     * @returns {Measurements}
     * @protected
     */
    _viewToMeasurements(value, isPositiveControl) {
        if (value === '' || value == undefined) {
            return {};
        }

        let measureConfig = this._getMeasureConfig(isPositiveControl);
        if (typeof value === 'number') {
            if (measureConfig.erythema)
                return { erythema: Math.abs(value) };
            else
                return { wheal: Math.abs(value) };
        }
        else if (value === 'X' || value === 'X-' || value === 'X-X') {
            return {
                wheal: measureConfig.wheal ? -1 : undefined,
                erythema: measureConfig.erythema ? -1 : undefined
            };
        }
        else if (typeof value === 'string' && measureConfig.wheal) {
            const [wheal,erythema] = value.split('-').map(m => parseInt(m));
            return {
                wheal: isNaN(wheal) ? undefined : wheal,
                erythema: isNaN(erythema) ? undefined : erythema
            };
        }
        else if (measureConfig.erythema) {
            const erythema = Math.abs(value);
            return {
                erythema: isNaN(erythema) ? undefined : erythema
            };
        }
        else {
            return {};
        }
    }

    /**
     * Open modal for selecting SPT Board and groups.
     *
     * @param callback function that takes the Panel as first argument, and sptGroups string as second.
     * @returns {Promise<void>}
     */
    async openPanelGroupsModal(callback) {
        // Get available testing panels
        if (!this.availablePanels) {
            this.availablePanels = await this.panelService.getActiveAtPractice(this.$scope.practice);
            this.availablePanels = (this.availablePanels.list || []).filter(m => m.type === 'TESTING');

            // Embedded useful data into each Panel (async)
            await Promise.all(this.availablePanels.map(panel => this.panelService.transform(panel)));

            // Add group names in each panel
            this.availablePanels.forEach(panel => this.panelService.populateSptGroupNames(this.$scope.practice, panel));
        }

        const appointment = await this._loadAppointment();
        const selectedPanelId = appointment.panel ? appointment.panel.id : '';
        const selectedPanel = (this.availablePanels.find(p => p.id === selectedPanelId) || {});
        const sptGroups = this.$scope.allergyTest.sptGroups;

        this.$uibModal.open(panelGroupsModal(this.availablePanels, selectedPanel, sptGroups))
            .result
            .then((selections) => {
                this.isPanelGroupsModified =
                    this.isPanelGroupsModified ||
                    !this.appointment.panel ||
                    this.appointment.panel.id !== selections.panel.id ||
                    this.$scope.allergyTest.sptGroups !== selections.sptGroups;

                this.appointment.panel = selections.panel
                    ? { id: selections.panel.id, href: selections.panel.href } : null;
                this.$scope.allergyTest.sptGroups = selections.sptGroups;

                if (callback)
                    callback(selections.panel, selections.sptGroups);
            })
            .catch(() => {
                // Cancelled - ensure old values are selected
                if (callback)
                    callback(selectedPanel, sptGroups);
            });
    }

    /**
     * Display a warning message when a negative control has a reaction
     * @returns a Promise that completes when the model closes.
     */
    negControlWarningModal() {
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            template: require('./widgets/testing-neg-ctrl-reaction-modal.html'),
            controller: function ($uibModalInstance, $scope) {
                $scope.done = () => {
                    $uibModalInstance.close('ok');
                }
            }
        });

        return modalInstance.result;
    }
}
