"use strict";

import angular from "angular";
export default angular.module("common.filters.dilution", [])
    /**
     * This filter accepts strings (expected to represent positive decimal integer scalar values), and returns
     * a string of either the same value OR a "C" (capital latin 'C' character) for values logically equalling
     * zero.
     */
    .filter("dilution", [ () => {

        let mapNumericForm =(/** {Number} */t)=> {
            if (t == 0) return "C";
            if (t > 0) return "#"+t;
        };

        return function(rawLex) {

            if (false == angular.isDefined(rawLex))
                return "";

            if (angular.isNumber(rawLex)) {
                return mapNumericForm(rawLex);
            }

            if (angular.isString(rawLex)) {
                if (false == isNaN(rawLex) ) {// then the string represents a number
                    return mapNumericForm(parseInt(rawLex, 10));
                }
            }

            console.error("common.filters.dilution() encountered unexpected value '" + rawLex + "'");
            return "";
        };
    }])
    .name;