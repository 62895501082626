'use strict';

/**
 * Base class for use by the (Dto)Service classes.
 * Provides construction and methods that are the same for all.
 */
export default class BaseService {

    static $inject=['serverAPI'];

    constructor(serverAPI) {
        this.serverAPI = serverAPI;
    }

    /**
     * @returns {Promise} to GlobalLinks
     */
    getGlobalLinks() {
        return this.serverAPI.globalLinksPromise;
    }

    /**
     * Get a DTO by its ReferenceDTO.
     * Only use this interface if the ReferenceDTO has just been received from the server as part of another DTO.
     * When the ReferenceDTO was received via page navigation, use getUncached().
     *
     * @param ref ReferenceDTO to a DTO type
     * @param referencingDTO the DTO containing the reference (and possibly the embedded value)
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     */
    get(ref, referencingDTO) {
        return this.serverAPI.get(ref.href, {}, referencingDTO)
            .then(dto => this.transform(dto));
    }

    /**
     * Get a DTO by its ReferenceDTO, bypassing the browser cache.
     * The latest version will always be fetched from the server, AND the results of this will not be placed in cache.
     * However, the new DTO will have an updated href to the latest version of the data, which when used will be cached.
     * Use this when the ReferenceDTO you have may be out of date, and so you need to ensure that old data isn't pulled
     * from the browser cache. A reference receive via page navigation is always regarded as out of date.
     *
     * @param ref ReferenceDTO to a DTO type
     * @param params is a key/value map for the query string, and may be empty.
     * @return Promise that will resolve to the desired DTO, or reject to a ServerError.
     */
    getUncached(ref, params) {
        let href = this._stripVersion(ref.href);
        return this.serverAPI.get(href, params)
            .then(dto => this.transform(dto));
    }

    /**
     * Update a DTO.
     *
     * @param dto modified DTO
     * @return Promise that will resolve to an updated DTO, or reject with a ServerError.
     */
    update(dto) {
        return this.serverAPI.put(dto.href, {}, dto)
            .then(dto => this.transform(dto));
    }

    /**
     * Remove a DTO instance. (Usually super-admin only.)
     *
     * @param dto
     * @returns Promise that resolves on success, or rejects with a ServerError.
     */
    delete(dto) {
        return this.serverAPI.delete(dto.href);
    }

    /**
     * Return a resolved Promise containing a (cached) DTO value.
     * Use this instead of ES6 Promise so that scope changes in callbacks will be $apply'd,
     * just as they would be if the web server were actually hit.
     *
     * @param dto
     * @return {Promise}
     */
    resolved(dto) {
        return this.serverAPI.$q.resolve(dto);
    }

    /**
     * Hook for sub-classes to transform a DTO from the server into client data model.
     * Normally this will return the original DTO with additional meta-data (typically fields starting with underscore).
     * It _could_ return something entirely different, but that would break update().
     *
     * Called automatically by BaseService functions, but also may be used directly if a DTO is loaded by other means.
     * Base class just returns the unmodified DTO.
     *
     * @param dto
     * @return original / new / modified dto or Promise to one
     */
    transform(dto) {
        return dto;
    }

    /**
     * Helper to strip the version off of an href, thus forcing the browser's cache to be skipped.
     * @param href
     * @returns {string} updated href
     * @protected
     */
    _stripVersion(href) {
        return href.split(';v', 2)[0];
    }
}
