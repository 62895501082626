'use strict';

import BaseController from './../base.controller.js';

export default class BaseTreatmentController extends BaseController {

    /** Is the next button disabled? */
    disableNext = true;

    /** Loaded Treatment DTO */
    treatment = null;

    /** Loaded Visit DTO */
    visit = null;

    /** Loaded Patient DTO */
    patient = null;

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);
        super.enableBrowserCloseWarning();

        this.patientService = $injector.get('patientService');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.treatmentService = $injector.get('treatmentService');
        this.visitService = $injector.get('visitService');
        this.TreatmentStage = $injector.get('TreatmentStage');
        this.$uibModal = $injector.get('$uibModal');

        // Scope functions
        $scope.nextDisabled = () => this.nextDisabled();
        $scope.exitModal = () => this.exitModal();
        $scope.update = () => this.update();
        $scope.saveAndExit = () => this.saveAndExit();
        $scope.nextStep = () => this.nextStep();
        $scope.noInjectionsModal = () => this.noInjectionsModal();

        // Scope data
        $scope.wizardTypeLabel = 'Appointment Type';
        $scope.wizardType = 'Patient Treatment';
        $scope.nextButtonLabel = 'Next';
        $scope.treatmentLoaded = false;
        $scope.preVitalsTab = false;
    }

    /**
     * Returns Promise that resolves when the Treatment, Visit, and Patient DTOs have finished loading.
     * Actually begins loading on first call.
     */
    treatmentLoaded() {
        console.log("BaseTreatmentController.treatmentLoaded()");

        if (!this.treatmentPromise) {
            // update the latest patient data
            let params = this.getRouteParams();
            if (!params || !params.href) {
                console.error("No treatment given to initialize treatment wizard");
                this.exitToDashboard();
                this.treatmentPromise = Promise.reject(null);
            }
            else {
                // Loads Patient and Treatment into scope. Promise lets you know when it's all done.
                this.treatmentPromise = this.notificationService.init()
                    .then(() => this.treatmentService.getUncached({href: params.href}))
                    .then(treatment => {
                        this.treatment = treatment;
                        this.$scope.treatmentLoaded = true;
                        this.$scope.wizardType = `Patient Treatment (${treatment.type})`;
                        this.$scope.vialSelectionTab = this.treatment.type === 'SCIT';

                        // Initialize client data
                        if (!angular.isObject(treatment.clientData.treatwiz)) {
                             treatment.clientData.treatwiz = {};
                        }

                        this._updateScopeFromTreatment();
                        return this.visitService.get(treatment.visit);
                    })
                    .then(visit => {
                        this.visit = visit;
                        this.$scope.preVitalsTab = !!visit.preVitals;

                        // Load Patient
                        return this.patientService.get(this.treatment.patient, this.treatment);
                    })
                    .then(patient => {
                        this.patient = this.$scope.patient = patient;
                        super.lockEntity(patient, () => this.exitToDashboard());
                        console.log("BaseTreatmentController.treatmentLoaded() finished loading");
                        return this.treatment;
                    })
                    .catch(reason => {
                        console.error(reason);
                        this.exitToDashboard();
                    });
            }
        }
        return this.treatmentPromise;
    }

    /**
     * Update $scope data from new treatment DTO.
     * @private
     */
    _updateScopeFromTreatment() {
        // Populate $scope variables
        this.$scope.vialTests = [];
        this.$scope.injections = [];
        this.$scope.vialTestTab = false;
        this.$scope.injectionsTab = false;

        this.treatment.injections.forEach(injection => {
            if (injection.idtDosage > 0) {
                this.$scope.vialTests.push(injection);
                this.$scope.vialTestTab = true;
            }

            if (this.isApplicableInjection(injection)) {
                this.$scope.injections.push(injection);
                this.$scope.injectionTab = true;
            }
        });
    }

    isApplicableInjection(injection) {
        return injection.dosage > 0 || (injection.plan === 'NO_TREAT_EXPIRED' && injection.planOverrideBy);
    }

    /**
     * Is the Next button disabled?
     * Child classes may override to add some more logic.
     *
     * @return {boolean}
     */
    nextDisabled() {
        return this.disableNext;
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Child classes should override/extend.
     * @return Promise to updated Treatment
     */
    async nextStep() {
        console.log("BaseTreatmentController.nextStep()");

        // Disable Next button, so it isn't clicked again
        this.disableNext = true;

        // Save updated prescription
        await this.update();

        // Try to advance to the next stage
        await this.advance();

        return this.treatment;
    }

    /**
     * PUT this.treatment back to the server, and receive the changes back.
     *
     * Override in child class to update the DTO from the view prior to calling return super.update().
     *
     * @return Promise to the updated Treatment received back from the server
     */
    update() {
        return this.treatmentService.update(this.treatment).then(treatment => {
            this.treatment = treatment;
            this._updateScopeFromTreatment();
            return this.treatment;
        });
    }

    /**
     * Try to advance to the next TreatmentStage. Save changes with update() first.
     * Typically called via nextStep(), rather than directly.
     *
     * @returns {Promise to boolean} true if advanced, false if did not.
     */
    async advance() {
        console.log("BaseTreatmentController.advance()");
        let startingStage = this.treatment.stage;

        this.treatment = await this.treatmentService.advance(this.treatment);
        this._updateScopeFromTreatment();

        if (startingStage === this.treatment.stage) {
            // Server declined to advance. Reload the controller
            console.log("Treatment didn't advance the stage - reloading the current controller");
            this.reload();
            return false;
        } else {
            // Stage advanced - route to next controller
            this.$scope.$apply(() => this.autoRoute());
            return true;
        }
    }

    /**
     * Reload the page when advance() failed.
     * Child classes should override
     */
    async reload() {
        this.$scope.$apply(() => this.autoRoute());
    }

    /**
     * Save the current Treatment and exit to dashboard
     */
    async saveAndExit() {
        console.log("BaseTreatmentController.saveAndExit()");
        this.disableNext = true;
        await this.update();
        this.$scope.$apply(() => this.exitToDashboard());
    }

    abort(note, checkoutToo) {
        console.log("BaseTreatmentController.abort()");
        if (!note) {
            return this.exitModal();
        }

        this.disableNext = true;
        return this.update()
            .then(() => this.treatmentService.abort(this.treatment, note))
            .then(treatment => {
                this.treatment = treatment;

                if (checkoutToo) {
                    this.visitService.checkout(this.visit).then((visit) => {
                        this.visit = visit; // update visit w/ checkout time, so autoroute doesn't try to checkout again
                        this.autoRoute();
                    });
                } else {
                    this.autoRoute();
                }

            });
    }

    /**
     * Simply exit back to the dashboard.
     * Nothing to update or query - or already have.
     */
    exitToDashboard() {
        this.routeToPage(this.urlPaths.DASHBOARD_APPOINTMENTS);
    }

    /**
     * Automatically route the the proper wizard tab based on the state of the Treatment.
     */
    autoRoute() {
        console.log("BaseTreatmentController.autoRoute()");
        let newPath = null;

        switch (this.treatment.stage) {
            case this.TreatmentStage.NEW:
                this.advance();
                return;
            case this.TreatmentStage.VIAL_SELECTION:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_VIAL_SELECTION;
                break;
            case this.TreatmentStage.QUESTIONNAIRE:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_QUESTIONNAIRE;
                break;
            case this.TreatmentStage.PRE_VITALS:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_PRE_VITALS;
                break;
            case this.TreatmentStage.CHECKLIST:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_CHECKLIST;
                break;
            case this.TreatmentStage.VIAL_TEST:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_VIAL_TEST;
                break;
            case this.TreatmentStage.IDT_TIMER:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_IDT_TIMER;
                break;
            case this.TreatmentStage.IDT_RESULTS:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_IDT_RESULTS;
                break;
            case this.TreatmentStage.INJECTION:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_INJECTION;
                break;
            case this.TreatmentStage.REACTION_TIMER:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_REACTION_TIMER;
                break;
            case this.TreatmentStage.EXAMINATION:
            case this.TreatmentStage.REFILL:
                newPath = this.urlPaths.DASHBOARD_TREATMENT_EXAMINATION;
                break;
            case this.TreatmentStage.COMPLETE:
            case this.TreatmentStage.ABORTED:
                if (!this.visit.checkoutTime) {
                    this.visitService.checkout(this.visit);
                }
                break;
            default:
                console.log("Unknown treatment stage: " + this.treatment.stage);
                break;
        }

        if (newPath)
            this.routeToPage(newPath, /** TreatmentDTO */this.treatment);
        else
            this.exitToDashboard();
    }

    /**
     * If the Treatment's stage does not match one of the valid stages accepted by the controller,
     * call routing to get to the right controller.
     *
     * @param validStages
     */
    validateStage(validStages) {
        if (!validStages.includes(this.treatment.stage)) {
            this.autoRoute();
            throw "Treatment Stage does not match controller. Re-routed.";
        }
    }

    /**
     * Initialize scope for ag-footer-note.
     * @param label The label used to identify this "other" user-action. Used to find existing note, or create new one.
     */
    initFooterNote(label) {
        this.$scope.footerNote = this.treatment.otherActions.find((ua) => ua.label === label)
            || { label: label, note: "" };
    }

    /**
     * Update allergyTest.otherActions with any change via the footer note.
     * @returns {boolean} true if footer note has changed since last save.
     */
    updateFooterNote() {
        let footerNote = this.$scope.footerNote;
        if (!footerNote)
            return false;

        // If new footer note, add it to the otherActions array.
        if (footerNote.id === undefined) {
            if (footerNote.note.length > 0) {
                this.treatment.otherActions.push(footerNote);
                return true;
            }
            else {
                return false;
            }
        }
        else {
            // Modified existing note?
            return footerNote._isModified;
        }
    }

    /** 
    * Display a modal when there are no injections to be given
    */
    noInjectionsModal() {
        this.$uibModal.open({
            backdrop: 'static', 
            keyboard: false,
            windowClass: 'noInjection',
            scope: this.$scope, //passed current scope to the modal
            template: require('./questionnaire/widgets/no-injections-modal.html'),
            css: require('./questionnaire/widgets/no-injections-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.modalFormData = { nextAction: null };
                let continuing = false;
                
                // Validate the form to enable/disable Continue button
                $scope.validate = () => {
                    if (continuing) {
                        return false;
                    }
                    
                    return true;
                };

                $scope.continue = () => {
                    continuing = true;
                    $uibModalInstance.close();
                }
            }
        }).result
            .then(() => {
                this.abort('No injections were given.', true);
            });
    }
    
    exitModal() {
        let parent = this;
        this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/exit-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.exitFormData = {
                    endApointment: undefined,
                    addNotes: undefined
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    if ($scope.exitFormData.endApointment === 'Other') {
                        return $scope.exitFormData.addNotes;
                    }  else {
                        return $scope.exitFormData.endApointment;
                    }
                };

                $scope.cancel = () => $uibModalInstance.close('cancel');

                $scope.continue = () => {
                    let note;
                    if ($scope.exitFormData.endApointment === 'Other') {
                        note = $scope.exitFormData.addNotes;
                        if (!note)
                            return;
                    } else {
                        note = $scope.exitFormData.endApointment;
                    }

                    parent.abort(note, true);
                };
            }
        });
    }
}
