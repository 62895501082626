"use strict";

import angular from 'angular';
export default angular.module('pages.patient.list.widgets',[])
    .directive('pdReactionFilterSelect', pdReactionFilterSelect)
    .name;


function pdReactionFilterSelect() {
    return {
        restrict: 'E',
        replace: true,
        template: require('./pd-reaction-filter-select.html'),
        scope: false
    }
}