'use strict';

import TestingController from '../testing.controller'

export default class TestVitalsController extends TestingController {

    static $inject = ['$scope', '$injector', 'visitVitalsService'];
    static stage = 'PRE_VITALS';

    constructor($scope, $injector, visitVitalsService) {

        super($scope, $injector);

        this.visitVitalsService = visitVitalsService;

        this.$scope.text = 'Record Patient Vitals';

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    reload() {
        this.$scope.isPageReadOnly = this.$scope.allergyTest.stage !== TestVitalsController.stage;

        if (!this.$scope.idtOnly && !this.$scope.isPageReadOnly) {
            this.$scope.openPanelGroupsModal = () => this.openPanelGroupsModal();
        }

        if (this.visit.preVitals) {
            this.visitVitalsService.getPreVitalsForVisit(this.visit).then(vitals => {
                if (!vitals.performedBy)
                    vitals.performedBy = {label: "Pre Vitals", note: ""};

                this.$scope.footerNote = vitals.performedBy;
                this.$scope.vitals = vitals;
            });
        }
        else {
            console.error("No pre-vitals. Server should not have put us in this stage. Advancing to next stage.");
            return this.advance();
        }
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        return this.visitVitalsService.update(this.$scope.vitals)
            .then(vitals => {
                this.$scope.vitals = vitals;

                // Contract of update() is to return the allergyTest
                return this.isPanelGroupsModified
                    ? super.update() : this.$scope.allergyTest;
            });
    }

    /**
     * @override
     */
    nextDisabled() {
        let vitals = this.$scope.vitals;

        if (this.disableNext || !vitals)
            return true;

        let allAnswered = true;
        vitals.results.forEach(r => {
            if (r.vital.preRequired || r.value) {
                let v = Number.parseFloat(r.value);
                r._isInvalid = (isNaN(v) || v < 0.0 || v > 999.99);
                if (r._isInvalid) {
                    allAnswered = false;
                }
            }
        });

        return !allAnswered;
    }

    /**
     * @override
     */
    next() {
        this.disableNext = true;

        /*
         * Pre-vitals can be navigated to at any time, so if the user clicks next from this page
         * but the current stage isn't pre-vitals, we navigate to the last stage they haven't yet
         * completed
         */
        if (this.$scope.allergyTest.stage !== TestVitalsController.stage) {
            super.routing(this.$scope.allergyTest);
            return;
        }

        this.update().then(() => {
            let vitals = this.$scope.vitals;

            if (this.$scope.warningFormData && this.$scope.warningFormData.proceedChoice === 'Reschedule') {
                super.abort("Reschedule due to pre-vitals warning.");
            } else if (vitals.warning && vitals.warning.warnText) {

                if (vitals.warning.override) {
                    this.advance();
                } else {
                    this.$scope.warningFormData = {
                        proceedChoice: null,
                        reasonToContinue: null,
                        addNotes: null,
                        warnings: []
                    };

                    for(let vitalResult of vitals.results) {
                        if (vitalResult.warnText) {
                            this.$scope.warningFormData.warnings.push({
                                text: vitalResult.warnText
                            });
                        }
                    }

                    this.warningModal();
                    this.disableNext = false;
                }
            } else {
                this.advance();
            }
        });
    }

    warningModal() {
        this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./vitals-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.reset = () => {
                    $scope.warningFormData.reasonToContinue = '';
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    if ($scope.warningFormData.proceedChoice === 'Continue' && $scope.warningFormData.reasonToContinue) {
                        return ($scope.warningFormData.reasonToContinue !== 'Other' || $scope.warningFormData.addNotes);
                    }
                    else {
                        return ($scope.warningFormData.proceedChoice === 'Reschedule');
                    }
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.continue = () => {
                    $uibModalInstance.close('success');

                    if ($scope.warningFormData.proceedChoice === 'Reschedule') {
                        $scope.vitals.warning.override = {note: 'Reschedule'};
                        $scope.next();
                    }
                    else if ($scope.warningFormData.reasonToContinue === 'Other' && $scope.warningFormData.addNotes) {
                        $scope.vitals.warning.override = {note: $scope.warningFormData.addNotes};
                        $scope.next();
                    }
                    else if ($scope.warningFormData.reasonToContinue === 'Retest vitals') {
                        // Just go back to page for edit
                    }
                    else {
                        $scope.vitals.warning.override = {note: $scope.warningFormData.reasonToContinue};
                        $scope.next();
                    }
                }
            }
        });
    }

}
