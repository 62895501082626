'use strict';

import BaseTreatmentController from '../base-treatment.controller'

export default class TreatmentVitalsController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.visitVitalsService = $injector.get('visitVitalsService');

        // Scope data
        $scope.text = "Record Patient Vitals";

        this.treatmentLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TreatmentVitalsController constructor completed!");
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.PRE_VITALS]);

        if (this.visit.preVitals) {
            this.visitVitalsService.getPreVitalsForVisit(this.visit).then(vitals => {
                if (!vitals.performedBy)
                    vitals.performedBy = {label: "Vitals", note: ""};

                this.$scope.vitals = vitals;
                this.$scope.footerNote = vitals.performedBy;
                this.disableNext = false;
            });
        }
        else {
            console.error("No pre-vitals. Server should not have put us in this stage. Advancing to next stage.");
            return this.advance();
        }
    }

    /**
     * Save changes to server.
     * @override
     */
    async update() {
        this.$scope.vitals = await this.visitVitalsService.update(this.$scope.vitals);

        // Contract of update() is to return the treatment
        return this.treatment;
    }

    /**
     * @override
     */
    nextDisabled() {
        let vitals = this.$scope.vitals;

        if (this.disableNext || !vitals)
            return true;

        let allAnswered = true;
        vitals.results.forEach(r => {
            if (r.vital.preRequired || r.value) {
                let v = Number.parseFloat(r.value);
                r._isInvalid = (isNaN(v) || v < 0.0 || v > 999.99);
                if (r._isInvalid) {
                    allAnswered = false;
                }
            }
        });

        return !allAnswered;
    }

    /**
     * @override
     */
    async nextStep() {
        this.disableNext = true;

        await this.update();

        let vitals = this.$scope.vitals;

        if (this.$scope.warningFormData && this.$scope.warningFormData.proceedChoice === 'Reschedule') {
            super.abort("Reschedule due to pre-vitals warning.", true);
        } else if (vitals.warning && vitals.warning.warnDateTime) {
            if (vitals.warning.override) {
                this.advance();
            } else {
                this.$scope.warningFormData = {
                    proceedChoice: null,
                    reasonToContinue: null,
                    addNotes: null,
                    warnings: []
                };

                for(let vitalResult of vitals.results) {
                    if (vitalResult.warnText) {
                        this.$scope.warningFormData.warnings.push({
                            text: vitalResult.warnText
                        });
                    }
                }

                this.warningModal();
                this.disableNext = false;
            }
        } else {
            this.advance();
        }
    }

    warningModal() {
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('./vitals-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.reset = () => {
                    $scope.warningFormData.reasonToContinue = '';
                };

                // validate the form to dis/enable Continue button
                $scope.validate = () => {
                    if ($scope.warningFormData.proceedChoice === 'Continue' && $scope.warningFormData.reasonToContinue) {
                        return ($scope.warningFormData.reasonToContinue !== 'Other' || $scope.warningFormData.addNotes);
                    }
                    else {
                        return ($scope.warningFormData.proceedChoice === 'Reschedule');
                    }
                };

                $scope.continue = () => {
                    if ($scope.warningFormData.proceedChoice === 'Reschedule') {
                        $scope.vitals.warning.override = {note: 'Reschedule'};
                        $uibModalInstance.close();
                    }
                    else if ($scope.warningFormData.reasonToContinue === 'Other' && $scope.warningFormData.addNotes) {
                        $scope.vitals.warning.override = {note: $scope.warningFormData.addNotes};
                        $uibModalInstance.close();
                    }
                    else if ($scope.warningFormData.reasonToContinue === 'Retest vitals') {
                        $uibModalInstance.dismiss();
                    }
                    else {
                        $scope.vitals.warning.override = {note: $scope.warningFormData.reasonToContinue};
                        $uibModalInstance.close();
                    }
                }
            }
        });

        modalInstance.result.then(() => {
            this.nextStep();
        });
    }

}
