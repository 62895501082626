'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TreatmentMeasureIntradermalController from './measure-intradermal.controller.js';

export default angular.module('pages.treatment.measure.idt', [ngRoute])
    .config(config)
    .controller('TreatmentMeasureIntradermalController', TreatmentMeasureIntradermalController)
    .directive('agVialTestMeasureValues', agVialTestMeasureValues)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_TREATMENT_IDT_RESULTS, {
        template: require('./measure-intradermal.html'),
        css: require('./measure-intradermal.scss'),
        controller: 'TreatmentMeasureIntradermalController'
    });
}

config.$inject = ['$routeProvider', 'UrlPaths'];

function agVialTestMeasureValues($timeout) {
    return {
        restrict: 'AE',
        scope: false,
        link: function (scope, element, attrs) {

            let cancelVialTestsWatch = scope.$watch('vialTests', (newValue, oldValue) => {
                if (angular.isArray(newValue)) {
                    for (let idx = 0; idx < newValue.length; ++idx) {
                        let wheal = newValue[idx].idtWhealSize;
                        scope["section" + (idx + 1)] = !angular.isDefined(wheal) ? '' : wheal < 0 ? 'X' : wheal;
                    }
                    cancelVialTestsWatch();
                }
            });

            scope.autoSelectPosition = function() {
                $timeout(() => { scope.nextTabIndex(1)}, 100);
            };

            scope.nextTabIndex = function (tabindex) {
                for (let attempt = 0; attempt < 2; ++attempt) {
                    for (; tabindex < 9; ++tabindex) {
                        let box = $("input[name='section" + tabindex + "']");
                        if (!box.prop('disabled') && box.prop('value') === '') {
                            box.focus();
                            console.log("nextTabIndex focus on " + tabindex);
                            return;
                        }
                    }

                    // try again from beginning
                    tabindex = 1;
                }
            };

            scope.setValue = function(value) {
                scope.injection.idtWhealSize = (value === 'X' ? -1 : value == '' ? null : parseInt(value));
            };
            
            scope.setFocus = function () {
                var currentFocus = $(document.activeElement);
                scope.currentFocus = currentFocus;
                scope.thisInput = currentFocus.attr('name');
                scope.tabindex = currentFocus.attr('tabindex');
                scope.setInstructionText(scope.tabindex);
            };
            
            scope.selectWheal = function (whealValue) {
                // Set value of grid section to selected wheal value
                scope[scope.thisInput] = whealValue;
                scope.injection.idtWhealSize = whealValue;

                // This is where the tabindex moves on to next input
                if(scope.tabindex < scope.vialTests.length){
                    scope.tabindex++;
                    scope.nextTabIndex(scope.tabindex);
                }
            };

            scope.autoSelectPosition();
        }
    };
}
