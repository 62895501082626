'use strict';
import angular from 'angular';
import ngRoute from 'angular-route';

import TestVitalsController from './vitals.controller.js';

export default angular.module('pages.testing.vitas', [ngRoute])
    .config(config)
    .controller('TestVitalsController',TestVitalsController)
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/vitals', {
        template: require('./vitals.html'),
        controller: 'TestVitalsController'
    });
}

config.$inject = ['$routeProvider'];
