"use strict";

import BaseController from "../../../../pages/base.controller.js";

/** View Model for a substance in a vial */
class SubstanceVM {
    /** {string} Display name */
    name;
    /** {number} Dilution. Don't show if -1 (diluent or sourced from another vial) */
    dilution;
    /** {number} Volume of the substance in ml */
    volume;
}

/** View Model for an injection/shot */
class InjectionVM {
    /** {string} When the treatment was performed */
    actionDateTime;

    /** {string} Location on the Patient where the injection is given. */
    location;

    /** {number} Dose (mLs) of this injection. */
    dose;

    /** {number} Size of the reaction, in mm */
    reactionWhealSize;

    /** {string[]} Notes about the reaction */
    reactionNotes = [];
}

/** View Model for a Treatment Vial */
class VialVM {

    /** {string} ID field of the TreatmentVial that this represents */
    treatmentVialId;

    /** {string} Name of the vial series this is a part of */
    seriesName;

    /** {UserAction} Who ordered this vial */
    orderedBy;

    /** {UserAction} Who mixed this vial */
    mixedBy;

    /** {ServiceStatus} Inventorial status of this vial */
    status;

    /** {string} Use by date of this vial */
    useBy;

    /** {string} Beyond use date of this vial */
    beyondUse;

    /** {string[]} Last vial test date */
    vialTestDates = [];

    /** {SubstanceVM[]} Content of the vial */
    substances = [];

    /** {InjectionVM[]} Injection history of this vial */
    injections = [];
}

class ViewSelection {
    /** {string} Name of this 'view' */
    name;
    /** {VialVM[]} Vials in this view */
    vials;
}

class ViewModel {
    /** {Patient} */
    patient;

    /** Treatment Summary from the passed in from Patient Details */
    treatment;

    /** {Date} Today's date - when this report was generated */
    date = new Date();

    /** {string[]} Array of vial series names */
    availableSeries = [];

    /** {string} User selected vial series name */
    selectedSeries;

    /** {ViewSelection[]} Views available under the current selectedSeries */
    availableViews = [];

    /** {ViewSelection} The selected 'view' */
    selectedView;
}

/**
 * Controller for the Shot History report popup.
 */
export default class ViewVialHistoryModalController extends BaseController {

    /** {ViewVM[]} All of the patient's vials */
    _allVials = [];

    /** {Map<string,Prescription>} Cache for Prescriptions when building up vial data. */
    _rxCache = new Map();

    /** {Map<string,Mix>} Cache for Mixes when building up vial data. */
    _mixCache = new Map();

    /** {Map<string,TreatmentVial} Cache for TreatmentVials when building up data */
    _treatVialCache = new Map();

    /** {ViewModel} Referenced as $scope.vm */
    vm = new ViewModel();

    constructor($scope, $injector, $uibModalInstance, patient, treatmentSummary) {

        super($scope, $injector);

        this.$uibModalInstance = $uibModalInstance;
        this.$filter = $injector.get('$filter');
        this.patientService = $injector.get('patientService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.mixService = $injector.get('mixService');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.treatmentService = $injector.get('treatmentService');
        this.treatmentType = $injector.get('TreatmentType');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.userService = $injector.get('userService');

        this.vm.patient = patient;
        this.vm.treatment = treatmentSummary;

        $scope.vm = this.vm;
        this.$scope.close = () => $uibModalInstance.close();
        this.$scope.onSelectSeries = (series) => this._onSelectSeries(series);
        this.$scope.onSelectView = (view) => this._onSelectView(view);
        this.$scope.isScit = () => this.vm.treatment.treatmentPlan === this.treatmentType.SCIT;
        this.$scope.getDoseVolume = (dose) => this._getDoseVolume(dose);
        this.$scope.getTreatmentReaction = (reactionWhealSize) => this._getTreatmentReaction(reactionWhealSize);

        this._initVM(patient)
            .then(() => this.$scope.$digest())
            .catch(err => console.error(err));
    }

    /**
     * The only way to get injections for a vial is to load the treatments that use it.
     * But if we loaded history per vial, we'd actually end up downloading the same treatments multiple
     * times (once for each vial in the treatment). Thus is is far more efficient just to download
     * the patient's complete treatment history.
     *
     * @param patient
     * @private
     */
    async _initVM(patient) {

        // Load all Treatments
        let treatments = await this.treatmentService.getPatientHistory(patient, 500);

        // Load all Prescriptions

        for (let treatment of treatments.list) {
            for (let injection of treatment.injections) {
                if (injection.injected === false)
                    continue;

                let treatVial = await this._getTreatmentVial(injection.vial);

                // Get the VialVM for this Injection
                let vial = this._allVials.find(vial => vial.treatmentVialId === treatVial.id);
                if (!vial) {
                    let prescription = await this._getRx(treatVial.prescription);
                    let mix = await this._getMix(treatVial.mix);
                    let prescribedVial = prescription.vials.find(m => m.barcode === treatVial.barcode);

                    // Build new VialVM
                    vial = new VialVM();
                    vial.treatmentVialId = treatVial.id;
                    vial.name = treatVial.name;
                    vial.seriesName = prescribedVial.baseName;
                    this.userService.populateUserAction(vial.orderedBy = prescription.orderedBy);
                    this.userService.populateUserAction(vial.mixedBy = mix.mixedBy);
                    vial.status = treatVial.status;
                    vial.useBy = treatVial.useBy;
                    vial.beyondUse = treatVial.beyondUse;

                    let rxVial = await this._getRxVial(treatVial);
                    for (let rxSubstance of rxVial.substances) {
                        let vmSubstance = new SubstanceVM();
                        vmSubstance.volume = rxSubstance.dosage;

                        // Figure out the proper name and clear dilution if N/A
                        let substance = await this.substanceService.get(rxSubstance.substance);
                        let category = await this.substanceCategoryService.get(substance.category);

                        // Conditionally set dilution
                        vmSubstance.dilution = category._isControl || category._isVial ? -1 : rxSubstance.dilution;

                        // Set name of the substance
                        if (rxSubstance.substanceVialId) {
                            vmSubstance.name = 'Vial ' +
                                prescription.vials.find(other => other.id === rxSubstance.substanceVialId).name;
                        }
                        else if (rxSubstance.substanceTreatmentVial) {
                            let sourceVial = await this._getTreatmentVial(rxSubstance.substanceTreatmentVial);
                            vmSubstance.name = sourceVial ? sourceVial.name : "?";
                        }
                        else {
                            vmSubstance.name = substance.name;
                        }

                        vial.substances.push(vmSubstance);
                    }

                    this._allVials.push(vial);
                }

                // Add Standard InjectionVM to vial
                let vmInjection = new InjectionVM();
                vmInjection.actionDateTime = treatment.performedBy.actionDateTime;
                vmInjection.location = injection.location;
                vmInjection.dose = injection.dosage;
                vmInjection.reactionWhealSize = injection.reactionWhealSize;

                if (injection.reactionWarning) {
                    vmInjection.reactionNotes.push(injection.reactionWarning.warnText);
                    if (injection.reactionWarning.override)
                        vmInjection.reactionNotes.push(injection.reactionWarning.override.note);
                }
                if (injection.examinedBy && injection.examinedBy.note)
                    vmInjection.reactionNotes.push(...injection.examinedBy.note.split('\n'));

                vial.injections.push(vmInjection);

                // Add Vial Test InjectionVM to vial
                if(injection.idtDosage){
                    let vmVialTest = new InjectionVM();
                    vmVialTest.actionDateTime = treatment.performedBy.actionDateTime;
                    vmVialTest.location = 'Vial Test';
                    vmVialTest.dose = injection.idtDosage;
                    vmVialTest.reactionWhealSize = injection.idtWhealSize;
                    if (injection.idtWarning) {
                        vmVialTest.reactionNotes.push(injection.idtWarning.warnText);
                        if (injection.idtWarning.override)
                            vmVialTest.reactionNotes.push(injection.idtWarning.override.note);
                    }
                    if (injection.examinedBy && injection.examinedBy.note)
                        vmVialTest.reactionNotes.push(...injection.examinedBy.note.split('\n'));

                    vial.injections.push(vmVialTest);
                }

                // Track newest vial test
                if (injection.idtWhealSize != undefined)
                    vial.vialTestDates.push(vmInjection.actionDateTime);

            }// for each injection
        }// for each treatment

        this._allVials = this._allVials.sort((a,b) => a.name.localeCompare(b.name));

        // Build list of {name: string} objects of the unique series names
        this.vm.availableSeries =
            Array.from(new Set(this._allVials.map(vial => vial.seriesName))).map(name => ({ name }));

        // Free cache memory
        this._rxCache.clear();
        this._treatVialCache.clear();
    }

    /**
     * Helper to find the PrescribedVial that goes with a TreatmentVial.
     *
     * Prescriptions are loaded and cached as needed.
     * @param treatVial
     * @returns {Promise.<T>}
     * @private
     */
    async _getRxVial(treatVial) {
        let rx = await this._getRx(treatVial.prescription);
        return rx.vials.find(rxVial => rxVial.barcode === treatVial.barcode);
    }

    /**
     * Helper to load and cache Prescriptions on demand.
     * @param pscriptRef
     * @returns {Promise<Prescription>}
     * @private
     */
    async _getRx(pscriptRef) {
        let rx = this._rxCache.get(pscriptRef.id);
        if (!rx) {
            rx = await this.prescriptionService.get(pscriptRef);
            this._rxCache.set(rx.id, rx);
        }

        return rx;
    }

    /**
     * Helper to load and cache Mixes on demand.
     * @param mixRef
     * @returns {Promise<Mix>}
     * @private
     */
    async _getMix(mixRef) {
        let mix = this._mixCache.get(mixRef.id);
        if (!mix) {
            mix = await this.mixService.get(mixRef);
            this._mixCache.set(mix.id, mix);
        }
        return mix;
    }

    /**
     * Helper to load and cache TreatmentVials on demand.
     * @param treatVialRef
     * @returns {Promise<TreatmentVial>}
     * @private
     */
    async _getTreatmentVial(treatVialRef) {
        let vial = this._treatVialCache.get(treatVialRef.id);
        if (!vial) {
            vial = await this.treatmentVialService.get(treatVialRef);
            this._treatVialCache.set(vial.id, vial);
        }

        return vial;
    }

    /**
     * Handle user selecting a vial series.
     * @param series
     * @private
     */
    _onSelectSeries(series) {
        this.vm.selectedSeries = series;

        let vialsInSeries = this._allVials.filter(vial => vial.seriesName === series.name);
        this.vm.availableViews = [];
        this.vm.availableViews.push({
            name: 'Summary',
            vials: vialsInSeries
        });

        vialsInSeries.forEach(vial => {
            this.vm.availableViews.push({
                name: vial.name,
                vials: [vial]
            })
        });

        this.vm.selectedView = undefined;
    }

    /**
     * Handle user selecting a vial view.
     * @param vialView
     * @private
     */
    _onSelectView(vialView) {
        this.vm.selectedView = vialView;
    }

    /**
     * Get shot or drop volume with units for a visit
     */
    _getDoseVolume(dose) {
        if (this.$scope.isScit()) {
            return this.$filter('number')(dose, 2) + ' ml';
        }

        let count = this.$filter('number')(dose, 0);
        return count + ' drop' + (count > 1 ? 's' : '');
    }

    /**
     * Get reaction text for a visit
     */
    _getTreatmentReaction(reactionWhealSize) {
        if (this.$scope.isScit()) {
            if (reactionWhealSize !== -1) {
                return reactionWhealSize + ' mm';
            }
            return "Skipped";
        }

        return reactionWhealSize ? 'Yes' : 'No';
    }
}
