"use strict";

// Framework
import angular from 'angular';
// impl derivation
import BaseSectionController from "../../widgets/section-content-panel/controller";

/**
 * View model of a single patient in a list
 */
class PatientView {

    /** {string} patient ID */
    id;

    isLocked = false;

    /** {string} Link to patient photo, if exists */
    photoHref;

    /** {Person} Basic details about this person */
    person;

    /** {LocalDate} Day the patient was born on */
    dayOfBirth;

    /** {string} EMR Chart # (foreign system ID) */
    chartNum;

    /** {Station} Office station where the person can be found */
    station;

    /** {string} A descriptive status about what is happening with the patient */
    status;

    /** {PatientTimer} Active timer, if any */
    timer;

    /** {ReferenceDTO<Visit>} Used by the controller to open a wizard */
    _activeVisit;

    constructor(dm) {
        this.id = dm.id;
        this.update(dm);
    }

    update(dm) {
        this.photoHref = dm.photo ? dm._links.photoThumbnail : undefined;
        this.person = dm.person;
        this.dayOfBirth = dm.dayOfBirth;
        this.chartNum = dm.chartNum;
        this.station = dm.station;
        this.status = dm.status;
        this.timer = dm.timer;
        this._activeVisit = dm.activeVisit;
    }
}

/** View Model for the entire page */
class PageView {
    /** {PatientView[]} Patients in the Waiting Room */
    waitingList = [];
    /** {PatientView[]} Patients in Testing */
    testingList = [];
    /** {PatientView[]} Patients in Treatment */
    treatmentList = [];
    /** {Station} Filter of what lists to display */
    visitTypeShowing;

    constructor(initVisitTypeShowing) {
        this.visitTypeShowing = initVisitTypeShowing;
    }

    clear() {
        this.waitingList = [];
        this.testingList = [];
        this.treatmentList = [];
        // Retain visitTypeShowing filter
    }
}

/**
 * Controller for the Dashboard Appointments page.
 */
export default class AppointmentsSectionController extends BaseSectionController {

    static $inject = ["$injector", "$scope", "DashboardSections", "Station"];

    constructor($injector, $scope, UiSectionTypes, Station) {

        super($injector, $scope, UiSectionTypes.APPOINTMENTS);
        require("./styles.scss");

        // Own resource init
        this.patientService = $injector.get("patientService");
        this.visitService = $injector.get("visitService");
        this.Station = Station;

        $scope.Station = Station;
        $scope.openWizard = (patient) => this._openWizard(patient);
        $scope.vm = new PageView(this.Station.ALL);

        this._fetchDataModel();
    }

    /**
     * This is called when the user selects a different Office.
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this._fetchDataModel();
    }

    /**
     */
    async _fetchDataModel() {
        this.$scope.vm.clear();

        await this.notificationService.init();
        this.unsubscribeAllSubscriptions();
        this._subscribeToPatientVisits();
        this._subscribeToEntityLocks();

        // Now fetch visits from server
        let officePatientList = await this.patientService.getAtOffice(this.$scope.office);
        angular.forEach(officePatientList.list, aPatient => this._enqueuePatientAppointment(aPatient));

        this.startAllSubscriptions();
        this.$scope.$digest();
    }

    /**
     * Put the patient into the designated list.
     * The destination containing instances are incorporated into the UI display of the patients.
     *
     * @param patient {Patient} data model
     */
    _enqueuePatientAppointment(patient) {

        switch (patient.station) {
            case this.Station.WAITING_ROOM:
                if (patient.status !== 'COMPLETE') {
                    this.$scope.vm.waitingList.push(new PatientView(patient));
                }
                break;
            case this.Station.TESTING:
                this.$scope.vm.testingList.push(new PatientView(patient));
                break;
            case this.Station.TREATMENT:
                this.$scope.vm.treatmentList.push(new PatientView(patient));
                break;
        }
    }

    /**** Patient Visit update handling *****/

    _subscribeToPatientVisits() {
        this.registerSubscription(this.notificationService.subscribePatientVisits(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onPatientVisitNotificationReceived(notification));
    }

    _onPatientVisitNotificationReceived(notification) {
        let patient = notification.body;
        let removed = false;

        let findAndUpdate = (list, listStation, dmPatient) => {
            for (let idx = 0; idx < list.length; ++idx) {
                if (list[idx].id === dmPatient.id) {
                    // Found match. Update or remove
                    if (dmPatient.station !== listStation) {
                        list.splice(idx, 1);
                    }
                    else {
                        list[idx].update(dmPatient);
                    }
                    return;
                }
            }

            // Not found, but belongs here, so add.
            if (dmPatient.station === listStation)
                list.push(new PatientView(dmPatient));
        };

        findAndUpdate(this.$scope.vm.waitingList, this.Station.WAITING_ROOM, patient);
        findAndUpdate(this.$scope.vm.testingList, this.Station.TESTING, patient);
        findAndUpdate(this.$scope.vm.treatmentList, this.Station.TREATMENT, patient);
    }

    /**** Patient entity lock handling *****/

    _subscribeToEntityLocks() {
        this.registerSubscription(this.notificationService.subscribeOfficeEntityLockEvents(this.$scope.practice, this.$scope.office))
            .then(null, null, (notification) => this._onEntityLockNotificationReceived(notification));
    }

    _onEntityLockNotificationReceived(notification) {
        let lockEvent = notification.body;

        let updatePatientLock = (list, lockEvent) => {
            for (let patient of list) {
                if (patient.id === lockEvent.id) {
                    patient.isLocked = lockEvent.lock;
                    break;
                }
            }
        };

        // Update isLocked flag on PatientView in any list it is found in
        updatePatientLock(this.$scope.vm.waitingList, lockEvent);
        updatePatientLock(this.$scope.vm.testingList, lockEvent);
        updatePatientLock(this.$scope.vm.treatmentList, lockEvent);
    }

    /**
     * Open the proper wizard for the given patient.
     *
     * @param patient {PatientView)
     */
    _openWizard(patient) {
        // What is the patient here for? Have to check with the visit.
        this.visitService.get(patient._activeVisit).then(visit => {
            if (visit.allergyTest) {
                this.routeToPage("/testing/initialize", visit.allergyTest);
            }
            else if (visit.treatment) {
                this.routeToPage(this.urlPaths.DASHBOARD_TREATMENT_INITIALIZE, visit.treatment);
            }
            else {
                console.log("Unexpected visit procedure: " + visit.procedure);
            }
        });
    }
}
