'use strict';

import BaseMixingController from '../../base-mixing.controller'
import {ClassicalDilution,ClassicalDilutions,toPascalCase} from "../../../../models/classical-dilutions";

export default class MixingVialController extends BaseMixingController {

    /** All concentrates in-service at the office */
    inServiceConcentrates;

    /** Map of substanceId to array of Concentrates that may be used for it. i.e. Map<string,Concentrate[]> */
    substanceConcentrates = new Map();

    static $inject = ['$scope', '$injector', '$document'];
    constructor($scope, $injector, $document) {
        super($scope, $injector);

        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.concentrateService = $injector.get('concentrateService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.globalConfigService = $injector.get('globalConfigService');

        $scope.text = "Loading ...";
        $scope.note = "";
        $scope.allowAutoBarcode = false;
        $scope.isReadyToMix = () => this._isReadyToMix();
        $scope.showTraditionalColor = (substance) => this._showTraditionalColor(substance);

        this.substanceService.getForPractice(this.$scope.practice);
        this._loadGlobalConfig();

        this.prescriptionLoaded([this.TAB_VIAL1, this.TAB_VIAL2, this.TAB_VIAL3, this.TAB_VIAL4,
                                 this.TAB_VIAL5, this.TAB_VIAL6, this.TAB_VIAL7, this.TAB_VIAL8,
                                 this.TAB_VIAL9, this.TAB_VIAL10, this.TAB_VIAL11, this.TAB_VIAL12,
                                 this.TAB_VIAL13, this.TAB_VIAL14, this.TAB_VIAL15, this.TAB_VIAL16,
                                 this.TAB_VIAL17, this.TAB_VIAL18, this.TAB_VIAL19, this.TAB_VIAL20]);

        /*
         * Since this._loadConcentrates() can take a fair amount of time depending on if it needs to load the full mixing board or not,
         * we use the this.rxPromise variable to only wait until the base prescription is loaded before calling this._loadConcentrates()
         * in order to improve the performance of loading data onto the UI
         */
        this.rxPromise
            .then(() => this._loadConcentrates())
            .then(() => this.prescriptionPromise)
            .then(() => this._loadTreatmentConfig())
            .then(() => this._populateDisplay())
            .catch(ex => console.error(ex));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.$scope.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    /**
     * Populate this.substanceConcentrates with Antigen concentrates on the Treatment board,
     * and diluents in service at the office.
     *
     * @returns {Promise} of true when complete
     * @private
     */
    async _loadConcentrates() {
        // Don't load the board if we are only diluting from an existing vial or no board
        if (this.$scope.antigenCount > 0 && this.pscript.mixingBoard) {
            this.board = await this.boardService.getWithCurrentVials(this.pscript.mixingBoard);
            this.$scope.isTraditionalArrangement = this.board.arrangement === this.BoardArrangement.TRADITIONAL;
        }

        this.inServiceConcentrates = (await this.concentrateService.getInServiceAtOffice(this.$scope.office)).list;
        for (let conc of this.inServiceConcentrates) {
            let concArray = this.substanceConcentrates.get(conc.substance.id) || [];
            concArray.push(conc);
            this.substanceConcentrates.set(conc.substance.id, concArray);
        }

        return true;
    }

    /**
     * Populate this.treatmentConfig with the TreatmentConfig used by the current prescription.
     *
     * @returns {Promise}
     * @private
     */
    _loadTreatmentConfig() {
        const treatConfigIdx = (this.isClassical && this.pscript.treatmentConfigs.length > 1)
            ? ClassicalDilution[this.pscript.vials[this.vialIndex].classicalDilution].order
            : 0;

        return this.treatmentConfigService.get(this.pscript.treatmentConfigs[treatConfigIdx].treatmentConfig).then((treatmentConfig) => {
            this.treatmentConfig = treatmentConfig;
        });
    }

    _createMixingSubstance(mixingSubstance, useBy) {
        return {
            barcode: mixingSubstance.barcode,
            useBy: useBy || mixingSubstance.useBy
        };
    }

    /**
     * If the current vial is partially completed, ask the user if they are okay with losing progress before updating.
     *
     * @override
     * @returns False if update prevented, Base mixing controller Update otherwise
     */
    async update() {
        let callSuper = true;

        // If some barcodes have been scanned but not all, prompt with modal to ensure user is OK with continuing
        let mixStarted = this.$scope.vial.substances.some(ps => ps.mixedFromBarcode);
        if (mixStarted && !this.$scope.vial.substances.every(ps => ps.mixedFromBarcode)) {
            callSuper = await this._incompleteVialModal();
        }

        if (callSuper) {
            // Don't send partially mixed vials to the server. Helping curb CLA-1710
            for (let i = 0; i < this.pscript.vials.length; i++) {
                let pv = this.pscript.vials[i];
                let scannedSubstances = pv.substances.filter(ps => ps.mixedFromBarcode);
                if (scannedSubstances.length !== 0 && scannedSubstances.length != pv.substances.length) {
                    // Partially scanned vial, unset the mixedFromBarcodes before sending to the server
                    pv.substances.forEach(ps => {
                        ps.mixedFromBarcode = null;
                    });
                }
            }
            return super.update();
        }
        return false;
    }

    /**
     * All data has been loaded - update scope for display.
     * @return Promise that completes
     * @private
     */
    async _populateDisplay() {

        let vial = this.$scope.vial = this.pscript.vials[this.vialIndex];
        let beyondUseDate = this.pscript.clientData.beyondUseDate;
        if (beyondUseDate && beyondUseDate[vial.id]) {
            vial._beyondUse = beyondUseDate[vial.id];
        }

        this.$scope.text = "Mix Vial " + vial.name;
        this.initFooterNote("Vial " + vial.name);

        // Set vial size
        if (this.pscript.treatmentType === 'SCIT') {
            this.$scope.vialSize = this.treatmentConfig.scit.vialSize;
        }
        else if (this.pscript.treatmentType === 'SLIT') {
            this.$scope.vialSize = this.prescriptionService.isEscalationVial(vial) ? this.treatmentConfig.slit.vialSizeEsc : this.treatmentConfig.slit.vialSizeMaint;
        }

        // Remove substances of 0 volume
        vial.substances = vial.substances.filter(ps => ps.dosage > 0);

        this.$scope.totalVolume = 0; // total volume of substances in vial is not always == vial volume?
        for (let ps of vial.substances) {
            this.$scope.totalVolume += ps.dosage;
            ps.barcodeInput = ps.mixedFromBarcode;

            ps._availableSubstances = [];
            if (ps.substanceVialId) {
                let maintVial = super.getVialById(ps.substanceVialId);
                ps._name = 'Vial ' + maintVial.name;
                ps._availableSubstances.push(this._createMixingSubstance(maintVial));
            }
            else if (ps.substanceTreatmentVial) {
                let sourceVial = await this.treatmentVialService.get(ps.substanceTreatmentVial);
                ps._name = sourceVial.name;
                ps._availableSubstances.push(this._createMixingSubstance(sourceVial));
                ps._useByDate = sourceVial.useBy;
            }
            else if (ps.dilution === 0 || !this.board) {
                // Should be mixed from concentrate, !this.board handles Manual SCIT Prescriptions for Traditional practices, where there's no board and the dilution is not 0 but we want to load from concentrate
                let concList = this.substanceConcentrates.get(ps.substance.id);
                if (!concList) {
                    this._notInServicesModal(ps, false);
                }
                else {
                    ps._name = ps._substance.name;
                    for (let i = 0; i < concList.length; i++) {
                        let c = concList[i];
                        ps._availableSubstances.push(this._createMixingSubstance(c));
                    }
                    // If there are multiple possible concentrates with different lotNum / usedByDate, then show the word 'multiple' for them.
                    // Even with multiple concentrates, if they have the same value, then that value can be shown.
                    ps._lotNum = concList.map(conc => conc.lot).reduce((accumulator, value) => (accumulator !== undefined && accumulator !== value) ? undefined : value) || 'multiple';
                    ps._useByDate = concList.map(conc => conc.useBy).reduce((accumulator, value) => (accumulator !== undefined && accumulator !== value) ? undefined : value) || 'multiple';
                }
            }
            else {
                let foundVial = false;
                for (let trayVial of this.board._currentVials) {
                    if (trayVial.substance.id === ps.substance.id && trayVial.dilution === ps.dilution) {
                        let conc = await this._getConcentrate(trayVial.concentrate);
                        ps._name = ps._substance.name;
                        ps._lotNum = conc.lot;
                        ps._useByDate = conc.useBy;
                        ps._availableSubstances.push(this._createMixingSubstance(trayVial, conc.useBy));
                        ps._color = this.isClassical && ClassicalDilutions[ps.dilution] ? toPascalCase(ClassicalDilutions[ps.dilution].color) : null;
                        foundVial = true;
                        break;
                    }
                }

                if (!foundVial) {
                    this._notInServicesModal(ps, true);
                }
            }
        }

        this.disableNext = false;
        this.disableExit = false;

        // awaits may or may not run - so may or may not have active $digest
        setTimeout(() => this.$scope.$digest());
    }

    _showTraditionalColor(substance) {
        return (this.$scope.isTraditionalArrangement && !substance._substance._category._isControl)
            || (this.isClassical && (substance.substanceVialId || substance.substanceTreatmentVial));
    }

    /**
     * Is the Next button disabled?
     *
     * @override
     * @return {boolean}
     */
    nextDisabled() {
        if (this.disableNext || !this.pscript)
            return true;

        for (let ps of this.$scope.vial.substances) {
            if (ps.dosage > 0 && (!ps.mixedFromBarcode || ps.error))
                return true;
        }

        return false;
    }

    /**
     * What to do when the Next button is click in the wizard.
     * Child classes may override/extend.
     * @return Promise to updated Prescription
     */
    async nextStep() {
        let vial = this.$scope.vial;

        /* Prompt to reprint vial label if applicable */
        this.pscript.clientData.beyondUseDate = this.pscript.clientData.beyondUseDate || {};
        let beyondUseDate = this.pscript.clientData.beyondUseDate;
        let hasNewBud = false;
        if (beyondUseDate[vial.id] !== vial._beyondUse) {
            beyondUseDate[vial.id] = vial._beyondUse;
            hasNewBud = true;
        }

        if (hasNewBud) {
            await this._reprintLabelModal();
        }

        // Intelligently advance vialIndex and tab
        const nextVialIndex = this.pscript.clientData.mixwizard.vialIndex = this.vialIndex + 1;
        let advanceTab = !this.isClassical;

        if (nextVialIndex >= this.pscript.vials.length) {
            this.pscript.clientData.mixwizard.tab = this.TAB_REVIEW;
            advanceTab = false;
        }
        else if (this.$scope.vial.baseName !== this.pscript.vials[nextVialIndex].baseName) {
            advanceTab = true;
        }

        return super.nextStep(advanceTab);
    }

    _reprintLabelModal() {
        let parent = this;
        return this.$uibModal.open({
            windowClass: 'printLabels',
            backdrop: 'static',
            keyboard: false,
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/reprint-label-modal.html'),
            css: require('../../widgets/print-labels-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.print = () => {
                    parent.labelPrinterService.printPrescribedVial(parent.pscript, $scope.vial).then(() => {
                        $uibModalInstance.close();
                    });
                };
            }
        }).result;
    }

    _notInServicesModal(type, isDilution) {
        this.$uibModal.open({
            windowClass: 'mixingAlertModal',
            backdrop: 'static',
            keyboard: false,
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/not-in-service-modal.html'),
            css: require('./widgets/mixing-alert-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.diluent = type._substance.name;
                $scope.dilution = type.dilution;
                $scope.isDilution = isDilution;
            }
        });
    }

    _incompleteVialModal() {
        let mixedSubstanceNames = this.$scope.vial.substances.filter(ps => ps.mixedFromBarcode).map(ps => ps._substance.name);
        return this.$uibModal.open({
            windowClass: 'mixingAlertModal',
            backdrop: 'static',
            keyboard: false,
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/incomplete-vial-modal.html'),
            css: require('./widgets/mixing-alert-modal.scss'),
            controller: function ($uibModalInstance, $scope) {
                $scope.mixedSubstanceNames = mixedSubstanceNames;
                $scope.continue = () => {
                    $uibModalInstance.close(true);
                }

                $scope.cancel = () => {
                    $uibModalInstance.close(false);
                }
            }
        }).result;
    }

    /**
     * The Mix can be completed if at least one vial has been completely mixed, and no vial is partially mixed.
     * @private
     */
    _isReadyToMix() {
        let haveFilledVial = false;
        for (let vial of this.pscript.vials) {
            let vialHasFill = false;
            let vialNeedsFill = false;

            for (let ps of vial.substances) {
                const hasFill = ps.mixedFromBarcode && !ps.error;
                const needFill = ps.dosage > 0 && !hasFill;
                if (hasFill)
                    vialHasFill = true;
                if (needFill)
                    vialNeedsFill = true;
            }

            if (vialHasFill && vialNeedsFill) {
                console.log("Partially filled vial - can't mix");
                return false;
            }
            else if (vialHasFill) {
                haveFilledVial = true;
            }
        }

        // Ready to mix if a vial is filled, and haven't aborted due to a partial vial
        return haveFilledVial;
    }

    /**
     * Optimization helper function. We want to load a concentrate. Normally this will go to the
     * server, but we may already have it in this.inServiceConcentrates, so look there first.
     * @returns {Promise.<void>}
     * @private
     */
    async _getConcentrate(concentrateRef) {
        let conc = null;

        if (this.inServiceConcentrates) {
            conc = this.inServiceConcentrates.find(conc => conc.href === concentrateRef.href);
        }

        if (!conc) {
            conc = await this.concentrateService.get(concentrateRef);
        }

        return conc;
    }
}
