"use strict";

import angular from 'angular';
import BaseController from '../../base.controller.js';

export default class SelfCheckinController extends BaseController {

    /** Milliseconds until form automatically resets for next patient after receiving results. */
    static autoResetTime = 6000;

    /** Milliseconds until form automatically resets for next patient if user doesn't enter anything for manual mode. */
    static manualEntryResetTime = 30000;

    /** Tracks idle timeout for manual entry */
    manualEntryTimer = null;

    static $inject = ['$scope', '$injector', '$timeout'];

    constructor($scope, $injector, $timeout) {
        super($scope, $injector);
        this.$uibModal = $injector.get('$uibModal');
        this.$timeout = $timeout;
        this.chronologyMappingService = $injector.get('chronologyMappingService');
        this.patientService = $injector.get('patientService');

        // Scope functions
        $scope.reset = () => this._resetVM();
        $scope.isWelcome = () => (!$scope.manual && !$scope.complete);
        $scope.isManualEntry = () => ($scope.manual && !$scope.complete);
        $scope.beginManualEntry = () => { $scope.manual = true; }
        $scope.resetManualTimer = () => this._resetManualTimer();
        $scope.attemptPinCheckin = () => this._attemptPinCheckin();
        $scope.attemptManualCheckin = () => this._attemptManualCheckin();
        $scope.toggleLogout = () => this._toggleLogoutModal();
        $scope.authLogout = () => this._authLogout();

        // Date Picker Options
        $scope.dobDatePicker = {
            opened: false,
            format: "MM/dd/yyyy",
            maxDate: new Date()
        };

        $scope.dobDateOptions = {
            datepickerMode: 'year', // start picking with year 1st time
            showWeeks: false,
            minDate: new Date(1900, 1, 1),
            initDate: new Date(2000, 1, 1),
            maxDate: new Date(),
        };

        this._switchUser();

        this._resetVM();

        this.checkinSoundPlayer = new Audio(require('../../../common/sounds/patient-checkin.mp3'));
    }

    /**
     * Reset view-model for new patient.
     * @private
     */
    _resetVM() {
        this._cancelManualTimer();

        this.$scope.manual = false;
        this.$scope.complete = false;
        this.$scope.attempting = false;
        this.$scope.logout = {};

        this.$scope.patient = {
            pin: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            dob: null,
        };
    }

    /**
     * If current user isn't of the role SELFSERVICE, switch to it.
     * @private
     */
    _switchUser() {
        if (!this.$scope.user)
            return; // already one the way to login page

        if (this.userService.isNormalUser(this.$scope.user)) {

            this.userService.switchToSelfService(this.$scope.user)
                .then(kioskUser => {
                    this.$scope.user = kioskUser;
                    this.sessionService.set(this.sessionService.KEY_USER, kioskUser);
                })
                .catch(rejected => {
                    console.error(rejected);
                    this.routeToPage(this.urlPaths.LOGIN_PAGE);
                });

            this.notificationService.disconnect();
        }
    }

    /**
     * If user doesn't keep making changes in manual entry mode, the form will reset to protect privacy.
     * This function is called whenever the user types something in the form.
     * @private
     */
    _resetManualTimer() {
        this._cancelManualTimer();
        this.manualEntryTimer = this.$timeout(() => this._resetVM(), SelfCheckinController.manualEntryResetTime);
    }

    _cancelManualTimer() {
        if (this.manualEntryTimer)
            this.$timeout.cancel(this.manualEntryTimer);
    }

    /**
     * Try to check-in patient based on PIN.
     * @private
     */
    _attemptPinCheckin() {
        this.$scope.logout = {};
        this.$scope.attempting = true;

        // Barcode scanner may not include leading zero digit of an EAN-13 barcode... so add it.
        // (Barcode is based on patientNum, a 32-bit signed int, which maxes out with 10 digits anyhow.)
        if (this.$scope.patient.pin.length === 12)
            this.$scope.patient.pin = '0' + this.$scope.patient.pin;

        this.patientService.selfCheckinPatient(this.$scope.user, this.$scope.patient.pin)
            .then(response => this._processCheckinResponse(response))
            .catch(ex => this._processCheckinResponse({result: 'ERROR'}));
    }

    /**
     * Try to check-in patient based on manually-entered data.
     * @private
     */
    _attemptManualCheckin() {
        this.$scope.logout = {};
        let patient = this.$scope.patient;
        if (patient.firstName && patient.lastName && patient.dob) {
            let dayOfBirth = this.chronologyMappingService.jsUTCDateToISODate(patient.dob);

            this.patientService.selfCheckinPatient(this.$scope.user, null, dayOfBirth, patient.phoneNumber, patient.firstName, patient.lastName)
                .then(response => this._processCheckinResponse(response))
                .catch(ex => this._processCheckinResponse({result: 'ERROR'}));
        }
    }

    /**
     * Process result from patient check-in attempt
     * @param response {SelfCheckinResponse} DTO
     * @private
     */
    _processCheckinResponse(response) {
        if (response.result === 'SUCCESS') {
            this.checkinSoundPlayer.play();

            /** Patient has been checked in */
            let patient = this.$scope.patient;
            patient.firstName = response.person.givenName;
            patient.lastName = response.person.familyName;
            this.$scope.complete = true;

            this.$timeout(() => this._resetVM(), SelfCheckinController.autoResetTime);
        }
        else {
            this._showFailureModal(response);
        }
    }

    _showFailureModal(response) {
        let $timeout = this.$timeout;

        let modal = this.$uibModal.open({
            windowClass: 'errorModal',
            template: require('./errorModal.html'),
            css: require('./errorModal.scss'),
            resolve: {
                vm: () => response
            },
            controller: function ($uibModalInstance, $scope, vm) {
                console.log(vm);
                $scope.vm = vm;
                $scope.done = () => $uibModalInstance.close();

                // Automatically close after delay.
                $timeout(() => $uibModalInstance.dismiss(), SelfCheckinController.autoResetTime);
            }
        });

        modal.result
            .then(() => this._resetVM())
            .catch(() => this._resetVM());
    }

    /**
     * Display the modal for authenticating logout of the kiosk user.
     * @private
     */
    _toggleLogoutModal() {
        let logout = this.$scope.logout;
        logout.showModal = !logout.showModal;

        if (logout.showModal) {
            logout.email = '';
            logout.password = '';
            logout.active = false;
            logout.errorMessage = null;
        }
    }

    /**
     * Authenticate a user and upon success, logout of the kiosk.
     * @private
     */
    _authLogout() {
        let logout = this.$scope.logout;

        logout.active = true;
        this.userService.authenticate(logout.email, logout.password)
            .then(nurse => {
                logout.success = true;
                this.userService.logout({});
            })
            .catch(err => {
                logout.active = false;
                if (err && err.errors)
                    logout.errorMessage = err.errors[0];
                else
                    logout.errorMessage = "Authentication failure.";
            });
    }
}
