'use strict';

import BaseService from './base.service.js';

export default class TreatmentConfigService extends BaseService {

    /**
     * Get TreatmentConfigs for a Practice
     *
     * @return Promise to a TreatmentConfig.List (sorted by name)
     */
    getAtPractice(practice) {
        return this.serverAPI.get(practice._links.treatmentConfig, {});
    }
}