import angular from 'angular';
import ngRoute from 'angular-route';

import TestPrepareIntradermalController from './prepare-intradermal.controller.js';

export default angular.module('pages.testing.prepareIntradermal', [ngRoute])
    .config(config)
    .controller('TestPrepareIntradermalController',TestPrepareIntradermalController)
    .directive('agPrepIntra', ['$timeout', '$parse', agPrepIntra])
    .directive('agPrepScans', ['$timeout', agPrepScans])
    .name;

function config($routeProvider) {

    $routeProvider.when('/testing/prepareIntradermal', {
        template: require('./prepare-intradermal.html'),
        css: require('./prepare-intradermal.scss'),
        controller: 'TestPrepareIntradermalController'
    });
}

config.$inject = ['$routeProvider'];

function agPrepIntra($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {

            // Function to change grid as user moves through allergens.
            scope.changeGrid = function () {
                for(let i=1; i<=100; i++) {
                    let filter_string = "grid" + i;
                    $parse(filter_string).assign(scope);
                    scope[filter_string] = false;

                    if (scope.count <= scope.dataChunkSize * i) {
                        let grid = "#grid-" + i;
                        let testingArea = $("#prepare-testing-area")[0];
                        if (i > 1 && scope.grid !== grid && testingArea.clientWidth !== testingArea.scrollWidth) {
                            // Scroll right 75 pixels (if scrollbar exists)
                            testingArea.scrollLeft += 75;
                        }
                        scope.grid = grid;
                        scope[filter_string] = true;
                        break;
                    }
                }
            };


            scope.setDilutionHeader = function (number) {
                for(let i=0; i<=30; i++) {
                    let filter_string = "dil" + i;
                    $parse(filter_string).assign(scope);
                    scope[filter_string] = false;
                    if(number === i) {
                        scope[filter_string] = true;
                    }
                }
            };

            scope.init = () => {
                $timeout(() => {
                    scope.changeGrid();
                    scope.setDilutionHeader(scope.number);
                }); //  a trick-- $timeout with 0 sec wait will add a new event to the browser event queue
            }
        }
    };
}

function agPrepScans($timeout) {
    return {
        link: function (scope, element, attrs) {

            scope.focusMe = function (index) {
                $timeout(function () {
                    const focusme = element.find('input')[0];
                    if (focusme !== undefined) {
                        focusme.focus();
                    }
                });
            };

            scope.setSubstance = function () {
                scope.selected = scope.count;
                scope.focusMe(scope.count);
                scope.count++;
                if (scope.substancesToTest[scope.count - 1] !== undefined) {
                    if (scope.substancesToTest[scope.count - 1].idtFromBarcode) {
                        scope.scanBarcode(scope.count);
                    }
                }
            };

            scope.scanBarcode = function (id) {
                let substance = scope.substancesToTest[scope.count - 1];
                let element = angular.element(document.getElementById('inputSubstance-' + id));

                if (substance.barcodeInput === "=" && scope.allowAutoBarcode && substance.availableBarcodes.length) {
                    substance.barcodeInput = substance.availableBarcodes[0];
                }

                if (substance.idtFromBarcode) {
                    element.addClass("inactive");
                    substance.barcodeInput = substance.idtFromBarcode;

                    if (scope.count <= scope.substancesToTest.length) {
                        scope.setSubstance(scope.count);
                    }
                } else if (substance.availableBarcodes.indexOf(substance.barcodeInput) > -1) {

                    element.addClass("inactive");
                    if (scope.count <= scope.substancesToTest.length) {
                        substance.idtFromBarcode = substance.barcodeInput;
                        substance.error = false;
                        if (scope.count <= scope.substancesToTest.length) {
                            scope.setSubstance(scope.count);
                        }
                    }
                } else {
                    substance.error = true;
                    element.val('');
                }


            };

            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    event.preventDefault();
                    scope.scanBarcode(scope.count);
                }
            });

            scope.count = 0;

            scope.$watch('substancesToTest', function () {
                scope.count = 0;
                scope.setSubstance();
            });
        }
    };
}
