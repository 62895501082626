"use strict";

import BaseApprovalController from "../base-approval.controller.js";

/**
 * Controller for Treatment Approvals.
 */
export default class TreatmentApprovalController extends BaseApprovalController {

    static NOTES_REL_ORDER_QUESTIONNAIRE = 10;
    static NOTES_REL_ORDER_PRE_VITAL = 20;
    static NOTES_REL_ORDER_VIAL_TEST = 30;
    static NOTES_REL_ORDER_PLAN_OVERRIDE = 35;
    static NOTES_REL_ORDER_INJECTION_OVERRIDE = 40;
    static NOTES_REL_ORDER_REACTIONS = 50;
    static NOTES_REL_ORDER_APPROVAL = 60;
    static NOTES_REL_ORDER_CANCELLATION = 70;

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                          Instance Members
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /** @type{AngularJs.$q} */_$q;

    /** @type{TreatmentService}  */_treatmentSvc;

    /** @type{VisitVitalsService} */_visitVitalsSvc;

    /** @type{QuestionnaireService} */_questionnaireSvc;

    /** @type{TreatmentDataModel} */_subject;

    static $inject =
    /* DI */[  "$scope","$injector"];

    constructor($scope , $injector ) {
        super($scope, $injector);
        this._initInjections($injector);
        this._initUiEvents($scope);
        this._acquireSubjectData($scope.isApproval);
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                         Lifecycle Management
    //
    // Instances will load the subject, the first time driven by _processRoute, and following subsequent
    // mutations, the model is refreshed by _refreshDataModel .
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /**
     * @param {AngularInjectorService} $injector
     */
    _initInjections($injector) {
        this._$q = $injector.get("$q");
        this._$uibModal = $injector.get("$uibModal");
        this._appointmentSvc = $injector.get("appointmentService");
        this._billSvc = $injector.get("billService");
        this._treatmentSvc = $injector.get("treatmentService");
        this._treatmentVialSvc = $injector.get("treatmentVialService");
        this._visitVitalsSvc = $injector.get("visitVitalsService");
        this._questionnaireSvc = $injector.get("questionnaireService");
        this._TreatmentType = $injector.get("TreatmentType");
    }

    _initUiEvents(/**{angular.Scope}*/$scope) {
        $scope.saveAndExit = () => this._saveAndExit();
        $scope.canApproveTreatment = () => this._canApproveTreatment();
        $scope.onUiApprove = () => this._approveTreatment();
    }

    /** @private */
    _acquireSubjectData(/** {boolean} */isApprovalCase) {
        this.$scope.relatedActionName = 'Treatment';

        if (!angular.isDefined(this.$scope.notes)) {
            this.$scope.notes = [];
        }

        let subjectReference = { href : this.$scope.treatmentHref };
        this._initDataModel(subjectReference, isApprovalCase)
            .then( dm => this._createVm(dm))
            .then( vm => this._renderVm(vm));
    }

    /**
     * @private
     * @param {Array.<TreatmentDataModel>} treatment
     * @param {TreatmentVial} matchVial only match injections from this TreatmentVial. If null, match everything.
     *
     * @returns {Promise.<Array.<{{
     *      _treatmentId : {String},
     *      _treatmentActionDateTime : {DateTime}
     *      _performedBy : {USerAction}
     *      href : {URL},
     *      version : {String},
     *      date: {Date},
     *      dosage: {Number},
     *      reaction: {Number}
     * }}>>}
     */
    _extractHistory(treatments, matchVial) {

        let usageList = [];
        let promises = [];

        treatments.forEach(aTreatment => {
            aTreatment.injections.forEach(anInjection => {
                if ((anInjection.dosage > 0 || anInjection.idtDosage > 0) && (matchVial === null || matchVial.id === anInjection.vial.id)) {
                    anInjection._treatmentId = aTreatment.id;
                    anInjection._treatmentActionDateTime = aTreatment.performedBy.actionDateTime;
                    anInjection._performedBy = aTreatment.performedBy;

                    if (matchVial !== null) {
                        anInjection._vial = matchVial;
                    }
                    else {
                        promises.push(this._treatmentVialSvc.get(anInjection.vial)
                            .then(vialDm => anInjection._vial = vialDm));
                    }
                    usageList.push(anInjection);
                }
            });
        });

        return this._$q.all(promises)
            .then(() =>  usageList);
    }

    /** @returns {Promise.<{InjectionsDataModel}>} */_fetchInjections(/** {TreatmentDataModel} */treatmentDm) {

        let historyPromises = [];

        treatmentDm.injections.forEach( anInjection => {
            this.addActionNote(
                [anInjection._vial.name, "Plan Override"].join(" "),
                anInjection.planOverrideBy,
                TreatmentApprovalController.NOTES_REL_ORDER_PLAN_OVERRIDE);

            this.addActionNote(
                [anInjection._vial.name, "Vial Test Skipped"].join(" "),
                anInjection.idtDoseOverrideBy,
                TreatmentApprovalController.NOTES_REL_ORDER_VIAL_TEST);
            
            this.addActionNote(
                [anInjection._vial.name, anInjection.reactionWhealSize === -1 ? "Vial Skipped" : "Dosage Override"].join(" "),
                anInjection.doseOverrideBy,
                TreatmentApprovalController.NOTES_REL_ORDER_INJECTION_OVERRIDE);

            this.addActionNote(
                [anInjection._vial.name, "Reaction"].join(" "),
                anInjection.examinedBy,
                TreatmentApprovalController.NOTES_REL_ORDER_REACTIONS);

            this.addWarningNote(
                [anInjection._vial.name, "Reaction"].join(" "),
                anInjection.reactionWarning,
                TreatmentApprovalController.NOTES_REL_ORDER_REACTIONS+1,
                anInjection.delayedReactionBy || anInjection.examinedBy || treatmentDm.performedBy);

            this.addWarningNote(
                [anInjection._vial.name, "Vial Test"].join(" "),
                anInjection.idtWarning,
                TreatmentApprovalController.NOTES_REL_ORDER_VIAL_TEST,
                anInjection.examinedBy || treatmentDm.performedBy);

            let aHistoryPromise;
            if (treatmentDm.type === 'SCIT') {
                aHistoryPromise = this._treatmentSvc.getVialHistory(anInjection._vial)
                    .then(treatmentList => this._extractHistory(treatmentList.list, anInjection._vial))
                    .then(otherUsage => anInjection._vial._otherUsage = otherUsage);
            }
            else /*SLIT*/ {
                aHistoryPromise = this._treatmentSvc.getPatientHistory(treatmentDm.patient, /*maxResults*/10)
                    .then(treatmentList => this._extractHistory(treatmentList.list, null))
                    .then(otherUsage => anInjection._vial._otherUsage = otherUsage);
            }
            historyPromises.push(aHistoryPromise);
        });
        return this._$q.all(historyPromises);
    }

    async _fetchVisit(treatmentDm/*TreatmentDataModel*/) /*Promise.<{VisitDataModel}>*/ {

        // Load the visit.
        let visitDm = await this.visitService.getUncached(treatmentDm.visit); /*VisitDataModel*/

        let appointmentPromise = this._appointmentSvc.get(visitDm.appointment);

        // Now in parallel, load preVitals, bill, and questionnaire, if they exist
        let preVitalsPromise = visitDm.preVitals
            ? this._visitVitalsSvc.getPreVitalsForVisit(visitDm)
            : Promise.resolve(null);

        let billPromise = this._billSvc.get(visitDm.bill);

        let questionnairePromise = visitDm.questionnaire
            ? this._questionnaireSvc.getForVisit(visitDm)
                : Promise.resolve(null);

        // Once all data is loaded, add them to the visit and return it.
        return this._$q.all([appointmentPromise, preVitalsPromise, billPromise, questionnairePromise])
            .then(results => {
                this.appointment = results[0];
                visitDm.appointment = results[0];
                visitDm.vitals = results[1];
                visitDm.bill = results[2];
                visitDm.questionnaire = results[3];
                return visitDm;
            });
    }

    /**
     * @param {ReferenceDto.<Treatment>} subject
     * @param {boolean} isApprovalCase
     * @returns {Promise.<TreatmentDataModel>}
     */
    _initDataModel(subject, isApprovalCase) {

        let treatmentPromise = (isApprovalCase
                ? this._treatmentSvc.getForApproval(subject)
                : this._treatmentSvc.get(subject));

        return treatmentPromise
            .then(dm => {
                this._subject = dm;

                if (dm.cancelledBy) {
                    this.$scope.isApproval = false;
                }

                return this._$q.all([
                    this.patientService.get(dm.patient, dm).then(p => dm.patient = p),
                    this._fetchVisit(dm).then(visitDm => dm.visit = visitDm),
                    this.userService.getUsers(this.$scope.practice, 'NURSE').then(users => this.$scope.users = users.list),
                    this.userService.populateUserAction(dm.performedBy),
                    this.userService.populateUserAction(dm.approvedBy),
                    this.userService.populateUserAction(dm.cancelledBy)
                ]);
            })
            .then(() => {
                let dm = this._subject;

                if (dm.performedBy) {
                    this.$scope.performedByUser = this.$scope.users.find(p => p.id === dm.performedBy.user.id);
                }

                if (dm.visit.appointment.provider) {
                    this.$scope.orderedByProvider = this.$scope.users.find(p => p.id === dm.visit.appointment.provider.id);
                }

                if (dm.visit.bill.provider) {
                    this.$scope.billToProvider = this.$scope.users.find(p => p.id === dm.visit.bill.provider.id);
                }

                dm.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 0));

                if (dm.visit.questionnaire) {
                    this.addActionNote("Questionnaire", dm.visit.questionnaire.performedBy, TreatmentApprovalController.NOTES_REL_ORDER_QUESTIONNAIRE);
                    this.addWarningNote("Questionnaire", dm.visit.questionnaire.warning, TreatmentApprovalController.NOTES_REL_ORDER_QUESTIONNAIRE + 1, dm.visit.questionnaire.performedBy);
                }

                if (dm.visit.vitals) {
                    this.addActionNote("Pre Vitals", dm.visit.vitals.performedBy, TreatmentApprovalController.NOTES_REL_ORDER_PRE_VITAL);
                    this.addWarningNote("Pre Vitals", dm.visit.vitals.warning, TreatmentApprovalController.NOTES_REL_ORDER_PRE_VITAL + 1, dm.visit.vitals.performedBy);
                }

                this.addActionNote("Approval", dm.approvedBy, TreatmentApprovalController.NOTES_REL_ORDER_APPROVAL);
                this.addActionNote("Cancelled", dm.cancelledBy, TreatmentApprovalController.NOTES_REL_ORDER_CANCELLATION);

                if (dm.stage === 'ABORTED' && dm.performedBy.note) {
                    this.$scope.warningBanner = dm.performedBy.note;
                }

                return this._fetchInjections(dm);
            })
            .then(() => this._subject);
    }

    /** @private */
    _renderVm(/** {TreatmentReviewViewModel} */viewModel) {
        this.$scope.vm = viewModel;
        this.$scope.patient = viewModel.patient;
        this.$scope.orderedBy = viewModel.orderedBy;
        this.$scope.performedBy = viewModel.performedBy;
        this.$scope.vitals = viewModel.vitals;
        this.$scope.approvedBy = viewModel.approvedBy;
        this.$scope.bill = viewModel.bill;
    }

    async _saveUpdatedTreatment(saveNote) {
        try {
            if (this.$scope.bill._dirty) {
                this.$scope.bill.provider = this.$scope.billToProvider;
                this.$scope.bill = await this._billSvc.update(this.$scope.bill);
            }

            this._subject.performedBy.user = this.$scope.performedByUser ? { id: this.$scope.performedByUser.id, href: this.$scope.performedByUser.href } : this._subject.performedBy.user;

            if (!this.appointment.provider || this.$scope.orderedByProvider.id !== this.appointment.provider.id) {
                this.appointment.provider = this.$scope.orderedByProvider ? { id: this.$scope.orderedByProvider.id, href: this.$scope.orderedByProvider.href } : this.appointment.orderedBy;
                await this._appointmentSvc.update(this.appointment);
            }

            if (saveNote && this.$scope.vm.approvalNotes) {
                let otherAction = { note: this.$scope.vm.approvalNotes };
                this._subject.otherActions.push(otherAction);
            }

            await this._treatmentSvc.update(this._subject);
        }
        catch(error) {
            console.log(error);
            this._acquireSubjectData(this.$scope.isApproval);
        }
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                          UI Event Reactions
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    _canApproveTreatment() {
        return !this.$scope.saving
            && !!this.$scope.performedByUser
            && !!this.$scope.orderedByProvider;
    }

    async _saveAndExit() {
        if (!this.$scope.approvedBy) {
            await this._saveUpdatedTreatment(true);
        }

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback();
            }
        });
    }

    async _approveTreatment() {
        this.$scope.saving = true;

        try {
            await this._saveUpdatedTreatment(false);
        }
        catch(error) {
            this.$scope.saving = false;
            return;
        }

        this._treatmentSvc.approve(this._subject, this.$scope.vm.approvalNotes)
            .then(() => {
                this.$scope.onExitCallback();
            })
            .catch(() => {
                this.$scope.saving = false;
                this._acquireSubjectData($scope.isApproval);
            });
    }

    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *
    //                                     View Model creation/mapping
    // These routines produce expressions of the subject data-model in a form the amenable to the UI-View layout.
    // * * ** *** ***** ******** ************* ********************* ************* ******** ***** *** ** * *

    /** @param dm {TreatmentDataModel} */
    /** @returns {TreatmentReviewViewModel} */
    _createVm(dm) {

        /** {TreatmentReviewViewModel} */
        let vm = {};
        /** {function({{date:{Date}}}, {{date:{Date}}}) : {-1|0|1} } */
        let byDateComparitor = (thisHasDate, thatHasDate) => { return (thisHasDate.date > thatHasDate.date);};

        vm.treatmentType = dm.type; //{TreatmentType} SLIT vs SCIT

        // Patient ::
        vm.patient = /** {TR-Patient-ViewModel} */{
            chartNum : dm.patient.chartNum,
            dayOfBirth : dm.patient.dayOfBirth,
            name : [
                dm.patient.person.givenName,
                dm.patient.person.middleName,
                dm.patient.person.familyName
            ].join(" ")
        };

        // Vitals ::
        if (dm.visit.vitals) {
            vm.vitals = /** {TR-Vitals-ViewModel}*/dm.visit.vitals.results
                .map(aVital => ({name: aVital.vital.label, preValue: aVital.value}));
        }
        else {
            vm.vitals = null;
        }

        // PerformedBy ::
        vm.performedBy = /** {TR-PerformedBy-ViewModel} */angular.copy(dm.performedBy);
        if (dm.visit && dm.visit.appointment && dm.visit.appointment.provider) {
            this.userService.getUserName(dm.visit.appointment.provider).then((userName) => {
               vm.orderedBy = { name : userName };
            });
        }

        // Vial Tests ::
        vm.testingVialList = /** {Array.<{TR-VialTest-ViewModel}>} */dm.injections
            .filter(aDm => aDm.idtDosage > 0) // only include if a vial-test was scheduled
            .map(aDm => {
                let isRepeat = false;

                let otherUsage = aDm._vial._otherUsage.sort(byDateComparitor);
                let ix = otherUsage.findIndex(m => m._treatmentId === dm.id);
                if (ix >= 1) {
                    /* If the previous treatment failed the vial test for this vial then the reason code is Repeat */
                    isRepeat = otherUsage[ix - 1].idtWarning && otherUsage[ix - 1].idtRepeat;
                }

                return {
                    name : aDm._vial.name,
                    //dosage : aDm.idtDosage, // dosage column not currently displayed
                    reaction : aDm.idtWhealSize,
                    barcode : aDm._vial.barcode,
                    useBy : aDm._vial.useBy,
                    beyondUse: aDm._vial.beyondUse,
                    reasonCode : isRepeat ? 'Repeat' : this._treatmentSvc.getIdtReasonCode(aDm)
                };
            });

        // Injections ::
        vm.treatmentVialsList = /** {Array.<{TR-TreatmentVial-ViewModel}>} */dm.injections
            .filter( aDm => aDm.dosage > 0 && aDm.injected) // only include if an injection was scheduled and injected
            .map( aDm => {

                /** {Array.<{TR-Vial-ViewModel}>} */
                let otherUsesVm = aDm._vial._otherUsage
                    .filter(m => m.injected) // only include if injected
                    .map( anotherUse => ({
                        dosage : anotherUse.dosage,
                        location: anotherUse.location,
                        reaction : anotherUse.reactionWhealSize,
                        date : anotherUse._treatmentActionDateTime,
                        performedBy : anotherUse._performedBy,
                        note : this._treatmentSvc.getInjectionNotes(anotherUse),
                        _treatmentId : anotherUse._treatmentId,
                        name : anotherUse._vial.name
                    }));

                otherUsesVm.sort(byDateComparitor); // oldest to newest

                // This treatment could be anywhere in history,
                // so have to find it and then go back one to find the "previous" injection.
                let previousReaction = undefined;
                if (dm.type === 'SCIT' && otherUsesVm.length > 0) {
                    for (let idx = otherUsesVm.length - 1; idx >= 0; --idx) {
                        if (otherUsesVm[idx]._treatmentId === dm.id) {
                            if (idx > 0)
                                previousReaction = otherUsesVm[idx - 1].reaction;
                            break;
                        }
                    }
                }
                else if (dm.type === 'SLIT' && otherUsesVm.length > 0) {
                    // Find all injections one date older than the current treatment,
                    // and then find the highest reaction size form those.
                    let prevTreat = null;
                    for (let other of otherUsesVm) {
                        if (other._treatmentId === dm.id)
                            break;
                        else
                            prevTreat = other;
                    }
                    if (prevTreat) {
                        previousReaction = otherUsesVm.filter(use => use.date == prevTreat.date)
                            .reduce((accumulator, use) => Math.max(accumulator, use.reaction), 0);
                    }
                }

                /** {TR-TreatmentVial-ViewModel} */
                return {
                    name : aDm._vial.name,
                    dosage : aDm.dosage,
                    location: aDm.location,
                    reaction : aDm.reactionWhealSize,
                    barcode : aDm._vial.barcode,
                    useBy : aDm._vial.useBy,
                    beyondUse: aDm._vial.beyondUse,
                    reasonCode : this._treatmentSvc.getReactionReasonCode(aDm),
                    pastTreatments : otherUsesVm,
                    previousReaction : previousReaction
                };
            });

        // Notes ::
        vm.approvalNotes = "";// Unlike the rest of this model (displaying values), this is an input recipient.

        // Bill ::
        vm.bill = dm.visit.bill;

        vm.approvedBy = dm.approvedBy;
        vm.cancelledBy = dm.cancelledBy;

        return vm;
    }
}
