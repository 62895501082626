import angular from 'angular';

import AllergyTestService from './allergy-test.service';
import AllergyTestConfigService from './allergy-test-config.service';
import AnalyticsService from './analytics.service';
import AppointmentService from './appointment.service';
import AttachmentService from './attachment.service';
import BillService from './bill.service';
import BoardService from './board.service';
import ChecklistService from './checklist.service';
import ChronologyMappingService from "./chronology-mapping.service";
import ConcentrateService from './concentrate.service';
import DiagnosisCodeService from './diagnosis-code.service';
import ExternalConcentrateService from './external-concentrate.service';
import ExternalResourceService from './external-resource.service';
import GlobalConfigService from './global-config.service';
import InsurancePolicyService from './insurance-policy.service';
import InventoryAlertService from './inventory-alert.service';
import MixService from './mix.service';
import NoticeService from './notice.service';
import NotificationService from './notification.service';
import OfficeService from './office.service';
import PanelService from './panel.service';
import PatientService from './patient.service';
import PatientNoteService from './patient-note.service';
import PracticeService from './practice.service';
import PrescriptionService from './prescription.service';
import ProcedureCodeService from './procedure-code.service';
import QuestionnaireService from './questionnaire.service';
import ReportsService from "./reports.service";
import RoutingService from './routing.service';
import SecurityQuestionService from './securityquestion.service';
import ServerAPI from './serverapi';
import SessionService from './session.service';
import StompClient from "./stompClient";
import SubstanceService from './substance.service';
import SubstanceCategoryService from './substance-category.service';
import TestReviewService from './test-review.service';
import TreatmentService from './treatment.service';
import TreatmentConfigService from './treatment-config.service';
import TreatmentVialService from './treatment-vial.service';
import UserService from './user.service';
import VisitService from './visit.service';
import VisitVitalsService from './visit-vitals.service';
import LabelPrinterService from './label-printer.service';
import PatientAlertService from './patient-alert.service';

export default angular.module('services',[])
    .service('allergyTestService', AllergyTestService)
    .service('allergyTestConfigService', AllergyTestConfigService)
    .service('analyticsService', AnalyticsService)
    .service('appointmentService', AppointmentService)
    .service('attachmentService', AttachmentService)
    .service('billService', BillService)
    .service('boardService', BoardService)
    .service('checklistService', ChecklistService)
    .service('chronologyMappingService', ChronologyMappingService)
    .service('concentrateService', ConcentrateService)
    .service('diagnosisCodeService', DiagnosisCodeService)
    .service('externalConcentrateService', ExternalConcentrateService)
    .service('externalResourceService', ExternalResourceService)
    .service('globalConfigService', GlobalConfigService)
    .service('insurancePolicyService', InsurancePolicyService)
    .service('inventoryAlertService', InventoryAlertService)
    .service('mixService', MixService)
    .service('noticeService', NoticeService)
    .service('notificationService', NotificationService)
    .service('officeService', OfficeService)
    .service('panelService', PanelService)
    .service('patientService', PatientService)
    .service('patientNoteService', PatientNoteService)
    .service('practiceService', PracticeService)
    .service('prescriptionService', PrescriptionService)
    .service('procedureCodeService', ProcedureCodeService)
    .service('questionnaireService', QuestionnaireService)
    .service('reportsService', ReportsService)
    .service('routingService', RoutingService)
    .service('securityQuestionService', SecurityQuestionService)
    .service('serverAPI', ServerAPI)
    .service('sessionService', SessionService)
    .service('stompClient', StompClient)
    .service('substanceService', SubstanceService)
    .service('substanceCategoryService', SubstanceCategoryService)
    .service('testReviewService', TestReviewService)
    .service('treatmentService', TreatmentService)
    .service('treatmentConfigService', TreatmentConfigService)
    .service('treatmentVialService', TreatmentVialService)
    .service('userService', UserService)
    .service('visitService', VisitService)
    .service('visitVitalsService', VisitVitalsService)
    .service('labelPrinterService', LabelPrinterService)
    .service('patientAlertService', PatientAlertService)
    .name;
