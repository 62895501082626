
'use strict';

export default class LogInController {
    static $inject = ['$injector','$scope', '$location', '$timeout', 'inventoryAlertService', 'userService', 'practiceService', 'officeService', 'sessionService', 'notificationService', 'substanceService', '$document'];

    constructor($injector, $scope, $location, $timeout, inventoryAlertService, userService, practiceService, officeService, sessionService, notificationService, substanceService, $document) {

        console.log("LogInController constructor");
        this.$scope = $scope;
        this.$location = $location;
        this.$timeout = $timeout;
        this.$uibModal = $injector.get('$uibModal');
        this.inventoryAlertService = inventoryAlertService;
        this.patientAlertService = $injector.get('patientAlertService');
        this.sessionService = sessionService;
        this.userService = userService;
        this.practiceService = practiceService;
        this.officeService = officeService;
        this.notificationService = notificationService;
        this.substanceService = substanceService;
        this.urlPaths = $injector.get('UrlPaths');

        $document[0].title = "Clarity";

        // Scope variables
        $scope.credentials = {};
        $scope.version = '';
        $scope.serverName = '';

        userService.getGlobalLinks()
            .then(global => {
                this.$scope.version = global.version;
                this.$scope.serverName = global.serverName;
            });

        // Scope functions
        $scope.login = () => this.login($scope.credentials);
        $scope.forgotPasswordModal = () => this._forgotPasswordModal();

        if ($location.path() === '/sso') {
            this._sessionCleanup(false);
            this._handleSSO();
        }
        else {
            // Reset services to no-user state
            this._sessionCleanup(true);
        }

        if ($location.search().timeout !== undefined) {
            $scope.error = true;
            $scope.errorMessages = ['Your session expired due to inactivity.'];
        }
    }

    login(credentials) {
        if (credentials) {
            this.$scope.loginSubmitted = true;
            this.$scope.error = false;
            this.userService.login(credentials)
                .then(() => this.userService.getCurrent())
                .then(user => this._completeLogin(user))
                .catch(err => this._loginError(err));
        }
    }

    /**
     * We got here from /sso instead of /login - so try to load already authenticated user and complete the login.
     * @private
     */
    _handleSSO() {
        this.userService.getCurrent()
            .then(user => {
                console.log("SSO login for " + user.email);
                this.$scope.credentials.email = user.email;
                this.$scope.credentials.password = "******";
                this.$scope.loginSubmitted = true;
                this.$scope.error = false;
                return user;
            })
            .then(user => {
                // Pause for a moment for user to see their login processing.
                return this.$timeout(() => user, 2000);
            })
            .then(user => this._completeLogin(user))
            .catch(() => this._loginError({errors: ['Unable to authenticate your account via CEO.']}));
    }

    /**
     * Finish logging in a user.
     * @param user {User} loaded current session User.
     * @return completed Promise
     * @private
     */
    _completeLogin(user) {
        if (!user.office) {
            this._loginError({errors: ['No office available.']});
            return Promise.resolve();
        }

        return this.sessionService.getSession(user).then(() => {
            this.$location.path(this.urlPaths.DASHBOARD_PAGE).search({});
        });
    }

    /**
     * Deal with failure to login.
     * @param err {ServerError}, or HTTP Response containing a ServerError, or undefined
     * @private
     */
    _loginError(err) {
        this._sessionCleanup(true);
        this.$scope.error = true;

        // get the error messages from the error
        if (err && err.data && err.data.errors)
            this.$scope.errorMessages = err.data.errors;
        else if (err && err.errors)
            this.$scope.errorMessages = err.errors;
        else
            this.$scope.errorMessages = ['Login failure.'];

        // for invalid login, we delay the user for 2500 milliseconds,  (2.5 sec)
        this.$timeout( ()=> {
            this.$scope.$apply( () => {this.$scope.loginSubmitted = false;} );
        }, 2500 );
    }

    /**
     * Cleanup user state upon logout or login failure
     * @private
     */
    _sessionCleanup(doLogout) {
        if (this.sessionService.get(this.sessionService.KEY_USER) && doLogout) {
            this.userService.logout();
        }

        this.sessionService.logout();
        this.inventoryAlertService.select(null);
        this.patientAlertService.cleanSession();
        this.notificationService.disconnect();
    }

    _forgotPasswordModal() {

        let modal = this.$uibModal.open({
            windowClass: 'forgotPassword',
            scope: this.$scope, //passed current scope to the modal
            template: require('./widgets/forgot-password-modal.html'),
            css: require('./widgets/forgot-password-modal.scss'),
            controller: function ($uibModalInstance, $scope, userService) {
                // stores current email
                $scope.email = '';

                // when cancel button is clicked
                $scope.cancel = () => $uibModalInstance.close('cancel');

                $scope.sendEmail = () => {
                    $scope.submitted = true;
                    if ($scope.email) {
                        $scope.serverSubmitted = true;
                        console.log("forget password email requested for " + $scope.email);
                        // if email exists
                        // This is where the email will be sent to the server, then
                        // return either an error (which should set a scope variable
                        // to 'error' to true or send an email to the user and set
                        // the modal state to sent which will set a scope variable
                        // sent to true.)
                        userService.forgotPassword($scope.email)
                            .then(response => {
                                $scope.error = false;
                                $scope.sent = true;

                                // set the forgot password response
                                $scope.emailFrom = response.emailFrom;
                                $scope.emailRecipient = response.emailRecipient;
                            },
                            err => {
                                $scope.unexpectedError = true;
                                $scope.sent = false;

                                // construct the error message from the error object
                                let msg = "";
                                err.errors.forEach( e => {msg = msg + e} );
                                $scope.unexpectedErrorMessage = msg;
                                // re enabled the submitted button
                                $scope.serverSubmitted = false;
                            });
                    }
                }
            }
        });
    }
}
