"use strict";

import angular from 'angular';
import BaseController from './../../base.controller.js';

import TreatmentVialDetailsController from "../../../pages/common/treatment-vial-details/treatment-vial-details.controller";
import CreateNextRxModalController from '../../../pages/common/create-next-rx/controller';
import CreateNextClassicalRxModalController from '../../../pages/common/create-next-classical-rx/controller';

import React from 'react'
import { submitPrintJob } from '../../../react/print/Printer'
import { PRINTER_DYMO30336_LANDSCAPE, PatientIdCardLabel } from '../../../react/print/Dymo30336Landscape'

export default class PatientDetailsController extends BaseController {

    static $inject = ['$scope', '$injector', 'UnapprovedStatus'];

    constructor($scope, $injector, UnapprovedStatus) {

        super($scope, $injector);

        this.$uibModal = $injector.get('$uibModal');
        this.$filter = $injector.get('$filter');
        this.$q = $injector.get('$q');
        this.allergyTestService = $injector.get('allergyTestService');
        this.appointmentService = $injector.get('appointmentService');
        this.attachmentService = $injector.get('attachmentService');
        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.billService = $injector.get('billService');
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.insurancePolicyService = $injector.get('insurancePolicyService');
        this.notificationType = $injector.get('NotificationType');
        this.patientService = $injector.get('patientService');
        this.patientNoteService = $injector.get('patientNoteService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.PrescriptionReason = $injector.get('PrescriptionReason');
        this.Procedure = $injector.get('Procedure');
        this.routingService = $injector.get('routingService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.treatmentService = $injector.get('treatmentService');
        this.treatmentType = $injector.get('TreatmentType');
        this.ServiceStatus = $injector.get("ServiceStatus");
        this.visitService = $injector.get('visitService');
        this.labelPrinterService = $injector.get('labelPrinterService');
        this.patientAlertService = $injector.get('patientAlertService');
        this.UnapprovedStatus = UnapprovedStatus;

        this.$scope.activities = [];
        this.$scope.tests = [];
        this.$scope.prescriptions = [];
        this.$scope.patientDetailsTests = [];
        this.$scope.treatments = [];
        this.$scope.patientDetailsTreatment = [];
        this.$scope.vials = [];
        this.$scope.patientDetailsRx = [];
        this.$scope.displayActivities = [];
        this.$scope.attachments = undefined;
        this.$scope.bills = [];
        this.$scope.provider = {};
        this.$scope.showPrintTable = true;

        // subscriptions
        this.patientUpdateSub = undefined;
        this.patientVisitSub = undefined;

        // patient / treatment update data
        this.$scope.treatment = {
            treatmentPlan: "No Treatments",
            treatmentStartDate: undefined,
            fromLastTreatment: undefined,
            patientConsent: undefined,
            patientEpiExpDate: undefined,
            patientPreferredOffice: undefined,
            lungFunction: undefined
        };

        // table filters
        this.$scope.historyFilters = undefined;

        this.$scope.reactionOptions = [{
            name: 'All',
            value: undefined
        },
        {
            name: 'Yes',
            value: true
        },
        {
            name: 'No',
            value: false
        }
        ];

        this._initHistoryTableFilter();
        this._initNotesTableFilter();
        // End Table Filter models


        // Date Picker Options
        this.$scope.dp = {
            opened: false,
            format: "MM/dd/yyyy",
            minDate: new Date()
        };

        this.$scope.dateOptions = {
            startingDay: 1,
            showWeeks: false
        };
        // End Date Picker Options

        var params = this.getRouteParams();
        if (!params || !params.href) {
            console.log('PatientDetailsController :: Route parameters missing or corrupted.');
            this.routeToPage(this.urlPaths.PATIENT_LIST);
        }

        this._loadPatientData(params);

        this.$scope.appointmentDetails = () => {
            this._appointmentDetails(this.$uibModal, this.$scope.patient);
        };

        this.$scope.addAttachement = () => {
            this._addAttachement(this.$uibModal, this.$scope.patient);
        };

        this.$scope.deleteAttachment = (attachment) => {
            this._deleteAttachment(this.$uibModal, attachment);
        }

        this.$scope.addPatientAlert = () => {
            this._addEditPatientAlert(this.$uibModal, { id: null });
        }

        this.$scope.editPatientAlert = (patientAlert) => {
            this._addEditPatientAlert(this.$uibModal, patientAlert);
        }

        this.$scope.editPatient = () => this._editPatient(this.$uibModal, this.$scope.patient);

        this.$scope.getBillingReportUrl = () => {
            return this._getBillingReportUrl();
        };

        this.$scope.anyBillsSelected = () => {
            for (let item of this.$scope.bills) {
                if (item.isSelected) {
                    return true;
                }
            }
            return false;
        };

        this.$scope.showDetails = (row) => {
            if (row.allergyTest) {
                this._openTest(row.allergyTest.href);
                this.$scope.showPrintTable = false;
            } else if (row.treatment) {
                this._openTreatment(row.treatment.href);
                this.$scope.showPrintTable = false;
            } else if (row.prescription) {
                this._openRx(row.prescription.href);
                this.$scope.showPrintTable = false;
            } else if (row.mix) {
                this._openMix(row.mix.href);
                this.$scope.showPrintTable = false;
            }
        };

        this.$scope.showNoteDetails = (row) => {
            if (row.activity) {
                this.$scope.showDetails(row.activity);
            }
            else if (row.patientNote) {
                this._patientNoteModal(row.patientNote, false);
            }
        }

        // Set new date from datepicker
        this.$scope.setNewEpiDate = () => {
            if (this.$scope.treatment.patientEpiExpDateObj !== undefined) {
                // Datepicker selection:
                let date = this.chronologyMappingService.jsUTCDateToISODate(this.$scope.treatment.patientEpiExpDateObj);
                // Update view
                this.$scope.treatment.patientEpiExpDate = date;

                // Update data model
                if (date !== this.$scope.patient.epiPenExpiration) {
                    this.$scope.patient.epiPenExpiration = date;
                    this._updatePatient(this.$scope.patient);
                }
            }
        };

        this.$scope.setNewPreferredOffice = (theChoice) => {
            this.$scope.treatment.patientPreferredOffice = theChoice;
            if (theChoice !== this.$scope.patient.preferredOffice) {
                this.$scope.patient.preferredOffice = { id: theChoice.id };
                this._updatePatient(this.$scope.patient);
            }
        }

        this.$scope.setNewLungFunction = () => {
            let newValue = Number(this.$scope.treatment.lungFunction);
            console.log("setNewLungFunction " + newValue);
            if (angular.isNumber(newValue) && newValue !== Number(this.$scope.patient.lungBaseline)) {
                // Update data model
                this.$scope.patient.lungBaseline = newValue;
                this._updatePatient(this.$scope.patient);
            }
        };

        this.$scope.setStatus = (active) => {
            if (active === undefined || active === this.$scope.patient.active) {
                // no update needed.
            } else if (this.$scope.patient.activeVisit) {
                // Only possible current status during an activeVisit is TRUE
                // Only possible transition being attempted here is to FALSE.
                // Don't allow a patient in be set to inactive during a visit.
                this._cannotDeactivateAlertModal();
            } else {
                this.$scope.patient.active = active;
                this._updatePatient(this.$scope.patient);
            }
        };

        this.$scope.printCardLabel = () => this._printCardLabel();

        /** Consent types are 'SCIT', and 'SLIT' */
        this.$scope.onConsentChanged = (consentType, isConsenting) => {
            let scope = this.$scope;

            let idx = scope.patient.consentsGiven.indexOf(consentType);
            if (isConsenting && idx === -1) {
                scope.patient.consentsGiven.push(consentType);
            }
            else if (!isConsenting && idx >= 0) {
                scope.patient.consentsGiven.splice(idx, 1);
            }

            this._updatePatient(scope.patient);
        }

        this.$scope.addPrescriptionHandler = () => this._addPrescriptionModal();
        this.$scope.viewVialHistoryHandler = () => this._viewVialHistoryModal();
        this.$scope.delayedReactionHandler = (treatment) => this._delayedReactionModal(treatment);
        this.$scope.treatmentReactionHover = (treatmentActivity) => this._loadTreatment(treatmentActivity);
        this.$scope.patientNoteHandler = (patientNote) => this._patientNoteModal(patientNote, true);
        this.$scope.patientAlertHandler = (patientAlert) => this._patientAlertModal(patientAlert);

        this.$scope.tabs = [{
            title: 'History',
            url: 'patient-details-history'
        }, {
            title: 'Tests',
            url: 'patient-details-tests'
        }, {
            title: 'Vials',
            url: 'patient-details-rx'
        }, {
            title: 'Treatment',
            url: 'patient-details-treatment'
        }, {
            title: 'Notes',
            url: 'patient-details-notes'
        }, {
            title: 'Billing',
            url: 'patient-details-billing'
        }, {
            title: 'Attachments',
            url: 'patient-details-attachments'
        }, {
            title: 'Alerts',
            url: 'patient-details-alerts'
        }];

        this.$scope.currentTab = 'patient-details-history';

        this.$scope.onClickTab = (tab) => {
            if (angular.equals(this.$scope.currentTab, tab.url)) {
                return;
            }

            this.$scope.currentTab = tab.url;
            if (this.$scope.isActiveTab('patient-details-attachments') && this.$scope.attachments === undefined) {
                this._loadAttachments();
            }
            else if (this.$scope.isActiveTab('patient-details-alerts') && this.$scope.patientAlerts === undefined) {
                this._loadPatientAlerts();
            }

        };

        this.$scope.isActiveTab = (tabUrl) => {
            return tabUrl == this.$scope.currentTab;
        };

        this.$scope.onReactionFilterChange = (opt) => {
            if (!opt) {
                return;
            }

            this.$scope.reactionFilterSelection = opt;
            this.$scope.historyFilters.reaction = opt.value;
        };

        this.$scope.onStatusHoverLastActivity = (row) => {
            let action = row.cancelledBy || (row.finalApprovedBy || (row.performedBy || action));

            action.label = action.label || "";
            action.name = action.name || "";
            action.actionDateTime = action.actionDateTime || "";

            return action.label + ": " + action.name + " - " + action.actionDateTime.split('T')[0];
        };

        this.$scope.onUiRetireOrReplaceVial = (vial) => this.offerReplaceRetireVialModal(vial);

        this.$scope.printPositiveReactions = (allergyTest) => this._printPositiveReactions(allergyTest);
        this.$scope.printAllAntigensTested = (allergyTest) => this._printAllAntigensTested(allergyTest);

        this.$scope.printVialLabel = (vial) => this._printVialLabel(vial);

        this.$scope.createNextRx = (vial, vials) => {
            if (vial.isClassical)
                this._createNextClassicalRx(vial);
            else
                this._createNextRx(vials);
        };

        this.$scope.removePatient = (patient) => this._removePatient(patient);
        this.$scope.loadTreatment = (treatmentActivity) => this._loadTreatment(treatmentActivity);

        this.$scope.updatePolicy = (updatedPolicyId) => this._updatePolicy(updatedPolicyId);
        this.$scope.updatePrimaryPolicy = (primaryPolicyId) => this._updatePrimaryPolicy(primaryPolicyId);
    } // End ctor

    /**
     * Load the patient whom's details will be displayed.
     * @private
     */
    async _loadPatientData(params) {
        this.$scope.fromLocation = this.routingService.extractLocationParams(params, '#/patient/patient-list', 'Patient List');
        await this.notificationService.init();

        let patient = await this.patientService.getUncached(params);
        let officeLocations = await this.officeService.getInPractice(this.$scope.practice);
        // Asynchronously update the patient from external resource
        this.patientService.refresh(patient);

        // Setup patient subscriptions
        this._subscribePatientUpdates(patient);
        this._subscribePatientVisitNotifications(patient);

        this._setTreatmentOfficeInfo(officeLocations);
        this._setPatientInfo(patient);
        this._setPatientInsurance(patient);

        let promiseResult = await Promise.all([
            this.patientService.getHistory(patient),
            this.prescriptionService.getForPatient(patient),
            this.patientNoteService.getForPatient(patient)
        ]);

        let history = promiseResult[0];
        history = history.list.filter(record => record.performedBy);
        this.$scope.prescriptions = promiseResult[1] ? promiseResult[1].list : [];
        let patientNotes = promiseResult[2] ? promiseResult[2].list : [];

        this._processHistory(history, patientNotes);
        this._initTreatmentInfo();
        this.startAllSubscriptions();
        this.$scope.$digest();
    }

    _processHistory(history, patientNotes) {
        history = this.$filter('orderBy')(history, '-performedBy.actionDateTime');
        this.$scope.activities = history;
        this._filterHistory(history);
        this.$scope.patientNotes = patientNotes;

        this._processNotes(history, patientNotes);
    }

    _processNotes(history, patientNotes) {
        let q = [];
        this.$scope.activities.forEach((activity) => {
            q.push(this.userService.populateUserAction(activity.performedBy));
            q.push(this.userService.populateUserAction(activity.finalApprovedBy));
            activity.actions.forEach(a => q.push(this.userService.populateUserAction(a)));
        }, this);
        this.$scope.patientNotes.forEach((patientNote) => {
            q.push(this.userService.getUserName(patientNote.createdByUser).then((userName) => {
                patientNote.name = userName;
            }));
        });

        return this.$q.all(q).then(() => {
            this.$scope.allNotes = [];

            history.forEach(activity => {
                activity.actions.forEach(action => {
                    this.$scope.allNotes.push({
                        date: action.actionDateTime.substr(0, 10),
                        time: action.actionDateTime.substr(11),
                        when: action.actionDateTime,
                        createdBy: action.name,
                        details: action.label,
                        text: action.note,
                        activity: activity,
                        patientNote: undefined
                    });
                });
            });

            patientNotes.forEach(patientNote => {
                let when = this.chronologyMappingService.utcToTimezone(patientNote.createdDateTime, this.$scope.office.timezone);
                this.$scope.allNotes.push({
                    date: when.substr(0, 10),
                    time: when.substr(11),
                    when: when,
                    createdBy: patientNote.name,
                    details: 'Patient',
                    text: patientNote.note,
                    activity: undefined,
                    patientNote: patientNote
                });
            })
        });
    }

    _processAlerts(patientAlerts) {
        let q = [];

        this.$scope.patientAlerts.forEach((patientAlert) => {
            q.push(this.userService.getUserName(patientAlert.alertedByUser).then((userName) => {
                patientAlert.name = userName;
            }));
        });
        return this.$q.all(q).then(() => {
            this.$scope.allAlerts = [];
            patientAlerts.forEach(patientAlert => {
                let when = this.chronologyMappingService.utcToTimezone(patientAlert.modifiedDateTime, this.$scope.office.timezone);

                let typeSortOrder = {
                    'TESTING': 0,
                    'MIXING': 1,
                    'TREATMENT': 2,
                    'DETAILS': 3
                };

                let pascalTypes = patientAlert.types.sort((a, b) => {
                    return typeSortOrder[a] - typeSortOrder[b]
                }).map(t => {
                    return t.match(/[a-z]+/gi)
                        .map(function (word) {
                            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
                        })
                        .join('')
                });
                this.$scope.allAlerts.push({
                    date: when.substr(0, 10),
                    name: patientAlert.name,
                    displayBefore: pascalTypes.join(', '),
                    status: patientAlert.active ? "Active" : "Inactive",
                    title: patientAlert.title,
                    message: patientAlert.message,
                    patientAlert: patientAlert
                });
            });
        });
    }

    _subscribePatientUpdates(patient) {
        this.registerSubscription(this.notificationService.subscribePatientUpdates(this.$scope.practice, patient))
            .then(null, null, (notification) => {
                this._onPatientUpdateNotification(notification);
                this._setPatientInsurance(patient);
            });
    }

    _onPatientUpdateNotification(notification) {
        if (notification.type != this.notificationType.Patient || notification.body.id != this.$scope.patient.id) {
            return;
        }

        this._setPatientInfo(notification.body);
    }

    _subscribePatientVisitNotifications(patient) {
        this.registerSubscription(this.notificationService.subscribeVisitForPatient(this.$scope.practice, patient))
            .then(null, null, (notification) => {
                this._onVisitNotification(notification);
            });
    }

    _onVisitNotification(notification) {
        if (notification.type != this.notificationType.Patient || notification.body.id != this.$scope.patient.id) {
            return;
        }

        // set new active visit info and station only
        let patient = notification.body;
        this.$scope.patient.activeVisit = patient.activeVisit;
        this.$scope.patient.station = patient.station;

        if (patient.activeVisit) {
            this.visitService.get(patient.activeVisit)
                .then(visit => this._processNewestVisit(visit));
        }
    }

    _processNewestVisit(visit) {

        this.officeService.get(visit.office)
            .then(office => {
                this.$scope.provider.office = office.name;
            });

        if (visit.appointment) {
            this.appointmentService.get(visit.appointment)
                .then(appointment => this.userService.getUserName(appointment.provider))
                .then(name => this.$scope.provider.name = name);
        }
    }

    _onBillNotification(notification) {
        // Find the matching bill and update
        for (let i = 0; i < this.$scope.bills.length; i++) {
            let item = this.$scope.bills[i];
            if (item.bill.id == notification.body.id) {
                let temp = item.bill;
                item.bill = notification.body;

                // Add back any properties added by the client
                for (let property in temp) {
                    if (!item.bill.hasOwnProperty(property)) {
                        item.bill[property] = temp[property];
                    }
                }

                this._loadBillDetails(item);
            }
        }
    }

    _subscribeBillUpdates(bill) {
        if (bill) {
            this.registerSubscription(this.notificationService.subscribeBillUpdates(this.$scope.practice, bill))
                .then(null, null, (notification) => {
                    this._onBillNotification(notification);
                });
        }
    }

    /**
     * Sort downloadedBy and transmissions list on the bill by name
     */
    _sortBillActions(bill) {
        bill.downloadedBy = bill.downloadedBy.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                let aDate = new Date(a.actionDateTime).getTime();
                let bDate = new Date(b.actionDateTime).getTime();
                return aDate < bDate ? -1 : 1;
            }
        });

        bill.transmissions = bill.transmissions.sort((a, b) => {
            if (a.sentBy.name < b.sentBy.name) {
                return -1;
            } else if (a.sentBy.name > b.sentBy.name) {
                return 1;
            } else {
                let aDate = new Date(a.sentBy.actionDateTime).getTime();
                let bDate = new Date(b.sentBy.actionDateTime).getTime();
                return aDate < bDate ? -1 : 1;
            }
        });
    }

    /**
     * Load the user names for the downloadedBy and transmission list on a given bill.
     * Also load the insurance policy for the bill.
     * @return Promise, which eventually returns true
     */
    _loadBillDetails(item) {
        this.ipPromises = this.ipPromises || {};

        let bill = item.bill;
        let q = [];

        if (bill.insurancePolicy) {
            let ipPromise = null;
            if (this.ipPromises[bill.insurancePolicy.id]) {
                ipPromise = this.ipPromises[bill.insurancePolicy.id];
            }
            else {
                ipPromise = this.insurancePolicyService.get(bill.insurancePolicy);
                this.ipPromises[bill.insurancePolicy.id] = ipPromise;
                q.push(ipPromise);
            }

            ipPromise.then((insurancePolicy) => {
                bill.insurancePolicy = insurancePolicy;
            });
        }

        for (let userAction of bill.downloadedBy) {
            q.push(this.userService.populateUserAction(userAction));
        }
        for (let transmission of bill.transmissions) {
            q.push(this.userService.populateUserAction(transmission.sentBy));
        }

        return this.$q.all(q).then(() => {
            this._sortBillActions(bill);
        });
    }

    /**
     * Load the bill for a given history item
     * @return Promise, which eventually returns true if the bill was loaded,
     * or false otherwise
     */
    _loadBill(item, uncached) {
        if (item.bill) {

            let promise = null;
            if (uncached) {
                promise = this.billService.getUncached(item.bill);
            } else {
                promise = this.billService.get(item.bill);
            }

            return promise.then((billDto) => {
                item.bill = billDto;
                return this._loadBillDetails(item).then(() => {
                    return true;
                });
            });
        }

        return this.billService.resolved(false);
    }

    /**
     * @param {Notification} notif
     * A Notification whose body is should contain an updated DM state of a PatientTreatmentVial which just
     * underwent replacement or ending of service. We affect the UI change by first seizing the list of VM's affected
     * by the change, identifying the subject member by ID, then applying the new field states.
     *
     * @private
     */
    _updatePatientVial(notif) {
        let /** {{ id : {String}, status : {ServiceStatus}, replaceable : {Boolean} }} */
            updatedVial = notif.body;

        for (let aVialVm of this.$scope.vials) {
            if (aVialVm.id === updatedVial.id) {
                aVialVm.status = updatedVial.status;
                aVialVm.isReplaceable = this._getAvailableVialChanges(aVialVm);
                break;
            }
        }
    }

    /**
     * Group history activities by type (Testing, Treatment, Prescriptions).
     * Also will set the last appointment date. (Most recent test or treatment).
     *
     * @private
     */
    _filterHistory(history) {
        let billQueue = [];
        let latestVisitItem = null;
        let haveMix = false;

        this.$scope.bills = [];
        this.$scope.tests = [];
        this.$scope.treatments = [];
        this.$scope.vials = [];

        for (let item of history) {
            this._subscribeBillUpdates(item.bill);

            let billPromise = this._loadBill(item, true).then((loaded) => {
                if (loaded) {
                    this.$scope.bills.push(item);
                }
            });

            billQueue.push(billPromise);

            if ((item.allergyTest || item.treatment) && (latestVisitItem == null || latestVisitItem.performedBy.actionDateTime < item.performedBy.actionDateTime))
                latestVisitItem = item;

            if (item.allergyTest) {
                this.$scope.tests.push(item);
            } else if (item.treatment) {
                this._processTreatmentItem(item);
            } else if (item.mix) {
                haveMix = true;
            }

            item.status = this.UnapprovedStatus[item.status]
        }

        // If have mixes, then we have patient vials to process too!
        if (haveMix) {
            this.officeService.getInPractice(this.$scope.practice, true).then(officeList => {
                this.allOffices = officeList.list;
                return this.patientService.treatmentVials(this.$scope.patient);
            }).then(treatmentVialsList => {
                for (let treatmentVial of treatmentVialsList.list) {
                    let aVialVm = angular.copy(treatmentVial);

                    /** @type{Prescription} */
                    const rx = this.$scope.prescriptions.find(m => m.id === treatmentVial.prescription.id);
                    /** @type{PatientHistory} */
                    const mix = history.find(h => h.mix && h.mix.id === treatmentVial.mix.id);

                    aVialVm.performedBy = mix ? angular.copy(mix.performedBy) : undefined;
                    aVialVm.treatmentType = rx ? rx.treatmentType : undefined;
                    aVialVm.isReplaceable = this._getAvailableVialChanges(aVialVm);
                    aVialVm.isClassical = rx && rx.diluteTo;
                    aVialVm.notes = [];

                    if (rx.dispersedBy) {
                        this.userService.populateUserAction(rx.dispersedBy.note)
                        aVialVm.notes.push(rx.dispersedBy);
                    }

                    let office = this.allOffices.find(m => m.id === aVialVm.office.id);
                    if (office) {
                        aVialVm.officeName = office.name;
                    }

                    this.$scope.vials.push(aVialVm);

                    if (aVialVm.isReplaceable) {
                        // Watch for status changes to replaceable vials
                        this.registerSubscription(
                            this.notificationService.subscribeToAPatientVialUpdates(
                                this.$scope.practice, treatmentVial)
                        ).then(null, null, (notification) => {
                            this._updatePatientVial(notification);
                        });
                    }
                }
            });
        }

        this.$q.all(billQueue).then(() => {
            let billSort = (a, b) => {
                let aPerformedBy = a.performedBy;
                let bPerformedBy = b.performedBy;
                if (!aPerformedBy) {
                    return 1;
                } else if (!bPerformedBy) {
                    return -1;
                } else if (aPerformedBy.actionDateTime < bPerformedBy.actionDateTime) {
                    return -1;
                } else {
                    return 1;
                }
            };

            this.$scope.bills = this.$scope.bills.filter(m => m.finalApprovedBy).sort(billSort);
            this.startAllSubscriptions();
        });

        // Load the last visit and update provider details
        if (latestVisitItem) {
            let start = this.chronologyMappingService.startOfDay(latestVisitItem.performedBy.actionDateTime);
            this.visitService.getForPatient(this.$scope.patient, start)
                .then(visits => {
                    if (visits.list.length > 0)
                        this._processNewestVisit(visits.list[visits.list.length - 1]);
                });
        } else if (this.$scope.patient.activeVisit) {
            this.visitService.get(this.$scope.patient.activeVisit)
                .then(visit => this._processNewestVisit(visit));
        }
    }

    _processTreatmentItem(treatmentItem) {
        treatmentItem.treatmentType = treatmentItem.activity.startsWith('SLIT') ? this.treatmentType.SLIT : this.treatmentType.SCIT;
        treatmentItem._isScit = treatmentItem.treatmentType === this.treatmentType.SCIT;
        treatmentItem._isSlit = treatmentItem.treatmentType === this.treatmentType.SLIT;
        this.$scope.treatments.push(treatmentItem);
    }

    _updatePatient(p) {
        this.patientService.update(p).then((patient) => {
            this._setPatientInfo(patient);
        });
    }

    _setPatientInfo(patient) {
        this.$scope.patient = patient;
        this.$scope.treatment.patientConsentSCIT = patient.consentsGiven.includes('SCIT');
        this.$scope.treatment.patientConsentSLIT = patient.consentsGiven.includes('SLIT');
        this.$scope.treatment.patientEpiExpDate = patient.epiPenExpiration;
        this.$scope.treatment.patientEpiExpDateObj = this.chronologyMappingService.isoDateToJsUTCDate(patient.epiPenExpiration);
        this.$scope.treatment.lungFunction = patient.lungBaseline;

        this.$scope.treatment.patientPreferredOffice = undefined;
        if (patient.preferredOffice) {
            this.$scope.treatment.patientPreferredOffice = this.$scope.officeChoices.find(office => office.id === patient.preferredOffice.id);
        }
    }

    _setTreatmentOfficeInfo(offices) {
        this.$scope.officeChoices = offices.list;
    }

    async _setPatientInsurance(patient) {

        let policies = await this.insurancePolicyService.getForPatient(patient);

        let today = this.chronologyMappingService.currentDate(this.$scope.office.timezone);
        this.$scope.insurancePolicies = {
            list: []
        };

        for (let policy of policies.list) {
            if (policy.effective <= today && today < policy.termination && (policy.priority === 1 || policy.priority === 2)) {
                if (policy.priority == 1) {
                    this.$scope.primaryPolicyId = policy.id;
                }
                this.$scope.insurancePolicies.list.push(policy);
            }
        }

        this.$scope.$digest();
    }

    async _updatePrimaryPolicy(primaryPolicyId) {
        let maxPriority = 1;
        this.$scope.primaryPolicyId = primaryPolicyId;
        this.$scope.processingInsurance = true;
        for (let i = 0; i < this.$scope.insurancePolicies.list.length; i++) {
            let policy = this.$scope.insurancePolicies.list[i];
            if (policy.id == primaryPolicyId) {
                policy.priority = 1;
            } else {
                maxPriority++;
                policy.priority = maxPriority;
            }
            policy = await this.insurancePolicyService.update(policy);
            // Update values so version is correct
            this.$scope.insurancePolicies.list[i] = policy;
        }
        this.$scope.processingInsurance = false;
        this.$scope.$digest();
    }

    async _updatePolicy(updatedPolicyId) {
        this.$scope.processingInsurance = true;
        for (let i = 0; i < this.$scope.insurancePolicies.list.length; i++) {
            let policy = this.$scope.insurancePolicies.list[i];
            if (policy.id == updatedPolicyId) {
                policy = await this.insurancePolicyService.update(policy);
                this.$scope.insurancePolicies.list[i] = policy;
            }
        }
        this.$scope.processingInsurance = false;
        this.$scope.$digest();
    }

    _initTreatmentInfo() {

        this.$scope.treatment.lastAppointmentDate = undefined;
        this.$scope.treatment.treatmentStartDate = undefined;
        this.$scope.treatment.fromLastTreatment = undefined;

        if (this.$scope.treatments.length > 0) {
            // Treatments are sorted newest to oldest. Newest completed treatment is the "last appointment",
            // while the oldest completed treatment is the "treatment start date"
            let completedTreatments = this.$scope.treatments.filter(t => t.completed);
            if (completedTreatments.length > 0) {
                let now = this.chronologyMappingService.currentDateTime(this.$scope.office.timezone);
                this.$scope.treatment.lastAppointmentDate = completedTreatments[0].performedBy.actionDateTime;
                this.$scope.treatment.treatmentStartDate = completedTreatments[completedTreatments.length - 1].performedBy.actionDateTime;
                this.$scope.treatment.fromLastTreatment = this.chronologyMappingService.daysBetween(now, this.$scope.treatment.lastAppointmentDate);
            }

            if (this.$scope.patient.active)
                this.$scope.treatment.treatmentPlan = this.$scope.treatments[0].treatmentType;
        }
    }

    async _loadAttachments() {
        let attachments = await this.attachmentService.getForPatient(this.$scope.patient);

        // filter out patient photos from attachments list.
        attachments = this.$filter('filter')(attachments.list, {
            displayType: '!Patient Photo'
        });

        // Converted createdDateTime to office timezone
        let timezone = this.$scope.office.timezone;
        attachments.forEach(a => {
            a.createdDateTime = this.chronologyMappingService.utcToTimezone(a.createdDateTime, timezone);
        });

        // Sort by creation date
        this.$scope.attachments = this.$filter('orderBy')(attachments, '-createdDateTime');
        this.$scope.$digest();
    }

    async _loadPatientAlerts() {
        let patientAlerts = await this.patientAlertService.getForPatient(this.$scope.patient);
        if (!patientAlerts) {
            return;
        }

        patientAlerts = patientAlerts.list;

        this.$scope.patientAlerts = patientAlerts;

        // Convert createdDateTime to office timezone
        this._processAlerts(patientAlerts);
        this.$scope.$digest();
    }

    _editPatient(uibModal, patient) {

        var modalInstance = uibModal.open({
            windowClass: 'editPatient',
            size: 'lg',
            template: require('../patient-edit/patient-edit.html'),
            css: require('../patient-edit/patient-edit.scss'),
            controller: 'PatientEditController',
            resolve: {
                patient: () => angular.copy(patient)
            }
        });

        modalInstance.result.then(updatedPatient => {
            // Did patient data change?
            if (updatedPatient && updatedPatient.href !== patient.href) {
                this._setPatientInfo(updatedPatient);
                this._setPatientInsurance(updatedPatient);
            }
        });
    }

    _appointmentDetails(uibModal, patient) {

        var modalInstance = uibModal.open({
            windowClass: 'appointment',
            size: 'md',
            template: require('../../common/appointment-details/appointment-details.html'),
            css: require('../../common/appointment-details/appointment-details.scss'),
            controller: 'AppointmentDetailsController',
            resolve: {
                patient: () => patient
            }
        });

        modalInstance.result.then(apt => {
            // Did patient data change?
            if (apt && apt.patient && apt.patient.href !== patient.href) {
                this._setPatientInfo(apt.patient);
                this._setPatientInsurance(apt.patient);
            }
        });
    }

    _addAttachement(uibModal, patient) {
        let _this = this;
        var modalInstance = uibModal.open({
            windowClass: 'attachment',
            size: 'md',
            template: require('../widgets/add-attachment.html'),
            css: require('../widgets/add-attachment.scss'),
            controller: 'AttachmentUploadController',
            resolve: {
                Patient: () => patient
            }
        });

        modalInstance.result.then(function () {
            _this._loadAttachments();
        }, function () {
            // modal dismissed / cancelled
        });
    }

    _deleteAttachment(uibModal, attachment) {
        let _this = this;
        var modalInstance = uibModal.open({
            windowClass: 'attachment',
            size: 'md',
            template: require('../widgets/delete-attachment.html'),
            controller: function ($scope, $uibModalInstance, attachmentService) {
                $scope.attachment = attachment;
                $scope.cancel = () => {
                    $uibModalInstance.dismiss();
                }
                $scope.continue = () => {
                    attachmentService.delete(attachment).then(() => {
                        $uibModalInstance.close(attachment);
                    });
                }
            },
        });

        modalInstance.result.then(function (attachment) {
            // Remove from local list of attachments
            for (let i = 0; i < _this.$scope.attachments.length; i++) {
                if (_this.$scope.attachments[i].id == attachment.id) {
                    _this.$scope.attachments.splice(i, 1);
                    break;
                }
            }
        }, function () {
            // modal dismissed / canceled
        })
    }

    _getBillingReportUrl() {
        let query = '';
        for (let i = 0; i < this.$scope.bills.length; i++) {
            let item = this.$scope.bills[i];
            if (item.isSelected === true) {
                query += query.length > 0 ? '&' : '';
                query += 'billIds=' + item.bill.id;
            }
        }

        if (query) {
            return this.$scope.practice._links.downloadBill + '?' + query;
        } else {
            return '';
        }
    }

    /**
     * Spawns a UI Modal which offers the user a form wizard to configure an RX for the subject patient.
     * @private
     */
    _addPrescriptionModal() {

        let rxCreated = (rxHref) => this._onPrescriptionCreated(rxHref);

        this.$uibModal.open({
            windowClass: 'addPrescriptionModal',
            template: require("./add-rx-modal/layout.html"),
            css: require("./add-rx-modal/styles.scss"),
            controller: "AddPrescriptionModalController",
            resolve: {
                patient: () => this.$scope.patient,
                onCreationHandler: () => rxCreated
            }
        });
    }


    /**
     * Spawns a UI Modal which shows the user the vial history.
     * @private
     */
    _viewVialHistoryModal() {
        this.$scope.showPrintTable = false;

        this.$uibModal.open({
            windowClass: 'viewVialHistoryModal',
            template: require("./view-vial-history/layout.html"),
            css: require("./view-vial-history/styles.scss"),
            controller: "ViewVialHistoryModalController",
            resolve: {
                patient: () => this.$scope.patient,
                treatmentSummary: () => this.$scope.treatment,
                vials: () => this.$scope.vials
            }
        })
            .result.then(() => {
                this.$scope.showPrintTable = true
            })
            .catch(() => {
                this.$scope.showPrintTable = true
            })

    }

    /**
     * Load and attach the underlying treatment to a TreatmentActivity
     * @param {TreatmentActivity} treatmentActivity treatmentActivity for which to load treatment
     * @private
     */
    _loadTreatment(treatmentActivity) {
        return treatmentActivity._treatment ?
            this.treatmentService.resolved(treatmentActivity._treatment) :
            this.treatmentService.get(treatmentActivity.treatment)
                .then(treatment => {
                    treatmentActivity._treatment = treatment;
                    treatment._givenInjections = treatment.injections.filter(i => i.injected);
                    return treatment;
                });
    }

    /**
     * Spawns a UI Modal which allows the user to record a delayed reaction.
     * @private
     */
    _delayedReactionModal(treatmentActivity) {
        this.$uibModal.open({
            windowClass: 'delayedReactionModal',
            template: require("./delayed-reaction-modal/layout.html"),
            css: require("./delayed-reaction-modal/styles.scss"),
            controller: 'DelayedReactionModalController',
            resolve: {
                treatment: () => this._loadTreatment(treatmentActivity)
            }
        })
            .result.then((hasChanges) => {
                if (!hasChanges) {
                    return;
                }

                /* When the server updates the Treatment with the delayed reaction it returns a Treatment object. We need the updated TreatmentHistory
                 * object so we retrieve it again here. */
                this.patientService.getHistory(this.$scope.patient, this.Procedure.TREATMENT).then((treatmentHistory) => {
                    let treatmentItems = treatmentHistory.list.filter(record => record.performedBy);
                    for (let treatmentItem of treatmentItems) {
                        let historyItemIndex = this.$scope.activities.findIndex(historyItem => historyItem.treatment && historyItem.treatment.id === treatmentItem.treatment.id);
                        if (historyItemIndex >= 0) {
                            this.$scope.activities[historyItemIndex] = treatmentItem;
                        }
                        else {
                            this.$scope.activities.push(treatmentItem);
                        }
                    }

                    this._processHistory(this.$scope.activities);
                });
            });
    }

    /**
     * Spawns a UI Modal which allows the user to create a new patient note or edit an existing patient note.
     * @param {PatientNote} patientNote if present, an existing patient note to edit. If not present, create
     * a new patient note.
     * @private
     */
    _patientNoteModal(patientNote, isEditable) {
        this.$uibModal.open({
            windowClass: 'patientNoteModal',
            template: require("./patient-note-modal/layout.html"),
            css: require("./patient-note-modal/styles.scss"),
            controller: 'PatientNoteModalController',
            resolve: {
                patient: () => this.$scope.patient,
                patientNote: () => patientNote,
                isEditable: () => isEditable
            }
        })
            .result.then((patientNote) => {
                let existingNoteIndex = this.$scope.patientNotes.findIndex(m => m.id === patientNote.id);
                if (existingNoteIndex >= 0) {
                    this.$scope.patientNotes[existingNoteIndex] = patientNote;
                }
                else {
                    this.$scope.patientNotes.push(patientNote);
                }

                this._processNotes(this.$scope.activities, this.$scope.patientNotes);
            });
    }

    /**
     * Spawns a UI Modal which allows the user to create a new patient alert or edit an existing patient alert.
     * @param {PatientAlert} patientAlert if present, an existing patient alert to edit. If not present, create
     * a new patient alert.
     * @private
     */
    _patientAlertModal(patientAlert) {
        this.$uibModal.open({
            windowClass: 'patientAlertModal',
            size: 'md',
            backdrop: 'static',
            template: require("./patient-alert-modal/layout.html"),
            css: require("./patient-alert-modal/styles.scss"),
            controller: 'PatientAlertModalController',
            resolve: {
                patient: () => this.$scope.patient,
                patientAlert: () => patientAlert
            }
        })
            .result.then((patientAlert) => {
                let existingAlertIndex = this.$scope.patientAlerts.findIndex(m => m.id === patientAlert.id);
                if (existingAlertIndex >= 0) {
                    this.$scope.patientAlerts[existingAlertIndex] = patientAlert;
                }
                else {
                    this.$scope.patientAlerts.push(patientAlert);
                }

                this._processAlerts(this.$scope.patientAlerts);
            });
    }


    /**
     * Spawns a UI Modal offering a form wizard to configure the vials of medication accompanying the RX generated
     * from #_addPrescriptionModal.
     *
     * @param {URL} prescriptionHref URL serving the RX instance which was just created
     * @private
     */
    _onPrescriptionCreated(prescriptionHref) {

        let $this = this;
        this.$scope.showPrintTable = false;

        this.$uibModal.open({
            size: "full",
            template: '<ag-prescription-approval prescription-href="prescriptionHref" on-exit-callback="close()" is-approval="true" is-manual-rx-case="::true"></ag-prescription-approval>',
            controller: function ($scope, $uibModalInstance, prescriptionHref) {
                $scope.prescriptionHref = prescriptionHref;
                $scope.close = () => {
                    $this.$scope.showPrintTable = true;
                    $uibModalInstance.close('');
                };
            },
            resolve: {
                prescriptionHref: () => prescriptionHref
            }
        });
    }

    /**
     * Open a model for viewing an Allergy Test.
     * @param testHref
     * @private
     */
    _openTest(testHref) {
        let $this = this;
        this.$uibModal.open({
            size: 'full',
            backdrop: 'static',
            keyboard: false,
            template: '<div><ag-test-approval allergy-test-href="allergyTestHref" on-exit-callback="close()" is-approval="false"></ag-test-approval></div>',
            controller: function ($scope, $uibModalInstance, allergyTestHref) {
                $scope.allergyTestHref = allergyTestHref;
                $scope.close = () => {
                    $this.$scope.showPrintTable = true;
                    $uibModalInstance.close('');
                };
            },
            resolve: {
                allergyTestHref: function () {
                    return testHref;
                }
            }
        });
    }

    /**
     * Open a model for viewing an RX.
     * @param href URL to get the prescription to open
     * @private
     */
    _openRx(href) {
        let $this = this;
        this.$uibModal.open({
            size: 'full',
            backdrop: 'static',
            keyboard: false,
            template: '<div><ag-prescription-approval prescription-href="href" on-exit-callback="close()" is-approval="false"></ag-prescription-approval></div>',
            controller: function ($scope, $uibModalInstance, rxHref) {
                $scope.href = rxHref;
                $scope.close = () => {
                    $this.$scope.showPrintTable = true;
                    $uibModalInstance.close('');
                };
            },
            resolve: {
                rxHref: function () {
                    return href;
                }
            }
        });
    }

    /**
     * Open a model for viewing a Mix.
     * @param {String} href URL to get the Mix to open
     * @private
     */
    _openMix(href) {
        let $this = this;
        this.$uibModal.open({
            size: 'full',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                mixHref: () => href
            },
            template: '<div><ag-mix-approval mix-href="href" on-exit-callback="close()" is-approval="false"></ag-mix-approval></div>',
            controller: function ($scope, $uibModalInstance, mixHref) {
                $scope.href = mixHref;
                $scope.close = () => {
                    $this.$scope.showPrintTable = true;
                    $uibModalInstance.close('');
                };
            }
        });
    }

    /**
     * Open a modal for viewing a Treatment.
     * @param {String} href URL to get the Treatment to open
     * @private
     */
    _openTreatment(href) {
        let $this = this;
        this.$uibModal.open({
            size: 'full',
            backdrop: 'static',
            keyboard: false,
            template: '<div><ag-treatment-review treatment-href="href" on-exit-callback="close()" is-approval="false"></ag-treatment-review></div>',
            controller: function ($scope, $uibModalInstance, treatmentHref) {
                $scope.href = treatmentHref;
                $scope.close = () => {
                    $this.$scope.showPrintTable = true;
                    $uibModalInstance.close('');
                };
            },
            resolve: {
                treatmentHref: function () {
                    return href;
                }
            }
        });

    }

    _initHistoryTableFilter() {
        this.$scope.historyFilters = {
            date: undefined,
            activity: undefined,
            performedBy: undefined,
            reaction: undefined
        };

        this.$scope.reactionFilterSelection = this.$scope.reactionOptions[0];
    }

    _initNotesTableFilter() {
        this.$scope.notesFilters = {
            when: undefined,
            createdBy: undefined,
            details: undefined,
            text: undefined,
            reverse: true
        };
    }

    /**
     * Display message: This patient is currently checked in for an appointment. Patient status cannot be changed until the patient is Checked-out.
     * @private
     */
    _cannotDeactivateAlertModal() {

        this.$uibModal.open({
            windowClass: 'alertModal',
            template: require('../widgets/cannot-deactivate-alert-modal.html'),
            css: require('../widgets/alert-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.close('cancel');
            }
        });
    }

    /**
     * Alert user that insurance information needs to be entered for the patient in order to update the medicare checkbox
     * @private
     */
    _cannotSetMedicareModal() {

        this.$uibModal.open({
            windowClass: 'alertModal',
            template: require('../widgets/cannot-set-medicare-modal.html'),
            css: require('../widgets/alert-modal.scss'),
            controller: function ($uibModalInstance, $scope) {

                $scope.cancel = () => $uibModalInstance.dismiss();
            }
        });
    }

    /**
     * User selected "Edit Vial" on a vial.
     * @private
     */
    offerReplaceRetireVialModal(vialVm) {

        this.$uibModal.open({
            windowClass: 'vialDetails',
            scope: this.$scope, //passed current scope to the modal
            template: require("../../../pages/common/treatment-vial-details/treatment-vial-details.html"),
            css: require("../../../pages/common/treatment-vial-details/treatment-vial-details.scss"),
            controller: TreatmentVialDetailsController,
            resolve: {
                /** {PatientDataModel} */
                patient: () => this.$scope.patient,
                /** {VialDataModel}*/
                vial: () => vialVm
            }
        }).result.then(() => {
            this.$scope.activities = [];
            this.$scope.tests = [];
            this.$scope.patientDetailsTests = [];
            this.$scope.treatments = [];
            this.$scope.patientDetailsTreatment = [];
            this.$scope.vials = [];
            this.$scope.patientDetailsRx = [];
            this.$scope.displayActivities = [];
            this.$scope.attachments = undefined;
            this.$scope.bills = [];
            this.$scope.provider = {};

            var params = this.getRouteParams();
            if (!params || !params.href) {
                console.log('PatientDetailsController :: Route parameters missing or corrupted.');
                this.routeToPage(this.urlPaths.PATIENT_LIST);
            }

            this._loadPatientData(params);
        });

        // The guest modal UI controller instance explicitly calls the modal-instance's close, BUT, there's no
        // point to doing any about it at this juncture. The deferred effects will hit via the notifications.
        // see #_updatePatientVial for details.
    }

    _printCardLabel() {
        const practiceName = this.$scope.practice.name;
        const patient = this.$scope.patient;
        const patientPhone = this.$filter('agPhoneNumber')(patient.phoneNumbers.mobile || patient.phoneNumbers.home);
        submitPrintJob(
            PRINTER_DYMO30336_LANDSCAPE,
            <PatientIdCardLabel
                barcode={patient.pin}
                practiceName={practiceName}
                patientName={patient.person.givenName + ' ' + patient.person.familyName}
                patientPhone={patientPhone}
            />
        )
    }

    _printReport(reportUrl) {
        var hiddenLink = document.createElement('a');
        hiddenLink.href = reportUrl;
        hiddenLink.target = '_blank';
        hiddenLink.click();
    }

    _printPositiveReactions(allergyTest) {
        this.allergyTestService.get(allergyTest)
            .then((dto) => this._printReport(dto._links.positiveReactionReport));
    }

    _printAllAntigensTested(allergyTest) {
        this.allergyTestService.get(allergyTest)
            .then((dto) => this._printReport(dto._links.antigensTestedReport));
    }

    _printVialLabel(vial) {
        return this.labelPrinterService.printTreatmentVials(vial);
    }

    /**
     * Create next rx modal
     * @private
     */
    _createNextRx(vials) {
        this.$uibModal.open({
            template: require('../../../pages/common/create-next-rx/layout.html'),
            css: require('../../../pages/common/create-next-rx/styles.scss'),
            controller: CreateNextRxModalController,
            resolve: {
                vials: () => vials,
                practice: () => this.$scope.practice
            }
        });
    }

    /**
     * Create next traditional rx modal
     * @private
     */
    _createNextClassicalRx(oldVial) {
        let oldPrescription = this.$scope.prescriptions.find(m => m.id === oldVial.prescription.id);

        this.$uibModal.open({
            template: require('../../../pages/common/create-next-classical-rx/layout.html'),
            css: require('../../../pages/common/create-next-classical-rx/styles.scss'),
            controller: CreateNextClassicalRxModalController,
            windowClass: 'create-next-classical-rx',
            resolve: {
                oldVial: () => oldVial,
                practice: () => this.$scope.practice,
                isTraditionalArrangement: () => {
                    return this.boardService.getAtPractice(this.$scope.practice, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS)
                        .then((boards) => boards.list.some(board => board.arrangement === this.BoardArrangement.TRADITIONAL));
                }
            }
        });
    }

    /**
     * Check if a vial can be created or edited from this vial
     * @private
     */
    _getAvailableVialChanges(treatmentVial) {
        let isReplaceable = true;

        if (angular.isDefined(treatmentVial.statusReason) && treatmentVial.statusReason !== this.PrescriptionReason.NOMORE) {
            isReplaceable = false;
        }
        else {
            let prescription = this.$scope.prescriptions.find(m => m.id === treatmentVial.prescription.id);
            let prescribedVial = prescription.vials.find(m => m.barcode === treatmentVial.barcode);
            for (let rx of this.$scope.prescriptions.filter(p => !p.hasOwnProperty('cancelledBy'))) {
                for (let pVial of rx.vials) {
                    if (pVial.prevPrescribedVial && pVial.prevPrescribedVial.id === prescribedVial.id) {
                        isReplaceable = false;
                    }
                }
            }
        }

        return isReplaceable;
    }

    _removePatient(patient) {
        this.$uibModal.open({
            template: require('../../../pages/patient/widgets/remove-patient.html'),
            css: require('../../../pages/patient/widgets/remove-patient.scss'),
            controller: ($scope, $uibModalInstance, patient, patientService) => {
                $scope.remove = () => {
                    patientService.remove(patient).then((patient) => {
                        $uibModalInstance.close('');
                        this.routeToPage(this.urlPaths.PATIENT_LIST);
                    });
                };
                $scope.cancel = () => $uibModalInstance.close('');
            },
            resolve: {
                patient: patient,
                patientService: this.patientService
            }
        });
    }
}
