'use strict';
import angular from 'angular';
import moment from 'moment';
import ngRoute from 'angular-route';

import MixingVialController from './mixing-vial.controller.js';

export default angular.module('pages.mixing.mixingVial', [ngRoute])
    .config(config)
    .controller('MixingVialController', MixingVialController)
    .directive('mixVial', ["$timeout", mixVial])
    .directive('agfocus', agfocus)
    .name;

function config($routeProvider, UrlPaths) {

    $routeProvider.when(UrlPaths.DASHBOARD_MIXING_VIAL + ':number', {
        template: require('./mixing-vial.html'),
        css: require('./mixing-vial.scss'),
        controller: 'MixingVialController'
    });
}

function agfocus() {
    return {
        link: function (scope, element, attrs) {
            element[0].focus();
        }
    };
}

function mixVial($timeout) {
    return {
        link: function (scope, element, attrs) {

            const loader_class = ".loader";

            scope.initVial = function (newVialSize) {
                let bottleSizer = '';
                if (newVialSize === 5 || newVialSize === 10 || newVialSize === 15)
                    bottleSizer = '-' + newVialSize + 'ml';

                scope.oLoader = {
                    interval: 10,
                    timer: null,
                    upPerc: 0,
                    upHeight: 0,
                    curHeight: 0,
                    step: 1,
                    newHeight: 0,
                    diff_bg: 0,
                    diff_top: 0,
                    size: $(loader_class).data("loader-size").split(" "),
                    heightInt: 0,
                    bimage: require("../../../../common/img/bottle/bottle-empty" + bottleSizer + ".png"),
                    fimage: require("../../../../common/img/bottle/bottle-full" + bottleSizer + ".png"),
                    loader: $(loader_class).children('.loaded').eq(0)
                };
                scope.oLoader.heightInt = parseInt(scope.oLoader.size[1], 10);

                $(loader_class).css({
                    width: scope.oLoader.size[0],
                    height: scope.oLoader.size[1],
                    'background-image': 'url(' + scope.oLoader.fimage + ')',
                    'background-size': scope.oLoader.size.join(' ')
                });
                $(scope.oLoader.loader).css({
                    width: scope.oLoader.size[0],
                    height: scope.oLoader.size[1],
                    'background-image': 'url(' + scope.oLoader.bimage + ')',
                    'background-size': scope.oLoader.size.join(' ')
                });
            };

            scope.setHeight = function (perc) {

                scope.oLoader.upPerc = parseFloat(perc);

                if (scope.oLoader.upHeight > 0) {
                    scope.oLoader.newHeight = scope.oLoader.upHeight;
                }

                scope.oLoader.upHeight = Math.ceil((scope.oLoader.upPerc / scope.vialSize) * scope.oLoader.heightInt);

                scope.oLoader.upHeight = scope.oLoader.upHeight + scope.oLoader.newHeight;

                scope.oLoader.upHeight = (scope.oLoader.upHeight > scope.oLoader.heightInt ? scope.oLoader.heightInt : scope.oLoader.upHeight);

                scope.oLoader.curHeight = parseFloat($(scope.oLoader.loader).height(), 10);

                scope.oLoader.step = (scope.oLoader.upHeight > (scope.oLoader.heightInt - scope.oLoader.curHeight) ? -1 : 1);

                scope.oLoader.diff_bg = (scope.oLoader.step === 1 ?
                    (scope.oLoader.heightInt - scope.oLoader.curHeight) - scope.oLoader.upHeight :
                    scope.oLoader.upHeight - (scope.oLoader.heightInt - scope.oLoader.curHeight));

                scope.oLoader.timer = setInterval(function () {
                    if (scope.oLoader.diff_bg) {
                        scope.oLoader.diff_bg--;
                        scope.oLoader.curHeight += scope.oLoader.step;
                        scope.oLoader.calc_perc = Math.ceil(scope.oLoader.diff_top / scope.oLoader.heightInt * 100);
                        scope.oLoader.calc_perc = (scope.oLoader.calc_perc < 0 ? 0 : scope.oLoader.calc_perc);
                        scope.oLoader.calc_perc = (scope.oLoader.calc_perc > 100 ? 100 : scope.oLoader.calc_perc);
                        $(scope.oLoader.loader).css({
                            height: scope.oLoader.curHeight
                        });
                    } else {
                        clearInterval(scope.oLoader.timer);
                    }
                }, scope.oLoader.interval);
            };

            scope.focusMe = function (index) {
                $timeout(function () {
                    const focusme = element.find('input')[0];
                    if (focusme !== undefined) {
                        focusme.focus();
                    }
                });
            };

            scope.setAllergen = function () {
                scope.selected = scope.count;
                scope.focusMe(scope.count);
                scope.count++;
                if(scope.vial.substances[scope.count - 1] !== undefined){
                    if (scope.vial.substances[scope.count - 1].mixed === true) {
                        scope.scanBarcode(scope.count);
                    }
                }
            };

            scope.scanBarcode = function (id) {
                let rxSubstance = scope.vial.substances[scope.count - 1];

                if (rxSubstance.barcodeInput === "=" && scope.allowAutoBarcode && rxSubstance._availableSubstances.length) {
                    rxSubstance.barcodeInput = rxSubstance._availableSubstances[0].barcode;
                }

                let availableSubstance = rxSubstance._availableSubstances.find(s => s.barcode === rxSubstance.barcodeInput);
                if (rxSubstance.mixedFromBarcode) {
                    angular.element(document.getElementById('inputAllergen-' + id)).addClass("inactive");
                    rxSubstance.barcodeInput = rxSubstance.mixedFromBarcode;

                    if (scope.count <= scope.vial.substances.length) {
                        scope.setHeight(scope.vial.substances[scope.count - 1].dosage);

                        if (scope.count <= scope.vial.substances.length) {
                            scope.setAllergen(scope.count);
                        }
                    }
                }
                else if (availableSubstance) {
                    angular.element(document.getElementById('inputAllergen-' + id)).addClass("inactive");
                    if (scope.count <= scope.vial.substances.length) {
                        scope.setHeight(rxSubstance.dosage);
                        rxSubstance.mixedFromBarcode = rxSubstance.barcodeInput;
                        rxSubstance.error = false;
                        if (scope.count <= scope.vial.substances.length) {
                            scope.setAllergen(scope.count);
                        }
                    }

                    let beyondUseDate = scope.vial._beyondUse;
                    let expirationDate = availableSubstance.useBy;
                    if (beyondUseDate && expirationDate && moment(expirationDate).isBefore(moment(beyondUseDate))) {
                        scope.vial._beyondUse = expirationDate;
                    }
                }
                else {
                    $timeout(() => {
                        scope.vial.substances[scope.count - 1].error = true;
                        angular.element(document.getElementById('inputAllergen-' + id)).val('');
                    });
                }


            };

            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    event.preventDefault();
                    scope.scanBarcode(scope.count);
                }
            });

            scope.count = 0;

            /* Watch total volume which represents what the total volume should be after all antigens are put into the vial */
            scope.$watch('totalVolume', function (newVialVolume, oldVialVolume) {
                if (newVialVolume !== undefined) {
                    let barcodesComplete = scope.vial ? scope.vial.substances.every(ps => ps.mixedFromBarcode) : false;
                    scope.count = barcodesComplete ? scope.vial.substances.length : 0;
                    scope.setAllergen();
                }
            });

            scope.$watch('vialSize', function(newVialSize, oldVialSize) {
                if (newVialSize !== undefined) {
                    scope.initVial(scope.vialSize);
                }
            });

        }
    };
}

config.$inject = ['$routeProvider', 'UrlPaths'];
