'use strict';

import BaseTreatmentController from '../base-treatment.controller';
import { ClassicalDilutions } from '../../../models/classical-dilutions';

export default class TreatmentMeasureIntradermalController extends BaseTreatmentController {

    static $inject = ['$scope', '$injector'];
    constructor($scope, $injector) {
        super($scope, $injector);

        this.PrescriptionReason = $injector.get("PrescriptionReason");
        this.prescriptionService = $injector.get('prescriptionService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.treatmentVialService = $injector.get('treatmentVialService');

        // Scope functions
        $scope.nottest = () => this._notest();
        $scope.setInstructionText = (pos) => this._setInstructionText(pos);
        $scope.setDisabled = (pos) => this._isDisabled(pos);
        $scope.toggleModal = () => {
            this.$scope.showModal = !this.$scope.showModal;
        };

        // Scope data
        $scope.treatmentIDT = true;
        $scope.text = '';
        $scope.wheals = new Array(17);
        this.$scope.showModal = false;
        this.vialTestPlan = null;

        // Load data
        this.treatmentLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    /**
     * Load or reload (upon failed advance)
     * @override
     */
    async reload() {
        super.validateStage([this.TreatmentStage.IDT_RESULTS]);
        this.$scope.location = this.treatment.clientData.treatwiz.vialTestLocation;
        this.$scope.gs = {};
        this.$scope.gs.measureConfig = {
            wheal: {
                unit: 'MM',
                max: 99
            }
        };

        this.disableNext = false;
        this.$scope.autoSelectPosition();

        this.warnCheckIndex = -1;
        this._checkWarnings();
        this.initFooterNote('Measure Intradermal');
    }

    /**
     * Is the Next button disabled?
     * @return {boolean}
     * @override
     */
    nextDisabled() {
        let injections = this.treatment ? this.treatment.injections : [];

        /* If only vial tests are being completed this appointment, then the appointment will be complete
        * and the patient ready for check out once all vial tests are measured */
        let isLastStage = injections.length &&
            injections.every(inj => inj.idtOnly || inj.refill || inj.idtRepeat || (inj.dosage === 0.0 && inj.idtDosage === 0.0));

        this.$scope.nextButtonLabel = isLastStage ? 'Check Out Patient' : 'Next';

        if (this.disableNext)
            return true;

        for (let inj of this.$scope.vialTests) {
            if (!angular.isNumber(inj.idtWhealSize))
                return true;
        }

        return false;
    }

    /**
     * @override
     */
    async nextStep() {
    	console.log("TreatmentMeasureIntradermalController.nextStep()");

        // Disable Next button, so it isn't clicked again
        this.disableNext = true;

        // Save updated prescription
        await this.update();

        // Try to advance to the next stage
        await this.advance();

        return this.treatment;
    }

    /**
     * Apply footer note before saving.
     * @override
     */
    async update() {
        this.updateFooterNote();
        return super.update();
    }

    /**
     * @override
     */
    async advance() {
    	console.log("TreatmentMeasureIntradermalController.advance()");
        let startingStage = this.treatment.stage;

        this.treatment = await this.treatmentService.advance(this.treatment);
        this._updateScopeFromTreatment();

    	if (startingStage === this.treatment.stage) {
            // Server declined to advance. Reload the controller
            console.log("Treatment didn't advance the stage - reloading the current controller");
            this.reload();
            return false;
        }
        else {
            if (this.treatment.stage == this.TreatmentStage.COMPLETE) {
                // Successfully completed - checkout the patient
                this.visit = await this.visitService.checkout(this.visit);
            }

            // Stage advanced - route to next controller
            this.$scope.$apply(() => this.autoRoute());
            return true;
        }
    }

    /**
     * Update the instructions when a new position receives focus.
     * @param pos 1-based position
     * @private
     */
    _setInstructionText(pos) {
        this.$scope.text = '';

        if (this.$scope.vialTests) {
            let injection = this.$scope.vialTests[pos-1];
            this.$scope.injection = injection;
            if (injection)
                this.$scope.text = 'Measure Vial Test - ' + injection._vial.name;
        }
    }

    /**
     * Is the input box at a position disabled?
     * @param pos 1-based position
     * @returns {boolean}
     * @private
     */
    _isDisabled(pos) {
        if (this.$scope.vialTests) {
            let vialTest = this.$scope.vialTests[pos - 1];
            return !vialTest || this._haveInactiveWarning(vialTest) || vialTest.idtDoseOverrideBy;
        }
        else {
            return true;
        }
    }

    /**
     * The Not Tested button was clicked
     * @private
     */
    _notest() {
        this.$scope.injection.idtWhealSize = -1;
        this.$scope[this.$scope.thisInput]='X';
        this.$scope.tabindex++;
        this.$scope.nextTabIndex(this.$scope.tabindex);
    }

    _checkWarnings() {

        // Note funny way of indexing.
        // Done so that the loop may be interrupted and resumed by calling back into this function.
        for (++this.warnCheckIndex; this.warnCheckIndex < this.$scope.vialTests.length; ++this.warnCheckIndex) {
            let inj = this.$scope.vialTests[this.warnCheckIndex];
            if (this._haveActiveWarning(inj)) {
                this._showWarningModal(inj, this.warnCheckIndex);

                // Quit for now. When the modal closes, it'll call back to _checkWarnings()
                return;
            }
        }

        // No more warnings. Do a final scan. If all are diluted or override, auto advance to treatment.
        let mayContinue = true;
        for (let inj of this.$scope.vialTests)
            if (this._haveActiveWarning(inj))
                mayContinue = false;

        if (mayContinue && !this.nextDisabled())
            this.nextStep();
        else
            this.$scope.autoSelectPosition();
    }

    /**
     * Have an IDT warning, and it has not been bypassed in some way
     * @param inj {Injection}
     * @returns {boolean} true if have a warning and it has been bypassed - false if no warning
     * @private
     */
    _haveActiveWarning(inj) {
        return inj.idtWarning && !(inj.idtWarning.override || inj.refill || inj.idtRepeat);
    }

    /**
     * Have an IDT warning, but it's been bypassed or refill?
     * @param inj {Injection}
     * @returns {boolean} true if have a warning, but it has been overridden, bypassed, or refilled - false if no warning.
     * @private
     */
    _haveInactiveWarning(inj) {
        return inj.idtWarning && (inj.idtWarning.override || inj.refill || inj.idtRepeat);
    }

    /**
     * Display vial test warning from the server
     */
    async _showWarningModal(injection, vialIndex) {

        let nextVials = (await this.treatmentVialService.getNextPrescribed(injection._vial)) || [];
        let hasPrepVial = !!nextVials.length;

        let options = [];
        if (!hasPrepVial) {
            options.push(this.PrescriptionReason.DILUTE_VIAL);

            let rx = await this.prescriptionService.get(injection._vial.prescription);
            let prescribedVial = rx.vials.find(pv => pv.barcode === injection._vial.barcode);
            let isConcentrateTraditional = !!rx.diluteTo && !rx.mixingBoard;
            /* Don't provide dilute from mixing board option if not applicable */
            if (!isConcentrateTraditional && prescribedVial && prescribedVial.classicalDilution !== ClassicalDilutions[ClassicalDilutions.length - 1].color) {
                options.push(this.PrescriptionReason.DILUTE_NEW);
            }
        }

        let vm = {
            name: injection._vial.name,
            color: injection._vial.color,
            dosage: injection.idtDosage,
            measurementPos: (vialIndex + 1),
            warningText: injection.idtWarning.warnText,
            options: options,
            userSelection: null,
            vialTest: true,
            hasPrepVial: hasPrepVial
        };

        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            resolve: {
                vm: () => vm
            },
            template: require('../widgets/reaction-warning-modal.html'),
            controller: function ($uibModalInstance, $scope, $sce, vm) {

                $scope.vm = vm;
                $scope.vm.warningText = $sce.trustAsHtml($scope.vm.warningText.replace(/[0-9]+mm/, "<span class='measure'>$&</span>"));

                $scope.continue = () => $uibModalInstance.close(vm.userSelection);
            }
        });

        modalInstance.result
            .then(userSelection => {
                if (userSelection === 'RESCHEDULE') {
                    injection.idtRepeat = true;
                }
                else if (userSelection === 'REMEASURE') {
                    this.$scope.thisInput = "section" + (vialIndex+1);
                    this.$scope[this.$scope.thisInput] = '';
                    injection.idtWhealSize = null;
                }
                else if (userSelection === 'OVERRIDE') {
                    // Physician approved override
                    injection.idtWarning.override =  {note: "Proceed with Physician's Approval"};
                }
                else {
                    injection.refill = userSelection;
                }

                this._checkWarnings();
            })
            .catch(() => {
                this._checkWarnings();
            })
    }
}
