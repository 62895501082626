"use strict";

import angular from 'angular';

export default class AttachmentUploadController {

    static $inject = ['$scope', '$injector', '$uibModalInstance', 'Patient'];

    constructor($scope, $injector, $uibModalInstance, Patient) {
        this.$scope = $scope;
        this.attachmentSrv = $injector.get('attachmentService');
        this.$uibModalInstance = $uibModalInstance;
        this.$scope.patient = Patient;
        this.$scope.file = undefined;
        this.$scope.displayType = undefined;
        this.$scope.fileSelected = false;

        this.$scope.setFile = (element) => {
            // This event occurs from native js outside of angular.
            this.$scope.$apply(function(scope) {
                scope.file = element.files[0];
            });
        };

        this.$scope.$watch(() => this.$scope.file, (newValue) => {
            if (!newValue) {
                return;
            }

            this.$scope.fileSelected = true;
        });

        this.$scope.cancel = () => $uibModalInstance.dismiss('cancel');
        this.$scope.continue = () => this._uploadFile(this.$scope.patient, this.$scope.file, this.$scope.displayType);
    }

    _uploadFile(patient, file, type) {
        this.attachmentSrv.createForPatient(patient, file, type)
            .then(() => {
                console.log('File uploaded successfully.');
                this.$uibModalInstance.close();
            }, (err) => {
                console.log('An error occurred uploading the file. [' + err + '].');
                this.$uibModalInstance.dismiss('error');
            });
    }
}