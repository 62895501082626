'use strict';

import moment from 'moment';

import BaseService from './base.service.js';

export default class NoticeService extends BaseService {
	
	/**
     * Get all active Notices. (These are defined globally.)
     *
     * @return Promise to Notice.List
     */
    getActive() {        
        return this.serverAPI.getGlobalLinks().then((global) => {
            let asOfDate = moment().format('YYYY-MM-DDTHH:mm:ss');
            return this.serverAPI.get(global.activeNotices, { asOfDate: asOfDate });
        });
    }
}
