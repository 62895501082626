'use strict';

import BaseService from './base.service.js';

export default class AllergyTestConfigService extends BaseService {

    /**
     * @type{Map<String,'classic'|'oto'|'both'|'none'>}
     * Practice ID to result for haveClassicalTests() */
    _cacheWorkflowCheck = new Map();

    /**
     * Get AllergyTestConfigs for a Practice
     *
     * @return Promise to a AllergyTestConfig.List (sorted by name)
     */
    getAtPractice(practice) {
        return this.serverAPI.get(practice._links.allergyTestConfig, {});
    }

    /**
     * Does the given practice have one or more classical tests defined?
     * @param practice
     * @returns {Promise<boolean>}
     */
    haveClassicalTests(practice) {
        return this._loadCachedWorkflow(practice).then(value => value === 'classic' || value === 'both');
    }

    /**
     * Does the given practice have one or more otolaryngic tests defined?
     * @param practice
     * @returns {Promise<boolean>}
     */
    haveOtolaryngicTests(practice) {
        return this._loadCachedWorkflow(practice).then(value => value === 'oto' || value === 'both');
    }

    /**
     * Create a new AllergyTestConfig in a Practice. (Super-admin only.)
     *
     * @param practice to add the AllergyTestConfig to
     * @param config new AllergyTestConfig DTO
     * @returns Promise to the created AllergyTestConfig DTO
     */
    create(practice, config) {
        return this.serverAPI.post(practice._links.allergyTestConfig, {}, config);
    }

    /**
     * Convert a MeasurementUnit DTO enum value into the display text to show after a measurement.
     */
    getUnitSuffix(measurementUnitDTO) {
        return (measurementUnitDTO === 'RANGE' ? '+' : 'mm');
    }

    /**
     * Load missing value into _cacheWorkflowCheck and return it.
     * @param practice
     * @returns {Promise<'classic'|'oto'|'both'|'none'>}
     * @private
     */
    _loadCachedWorkflow(practice) {
        const cachedValue = this._cacheWorkflowCheck.get(practice.id);
        if (cachedValue !== undefined) {
            return this.serverAPI.$q.resolve(cachedValue);
        }
        else {
            return this.getAtPractice(practice).then((configs) => {
                const haveClassical = configs.list.find(c => c.active && c.classicalSpt != null) !== undefined;
                const haveOtolaryngic = configs.list.find(c => c.active && c.spt && c.spt.length) !== undefined;
                const value = haveClassical && haveOtolaryngic ? 'both' : haveClassical ? 'classic' : haveOtolaryngic ? 'oto' : 'none';
                this._cacheWorkflowCheck.set(practice.id, value);
                return value;
            });
        }
    }
}
