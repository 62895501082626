import angular from 'angular';

export default class DisplayBillingCodesController {
    static $inject = ['$scope', 'diagnosisCodeService', 'procedureCodeService'];

    constructor($scope, diagnosisCodeService, procedureCodeService) {
        this.$scope = $scope;

        this.diagnosisCodes = [];
        this.procedureCodes = [];

        diagnosisCodeService.getAll().then((result) => {
            this.diagnosisCodes = result.list;
        });

        procedureCodeService.getAll().then((result) => {
            this.procedureCodes = result.list;
        });
    }

    verifyDiagnosisCode() {
        if (this.diagnosisCodes.findIndex(m => m.code === this.diagnosisCode) === -1) {
            this.diagnosisCode = '';
        }
    }

    verifyProcedureCode() {
        if (this.procedureCodes.findIndex(m => m.code === this.procedureCode) === -1) {
            this.procedureCode = '';
        }
    }

    // Get global diagnosis codes that do not yet exist on the bill
    getDiagnosisCodes() {
        let result = [];
        for(let diagnosisCode of this.diagnosisCodes) {
            if (this.$scope.bill.diagnosisCodes.findIndex(m => m.code === diagnosisCode.code) === -1) {
                result.push(diagnosisCode.code);
            }
        }
        return result;
    }

    // Get global procedure codes that do not yet exist on the bill
    getProcedureCodes() {
        let result = [];
        for(let procedureCode of this.procedureCodes) {
            if (this.$scope.bill.procedureCodes.findIndex(m => m.code === procedureCode.code) === -1) {
                result.push(procedureCode.code);
            }
        }
        return result;
    }

    addDiagnosisCode() {
        if (this.diagnosisCode) {
            let obj = { code: this.diagnosisCode };
            this.$scope.bill.diagnosisCodes.push(obj);
            this.$scope.bill._dirty = true;
            this.diagnosisCode = '';
        }
    }

    removeDiagnosisCode(i) {
        this.$scope.bill.diagnosisCodes.splice(i, 1);
        this.$scope.bill._dirty = true;
    }

    addProcedureCode() {
        if (this.procedureCode && this.procedureCount) {
            let obj = { code: this.procedureCode, count: this.procedureCount }
            this.$scope.bill.procedureCodes.push(obj);
            this.$scope.bill._dirty = true;
            this.procedureCode = '';
            this.procedureCount = '';
        }
    }

    removeProcedureCode(i) {
        this.$scope.bill.procedureCodes.splice(i, 1);
        this.$scope.bill._dirty = true;
    }
}
