"use strict";

import angular from 'angular';

/**
 * Enumeration of the variety of roles for phone-numbers.
 */
export default angular.module("models.phone-context", [])
    .constant("PhoneContext", {
        HOME : "home",
        WORK : "work",
        MOBILE : "mobile"
    }).name;
