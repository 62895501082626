"use strict";

import BaseApprovalController from "../base-approval.controller.js";

/**
 * Controller for Test Approvals.
 */
export default class TestReviewApprovalController extends BaseApprovalController {

    constructor($scope, $injector) {
        super($scope, $injector);

        this.allergyTestService = $injector.get('allergyTestService');
        this.allergyTestConfigService = $injector.get('allergyTestConfigService');
        this.billService = $injector.get('billService');
        this.BoardArrangement = $injector.get('BoardArrangement');
        this.boardService = $injector.get('boardService');
        this.panelService = $injector.get('panelService');
        this.Procedure = $injector.get('Procedure');
        this.ServiceStatus = $injector.get('ServiceStatus');
        this.substanceService = $injector.get('substanceService');
        this.substanceCategoryService = $injector.get('substanceCategoryService');
        this.testReviewService = $injector.get('testReviewService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.$sce = $injector.get('$sce');

        /**
         * Logical State change handler. Authorized users may alter an antigen test results' stats.
         * This routine reacts to such changes to the endPoint. We alter the test row's data-object's "treat" field
         * to reflect special cases. "treat" logically means "should I treat the patient for this?"
         *
         * @param {Integer} antigenIndex; is the logical list index within scope.vmResults
         * @param {Integer} updatedEndPoint; where 0 < x < MAX-END-POINT
         */
        this.$scope.onAntigenEndPointUpdate = (antigenIndex, updatedEndPoint) => {
            if (updatedEndPoint == 0) {
                this.$scope.vmResults[antigenIndex].treat = false;
            }
        };

        // This callback reacts to UI changes on the Prescription field of an Antigen row in the subject test result.
        this.$scope.onAntigenPrescriptionChange = (antigenIndex, createRx) => {};

        this.$scope.isTreatmentConfigDisabled = (index) => this._isTreatmentConfigDisabled(index);
        this.$scope.canStartPrescription = () => this._canStartPrescription();
        this.$scope.approveReview = (createRx) => this._approveTestReview(createRx);
        this.$scope.editDelayedReactions = () => { this.$scope.enableDelayedReactions = true; };
        this.$scope.saveDelayedReactions = () => this._saveDelayedReactions();
        this.$scope.saveAndExit = () => this._saveAndExit();

        this.initData(this.$scope.testReviewHref);
    }

    async initData(testReviewHref) {
        /*
         * Initialize $scope with empty data until it can be async loaded
         */
        this.$scope.spt = {};
        this.$scope.idt = {};
        this.$scope.patient = { name: '', chartNum: '', dayOfBirth: '' };
        this.$scope.performedBy = { name: '', actionDateTime: '' };
        this.$scope.spt.vitals = [];
        this.$scope.idt.vitals = [];
        this.$scope.vmResults = [];
        this.$scope.antigenTableConfig = {};
        this.$scope.antigenCategories = []; // SubstanceCategory DTO + 'positive' true if any computedEndPoint > 0
        this.$scope.spt.notes = [];
        this.$scope.idt.notes = [];
        this.$scope.doctorSelectedTreatment = this.$scope.patientSelectedTreatment = 'NONE';
        this.$scope.isCandidate = false;
        this.$scope.approvalNote = '';
        this.$scope.prescribed = undefined;
        this.$scope.enableDelayedReactions = false;
        this.$scope.approvedBy = undefined;
        this.$scope.cancelledBy = undefined;
        this.$scope.warning = undefined;

        let testReview = this.testReview = await this.testReviewService.get({href: testReviewHref});

        let sptPromise = this.allergyTestService.getUncached(this.testReview.sptTest);
        let idtPromise = this.allergyTestService.getUncached(this.testReview.idtTest);
        let [sptTest, idtTest] = await Promise.all([sptPromise, idtPromise]);
        this.sptTest = sptTest;
        this.idtTest = idtTest;

        if (testReview.cancelledBy) {
            this.$scope.isApproval = false;
        }

        this.$scope.patientSelectedTreatment = idtTest.treatmentType;
        this.$scope.doctorSelectedTreatment = idtTest.recommendedTreatmentType ? idtTest.recommendedTreatmentType : idtTest.treatmentType;
        this.$scope.isCandidate = idtTest.candidate;
        this.$scope.prescribed = idtTest.prescribed;

        let boardPromise = this.boardService.getAtPractice(this.$scope.practice, this.Procedure.MIXING, this.ServiceStatus.IN_SERVICE, this.boardService.NO_VIALS);

        let uaQ = [];
        if (testReview.approvedBy) {
            uaQ.push(this.userService.populateUserAction(testReview.approvedBy).then(approvedBy => this.$scope.approvedBy = approvedBy));
        }

        if (testReview.cancelledBy) {
             uaQ.push(this.userService.populateUserAction(testReview.cancelledBy).then(cancelledBy => this.$scope.cancelledBy = cancelledBy));
        }

        uaQ.push(this.userService.populateUserAction(sptTest.performedBy).then(performedBy => this.$scope.spt.performedBy = performedBy));
        uaQ.push(this.userService.populateUserAction(idtTest.performedBy).then(performedBy => this.$scope.idt.performedBy = performedBy));

        let patient = this.$scope.patient = await this.patientService.get(idtTest.patient, idtTest);
        let person = patient.person;
        patient.name = person.givenName + ' ' + person.middleName + ' ' + person.familyName;

        this.sptVisit = await this.visitService.get(sptTest.visit);
        this.idtVisit = await this.visitService.get(idtTest.visit);
        this.$scope.spt.encounter = (await this.billService.get(this.sptVisit.bill)).encounter;
        this.$scope.idt.encounter = (await this.billService.get(this.idtVisit.bill)).encounter;

        this.populateVitals(this.sptVisit, this.$scope.spt.vitals);
        this.populateQuestionnaire(this.sptVisit, this.$scope.spt.notes);
        this.populateVitals(this.idtVisit, this.$scope.idt.vitals);
        this.populateQuestionnaire(this.idtVisit, this.$scope.idt.notes);

        let sptAppointment = await this.appointmentService.get(this.sptVisit.appointment);
        let panel = this.panel = await this.panelService.get(sptAppointment.panel);
        this.panelService.populateSptGroupNames(this.$scope.practice, panel);

        // Add meta-data to controlResults and add them to scope
        const sptGroupSize = this.$scope.practice.config.sptSubstancesPerTray / this.$scope.practice.config.sptPrickersPerTray;
        let tmpResults = [];

        for (let result of sptTest.controlResults.concat(idtTest.controlResults)) {
            let initResult = tmpResults.find(m => m.substance.id === result.substance.id);
            if (initResult) {
                // Copy IDT results to SPT results
                Object.assign(initResult, result);
            }
            else {
                let subst = await this.substanceService.get(result.substance, sptTest);

                let os = this.panel.substances.find((os) => os.substance.id === subst.id);
                result._name = subst.name;
                result._substanceCategoryId = subst.category.id;
                result._sptPos = result._orderBy = (os ? Number(os.sptPos) : -1);
                result._isControl = true;
                result._sptGroup = os ? os.sptGroup : null;
                result._groupPos = os ? (os.sptPos % sptGroupSize) + 1 : -1;

                if (!os || !sptTest.sptGroups || sptTest.sptGroups.includes(os.sptGroup) || result.idtDilution === 0) {
                    tmpResults.push(result);
                }
            }
        }

        let groupsWithMeasuredSptAntigens = [];
        for (let pos = 0; pos < sptTest.antigenResults.length; ++pos) {
            let result = sptTest.antigenResults[pos];

            let os = this.panel.substances.find((os) => os.substance.id === result.substance.id);
            if (os) {
                if (groupsWithMeasuredSptAntigens.indexOf(os.sptGroup) == -1 && result.sptMeasurements && result.sptMeasurements.wheal != -1 && result.sptMeasurements.wheal != null) {
                    groupsWithMeasuredSptAntigens.push(os.sptGroup);
                }
            }
        }

        // Add meta-data to antigenResults and add them to scope
        this.$scope.isAnyCategoryPositive = false;
        for (let pos = 0; pos < sptTest.antigenResults.length; ++pos) {
            let result = sptTest.antigenResults[pos];
            result._isControl = false;

            let os = this.panel.substances.find((os) => os.substance.id === result.substance.id);
            if (os) {
                let subst = os.substance._dto;
                result.substance = subst;
                result._name = subst.name;
                result._substanceCategoryId = subst.category.id;
                result._sptPos = result._orderBy = Number(os.sptPos);
                result._panelPos = Number(os.panelPos);
                result._sptGroup = os.sptGroup;
                result._groupPos = (os.sptPos % sptGroupSize) + 1;

                if (result.computedEndPoint > 0) {
                    panel._categories.get(subst.category.id)._isPositive = true;
                    this.$scope.isAnyCategoryPositive = true;
                }

                let isMeasuredSptGroup = groupsWithMeasuredSptAntigens.indexOf(os.sptGroup) != -1;
                if (isMeasuredSptGroup || result.idtDilution === 0) {
                    tmpResults.push(result);
                }
            }
        }

        // Add IDT antigen results
        for (let pos = 0; pos < idtTest.antigenResults.length; ++pos) {
            let existsInSpt = false;
            let result = idtTest.antigenResults[pos];
            result._isControl = false;

            let os = this.panel.substances.find((os) => os.substance.id === result.substance.id);
            if (os) {
                let subst = os.substance._dto;
                let initResult = tmpResults.find(m => m.substance.id == subst.id);

                if (initResult) {
                    delete result.sptMeasurements;
                    Object.assign(initResult, result);
                    existsInSpt = true;
                }
                else {
                    result.substance = subst;
                    result._name = subst.name;
                    result._substanceCategoryId = subst.category.id;
                    result._sptPos = result._orderBy = Number(os.sptPos);
                    result._panelPos = Number(os.panelPos);
                    result._sptGroup = os.sptGroup;
                    result._groupPos = (os.sptPos % sptGroupSize) + 1;
                }

                if (result.computedEndPoint > 0) {
                    panel._categories.get(subst.category.id)._isPositive = true;
                    this.$scope.isAnyCategoryPositive = true;
                }

                //If the SPT Group was skipped, but it was measured at IDT add to result list
                if (!existsInSpt && result.idtDilution == 0) {
                    tmpResults.push(result);
                }
            }

        }

        this.$scope.antigenCategories = [];
        panel._categories.forEach(cat => {
            if (cat._isAntigen)
                this.$scope.antigenCategories.push(cat);
        });

        await Promise.all(uaQ);
        if (idtTest.stage === 'ABORTED' && idtTest.performedBy.note) {
            this.$scope.warning = idtTest.performedBy.note;
        }

        this.addActionNote('Test Procedure', sptTest.performedBy, 1, this.$scope.spt.notes);
        this.addWarningNote('Histamine', sptTest.warnings.SPT_HISTAMINE, 40, sptTest.performedBy, this.$scope.spt.notes);
        this.addActionNote('Approved', sptTest.approvedBy, 100, this.$scope.spt.notes);
        this.addActionNote('Cancelled', sptTest.cancelledBy, 101, this.$scope.spt.notes);
        sptTest.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 0, this.$scope.spt.notes));

        this.addActionNote('Test Procedure', idtTest.performedBy, 1, this.$scope.idt.notes);
        this.addWarningNote('Histamine', idtTest.warnings.SPT_HISTAMINE, 40, sptTest.performedBy, this.$scope.idt.notes);
        this.addActionNote('Approved', idtTest.approvedBy, 100, this.$scope.idt.notes);
        this.addActionNote('Cancelled', idtTest.cancelledBy, 101, this.$scope.idt.notes);
        idtTest.otherActions.forEach(ua => this.addActionNote(ua.label, ua, 0, this.$scope.idt.notes));

        // Prepare the range of EndPoints. View components will need to be provided the collection values as they
        // lack the means to generate them in their given impl (Angular's ng-options constraints)
        let practiceConfig = this.$scope.practice.config;
        let dilutionsCount = Math.max(practiceConfig.idtDilutionsCount, practiceConfig.mixDilutionsCount);
        this.$scope.validEndPoints = [];
        for(let iEndPoint=0; iEndPoint <= dilutionsCount; iEndPoint++) {
            this.$scope.validEndPoints.push(iEndPoint);
        }

        // Wait until here to copy into scope, so that all the data arrives at once.
        // index.js stops listening after the first bit of results (an optimization)
        this.$scope.vmResults = tmpResults.sort((a,b) => a._orderBy - b._orderBy);

        /*
         * Compute IDT Dilution ranges
         */
        let testConfig = await this.allergyTestConfigService.get(idtTest.config);

        let dilutionGroups = new Map();
        for (let sptAlg of testConfig.spt) {
            let sptWheal = sptAlg.sptWhealSize;
            let dilution = sptAlg.idtDilution;
            if (sptWheal < 0 || sptWheal > 99 || dilution > 99)
                continue;
            let group = dilutionGroups.get(dilution);
            if (group === undefined) {
                group = {
                    dilution: dilution,
                    styleClass: 'dil'+dilution,
                    min: 99,
                    max: 0
                };
                dilutionGroups.set(dilution, group);
            }
            if (sptWheal < group.min)
                group.min = sptWheal;
            if (sptWheal > group.max)
                group.max = sptWheal;
        }

        this.$scope.dilutionRanges = [];
        for (let group of dilutionGroups.values()) {
            if (group.min === 0)
                group.label = this.$sce.trustAsHtml("&le; " + group.max);
            else if (group.max === 99 || group.dilution === 100)
                group.label = this.$sce.trustAsHtml("&ge; " + group.min);
            else if (group.min === group.max)
                group.label = this.$sce.trustAsHtml(group.min.toString());
            else
                group.label = this.$sce.trustAsHtml(group.min + "-" + group.max);

            this.$scope.dilutionRanges.push(group);
        }
        this.$scope.dilutionRanges.sort((a,b) => a.dilution - b.dilution);

        /*
         * Antigen/Control results table config
         */
        this.isClassical = !!testConfig.classicalSpt;
        this.$scope.antigenTableConfig = {
            isClassical: this.isClassical,
            sptWhealUnit: testConfig.classicalSpt && testConfig.classicalSpt.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalSpt.wheal.unit) : 'mm',
            sptErythemaUnit: testConfig.classicalSpt && testConfig.classicalSpt.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalSpt.erythema.unit) : 'mm',
            idtWhealUnit: testConfig.classicalIdt && testConfig.classicalIdt.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalIdt.wheal.unit) : 'mm',
            idtErythemaUnit: testConfig.classicalIdt && testConfig.classicalIdt.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.classicalIdt.erythema.unit) : 'mm',

            sptHistWhealUnit: testConfig.sptHistamine && testConfig.sptHistamine.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.sptHistamine.wheal.unit) : 'mm',
            sptHistErythemaUnit: testConfig.sptHistamine && testConfig.sptHistamine.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.sptHistamine.erythema.unit) : 'mm',
            idtHistWhealUnit: testConfig.idtHistamine && testConfig.idtHistamine.wheal ? this.allergyTestConfigService.getUnitSuffix(testConfig.idtHistamine.wheal.unit) : 'mm',
            idtHistErythemaUnit: testConfig.idtHistamine && testConfig.idtHistamine.erythema ? this.allergyTestConfigService.getUnitSuffix(testConfig.idtHistamine.erythema.unit) : 'mm'    
        };

        /*
         * Init TreatmentConfig and Panel variables
         */
        this.$scope.selectedTreatmentConfig = this.isClassical ? [null,null,null,null,null] : [null];
        let isHistorical = !(this.$scope.isApproval || this.$scope.isTestWizard);
        idtTest.treatmentConfigs.map((tcRef, idx) => {
            return this.treatmentConfigService.get(tcRef.treatmentConfig).then(tc => {
                this.$scope.selectedTreatmentConfig[tcRef.dilution] = tc.active || isHistorical ? tc : null;
            });
        });

        this.$scope.selectedPanel = null;
        if (idtTest.mixingPanel) {
            this.panelService.get(idtTest.mixingPanel).then(panel => this.$scope.selectedPanel = panel);
        }

        this.$scope.selectedBoard = null;
        if (idtTest.mixingBoard) {
            this.boardService.getWithNoVials(idtTest.mixingBoard).then(board => this.$scope.selectedBoard = board);
        }

        this.$scope.boards = (await boardPromise).list;
        let traditionalBoards = this.$scope.boards.filter(m => m.arrangement === this.BoardArrangement.TRADITIONAL);
        this.$scope.hasTraditionalArrangement = traditionalBoards.length > 0;
        this.$scope.boards = this.$scope.hasTraditionalArrangement ? traditionalBoards : this.$scope.boards;
        this.$scope.showBoardSelect = !this.isClassical || this.$scope.hasTraditionalArrangement;
        this.$scope.showPanelSelect = !this.$scope.showBoardSelect;

        if (!this.$scope.showBoardSelect) {
            this.$scope.panels = (await this.panelService.getActiveAtPractice(this.$scope.practice)).list.filter(m => m.type === 'MIXING');
        }

        if (this.$scope.isApproval) {
            let treatmentConfigs = (await this.treatmentConfigService.getAtPractice(this.$scope.practice)).list;
            this.$scope.treatmentConfigs = treatmentConfigs.filter(config => config.active);

            // Initialize default treatment config and mixing board, if applicable
            if (this.$scope.doctorSelectedTreatment !== 'NONE') {
                let relevantConfigs = this.$scope.treatmentConfigs.filter(m => m.treatmentType === this.$scope.doctorSelectedTreatment);
                if (relevantConfigs.length === 1 && idtTest.treatmentConfigs.length === 0) {
                    this.$scope.selectedTreatmentConfig[0] = relevantConfigs[0];
                }

                if (this.$scope.boards.length === 1) {
                    this.$scope.selectedBoard = this.$scope.boards[0];
                }
            }
        }

        this.$scope.$digest();
    }

    _isTreatmentConfigDisabled(index) {
        if (this.$scope.hasTraditionalArrangement) {
            let populatedIndex = this.$scope.selectedTreatmentConfig.findIndex(m => m !== null);
            return populatedIndex >= 0 && populatedIndex !== index;
        }
        else {
            return index > 0 && this.$scope.selectedTreatmentConfig[index-1] == null;
        }
    }

    /**
     * Returns true if the configuration for approving a test and starting a prescription
     * prescription is valid, false otherwise
     */
    _canStartPrescription() {
        return this.$scope.isCandidate
            && this.$scope.isAnyCategoryPositive
            && this.$scope.doctorSelectedTreatment !== 'NONE'
            && this.$scope.selectedTreatmentConfig
            && this.$scope.selectedTreatmentConfig.some(m => m !== null)
            && this.$scope.selectedPanel !== undefined
            && this.$scope.selectedPanel !== null
            && !this.$scope.saving;
    }

    /**
     * @param {boolean} createRx
     * @private
     */
    async _approveTestReview(createRx) {
        this.$scope.saving = true;
        try {
            this._updateModelFromView();
            this.idtTest = await this.allergyTestService.update(this.idtTest);

            let prescribeTreatment = (createRx ? this.idtTest.recommendedTreatmentType : 'NONE');

            /* Approve the test review */
            await this.testReviewService.approve(this.testReview, this.$scope.approvalNote, prescribeTreatment);
        }
        catch(error) {
            this.$scope.saving = false;
            return;
        }

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback();
            }
        });
    }

    /**
     * Saved modified allergy test to server and exit.
     * @returns {Promise<void>}
     * @private
     */
    async _saveAndExit() {
        this._updateModelFromView();

        this.testReview = await this.testReviewService.update(this.testReview);

        this.$scope.$apply((scope) => {
            if (scope.onExitCallback) {
                scope.onExitCallback({testReview: this.testReview});
            }
        });
    }

    /**
     * Update this.idtTest with changes in $scope.
     * Helper for _approveAllergyTest() and _saveAndExit()
     * @private
     */
    _updateModelFromView() {
        for (let item of this.$scope.vmResults) {
            if (item._panelPos >= 0) {
                this.idtTest.antigenResults[item._panelPos].treat = item.treat;
            }
        }

        this.idtTest.candidate = this.$scope.isCandidate;
        this.idtTest.recommendedTreatmentType = (this.$scope.isCandidate ? this.$scope.doctorSelectedTreatment : 'NONE');
        this.idtTest.treatmentConfigs = this.$scope.selectedTreatmentConfig
            .map((tc,i) => { 
                return tc ? { treatmentConfig: { id: tc.id }, dilution: i } : null;
            })
            .filter(tc => tc != null);
        this.idtTest.mixingPanel = (this.$scope.selectedPanel ? { id : this.$scope.selectedPanel.id } : null);
        this.idtTest.mixingBoard = (this.$scope.selectedBoard ? { id : this.$scope.selectedBoard.id } : null);
    }

    /**
     * User has edited some delayed reactions to antigens and wishes to save the results.
     * @private
     */
    async _saveDelayedReactions() {
        this.$scope.enableDelayedReactions = false;
        let idtTest = await this.allergyTestService.update(this.idtTest);

        // Have to reload, because we need to use the new content of allergyTest, but this controller
        // adds new fields to its data that is not in the new object we just received from the server.
        this.initData(idtTest.href);
    }
}
