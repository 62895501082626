'use strict';

import TestingController from '../testing.controller'

export default class TestSptCheckListController extends TestingController {

    static $inject = ['$scope', '$injector', 'checklistService', 'boardService', 'panelService', 'Procedure'];
    constructor($scope, $injector, checklistService, boardService, panelService, Procedure) {

        super($scope, $injector);

        this.boardService = boardService;
        this.panelService = panelService;
        this.checklistService = checklistService;
        this.globalConfigService = $injector.get('globalConfigService');
        this.$q = $injector.get('$q');
        this.Procedure = Procedure;
        this.disableNext = true;
        this.allowAutoBarcode = false;

        $scope.barCode = '';
        $scope.testingViews = [];

        $scope.setLocation = (location) => this._setLocation(location);
        $scope.onBarcodeChange = () => this._onBarcodeChange();
        $scope.barcodeClass = () => this._barcodeClass();

        this._loadGlobalConfig();

        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    _loadGlobalConfig() {
        return this.globalConfigService.get().then((config) => {
            this.allowAutoBarcode = config.allowAutoBarcode;
        });
    }

    reload() {
        super.validateStage(['SPT_CHECKLIST']);

        this.$scope.text = 'Complete Skin Prick Test Checklist';
        this.$scope.location = this.$scope.allergyTest.sptLocation;
        this.initFooterNote("SPT Checklist");

        this.$scope.openPanelGroupsModal = () => this.openPanelGroupsModal((p, g) => this._panelGroupsSelected(p, g));

        // _loadPanel grabs this.$scope.panel or looks up the panel based on the appointment
        this._loadPanel().then(() => {
            // populateSptGroupNames populates panel.substances[x].sptGroup
            this.panelService.populateSptGroupNames(this.$scope.practice, this.$scope.panel);

            this._loadSptBoard().then(() => {
                if (this.$scope.allergyTest.sptBoard) {
                    this.$scope.barCode = this.$scope.allergyTest.sptBoard.barcode;
                }

                if (this._hasSptBoardWarning()) {
                    this.warningModal();
                }
                else {
                    this.openPanelGroupsModal((p, g) => this._panelGroupsSelected(p, g));
                }
            });
        });

        Promise.all([
            this._manageValidBoards(),
            this._loadChecklist()
        ])  .then(() => {
                this.disableNext = false;
            });
    }

    _loadChecklist() {
        return this.checklistService.getForSPT(this.$scope.practice)
            .then(checklist => {
                this.$scope.itemLabels = checklist.items;
                this.$scope.itemChecked = [];
            });
    }

    _loadSptBoard() {
        var deferred = this.$q.defer();
        if (this.$scope.allergyTest.sptBoard) {
            return this.boardService.getWithCurrentVials(this.$scope.allergyTest.sptBoard).then((board) => {
                this.$scope.allergyTest.sptBoard = board;
                this._loadUntestableSubstances(board);
                deferred.resolve();
            });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    }

    _panelGroupsSelected(panel, sptGroups) {
        if (!this.$scope.panel || !panel || this.$scope.panel.id !== panel.id) {
            this.$scope.panel = panel;
            this.$scope.barCode = '';
            this.$scope.boardError = undefined;
            this.$scope.untestableSubstances = undefined;
        }

        this.$scope.allergyTest.sptGroups = sptGroups;
        let numPrickers = sptGroups.length;
        let testingViews = [];

        /*
         * Update "Apply Skin Prick" checklist item (classical only)
         */
        let label = 'Apply Skin Prick Tests:';
        // Track actual SPT pricker names to apply - may be more than sptGroups if controls are in other groups.
        // Using a set to track because there could be more than one control listed for the same group.
        const actualSptPrickers = new Set();

        for (let panelSubst of panel.substances) {
            if (panelSubst.sptPos >= 0 && panelSubst.substance._dto.category._dto._isControl && !sptGroups.includes(panelSubst.sptGroup)) {
                label += (' ' + panelSubst.substance._dto.name + ` (Group ${panelSubst.sptGroup}),`);
                actualSptPrickers.add(panelSubst.sptGroup);
            }
        }
        for (let letter of sptGroups) {
            label += ` Group ${letter},`;
            actualSptPrickers.add(letter);
        }
        label = label.substr(0, label.length - 1); //remove final comma
        numPrickers = actualSptPrickers.size;

        let itemIdx = this.$scope.itemLabels.findIndex(label => label.startsWith('Apply Skin Prick'));
        if (itemIdx < 0)
            itemIdx = this.$scope.itemLabels.length;
        this.$scope.itemLabels[itemIdx] = label;

        if (panel) {
            this.appointment.panel = { id: panel.id };
            this.$scope.selectedPanelId = panel.id;

            testingViews.push({key: 'COLUMN', value: 'Column'});

            const sptGroupSize = this.$scope.practice.config.sptSubstancesPerTray / this.$scope.practice.config.sptPrickersPerTray;
            const numSubstances = sptGroupSize * numPrickers;
            console.log(numSubstances + " substances for groups " + sptGroups + " of size " + sptGroupSize);
            if (numSubstances <= 40) {
                testingViews.splice(0, 0, {key: 'BACK', value: 'Back'});
                testingViews.splice(0, 0, {key: 'ARM', value: 'Arm'});
            } else {
                this.$scope.location = 'COLUMN';
            }
        }
        else {
            this.appointment.panel = null;
        }

        const panelHasControl = panel && panel.substances.some(ps => ps.sptPos >= 0 && ps.substance._dto.category._dto._isControl);
        this.$scope.nextButonLabel = panelHasControl && this.$scope.practice.config.minSptHistamineReactionWait > 0 ? 'Start Histamine Timer' : 'Next';
        this.$scope.testingViews = testingViews;
        this._refreshValidBoards().then();

    }

    /**
     * @override
     * @returns {boolean}
     */
    nextDisabled() {
        if (this.disableNext || !this.$scope.panel || !this.$scope.allergyTest || !this.$scope.allergyTest.sptGroups)
            return true;

        if (!this.$scope.validBarcode) {
            return true;
        }

        for (let idx = 0; idx < this.$scope.itemLabels.length; ++idx) {
            if (!this.$scope.itemChecked[idx])
                return true;
        }

        return false;
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        this.$scope.allergyTest.sptLocation = this.$scope.location;
        this.updateFooterNote();

        return super.update();
    }

    _setLocation(location) {
        this.$scope.location = location;
    }

    _onBarcodeChange() {
        this.$scope.validBarcode = undefined;
        this.$scope.allergyTest.sptBoard = undefined;
        this.$scope.boardError = undefined;
        this.$scope.untestableSubstances = undefined;

        if (this.$scope.barCode === '=' && this.allowAutoBarcode && this.validBoards.length) {
            this.$scope.barCode = this.validBoards[0].barcode;
        }

        if (this.$scope.barCode.length == 8) {
            let candidateFound = false;
            for (let candidateBoard of this.validBoards) {
                if (this.$scope.barCode !== candidateBoard.barcode) {
                    continue;
                }

                candidateFound = true;
                this.$scope.allergyTest.sptBoard = candidateBoard;
                this._loadSptBoard();

                break;
            }

            if (!candidateFound) {
                this.$scope.validBarcode = false;
            }   
        }
    }

    _barcodeClass() {
        // $scope.validBarcode is undefined while checking to see if a scanned barcode is valid, don't want input to flash red during that time - hence strict false check
        if (this.$scope.barCode && this.$scope.barCode.length && this.$scope.validBarcode === false) {
            return 'error';
        }
        else if (this.$scope.boardError) {
            return 'warning';
        }

        return '';
    }

    /**
     * Initialize this.validBoards and refresh it whenever boards at the office change.
     * @returns {Promise} that resolves when complete
     * @private
     */
     _manageValidBoards() {
        // Init the valid boards now
        return this._refreshValidBoards()
            .then(() => {
                // When boards change at the practice, refresh this.validBoards
                return this.notificationService.init();
            })
            .then(() => {
                super.registerSubscription(this.notificationService.subscribeAllBoardUpdatesAtPractice(this.$scope.practice))
                    .then(null, null, (notification) => {
                        if (notification.body.procedure === this.Procedure.SPT)
                            return this._refreshValidBoards();
                    });

                super.registerSubscription(this.notificationService.subscribeBoardCreation(this.$scope.practice))
                    .then(null, null, (notification) => {
                        if (notification.body.procedure === this.Procedure.SPT)
                            return this._refreshValidBoards();
                    });

                super.startAllSubscriptions();
            });
    }

    /**
     * Load the current valid SPT boards at an office.
     * @returns {Promise} that resolves when complete
     * @private
     */
    _refreshValidBoards() {
        if (this.$scope.panel) {
            return this.boardService.getAvailableInPanel(this.$scope.practice, this.$scope.panel, this.Procedure.SPT)
                .then(boards => this.validBoards = boards.list);
        }
        else {
            this.validBoards = [];
            return Promise.resolve();
        }
    }

    _hasSptBoardWarning() {
        return this.$scope.allergyTest.warnings.SPT_BOARD && !this.$scope.allergyTest.warnings.SPT_BOARD.override;
    }

    _loadUntestableSubstances() {
        let panel = this.$scope.panel;
        if (!panel) {
            this.$scope.validBarcode = undefined;
            return;
        }

        let invalidSubstances = this.boardService.getOutOfServiceSubstances(this.$scope.allergyTest.sptBoard);

        let testableSubstances = [];
        let untestableSubstances = [];

        const testableSptGroups = this.$scope.allergyTest.sptGroups;

        // Testable substances should include only those that are in the selected panel groups and not out of service
        for (let panelSubst of this.$scope.panel.substances) {
            let substance = panelSubst.substance._dto;
            let category = substance.category._dto;

            if (panelSubst.sptPos < 0 || (testableSptGroups.indexOf(panelSubst.sptGroup) < 0 && category._isAntigen)) {
                // Not tested during SPT or if antigen's group isn't selected
                continue;
            }

            if (invalidSubstances.some(iS => iS.id === substance.id)) {
                untestableSubstances.push(substance);
            }
            else {
                testableSubstances.push(substance);
            }
        }

        this.$scope.validBarcode = true;
        if (!testableSubstances.length) {
            this.$scope.boardError = 'No substances on this board are in service.';
            this.$scope.untestableSubstances = untestableSubstances;
            this.$scope.validBarcode = false;
        }
        else if (untestableSubstances.length) {
            this.$scope.boardError = 'One or more substances on this board are not in service.';
            this.$scope.untestableSubstances = untestableSubstances;
        }
    }

    warningModal() {
        let parent = this;
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope, //passed current scope to the modal
            template: require('../widgets/board-warning-modal.html'),
            controller: function ($uibModalInstance, $scope) {
                $scope.warningText = "Some substances on the scanned board are not in service. You will not be able to record skin prick reactions for the following substances:";
                $scope.cancel = () => {
                    $uibModalInstance.dismiss();
                }

                $scope.continue = () => {
                    $uibModalInstance.close('success');
                    $scope.allergyTest.warnings.SPT_BOARD.override = { note: "Proceed with board containing expired antigens" };
                    parent.next();
                }
            }
        }).closed.then(function () {
            let $scope = parent.$scope;
            $scope.disableNext = false;
            if (!$scope.panel && !$scope.allergyTest.warnings.SPT_BOARD.override) {
                $scope.openPanelGroupsModal();
            }
        });
    }
}
