'use strict';

import TestingController from '../testing.controller'

export default class TestIdtHistamineTimerController extends TestingController {

    static $inject = ['$scope','$injector'];
    constructor($scope, $injector) {

        super($scope, $injector);

        this.$scope.expiredTimer = false;
        this.$scope.text = 'Intradermal Timer';

        this.disableNext = true;
        this.allergyTestLoaded()
            .then(() => this.reload())
            .catch((e) => console.error(e));

        console.log("TestIdtHistamineTimerController constructor completed !");
    }

    reload() {
        super.validateStage(['IDT_HISTAMINE_TIMER']);
        this.initFooterNote('IDT Histamine');
        this.disableNext = false;
    }

    /**
     * Save if something changed.
     * @override
     */
    update() {
        if (this.updateFooterNote())
            return super.update();
        else
            return Promise.resolve(this.$scope.allergyTest);
    }

    /**
     * @override
     */
    nextDisabled() {
        return this.disableNext || !this.$scope.expiredTimer;
    }

    /**
     * @override
     */
    next() {
        this.disableNext = true;
        this.advance();
    }
}
