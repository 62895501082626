"use strict";

export default class FreshWidgetService {

    /** @constant {String} */static API_WINDOW_FIELD = "FreshWidget";

    /**
     * {FreshWidget} :=
     * {{
     *     close : {function()},
     *     create : {function()},
     *     destroy : {function()},
     *     iframe : {function()},
     *     init : {function(t,i)},
     *     show : {function()},
     *     update : {function(t)}
     * }}
     */
    widgetApi = undefined;

    /** {String} */email = undefined;

    /** {AngularJs.windowService} */windowService;

    /** {AngularJs.$httpParamSerializer} */httpParamSerializer;

    static $inject = ["$window", "$httpParamSerializer"];

    constructor($window, $httpParamSerializer) {
        this.windowService = $window;
        this.httpParamSerializer = $httpParamSerializer;
    }

    ensureReadiness(emailAddress) {

        if (this.email !== emailAddress) {

            if (this.windowService.hasOwnProperty(FreshWidgetService.API_WINDOW_FIELD)) {
                this.email = emailAddress;
                this.widgetApi = this.windowService[FreshWidgetService.API_WINDOW_FIELD]
                this._init(emailAddress);

                return this.widgetApi;
            }
            else {
                // 20230425 FreshDesk is no longer in use.
                // console.warn("FreshWidget did not exist in the global namespace as expected.");
                // console.warn("If you are seeing this warning for the first time, please restart your app-service and retry.");
            }

        }

    }

    /** @returns {FreshWidget} */getApi() {
        return this.widgetApi;
    }

    _init(emailAddress) {

        let initParams = {
                utf8 : true,
                widgetType : "popup",
                buttonType : "text",
                buttonText : "Help",
                buttonColor : "white",
                buttonBg : "#71469f",
                alignment : 4,
                offset : "235px",
                formHeight : "500px",
                screenshot : "no",
                url : "https://audigymedical.freshdesk.com"
            },
            queryParamMap = {
                widgetType : "popup",
                formTitle : "Clarity Help & Support",
                submitTitle : "Submit Request",
                screenshot : "no",
                attachFile : "no",
                searchArea : "no",
                "helpdesk_ticket[requester]" : emailAddress,
                "helpdesk_ticket[subject]" : "Clarity Allergy Support"
            };

        initParams.queryString = this.httpParamSerializer(queryParamMap);

        this.widgetApi.destroy();
        this.widgetApi.init("", initParams);
        this.widgetApi.create();
    }

}