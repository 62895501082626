"use strict";

import angular from 'angular';

/**
 * Enumeration of the variety of Concentrate use types.
 */
export default angular.module("models.concentrate-use-type", [])
    .constant("ConcentrateUseType", {
        TESTING_TRAY : "Testing Tray",
        SP_WELL_TRAY : "SP Well Tray",
        MIXING_TRAY : "Mixing Tray",
        TREATMENT_VIAL : "Treatment Vial"
    }).name;