'use strict';

import TestingController from '../testing.controller'

export default class TestMeasureIntradermalController extends TestingController {

    /** @type{Array<boolean>} Disabled view indexes */
    disabledSubstances;

    static $inject = ['$scope', '$injector','substanceService','substanceCategoryService'];
    constructor($scope, $injector, substanceService, substanceCategoryService) {

        super($scope, $injector);

        this.substanceService = substanceService;
        this.substanceCategoryService = substanceCategoryService;
        this.$q = $injector.get('$q');
        this.boardService = $injector.get('boardService');
        this.disableNext = true;

        /* Traditional only. The viewIndex of histamine */
        this.histamineViewIndex = -1;

        // set text display in the instruction panel
        $scope.setInstructionText = (viewIndex) => {
            if (!this.$scope.antigenSubstances || !viewIndex)
                return;

            this.$scope.tabindex = viewIndex;
            this.$scope.text = '';

            const antigen = this.$scope.antigenSubstances.find(s => s.viewIndex === viewIndex);
            if (antigen) {
                if (!this.isClassical) {
                    this.$scope.text = `Measure Antigen #${antigen.idtPos + 1}: ${antigen.substance.name}`;
                }
                else {
                    this.$scope.text = `Measure Antigen #${viewIndex}: ${antigen.substance.name}`;
                }
                return;
            }

            const control = this.$scope.controlSubstances.find(s => s.viewIndex === viewIndex);
            if (control) {
                if (!this.isClassical) {
                    this.$scope.text = `Measure Added Control: ${control.substance.name}`;
                }
                else {
                    this.$scope.text = `Measure Control #${viewIndex}: ${control.substance.name}`;
                }
            }
        };

        // set input disable for a specific cell in stamp - support disable control(histamine) cell
        $scope.setDisabled = (viewIndex) => {
            if (!this.disabledSubstances)
                return false;

            let disabled = (this.disabledSubstances[viewIndex] === true);

            if (!disabled) {
                const required = !!this.$scope.antigenSubstances.find(s => s.viewIndex === viewIndex) ||
                    !!this.$scope.controlSubstances.find(s => s.viewIndex === viewIndex) ||
                    (viewIndex === this.histamineViewIndex);

                if (!required)
                    disabled = true;
            }

            return disabled;
        };

        // Show warning if reaction to negative control
        $scope.onWhealChange = (index) => {
            // this.negControlWarningModal();
            let control = this.$scope.controlSubstances.find(s => s.viewIndex === index);
            if (!control || control.positive)
                return;

            let measureConfig = this._getMeasureConfig(false);
            let measurement = this._viewToMeasurements(this.$scope.gs.wheals[index], control.positive);

            if ((!measureConfig.wheal || !measureConfig.erythema) && (measurement.wheal > 0 || measurement.erythema > 0)) {
                this.negControlWarningModal();
            }
            else if (measurement.wheal !== undefined && measurement.erythema !== undefined && (measurement.wheal > 0 || measurement.erythema > 0)) {
                this.negControlWarningModal();
            }
        };

        $scope.switchArm = () => {
            this.updateDtoFromView();
        };

        $scope.toggleModal = () => {
            this.$scope.showModal = !this.$scope.showModal;
        };

        let onInputFocus = (index) => {
            let isPositiveControl = this.histamineViewIndex === index;
            this.$scope.gs.measureConfig = this._getMeasureConfig(isPositiveControl);
        };

        /* Grid scope */
        $scope.gs = {
            substanceCount: 0,
            setDisabled: $scope.setDisabled,
            setFocus: $scope.setFocus,
            onInputFocus: onInputFocus,
            onWhealChange: $scope.onWhealChange,
            switchArm: $scope.switchArm,
            wheals: [],
            boxNumbers: [],
            focused: [],
            leftArm: false,
            rightArm: false,
            arrangement: 'SEQ',
            measureConfig: {}
        };

        this.allergyTestLoaded()
            .then(() => this._loadAllergyTestConfig())
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    async reload() {
        super.validateStage(['IDT_ANTIGEN_RESULTS']);

        this.$scope.isLoaded = false;
        this.$scope.gs.leftArm = true;
        this.$scope.gs.rightArm = false;
        this.$scope.gs.limitViewToCount = this.isClassical;
        this.$scope.gs.sheet = 0;
        this.$scope.gs.isOtolaryngic = this.isOtolaryngic;
        this.measuringControls = false;

        // Based IDT view on SPT stamp size, just to give consistent visualization. Result is either 4 or 5.
        this.$scope.stampRows = this.$scope.practice.config.sptSubstancesPerTray / this.$scope.practice.config.sptPrickersPerTray / /*stampColumns*/2;

        // handle the click event in NotTested in Instruction directive
        this.$scope.nottest = () => {
            this.$scope.gs.wheals[this.$scope.thisInput] = 'X';
            this.$scope.tabindex++;
            this.$scope.nextTabIndex(this.$scope.tabindex);
        };

        this.$q.all([this._loadIdtBoard(), this._loadPanel()])
            .then(() => this.loadSubstances())
            .then(() => {
                if (this.$scope.allergyTest.abortPending === true) {
                    this.$scope.nextButonLabel = "End Appointment";
                }

                if (this.measuringControls) {
                    for (let i = 0; i < this.$scope.antigenSubstances.length; i++) {
                        let s = this.$scope.antigenSubstances[i];
                        this.disabledSubstances[s.viewIndex] = true;
                    }
                }

                this.$scope.showModal = false;
                this.disableNext = false;
                this.initFooterNote('Measure Intradermal');
                this.$scope.isLoaded = true;
            });
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        this.updateDtoFromView();
        this.updateFooterNote();
        return super.update();
    }

    updateDtoFromView() {
        this.$scope.antigenSubstances.forEach(s => {
            const measurements = this._viewToMeasurements(this.$scope.gs.wheals[s.viewIndex], false);
            measurements.position = s.viewIndex;
            s.antigenResult.idtMeasurements = measurements;
        });
        this.$scope.controlSubstances.forEach(s => {
            const measurements = this._viewToMeasurements(this.$scope.gs.wheals[s.viewIndex], false);
            measurements.position = s.viewIndex;
            s.controlResult.idtMeasurements = measurements;
        });

        if (this.histamineViewIndex > -1) {
            const measurements = this._viewToMeasurements(this.$scope.gs.wheals[this.histamineViewIndex], true);
            measurements.position = this.histamineViewIndex;
            this.$scope.allergyTest.idtHistamineMeasurement = measurements;
        }
    }

    /**
     * @override
     */
    nextDisabled() {
        if (this.disableNext || !this.$scope.allergyTest)
            return true;

        // Are there any measurements not filled in?
        if (!this.measuringControls) {
            let empty = this.$scope.antigenSubstances.reduce((haveEmpty, substance) => {
                const skip = this.$scope.gs.wheals[substance.viewIndex] === 'X';
                if (skip || haveEmpty) {
                    return haveEmpty;
                }
                else {
                    let measureConfig = this._getMeasureConfig(false);
                    const measurements = this._viewToMeasurements(this.$scope.gs.wheals[substance.viewIndex], false);
                    return (measureConfig.wheal && measurements.wheal == undefined) ||
                        (measureConfig.erythema && measurements.erythema == undefined);
                }
            }, false);

            // Is histamine being tested and is it measured?
            if (!empty && this.histamineViewIndex > -1) {
                let measureConfig = this._getMeasureConfig(true);
                const measurements = this._viewToMeasurements(this.$scope.gs.wheals[this.histamineViewIndex], true);
                empty = (measureConfig.wheal && measurements.wheal == undefined) ||
                    (measureConfig.erythema && measurements.erythema == undefined);
            }

            return empty;
        }
        else {
            for (let i = 0; i < this.$scope.controlSubstances.length; i++) {
                let s = this.$scope.controlSubstances[i];
                if (s.isTestable) {
                    let measureConfig = this._getMeasureConfig(s.positive);
                    const measurements = this._viewToMeasurements(this.$scope.gs.wheals[s.viewIndex], s.positive);
                    let empty = (measureConfig.wheal && measurements.wheal == undefined) ||
                        (measureConfig.erythema && measurements.erythema == undefined);
                    if (empty) {
                        return true;
                    }
                }
            }
        }
    }

    _loadIdtBoard() {
        var deferred = this.$q.defer();
        if (this.$scope.allergyTest.idtBoard) {
            return this.boardService.getWithCurrentVials(this.$scope.allergyTest.idtBoard).then((board) => {
                this.$scope.idtBoard = board;
                deferred.resolve();
            });
        } else {
            deferred.resolve();
        }
        return deferred.promise;
    }

    async loadSubstances() {
        let practice = this.$scope.practice;
        let allergyTest = this.$scope.allergyTest;
        this.disableNext = true;
        this.$scope.antigenSubstances = [];
        this.$scope.controlSubstances = [];
        this.disabledSubstances = [];
        this.panelSubstanceMap = new Map();
        this.panelSubstancePos = new Map();

        // Used at the end in Traditional cases to eliminate gaps from untested substances on the grid.
        let combinedSubstances = [];

        const panel = await this._loadPanel();
        this.panelService.populateSptGroupNames(this.$scope.practice, panel);
        panel.substances.sort((a, b) => a.idtPos - b.idtPos);

        //panel substance map, to reduce the amount of unnecessary looping
        let panelSubstancesBySubstance = new Map();
        for (let panelSubst of panel.substances) {
            let substance = panelSubst.substance._dto;
            let category = substance.category._dto;
            panelSubstancesBySubstance.set(substance.id, panelSubst);
        }

        let sptGroupViewIndexMap = new Map();
        const sptGroupSize = practice.config.sptSubstancesPerTray / practice.config.sptPrickersPerTray;

        for (let i = 0; i < allergyTest.sptGroups.length; i++){
            sptGroupViewIndexMap.set(allergyTest.sptGroups[i], (sptGroupSize * i) + 1);
        }

        let dynamicSubstances = [];
        // We want controls to be to one side and measured last.
        for (let cr of this.$scope.allergyTest.controlResults) {
            let panelSubst = panelSubstancesBySubstance.get(cr.substance.id);
            // Controls behave the same for Oto and Traditional. If they're not tested, they don't show up
            let controlSubstance = await this.handleAnyResult(cr, panelSubst);
            if (controlSubstance && controlSubstance.isTestable) {
                controlSubstance.controlResult = cr;
                dynamicSubstances.push(controlSubstance);
            }
        }

        for (let i = 0; i < this.$scope.allergyTest.antigenResults.length; i++) {
            let ar = this.$scope.allergyTest.antigenResults[i];
            let panelSubst = panelSubstancesBySubstance.get(ar.substance.id);
            // Antigens should have a cell in Oto if: they were tested at SPT or are being tested at IDT
            // Antigens should have a cell in Trad if they are being tested
            let antigenSubstance = await this.handleAnyResult(ar, panelSubst);
            if (antigenSubstance && (!this.isClassical || ar.idtDilution >= 0)) {
                antigenSubstance.antigenResult = ar;
                if (this.isClassical || antigenSubstance.sptMeasurementEntered) {
                    if (panelSubst.idtPos >= 0) {
                        this.$scope.antigenSubstances.push(antigenSubstance);
                        combinedSubstances.push(antigenSubstance);
                    }
                    else {
                        dynamicSubstances.push(antigenSubstance);
                    }
                }
            }
        }

        combinedSubstances.sort((a, b) => a.viewIndex - b.viewIndex);

        dynamicSubstances.sort((a, b) => a.positive ? 0 : 1 - b.positive ? 0 : 1);
        for (let dynamicSubstance of dynamicSubstances) {
            dynamicSubstance.isControl ?
                this.$scope.controlSubstances.push(dynamicSubstance) :
                this.$scope.antigenSubstances.push(dynamicSubstance);

            combinedSubstances.push(dynamicSubstance);
        }

        let testedSubstanceCount = 0;
        let iOffset = 0;
        for (let i = 0; i < combinedSubstances.length; i++) {
            let anySubstance = combinedSubstances[i];
            let iWithOffset = i + iOffset + 1;
            let minViewIndex = this.isClassical ? 0 : sptGroupViewIndexMap.get(anySubstance.sptGroup);
            if (minViewIndex > iWithOffset) {
                iOffset += minViewIndex - iWithOffset;
                iWithOffset = i + iOffset + 1;
            }

            anySubstance.viewIndex = iWithOffset;

            let measurements = null;
            if (anySubstance.isControl) {
                this.$scope.gs.boxNumbers[iWithOffset] = 'C';
                measurements = anySubstance.controlResult.idtMeasurements;

                if (anySubstance.isTestable && (measurements == undefined || measurements.wheal == undefined)) {
                    this.measuringControls = true;
                }
            }
            else {
                this.$scope.gs.boxNumbers[iWithOffset] = (anySubstance.idtPos + 1) || 1;
                measurements = anySubstance.antigenResult.idtMeasurements;
            }

            // Fill in measured value for this substance
            this.$scope.gs.wheals[anySubstance.viewIndex] = this._measurementsToView(measurements, anySubstance.positive);

            if (anySubstance.isTestable && anySubstance.positive) {
                this.histamineViewIndex = anySubstance.viewIndex;
            }

            if (!anySubstance.isTestable) {
                this.disabledSubstances[anySubstance.viewIndex] = true;
                if (!this.isClassical && !anySubstance.sptMeasurementEntered) {
                    this.$scope.gs.wheals[anySubstance.viewIndex] = 'X';
                }
            }
            else {
                testedSubstanceCount++;
            }
        }

        // substanceCount is a misnomer now with SPT groups being skipped. It's the highest substance position to measure.
        let lastViewIndex = combinedSubstances.reduce((max, item) => Math.max(max, item.viewIndex), 0);

        this.$scope.gs.substanceCount = lastViewIndex;
        this.$scope.gs.testedSubstanceCount = testedSubstanceCount;

        if (!this.isClassical) {
            this.$scope.gs.customGridNames = this.calculateCustomGridNames(combinedSubstances);
        }
        this.disableNext = false;
        this.$scope.$digest();
    }

    calculateCustomGridNames() {
        // Custom Grid names are only set in Otolaryngic practices at the IDT step.
        // These are to be able to show the exact antigen numbers that are in a given block of cells.
        // e.g. 1-6 for the first 8 block cell if two controls were in that cell at SPT
        let allergyTest = this.$scope.allergyTest;
        let customGridNames = [];

        let sptGroupMap = new Map();
        for (let i = 0; i < allergyTest.sptGroups.length; i++){
            let sptGroup = allergyTest.sptGroups[i];
            sptGroupMap.set(sptGroup, { min: 999, max: 0 });
        }

        let panel = this.$scope.panel;
        for (let i = 0; i < panel.substances.length; i++) {
            let substance = panel.substances[i];
            if (allergyTest.sptGroups.indexOf(substance.sptGroup) != -1 && substance.idtPos != -1) {
                let minMax = sptGroupMap.get(substance.sptGroup);
                minMax.min = minMax.min > substance.idtPos ? substance.idtPos : minMax.min;
                minMax.max = minMax.max < substance.idtPos ? substance.idtPos : minMax.max;
            }
        }

        sptGroupMap.forEach(kv => {
            customGridNames.push(`${kv.min + 1} - ${kv.max + 1}`);
        })
        return customGridNames;
    }

    async handleAnyResult(anyResult, panelSubstance) {
        let substance, category, anySubstance = {};
        if (panelSubstance) {
            substance = panelSubstance.substance._dto;
            category = substance.category._dto;
            anySubstance.panelPos = panelSubstance.panelPos;
            anySubstance.idtPos = panelSubstance.idtPos;
            anySubstance.viewIndex = panelSubstance.idtPos + 1;
            anySubstance.sptPos = panelSubstance.sptPos;
            anySubstance.sptGroup = panelSubstance.sptGroup;
        }
        else {
            // If panelSubstance is not set, then this is an adhoc control not on the panel.
            substance = await this.substanceService.get(anyResult.substance);
            category = substance._category;
        }

        anySubstance.positive = category._isPositiveControl;
        anySubstance.substance = substance;
        anySubstance.isControl = category._isControl;

        let isAntigen = category._isAntigen;
        let dilution = anyResult.idtDilution;

        // Testable if dilution in range and the board has the right dilutions in service
        let testable = (dilution >= 0 && dilution < 100);

        //In Otoloaryngic, check board for vial w/ correct antigen dillution that's in service (controls added ad-hoc are not worried about)
        //In Traditional, could not get to this point we expired concentrate because of previous page in wizard
        if (!this.isClassical && isAntigen) {
            testable = testable && this.$scope.idtBoard.vials.some(v => v.status === "IN_SERVICE" && v.substance.id === substance.id && v.dilution === dilution);
        }

        // Don't create the viewmodel object if !testable && isClassical because then it would have an empty and disabled spot in the grid; Not desirable for traditional practices
        if (!testable && this.isClassical) {
            return undefined;
        }

        anySubstance.isTestable = testable;

        // sptMeasurementEntered will be false if the SPT Group was skipped
        // and true if a normal measurement was taken or the substance was skipped individually
        anySubstance.sptMeasurementEntered = anyResult.sptMeasurements && anyResult.sptMeasurements.wheal != undefined;
        return anySubstance;
    }
}
