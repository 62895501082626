"use strict";

import BaseController from "../../../base.controller.js";

export default class ApprovalDetailsController extends BaseController {
    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        var params = this.getRouteParams();
        if (!params || !params.href) {
            console.log('TestDetailsController :: Route parameters missing or corrupted.');
            this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS);
        }

        this.$scope.href = params.href;

        this.$scope.close = () => {
            this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS);
        }
    }
}
