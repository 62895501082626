'use strict';

import TestingController from '../testing.controller';

export default class TestMeasureSptResultController extends TestingController {

    static $inject = ['$scope', '$injector','boardService','panelService'];
    constructor($scope, $injector, boardService, panelService) {

        super($scope, $injector);

        this.boardService = boardService;
        this.panelService = panelService;
        this.sptGroupSize = this.$scope.practice.config.sptSubstancesPerTray / this.$scope.practice.config.sptPrickersPerTray;

        // Set text display in the instruction panel
        $scope.setInstructionText = (index) => {
            index = Number(index);
            this.$scope.tabindex = index;
            this.$scope.text = '';

            if (!this.$scope.allergyTest || !this.$scope.controlSubstances) {
                return;
            }

            const posInGroup = 1 + (index - 1) % this.sptGroupSize;
            let useRelativePos = !this.isOtolaryngic;

            let isControl = true;
            let substance = this.$scope.controlSubstances.find(m => (m.sptPos + 1) === index);
            if (!substance) {
                substance = this.$scope.antigenSubstances.find(m => (m.sptPos + 1) === index);
                isControl = false;
            }

            // Otolaryngic Example: `Measure Group C Antigen #17: Sheep Sorrel
            // Tradictional Example: `Measure Group C Antigen #1: Sheep Sorrel
            this.$scope.text = `Measure Group ${substance.sptGroup} ${isControl ? `Control` : `Antigen`} `
                + `#${useRelativePos ? posInGroup : index}: ${substance.substance.name}`;
        };

        // Set input disable for a specific cell in stamp - support disable control(histamine) cell
        $scope.setDisabled = (index) => {
            let disabled = false;

            if (!this.$scope.allergyTest || !this.$scope.controlSubstances) {
                disabled = true;
            } else if (this.isAntigenStage) {
                let controlIndex = this.$scope.controlSubstances.findIndex(m => (m.sptPos + 1) === index);
                let substanceIndex = this.$scope.antigenSubstances.findIndex(m => (m.sptPos + 1) === index);
                let invalid = false;
                if (controlIndex !== -1) {
                    invalid = this.$scope.controlSubstances[controlIndex].invalid;
                }
                else if (substanceIndex !== -1) {
                    invalid = this.$scope.antigenSubstances[substanceIndex].invalid;
                }
                disabled = (controlIndex === -1 && substanceIndex === -1) || invalid;
            } else if (this.isHistamineStage) {
                let controlIndex = this.$scope.controlSubstances.findIndex(m => m.sptPos + 1 === index);
                let invalid = false;
                if (controlIndex !== -1) {
                    invalid = this.$scope.controlSubstances[controlIndex].invalid;
                }
                disabled = controlIndex === -1 || invalid;
            }

            return disabled;
        };

        $scope.onMeasurementChange = (index) => {
            // Check if a control is changed while in antigen stage...
            if (this.isHistamineStage)
                return;

            let control = this.$scope.controlSubstances.find(m => (m.sptPos + 1) == index);
            if (!control)
                return;

            let measurement = this._viewToMeasurements(this.$scope.gs.wheals[index], control.positive);

            if (measurement.wheal >= 0 || measurement.erythema >= 0) {
                this.update().then(() => {
                    if (control.positive && this._hasHistamineWarning()) {
                        this.histamineWarningModal();
                    } else if (!control.positive  && (measurement.wheal > 0 || measurement.erythema > 0)) {
                        this.negControlWarningModal();
                    }
                });
            }
        };

        $scope.switchArm = () => {
            this.updateDtoFromView();
        };

        let onInputFocus = (index) => {
            let control = this.$scope.controlSubstances.find(m => (m.sptPos + 1) === index);
            let isPositiveControl = control && control.positive;
            this.$scope.gs.measureConfig = this._getMeasureConfig(isPositiveControl);
        };

        /* Grid scope */
        $scope.gs = {
            substanceCount: 0,
            setDisabled: $scope.setDisabled,
            setFocus: $scope.setFocus,
            onInputFocus: onInputFocus,
            onWhealChange: $scope.onMeasurementChange,
            switchArm: $scope.switchArm,
            wheals: [],
            boxNumbers: [],
            focused: [],
            leftForearm: false,
            rightForearm: false,
            sptGroups: '',
            arrangement: 'SEQ',
            measureConfig: {},
            isOtolaryngic: this.isOtolaryngic
        };

        this.shownNegControlWarning = false;
        this.allergyTestLoaded()
            .then(() => this._loadAllergyTestConfig())
            .then(() => this.reload())
            .catch((e) => console.error(e));
    }

    async reload() {
        super.validateStage(['SPT_ANTIGEN_RESULTS', 'SPT_HISTAMINE_RESULTS']);

        this.$scope.isLoaded = false;
        this.$scope.gs.leftForearm = true;
        this.$scope.gs.rightForearm = false;
        this.$scope.gs.sptGroups = this.$scope.allergyTest.sptGroups;
        this.$scope.gs.isOtolaryngic = this.isOtolaryngic;

        this.$scope.gs.arrangement = this.$scope.practice.config.sptPrickerArrangement;

        this.isAntigenStage = (this.$scope.allergyTest.stage === 'SPT_ANTIGEN_RESULTS');
        this.isHistamineStage = !this.isAntigenStage;

        if (this.isHistamineStage) {
            this.$scope.textrightjustify = 'TIME ELAPSED:';

            if (this._hasHistamineWarning()) {
                this.histamineWarningModal();
            }

        } else if (this.isAntigenStage) {

            this.$scope.skipTestLocations = [];

            // handle the click event in NotTested in Instruction directive
            this.$scope.nottest = () => {
                this.$scope.skipTestLocations.push(this.$scope.tabindex);
                this.$scope.gs.wheals[this.$scope.thisInput] = 'X';
                this.$scope.tabindex++;
                this.$scope.nextTabIndex(this.$scope.tabindex);
            };

            if (this.$scope.allergyTest.abortPending === true) {
                this.$scope.nextButonLabel = "End Appointment";
            }
        }

        await this.loadSubstances(this.$scope.allergyTest.sptBoard);

        this.$scope.showModal = false;
        this.$scope.toggleModal = () => {
            this.$scope.showModal = !this.$scope.showModal;
        };

        // Get sptPos of histamine
        let positiveControl = this.$scope.controlSubstances.find(m => m.positive);
        let positiveSptPos = positiveControl ? positiveControl.sptPos : -1;

        // Initialize the values
        for (let index = 0; index < this.$scope.allergyTest.sptMeasurements.length; index++) {
            const measurements = this.$scope.allergyTest.sptMeasurements[index];
            let substance = this.$scope.antigenSubstances.find(m => (m.sptPos) === index);
            if (substance && substance.invalid) {
                measurements.wheal = -1;
            }
            this.$scope.gs.wheals[index+1] = this._measurementsToView(measurements, (index === positiveSptPos));
            this.$scope.gs.boxNumbers[index+1] = index+1;
        }

        this.initFooterNote("Measure SPT Results");
        this.$scope.isLoaded = true;
        this.$scope.$digest();
    }

    /**
     * What to do when the Next button is click in the wizard.
     * @override
     */
    next() {
        this.disableNext = true;
        this.update().then(() => this.advance());
    }

    /**
     * Save changes to server.
     * @override
     */
    update() {
        this.updateFooterNote();
        if (this.updateDtoFromView() && this.isHistamineStage && !this.shownNegControlWarning) {
            return this.negControlWarningModal()
                .then(() => {
                    this.shownNegControlWarning = true;
                    return super.update();
                });
        } else {
            return super.update();
        }
    }

    updateDtoFromView() {
        let warn = false;

        if (this.isAntigenStage) {
            this.$scope.antigenSubstances.forEach(s => {
                const measurements = this._viewToMeasurements(this.$scope.gs.wheals[(s.sptPos + 1)], s.positive);
                measurements.position = s.sptPos;

                this.$scope.allergyTest.sptMeasurements[s.sptPos] = measurements;
                let antigenResult = this.$scope.allergyTest.antigenResults.find(aR => aR.substance.id === s.substance.id);
                if (!antigenResult) {
                    antigenResult = {
                        substance: { id: s.substance.id },
                        allergyTest: { id: this.$scope.allergyTest.id },
                    }
                    this.$scope.allergyTest.antigenResults.push(antigenResult);
                };

                antigenResult.sptMeasurements = measurements;
            });
        }

        this.$scope.controlSubstances.forEach(s => {
            const measurements = this._viewToMeasurements(this.$scope.gs.wheals[(s.sptPos+1)], s.positive);
            measurements.position = s.sptPos;

            this.$scope.allergyTest.sptMeasurements[s.sptPos] = measurements;
            let controlResult = this.$scope.allergyTest.controlResults.find(cR => cR.substance.id === s.substance.id);
            if (!controlResult) {
                controlResult = {
                    substance: { id: s.substance.id },
                    allergyTest: { id: this.$scope.allergyTest.id },
                }
                this.$scope.allergyTest.controlResults.push(controlResult);
            };

            controlResult.sptMeasurements = measurements;

            // Check for any reaction to a positive control . Mr. Smith Goes to Washington
            if (!s.positive && (measurements.wheal > 0 || measurements.erythema > 0)) {
                warn = true;
                console.log('Negative control has a reaction');
            }
        });

        return warn;
    }

    /**
     * @override
     */
    nextDisabled() {
        if (this.disableNext || !this.$scope.allergyTest) {
            return true;
        }

        // Are there any measurements not filled in?
        let empty = false;
        if (this.isHistamineStage) {
            empty = this.$scope.controlSubstances.reduce((haveEmpty, substance) => {
                if (haveEmpty) {
                    return haveEmpty;
                }
                else {
                    const measureConfig = this._getMeasureConfig(substance.positive);
                    const measurements = this._viewToMeasurements(this.$scope.gs.wheals[substance.sptPos + 1], substance.positive);

                    return (measureConfig.wheal && measurements.wheal == undefined) ||
                           (measureConfig.erythema && measurements.erythema == undefined);
                }
            }, false);
        } else if (this.isAntigenStage) {
            empty = this.$scope.antigenSubstances.reduce((haveEmpty, substance) => {
                const index = substance.sptPos + 1;
                const skip = this.$scope.skipTestLocations.includes(index);
                if (skip || haveEmpty) {
                    return haveEmpty;
                }
                else {
                    const measureConfig = this._getMeasureConfig(false);
                    const measurements = this._viewToMeasurements(this.$scope.gs.wheals[index], false);
                    return (measureConfig.wheal && measurements.wheal == undefined) ||
                           (measureConfig.erythema && measurements.erythema == undefined);
                }
            }, false);
        }

        return empty;
    }

    async loadSubstances(sptBoard) {
        this.disableNext = true;
        this.$scope.controlSubstances = [];
        this.$scope.antigenSubstances = [];

        // let board = await this.boardService.getWithNoVials(sptBoard);
        let board = await this.boardService.getWithCurrentVials(sptBoard, null, true);
        let invalidSubstances = this.boardService.getOutOfServiceSubstances(board);

        let panel = await this.panelService.get(board.panel, board);
        this.panelService.populateSptGroupNames(this.$scope.practice, panel);
        const includedSptGroups = this.$scope.allergyTest.sptGroups;

        let testedSubstanceCount = 0;
        for (let panelSubst of panel.substances) {
            // Skip SPT groups not being tested & non-SPT substances
            if (panelSubst.sptPos < 0 || includedSptGroups.indexOf(panelSubst.sptGroup) < 0)
                continue;

            let substance = panelSubst.substance._dto;
            let category = substance.category._dto;

            let isInvalidSubstance = invalidSubstances.some((invalidSubstance) => {
                return invalidSubstance.id === substance.id;
            });

            testedSubstanceCount++;

            if (category._isAntigen) {
                let antigenSubstance = {
                    substance: substance,
                    panelPos: panelSubst.panelPos,
                    sptPos: panelSubst.sptPos,
                    sptGroup: panelSubst.sptGroup,
                    invalid: isInvalidSubstance
                };

                this.$scope.antigenSubstances.push(antigenSubstance);
            }
            else if (category._isControl) {

                let controlSubstance = {
                    positive: category._isPositiveControl,
                    substance: substance,
                    panelPos: panelSubst.panelPos,
                    sptPos: panelSubst.sptPos,
                    sptGroup: panelSubst.sptGroup,
                    invalid: isInvalidSubstance
                };

                this.$scope.controlSubstances.push(controlSubstance);
            }
        }

        this.$scope.stampColumns = 2;
        this.$scope.stampRows = board.substancesPerTray / this.$scope.practice.config.sptPrickersPerTray / this.$scope.stampColumns;

        // substanceCount is a misnomer now with SPT groups being skipped. It's the highest substance position to measure.
        let lastSptPos = this.$scope.controlSubstances.reduce((max,item) => Math.max(max, item.sptPos), 0);
        if (this.isAntigenStage) {
            lastSptPos = this.$scope.antigenSubstances.reduce((max,item) => Math.max(max, item.sptPos), lastSptPos);
        }
        this.$scope.gs.substanceCount = lastSptPos + 1;
        this.$scope.gs.testedSubstanceCount = testedSubstanceCount;
        console.log('substanceCount', this.$scope.gs.substanceCount);
        console.log('testedSubstanceCount', testedSubstanceCount);
        this.disableNext = false;
    }

    _hasHistamineWarning() {
        return this.$scope.allergyTest.warnings.SPT_HISTAMINE && !this.$scope.allergyTest.warnings.SPT_HISTAMINE.override && !this.$scope.allergyTest.abortPending;
    }

    histamineWarningModal() {
        let parent = this;
        var modalInstance = this.$uibModal.open({
            windowClass: 'warningModal',
            scope: this.$scope,
            template: require('./testing-wheal-size-modal.html'),
            controller: function ($uibModalInstance, $scope) {

                $scope.warningFormData = {
                    warnings: [],
                    mayTryIdt: ($scope.allergyTest.histamineState === 'TRY_IDT'),
                    proceedChoice: undefined,
                    addNotes: undefined
                };

                let spitText =
                    $scope.allergyTest.warnings.SPT_HISTAMINE.warnText.replace(/\n/g, "~").split("~");// split text by /n
                spitText.forEach(str => {
                    if (str) {
                        $scope.warningFormData.warnings.push(str);
                    }
                });

                $scope.validate = () => {
                    if ($scope.warningFormData.proceedChoice === 'Continue') {
                        return $scope.warningFormData.addNotes;
                    }
                    else {
                        return $scope.warningFormData.proceedChoice;
                    }
                };

                $scope.cancel = () => $uibModalInstance.dismiss();

                $scope.done = () => {
                    $uibModalInstance.close('success');

                    if ($scope.warningFormData.proceedChoice === 'Reschedule') {
                        parent.abort("Reschedule due to SPT Histamine warning.");
                    } else if ($scope.warningFormData.proceedChoice === 'Continue') {

                        $scope.allergyTest.warnings.SPT_HISTAMINE.override = { note: $scope.warningFormData.addNotes };
                        parent.next();
                    } else {
                        // Remeasure - just return to the form
                    }
                }
            }
        });
    }
}
