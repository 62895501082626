'use strict';

import angular from 'angular';

import AppointmentDetailsController from './appointment-details.controller.js';

export default angular.module('pages.common.appointment', [])
    .controller('AppointmentDetailsController', AppointmentDetailsController)
    .directive('limitTo', [limitTo])
    .name;

function limitTo() {
    return {
        restrict: "A",
        link: function(scope, elem, attrs) {
            var limit = parseInt(attrs.limitTo);
            angular.element(elem).on("keypress", function(e) {
                if (this.value.length == limit) e.preventDefault();
            });
        }
    }
}