"use strict";

import angular from "angular";

export default function agLocationManagement() {

    return {
        controller : "LocationManagementController",
        scope : false,
        restrict : "E",
        css : require("./styles.scss"),
        template : require("./layout.html"),
        link : function(scope, element, attrs, controller) {
            scope.onLocationClick =(newLocation)=> controller.onOfficeLocationChange(newLocation);
        }
    };
};

