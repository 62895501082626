'use strict';

import BaseService from './base.service.js';

export default class BillService extends BaseService {

    /**
     * Send a bill to the EMR
     * @param bill the bill to send
     * @return Promise that, when resolved, contains no content
     */
    send(bill) {
        return this.serverAPI.put(bill._links.send);
    }

    /**
     * Fetch the BillHistory view DTO of a Bill
     * @param bill {Bill} DTO
     * @returns {Promise<BillHistory>}
     */
    getHistory(bill) {
        return this.serverAPI.get(bill._links.history);
    }

    /**
     * Retrieve one or more bills
     * @param practice the current user's practice
     * @param from only bills where the procedure was performed on or after this time
     * @param to only bills where the procedure was performed on or before this time
     * @param downloaded if true, only bills that have been downloaded at least once.
     * If false, only bills that have never been downloaded.
     * @param submitted if true, only bills that have been submitted at least once.
     * If false, only bills that have never been submitted
     * @return {Promise<BillHistory>} bills that matched the query
     */
    search(practice, officeId, performedFrom, performedTo, downloaded, submitted) {
        let params = {
            officeId,
            performedFrom: performedFrom,
            performedTo: performedTo,
            downloaded: downloaded,
            submitted: submitted
        };

        return this.serverAPI.get(practice._links.billHistory, params);
    }
}
