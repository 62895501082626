'use strict';

import BaseController from '../../base.controller.js';

/**
 * Controller for the Patient Add & Edit model. See wireframe screens 405 & 406.
 */
export default class PatientEditController extends BaseController {

    ENTRY_VALIDATION_DELAY = 1000; //ms

    /**
     * Construct
     * @param $uibModalInstance modal containing this U/I
     * @param patient if defined, edit this patient. If undefined, create a new patient.
     */
    constructor($scope, $injector, $uibModalInstance, patient) {
        super($scope, $injector);

        /*
         * Initialize fields and scope methods
         */
        this.$scope = $scope;
        this.$scope.patient = patient;
        this.$scope.insurance1 = null;
        this.$scope.insurance2 = null;
        this.$scope.insurance1old = null; // set if replaced via U/I
        this.$scope.insurance2old = null; // set if replaced via U/I

        this.$uibModalInstance = $uibModalInstance;
        this.chronologyMappingService = $injector.get('chronologyMappingService');
        this.insurancePolicyService = $injector.get('insurancePolicyService');
        this.patientService = $injector.get('patientService');
        this.diagnosisCodeService = $injector.get('diagnosisCodeService');
        this.$timeout = $injector.get('$timeout');
        this.$uibModal = $injector.get('$uibModal');

        /** Cancel button clicked */
        $scope.cancel = () => this.$uibModalInstance.dismiss();

        /** Save & Close button clicked */
        $scope.save = () => this._saveAndExit();

        $scope.validateChartNum = () => this._validateChartNum();
        $scope.validateNameAndBirth = () => this._validateNameAndBirth();
        $scope.editInsurance = (policy) => this._editInsurance(policy);
        $scope.replaceInsurance = (policy) => this._replaceInsurance(policy);

        // Date Picker Options
        this.$scope.dobDatePicker = {
            value: new Date(),
            opened: false,
            format: "MM/dd/yyyy",
            maxDate: new Date()
        };

        this.$scope.dobDateOptions = {
            datepickerMode: 'year', // start picking with year 1st time
            showWeeks: false,
            minDate: new Date(1900, 1, 1),
            initDate: new Date(2000, 1, 1),
            maxDate: new Date(),
        };

        /*
         * Load data from server
         */
        if (this.$scope.patient) {
            this._buildEditVM();
            if (this.$scope.patient.dayOfBirth) {
                this.$scope.dobDatePicker.value = this.chronologyMappingService.isoDateToJsUTCDate(this.$scope.patient.dayOfBirth);
            }
        }
        else {
            this._buildAddVM();
        }
    }

    /**
     * View model is a Patient DTO in $scope.patient and InsurancePolicy DTO objects in $scope.insurance1 and $scope.insurance2.
     * I considered a separate view model structure, but it would have just been like the DTOs.
     * @private
     */
    _buildEditVM() {
        let patient = this.$scope.patient;
        this._addMissingPatientFields(patient);

        this.insurancePolicyService.getForPatient(patient)
            .then(policyList => {
                let today = this.chronologyMappingService.currentDate(this.$scope.office.timezone);

                for (let policy of policyList.list) {
                    if (policy.termination <= today)
                        continue;

                    if (policy.priority === 1)
                        this.$scope.insurance1 = policy;
                    else if (policy.priority === 2)
                        this.$scope.insurance2 = policy;
                }

                if (this.$scope.insurance1 === null)
                    this.$scope.insurance1 = this._createInsurancePolicy(1);

                if (this.$scope.insurance2 === null)
                    this.$scope.insurance2 = this._createInsurancePolicy(2);
            });

    }

    /**
     * View model is a Patient DTO in $scope.patient and InsurancePolicy DTO objects in $scope.insurance1 and $scope.insurance2.
     * I considered a separate view model structure, but it would have just been like the DTOs.
     * @private
     */
    _buildAddVM() {
        let patient = this.$scope.patient = {
            person: {
                familyName: '',
                middleName: '',
                givenName: '',
                credential: ''
            },
            chartNum: '',
            dayOfBirth: '',
            active: true,
        };

        this._addMissingPatientFields(patient);

        this.$scope.insurance1 = this._createInsurancePolicy(1);
        this.$scope.insurance2 = this._createInsurancePolicy(2);
    }

    /**
     * Initialize sub-structures that may be missing from patient
     * @private
     */
    _addMissingPatientFields(patient) {
        if (!patient.emailAddresses)
            patient.emailAddresses = {};

        if (!patient.emailAddresses.primary)
            patient.emailAddresses.primary = '';

        if (!patient.phoneNumbers)
            patient.phoneNumbers = {};

        if (!patient.phoneNumbers.home)
            patient.phoneNumbers.home = '';
        if (!patient.phoneNumbers.mobile)
            patient.phoneNumbers.mobile = '';

        if (!patient.physicalAddresses)
            patient.physicalAddresses = {};

        if (!patient.physicalAddresses.home) {
            patient.physicalAddresses.home = {
                street1: '',
                street2: '',
                city: '',
                state: '',
                postal: ''
            };
        }
    }

    /**
     * Construct a new InsurancePolicy.
     * @param priority
     * @returns {InsurancePolicy}
     * @private
     */
    _createInsurancePolicy(priority) {
        return {
            id: null,
            priority: priority,
            companyName: '',
            policyNum: '',
            groupName: '',
            medicare: false,
            effective: this.chronologyMappingService.currentDate(this.$scope.office.timezone),
            termination: '2099-12-31',
            _editing: true,
            _placeholder: 'Enter insurance name...'
        };
    }

    _editInsurance(policy) {
        policy._editing = true;
    }

    _replaceInsurance(policy) {
        // Nevermind if already a new policy
        if (!policy.id)
            return;

        let oldPolicy = policy;
        let newPolicy = null;

        if (policy.priority === 1) {
            oldPolicy = this.$scope.insurance1old = this.$scope.insurance1;
            newPolicy = this.$scope.insurance1 = this._createInsurancePolicy(1);
        }
        else if (policy.priority === 2) {
            oldPolicy = this.$scope.insurance2old = this.$scope.insurance2;
            newPolicy = this.$scope.insurance2 = this._createInsurancePolicy(2);
        }

        oldPolicy.termination = oldPolicy.effective;
        newPolicy.medicare = oldPolicy.medicare;
        newPolicy._placeholder = "Enter new insurance name...";
    }

    /**
     * Save the Patient data - either updating an existing one or adding a new patient.
     */
    async _saveAndExit() {

        // Update or create Patient
        let home = this.$scope.patient.physicalAddresses.home;
        home.state = home.state.toUpperCase();
        home.postal = home.postal.toUpperCase();

        if (this.$scope.patient.id) {
            this.$scope.patient = await this.patientService.update(this.$scope.patient);
        }
        else {
            this.$scope.patient = await this.patientService.create(this.$scope.practice, this.$scope.patient);
        }

        // Update or create InsurancePolicy #1
        if (this.$scope.insurance1old !== null) {
            this.$scope.insurance1old = await this.insurancePolicyService.update(this.$scope.insurance1old);
        }

        if (this.$scope.insurance1.id) {
            this.$scope.insurance1 = await this.insurancePolicyService.update(this.$scope.insurance1);
        }
        else if (this.$scope.insurance1.companyName.length > 0) {
            this.$scope.insurance1 = await this.insurancePolicyService.createForPatient(this.$scope.patient, this.$scope.insurance1);
        }

        // Update or create InsurancePolicy #2
        if (this.$scope.insurance2old !== null) {
            this.$scope.insurance2old = await this.insurancePolicyService.update(this.$scope.insurance2old);
        }

        if (this.$scope.insurance2.id) {
            this.$scope.insurance2 = await this.insurancePolicyService.update(this.$scope.insurance2);
        }
        else if (this.$scope.insurance2.companyName.length > 0) {
            this.$scope.insurance2 = await this.insurancePolicyService.createForPatient(this.$scope.patient, this.$scope.insurance2);
        }

        this.$scope.$apply(() => this.$uibModalInstance.close(this.$scope.patient));
    }

    /**
     * Check to see if another patient already claims the entered chart #.
     * Updates $scope.showChartNumBanner.
     *
     * This function will be called for every character the user types. Processing is delayed
     * until the user stops typing for a bit.
     *
     * @private
     */
    _validateChartNum() {
        this.$timeout.cancel(this._pendingChartNumCheck);
        this._pendingChartNumCheck = this.$timeout(this.ENTRY_VALIDATION_DELAY);
        this._pendingChartNumCheck.then(() => {

            let patient = this.$scope.patient;

            if (patient.chartNum && patient.chartNum.length > 0) {
                this.patientService.findPatientsBy(this.$scope.practice, {'chartNum': patient.chartNum})
                    .then(otherPatients => {
                        let others = otherPatients.list.filter(p => p.id !== patient.id);
                        this.$scope.showChartNumBanner = (others.length > 0);
                    });
            }
            else {
                // Field not yet set
                this.$scope.showChartNumBanner = false;
            }
        });
    }

    /**
     * Check to see if another patient has the same name and day of birth.
     * Updates $scope.showNameBirthBanner.
     *
     * This function will be called for every character the user types. Processing is delayed
     * until the user stops typing for a bit.
     *
     * @private
     */
    _validateNameAndBirth() {
        if (!this.$scope.patient.person.familyName || !this.$scope.dobDatePicker.value)
            return;

        this.$timeout.cancel(this._pendingNameBirthCheck);
        this._pendingNameBirthCheck = this.$timeout(this.ENTRY_VALIDATION_DELAY);
        this._pendingNameBirthCheck.then(() => {

            let patient = this.$scope.patient;
            let familyName = patient.person.familyName;
            let dayOfBirth = this.chronologyMappingService.jsUTCDateToISODate(this.$scope.dobDatePicker.value);
            patient.dayOfBirth = dayOfBirth;

            if (familyName && familyName.length > 1 && dayOfBirth && dayOfBirth.length === 10) {
                this.patientService.findPatientsBy(this.$scope.practice, {
                    'familyName': familyName,
                    'dayOfBirth': dayOfBirth
                })
                    .then(otherPatients => {
                        let others = otherPatients.list.filter(p => p.id !== patient.id);
                        this.$scope.showNameBirthBanner = (others.length > 0);
                    });
            }
            else {
                // Both fields not yet set
                this.$scope.showNameBirthBanner = false;
            }
        });
    }
}

