'use strict';

import BaseService from './base.service.js';

export default class MixService extends BaseService {

    /**
     * Get a Mix by its ReferenceDTO, bypassing the browser cache, and flagging the server
     * that user is reviewing this mix for possible approval.
     *
     * @param ref ReferenceDTO to a Mix
     * @return Promise that will resolve to the Mix, or reject to a ServerError.
     */
    getForApproval(ref) {
        return super.getUncached(ref, {forApproval: true});
    }

    /**
     * Get Mixes for a patient.
     *
     * @param patient fetch for this Patient
     * @returns Promise to a Mix.List
     */
    getForPatient(patient) {
        return this.serverAPI.get(patient._links.mixes);
    }

    /**
     * Get Mixes for a Prescription.
     *
     * Handles case where Prescription doesn't have any mixes - returning an empty list.
     *
     * @param prescription fetch for this Prescription
     * @returns Promise to a Mix.List
     */
    getForPrescription(prescription) {
        const uri = prescription._links.mixes;
        return uri ? this.serverAPI.get(uri) : super.resolved({list:[]});
    }

    /**
     * Overrides the update from base service to include notes.
     *
     * @param dto modified DTO
     * @param note change-request note
     * @return Promise that will resolve to an updated DTO, or reject with a ServerError.
     */
    update(dto, note) {
        return this.serverAPI.put(dto.href, {note}, dto).then(dto => this.transform(dto));
    }

    /**
     * Approve this Mix (as a Doctor).
     *
     * @param mix existing Mix DTO
     * @param note text note to add to the approval.
     * @returns Promise to the updated Mix DTO
     */
    approve(mix, note) {
        return this.serverAPI.put(mix._links.approve, {note});
    }
}
