'use strict';

import BaseMixingPrescriptionDetailsController from '../../common/base-prescription-details/base-prescription-details.controller';

export default class InternalMixingPrescriptionDetailsController extends BaseMixingPrescriptionDetailsController {

    static $inject = ['$scope', '$injector'];

    constructor($scope, $injector) {
        super($scope, $injector);

        this.updateUiDetails("Begin Mixing", "Review Prescription Details and Apply Labels to Vials");
    }

    /**
     * What to do when the Next button is click in the wizard.
     * @return Promise to updated Prescription
     * @override
     */
    async nextStep() {
        // Disable buttons
        this.disableNext = true;
        this.disableExit = true;

        if (this.$scope.rxChangeRequest) {
            this._sendChangeRequest();
        }
        else {
            let today = this.chronologyMappingService.currentDate(this.$scope.office.timezone);
            if (this.pscript.labelsPrinted !== today) {
                await this._printLabelsDialog(angular.isDefined(this.pscript.labelsPrinted));
            }
            return super.nextStep();
        }
    }
}
