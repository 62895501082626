"use strict";

import BaseSectionController from "../../widgets/section-content-panel/controller";
import DashboardSections from "../../models/dashboard-sections";
import PhoneContext from "../../../../models/phone-context";
import {ChronoUnit, LocalDateTime, Period} from 'js-joda';

export default class NoTreatmentSectionController extends BaseSectionController {

    static $inject =  ["$injector", "$scope", "DashboardSections", "PhoneContext"];
    static TIMEFRAME_THIS_MONTH = "THIS-MONTH";
    static TIMEFRAME_THIS_YEAR = "THIS-YEAR";

    constructor($injector, $scope, UiSectionTypes, PhoneContext) {
        super($injector, $scope, UiSectionTypes.DASHBOARD_NO_TREATMENT);

        // Establish own resources
        this.PhoneContext = PhoneContext;
        this.allergyTestService = $injector.get("allergyTestService");
        this.chronologyMappingService = $injector.get("chronologyMappingService");
        this.patientService = $injector.get("patientService");

        // Establish stateful reactions
        $scope.TIMEFRAME_THIS_MONTH = NoTreatmentSectionController.TIMEFRAME_THIS_MONTH;
        $scope.TIMEFRAME_THIS_YEAR = NoTreatmentSectionController.TIMEFRAME_THIS_YEAR;
        $scope.$watch( ()=> $scope.dateRange, newDateRange => this._updateTimeFrame());
        $scope.onPatientSelection =(argRow)=> this._gotoPatientDetails(argRow._patient);
        $scope.onTestSelection =(argRow)=> this._gotoTestApproval(argRow._test);

        // Establish own model state
        this.reload();
    }

    /**
     * Reaction behavior to the user actively changing the session's subject office location. The current office is
     * a fundamental input in determining which sets of records are applicable. Hence, reload everything!
     *
     * @override
     */
    officeChanged() {
        super.officeChanged();
        this.reload();
    }

    // ****************************************** Public API ***********************************************************

    /** Load data, or reload when something changes. */
    reload() {

        if (angular.isDefined(this.$scope.dateRange)) {

            // Current DateTime is the end. It's an ISO-8601 string that we can manipulate.
            let tEnd = this.chronologyMappingService.currentDateTime(this.$scope.office.timezone);
            let tStart = this._uiToVmTime(tEnd, this.$scope.dateRange);

            this._resetSmartTable();
            this._loadPatients(tStart, tEnd);
        }
    }

    // ****************************************** Internal Impl ********************************************************

    /**
     * Acquires the list of all patients who were a Immunotherapy Candidate AND selected "No Treatment" after being
     * positive for one or more antigen.
     *
     * @param {string} tStart ISO-8601 DateTime
     * @param {string} tEnd ISO-8601 DateTime
     *
     * @private
     */
    async _loadPatients(tStart, tEnd) {
        this.$scope.isLoading = true;

        let untreatedTests = await this.allergyTestService.getDeclinedCandidates(this.$scope.office, tStart, tEnd);

        for (let aTest of untreatedTests.list) {

            let aVmTest = {};
            let aPatient = await this.patientService.get(aTest.patient, untreatedTests);
            let aProvider = await this.userService.getUserName(aTest.performedBy.user);

            aVmTest.patientName =
                [aPatient.person.givenName, aPatient.person.middleName, aPatient.person.familyName].join(" ");

            aVmTest.chartNumber =  aPatient.chartNum;

            aVmTest.phoneNumber = this.patientService.getPhonePrimaryNumber(aPatient);

            aVmTest.testingDate = aTest.performedBy.actionDateTime;

            aVmTest.provider = aProvider;
            aVmTest._patient = aPatient;
            aVmTest._test = aTest;

            aVmTest.candidate = aTest.candidate ? 'Yes' : 'No';

            this.$scope.rowCollection.push(aVmTest);
        }

        this.$scope.isLoading = false;
        this._updateSmartTable();
    }

    _resetSmartTable() {
        this.$scope.rowCollection = [];
        this.$scope.displayedCollection = [];
        this.$scope.rowSelectedObj = { isSet: false };
    }

    /**
     * @param {string} current ISO DateTime at the subject office.
     * @param {string} periodExpr
     *   Non-opaque, contextual identifier impl'd as a majik string. The value is assumed to either lexically match
     *   one of the class constants (TIMEFRAME_THIS_MONTH or TIMEFRAME_THIS_YEAR), failing that the string is
     *   processed as a JS-Joda.Period chronology serialized representation.
     *   The value is formatted to support Joda's API.
     *
     * @see https://js-joda.github.io/js-joda/esdoc/class/src/Period.js~Period.html
     *
     * @returns {string} ISO-8601 DateTime
     * @private
     */
    _uiToVmTime(currentDateTime, periodExpr) {

        let parsedPeriod, myTimeFrame;

        let now = LocalDateTime.parse(currentDateTime);

        if (periodExpr === NoTreatmentSectionController.TIMEFRAME_THIS_MONTH) {
            // Special Case :: limit appointments to those created the current Gregorian calendar month.
            // This is NOT necessarily equivalent to "Gimme the past 30 days!".
            myTimeFrame = now.withDayOfMonth(1);
        }
        else if (periodExpr === NoTreatmentSectionController.TIMEFRAME_THIS_YEAR) {
            // Special Case :: limit appointments to those created the current Gregorian calendar year.
            // This is NOT necessarily equivalent to "Gimme the past 365 days!".
            myTimeFrame = now.withDayOfYear(1);
        }
        else {
            // General Case :: arg expression assumed to be of the form "PnU",
            // where : 'P' is required by format (it means Period)
            //  n is an integer
            //  U is code, of value 'D','M' or 'Y' representing days, months, year respectively.
            // In this case, we let Joda's Chronologic-Arithmetic handle it...
            parsedPeriod = Period.parse(periodExpr);
            myTimeFrame = now.minusTemporalAmount(parsedPeriod);
        }

        return myTimeFrame.truncatedTo(ChronoUnit.DAYS).toString();
    }

    /**
     * Make a copy of the scoped data for smart table display purposes.
     *
     * @private
     */
    _updateSmartTable() {
        this.$scope.$apply( () => { this.$scope.displayedCollection = [].concat(this.$scope.rowCollection) } );
    }

    /**
     * Reaction behavior to the user actively changing the session's subject time frame of interest.
     * The service powering appointment data acquisition always wants finite a timeframe when accessing appointments,
     * which effectively makes the time period a fundamental input in determining which sets of records are applicable.
     * Hence, reload everything!
     *
     * @private
     */
    _updateTimeFrame() {
        this.reload();
    }

    /**
     * Navigate to the Patient Details
     *
     * @param patient a Patient DTO
     * @private
     */
    _gotoPatientDetails(patient) {
        this.routeToPage(this.urlPaths.PATIENT_DETAILS, patient, this.routingService.createLocationParams('No Treatment'));
    }

    /**
     * Navigate to the Re-Approve an Allergy Test
     *
     * @param test an AllergyTest DTO
     * @private
     */
    _gotoTestApproval(test) {
        this.routeToPage(this.urlPaths.DASHBOARD_APPROVALS_TEST, test);
    }
}
