'use strict';

import ngRoute from 'angular-route';
import services from 'services';

export default angular.module('app.configuration', [
        ngRoute,
        services
    ])
    .run(['$rootScope', '$location', '$uibModalStack', 'analyticsService',
    function ($rootScope, $location, $uibModalStack, analyticsService) {
        // close the opened modal on location change.
        $rootScope.$on('$locationChangeStart', function ($event) {
            var openedModal = $uibModalStack.getTop();
            if (openedModal) {
                $uibModalStack.dismiss(openedModal.key);
            }
        });

        $rootScope.$on('$routeChangeSuccess', (event, current, previous) => {
            analyticsService.sendPageView($location.path());
        });

        $rootScope.INFO_TEXT = {
            UBD: 'Use by date is the date patients should complete treatment by.',
            BUD: 'Beyond use date is the date of the earliest expiring substance present in the patient vial, with a maximum of 365 days from the date on which the vial was mixed.'
        };
    }])
    .config(['$routeProvider', function ($routeProvider) {
        let originalWhen = $routeProvider.when;

        $routeProvider.when = function(path, route) {
            route.resolve || (route.resolve = {});

            if (route.resolve.session === undefined) {
                angular.extend(route.resolve, {
                    session : ['sessionService', (sessionService) => {
                        return sessionService.getSession().then(session => {
                            return session;
                        }).catch(err => {
                            return null;
                        });
                    }]
                });
            }

            return originalWhen.call($routeProvider, path, route);
        };

        $routeProvider.otherwise({
            redirectTo: function(params, currentPath) {
                console.error('Unknown route "' + currentPath + '". Redirecting to dashboard.');
                return '/dashboard';
            }
        });
    }])
    .config(['$locationProvider', function($locationProvider) {
		$locationProvider.hashPrefix('');
	}])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.useApplyAsync(true); // Improve performance by doing one $async after concurrent $http actions
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
        $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';
        $httpProvider.interceptors.push(['$injector', function ($injector) {
            return $injector.get('ErrorInterceptor');
        }]);
    }])
    .factory('ErrorInterceptor', ['$rootScope', '$q', '$location', '$injector', function ($rootScope, $q, $location, $injector) {
        return {

            responseError: function (response) {
                if (response.config && response.config.acceptedStatusCodes && response.config.acceptedStatusCodes.indexOf(response.status) > -1) {
                    // Caller will handle this response
                    return $q.reject(response);
                }

                console.log("Response status: " + response.status);
                console.log("Response data  : " + JSON.stringify(response.data));
                if (response.status === 401 || response.status === 403) {
                    $location.path('/login');
                }
                else {
                    $injector.get('$uibModal').open({
                        windowClass: 'alert-modal',
                        template: require('../widgets/common/alert-modal.html'),
                        css: require('../widgets/common/alert-modal.scss'),
                        controller: function ($uibModalInstance, $scope) {
                            $scope.close = () => $uibModalInstance.dismiss();

                            $scope.alertMessages = [];
                            if (response.data && response.data.errors) {
                                if (response.data.errors.length === 0) {
                                    scope.alertMessages.push('Error code ' +response.data.status);
                                }
                                else {
                                    for (var i = 0; i < response.data.errors.length; i++) {
                                        var data = response.data.errors[i].toString();
                                        $scope.alertMessages.push(data);
                                    }
                                }
                            }
                            else if (angular.isDefined(response.status)) {
                                $scope.alertMessages.push('Error code ' + response.status);
                            }
                        }
                    });
                }

                return $q.reject(response);
            }
        };
    }])
    .name;

