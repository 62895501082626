'use strict';

import BaseService from './base.service.js';

export default class SecurityQuestion extends BaseService {
    static $inject=['$location','serverAPI'];
    
    constructor($location, serverAPI) {
        super(serverAPI);
        this.$location = $location;
    }

    /**
     * GET gets the list of all active security questions 
     * @returns SecurityQuestion.List list of security questions
     */
    getAllActive() {
        return this.getGlobalLinks()
            .then(globalLinks => this.serverAPI.get(globalLinks.securityQuestions, {"active": true}));
    }
}
