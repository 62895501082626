'use strict';

import BaseController from '../../base.controller.js';
import {ClassicalDilution, ClassicalDilutions} from '../../../models/classical-dilutions';
/**
 * Controller for the Label Details model.
 */
export default class LabelDetailsController extends BaseController {
    constructor($scope, $injector, $uibModalInstance) {
        super($scope, $injector);

        this.$filter = $injector.get('$filter');
        this.$timeout = $injector.get('$timeout');
        this.$uibModalInstance = $uibModalInstance;
        this.patientService = $injector.get('patientService');
        this.prescriptionService = $injector.get('prescriptionService');
        this.substanceService = $injector.get('substanceService');
        this.treatmentConfigService = $injector.get('treatmentConfigService');
        this.treatmentVialService = $injector.get('treatmentVialService');
        this.boardService = $injector.get('boardService');
        this.concentrateService = $injector.get('concentrateService');
        this.externalConcentrateService = $injector.get('externalConcentrateService');

        this.ServiceStatus = $injector.get('ServiceStatus');

        $scope.inWizard = !!$scope.$resolve.inWizard;
        $scope.barcode = '';
        $scope.vial = null;
        $scope.error = null;

        $scope.onBarcodeEntered = (barcode) => this._onBarcodeEntered(barcode);
        $scope.navigateToBarcode = (barcode, previousVial) => this._navigateToBarcode(barcode, previousVial);
        $scope.navigateToConcentrateDetails = (concentrate) => this._navigateToConcentrateDetails(concentrate);
        $scope.navigateToPatientDetails = (patient) => this.routeToPage(this.urlPaths.PATIENT_DETAILS, patient);
        $scope.print = () => this._print();

        /** Close button clicked */
        $scope.close = () => {
            this.$uibModalInstance.close();
        };
    }

    _onBarcodeEntered(barcode) {
        if (!(barcode && barcode.length === 8)) {
            return;
        }
        else if (this.$scope.vial && this.$scope.vial.barcode === barcode) {
            return;
        }

        this.$scope.error = null;
        this._createVm(barcode)
            .then(() => this.$timeout());
    }

    _navigateToBarcode(barcode, previousVial) {
        this.$scope.previousVial = previousVial;
        this.$scope.barcode = barcode;
        this._onBarcodeEntered(barcode);
    }

    _navigateToConcentrateDetails(concentrate) {
        this.routeToPage(this.urlPaths.INVENTORY_CONCENTRATE_DETAILS, concentrate);
    }

    _print() {
        window.print();
    }

    async _createVm(barcode) {
        this.$scope.vial = null;

        let vm = await this._createTreatmentVialVm(barcode);
        if (!vm) {
            vm = await this._createTrayVialVm(barcode);
        }

        vm.storageConditions = 'Store @ 2-8°C';
        this.$scope.vial = vm;
    }

    async _createTreatmentVialVm(barcode) {
        let vm = { contents: [], isTreatmentVial: true };
        let treatmentVial = null;
        try {
            treatmentVial = await this.treatmentVialService.getByBarcode(this.$scope.practice, barcode);
        }
        catch (error) {
            if (error.status === 404) {
                return;
            }
        }

        let [patient, prescription, treatmentConfig] = await Promise.all([
            this.patientService.get(treatmentVial.patient),
            this.prescriptionService.get(treatmentVial.prescription),
            this.treatmentConfigService.get(treatmentVial.treatmentConfig)
        ]);

        vm.barcode = treatmentVial.barcode;
        vm.patient = patient;
        vm.patientName = patient.person.givenName + ' ' + patient.person.familyName;
        vm.dateOfBirth = this.$filter('date')(patient.dayOfBirth, 'MM/dd/yyyy', 'Z');
        vm.name = treatmentVial.name;
        vm.ubd = this.$filter('date')(treatmentVial.useBy, 'MM/dd/yyyy', 'Z');
        vm.bud = this.$filter('date')(treatmentVial.beyondUse, 'MM/dd/yyyy', 'Z');
        vm.treatmentType = prescription.treatmentType;
        vm.isMixExternal = prescription.mixExternal;
        vm.externalMixer = prescription.externalMixer;

        let prescribedVial = prescription.vials.find(vial => vial.barcode === treatmentVial.barcode);
        if (prescribedVial) {
            vm.volume = prescribedVial.capacity;

            let board;
            if (prescription.mixingBoard && prescribedVial.substances.some(ps => ps.dilution !== 0)) {
                //Retrieve the board here if we'll need it later to avoid retrieving it each loop
                board = await this.boardService.get(prescription.mixingBoard);
            }

            for (let ps of prescribedVial.substances) {
                let content = { barcode: null, name: null, volume: 0, warning: null, dilution: null };

                if (ps.substanceVialId) {
                    let prescribedVial = prescription.vials.find(vial => vial.id === ps.substanceVialId);
                    content.barcode = prescribedVial.barcode;
                    content.name = prescribedVial.name;
                    content.volume = ps.dosage;
                }
                else if (ps.substanceTreatmentVial) {
                    let treatmentVial = await this.treatmentVialService.get(ps.substanceTreatmentVial);
                    content.barcode = treatmentVial.barcode;
                    content.name = treatmentVial.name;
                    content.volume = ps.dosage;
                }
                else if (ps.substance) {
                    let substance = await this.substanceService.get(ps.substance);
                    content.name = substance.name;
                    content.volume = ps.dosage;

                    if (prescription.diluteTo) {
                        content.dilution = ClassicalDilutions[ps.dilution].color;
                    }
                    else {
                        if (ps.dilution === 0) {
                            content.dilution = "C";
                        }
                        else {
                            content.dilution = "#" + ps.dilution;
                        }
                    }

                    let concentrate = null;
                    if (prescription.mixExternal) {
                        concentrate = await this.externalConcentrateService.getByBarcode(this.$scope.practice, ps.mixedFromBarcode);
                        concentrate.linkToDetails = false;
                    }
                    else {
                        if (ps.dilution === 0) {
                            concentrate = await this.concentrateService.getByBarcode(this.$scope.practice, ps.mixedFromBarcode);
                        }
                        else if (prescription.mixingBoard) {
                            let trayVial = board ? board.vials.find(vial => vial.barcode === ps.mixedFromBarcode) : undefined;
                            if (trayVial) {
                                concentrate = await this.concentrateService.get(trayVial.concentrate);
                            }
                        }
                        concentrate.linkToDetails = true;
                    }

                    content.concentrate = concentrate;
                    if (concentrate && concentrate.status === this.ServiceStatus.EXPIRED) {
                        content.warning = {
                            message: "This concentrate has expired",
                            status: concentrate.status
                        }
                    }
                    else if (concentrate && concentrate.status === this.ServiceStatus.RECALLED) {
                        content.warning = {
                            message: "This concentrate has been recalled by the manufacturer",
                            status: concentrate.status
                        };
                    }
                }

                vm.contents.push(content);
            }
        }

        if (prescription.treatmentType === 'SCIT') {
            vm.treatmentTypeAbbrv = 'SC';
            vm.numberOfDoses = treatmentConfig.scitSchedule.length;
        }
        else if (prescription.treatmentType === 'SLIT') {
            vm.treatmentTypeAbbrv = 'SL';
            vm.numberOfDoses = this.prescriptionService.isEscalationVial(prescribedVial) ? treatmentConfig.slit.dosageEsc : treatmentConfig.slit.dosageMaint;
        }

        return vm;
    }

    async _createTrayVialVm(barcode) {
        let vm = { isTrayVial: true };
        let trayVial = null;
        try {
            trayVial = await this.boardService.getTrayVialByBarcode(this.$scope.practice, barcode);
        } catch (error) {
            if (error.status === 404) {
                return;
            }
        }

        let [board, concentrate] = await Promise.all([
            this.boardService.getByTrayVial(this.$scope.practice, trayVial),
            this.concentrateService.get(trayVial.concentrate)
        ]);

        let substance = await this.substanceService.get(concentrate.substance);

        vm.barcode = trayVial.barcode;
        vm.concentrate = concentrate;
        vm.name = substance.name;
        vm.formulation = concentrate.potency + ' ' + concentrate.potencyUnit;
        vm.ubd = this.$filter('date')(concentrate.useBy, 'MM/dd/yyyy', 'Z');
        
        if (board.arrangement === 'TRADITIONAL') {
            vm.dilution = ClassicalDilutions[trayVial.dilution].color;
        } else {
            if (trayVial.dilution === 0) {
                vm.dilution = 'C';
            } else {
                vm.dilution = '#' + trayVial.dilution;
            }
        }
        
        return vm;
    }
}
